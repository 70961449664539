export const institutionTypes = [
  {
    id: 27,
    value: 'Hospital',
    name: 'Hospital',
  },
  {
    id: 1,
    value: 'Outpatient Clinic',
    name: 'Outpatient Clinic',
  },
  {
    id: 2,
    value: 'Long-Term Care Facility',
    name: 'Long-Term Care Facility',
  },
  {
    id: 3,
    value: 'Clinical Lab',
    name: 'Clinical Lab',
  },
  {
    id: 4,
    value: 'Hospice Homes',
    name: 'Hospice Homes',
  },
  {
    id: 5,
    value: 'Addiction Treatment Centers',
    name: 'Addiction Treatment Centers',
  },
  {
    id: 6,
    value: 'Ambulatory Surgical Centers',
    name: 'Ambulatory Surgical Centers',
  },
  {
    id: 7,
    value: 'Long-Term Care Facility',
    name: 'Long-Term Care Facility',
  },
  {
    id: 8,
    value: 'Birth Centers',
    name: 'Birth Centers',
  },
  {
    id: 9,
    value: 'Blood Banks',
    name: 'Blood Banks',
  },
  {
    id: 10,
    value: 'Cancer Center',
    name: 'Cancer Center',
  },
  {
    id: 11,
    value: 'Clinical Research Center',
    name: 'Clinical Research Center',
  },
  {
    id: 12,
    value: 'Imaging and Radiology Centers',
    name: 'Imaging and Radiology Centers',
  },
  {
    id: 13,
    value: 'Dental Clinic',
    name: 'Dental Clinic',
  },
  {
    id: 14,
    value: 'Medical Laboratory',
    name: 'Medical Laboratory',
  },
  {
    id: 15,
    value: 'Dialysis Centers',
    name: 'Dialysis Centers',
  },
  {
    id: 16,
    value: 'Egg Cell Bank',
    name: 'Egg Cell Bank',
  },
  {
    id: 17,
    value: 'Nursing Homes',
    name: 'Nursing Homes',
  },

  {
    id: 18,
    value: 'Optometry',
    name: 'Optometry',
  },
  {
    id: 19,
    value: 'Orthodontic Clinic',
    name: 'Orthodontic Clinic',
  },
  {
    id: 20,
    value: 'Orthopedic Centers',
    name: 'Orthopedic Centers',
  },
  {
    id: 21,
    value: 'Pharmacies and Drug Stores',
    name: 'Pharmacies and Drug Stores',
  },
  {
    id: 22,
    value: 'Psychiatric Care Centers',
    name: 'Psychiatric Care Centers',
  },
  {
    id: 23,
    value: 'Urgent Care Center',
    name: 'Urgent Care Center',
  },
  {
    id: 24,
    value: 'Sperm Bank',
    name: 'Sperm Bank',
  },
  {
    id: 25,
    value: 'Telehealth',
    name: 'Telehealth',
  },
  {
    id: 26,
    value: 'Walk-In Retail Clinics',
    name: 'Walk-In Retail Clinics',
  },
  {
    id: 28,
    value: 'Maternal care',
    name: 'Maternal care',
  }
];
export const genderTypes = [
  {
    id: 1,
    value: 'male',
    name: 'Male',
  },
  {
    id: 2,
    value: 'female',
    name: 'Female',
  },
];
export const qualifications = [
  {
    id: 1,
    name: 'MBBS',
    value: 'MBBS',
  },
  {
    id: 2,
    name: 'BMBS',
    value: 'BMBS',
  },
  {
    id: 3,
    name: 'MBChC',
    value: 'MBChC',
  },
  {
    id: 4,
    name: 'MBChB',
    value: 'MBChB',
  },
  {
    id: 5,
    name: 'MBBCh',
    value: 'MBBCh',
  },
  {
    id: 6,
    name: 'MD',
    value: 'MD',
  },
  {
    id: 7,
    name: 'DO',
    value: 'DO',
  },
  {
    id: 8,
    name: 'MS or MSurg or MChir or MCh or ChM or CM',
    value: 'MS or MSurg or MChir or MCh or ChM or CM',
  },
  {
    id: 9,
    name: 'DCM',
    value: 'DCM',
  },
  {
    id: 10,
    name: 'DClinSurg',
    value: 'DClinSurg',
  },
  {
    id: 11,
    name: 'DClinSurg',
    value: 'DClinSurg',
  },
  {
    id: 12,
    name: 'DMSc or DMedSc',
    value: 'DMSc or DMedSc',
  },
  {
    id: 13,
    name: 'DPM',
    value: 'DPM',
  },
  {
    id: 14,
    name: 'DSurg or DS',
    value: 'DSurg or Ds',
  },
  {
    id: 15,
    name: 'MPHO',
    value: 'MPHO',
  },
  {
    id: 16,
    name: 'MPhO',
    value: 'MPhO',
  },
];
export const loggedUser = {
  _id: '64a2c1c45859654c64cac646',
  businessName: 'enyata hospital',
  businessEmailAddress: 'osita@enyata.com',
  countryCode: '+234',
  phoneNumber: '8033779715',
  providedServiceType: 'Urgent Care Center',
  GPINumber: 'GPI-00728060',
  status: 'active',
  businessDescription: 'No 13, Kudirat Street',
  logoUrl: '20230720-11185520230417-125758pouuch (1).png',
  websiteLink: 'www.enyataHospital',
  CACLicenseNumber: 'AU89000012',
  CACLicenseUrl: '20230720-113229ASHAOLU-DAYO-SAMUEL-RESUME_compressed.pdf',
  healthCareFacilities: [
    {
      country: 'Nigeria',
      state: 'Imo State',
      city: 'Owerri',
      address: 'aladinma',
      isPrimaryAddress: true,
      _id: '64b8e9765eb69cbe7c3bddc2',
    },
  ],
  contactInformation: [
    {
      firstName: 'Bade',
      lastName: 'Jones',
      emailAddress: 'badejones@gmail.com',
      phoneNumber: '34434',
      _id: '64aeb66e29c31abca14e85ac',
    },
  ],
  specialities: [
    {
      name: 'Cardiology',
      description: 'Deals with heart related diseases',
      _id: '64e8253a5fd834149971e3e9',
      createdAt: '2023-08-25T03:51:22.864Z',
      updatedAt: '2023-08-25T03:51:22.864Z',
    },
  ],
  bankDetails: [
    {
      accountNumber: '0076611916',
      accountName: 'CHINWEOKE G NWANKWO',
      bankName: 'Access Bank',
      bankCode: '044',
      _id: '6516dbb6dc1603ad11a99829',
    },
  ],
  refreshToken: '11b062178194e9a4116d3d838bd5b3a08b3694e427c78b4e2e3e46dad5ed1af4731d3bb441311faeac312186d9945cfb1d43',
  authToken:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGEyYzFjNDU4NTk2NTRjNjRjYWM2NDYiLCJpYXQiOjE2OTgxMzg4OTEsImV4cCI6MTY5ODE0MDY5MX0.KFTelRtnSg4xoqbhxtQXvOM1wKZKa1EQo-aJJLnYLVk',
  authTokenExpiry: '2023-10-24T09:44:51+00:00',
};
