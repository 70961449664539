import { useState } from "react"
import useMakeRequest from "hooks/useMakeRequest"
import useTryCatch from "hooks/useTryCatch"
import { createShift, deleteShift, deleteShiftStaff, getShift, getShifts, getStaffShifts, getSwapRequests } from "store/modules/shift/actions"

export default function useShift() {
    const { makeRequest, makeAnonymousRequest } = useMakeRequest()
    const [loading, setLoading] = useState(false)
    const { tryCatch } = useTryCatch()

    const create_shift = (formData) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: createShift({ payload: formData }) })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_shifts = ({ pathname, params }) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getShifts({ pathname, params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_shift = ({ pathname, params }) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getShift({ pathname, params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const delete_shift = (shiftId) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: deleteShift({ pathname: shiftId }) })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const delete_shift_staff = (pathname) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: deleteShiftStaff({ pathname }) })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const block_time_slot = (payload) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url: '/calendar/block-time', method: 'put', payload })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const swap_time_slot = ({ url, payload }) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'put', payload })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const swap_time_slot_request = ({ url, payload }) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'post', payload })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const add_staff = ({ url, payload }) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'patch', payload })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const edit_shift = ({ formData, shiftId }) => {
        const url = `/shift/${shiftId}`
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'patch', payload: formData })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_staff_shifts = ({ pathname, params }) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getStaffShifts({ pathname, params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_swap_requests = ({ pathname, params }) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getSwapRequests({ pathname, params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const update_swap_request_status = ({ swapRequestId, status }) => {
        const url = `/calendar/${swapRequestId}/change-status/${status}`
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'patch' })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    return {
        create_shift,
        get_shifts,
        get_shift,
        delete_shift,
        delete_shift_staff,
        block_time_slot,
        swap_time_slot,
        edit_shift,
        add_staff,
        get_staff_shifts,
        get_swap_requests,
        swap_time_slot_request,
        update_swap_request_status,
        loading
    }
}