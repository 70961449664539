import ResetPasswordForm from 'components/AuthForms/ResetPasswordForm'
import VerifyAccountForm from 'components/AuthForms/VerifyAccountForm'
import useResendOtp from 'hooks/useResendOtp'
import AuthLayout from 'layouts/AuthLayout'
import React from 'react'
import { useSearchParams } from 'react-router-dom'

const ResetPassword = () => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams]);
  const {timeLeft} = useResendOtp()
  
  return (
    <AuthLayout
    right={params.email ? <VerifyAccountForm timeLeft={timeLeft}/> : <ResetPasswordForm params={params} />
    }
    showBackButton={true}
  />
  )
}

export default ResetPassword