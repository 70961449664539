import React from 'react';
import { useSearchParams } from 'react-router-dom';
import TermsOfUse from './TermsOfUse';
import PrivacyPolicy from './PrivacyPolicy';

const TermsAndConditions = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type');

  let TabsHeader = ['Terms of use', 'Privacy policy'];
  return (
    <div className="md:px-32 py-6 px-6">
      <div className='sticky top-0 bg-neutral_white lg:pb-20'>
        <div className="flex flex-col justify-center items-center lg:w-[40%] mx-auto text-center">
          <div>
            <p className="text-brand_primary text-12 font-campton_m">Last updated on 13th November 2023</p>
            <p className="md:text-[48px] text-[38px] text-neutral_black font-campton_r mt-2.5">Legal</p>
          </div>
          <ul
            className="overflow-x-scroll xl:overflow-x-hidden scrollbar-hide overflow-scroll-none
            font-dmsans_m border border-neutral_stroke_2 flex justify-between items-center py-2 px-3 mb-6 rounded-full mt-6 w-full"
          >
            {TabsHeader?.map((tab) => (
              <li
                className={`tabs__item ${
                  type === tab.toLowerCase()
                    ? 'bg-accent_blue text-neutral_white font-campton_m  rounded-full px-6'
                    : 'font-campton_r text-neutral_body'
                } cursor-pointer py-2.5 lg:px-3 w-1/2`}
                data-testid="test-tab"
                onClick={() => setSearchParams({ type: tab.toLowerCase() })}
                key={tab}
              >
                <div className={`whitespace-no-wrap lg:text-14 text-12 capitalize  `}>{tab}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <section className="scroll-smooth overflow-y-scroll">
        {type === 'terms of use' ? <TermsOfUse /> : <PrivacyPolicy />}
      </section>
    </div>
  );
};

export default TermsAndConditions;
