import { SlideUp } from 'components/Animations';
import SubNavbar from 'components/SubNavbar';
import React, { useMemo, useState } from 'react';
import RadioButtonSelect from 'components/RadioButtonSelect';
import { TextArea } from 'components/Inputs';
import { Button } from 'components/Buttons';
import usePolicies from 'hooks/fetch/usePolicies';
import BillingPolicy from './Component/Forms/BillingPolicy';
import PropTypes from 'prop-types';
import { checkEmptyProperties } from 'utils/checkEmptyProperties';
import AppointmentPolicy from './Component/Forms/AppointmentPolicy';
import useTable from 'hooks/useTable';
import { useAuthState } from 'store/modules/auth';
import { usePolicyState } from 'store/modules/policies';

const CreatePolicy = ({ callback, type, action, setViewType }) => {
  const { create_billing_policy, loading, get_policies, get_current_policy, create_appointment_policy } = usePolicies();
  const { params } = useTable();
  const [policyType, setPolicyType] = useState('');
  const { currentPolicy } = usePolicyState();
  const { institutionId } = useAuthState();
  const [formData, setFormData] = useState({
    description: '',
    paymentPlan: {
      fullPayment: false,
      installment: false,
      installmentPercentage: 0,
    },
    paymentMethod: {
      cash: false,
      card: false,
      insurance: false,
    },
    daysAfterBilledDate: 0,
    latePayment: {
      choice: '',
      feePercentage: 0,
    },
  });
  const [appointmentFormData, setAppointmentFormData] = useState({
    cancellationFee: '',
    cancellationFeePercentage: 0,
    reschedulingTimeframe: '',
    reschedulingTimeframeDays: 0,
    lateArrivalPolicy: '',
    noShowPolicy: '',
    description: '',
  });

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCallback = () => {
    if (type) {
      setViewType('view');
    } else {
      callback();
    }
    get_current_policy(institutionId);
    get_policies(params);
  };

  const createPolicy = () => {
    (policyType || type) === 'billingPolicy'
      ? create_billing_policy({...formData, daysAfterBilledDate: Number(formData.daysAfterBilledDate)}, handleCallback)
      : create_appointment_policy(appointmentFormData, handleCallback);
  };

  const disabled = useMemo(() => {
    if ((policyType || type) === 'billingPolicy') return checkEmptyProperties(formData)
    if (appointmentFormData.cancellationFee === 'yes' && !appointmentFormData.cancellationFeePercentage) return true
    if (appointmentFormData.reschedulingTimeframe === 'yes' && !appointmentFormData.reschedulingTimeframeDays) return true
    return checkEmptyProperties(appointmentFormData)
  }, [appointmentFormData, policyType, formData, type])

  return (
    <>
      <SlideUp>
        {action === 'create' && <SubNavbar previous="Policies" current="New Policy" onClick={callback} />}
        <div className="p-6 flex justify-between flex-col h-full">
          <div>
            <p className="font-campton_m text-12 text-neutral_black mb-3">Policy Type</p>
            <div className="flex">
              {(!currentPolicy?.currentBillingPolicy || type === 'billingPolicy') && (
                <RadioButtonSelect
                  label="Billing Policy"
                  labelStyles="text-neutral_black text-14"
                  variant="mr-6"
                  isChecked={(policyType || type) === 'billingPolicy'}
                  onChange={() => setPolicyType('billingPolicy')}
                />
              )}
              {(!currentPolicy?.currentAppointmentPolicy || type === 'appointmentPolicy') && (
                <RadioButtonSelect
                  label="Appointment Policy"
                  variant="mr-6"
                  labelStyles="text-neutral_black text-14"
                  isChecked={(policyType || type) === 'appointmentPolicy'}
                  onChange={() => setPolicyType('appointmentPolicy')}
                />
              )}
            </div>
            <TextArea
              label="Enter description"
              type="text"
              id="description"
              name="description"
              placeholder="Enter description"
              variant="col-span-12 mt-4"
              readOnly={loading}
              value={
                (policyType || type) === 'billingPolicy' ? formData?.description : appointmentFormData?.description
              }
              onChange={(e) => {
                (policyType || type) === 'billingPolicy'
                  ? setFormData({ ...formData, description: e.target.value })
                  : setAppointmentFormData({ ...appointmentFormData, description: e.target.value });
              }}
            />
          </div>
          <>
            {(policyType || type) === 'billingPolicy' && (
              <BillingPolicy
                formData={formData}
                setFormData={setFormData}
                loading={loading}
                handleChange={handleChange}
                type={type}
                action={action}
              />
            )}
            {(policyType || type) === 'appointmentPolicy' && (
              <AppointmentPolicy
                formData={appointmentFormData}
                setFormData={setAppointmentFormData}
                loading={loading}
                handleChange={handleChange}
                action={action}
                type={type}
              />
            )}
          </>
          <div className="mt-1 space-x-4 md:fixed bottom-[50px] right-[50px] flex justify-end">
            <Button
              className="w-fit rounded-full h-[40px] font-campton_m text-14 hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary"
              theme="transparent"
              onClick={callback}
              name="Cancel"
            />
            <Button
              name={action === 'create' ? 'Create Policy' : 'Save'}
              theme="blue"
              className={`${action === 'create' ? 'w-fit' : 'w-[88px]'} h-[40px]`}
              onClick={createPolicy}
              loading={loading}
              disabled={disabled}
            />
          </div>
        </div>
      </SlideUp>
    </>
  );
};

export default CreatePolicy;
CreatePolicy.propTypes = {
  callback: PropTypes.func,
  type: PropTypes.string,
  setViewType: PropTypes.func,
  action: PropTypes.string,
};
