import { policies } from 'services/endpoints/policies'
import { createCustomAsyncThunk, customAsyncThunk } from 'utils/createThunk'

const getPolicies = createCustomAsyncThunk('policy/getAllPolicies', 'get', policies.get_policies)
const createBillingPolicy = customAsyncThunk('policy/createBillingPolicy', 'post', policies.create_billing_policy)
const createAppointmentPolicy = customAsyncThunk('policy/createAppointmentPolicy', 'post', policies.create_appointment_policy)
const getCurrentPolicy = createCustomAsyncThunk('policy/getSinglePolicy', 'get', policies.current_policy)

export {
    getPolicies,
    createBillingPolicy,
    getCurrentPolicy,
    createAppointmentPolicy
}