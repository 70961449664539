import React, { useEffect, useState, useContext, useMemo } from 'react';
import Medications from 'pages/Clinicals/Patients/AllPatients/PatientDetails/Components/Medications';
import useMedications from 'hooks/fetch/useMedications';
import { useParams } from 'react-router-dom';
import { VisitDetailsContext, PatientContext } from 'context';
import TreatmentPlanView from '../../TreatmentPlanView';
import Procedure from './Procedures';
import Therapies from './Therapies';
import Referrals from './Referrals';
import usePatient from 'hooks/fetch/usePatient';
import { useAuthState } from 'store/modules/auth';
import PropTypes from "prop-types"

const TreatmentPlan = ({ visitStatus }) => {
  const { get_medications } = useMedications()
  const { id } = useParams()
  const { institutionId } = useAuthState()
  const { singleVisit, canUpdate, progressNote, getSingleProgressNoteProxy } = useContext(VisitDetailsContext)
  const { get_procedure, get_referrals, get_therapies } = usePatient();
  const [view, setView] = useState('');
  const pathname = window.location.pathname
  
  const contextValues = useMemo(() => {
    return { progressNote, getSingleProgressNoteProxy }
  }, [progressNote])

  const views = {
    'Medications': <Medications callback={() => setView('')} view={view} singleVisit={singleVisit} visitStatus={visitStatus} canUpdate={canUpdate} />,
    'Procedure': <Procedure callback={() => setView('')} singleVisit={singleVisit} visitStatus={visitStatus} canUpdate={canUpdate} />,
    'Therapies': <Therapies callback={() => setView('')} singleVisit={singleVisit} visitStatus={visitStatus} canUpdate={canUpdate} />,
    'Referrals': <Referrals callback={() => setView('')} singleVisit={singleVisit} visitStatus={visitStatus} canUpdate={canUpdate} />
  }

  useEffect(() => {
    const params = {
      type: 'visit',
      visitId: id
    }
    if (!pathname.includes('progress-note')) {
      get_medications(params);
      get_procedure({ ...params, institutionId });
      get_referrals({ ...params, institutionId });
      get_therapies({ ...params, institutionId });
    }
  }, [])

  const View = views[view];

  return (
    <PatientContext.Provider value={contextValues}>
      <TreatmentPlanView view={view} setView={setView} View={View} />
    </PatientContext.Provider>
  );
};

export default TreatmentPlan;
TreatmentPlan.propTypes = {
  visitStatus: PropTypes.bool,
}
