import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import Dropdown from 'react-dropdown';

const RoundedDropdown = ({ label, options = [], onChange, defaultOption }) => {
  return (
    <div>
      <Dropdown
        options={options}
        onChange={onChange}
        value={`${label}: ${defaultOption?.label}`}
        className="roundedDropdown"
        controlClassName="roundedDropdownControl"
        placeholder="Select"
        arrowClosed={<ArrowDown fill="#205F74" />}
        arrowOpen={<ArrowUp fill="#205F74" />}
      />
    </div>
  );
};

RoundedDropdown.propTypes = {
  defaultOption: PropTypes.shape({
    label: PropTypes.any,
    value: PropTypes.string,
  }),
  label: PropTypes.any,
  onChange: PropTypes.any,
  options: PropTypes.any,
};

export default RoundedDropdown;
