import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import RoundedDropdown from 'components/Dropdown/RoundedDropdown';

const AddStaffDropdowns = ({ onChange }) => {
  const { rolesList } = useSelector((state) => state.roles);
  const { user } = useSelector((state) => state.auth);

  const all = {
    label: 'All',
    value: 'all',
  };

  const specialties = user?.specialties?.map((item) => {
    return { label: item?.name, value: item?.name?.toLowerCase() };
  });

  const locations = user?.healthCareFacilities?.map((location) => {
    return { label: location?.city, value: location?.city?.toLowerCase() };
  });

  const roles = rolesList?.map((role) => {
    return { name: role?.name, value: role?.name?.toLowerCase() };
  });

  if (specialties) {
    specialties?.unshift(all);
  }

  if (roles) {
    roles?.unshift(all);
  }

  if (locations) {
    locations?.unshift(all);
  }

  return (
    <div className="flex items-center gap-2">
      <RoundedDropdown label="Role" defaultOption={all} options={roles} onChange={(v) => onChange('role', v)} />

      <RoundedDropdown
        label="Specialty"
        defaultOption={all}
        options={specialties}
        onChange={(v) => onChange('specialty', v)}
      />

      <RoundedDropdown
        label="Location"
        defaultOption={all}
        options={locations}
        onChange={(v) => onChange('location', v)}
      />
    </div>
  );
};

AddStaffDropdowns.propTypes = {
  onChange: PropTypes.func,
  selectedLocation: PropTypes.any,
  selectedRole: PropTypes.any,
  selectedSpecialty: PropTypes.any,
};

export default AddStaffDropdowns;
