import React from 'react';
import PropTypes from 'prop-types';
import Card from 'pages/Onboarding/Components/Card';

const Onboarding = ({ steps }) => {
  return (
    <div className="grid grid-cols-12 gap-6">
      {steps.map((item) => (
        <Card
          variant={item?.variant}
          key={item.title}
          title={item.title}
          btnName={item.btnName}
          onClick={item.onclick}
          theme={item.theme}
          btnStyles={item.btnStyles}
          type={item?.type}
          description={item.description}
          icon=<img src={item.icon} alt={item.title}
          />
          condition={item.condition} 
        />
      ))}
    </div>
  );
};

export default Onboarding;
Onboarding.propTypes = {
  steps: PropTypes.array,
};
