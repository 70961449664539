import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { userInitals } from 'utils/ImagePlaceholder';
import Avatar from 'components/Avatar/Avatar';

const ViewReferral = ({ data }) => {
  return (
    <>
     <div className="border border-neutral_stroke_2 rounded-lg py-4 px-6 text-14 font-campton_r mb-6">
        <p className="font-campton_r text-12 text-neutral_body mb-4">Details</p>
        <div className="pb-4 border-b border-neutral_stroke_2">
          <p className="text-neutral_black">Referral Date</p>
          <p className="text-neutral_body">{dayjs(data?.createdAt).format('MMMM DD, YYYY')}</p>
        </div>
   
        <div className="pt-4 border-neutral_stroke_2">
          <p className="text-neutral_black">Reason</p>
          <p className="text-neutral_body">{data?.reason}</p>
        </div>
      </div>
      <div className="border border-neutral_stroke_2 p-4 rounded-2xl">
        <p className="text-neutral_body font-campton_r text-12 mb-4">Receiving Institution Information</p>
        <div className="flex justify-between items-start mt-4">
          <div className="flex">
            <Avatar
              url={data?.institutionInfo?.profilePic}
              width="32px"
              height="32px"
              textVariant="!text-12"
              alt={data?.institutionInfo?.businessName}
              initials={userInitals(data?.institutionInfo?.businessName?.split(' ')[0], data?.institutionInfo?.businessName?.split(' ')[1])}
              className="bg-brand_secondary mr-1.5 w-8 h-8"
            />
            <div className="font-campton_r">
              <p className="text-neutral_black text-14 capitalize">
                {`${data?.institutionInfo?.businessName}`}
              </p>
              <p className="text-12 text-neutral_body">{data?.institutionInfo?.emailAddress || 'N/A'}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="border border-neutral_stroke_2 p-4 rounded-2xl mt-6">
        <p className="text-neutral_body font-campton_r text-12 mb-4">Receiving Provider Information</p>
        <div className="flex justify-between items-start mt-4">
          <div className="flex">
            <Avatar
              url={data?.providerInfo?.profilePic}
              width="32px"
              height="32px"
              textVariant="!text-12"
              alt={data?.providerInfo?.firstName}
              initials={userInitals(data?.providerInfo?.firstName, data?.providerInfo?.lastName)}
              className="bg-brand_secondary mr-1.5 w-8 h-8"
            />
            <div className="font-campton_r">
              <p className="text-neutral_black text-14 capitalize">
                {`${data?.providerInfo?.firstName} ${data?.providerInfo?.lastName}`}
              </p>
              <p className="text-12 text-neutral_body">{data?.providerInfo?.emailAddress || 'N/A'}</p>
              <p className="text-12 text-neutral_body">
                {`${data?.providerInfo?.title ?? 'N/A'} | ${data?.providerInfo?.specialty ?? 'N/A'} | ${data?.providerInfo?.qualification}`}
              </p>
            </div>
          </div>
          <p className="text-12 text-neutral_body font-campton_r">{data?.providerInfo?.GPINumber ?? 'N/A'}</p>
        </div>
      </div>
    </>
  );
};

export default ViewReferral;
ViewReferral.propTypes = {
  callback: PropTypes.any,
  data: PropTypes.object,
};
