import { useState } from 'react';
import {
    getState,
    getCities,
    getCountries,
} from 'store/modules/auth/actions';
import useMakeRequest from 'hooks/useMakeRequest';
import useTryCatch from 'hooks/useTryCatch';

export default function useCountry() {
    const { makeRequest } = useMakeRequest();
    const { tryCatch } = useTryCatch();
    const [loading, setLoading] = useState(false);
    const [cities, setCities] = useState([])
    const [states, setStates] = useState([])

    const get_countries = async () => {
        return tryCatch(async () => {
            setLoading(true);
            return makeRequest({ action: getCountries(), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false));
        });
    };

    const get_states = async (formData) => {
        return tryCatch(async () => {
            setLoading(true);
            return makeRequest({ action: getState({ payload: formData }), alert: false })
                .then((res) => setStates(res?.data?.states || []))
                .finally(() => setLoading(false));
        });
    };

    const get_cities = async (formData) => {
        return tryCatch(async () => {
            setLoading(true);
            return makeRequest({ action: getCities({ payload: formData }), alert: false })
                .then((res) => setCities(res?.data || []))
                .finally(() => setLoading(false));
        });
    };

    return {
        get_countries,
        get_cities,
        get_states,
        cities,
        states,
        loading
    };
}
