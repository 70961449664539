import { createCustomAsyncThunk, customAsyncThunk } from 'utils/createThunk'
import { institution, patient } from '../../../services/endpoints'

const getPatients = createCustomAsyncThunk('patient/getPatients', 'get', patient.patients)
const addPatient = customAsyncThunk('patient/getPatients', 'post', institution.add_patient)
const getPatientsAnalytics = createCustomAsyncThunk('patient/getPatientsAnalytics', 'get', patient.get_patients_analytics)
const getInPatientsAnalytics = createCustomAsyncThunk('patient/getInPatientsAnalytics', 'get', patient.get_in_patients_analytics)
const getInPatients = createCustomAsyncThunk('patient/getInPatients', 'get', patient.in_patient)
const getHighRiskInPatients = createCustomAsyncThunk('patient/getHighRiskInPatients', 'get', patient.high_risk_in_patient)

// add_patient
export {
    getPatients,
    getPatientsAnalytics,
    getInPatientsAnalytics,
    getInPatients,
    addPatient,
    getHighRiskInPatients
}