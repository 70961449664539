import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/Buttons';
import { Input } from 'components/Inputs';
import { ReactComponent as Bank } from 'assets/icons/bank.svg';
import { ReactComponent as Check } from 'assets/icons/check-box-selected.svg';
import { ReactComponent as Uncheck } from 'assets/icons/check-box-unselected.svg';
import dayjs from 'dayjs';
import { useAuthState } from 'store/modules/auth';
import usePayments from 'hooks/fetch/usePayments';
import { formatCurrency } from 'utils/formatCurrency';
import Modal from 'components/Modals/Modal';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';

export default function ConfirmWithdraw({ amount, callback, closeModal }) {
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState(false);
  const { user, institutionId } = useAuthState();
  const { withdraw_funds, loading } = usePayments();

  const withdrawFunds = () => {
    withdraw_funds({
      institutionId,
      amount,
      password
    }, callback)
  }

  return (
    <Modal
      closeModal={closeModal}
      icon={<ArrowBack fill="#748181" className="w-2" />}
      styles="w-full md:w-[457px]"
      position="modal-right"
      variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
      title="Confirmation"
    >
      <div className="flex flex-col justify-between h-full">
        <div>
          <section className="pb-[24px] border-b border-dashed border-b-neutral_stroke_1">
            <div className="p-[24px] bg-primary_tint_50 border border-neutral_stroke_2 rounded-[16px] mb-[24px]">
              <div className="border-b border-b-neutral_stroke_2 pb-[12px]">
                <p className="text-12 text-neutral_body font-campton_r mb-[4px]">Amount</p>
                <p className="text-14 text-neutral_black font-campton_m">{formatCurrency(amount)}</p>
              </div>
              <div className="pt-[12px]">
                <p className="text-12 text-neutral_body font-campton_r mb-[4px]">Date</p>
                <p className="text-14 text-neutral_black font-campton_m">{dayjs(new Date()).format('DD MMM, YYYY')}</p>
              </div>
            </div>
            <div className={`rounded-[16px] p-[24px] w-full border border-neutral_stroke_2`}>
              <p className="text-14 text-neutral_body font-campton_r mb-[12px]">Destination</p>
              <div className="flex">
                <Bank />
                {user.bankDetails ?
                  <div className={`ml-[12px] text-14 text-neutral_black font-campton_m`}>
                    <p className="text-14 text-neutral_black font-campton_m">
                      {user?.bankDetails?.accountName}
                    </p>
                    <p className="text-14 text-neutral_black font-campton_r mr-[4px]">
                      <span className="border-r border-r-neutral_stroke_2 pr-[10px] mr-[10px]">{user?.bankDetails?.bankName}</span>{' '}
                      <span>**** {user?.bankDetails?.accountNumber.slice(-4)}</span>
                    </p>
                  </div> :
                  <p className="text-14 text-neutral_black font-campton_m mt-2 ml-2">
                    No bank details available</p>}
              </div>
            </div>
          </section>
          <div className="py-[24px]">
            <Input
              label="Password"
              type="password"
              id="password"
              name="password"
              readOnly={loading}
              value={password}
              showError={false}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex">
            <div data-testid='test-check' className="cursor-pointer" onClick={() => setConfirm(!confirm)}>
              {confirm ? <Check /> : <Uncheck />}
            </div>
            <p className="text-12 text-neutral_black font-campton_r leading-[18px] ml-[12px] mt-[2px]">
              By checking this box, you are authorizing this withdrawal and your signature will be attached
            </p>
          </div>
        </div>
        <div className="flex justify-end py-[20px]">
          <Button
            name="Withdraw Funds"
            theme="blue"
            loading={loading}
            disabled={!confirm || !password || !user.bankDetails}
            className="h-[40px] text-16 px-[12px] w-[147px]"
            onClick={withdrawFunds}
          />
        </div>
      </div>
    </Modal>
  );
}

ConfirmWithdraw.propTypes = {
  closeModal: PropTypes.func,
  amount: PropTypes.string,
  callback: PropTypes.func
};
