import { Button } from "components/Buttons";
import PropTypes from "prop-types"
import FormWrapper from "components/FormWrapper";
import { Input } from "components/Inputs";
import React from "react";
import { ReactComponent as Uncheck } from "assets/icons/check-box-unselected.svg";
import { ReactComponent as Check } from "assets/icons/check-box-selected.svg";
import { DefaultDatepicker } from 'components/Datepicker/Index';
import { payment_status } from "mocks/billAndPayments";
import Modal from "components/Modals/Modal";

const Filter = ({ cancel, params, setParams, func, loading }) => {
    const paymentMethods = ["cash", "electronic"]
    const handleAmount = (e) => {
        setParams({ ...params, [e.target.name]: e.target.value })
    }

    const selectPaymentStatus = (status) => {
        if (status === params.paymentStatus) return setParams({ ...params, paymentStatus: '' })
        setParams({ ...params, paymentStatus: status })
    }

    const selectPaymentMethod = (status) => {
        if (status === params.paymentMethod) return setParams({ ...params, paymentMethod: '' })
        setParams({ ...params, paymentMethod: status })
    }

    return (
        <Modal
            closeModal={cancel}
            styles="w-full md:w-[457px]"
            position="modal-right"
            title="Filter"
            variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
        >
            <FormWrapper className="relative">
                <div className="modal-right-body">
                    <section className="mb-[24px]">
                        <p className="text-12 text-neutral_black font-campton_m mb-[10px]">Amount Range</p>
                        <div className="grid grid-cols-2 gap-x-[16px]">
                            <div>
                                <Input
                                    label="From"
                                    type="text"
                                    id="startAmount"
                                    name="startAmount"
                                    readOnly={loading}
                                    value={params?.startAmount}
                                    measurement="NGN"
                                    onChange={handleAmount}
                                />
                            </div>
                            <div>
                                <Input
                                    label="To"
                                    type="text"
                                    id="endAmount"
                                    name="endAmount"
                                    readOnly={loading}
                                    value={params?.endAmount}
                                    measurement="NGN"
                                    onChange={handleAmount}
                                />
                            </div>
                        </div>
                    </section>
                    <section className="mb-[24px]">
                        <p className="text-12 text-neutral_black font-campton_m mb-[10px]">Bill Date Range</p>
                        <div className="grid grid-cols-2 gap-x-[16px]">
                            <div>
                                <DefaultDatepicker
                                    label="From"
                                    containerVariant="lg:col-span-6 col-span-12"
                                    startDate={params.fromDate}
                                    onSelect={(date) => setParams({ ...params, fromDate: date })}
                                    name="billFromDate"
                                />
                            </div>
                            <div>
                                <DefaultDatepicker
                                    label="To"
                                    containerVariant="lg:col-span-6 col-span-12"
                                    startDate={params.toDate}
                                    minDate={new Date(params.fromDate)}
                                    onSelect={(date) => setParams({ ...params, toDate: date })}
                                    name="billToDate"
                                />
                            </div>
                        </div>
                    </section>
                    <section className="mb-[24px]">
                        <p className="text-12 text-neutral_black font-campton_m mb-[10px]">Due Date Range</p>
                        <div className="grid grid-cols-2 gap-x-[16px]">
                            <div>
                                <DefaultDatepicker
                                    label="From"
                                    containerVariant="lg:col-span-6 col-span-12"
                                    startDate={params.fromDueDate}
                                    onSelect={(date) => setParams({ ...params, fromDueDate: date })}
                                    name="fromDueDate"
                                //value={formData?.startDate}
                                />
                            </div>
                            <div>
                                <DefaultDatepicker
                                    label="To"
                                    containerVariant="lg:col-span-6 col-span-12"
                                    startDate={params.toDueDate}
                                    minDate={new Date(params.fromDueDate)}
                                    onSelect={(date) => setParams({ ...params, toDueDate: date })}
                                    name="toDueDate"
                                />
                            </div>
                        </div>
                    </section>
                    <section className="mb-[24px]">
                        <p className="text-12 text-neutral_black font-campton_m mb-[10px]">Payment Status</p>
                        <div className="flex justify-between">
                            {payment_status.map(status =>
                                <div
                                    key={status.id}
                                    className="flex"
                                    onClick={() => selectPaymentStatus(status.value)}
                                >
                                    <div data-testid={status.name} className="cursor-pointer hide_tap">
                                        {params.paymentStatus === status.value ? <Check /> : <Uncheck />}
                                    </div>
                                    <p className="text-14 text-neutral_black font-campton_r ml-[8px] capitalize">{status.name}</p>
                                </div>
                            )}
                        </div>
                    </section>
                    <section>
                        <p className="text-12 text-neutral_black font-campton_m mb-[10px]">Payment Method</p>
                        <div className="grid grid-cols-2">
                            {paymentMethods.map(status =>
                                <div
                                    key={status}
                                    className="flex"
                                    onClick={() => selectPaymentMethod(status)}
                                >
                                    <div data-testid={status} className="cursor-pointer hide_tap">
                                        {params.paymentMethod === status ? <Check /> : <Uncheck />}
                                    </div>
                                    <p className="text-14 text-neutral_black font-campton_r ml-[8px] capitalize">{status}</p>
                                </div>
                            )}
                        </div>
                    </section>
                </div>
                <div className="modal-right-button-container">
                    <Button
                        name="Cancel"
                        theme="transparent"
                        type="button"
                        onClick={cancel}
                        className="h-[40px] text-16 w-[79px] mr-[16px]"
                    />
                    <Button
                        name="Filter"
                        theme="blue"
                        loading={loading}
                        onClick={func}
                        className="h-[40px] text-16 w-[128px]"
                    />
                </div>
            </FormWrapper>
        </Modal>
    )
}

export default Filter;

Filter.propTypes = {
    cancel: PropTypes.func,
    params: PropTypes.object,
    setParams: PropTypes.func,
    func: PropTypes.func,
    loading: PropTypes.bool
}