import { unwrapResult } from "@reduxjs/toolkit"
import { notify } from "../store/modules/global"
import { useDispatch } from "react-redux"
import useHandleError from "./useHandleError"
import { useNavigate } from "react-router-dom"
import instance from "services/axios-instance"
import { removeSpecificKeys } from "utils/removeSpecificKeys"

export default function useMakeRequest() {
    let dispatch = useDispatch()
    const { handleError } = useHandleError()
    let navigate = useNavigate();

    const checkResponse = ({ res, callback, successMessage, to, alert }) => {
        if (res?.message === 'Token expired' && res?.code === 401) return res
        if ([200, 201].includes(res?.code) || res?.status === "success" || (res.error !== undefined && !res.error)) {
            if (alert) dispatch(notify({ display: true, status: 'success', message: successMessage ?? res?.message }))
            if (to) navigate(to)
            if (callback) callback()
            return res
        }
        if (![200, 201].includes(res?.code) || !res?.error) {
            dispatch(
                notify({
                    display: true,
                    status: 'error',
                    message: handleError({ status: res?.code, message: res?.message || 'Internal Server Error' })
                })
            )
            return res
        }
    }

    const checkNetworkStatus = () => {
        if (!window.navigator.onLine) {
            dispatch(
                notify({
                    display: true,
                    status: 'error',
                    message: "No internet connection"
                })
            )
        }
    }

    const makeRequest = ({ action, callback, alert = true, successMessage, to }) => {
        checkNetworkStatus()
        return dispatch(action)
            .then(unwrapResult)
            .then(res => {
                if (!Object.keys(res).length) return;
                return checkResponse({ res, callback, successMessage, to, alert })
            })
            .catch(error => error)
    }

    const makeAnonymousRequest = async ({ url, method, alert = true, payload, params, callback }) => {
        checkNetworkStatus()
        try {
            const removeEmptyParams = removeSpecificKeys({ ...params })
            const body = { ...payload }
            const queryParams = params ? '?' + new URLSearchParams(removeEmptyParams).toString() : ''
            const response = await instance[method || 'get'](`${url}${queryParams}`, body)
            const data = response?.data || response?.response?.data

            return checkResponse({ res: data, callback, alert })
        } catch (error) {
            return error
        }
    }

    return { makeRequest, makeAnonymousRequest }
}