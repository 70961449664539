import React from "react"
import PropTypes from "prop-types"
import { Button } from "components/Buttons"
import { Search, TextArea } from "components/Inputs"
import Accordion from "components/Accordion"
import usePhysicalExamination from "../hooks/usePhysicalExamination"
import useVisit from "hooks/fetch/useVisit"
import { removeSpecificKeys } from "utils/removeSpecificKeys"

export default function CreateAndEdit({ purpose, type, setDisplay, id, examination, callback, examinationId }) {
    const { examinationTypes, handleChange } = usePhysicalExamination(examination)
    const { add_physical_examination, edit_physical_examination, loading } = useVisit()

    const submit = () => {
        const clonedExaminations = JSON.parse(JSON.stringify(examinationTypes))
        removeSpecificKeys(clonedExaminations, ['id', 'show', 'suggestions']) // remove specified keys
        const examinationsWithObservation = clonedExaminations.filter(item => item.observation !== '')
        const addPayload = {
            type,
            examination: examinationsWithObservation,
            [type]: id
        }
        const editPayload = { examination: examinationsWithObservation }
        const action = purpose !== 'edit' ?
            add_physical_examination({ payload: addPayload }) :
            edit_physical_examination({ payload: editPayload, id: examinationId })
        action.then(res => {
            if ([201, 200].includes(res?.code)) {
                callback()
                setDisplay()
            }
        })
    }

    return (
        <div className="flex flex-col justify-between h-full" onSubmit={submit}>
            <div>
                <p className="text-14 text-neutral_body font-campton_m mb-[16px]">Examine patient bodily part and note observations.</p>
                <Search
                    id="search"
                    name="search"
                    placeholder="Search by body part"
                //onChange={handleSearch}
                />
                <div className="mt-[16px] h-[299px] overflow-y-auto">
                    {examinationTypes.map((item) =>
                        <Accordion
                            key={item.id}
                            title={item.title}
                            iconPosition="left"
                            className="border-b border-neutral_stroke_2 py-[16px]"
                        >
                            <>
                                <div className="pt-[24px]">
                                    <TextArea
                                        type="text"
                                        id={item?.name}
                                        name={item?.name}
                                        label="observation"
                                        readOnly={loading}
                                        disabled={loading}
                                        value={item?.observation}
                                        onChange={(e) => handleChange(e.target.value, item.name)}
                                        rows={3}
                                    />
                                </div>
                                <ul className="flex flex-wrap">
                                    {item?.suggestions?.map(suggestion =>
                                        <li
                                            key={suggestion}
                                            data-testid={suggestion}
                                            className="text-12 text-neutral_black font-campton_r
                                        leading-[18px] bg-[#F2F3F3] py-[4px] px-[8px] mr-[8px] w-fit mb-[8px] cursor-pointer"
                                            onClick={() => handleChange(suggestion, item.name)}
                                        >
                                            {suggestion}
                                        </li>
                                    )}
                                </ul>
                            </>
                        </Accordion>
                    )}
                </div>
            </div>
            <div className="flex justify-end py-[20px]">
                <Button
                    name="Save"
                    theme="blue"
                    className={`h-[40px] text-16 px-[12px] w-[104px]`}
                    onClick={() => submit()}
                    loading={loading}
                    disabled={loading}
                />
            </div>
        </div>
    )
}

CreateAndEdit.propTypes = {
    callback: PropTypes.func,
    setDisplay: PropTypes.func,
    type: PropTypes.string,
    purpose: PropTypes.string,
    examination: PropTypes.array,
    id: PropTypes.string,
    examinationId: PropTypes.string
}