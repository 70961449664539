import React, { useEffect } from "react"
import Accordion from "components/Accordion"
import { VitalSignItem } from "components/Cards"
import PropTypes from "prop-types"
import Switch from "components/Switch"
import useTestTypes from "hooks/useTestTypes"

export default function TestDetails({ test }) {
    const { tests, updateTest } = useTestTypes()

    useEffect(() => {
        updateTest(test?.testType, test?.testProperties)
    }, [test])

    return (
        <Accordion
            title={test?.testType}
            display={false}
        >
            <>
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-[24px] pt-[24px] mb-[24px]">
                    {tests?.filter(t => t.value !== '').map(item =>
                        <VitalSignItem
                            key={item?.name}
                            name={item?.name}
                            value={item?.value}
                            label={item?.unit}
                        //hint="Range (4500 - 11000 WBCs)"
                        />
                    )}
                </div>
                <div>
                    {test?.testResult?.map(item => <img key={item} src={item} className="w-full h-auto mb-[24px]" />)}
                </div>
                <div className="flex items-center">
                    <Switch status={test?.isCritical ? 'active' : 'inactive'} />
                    <p className="text-14 text-neutral_black font-campton_r ml-[8px]">This lab test result is critical and requires immediate attention.</p>
                </div>
            </>
        </Accordion>
    )
}

TestDetails.propTypes = {
    test: PropTypes.object
}