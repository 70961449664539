import { createSlice } from '@reduxjs/toolkit';
import { getRoleAssignees, getRoles } from './actions';

const initialState = {
  roles: [],
  rolesList: [],
  assignees: [],
  loading: false,
};

export const roles = createSlice({
  name: 'roles',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.fulfilled, (state, action) => {
        state.roles = action?.payload?.data ?? [];
        state.rolesList = action?.payload?.data?.Roles ?? []
      })
      .addCase(getRoleAssignees.fulfilled, (state, action) => {
        state.assignees = action?.payload?.data?.assignees ?? [];
      });
  },
});

export default roles.reducer;
