import React, { useContext } from "react"
import { PatientContext } from "context"
import { ReactComponent as EmptyStateIcon } from "assets/svg/empty-surgeries.svg"
import AnimateList from "../AnimateList"
import { formatDate } from "utils/formatDate"
import PropTypes from 'prop-types';
import EmptyState from "components/Table/EmptyState";
import Layout from "../Layout";

export default function Surgeries({ view, setModal, callback, visitStatus, canUpdate = true }) {
  const { patient } = useContext(PatientContext)

  return (
    <Layout
      view={view}
      visitStatus={visitStatus && canUpdate}
      setModal={setModal}
      callback={callback}
    >
      <>
        {!view && <p className="text-14 text-primary_shade_100 font-campton_m mt-[24px]">Previous Surgeries/Hospitalization</p>}
        {patient?.medicalRecord?.surgeries?.length ?
          patient?.medicalRecord?.surgeries?.map((item, i) =>
            <AnimateList key={item?._id} index={i}>
              <div className="flex mt-[10px] border-b border-b-neutral_stroke_2 py-[24px]">
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.5" width="32" height="32" rx="16" fill="#EBF6FA" />
                  <path d="M21 13H9.5V11.5C9.5 11.3674 9.44732 11.2402 9.35355 11.1464C9.25979 11.0527 9.13261 11 9 11C8.86739 11 8.74021 11.0527 8.64645 11.1464C8.55268 11.2402 8.5 11.3674 8.5 11.5V21.5C8.5 21.6326 8.55268 21.7598 8.64645 21.8536C8.74021 21.9473 8.86739 22 9 22C9.13261 22 9.25979 21.9473 9.35355 21.8536C9.44732 21.7598 9.5 21.6326 9.5 21.5V19.5H22.5V21.5C22.5 21.6326 22.5527 21.7598 22.6464 21.8536C22.7402 21.9473 22.8674 22 23 22C23.1326 22 23.2598 21.9473 23.3536 21.8536C23.4473 21.7598 23.5 21.6326 23.5 21.5V15.5C23.5 14.837 23.2366 14.2011 22.7678 13.7322C22.2989 13.2634 21.663 13 21 13ZM9.5 14H14V18.5H9.5V14ZM15 18.5V14H21C21.3978 14 21.7794 14.158 22.0607 14.4393C22.342 14.7206 22.5 15.1022 22.5 15.5V18.5H15Z" fill="#36A1C5" />
                </svg>
                <div className="ml-[16px]">
                  <h3 className="text-16 text-neutral_black font-campton_m capitalize mb-[4px]">{item?.procedure}</h3>
                  <p className="text-14 text-neutral_body font-campton_r mb-[4px]">
                    Date: <span className="text-neutral_black capitalize">{formatDate(item?.date, 'number')}</span>
                  </p>
                </div>
              </div>
            </AnimateList>
          ) :
          <EmptyState
            title="No Previous Surgeries"
            icon={<EmptyStateIcon className="mb-[24px]" />}
          />
        }
      </>
    </Layout>
  )
}
Surgeries.propTypes = {
  callback: PropTypes.func,
  view: PropTypes.string,
  setModal: PropTypes.func,
  visitStatus: PropTypes.bool,
  canUpdate: PropTypes.bool
};