/* eslint-disable no-use-before-define */
import { Button } from 'components/Buttons';
import { DefaultDatepicker } from 'components/Datepicker/Index';
import FormWrapper from 'components/FormWrapper';
import { Input, TextArea } from 'components/Inputs';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { checkEmptyProperties } from 'utils/checkEmptyProperties';
import { useParams } from 'react-router-dom';
import Spinner from 'components/Spinner';
import Dropdown from 'components/Dropdown';
import useAddProcedure from 'hooks/useAddProcedure';
import usePatient from 'hooks/fetch/usePatient';
import { useSelector } from 'react-redux';
import { PatientContext } from 'context';

const AddProcedure = ({ callback, singleVisit }) => {
  const { id } = useParams();
  const patientId = singleVisit?.patient?._id
  const visitId = id
  const { institutionId } = useSelector(state => state.auth)
  const { get_procedure } = usePatient();
  const { progressNote, getSingleProgressNoteProxy } = useContext(PatientContext)

  const handleCallback = () => {
    callback();
    get_procedure({
      type: 'visit',
      visitId: progressNote?.visit ?? id,
      institutionId,
    });
    if(progressNote) {
    getSingleProgressNoteProxy()
    }
  };

  const {
    handleChange,
    handleSelect,
    providerName,
    createProcedure,
    procedureList,
    isFetchingProcedures,
    setProviderGPINumber,
    providerGPINumber,
    isFetchingProvider,
    loading,
    formData,
    setFormData
  } = useAddProcedure(handleCallback, patientId, visitId, progressNote)

  return (
    <FormWrapper className="flex justify-between flex-col h-full">
      <div className="grid">
        <div className="grid grid-cols-1 gap-2">
          <Dropdown
            type="select"
            id="procedure"
            label="Procedure"
            name="procedure"
            width="mb-3"
            loading={isFetchingProcedures}
            selected={formData?.procedureName}
            options={procedureList}
            onSelect={handleSelect}
          />
          <Input
            label="Procedure Assessment"
            type="text"
            id="procedureAssessment"
            name="preProcedureAssessment"
            readOnly={loading}
            value={formData?.preProcedureAssessment}
            onChange={handleChange}
          />
        </div>
        <DefaultDatepicker
          label="Procedure Date"
          startDate={formData?.procedureDate}
          minDate={new Date()}
          onSelect={(e) => setFormData({ ...formData, procedureDate: e })}
          containerVariant="mt-2"
          name="date"
          showCalender={true}
        />
        <DefaultDatepicker
          label="Procedure Time"
          startDate={formData.procedureTime}
          showTimeSelectOnly={true}
          containerVariant="mt-5"
          dateFormat="h:mm aa"
          showTimeSelect={true}
          timeCaption="Select Start time"
          onSelect={(date) => setFormData({ ...formData, procedureTime: date })}
          name="startTime"
          showTimeIcon={true}
        />
        <div className="grid gap-4 mt-5">
          <div>
            <Input
              label="Provider GPI-Number"
              type="text"
              id="gpiNumber"
              name="providerGPINumber"
              value={providerGPINumber}
              onChange={(e) => setProviderGPINumber(e.target.value)}
              measurement={isFetchingProvider ? <Spinner height="h-[10px]" width="w-[10px] mr-4" /> : ''}
            />
          </div>
          <div>
            <Input
              label='Provider Name'
              type="text"
              variant="capitalize"
              id="fullName"
              name="fullName"
              value={providerName}
              disabled={true}
            />
          </div>
        </div>
        <TextArea
          label="Add procedure Instructions"
          type="text"
          id="preProcedureInstructions"
          rows={4}
          variant="mt-5"
          name="preProcedureInstructions"
          readOnly={loading}
          value={formData?.preProcedureInstructions}
          onChange={handleChange}
        />
      </div>
      <div className="flex mt-1 space-x-4 justify-end">
        <Button
          className="w-20 h-10
          text-14 hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary  px-2 py-1"
          theme="transparent"
          onClick={callback}
          name="Cancel"
        />
        <Button
          name="Create procedure"
          theme="blue"
          loading={loading}
          disabled={checkEmptyProperties(formData) || !providerName}
          className="w-40 h-10 py-2 px-8"
          onClick={createProcedure}
        />
      </div>
    </FormWrapper>
  );
};

export default AddProcedure;
AddProcedure.propTypes = {
  singleVisit: PropTypes.object,
  callback: PropTypes.func,
};
