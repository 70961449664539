import CheckBox from "components/Inputs/CheckBox";
import Modal from 'components/Modals/Modal';
import RadioButtonSelect from 'components/RadioButtonSelect';
import React, { useState } from 'react';
import PropTypes from "prop-types"
import { Button } from "components/Buttons";
import useVisit from "hooks/fetch/useVisit";
import { useNavigate } from "react-router-dom";

const AddProgressNote = ({ closeModal, visitId, patientId, addedType }) => {
  const navigate = useNavigate()
  const [agreedToTerms, setAgreedToTerms] = useState(false)
  const [progressNoteType, setProgressNoteType] = useState(!addedType.includes('admissionNotes') ? 'admissionNotes' : '');
  const { add_progress_note, loading } = useVisit()

  const addProgressNote = () => {
    const payload = {
      type: progressNoteType,
      patient: patientId,
      visit: visitId
    }
    add_progress_note(payload)
      .then((res) => {
        if (res?.code === 201) navigate(`/clinicals/in-patients/${patientId}/progress-notes/${res?.data?._id}/add`)
      })
  }

  return (
    <Modal
      closeModal={closeModal}
      styles="w-full md:w-[411px]"
      title="Create Progress Note"
      variant="py-[14.5px] px-[24px] text-center border-b border-b-neutral_stroke_2 text-16 font-campton_m"
    >
      <div>
        <p className="font-campton_m text-14 text-neutral_black">Progress Note Type</p>
        <div className='mt-4 pb-6 border-b border-dashed border-neutral_stroke_2 mb-6'>
          <RadioButtonSelect
            label="Admission notes"
            variant="mr-2"
            isChecked={!addedType.includes('admissionNotes')}
            labelStyles={`${(addedType.includes('admissionNotes')) ? 'text-neutral_body' : 'text-neutral_black'} text-14`}
            disabled={addedType.includes('admissionNotes')}
            onChange={() => setProgressNoteType('admissionNotes')}
          />
          <RadioButtonSelect
            label="In-patient progress"
            isChecked={progressNoteType === 'inPatientProgress'}
            variant="mr-2 mt-3"
            disabled={!addedType.includes('admissionNotes')}
            labelStyles={`${!addedType.includes('admissionNotes') ? 'text-neutral_body' : 'text-neutral_black'} text-14`}
            onChange={() => setProgressNoteType('inPatientProgress')}
          />
          <RadioButtonSelect
            label="Discharge summary"
            isChecked={progressNoteType === 'dischargeSummary'}
            variant="mr-2 mt-3"
            labelStyles={`${!addedType.includes('admissionNotes') ? 'text-neutral_body' : 'text-neutral_black'} text-14`}
            disabled={!addedType.includes('admissionNotes')}
            onChange={() => setProgressNoteType('dischargeSummary')}
          />
          <RadioButtonSelect
            label="Addendum"
            isChecked={progressNoteType === 'addendum'}
            disabled={!addedType.includes('admissionNotes')}
            labelStyles={`${!addedType.includes('admissionNotes') ? 'text-neutral_body' : 'text-neutral_black'} text-14`}
            variant="mr-2 mt-3"
            onChange={() => setProgressNoteType('addendum')}
          />
        </div>
        <CheckBox
          variant="ml-0"
          label="By checking the box, your signature will be attached to this progress note created for this patient."
          value={agreedToTerms}
          id="terms"
          checked={agreedToTerms}
          onChange={() => {
            setAgreedToTerms(!agreedToTerms)
          }}
        />
        <div className="flex mt-10 justify-end">
          <Button
            name="Save"
            theme="blue"
            loading={loading}
            className="w-[62px] h-10"
            disabled={!agreedToTerms || !progressNoteType}
            onClick={addProgressNote}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddProgressNote;

AddProgressNote.propTypes = {
  closeModal: PropTypes.func,
  visitId: PropTypes.string,
  patientId: PropTypes.string,
  addedType: PropTypes.array
}
