export const vitals_list_header = ['Patient Name', 'Phone number', 'Date created', 'Recorded by', '']

export const vitals_list_data = [
    {
        _id: 1,
        firstName: 'Gabriel',
        lastName: 'Inyamah',
        gender: 'Male',
        phoneNumber: '+23490909090',
        created_at: 'May 27, 2020',
        recordedBy: 'Reddington Hospital',
    },
    {
        _id: 2,
        firstName: 'Success',
        lastName: 'Ologunsua',
        gender: 'Female',
        phoneNumber: '+23490909090',
        created_at: 'May 27, 2020',
        recordedBy: 'R-Jolad Hospital',
    },
    {
        _id: 3,
        firstName: 'Chinedu',
        lastName: 'Inyamah',
        gender: 'Male',
        phoneNumber: '+23490909090',
        created_at: 'May 27, 2020',
        recordedBy: 'Reddington Hospital',
    },
    {
        _id: 4,
        firstName: 'Gabriel',
        lastName: 'Inyamah',
        gender: 'Male',
        phoneNumber: '+23490909090',
        created_at: 'May 27, 2020',
        recordedBy: 'Reddington Hospital',
    },
    {
        _id: 5,
        firstName: 'Success',
        lastName: 'Inyamah',
        gender: 'Female',
        phoneNumber: '+23490909090',
        created_at: 'May 27, 2020',
        recordedBy: 'R-Jolad Hospital',
    },
    {
        _id: 6,
        firstName: 'Chinedu',
        lastName: 'Inyamah',
        gender: 'Male',
        phoneNumber: '+23490909090',
        created_at: 'May 27, 2020',
        recordedBy: 'Reddington Hospital',
    }
]

export const patient_vitals = {
    "_id": "646422fc2c29ddde8e324361",
    "firstName": "chinedu",
    "lastName": "ikechi",
    "emailAddress": "chinedu@gmail.com",
    "phoneNumber": "09058702557",
    "gender": "male",
    "homeAddress": "12a,Anthony Street",
    "country": "Ghana",
    "dateOfBirth": "1960-01-01T00:00:00.000Z",
    "gNumber": "G-00470992",
    "patientStatus": "active",
    "verificationToken": "",
    "verificationTokenExpiry": "",
    "refreshToken": "2e69af9e110a4a55174daa3c4dbdfd07fc46856c8002a763c05f618eac4826d9734a526143c2ba567ff17ea980a90d4322a3",
    "maritalStatus": "single",
    "createdAt": "2023-05-17T00:42:36.029Z",
    "updatedAt": "2023-05-17T00:43:50.650Z",
    "medicalRecord": {
        "_id": "646422fc2c29ddde8e324363",
        "patientId": "646422fc2c29ddde8e324361",
        "bloodType": {
            "bloodType": "O+",
            "genotype": "AA",
            "_id": "646422fc2c29ddde8e324364"
        },
        "allergies": [
            "asthma"
        ],
        "emergencyContact": [],
        "medicalDiagnosis": [],
        "surgeries": [],
        "immunizationHistory": [],
        "familyHistory": []
    }
}