import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { ReactComponent as Ellipsis } from 'assets/icons/ellipsis.svg';
import useOnClickOutside from 'hooks/useOnClickOutside';

export default function MoreOptions({ children, position, icon, className }) {
    const [show, setShow] = useState(false)
    const containerRef = useOnClickOutside(() => setShow(false))
    const IconType = icon || Ellipsis
    const iconRef = useRef(null)

    return (
        <div ref={containerRef} className={`${className || ''} relative`}>
            <IconType
                data-testid="more-options"
                className="cursor-pointer hide_tap"
                onClick={(e) => {
                    e.stopPropagation()
                    setShow(!show)
                }}
                ref={iconRef}
            />
            <div className={`absolute z-10 ${position} top-[14px] mt-[14px]`}>
                <motion.div
                    initial={{ opacity: 0, y: "20px" }}
                    animate={{ opacity: show ? 1 : 0, y: !show ? '20px' : 0 }}
                    exit={{ opacity: 0, y: '20px' }}
                    onClick={(e) => {
                        e.stopPropagation()
                        setShow(false)
                    }}
                    className={`${show ? '' : 'hidden'} bg-neutral_white p-[16px] rounded-[16px] text-14 font-campton_r options_shadow border border-neutral_stroke_1` }
                >
                    {children}
                </motion.div>
            </div>
        </div>
    )
}

MoreOptions.propTypes = {
    children: PropTypes.element,
    icon: PropTypes.any,
    position: PropTypes.string,
    className: PropTypes.string
}