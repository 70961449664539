import React, { useState, useEffect } from 'react';
import { SlideUp } from 'components/Animations';
import { ReactComponent as Wallet } from 'assets/icons/wallet1.svg';
import PropTypes from 'prop-types';
import Layout from 'components/Threads/Layout';
import Avatar from 'components/Avatar/Avatar';
import { Button } from 'components/Buttons';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { useParams, useSearchParams } from 'react-router-dom';
import useService from 'hooks/fetch/useService';
import { useServiceState } from 'store/modules/services';
import dayjs from 'dayjs';
import { formatCurrency } from 'utils/formatCurrency';
import { userInitals } from 'utils/ImagePlaceholder';
import Spinner from 'components/Spinner';

export default function SingleService({ editFunc, delFunc }) {
  const [selectedDetails, setSelectedDetails] = useState();
  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');
  const { serviceDetails } = useServiceState();
  const { get_service_details, loading } = useService();
  const { id } = useParams();

  useEffect(() => {
    get_service_details(service);
  }, []);

  return (
    <>
      {!serviceDetails || loading ? (
        <Spinner height="h-[80vh]" />
      ) : (
        <SlideUp>
          <div className="relative h-full sm:h-[80vh] overflow-hidden">
            <div className="flex md:flex-row flex-col w-full border-b border-dashed border-b-neutral_stroke_2 p-[24px] md:space-x-6 md:space-y-0 space-y-6">
              <section className="p-[24px] bg-[#F7FBFD] border border-[#153E4C14] rounded-[8px] sm:w-full">
                <p className="text-14 text-neutral_black mb-[8px] font-campton_m">{serviceDetails?.name}</p>
                <p className="text-12 text-neutral_body font-campton_r mb-[24px]">
                  {serviceDetails?.serviceProperties?.[0]?.description}
                </p>
                <div className="font-campton_r grid sm:grid-cols-3 gap-6">
                  <div className="md:border-r border-r-[#153E4C14]">
                    <p className="text-12 text-neutral_body mb-[4px]">Category</p>
                    <p className="text-14 text-neutral_black">{serviceDetails?.categoryId?.name}</p>
                  </div>
                  <div className="md:border-r border-r-[#153E4C14]">
                    <p className="text-12 text-neutral_body mb-[4px]">Date Created</p>
                    <p className="text-14 text-neutral_black">
                      {dayjs(serviceDetails?.createdAt).format('MMM DD, YYYY, hh:mm A')}
                    </p>
                  </div>
                  <div>
                    <p className="text-12 text-neutral_body mb-[4px]">Last Modified</p>
                    <p className="text-14 text-neutral_black">
                      {dayjs(serviceDetails?.updatedAt).format('MMM DD, YYYY, hh:mm A')}
                    </p>
                  </div>
                </div>
              </section>
              <section className="w-[323px] border border-[#153E4C14] rounded-[4px] font-campton_r flex flex-col shrink-0">
                <div className="py-[8px] px-[24px] h-full">
                  <Wallet className="mb-[12px]" />
                  <p className="text-12 text-neutral_body mb-[4px]">Fee (NGN)</p>
                  <p className="text-16 text-neutral_black">
                    {formatCurrency(serviceDetails?.serviceProperties?.[0]?.fee)}
                  </p>
                </div>
                <div className="border-t border-t-[#153E4C14] grid grid-cols-2">
                  <div className="py-[8px] px-[16px] border-r border-r-[#153E4C14]">
                    <p className="text-12 text-neutral_body">Grand total (NGN)</p>
                    <p className="text-14 text-neutral_black">
                      {formatCurrency(serviceDetails?.serviceProperties?.[0]?.totalFee)}
                    </p>
                  </div>
                  <div className="py-[8px] px-[16px]">
                    <p className="text-12 text-neutral_body">VAT (NGN)</p>
                    <p className="text-14 text-neutral_black">
                      {formatCurrency(serviceDetails?.serviceProperties?.[0]?.vat)}
                    </p>
                  </div>
                </div>
              </section>
            </div>
            <div className="px-[24px] pb-[80px] overflow-y-scroll">
              <h3 className="text-14 text-neutral_black font-campton_sb mb-[4px] mt-[20px]">Activity Timeline</h3>
              <p className="text-12 text-neutral_body font-campton_m mb-[24px]">
                This timeline shows users who has updated the billing policy over time
              </p>
              <div>
                {serviceDetails?.serviceProperties?.map((item) => (
                  <Layout
                    createdAt={dayjs(item.updatedAt).format('MMM DD, YYYY, hh:mm A')}
                    border={false}
                    key={item?._id}
                  >
                    <>
                      <p className="text-12 text-neutral_body font-campton_m mb-[4px]">Modified By</p>
                      <div className="flex md:flex-row flex-col justify-between items-center pb-[16px]">
                        <div className="flex space-x-2 mt-2">
                          <Avatar
                            url={item?.createdBy?.profilePic}
                            width="40px"
                            height="40px"
                            alt={
                              item?.createdBy?.firstName
                                ? item?.createdBy?.firstName?.split('')[0]
                                : item.createdBy?.businessName
                            }
                            initials={
                              item?.createdBy?.firstName !== ''
                                ? userInitals(item?.createdBy?.firstName, item?.createdBy?.lastName)
                                : userInitals(
                                    item?.createdBy?.businessName?.split(' ')[0],
                                    item?.createdBy?.businessName?.split(' ')[1],
                                  )
                            }
                            className="bg-brand_secondary w-8 h-8"
                          />
                          <div>
                            <p className="font-campton_m text-neutral_black text-14 capitalize">
                              {item?.createdBy?.firstName !== ''
                                ? `${item?.createdBy?.firstName + ' ' + item?.createdBy?.lastName}`
                                : `${item?.createdBy?.businessName}`}
                            </p>
                            <p className="text-12 font-campton_r text-neutral_body mt-0.5">
                              {item?.createdBy?.emailAddress !== '' ? item?.createdBy?.emailAddress : item?.createdBy?.businessEmailAddress}
                            </p>
                          </div>
                        </div>
                        <Button
                          className="w-fit rounded-full h-8 font-campton_m text-14 hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary md:mt-0 mt-2"
                          theme="transparent"
                          onClick={() => setSelectedDetails(item._id)}
                          name="View older version"
                        />
                      </div>
                      {selectedDetails === item._id && (
                        <div className="grid grid-cols-2 md:grid-cols-4 py-[16px] border-t border-t-neutral_stroke_2 relative font-campton_r">
                          <span
                            className="bg-primary_shade_300 p-2 h-6 w-6 rounded-full cursor-pointer hide_tap flex items-center justify-center absolute -top-[12px] right-0"
                            data-testid='close'
                            onClick={() => setSelectedDetails('')}
                          >
                            <Close fill="#FFFFFF" />
                          </span>
                          <div className="mr-[24px] border-r border-r-[#153E4C14]">
                            <p className="text-12 text-neutral_body mb-[4px]">Category</p>
                            <p className="text-14 text-neutral_black">{id}</p>
                          </div>
                          <div className="mr-[24px] border-r border-r-[#153E4C14]">
                            <p className="text-12 text-neutral_body mb-[4px]">Fee (NGN)</p>
                            <p className="text-14 text-neutral_black">{formatCurrency(item?.fee)}</p>
                          </div>
                          <div className="mr-[24px] border-r border-r-[#153E4C14]">
                            <p className="text-12 text-neutral_body mb-[4px]">Date Created</p>
                            <p className="text-14 text-neutral_black">
                              {dayjs(item?.createdAt).format('MMM DD, YYYY, hh:mm A')}
                            </p>
                          </div>
                          <div>
                            <p className="text-12 text-neutral_body mb-[4px]">Last Modified</p>
                            <p className="text-14 text-neutral_black">
                              {dayjs(item?.updatedAt).format('MMM DD, YYYY, hh:mm A')}
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  </Layout>
                ))}
              </div>
            </div>
            <div className="flex justify-end w-full py-[16px] px-[24px] absolute left-0 bottom-0 bg-neutral_white">
              <Button name="Delete" theme="transparent" className="w-[112px] h-[40px] mr-[16px]" onClick={delFunc} />
              <Button name="Edit" theme="blue" onClick={editFunc} className="w-[112px] h-[40px]" />
            </div>
          </div>
        </SlideUp>
      )}
    </>
  );
}
SingleService.propTypes = {
  editFunc: PropTypes.func,
  delFunc: PropTypes.func,
};
