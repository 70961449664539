import Navbar from 'components/Navbar';
import React, { useEffect, useMemo, useState } from 'react';
import SummaryCards from './Components/SummaryCards';
import HorizontalMenu from 'components/HorizontalMenu';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useDebounce from 'hooks/useDebounce';
import useAppointments from 'hooks/fetch/useAppointments';
import useTable from 'hooks/useTable';
import { useAuthState } from 'store/modules/auth';
import { SlideUp } from 'components/Animations';
import Table from 'components/Table';
import Modal from 'components/Modals/Modal';
import { useAppointmentState } from 'store/modules/appointment';
import { appointment_list_header } from 'mocks/appointment';
import AppointmentTableRow from './Components/AppointmentTableRow';
import ViewAppointment from './ViewAppointment';
import EditAppointment from './EditAppointment';
import Filter from './Components/Filter';
import dayjs from 'dayjs';
import Restricted from 'HOC/Restricted';

const Appointments = () => {
  const [filterParams, setFilterParams] = useState({
    type: '',
    status: '',
    fromDate: '',
    toDate: '',
  });
  const navigate = useNavigate();
  const { user, institutionId } = useAuthState();
  const [type, setType] = useState('');
  const { appointments, appointmentRequest } = useAppointmentState();
  const [searchParams] = useSearchParams();
  const category = searchParams.get('category');
  const [appointmentList, setAppointmentList] = useState([]);
  const { params, selectLimit, handleSearch, changePage, openFilterModal, closeFilterModal, filterModal } = useTable();
  const { debouncedValue } = useDebounce(params.search);
  const { get_institution_appointments, get_provider_appointments, get_appointment_request, loading } = useAppointments();
  const [selectedAppointment, setSelectedAppointment] = useState({
    patientInfo: {
      id: '64662cf2e3d4a6154dddbc9e',
      firstName: 'Johnson',
      lastName: 'Akinpelu',
      emailAddress: 'dunniakinpelu@outlook.com',
      gNumber: 'G-00718995',
    },
  });

  const tabsHeader = [
    {
      id: 0,
      name: 'all appointments',
      method: () => navigate(''),
    },
    {
      id: 1,
      name: 'cancelled requests',
      method: () => navigate('?category=cancelled'),
    },
    {
      id: 2,
      name: 'rescheduled requests',
      method: () => navigate('?category=rescheduled'),
    },
  ];

  const newParams = {
    ...params,
    requestType: user?.type === 'staff' ? 'provider' : 'institution',
    providerId: user?.type === 'staff' ? user?._id : '',
    institutionId
  };

  const handleSingleView = (item, type) => {
    setSelectedAppointment(item);
    setType(type);
  };

  const currentTab = useMemo(() => {
    if (category === 'cancelled') return 'cancelled requests';
    if (category === 'rescheduled') return 'rescheduled requests';
    return 'all appointments';
  }, [category]);

  useEffect(() => {
    switch (currentTab) {
      case 'cancelled requests':
        get_appointment_request(`${institutionId}/appointment/cancel-requests`, params).then((res) =>
          setAppointmentList(res?.data?.cancelRequests || undefined),
        );
        break;
      case 'rescheduled requests':
        get_appointment_request(`${institutionId}/reschedule/requests`, newParams).then((res) =>
          setAppointmentList(res?.data?.rescheduleRequests || undefined),
        );
        break;
      default:
        if (institutionId) {
          const getAppointments = user?.type !== "staff" ? get_institution_appointments : get_provider_appointments;
          getAppointments(newParams).then((res) => setAppointmentList(res?.data?.appointments || undefined));
        }
    }
  }, [debouncedValue, params.page, params.limit, currentTab]);

  const filterAppointment = () => {
    closeFilterModal()
    switch (currentTab) {
      case 'cancelled requests':
        get_appointment_request(`${institutionId}/appointment/cancel-requests`, {
          ...params,
          ...filterParams,
          fromDate: filterParams.fromDate ? dayjs(filterParams.fromDate).format('YYYY-MM-DD') : '',
          toDate: filterParams.toDate ? dayjs(filterParams.toDate).format('YYYY-MM-DD') : '',
        }).then((res) => setAppointmentList(res?.data?.cancelRequests || undefined));
        break;
      case 'rescheduled requests':
        get_appointment_request(`${institutionId}/reschedule/requests`, {
          ...newParams,
          ...filterParams,
          fromDate: filterParams.fromDate ? dayjs(filterParams.fromDate).format('YYYY-MM-DD') : '',
          toDate: filterParams.toDate ? dayjs(filterParams.toDate).format('YYYY-MM-DD') : '',
        }).then((res) => setAppointmentList(res?.data?.rescheduleRequests || undefined));
        break;
      default:
        if (institutionId) {
          const getAppointments = user?.businessName ? get_institution_appointments : get_provider_appointments;
          getAppointments({
            ...newParams,
            ...filterParams,
            fromDate: filterParams.fromDate ? dayjs(filterParams.fromDate).format('YYYY-MM-DD') : '',
            toDate: filterParams.toDate ? dayjs(filterParams.toDate).format('YYYY-MM-DD') : '',
          }).then((res) => setAppointmentList(res?.data?.appointments || undefined));
        }
    }
  };

  return (
    <div>
      <Navbar title="Appointments" />
      <HorizontalMenu
        tabs={tabsHeader}
        currentTab={currentTab}
        containerClass="sticky z-20 top-[76.5px] sm:top-[79px]"
      />
      <Restricted module='Appointment' permission='View'>
        <>
          <SlideUp>
            <SummaryCards />
            <div className="p-6">
              <Table
                title={`${currentTab.includes('requests') ? currentTab : 'Appointment'} List`}
                buttonName="New Appointment"
                totalCount={currentTab.includes('requests') ? appointmentRequest?.total : appointments?.allCount}
                headers={
                  user?.businessName
                    ? appointment_list_header
                    : appointment_list_header?.filter((item) => !item.includes('Provider'))
                }
                data={appointmentList}
                handleSearch={handleSearch}
                filterFnc={openFilterModal}
                selectLimit={selectLimit}
                changePage={changePage}
                buttonFnc={() => navigate('/appointments/new-appointment')}
                currentPage={currentTab.includes('requests') ? appointmentRequest?.page : appointments?.page}
                perPage={currentTab.includes('requests') ? appointmentRequest?.limit : appointments?.limit}
                loading={loading}
                module="Appointment"
                permission="Book appointment"
                searchPlaceholder="Search by patient/provider’s name, ID..."
              >
                <>
                  {appointmentList?.map((item) => (
                    <AppointmentTableRow
                      key={item?._id}
                      user={user}
                      item={item}
                      selectedTab={currentTab}
                      handleSingleView={handleSingleView}
                    />
                  ))}
                </>
              </Table>
            </div>
          </SlideUp>
          {type && (
            <Modal
              closeModal={() => setType('')}
              styles="w-full md:w-[457px]"
              position="modal-right"
              variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
              title={`${type} ${type === 'reschedule' ? 'Appointment' : ''} ${!user?.businessName || type === 'view' ? 'Request' : ''
                }`}
            >
              {type === 'view' ? (
                <ViewAppointment
                  type={currentTab}
                  data={selectedAppointment}
                  callback={() => setType('')}
                  setAppointmentList={setAppointmentList}
                  params={newParams}
                />
              ) : (
                <EditAppointment
                  data={selectedAppointment}
                  callback={() => setType('')}
                  type={type}
                  setAppointmentList={setAppointmentList}
                  params={newParams}
                />
              )}
            </Modal>
          )}
          {filterModal && (
            <Filter
              closeModal={closeFilterModal}
              params={filterParams}
              setParams={setFilterParams}
              loading={loading}
              func={filterAppointment}
            />
          )}
        </>
      </Restricted >
    </div >
  );
};

export default Appointments;
