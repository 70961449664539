import React from 'react';
import { Input } from 'components/Inputs';
import Phone from 'components/Inputs/PhoneInput';
import Dropdown from 'components/Dropdown';
import PropTypes from 'prop-types';
import { institutionTypes } from 'mocks/auth';

const InstitutionAccountSetup = ({ formData, setFormData, phone, setPhone }) => {

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <div className="mb-6 pt-6">
        <Input
          label="Business Name"
          type="text"
          id="businessName"
          name="businessName"
          value={formData?.businessName}
          onChange={handleChange}
          showError={false}
        />
      </div>
      <div className="mb-6">
        <Input
          label="Business Email Address"
          type="email"
          id="businessEmailAddress"
          name="businessEmailAddress"
          value={formData?.businessEmailAddress}
          onChange={handleChange}
        />
      </div>
      <Phone value={phone} onChange={setPhone} />
      <Dropdown
        type="select"
        id="providedServiceType"
        placeholder="Type of institutions"
        name="providedServiceType"
        options={institutionTypes}
        selected={formData?.providedServiceType}
        onSelect={(data) => {
          setFormData({ ...formData, providedServiceType: data.value });
        }}
      />
    </div>
  );
};

export default InstitutionAccountSetup;
InstitutionAccountSetup.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  phone: PropTypes.object,
  setPhone: PropTypes.func
};
