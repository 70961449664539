import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = (date, type = 'string') => {
  if (!date) return null
  if (type === 'string') {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = new Date(date).getDate();
    const month = months[new Date(date).getMonth()];
    const year = new Date(date).getFullYear();
    return `${day} ${month} ${year}`;
  }
  return new Date(date)?.toISOString()?.split("T")[0]
}

export const formatToDateWithDash = (dateString) => {
  if (!dateString) return null
  return dayjs(dateString).format('YYYY-MM-DD');
}

export const getMonth = (month) => {
  if (!month) return null
  return new Date(month).getMonth();
}

export const getYear = (year) => {
  if (!year) return null
  return dayjs(new Date(year)).format('YYYY')
}

const convertTo24HourFormat = (time12Hour) => {
  if (!time12Hour) return null
  const timeParts = time12Hour?.split(':')
  let hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1].split(' ')[0]);
  const meridiem = timeParts[1].split(' ')[1];

  if (meridiem === 'PM' && hours < 12) {
    hours += 12;
  } else if (meridiem === 'AM' && hours === 12) {
    hours = 0;
  }

  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  return formattedTime;
}

export const insertTimeToDate = (date, time) => {
  if (!date || !time) return null
  const T = date?.replace('00:00:00.', `${convertTo24HourFormat(time)?.split(' ')[0]}:00.`);
  const D = dayjs(T);
  return D.subtract(1, 'hour')
}

export const isTimeInRange = (appointmentDate, startTime, endTime) => {
  if (!appointmentDate || !startTime || !endTime) return null
  const startT = convertTo24HourFormat(startTime)
  const endT = convertTo24HourFormat(endTime)
  const startDateTime = insertTimeToDate(appointmentDate, startT)
  const endDateTime = insertTimeToDate(appointmentDate, endT)
  const currentDate = dayjs(dayjs().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'))

  if (!startDateTime.isSame(currentDate, 'day')) return false
  if (currentDate.isAfter(startDateTime) && currentDate.isBefore(endDateTime)) return true

  return false
}

export const isAppointmentDate = (appointmentDate, startTime) => {
  if (!appointmentDate || !startTime) return null
  const appointmentDateTime = insertTimeToDate(appointmentDate, startTime)
  const currentDate = dayjs(dayjs().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'))

  if (!appointmentDateTime.isSame(currentDate, 'day')) return false
  return true
}

export function getLocalTimeFromUTC(utcDateStr) {
  const currentTimezone = dayjs.tz.guess();
  return dayjs.tz(utcDateStr, currentTimezone).toDate();
}