import React, { useContext, useEffect, useState } from "react";
import { SlideUp } from "components/Animations";
import { Button } from "components/Buttons";
import AbnormalFindings from "./AbnormalFindings";
import TestType from "./TestType";
import FormWrapper from "components/FormWrapper";
import { removeSpecificKeys } from "utils/removeSpecificKeys";
import useLabTest from "hooks/fetch/useLabTest";
import { LabTestDetailsContext } from "context";
import useTestTypes from "hooks/useTestTypes";
import dayjs from "dayjs";
import useUpload from "hooks/useUpload";

export default function Edit() {
    const [file, setFile] = useState([])
    const { upload, uploading } = useUpload(false)
    const { setTab, labTest, getTestDetails } = useContext(LabTestDetailsContext)
    const { tests, filterTestWithValue, handleTestChange, updateTest } = useTestTypes(labTest?.testType)
    const { update_lab_test, loading } = useLabTest()
    const [abnormalFindings, setAbnormalFindings] = useState('')
    const [isCritical, setIsCritical] = useState(false)

    const toggleCritical = () => {
        setIsCritical(!isCritical)
    }

    useEffect(() => {
        if (labTest?.testProperties) updateTest(labTest?.testType, labTest?.testProperties)
        setIsCritical(labTest?.isCritical)
        setAbnormalFindings(labTest?.abnormalFindings)
    }, [])

    const uploadSelectedFiles = async (file) => {
        const timestamp = dayjs()?.format('YYYYMMDD-HHmmss')
        const result = await Promise.all(
            file.map(async (item) => {
                const res = await upload(item, { "file": `${timestamp}${item?.name}` }, timestamp)
                return res?.imageUrl
            })
        )
        return result
    }

    const submit = async () => {
        try {
            const clonedTestTypes = JSON.parse(JSON.stringify(filterTestWithValue))
            const result = removeSpecificKeys(clonedTestTypes, ['isCritical'])
            const convertedObject = {}
            result.forEach(item => convertedObject[item.name] = item.value)
            if (file?.length) {
                const arrayOfFilesURL = await uploadSelectedFiles(file)
                const payload = removeSpecificKeys({ abnormalFindings, testProperties: convertedObject, isCritical, testResult: arrayOfFilesURL })
                update_lab_test({ pathname: `${labTest?._id}/lab-test`, payload })
                    .then(res => {
                        if (res?.code === 200) {
                            getTestDetails()
                            setTab('view')
                        }
                    })
            } else {
                const payload = removeSpecificKeys({ abnormalFindings, testProperties: convertedObject, isCritical })
                update_lab_test({ pathname: `${labTest?._id}/lab-test`, payload })
                    .then(res => {
                        if (res?.code === 200) {
                            getTestDetails()
                            setTab('view')
                        }
                    })
            }
        } catch (error) {
            return error
        }
    }

    return (
        <SlideUp>
            <FormWrapper className="px-[24px] pb-[20px]" onSubmit={submit}>
                <TestType
                    file={file}
                    setFile={setFile}
                    tests={tests}
                    testName={labTest?.testType}
                    isCritical={isCritical}
                    handleChange={handleTestChange}
                    toggleCritical={toggleCritical}
                    loading={loading}
                />
                <AbnormalFindings
                    abnormalFindings={abnormalFindings}
                    setAbnormalFindings={setAbnormalFindings}
                    loading={loading}
                />
                <div className="flex justify-end mt-[55px]">
                    <Button
                        name="Cancel"
                        theme="transparent"
                        onClick={() => setTab('view')}
                        disabled={loading}
                        className="h-[40px] w-[112px] text-16 px-[12px] mr-[16px]"
                    />
                    <Button
                        name="Save"
                        theme="blue"
                        className="h-[40px] w-[112px] text-16 px-[12px]"
                        disabled={loading}
                        loading={loading || uploading}
                    />
                </div>
            </FormWrapper>
        </SlideUp>
    )
}