import React, { useEffect, useMemo, useState } from "react";
import { SlideUp } from "components/Animations";
import Avatar from "components/Avatar/Avatar";
import Table from "components/Table";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg"
import { vitals_list_header } from "mocks/clinicals/vital-signs";
import { useNavigate } from "react-router-dom";
import Navbar from "components/Navbar";
import useTable from "hooks/useTable";
import useVitals from "hooks/fetch/useVitals";
import { useSelector } from "react-redux";
import useDebounce from "hooks/useDebounce";
import { formatDate, formatToDateWithDash } from "utils/formatDate";
import Filter from "./Components/Filter";
import { removeSpecificKeys } from "utils/removeSpecificKeys";
import Restricted from "HOC/Restricted";

export default function VitalSigns() {
    const navigate = useNavigate()
    const { vitals } = useSelector(state => state.clinicals)
    const { institutionId } = useSelector(state => state.auth)
    const { get_vitals, loading } = useVitals()
    const { params, selectLimit, handleSearch, changePage, openFilterModal, closeFilterModal, filterModal } = useTable()
    const { debouncedValue } = useDebounce(params.search)
    const [filterParams, setFilterParams] = useState({ startDate: '', endDate: '' })
    const [addFilter, setAddFilter] = useState(false)

    useEffect(() => {
        if (addFilter) {
            const filtered = removeSpecificKeys(filterParams) // remove undefined keys
            const convertedDate = Object.fromEntries(Object.entries(filtered).map(([key, value]) => [key, formatToDateWithDash(value)]))
            const newParams = { ...params, ...convertedDate }
            get_vitals({ id: `${institutionId}/vitals`, params: newParams })
            return
        }
        get_vitals({ id: `${institutionId}/vitals`, params })
    }, [debouncedValue, params.page, params.limit, addFilter, filterParams])

    useEffect(() => {
        document.title = 'Vital Signs - Gabbi Health'
    }, [])

    const filters = useMemo(() => {
        const filtered = removeSpecificKeys(filterParams) // remove undefined keys
        const convertedDate = Object.fromEntries(Object.entries(filtered).map(([key, value]) => [key, formatToDateWithDash(value)]))
        return addFilter ? Object.entries(convertedDate) : []
    }, [filterParams, addFilter])

    const clearFilters = () => {
        setFilterParams({ startDate: '', endDate: '' })
    }

    return (
        <>
            <Navbar title="Vital Sign Tracking" />
            <Restricted module="Vital Signs" permission="View vitals">
                <>
                    <SlideUp>
                        <div className="p-[24px]">
                            <Table
                                title="Vitals"
                                headers={vitals_list_header}
                                data={vitals?.vitals}
                                filterFnc={openFilterModal}
                                totalCount={vitals?.total}
                                perPage={vitals?.limit}
                                currentPage={vitals?.page}
                                selectLimit={selectLimit}
                                changePage={changePage}
                                loading={loading}
                                filters={filters}
                                clearFilters={clearFilters}
                                handleSearch={handleSearch}
                                module="Vital signs"
                                permission="Add new vitals"
                                buttonName="New Vitals"
                                buttonFnc={() => navigate("/clinicals/vital-signs/new")}
                            >
                                <>
                                    {vitals?.vitals?.map((item) =>
                                        <tr
                                            data-testid={item?._id}
                                            key={item?._id}
                                            className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                                            onClick={() => navigate(`/clinicals/vital-signs/${item?._id}`)}
                                        >
                                            <td className="px-[10px] py-[8px] flex items-center capitalize">
                                                <Avatar
                                                    url={item?.patient?.profilePic}
                                                    width="40px"
                                                    height="40px"
                                                    alt={item?.patient?.firstName?.split('')[0]}
                                                    initials={`${item?.patient?.firstName?.split('')[0] || ''}${item?.patient?.lastName?.split('')[0] || ''}`}
                                                    className="mr-[15px] w-[40px] h-[40px]"
                                                />
                                                {item?.patient?.firstName} {item?.patient?.lastName}
                                            </td>
                                            <td className="px-[10px] py-[8px] capitalize">{item?.patient?.countryCode}{item?.patient?.phoneNumber}</td>
                                            <td className="px-[10px] py-[8px] capitalize">{formatDate(item?.createdAt)}</td>
                                            <td className="px-[10px] py-[8px] capitalize">{item?.recordedBy?.firstName} {item?.recordedBy?.lastName}</td>
                                            <td className="px-[10px] py-[8px] w-[20px]"><ArrowRight fill="#A9B2B2" /></td>
                                        </tr>
                                    )}
                                </>
                            </Table>
                        </div>
                    </SlideUp>
                    {filterModal &&
                        <Filter
                            closeFilterModal={closeFilterModal}
                            filterParams={filterParams}
                            setFilterParams={setFilterParams}
                            setAddFilter={() => setAddFilter(true)}
                        />
                    }
                </>
            </Restricted>
        </>
    )
}