import React, { useContext } from 'react';
import Avatar from 'components/Avatar/Avatar';
import { PatientContext } from 'context';
import { ReactComponent as EmptyStateIcon } from 'assets/svg/empty-emergency-contact.svg';
import EmptyState from "components/Table/EmptyState"
import AnimateList from '../AnimateList';
import Status from 'components/Status';
import PropTypes from 'prop-types';
import Layout from '../Layout';

export default function EmergencyContact({ view, callback, setModal, visitStatus, canUpdate = true }) {
  const { patient } = useContext(PatientContext);

  return (
    <Layout
      view={view}
      visitStatus={visitStatus && canUpdate}
      setModal={setModal}
      callback={callback}
    >
      <>
        {patient?.medicalRecord?.emergencyContact?.length ? (
          patient?.medicalRecord?.emergencyContact?.map((item, i) => (
            <AnimateList key={item?._id} index={i} className=''>
              <div className="flex mt-[10px] border-b 
              border-b-neutral_stroke_2 pb-6 pt-6 first-of-type:pt-0 first-of-type:mt-6">
                <Avatar
                  width="40px"
                  height="40px"
                  alt={item?.firstName?.split('')[0]}
                  initials={`${item?.firstName?.split('')[0] || ''}${item?.lastName?.split('')[0] || ''}`}
                  className="w-[30px] sm:w-[40px] h-[30px] sm:h-[40px] mr-[16px] shrink-0"
                />
                <div>
                  <div className="flex items-center mb-[4px]">
                    <h3 className="text-16 text-neutral_black font-campton_m capitalize">
                      {item?.firstName} {item?.lastName}
                    </h3>
                    <Status status={item?.relationship} className="bg-[#E6F9EE] text-[#1BA154] font-campton_r ml-[8px]" />
                  </div>
                  <p className="text-14 text-neutral_body font-campton_r mb-[4px]">
                    <span className="hidden sm:inline-block">Email:</span>{' '}
                    <span className="text-neutral_black">{item?.emailAddress}</span>
                  </p>
                  <p className="text-14 text-neutral_body font-campton_r mb-[4px]">
                    <span className="hidden sm:inline-block">Phone Number:</span>{' '}
                    <span className="text-neutral_black">
                      {item?.countryCode}
                      {item?.phoneNumber}
                    </span>
                  </p>
                  <p className="text-14 text-neutral_body font-campton_r capitalize">
                    <span className="hidden sm:inline-block">Address:</span>{' '}
                    <span className="text-neutral_black">{item?.address}</span>
                  </p>
                </div>
              </div>
            </AnimateList>
          ))
        ) : (
          <EmptyState title="No Emergency Contact" icon={<EmptyStateIcon className="mb-[24px]" />} />
        )}
      </>
    </Layout>
  );
}
EmergencyContact.propTypes = {
  callback: PropTypes.func,
  view: PropTypes.string,
  setModal: PropTypes.func,
  visitStatus: PropTypes.bool,
  canUpdate: PropTypes.bool
};
