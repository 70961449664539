import React, { useEffect, useMemo, useState } from "react";
import { SlideUp } from "components/Animations";
import Table from "components/Table";
import { useSelector } from "react-redux";
import useTable from "hooks/useTable";
import { useNavigate } from "react-router-dom";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import { Line } from "react-chartjs-2"
import usePatient from "hooks/fetch/usePatient";
import { month_names } from "mocks/date";
import Status from "components/Status";
import { patient_reg_chart_dropdown } from "mocks/patient";
import HighRiskOverview from "./Components/HighRiskOverview";
import Layout from "../Layout";
import useDebounce from "hooks/useDebounce";
import { formatDate } from "utils/formatDate";
import { formatTwelveHour } from "utils/formatTime";
import InPatientTableRow from "../Componets/InpatientTableRow";
import DischargePatient from "./Components/DischargePatient";
import Restricted from "HOC/Restricted";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    animation: { duration: 1000 },
    plugins: {
        legend: {
            display: false
        },
    },
    scales: {
        y: {
            beginAtZero: true
        }
    }
};

export default function InPatients() {
    const [patientToDischarge, setPatientToDischarge] = useState()
    const { institutionId } = useSelector(state => state.auth)
    const { inPatients, inPatientsAnalytics } = useSelector(state => state.patient)
    const { params, changePage, selectLimit, handleSearch } = useTable()
    const { loading: loadingPatients, get_in_patients } = usePatient()
    const { get_in_patients_analytics } = usePatient()
    const navigate = useNavigate()
    const [chartFilter, setChartFilter] = useState('monthly')
    const { debouncedValue } = useDebounce(params.search)

    const getInPatientsProxy = () => {
        const newParams = {
            ...params,
            institutionId,
            //providerId: user?.businessName ? '' : user?._id,
            //type: userType,
            admissionStatus: 'admitted'
        }
        get_in_patients({ params: newParams })
    }

    useEffect(() => {
        getInPatientsProxy()
    }, [debouncedValue, params.page, params.limit])

    useEffect(() => {
        const newParams = {
            filter: chartFilter,
            //type: userType,
            institutionId,
            //providerId: user?.businessName ? '' : user?._id,
        }
        get_in_patients_analytics({ params: newParams })
    }, [chartFilter])

    const chart = useMemo(() => {
        let labels = ['']
        let data = ['']
        inPatientsAnalytics?.map((item) => {
            labels.push(chartFilter === 'monthly' ? Object.keys(month_names)[item.month - 1] : item?.year)
            data.push(item?.count)
            return item;
        })
        return { labels, data }
    }, [inPatientsAnalytics])

    const data = {
        labels: chart.labels,
        datasets: [{
            data: chart.data,
            backgroundColor: "#fff9b320",
            borderColor: "#23CE6B",
            //borderWidth: 2,
            borderCapStyle: 'round',
            borderDashOffset: [10],
            //fill: true,
            lineTension: '0.4',
            pointBackgroundColor: '#23CE6B',
            pointRadius: 0,
            pointHitRadius: 10
        }]
    };

    const dataToExport = useMemo(() => {
        return inPatients?.inPatients?.map((item) => {
            return {
                patientName: `${item?.patientInfo?.firstName} ${item?.patientInfo?.lastName}`,
                admissionDiagnosis: item?.admissionDiagnosis,
                createdAt: `${formatDate(item?.createdAt)} ${formatTwelveHour(item?.createdAt)}`,
                location: `${item?.location} | ${item?.roomNumber}`,
                lengthOfStay: `${item?.lengthOfStay} day(s)`
            }
        })
    }, [inPatients])

    useEffect(() => {
        document.title = 'In-Patient - Gabbi Health'
    }, [])

    return (
        <Layout>
            <Restricted module="Inpatient Management" permission="View patient">
                <SlideUp>
                    <div className="flex flex-col lg:flex-row border-b border-b-neutral_stroke_2">
                        <HighRiskOverview />
                        <section className="p-[24px] w-full">
                            <div className="flex items-center flex-wrap justify-between mb-[16px]">
                                <h3 className="text-16 text-neutral_black font-campton_m mb-[8px] sm:mb-0">In-Patient Registration Trend</h3>
                                <div className="flex items-center">
                                    {patient_reg_chart_dropdown.map((item) =>
                                        <Status
                                            key={item.id}
                                            status={item.name}
                                            onClick={() => setChartFilter(item.name.toLowerCase())}
                                            className={`
                                        ${chartFilter === item.name.toLowerCase() ? 'bg-brand_primary text-neutral_white cursor-default' : 'border border-neutral_stroke_2 cursor-pointer text-[#143566]'}
                                        px-[12px] mr-[12px] text-14
                                    `}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="flex justify-center items-center h-[230px]">
                                <Line
                                    data={data}
                                    options={chartOptions}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="p-[24px]">
                        <Table
                            title="In-Patient List"
                            headers={['Patient Name', 'Admission Diagnosis', 'Admission Date', 'Location | Room No.', 'Length of Stay', 'Status', '']}
                            data={dataToExport}
                            totalCount={inPatients?.total}
                            perPage={inPatients?.limit}
                            currentPage={inPatients?.page}
                            selectLimit={selectLimit}
                            changePage={changePage}
                            emptyStateTitle='No Patients'
                            emptyStateCaption='All in-patients will be displayed here'
                            loading={loadingPatients}
                            handleSearch={handleSearch}
                            module="Inpatient management"
                            permission="Admit patient"
                            buttonName="Admit Patient"
                            buttonFnc={() => navigate("/clinicals/in-patients/admit-patient")}
                        >
                            <>
                                {inPatients?.inPatients?.map((item) =>
                                    <InPatientTableRow
                                        key={item?._id}
                                        item={item}
                                        discharge={() => setPatientToDischarge(item)}
                                    />
                                )}
                            </>
                        </Table>
                    </div>
                    {patientToDischarge &&
                        <DischargePatient
                            patientId={patientToDischarge?._id}
                            callback={getInPatientsProxy}
                            visitId={patientToDischarge?.visit}
                            closeModal={() => setPatientToDischarge()}
                        />
                    }
                </SlideUp>
            </Restricted>
        </Layout>
    )
}