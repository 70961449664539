import { SlideUp } from 'components/Animations';
import Avatar from 'components/Avatar/Avatar';
import Navbar from 'components/Navbar';
import React, { useRef, useState } from 'react';
import { useAuthState } from 'store/modules/auth';
import { userInitals } from 'utils/ImagePlaceholder';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { Button } from 'components/Buttons';
import Card from '../Policies/Component/Card';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import EditProfile from './Components/EditProfile';
import { ReactComponent as Dot } from 'assets/svg/Ellipse 4.svg';
import dayjs from 'dayjs';
import useUpload from 'hooks/useUpload';
import useAuth from 'hooks/fetch/useAuth';
import { removeSpecificKeys } from 'utils/removeSpecificKeys';

const Profile = () => {
  const [showForm, setShowForm] = useState(false);
  const { user, institutionId } = useAuthState();
  const fileRef = useRef();
  const primaryLocation = user?.healthCareFacilities?.find((facility) => facility.isPrimaryAddress === true);
  const primaryAddress = `${user?.businessName ? primaryLocation?.address + ', ' + primaryLocation?.state + ', ' + primaryLocation?.country : ''
    }`;
  const [contactPerson, setContactPerson] = useState({});
  const { upload, uploading } = useUpload();
  const { update_basic_info, loading } = useAuth();

  const providerAddress = user?.locationName || user?.businessAddress;

  const showContactForm = (item) => {
    setShowForm(true);
    setContactPerson(item);
  };

  const callback = () => {
    setContactPerson({});
    setShowForm(false);
  };

  const uploadFile = (e) => {
    let timestamp = dayjs()?.format('YYYYMMDD-HHmmss');
    const payload = { logo: `${timestamp}${e.target.files[0]?.name}` };
    upload(e.target.files[0], payload, timestamp, null, false).then((res) => {
      if (res?.response?.status === 200) {
        const payload = {
          logoUrl: res?.imageUrl,
          institutionId: institutionId,
          businessAddress: user?.businessAddress || user?.locationName,
          businessDescription: user?.businessDescription,
          type: `${user?.businessName ? 'institution' : 'provider'}`,
        };
        update_basic_info(removeSpecificKeys(payload));
      }
      fileRef.current = null;
    });
  };

  return (
    <>
      <Navbar title="Profile" />
      <SlideUp>
        <div className="p-6">
          <div className="border border-[#153E4C14] bg-primary_tint_50 rounded-lg p-6">
            <div className="flex justify-between items-center">
              <div className="flex space-x-3 items-center">
                <div className="hide_tap w-fit">
                  <Avatar
                    url={user?.logoUrl}
                    width="80px"
                    height="80px"
                    alt={user?.firstName || user?.businessName}
                    initials={
                      user?.businessName
                        ? user?.businessName?.split('')[0]
                        : userInitals(user?.firstName, user?.lastName)
                    }
                    className="w-[80px] h-[80px] cursor-pointer"
                    textVariant="text-[24px]"
                    canViewProfile={true}
                  />
                  <label htmlFor="file" className="translate-x-[80px] cursor-pointer flex items-center">
                    <div className="relative w-fit">
                      <div className="h-6 w-6 bg-neutral_white absolute rounded-full flex items-center justify-center right-0 bottom-0 shadow-light">
                        <Edit className="" width="20" />
                      </div>
                    </div>
                  </label>
                  <input
                    data-testid="file"
                    type="file"
                    id="file"
                    name="file"
                    accept="image/png, image/jpg, image/jpeg"
                    className="hidden"
                    ref={fileRef}
                    readOnly={uploading || loading}
                    onChange={(e) => uploadFile(e)}
                  />
                </div>
                <div>
                  <p className="text-16 text-neutral_black font-campton_m capitalize">
                    {user?.businessName || `${user.firstName + ' ' + user.lastName}`}
                    <span className="font-campton_r text-neutral_body ml-1 uppercase">
                      {user?.gender?.split('')[0] || ''}
                    </span>
                  </p>
                  <div className="font-campton_r text-14 text-neutral_body">
                    <p className="">{user?.businessName ? user?.businessEmailAddress : user?.specialty}</p>
                    <p>{user?.businessName ? user?.qualification : user?.user?.businessName}</p>
                  </div>
                  {uploading ? (
                    <span className="text-brand_secondary font-campton_m text-14">Uploading logo, please wait...</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <Button
                className="w-fit rounded-full h-8 font-campton_m text-14 hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary"
                theme="transparent"
                onClick={() => {
                  setContactPerson({});
                  setShowForm(true);
                }}
                name="Edit Profile"
              />
            </div>
            <div
              className={`grid ${user?.businessName ? 'xl:grid-cols-4' : 'xl:grid-cols-3'
                } md:grid-cols-2 grid:cols-1 mt-6`}
            >
              <Card title="Email Address" variant="border-l-0 pl-0">
                {user?.businessEmailAddress || user?.emailAddress}
              </Card>
              <Card title="Phone Number" variant="md:border-l pl-0 md:pl-6 border-l-0 md:mt-0 mt-6">{`${user?.countryCode + user.phoneNumber
                }`}</Card>
              {user?.type === 'staff' && (
                <Card title="Hospital Name" variant="md:border-l md:pl-6 border-l-0 pl-0 md:mt-0 mt-6">
                  {user?.institutionName}
                </Card>
              )}
              <Card
                title="Hospital Location"
                variant={`${user?.type === 'staff' ? 'border-l-0 !pl-0 mt-6' : 'border-l-0 lg:border-l pl-0 lg:pl-6 mt-6 lg:mt-0'
                  } `}
              >
                {user?.businessName ? primaryAddress : providerAddress}
              </Card>
              <Card
                title="License"
                variant={`${user?.type === 'staff'
                    ? 'md:border-l md:pl-6 border-l-0 pl-0 mt-6'
                    : 'border-l-0 md:border-l pl-0 md:pl-6 mt-6 lg:mt-0'
                  } `}
              >
                {user?.CACLicenseNumber || user?.medicalLicenseNumber}
              </Card>
            </div>
          </div>
          <div className="mt-[22px]">
            {showForm && (
              <SlideUp>
                <div className="flex items-center space-x-6 mb-6">
                  <hr className="text-neutral_stroke_2 w-[96%]" />
                  <span
                    data-testid="accordion-btn"
                    className="bg-primary_shade_300 h-6 w-6 rounded-full flex items-center justify-center cursor-pointer"
                    onClick={callback}
                  >
                    <Close fill="#FFFFFF" className="cursor-pointer" />
                  </span>
                </div>
                <EditProfile
                  primaryAddress={user?.businessName ? primaryAddress : providerAddress}
                  contactPerson={contactPerson}
                  callback={callback}
                />
              </SlideUp>
            )}
            {user?.businessName && !contactPerson?.firstName ? (
              <SlideUp>
                <div className="border border-neutral_stroke_2 rounded-lg p-4 mt-6">
                  <p className="font-campton_m text-neutral_black">Contact Person</p>
                  <div className="flex flex-col ">
                    {user?.contactInformation?.map((item) => (
                      <div key={item._id} className="flex md:flex-row flex-col justify-between mt-8">
                        <div className="flex space-x-2">
                          <Avatar
                            width="32px"
                            height="32px"
                            alt={item?.firstName || item?.businessName}
                            initials={userInitals(item?.firstName, item?.lastName)}
                            className="w-8 h-8 bg-brand_secondary"
                            textVariant="text-14"
                          />
                          <div>
                            <p className="font-campton_r text-neutral_black text-14 capitalize">
                              {item.firstName} {item.lastName}
                            </p>
                            <p className="text-neutral_body font-campton_r text-12 flex items-center space-x-1">
                              {item.emailAddress} <Dot className="ml-1 mb-0.5" />{' '}
                              <span>
                                {item.countryCode ?? ''}
                                {item?.phoneNumber ?? ''}
                              </span>
                            </p>
                          </div>
                        </div>
                        <Button
                          className="md:w-fit w-24 h-8 md:mt-0 mt-3"
                          theme="transparent"
                          onClick={() => showContactForm(item)}
                          name="Change"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </SlideUp>
            ) : (
              ''
            )}
          </div>
        </div>
      </SlideUp>
    </>
  );
};

export default Profile;
