import React, { useEffect, useMemo, useState } from 'react';
import { SlideUp } from 'components/Animations';
import useTable from 'hooks/useTable';
import Table from 'components/Table';
import { ReactComponent as Cancel } from 'assets/icons/remove.svg';
import { ReactComponent as Calendar } from 'assets/icons/calendar2.svg';
import Navbar from 'components/Navbar';
import SubNavbar from 'components/SubNavbar';
import { useNavigate } from 'react-router-dom';
import Overview from './Components/Overview';
import Avatar from 'components/Avatar/Avatar';
import MoreOptions from 'components/Options/MoreOptions';
import useShift from 'hooks/fetch/useShift';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'components/Spinner';
import useDebounce from 'hooks/useDebounce';
import EmptyState from 'components/Table/EmptyState';
import { setSingleStaffShift } from 'store/modules/shift';
import Delete from 'components/Forms/Delete';
import AddStaff from './Components/Forms/AddStaff';
import EditShift from './Components/EditShift';
import usePermission from 'hooks/usePermission';

export default function SingleShift() {
  const [type, setType] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, get_shift } = useShift();
  const { loading: isDeletingStaff, delete_shift_staff } = useShift();
  const { params, changePage, selectLimit, handleSearch } = useTable();
  const { institutionId } = useSelector((state) => state.auth);
  const shiftId = location?.pathname?.split('/')[3];
  const [shift, setShift] = useState();
  const [deleteShift, setDeleteShift] = useState(false);
  const { debouncedValue } = useDebounce(params.search);
  const { loading: isDeletingShift, delete_shift } = useShift();
  const [editShift, setEditShift] = useState(false)
  const { hasPermission } = usePermission()

  const getShiftProxy = () => {
    get_shift({ pathname: `${institutionId}/${shiftId}`, params })
      .then((res) => setShift(res?.data || {}));
  };

  const deleteShiftProxy = () => {
    delete_shift(shiftId).then((res) => {
      if (res?.code === 200) navigate('/e-shift/scheduling');
    });
  };

  const options = [
    {
      id: 1,
      name: 'View calendar',
      value: 'view',
      icon: <Calendar className="mr-[8px]" />,
      method: (selectedStaff) => {
        dispatch(setSingleStaffShift()); //clears previous state if any
        dispatch(setSingleStaffShift(selectedStaff));
        navigate(`/e-shift/scheduling/${shiftId}/${selectedStaff?._id}`);
      },
      permission: true
    },
    {
      id: 2,
      name: 'Remove staff',
      value: 'remove',
      icon: <Cancel className="mr-[8px]" />,
      method: (selectedStaff) => {
        delete_shift_staff(`${shiftId}/${selectedStaff?._id}`).then((res) => {
          if (res?.code === 200) getShiftProxy();
        });
      },
      permission: hasPermission('Scheduling', "Remove staff from shift")
    },
  ];

  const dataToExport = useMemo(() => {
    return shift?.shift?.staffs?.map((item) => {
      return {
        name: `${item?.firstName} ${item?.lastName}`,
        specialty: item?.specialty,
        location: item?.location,
      };
    });
  }, [shift]);

  const handleCloseModal = () => {
    setType('');
  };

  useEffect(() => {
    getShiftProxy();
  }, [debouncedValue, params.page, params.limit]);

  return (
    <>
      <Navbar title="Shift Management" />
      <SubNavbar
        previous="Shift Management"
        onClick={() => navigate('/e-shift/scheduling')}
        current={shift?.shift?.shiftName}
      />
      {!shift ? (
        <Spinner height="h-[80vh]" />
      ) : (
        <SlideUp>
          <Overview data={shift?.shift} deleteFunc={() => setDeleteShift(true)} setEditShift={setEditShift} />
          <div className="p-[24px]">
            <Table
              title="Staff List"
              headers={['Name', 'Specialty', 'Location', '']}
              data={dataToExport}
              totalCount={shift?.staffCount}
              perPage={shift?.limit}
              currentPage={shift?.page}
              searchPlaceholder="Search by staff name..."
              selectLimit={selectLimit}
              changePage={changePage}
              loading={loading}
              handleSearch={handleSearch}
              emptyState={
                <EmptyState
                  title="You’ve not added staff yet"
                  caption="Staff added will be displayed and managed here"
                  buttonName="Add Staff"
                  buttonFunc={() => setType('Add Staff')}
                />
              }
              buttonName="Add Staff"
              buttonFnc={() => setType('Add Staff')}
              module="Specialty"
              permission="Add staff"
            >
              <>
                {shift?.shift?.staffs?.map((item) => (
                  <tr
                    key={item?._id}
                    className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1"
                  >
                    <td className="px-[10px] py-[8px] flex items-center capitalize">
                      <Avatar
                        url={item?.profile_picture}
                        width="40px"
                        height="40px"
                        alt={item?.firstName?.split('')[0]}
                        initials={`${item?.firstName?.split('')[0] || ''}${item?.lastName?.split('')[0] || ''}`}
                        className="mr-[15px] w-[40px] h-[40px]"
                      />
                      {item?.firstName} {item?.lastName}
                    </td>
                    <td className="px-[10px] py-[11px] capitalize">{item?.specialty || 'N/A'}</td>
                    <td className="px-[10px] py-[11px] capitalize">{item?.locationName || 'N/A'}</td>
                    <td className="px-[10px] py-[11px] w-[70px]">
                      <MoreOptions position="right-[8px]">
                        <>
                          {options?.filter(o => o.permission)?.map((option) => (
                            <button
                              key={option?.id}
                              type='button'
                              data-testid={option?.value}
                              onClick={() => (!isDeletingStaff ? option.method(item) : {})}
                              className={`${item?.status?.toLowerCase() === 'deactivated' && option?.value === 'deactivate'
                                ? 'hidden'
                                : ''
                                } ${item?.status?.toLowerCase() !== 'deactivated' && option?.value === 'activate'
                                  ? 'hidden'
                                  : ''
                                } cursor-pointer w-[150px] flex items-center py-[9.5px] hover:bg-[#dfe2e23b] text-neutral_body hover:text-neutral_black hover:pl-[8px] rounded-[50px]`}
                            >
                              {option.icon}
                              <span className="inline-block">{option?.name}</span>
                            </button>
                          ))}
                        </>
                      </MoreOptions>
                    </td>
                  </tr>
                ))}
              </>
            </Table>
          </div>
        </SlideUp>
      )}
      {deleteShift &&
        <Delete
          title="Delete Shift?"
          buttonName="Delete"
          description='Do you want to delete this shift?'
          cancel={() => setDeleteShift(false)}
          loading={isDeletingShift}
          deleteFnc={deleteShiftProxy}
        />
      }
      {type === 'Add Staff' &&
        <AddStaff type={type} closeModal={handleCloseModal} getShiftProxy={getShiftProxy} />
      }
      {editShift &&
        <EditShift
          callback={() => setEditShift(false)}
          shift={shift?.shift}
        />
      }
    </>
  );
}
