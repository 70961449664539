import React from "react";
import PropTypes from 'prop-types';
import Status from "components/Status";
import Avatar from "components/Avatar/Avatar";
import { Button } from "components/Buttons";
import Restricted from "HOC/Restricted";
import Modal from "components/Modals/Modal";

export default function ViewStaff({ deleteStaff, changeRole, staff, closeModal,title }) {
    return (
        <Modal
            closeModal={closeModal}
            styles="w-full md:w-[457px]"
            position="modal-right"
            title={title}
            variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
        >
            <div className="relative">
                <div className="modal-right-body">
                    <div className="bg-brand_primary h-[105px] rounded-t-[8px] relative">
                        <Avatar
                            url={staff?.profilePicture}
                            width="80px"
                            height="80px"
                            alt={staff?.firstName?.split('')[0]}
                            initials={`${staff?.firstName?.split('')[0] || ''}${staff?.lastName?.split('')[0] || ''}`}
                            className="w-[80px] h-[80px] absolute top-[65px] left-[24px] border border-neutral_white"
                        />
                    </div>
                    <div className="border border-neutral_stroke_2 px-[24px] pt-[56px] pb-[24px]">
                        <div className="flex justify-between mb-[4px]">
                            <div className="flex items-center">
                                <p className="text-16 text-neutral_black font-campton_m capitalize">{`${staff?.firstName} ${staff?.lastName}`}</p>
                                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-[8px]">
                                    <circle cx="2" cy="2" r="2" fill="#DFE2E2" />
                                </svg>
                                <p className="text-14 text-neutral_body font-campton_r">m</p>
                            </div>
                            <Status status={staff?.role} className="bg-[#E6F9EE] text-[#1BA154]" />
                        </div>
                        <p className="text-14 text-neutral_body font-campton_r mb-[4px]">{staff?.emailAddress}</p>
                        <p className="text-14 text-neutral_body font-campton_r mb-[16px]">{staff?.phoneNumber}</p>
                        <div className="border border-neutral_stroke_2 rounded-[8px] text-12 font-campton_r">
                            <div className="px-[16px] py-[8px] border-b border-b-neutral_stroke_2">
                                <p className="text-neutral_body">Specialty</p>
                                <p className="text-neutral_black capitalize">{staff?.specialty || "N/A"}</p>
                            </div>
                            <div className="px-[16px] py-[8px] border-b border-b-neutral_stroke_2">
                                <p className="text-neutral_body">Location</p>
                                <p className="text-neutral_black capitalize">{staff?.locationName || "N/A"}</p>
                            </div>
                            <div className="px-[16px] py-[8px]">
                                <p className="text-neutral_body">Qualifications</p>
                                <p className="text-neutral_black">{staff?.qualification || "N/A"}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {staff.role !== 'superAdmin' &&
                    <div className="modal-right-button-container">
                        <Restricted module='Staff Management' permission='Delete staff'>
                            <Button
                                name="Delete staff"
                                theme="transparent"
                                onClick={deleteStaff}
                                className="h-[40px] text-16 px-[12px] mr-[16px]"
                            />
                        </Restricted>
                        <Restricted module='Staff Management' permission='Change role'>
                            <Button
                                name="Change role"
                                theme="blue"
                                className="h-[40px] text-16 px-[12px]"
                                onClick={changeRole}
                            />
                        </Restricted>
                    </div>
                }
            </div>
        </Modal>
    )
}

ViewStaff.propTypes = {
    deleteStaff: PropTypes.func,
    staff: PropTypes.object,
    changeRole: PropTypes.func,
    closeModal: PropTypes.func,
    title: PropTypes.any
}