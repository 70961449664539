export const specialty_list_header = ['Specialty Name', 'Date Created', 'Time Created', 'No of Staff', ''];
export const staff_list_header = ['Name', 'GPI Number', 'Location', ''];
export const specialty_list = [
    {
        _id: 1,
        name: 'Cardiology',
        createdAt: '24-MAY-2023',
        timeCreated: '11:00AM',
        staffCount: '500'
    },
    {
        _id: 2,
        name: 'Radiology',
        createdAt: '24-MAY-2023',
        timeCreated: '11:00AM',
        staffCount: '500'
    },
    {
        _id: 3,
        name: 'Pathology',
        createdAt: '24-MAY-2023',
        timeCreated: '11:00AM',
        staffCount: '500'
    },
    {
        _id: 4,
        name: 'Dermatology',
        createdAt: '24-MAY-2023',
        timeCreated: '11:00AM',
        staffCount: '500'
    }
]
export const staff_list = [
    {
        title: 'Dr.',
        firstName: 'Gabriel ',
        lastName: 'Inyamah',
        GPINumber: 'GPI-89112451',
        emailAddress: 'gabrielinyamah@reddington.ng',
        location: 'Lekki'
    },
    {
        title: 'Dr.',
        firstName: 'Barakat',
        lastName: 'Salam',
        GPINumber: 'GPI-89112451',
        emailAddress: 'barakatsalam@reddington.ng',
        location: 'Ikeja_1'
    },
    {
        title: 'Dr.',
        firstName: 'Muhammad',
        lastName: 'Oni',
        emailAddress: 'muhammad@reddington.ng',
        GPINumber: 'GPI-89112451',
        location: 'Ikeja_2'
    },
    {
        title: 'Dr.',
        firstName: 'Elizabeth',
        lastName: 'Ajao',
        GPINumber: 'GPI-89112451',
        emailAddress: 'elizabethajao@reddington.ng',
        location: 'Victoria Island'
    },
    {
        title: 'Dr.',
        firstName: 'Success',
        lastName: 'Ologunsua',
        GPINumber: 'GPI-89112451',
        emailAddress: 'success@reddington.ng',
        location: 'Lekki'
    },
]