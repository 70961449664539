import { createSlice } from '@reduxjs/toolkit';
import { getAllBanks, getBillingHistory, getInstitutionRevnue, getWalletTransactions } from './actions';
import { useAppSelector } from 'hooks/useReduxHook';

const initialState = {
  banks: [],
  billingHistory: {},
  revenue: {},
  walletTransactions: {},
  billDetails: {}
};

export const payment = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setBillDetails: (state = initialState, action) => {
      state.billDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBanks.fulfilled, (state, action) => {
        state.banks = action?.payload;
      })
      .addCase(getBillingHistory.fulfilled, (state, action) => {
        if (action.payload.code === 200) state.billingHistory = action?.payload.data;
      })
      .addCase(getInstitutionRevnue.fulfilled, (state, action) => {
        state.revenue = action?.payload.data;
      })
      .addCase(getWalletTransactions.fulfilled, (state, action) => {
        state.walletTransactions = action?.payload.data;
      })
  },
});
// Selectors
const selectPayment = (state) => state.payment;
//Reducers and actions
export const {setBillDetails} = payment.actions
//App Redux State
export const usePaymentState = () => useAppSelector(selectPayment);

export default payment.reducer;
