export const convertJsonToCsv = (jsonData, headers) => {
    // Prepare CSV header using the selected fields
    const csvHeader = headers.join(',') + '\n';

    // Prepare CSV rows
    const csvRows = jsonData.map(item => {
        return Object.values(item).join(',') + '\n';
    });

    // Combine header and rows into a single CSV string
    const csvContent = csvHeader + csvRows.join('');

    return csvContent;
}