import React, { useEffect } from 'react';
import { Button } from 'components/Buttons';
import { ReactComponent as PasswordCheck } from 'assets/icons/password-check.svg';
import { ReactComponent as Location } from 'assets/icons/location-illustration.svg';
import { ReactComponent as Dot } from 'assets/svg/Ellipse 4.svg';
import { Search } from 'components/Inputs';
import Avatar from 'components/Avatar/Avatar';
import { userInitals } from 'utils/ImagePlaceholder';
import PropTypes from 'prop-types';
import { singleLocation } from 'mocks/settings/locations';
import useLocations from 'hooks/fetch/useLocations';
import { useAuthState } from 'store/modules/auth';
import { useLocationState } from 'store/modules/locations';
import useTable from 'hooks/useTable';
import useDebounce from 'hooks/useDebounce';
import EmptyState from 'components/Table/EmptyState';
import Restricted from 'HOC/Restricted';

const ViewLocation = ({ onClick }) => {
  const { institutionId, user } = useAuthState();
  const { locationDetails } = useLocationState();
  const { get_location_details } = useLocations();
  const { handleSearch, params } = useTable();
  const { debouncedValue } = useDebounce(params.search);

  useEffect(() => {
    if (debouncedValue)
      get_location_details(`${institutionId}/location/${locationDetails?._id}`, null, { search: debouncedValue });
  }, [debouncedValue]);

  return (
    <div className="relative">
      <div className="modal-right-body">
        <div className="border border-[#153E4C14] rounded-lg bg-primary_tint_50 p-6">
          {locationDetails?.isPrimaryAddress ? (
            <Button className="bg-[#E6F9EE] h-7 mt-2 w-fit text-14 px-3 border-0">
              <p className="font-campton_m text-[#1BA154] flex datas-center text-12">
                <span className="mr-1.5">
                  <PasswordCheck />
                </span>
                Primary address
              </p>
            </Button>
          ) : (
            <button className="bg-[#F7F8F8] h-7 text-12 mt-2 w-fit px-2 font-campton_m text-neutral_body rounded-full border border-neutral_stroke_2">
              Make primary address
            </button>
          )}
          <div className="flex space-x-6 mt-7">
            <Location />
            <div>
              <p className="font-campton_m text-neutral_black capitalize">
                {user?.businessName}, {locationDetails?.city}
              </p>
              <p className="font-campton_r text-12 text-neutral_body mt-0.5">{locationDetails?.address}</p>
            </div>
          </div>
          <div className="flex justify-between mt-6 border-b border-dashed border-neutral_stroke_2 pb-6">
            <div>
              <p className="text-12 text-neutral_body font-campton_r">State of Operation</p>
              <p className="font-campton_r text-neutral_black text-14">{locationDetails?.state}</p>
            </div>
            <div>
              <p className="text-12 text-neutral_body font-campton_r">City</p>
              <p className="font-campton_r text-neutral_black text-14">{locationDetails?.city}</p>
            </div>
          </div>
          <div className="mt-6">
            <p className="text-12 font-campton_r text-neutral_body">Contact Person</p>
            <p className="text-12 font-campton_r text-neutral_black">{locationDetails?.contactPersonInformation?.name}</p>
            <p className="text-neutral_body text-12 flex items-center space-x-1">
              {locationDetails?.contactPersonInformation?.emailAddress} <Dot className="ml-2" />{' '}
              <span className="text-neutral_body font-campton_r">
                {' '}
                {locationDetails?.contactPersonInformation?.countryCode}
                {locationDetails?.contactPersonInformation?.phoneNumber}
              </span>
            </p>
          </div>
        </div>
        <div className="mt-6">
          <p className="text-14 font-campton_m text-neutral_black my-3">
            Staff List <span className="text-neutral_body font-campton_r">({locationDetails?.staffCount})</span>
          </p>
          <Search id="search" name="search" placeholder="Search by staff name..." onChange={handleSearch} />
          {locationDetails?.staff?.length > 0 ? (
            <div className="h-[calc(100vh-605px)] overflow-y-scroll mt-3">
              {locationDetails?.staff?.map((item) => (
                <div
                  className="border-b border-b-neutral_stroke_2 py-3 last-of-type:border-b-0 flex items-center"
                  key={item.firstName}
                >
                  <Avatar
                    url={item?.profilePic}
                    width="42px"
                    height="42px"
                    alt={singleLocation?.provider?.firstName?.split('')[0]}
                    initials={userInitals(item?.firstName || '', item?.lastName || '')}
                    className="bg-brand_secondary w-8 h-8"
                  />
                  <div className="ml-2">
                    <p className="flex text-12 items-center font-campton_r">
                      <span className="text-14  text-neutral_black capitalize">
                        {item?.firstName} {item?.lastName}{' '}
                      </span>
                      <Dot className="mx-2" />
                      <span className="text-12 text-neutral_body capitalize">{item?.specialty}</span>
                    </p>
                    <p className="text-12 font-campton_r text-neutral_body">{item?.emailAddress}</p>
                  </div>
                </div>
              ))}
            </div>) : <EmptyState title="No Staffs Available" />
          }
        </div>
      </div>
      <div className="modal-right-button-container">
        <Restricted module='Location' permission='Edit location'>
          <Button name="Edit Location Information" theme="blue" className="w-fit h-10" onClick={onClick} />
        </Restricted>
      </div>
    </div>
  );
};

export default ViewLocation;
ViewLocation.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.object,
  id: PropTypes.string,
};
