import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/formatDate';
import LineChart from 'components/Chart/LineChart';

export default function RespiratoryRate({ analyticsData, recentVitals, className }) {
    const data = useMemo(() => {
        let data = ['']
        let labels = ['']
        analyticsData?.reverse()?.map((item) => {
            labels.push(formatDate(item?.date))
            data.push(item?.respiratoryRate)
            return item;
        })
        return { labels, data }
    }, [analyticsData]);

    return (
        <>
            <p className="font-campton_m text-14 text-neutral_black mb-[16px]">{recentVitals} <span className='text-neutral_body'>bpm</span></p>
            <div className={className}>
                <LineChart chartData={data} />
            </div>
        </>
    );
}

RespiratoryRate.propTypes = {
    analyticsData: PropTypes.array,
    recentVitals: PropTypes.string,
    className: PropTypes.string
}
