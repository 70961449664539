import React from "react";
import PropTypes from "prop-types"
import Avatar from "components/Avatar/Avatar";
import { ReactComponent as Contact } from "assets/icons/appointments-contact.svg";
import { ReactComponent as Onsite } from "assets/icons/onsite.svg";
import { ReactComponent as Teleclinic } from "assets/icons/teleclinic.svg";
import { formatDate } from "utils/formatDate";

export default function Card({ id, image, specialist, patient, date, type, onClick, time }) {
    return (
        <div
            onClick={onClick}
            data-testid={id}
            className="px-[24px] py-[16px] border border-neutral_stroke_2 rounded-[8px] mb-[24px] cursor-pointer hide_tap"
        >
            <div className="flex items-center mb-[15px]">
                {type?.toLowerCase() !== 'inapp' ?
                    <><Onsite /><p className="ml-[8.5px] text-12 text-[#DF3939] font-campton_r">Onsite</p></> :
                    <><Teleclinic /><p className="ml-[8.5px] text-12 text-[#23CE6B] font-campton_r">Teleclinic</p></>
                }
                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-[8px]">
                    <circle cx="3" cy="3" r="3" fill="#ECEEEE" />
                </svg>
                <p className="text-12 font-campton_r">{formatDate(date)} ({time})</p>
            </div>
            <div className="flex">
                <Avatar
                    url={image}
                    width="40px"
                    height="40px"
                    //alt={profile?.first_name?.split('')[0]}
                    initials={`${specialist?.firstName?.split('')[0] || ''}${specialist?.lastName?.split('')[0] || ''}`}
                    className="w-[30px] sm:w-[40px] h-[30px] sm:h-[40px] mr-[8px] shrink-0"
                />
                <div>
                    <p className="text-16 font-campton_m text-primary_shade_300">
                        {specialist?.title} {specialist?.firstName} {specialist?.lastName}
                    </p>
                    <p className="text-14 font-campton_r text-neutral_body mb-[12px] capitalize">
                        {specialist?.specialty}{specialist?.qualification ? `, ${specialist?.qualification}` : ''}
                    </p>
                    <div className="flex">
                        <Contact fill="#748181" />
                        <p className="text-14 font-campton_r text-neutral_body ml-[8px]">{patient?.firstName} {patient?.lastName}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

Card.propTypes = {
    image: PropTypes.string,
    specialist: PropTypes.object,
    patient: PropTypes.object,
    date: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,
    time: PropTypes.string
}