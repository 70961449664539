import FormWrapper from 'components/FormWrapper';
import Modal from 'components/Modals/Modal';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Uncheck } from 'assets/icons/check-box-unselected.svg';
import { ReactComponent as Check } from 'assets/icons/check-box-selected.svg';
import { DefaultDatepicker } from 'components/Datepicker/Index';
import Accordion from 'components/Accordion';
import { service_subCategory } from 'mocks/services';
import { useServiceState } from 'store/modules/services';
import { Button } from 'components/Buttons';
import useService from 'hooks/fetch/useService';
import { useAuthState } from 'store/modules/auth';
import useTable from 'hooks/useTable';
import dayjs from 'dayjs';

const FilterService = ({ closeModal }) => {
  const [filterParams, setFilterParams] = useState({
    fromDate: '',
    toDate: '',
    subCategory: '',
  });

  const { selectedCategory } = useServiceState();
  const { get_services, loading } = useService();
  const { user, institutionId } = useAuthState();
  const { params } = useTable();

  const filterService = () => {
    closeModal();
    get_services({
      ...params,
      institutionId,
      providerId: user?.businessName ? '' : user?._id,
      category: selectedCategory?.name,
      type: user?.businessName ? 'institution' : 'provider',
      ...filterParams,
      toDate: filterParams.toDate ? dayjs(filterParams.toDate).format('YYYY-MM-DD') : '',
      fromDate: filterParams.fromDate ? dayjs(filterParams.fromDate).format('YYYY-MM-DD') : '',
    });
  };
  return (
    <Modal
      closeModal={closeModal}
      styles="w-full md:w-[457px]"
      position="modal-right"
      title="Filter"
      variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
    >
      <FormWrapper className="flex flex-col justify-between h-full">
        <div>
          <Accordion
            display={false}
            title="By Sub-category"
            className="border-b pb-4 border-neutral_stroke_2 mb-4"
            variant="mt-3"
            titleClassName="text-12"
          >
            <div className="bg-[#F7F8F8] p-4 rounded-lg h-60 overflow-scroll">
              {service_subCategory[selectedCategory?.name]?.map((item) => (
                <div
                  key={item.name}
                  className={`${
                    item.name === 'all' ? 'border-b border-dashed border-neutral_stroke_2 py-2' : 'py-3'
                  } flex`}
                  onClick={() => setFilterParams({ ...filterParams, subCategory: item.name })}
                >
                  <div data-testid={item.name} className="cursor-pointer hide_tap">
                    {filterParams.subCategory === item.name ? <Check /> : <Uncheck />}
                  </div>
                  <p className="text-14 text-neutral_black font-campton_r ml-[8px] capitalize">{item.name}</p>
                </div>
              ))}
            </div>
          </Accordion>
          <section className="mb-[24px]">
            <p className="text-12 text-neutral_black font-campton_m mb-[10px]">By Date Range</p>
            <div className="grid grid-cols-2 gap-x-[16px]">
              <div>
                <DefaultDatepicker
                  label="From"
                  containerVariant="lg:col-span-6 col-span-12"
                  startDate={filterParams.fromDate}
                  maxDate={new Date()}
                  onSelect={(date) => setFilterParams({ ...filterParams, fromDate: date })}
                  name="startDate"
                  showCalender={true}
                />
              </div>
              <div>
                <DefaultDatepicker
                  label="To"
                  containerVariant="lg:col-span-6 col-span-12"
                  startDate={filterParams.toDate}
                  minDate={filterParams?.fromDate}
                  onSelect={(date) => setFilterParams({ ...filterParams, toDate: date })}
                  name="endDate"
                  showCalender={true}
                />
              </div>
            </div>
          </section>
        </div>
        <div className="flex justify-end py-[20px]">
          <Button
            name="Cancel"
            theme="transparent"
            type="button"
            onClick={closeModal}
            className="h-[40px] text-16 w-[79px] mr-[16px]"
          />
          <Button
            name="Filter"
            theme="blue"
            loading={loading}
            onClick={filterService}
            className="h-[40px] text-16 w-[128px]"
          />
        </div>
      </FormWrapper>
    </Modal>
  );
};

export default FilterService;
FilterService.propTypes = {
  closeModal: PropTypes.func,
};
