import React, { useContext, useEffect, useMemo, useState } from "react";
import { SlideUp } from "components/Animations";
import Table from "components/Table";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg"
import { ReactComponent as File } from "assets/icons/file.svg"
import { formatDate } from "utils/formatDate";
import Status from "components/Status";
import useTable from "hooks/useTable";
import usePatient from "hooks/fetch/usePatient";
import { PatientContext } from "context";
import useDebounce from "hooks/useDebounce";
import EmptyState from "components/Table/EmptyState";
import Modal from "components/Modals/Modal";
import BillingInformation from "./BillingInformation/BillingInformation";
import { formatCurrency } from "utils/formatCurrency";

export default function BillsAndPayments() {
    const { handleSearch, changePage, selectLimit, params } = useTable()
    const { patient } = useContext(PatientContext)
    const { get_patient_bills, loading } = usePatient()
    const [bills, setBills] = useState()
    const [selectedBills, setSelectedBills] = useState()
    const { debouncedValue } = useDebounce(params.search);

    useEffect(() => {
        const newParams = { type: 'patient', patientId: patient?._id, ...params }
        get_patient_bills({ params: newParams })
            .then(res => setBills(res?.data || undefined))
    }, [debouncedValue, params.page, params.limit])

    const dataToExport = useMemo(() => {
        return bills?.bills?.map((item) => {
            return {
                billingId: item?.billId,
                amount: item?.amountExpected,
                billDate: formatDate(item?.createdAt),
                dueDate: formatDate(item?.dueDate),
                status: item?.paymentStatus,
            };
        });
    }, [bills])

    return (
        <SlideUp>
            <div className="p-[24px]">
                <Table
                    title="Billing History"
                    headers={['Billing ID', 'Amount (NGN)', 'Bill Date', 'Due Date', 'Status', '']}
                    data={dataToExport}
                    totalCount={bills?.total}
                    perPage={bills?.limit}
                    currentPage={bills?.page}
                    selectLimit={selectLimit}
                    changePage={changePage}
                    loading={loading}
                    handleSearch={handleSearch}
                >
                    <>
                        {bills?.bills?.map((item) =>
                            <tr
                                data-testid={item?._id}
                                key={item?._id}
                                className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer hide_tap"
                                onClick={() => setSelectedBills(item)}
                            >
                                <td className="px-[10px] py-[8px] flex items-center capitalize">
                                    <File />
                                    <div className="ml-[8px]">
                                        <p className="text-14 font-campton_r text-neutral_black">{item?.billId}</p>
                                        <p className="text-14 font-campton_r text-neutral_body">{item?.description}</p>
                                    </div>
                                </td>
                                <td className="px-[10px] py-[8px]">{formatCurrency(item?.amountExpected)}</td>
                                <td className="px-[10px] py-[8px]">{formatDate(item?.createdAt)}</td>
                                <td className="px-[10px] py-[8px] capitalize">{formatDate(item?.dueDate) || 'N/A'}</td>
                                <td className="px-[10px] py-[8px] capitalize">
                                    <Status status={item?.paymentStatus} />
                                </td>
                                <td className="px-[10px] py-[8px]"><ArrowRight fill="#A9B2B2" /></td>
                            </tr>
                        )}
                    </>
                </Table>
            </div>
            {selectedBills ?
                <Modal
                    closeModal={() => setSelectedBills()}
                    styles="w-full md:w-[457px]"
                    position="modal-right"
                    title="Billing Information"
                    variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
                >
                    <BillingInformation data={selectedBills} />
                </Modal> : ''
            }
        </SlideUp>
    )
}