import React, { useMemo } from "react";
import PropTypes from "prop-types"
import { Button } from "components/Buttons";
import { ReactComponent as ArrowBack } from "assets/icons/arrow-back.svg";
import Service from "./Service";
import { useNavigate } from "react-router-dom";
import EmptyState from "components/Table/EmptyState";

export default function SelectService({ back, services, next, handleSelectedService, getServicesByCategory, isFetchingCategory }) {
    const navigate = useNavigate()

    const checkIfAServiceIsSelected = useMemo(() => {
        return services?.some(service => service?.selected?.length)
    }, [services])

    return (
        <div className="flex flex-col justify-between relative">
            <div>
                <div className="flex items-center mb-[24px]">
                    <Button
                        theme="transparent"
                        type="button"
                        className="w-[82px] mr-[12px]"
                        onClick={back}
                    >
                        <>
                            <ArrowBack fill="#748181" className="w-[7px] h-[12px] mr-[13.5px]" />
                            Back
                        </>
                    </Button>
                    <h2 className="text-16 text-neutral_black font-campton_m pl-[12px] border-l border-l-neutral_stroke_2">Select Services</h2>
                </div>
                <div className="px-[24px] h-[62vh] overflow-y-auto pb-[38px]">
                    {services?.map((item) =>
                        <Service
                            key={item?.module}
                            item={item}
                            getServicesByCategory={getServicesByCategory}
                            handleSelectedService={handleSelectedService}
                            isFetchingCategory={isFetchingCategory}
                        />
                    )}
                    {!services?.length ? <EmptyState title="No Service Created." /> : ''}
                </div>
            </div>
            <div className="flex justify-end bg-neutral_white absolute bottom-0 right-0 w-full">
                <Button
                    name="Cancel"
                    theme="transparent"
                    type="button"
                    onClick={() => navigate(-1)}
                    className="h-[40px] text-16 w-[79px] mr-[16px]"
                />
                <Button
                    name="Next"
                    theme="blue"
                    type="button"
                    onClick={next}
                    disabled={!checkIfAServiceIsSelected}
                    className="h-[40px] text-16 w-[88px]"
                />
            </div>
        </div>
    )
}

SelectService.propTypes = {
    back: PropTypes.func,
    next: PropTypes.func,
    services: PropTypes.array,
    handleSelectedService: PropTypes.func,
    getServicesByCategory: PropTypes.func,
    isFetchingCategory: PropTypes.bool
}