import Dropdown from 'components/Dropdown';
import { Input } from 'components/Inputs';
import React, { useEffect, useState } from 'react';
import { genderTypes, qualifications } from 'mocks/auth';
import { titles } from 'mocks/settings';
import Phone from 'components/Inputs/PhoneInput';
import { Button } from 'components/Buttons';
import PropTypes from 'prop-types';
import { useAuthState } from 'store/modules/auth';
import useAuth from 'hooks/fetch/useAuth';
import { removeSpecificKeys } from 'utils/removeSpecificKeys';
import useSpecialty from 'hooks/fetch/useSpecialty';

const EditProfile = ({ primaryAddress, contactPerson, callback }) => {
  const { edit_provider_profile, loading } = useAuth();
  const { get_Specialties, loading: isFetchingSpecialties = true } = useSpecialty()
  const [availableSpecialties, setAvailableSpecialties] = useState([])
  const { user } = useAuthState();
  const { update_basic_info, loading: updatingBasicInfo, edit_contact_info } = useAuth();
  const [phone, setPhone] = useState({
    phoneNumber: contactPerson?.firstName ? contactPerson?.phoneNumber : user?.phoneNumber,
    countryCode: contactPerson?.firstName ? contactPerson?.countryCode : user?.countryCode
  });
  const initialFormData = {
    fullName: `${user?.firstName + ' ' + user?.lastName}`,
    title: `${user?.title || ''}`,
    qualification: `${user?.qualification || ''}`,
    specialty: `${user?.specialty || ''}`,
    medicalLicenseNumber: `${user?.medicalLicenseNumber || ''}`,
    gender: `${user?.gender || ''}`,
    emailAddress: `${user?.emailAddress || ''}`,
    phoneNumber: `${user?.phoneNumber || phone.phoneNumber}`,
  };

  const intitialInsitutionData = {
    businessName: user?.businessName ?? '',
    websiteLink: user?.websiteLink ?? '',
    providedServiceType: user?.providedServiceType ?? '',
    businessEmailAddress: user?.businessEmailAddress ?? '',
    businessDescription: user?.businessDescription,
  };
  
  const initialContactPerson = {
    firstName: contactPerson?.firstName ?? '',
    lastName: contactPerson?.lastName ?? '',
    emailAddress: contactPerson?.emailAddress ?? '',
    phoneNumber: contactPerson?.phoneNumber ?? '',
    countryCode: contactPerson?.countryCode ?? ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [institutionData, setInstitutionData] = useState(intitialInsitutionData);
  const [contactPersonData, setContactPersonData] = useState(initialContactPerson);

  useEffect(() => {
    setContactPersonData({
      firstName: contactPerson?.firstName ?? '',
      lastName: contactPerson?.lastName ?? '',
      emailAddress: contactPerson?.emailAddress ?? '',
      phoneNumber: contactPerson?.phoneNumber ?? '',
      countryCode: contactPerson?.countryCode ?? ''
    })
  }, [contactPerson])

  const handleChange = (e) => {
    user?.businessName
      ? setInstitutionData((prevFormData) => ({
        ...prevFormData,
        [e.target.name]: e.target.value,
      }))
      : setFormData((prevFormData) => ({
        ...prevFormData,
        [e.target.name]: e.target.value,
      }));
  };

  const editProfile = () => {
    const payload = {
      ...formData,
      firstName: formData.fullName.split(' ')[0],
      lastName: formData.fullName.split(' ')[1],
      countryCode: phone?.countryCode,
      phoneNumber: phone?.phoneNumber,
    };
    const insitutionPayload = {
      ...institutionData,
      type: 'institution',
      institutionId: user._id,
      countryCode: phone?.countryCode,
      phoneNumber: phone?.phoneNumber,
    };
    user?.businessName
      ? update_basic_info(removeSpecificKeys(insitutionPayload, ['businessEmailAddress', 'providedServiceType']))
      : edit_provider_profile(payload);
  };

  const editContactInfo = () => {
    edit_contact_info({
      ...contactPersonData,
      countryCode: phone?.countryCode,
      phoneNumber: phone?.phoneNumber,
      type: 'institution',
      institutionId: user._id,
      contactInfoId: contactPerson?._id,
    }, callback)
  }

  useEffect(() => {
    get_Specialties({ limit: 1000 })
      .then(res => res?.code === 200 && setAvailableSpecialties(res?.data?.specialties))
  }, [])

  const specialties = availableSpecialties?.map((item) => {
    return { name: item?.name, value: item?.name }
  })

  return (
    <div className="border border-neutral_stroke_2 rounded-lg p-4">
      <p className="font-campton_m text-neutral_black">{`Edit ${contactPerson?.firstName ? 'Contact Person' : 'Profile'}`}</p>
      {!contactPerson?.firstName ? (
        <div>
          <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-x-6 pb-6 border-b border-neutral_stroke_2 border-dashed">
            {user?.businessName ? (
              <>
                <div>
                  <Input
                    label="Business Name"
                    type="text"
                    id="businessName"
                    name="businessName"
                    value={institutionData?.businessName}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <Input
                    label="Website Link"
                    type="text"
                    id="websiteLink"
                    name="websiteLink"
                    value={institutionData?.websiteLink}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <Input
                    label="Type of institutions"
                    type="text"
                    id="providedServiceType"
                    disabled={true}
                    name="providedServiceType"
                    value={institutionData?.providedServiceType}
                    onChange={handleChange}
                  />
                </div>
              </>
            ) : (
              <>
                <div>
                  <Input
                    label="Full Name"
                    type="text"
                    id="fullName"
                    name="fullName"
                    value={formData?.fullName}
                    onChange={handleChange}
                  />
                </div>
                <Dropdown
                  type="select"
                  id="title"
                  placeholder="title"
                  name="title"
                  width="mb-6"
                  options={titles}
                  selected={formData?.title}
                  onSelect={(data) => {
                    setFormData({ ...formData, title: data.value });
                  }}
                />
                <Dropdown
                  type="select"
                  id="qualifications"
                  placeholder="Qualifications"
                  name="qualifications"
                  width="mb-6"
                  options={qualifications}
                  selected={formData?.qualification}
                  onSelect={(data) => {
                    setFormData({ ...formData, qualification: data.value });
                  }}
                />
                <Dropdown
                  type="select"
                  id="specialty"
                  placeholder="specialty"
                  name="specialty"
                  width="mb-6"
                  options={specialties}
                  loading={isFetchingSpecialties}
                  selected={formData?.specialty}
                  onSelect={(data) => {
                    setFormData({ ...formData, specialty: data.value });
                  }}
                />
                <div>
                  <Input
                    label="License ID"
                    type="text"
                    id="licenseID"
                    name="medicalLicenseNumber"
                    value={formData?.medicalLicenseNumber}
                    onChange={handleChange}
                  />
                </div>
                <Dropdown
                  type="select"
                  id="gender"
                  placeholder="gender"
                  name="gender"
                  width="mb-6"
                  options={genderTypes}
                  selected={formData?.gender}
                  onSelect={(data) => {
                    setFormData({ ...formData, gender: data.value });
                  }}
                />
              </>
            )}
          </div>
          <p className="font-campton_m text-neutral_black mt-[25px] ">Contact Information</p>
          <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-6 mt-6">
            <div>
              <Input
                label="Email Address"
                type="text"
                id="emailAddress"
                name="emailAddress"
                disabled={true}
                value={formData?.emailAddress || institutionData?.businessEmailAddress}
                onChange={handleChange}
              />
            </div>
            <Phone value={phone} onChange={setPhone} />
            <Dropdown
              type="select"
              id="location"
              placeholder="Location"
              label={user?.businessName ? 'Location' : ''}
              name="location"
              disabled={true}
              width="mb-6"
              options={[]}
              selected={primaryAddress}
            />
          </div>
        </div>
      ) : (
        <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-6 ">
          <div>
            <Input
              label="First Name"
              type="text"
              id="firstName"
              name="firstName"
              value={contactPersonData?.firstName}
              onChange={(e) => {
                setContactPersonData({ ...contactPersonData, firstName: e.target.value });
              }}
            />
          </div>
          <div>
            <Input
              label="Last Name"
              type="text"
              id="lastName"
              name="lastName"
              value={contactPersonData?.lastName}
              onChange={(e) => {
                setContactPersonData({ ...contactPersonData, lastName: e.target.value });
              }}
            />
          </div>
          <div>
            <Input
              label="Email Address"
              type="text"
              id="emailAddress"
              name="emailAddress"
              value={contactPersonData?.emailAddress}
              onChange={(e) => {
                setContactPersonData({ ...contactPersonData, emailAddress: e.target.value });
              }}
            />
          </div>
          <Phone value={phone} onChange={setPhone} />
        </div>
      )}
      <div className="flex mt-1 space-x-4 justify-end">
        <Button
          className="w-28 rounded-full h-10 font-campton_m text-14 hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary"
          theme="transparent"
          onClick={() => contactPerson?.firstName ? callback() : setFormData(initialFormData)}
          name="Cancel"
        />
        <Button
          name="Save"
          theme="blue"
          loading={loading || updatingBasicInfo}
          className="w-28 h-10"
          onClick={contactPerson?.firstName ? editContactInfo : editProfile}
        />
      </div>
    </div>
  );
};

export default EditProfile;
EditProfile.propTypes = {
  primaryAddress: PropTypes.string,
  contactPerson: PropTypes.object,
  callback: PropTypes.func
};
