import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'components/Slider';
import RedirectIfToken from 'HOC/RedirectIfToken';
import { Button } from 'components/Buttons';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import { useNavigate } from 'react-router-dom';

export default function AuthLayout({ center, right, showBackButton }) {
  const navigate = useNavigate();
  
  return (
    <RedirectIfToken>
      <div className={`${center ? 'block' : 'flex'} relative py-0 h-screen`}>
        <div className="hidden lg:block w-1/2 bg-blue h-screen left-0 top-0 z-10 overflow-y-scroll">
          <Slider />
        </div>
        {center && <div className="md:px-32 px-4">{center}</div>}
        {right && (
            <div className="w-full lg:w-1/2 h-full md:flex overflow-x-hidden overflow-y-auto justify-center items-center py-3">
              <div className="px-6  md:hidden block">
              {showBackButton && <Button theme="transparent" className="w-[82px] h-8" type="button" onClick={() => navigate(-1)}>
                  <>
                    <ArrowBack fill="#748181" className="w-[7px] h-[12px] mr-[13.5px]" />
                    Back
                  </>
                </Button> }
              </div>
              <div className="md:mx-auto">
                <div className="w-full sm:max-w-[450px] px-6 md:mx-auto">{right}</div>
              </div>
            </div>
        )}
      </div>
    </RedirectIfToken>
  );
}

AuthLayout.propTypes = {
  center: PropTypes.element,
  showBackButton: PropTypes.any ,
  right: PropTypes.element,
};
