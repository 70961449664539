import { SlideUp } from 'components/Animations';
import SubNavbar from 'components/SubNavbar';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from 'components/Navbar';
import Spinner from 'components/Spinner';
import { usePolicyState } from 'store/modules/policies';
import { motion } from 'framer-motion';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { userInitals } from 'utils/ImagePlaceholder';
import { Button } from 'components/Buttons';
import dayjs from 'dayjs';
import { ReactComponent as Timer } from 'assets/icons/timer.svg';
import { ReactComponent as Edit } from 'assets/svg/edit-icon.svg';
import Avatar from 'components/Avatar/Avatar';
import PolicyCard from './Component/Card';
import CreatePolicy from './CreatePolicy';
import Restricted from 'HOC/Restricted';

const ViewPolicy = () => {
  const navigate = useNavigate();
  const [viewType, setViewType] = useState('view');
  const { type } = useParams();
  const { currentPolicy, policies } = usePolicyState();
  const [selectedPolicy, setSelectedPolicy] = useState();
  const policyType = `current${type?.charAt(0).toUpperCase() + type?.slice(1)}`;
  const navTitle = type?.split(/(?=[A-Z])/)?.join(' ');

  return (
    <>
      <Navbar title="Policies" />
      <SubNavbar
        previous="Policies"
        prePrev={viewType === 'view' ? '' : `${navTitle}`}
        current={viewType === 'edit' ? `Edit ${navTitle}` : `${navTitle}`}
        onClick={() => navigate('/settings/policies')}
        prevOnClick={() => setViewType('view')}
      />
      <Restricted module='Policy' permission='Edit policy'>
        <>
          {!policies?.[type]?.length ? (
            <Spinner height="h-[80vh]" />
          ) : (
            <SlideUp>
              {viewType === 'view' && (
                <div className="p-6">
                  <div className="border border-[#153E4C14] bg-primary_tint_50 rounded-lg p-6">
                    <div className="flex space-x-2.5 items-center">
                      <p className="font-campton_m text-14 text-neutral_black capitalize">{navTitle}</p>
                      <Restricted module='Policy' permission='Edit policy'>
                        <Edit
                          className="shrink-0 cursor-pointer"
                          onClick={() => setViewType('edit')}
                          data-testid="edit-icon"
                        />
                      </Restricted>
                    </div>
                    <p className="font-campton_r text-neutral_body text-12 mt-2 capitalize">
                      {currentPolicy?.[policyType]?.description}
                    </p>
                    {type === 'billingPolicy' && (
                      <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-6">
                        <PolicyCard title="Payment Plan" variant="border-l-0">
                          <>
                            {currentPolicy?.[policyType]?.paymentPlan?.fullPayment && <p>Full Payment;</p>}
                            {currentPolicy?.[policyType]?.paymentPlan?.installment && (
                              <p>
                                Installments Payment{' '}
                                <span className="text-brand_secondary">
                                  ({`${currentPolicy?.[policyType]?.paymentPlan?.installmentPercentage}% of total bill`})
                                </span>
                              </p>
                            )}
                          </>
                        </PolicyCard>
                        <PolicyCard title="Payment Due Date">
                          <p>{`${currentPolicy?.[policyType]?.daysAfterBilledDate} Days after billed date`} </p>
                        </PolicyCard>
                        <PolicyCard title="Late Payment" variant="">
                          <p> {`${currentPolicy?.[policyType]?.latePayment?.feePercentage}% of total bill (per) Day`}</p>
                        </PolicyCard>
                        <PolicyCard title="Payment Method" variant="">
                          <p>
                            <span>{`${currentPolicy?.[policyType]?.paymentMethod?.cash ? 'Cash,' : ''}`}</span>
                            <span>{`${currentPolicy?.[policyType]?.paymentMethod?.card ? 'Card, ' : ''}`}</span>
                            <span>{`${currentPolicy?.[policyType]?.paymentMethod?.insurance ? 'Insurance Coverage, ' : ''
                              }`}</span>
                          </p>
                        </PolicyCard>
                      </div>
                    )}
                    {type === 'appointmentPolicy' && (
                        <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6">
                          <PolicyCard title="Appointment Rescheduling" variant="border-l-0">
                            <p>{`${currentPolicy?.[policyType]?.reschedulingTimeframeDays ?? 0
                              } day(s) before appointment`}</p>
                          </PolicyCard>
                          <PolicyCard title="Appointment Cancellation" variant='md:pl-6 pl-0 md:mt-0 mt-6 md:border-l border-l-0'>
                            <p>{currentPolicy?.[policyType]?.cancellationFeePercentage ?? 0}% charge of total bill </p>
                          </PolicyCard>
                          <PolicyCard title="No- show" variant='lg:pl-6 pl-0 lg:mt-0 mt-6 lg:border-l border-l-0'>
                            <p>{currentPolicy?.[policyType]?.noShowPolicy === 'yes' ? '' : 'Non-'}refundable</p>
                          </PolicyCard>
                        <PolicyCard title='Late Arrivallll' variant='md:border-l lg:border-l-0 md:pl-6 lg:pl-0 mt-6'>
                          <p>{currentPolicy?.[policyType]?.lateArrivalPolicy}</p>
                        </PolicyCard>
                        </div>
                    )}
                  </div>
                  <div className="pt-6 border-t mt-6 border-neutral_stroke_2 border-dashed">
                    <div className="mt-6">
                      <p className="text-14 font-campton_m text-neutral_black">Activity Timeline</p>
                      <p className="text-12 text-neutral_body font-campton_r mt-1">
                        This timeline shows users who has updated the billing policy over time
                      </p>
                      <motion.div
                        initial={{ opacity: 0, y: '10px' }}
                        animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
                        exit={{ opacity: 0 }}
                      >
                        {policies?.[type]?.map((item) => (
                          <div
                            className="relative pl-6 pb-4 border-l-[1px] border-dashed border-neutral_stroke_2 ml-4 mt-6 w-full"
                            key={item._id}
                          >
                            <div className="absolute top-0 left-0 translate-x-[-50%] translate-y-[15%]">
                              <Timer className="translate-y-[-50%]" />
                            </div>
                            <div className="text-neutral_body font-campton_r ml-2">
                              <p className="text-12 mb-2">{dayjs(item?.updatedAt).format('MMM DD, YYYY, hh:mm A')}</p>
                              <div>
                                <p className="text-12 text-neutral_body font-campton_r">Modified By</p>
                                <div className="flex md:flex-row flex-col justify-between pr-4">
                                  <div className="flex space-x-2 mt-2">
                                    <Avatar
                                      url={item?.createdBy?.profilePic}
                                      width="40px"
                                      height="40px"
                                      alt={item?.createdBy?.firstName?.split('')[0]}
                                      initials={userInitals(
                                        item?.createdBy?.firstName || '',
                                        item?.createdBy?.lastName || '',
                                      )}
                                      className="bg-brand_secondary w-8 h-8"
                                    />
                                    <div>
                                      <p className="font-campton_m text-neutral_black text-14 capitalize">
                                        {item?.createdBy?.firstName + ' ' + item?.createdBy?.lastName || 'N/A'}
                                      </p>
                                      <p className="text-12 font-campton_r text-neutral_body mt-0.5">
                                        {item?.createdBy?.emailAddress || 'N/A'}
                                      </p>
                                    </div>
                                  </div>
                                  <Button
                                    className="w-fit h-8 md:mt-0 mt-3"
                                    theme="transparent"
                                    onClick={() => setSelectedPolicy(item._id)}
                                    name="View older version"
                                  />
                                </div>
                              </div>
                              {selectedPolicy === item._id && (
                                <>
                                  <div className="flex items-center space-x-6 my-1">
                                    <hr className="text-neutral_stroke_2 w-[96%]" />
                                    <span
                                      data-testid="accordion-btn"
                                      className="bg-primary_shade_300 h-6 w-6 rounded-full flex items-center justify-center cursor-pointer"
                                      onClick={() => setSelectedPolicy('')}
                                    >
                                      <Close fill="#FFFFFF" className="cursor-pointer" />
                                    </span>
                                  </div>
                                  {type === 'billingPolicy' && (
                                    <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1">
                                      <PolicyCard title="Payment Plan" variant="border-l-0">
                                        <>
                                          {item?.paymentPlan.fullPayment && <p>Full Payment;</p>}
                                          {item?.paymentPlan.installment && (
                                            <p>
                                              Installments Payment{' '}
                                              <span className="text-brand_secondary">
                                                (
                                                {`${currentPolicy?.[policyType]?.paymentPlan.installmentPercentage}% of total bill`}
                                                )
                                              </span>
                                            </p>
                                          )}
                                        </>
                                      </PolicyCard>
                                      <PolicyCard title="Payment Due Date">
                                        <p>{`${item?.daysAfterBilledDate} Days after billed date`} </p>
                                      </PolicyCard>
                                      <PolicyCard title="Late Payment" variant="">
                                        <p> {`${item?.latePayment?.feePercentage}% of total bill (per) Day`}</p>
                                      </PolicyCard>
                                      <PolicyCard title="Payment Method" variant="">
                                        <p>
                                          <span>{`${item?.paymentMethod?.cash ? 'Cash,' : ''}`}</span>
                                          <span>{`${item?.paymentMethod?.card ? 'Card, ' : ''}`}</span>
                                          <span>{`${item?.paymentMethod?.insurance ? 'Insurance Coverage, ' : ''}`}</span>
                                        </p>
                                      </PolicyCard>
                                    </div>
                                  )}
                                  {type === 'appointmentPolicy' && (
                                    <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1">
                                      <PolicyCard title="Appointment Rescheduling" variant="border-l-0">
                                        <p>{`${item.reschedulingTimeframeDays ?? 0} day(s) before appointment`}</p>
                                      </PolicyCard>
                                      <PolicyCard title="Appointment Cancellation" variant='md:pl-6 pl-0 md:border-l border-l-0 md:mt-0 mt-6'>
                                        <p>{item.cancellationFeePercentage ?? 0}% charge of total bill </p>
                                      </PolicyCard>
                                      <PolicyCard title="No- show" variant="lg:border-l border-l-0 lg:pl-6 pl-0 lg:mt-0 mt-6">
                                        <p>{item.noShowPolicy === 'yes' ? '' : 'Non-'}refundable</p>
                                      </PolicyCard>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                      </motion.div>
                    </div>
                  </div>
                </div>
              )}
              {viewType === 'edit' && <CreatePolicy action="edit" type={type} callback={() => setViewType('view')} setViewType={setViewType} />}
            </SlideUp>
          )}
        </>
      </Restricted>
    </>
  );
};

export default ViewPolicy;
