import LocationCard from 'pages/Onboarding/Components/LocationCard';
import React from 'react';
import PropTypes from 'prop-types';

const LocationList = ({ options, deleteLocation, handleMakePrimaryAddress }) => {

  return (
    <div className="border-t border-dashed border-neutral_stroke pt-6 flex overflow-x-auto w- space-x-6">
      {options?.map((item, index) => (
        <LocationCard
          data={item}
          key={item.address}
          removeLocationFunc={() => deleteLocation(item.address)}
          onClick={() => handleMakePrimaryAddress(index)}
        />
      ))}
    </div>
  );
};

export default LocationList;
LocationList.propTypes = {
  options: PropTypes.array,
  deleteLocation: PropTypes.func,
  handleMakePrimaryAddress: PropTypes.func
};
