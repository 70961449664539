import { createSlice } from '@reduxjs/toolkit';
import { getCurrentPolicy, getPolicies } from './actions';
import { useAppSelector } from 'hooks/useReduxHook';

const initialState = {
  policies: [],
  currentPolicy: {},
};

export const policy = createSlice({
  name: 'policy',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPolicies.fulfilled, (state, action) => {
        state.policies = action?.payload?.data;
      })
      .addCase(getCurrentPolicy.fulfilled, (state, action) => {
        state.currentPolicy = action?.payload?.data;
      })
  },
});
// Selectors
const selectPolicies = (state) => state.policy;
//App Redux State
export const usePolicyState = () => useAppSelector(selectPolicies);

export default policy.reducer;
