import React from "react"
import PropTypes from "prop-types"
import Accordion from "components/Accordion"
import { VitalSignItem } from "components/Cards"
import { ReactComponent as Blood } from "assets/icons/drop.svg"
import { ReactComponent as Temperature } from "assets/icons/temperature.svg"
import { ReactComponent as StickFigure } from "assets/icons/stick-figure.svg"
import { ReactComponent as HeartRate } from "assets/icons/heart-rate.svg"
import { ReactComponent as Oxygen } from "assets/icons/oxygen.svg"
import { ReactComponent as Ruler } from "assets/icons/ruler.svg"
import { ReactComponent as Stethoscope } from "assets/icons/stethoscope.svg"

export default function VitalSigns({ vitals }) {
    return (
        <Accordion
            title="Vital Signs"
            display={false}
        >
            <div className="mt-[16px] grid sm:grid-cols-2 lg:grid-cols-3 gap-[24px]">
                <VitalSignItem name="Blood Pressure" value={vitals?.sysBloodPressure && `${vitals?.sysBloodPressure}/${vitals?.diaBloodPressure}mmHg`} icon={<Blood/>}/>
                <VitalSignItem name="Heart Rate" value={vitals?.heartRate && `${vitals?.heartRate}bpm`} icon={<HeartRate/>}/>
                <VitalSignItem name="Respiratory Rate" value={vitals?.respiratoryRate && `${vitals?.respiratoryRate}bpm`} icon={<Stethoscope/>}/>
                <VitalSignItem name="Temperature" value={vitals?.temperature && `${vitals?.temperature}°C`} icon={<Temperature/>}/>
                <VitalSignItem name="Oxygen Saturation" value={vitals?.oxygenSaturation && `${vitals?.oxygenSaturation}%`} icon={<Oxygen/>}/>
                <VitalSignItem name="Height" value={vitals?.height && `${vitals?.height}meters`} icon={<Ruler/>}/>
                <VitalSignItem name="Weight" value={vitals?.weight && `${vitals?.weight}kg`} icon={<StickFigure fill="#DF3939"/>}/>
                <VitalSignItem name="BMI" value={vitals?.bmi && `${Number(vitals?.bmi)?.toFixed(2)}`} icon={<StickFigure fill="#F59E0B"/>}/>
            </div>
        </Accordion>
    )
}

VitalSigns.propTypes = {
    vitals: PropTypes.object
}