import React from "react";
import PropTypes from "prop-types"
import { motion } from "framer-motion"

export default function HorizontalMenu({ tabs, setCurrentTab, currentTab, containerClass }) {
    return (
        <div className={`flex px-[24px] border-b border-b-neutral_stroke_2 overflow-x-auto no-scrollbar bg-neutral_white ${containerClass} z-20`}>
            {tabs?.map((tab) =>
                <div key={tab?.id} className="mr-[16px]">
                    <button
                        data-testid={tab?.name}
                        onClick={() => tab?.method ? tab?.method() : setCurrentTab(tab?.value ?? tab?.name)}
                        className={`
                                ${currentTab === tab?.name ? 'text-brand_secondary font-campton_m' : 'text-neutral_body font-campton_r'}
                                text-14 cursor-pointer hide_tap whitespace-nowrap pt-[16px] pb-[11px]
                                capitalize`}
                    >
                        {tab?.name}
                    </button>
                    {currentTab === (tab.value ?? tab?.name) ?
                        <motion.div
                            initial={{ opacity: 0, y: "10px" }}
                            animate={{ opacity: 1, y: 0, transition: { type: 'spring', duration: 1 } }}
                            exit={{ opacity: 0 }}
                            className="h-[4px] rounded-t-[8px] bg-brand_secondary top-0">
                        </motion.div> : ''}
                </div>
            )}
        </div>
    )
}

HorizontalMenu.propTypes = {
  tabs: PropTypes.array,
  setCurrentTab: PropTypes.func,
  currentTab: PropTypes.string,
  containerClass: PropTypes.string
};
