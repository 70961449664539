import useAuth from 'hooks/fetch/useAuth';
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'store/modules/auth';
import { Button } from 'components/Buttons';
import PropTypes from 'prop-types';
import { checkEmptyProperties } from 'utils/checkEmptyProperties';
import useLocations from 'hooks/fetch/useLocations';
import useTable from 'hooks/useTable';
import LocationList from '../LocationList';
import LocationsForm from '../LocationsForm';
import useCountry from 'hooks/fetch/useCountry';

const CreateLocation = ({ callback, type, locationDetails }) => {
  const { params } = useTable();
  const { get_countries, loading: isLoadingCountry } = useCountry()
  const { get_cities, cities, loading: isLoadingCities } = useCountry()
  const { get_states, states, loading: isLoadingStates } = useCountry()
  const [locations, setLocations] = useState([]);
  const { create_location, loading } = useAuth();
  const { edit_location, get_locations } = useLocations();
  const [formData, setFormData] = useState({
    state: locationDetails?.state || '',
    city: locationDetails?.city || '',
    address: locationDetails?.address || '',
    country: locationDetails?.country || '',
    contactPersonInformation: {
      name: locationDetails?.contactPersonInformation?.name || '',
      emailAddress: locationDetails?.contactPersonInformation?.emailAddress || '',
    },
    isPrimaryAddress: false,
  });
  const { institutionId } = useAuthState();
  const pathname = window.location.pathname;
  const [phone, setPhone] = useState({
    phoneNumber: type === 'Edit' && locationDetails?.contactPersonInformation?.phoneNumber ? locationDetails?.contactPersonInformation?.phoneNumber : '',
    countryCode: type === 'Edit' && locationDetails?.contactPersonInformation?.countryCode ? locationDetails?.contactPersonInformation?.countryCode : '',
  });

  useEffect(() => {
    get_countries()
  }, [])

  useEffect(() => {
    if (formData?.country) get_states({ country: formData?.country })
  }, [formData?.country]);

  useEffect(() => {
    if (formData?.state) get_cities({ country: formData?.country, state: formData?.state });
  }, [formData?.state]);

  const newFormData = {
    ...formData,
    contactPersonInformation: {
      ...formData.contactPersonInformation,
      countryCode: phone?.countryCode,
      phoneNumber: phone?.phoneNumber,
    },
  };

  const deleteLocation = (address) => {
    const newLocations = [...locations];
    const idx = newLocations.findIndex((item) => item.address === address);
    newLocations.splice(idx, 1);
    setLocations(newLocations);
  };

  const handleMakePrimaryAddress = (index) => {
    const newLocations = [...locations];
    newLocations.forEach((location, id) => {
      if (id === index) {
        newLocations[index] = { ...newLocations[index], isPrimaryAddress: !newLocations[index].isPrimaryAddress };
        return;
      }
      location.isPrimaryAddress = false;
    });
    setLocations(newLocations);
  };

  const pushIntoArray = () => {
    setLocations((prev) => [...prev, { ...newFormData, isPrimaryAddress: !locations.length }]);
    setFormData({
      state: '',
      city: '',
      address: '',
      country: '',
      contactPersonInformation: {
        name: '',
        emailAddress: '',
      },
    })
    setPhone({ phoneNumber: '' })
  };

  const disabledBtn = () => {
    return !formData.state || !formData.city || !formData.address || !formData.country;
  };

  const editLocationCallback = () => {
    get_locations({ page: params.page, limit: params.limit });
    callback();
  };

  const creatLocation = () => {
    const payload = {
      locationArrayData: pathname.includes('settings') ? [{ ...newFormData, isPrimaryAddress: false }] : locations,
      institutionId,
      type: 'institution'
    };
    type === 'Create'
      ? create_location(payload, pathname.includes('settings') ? editLocationCallback : callback)
      : edit_location(
        `${institutionId}/location/${locationDetails?._id}`,
        { ...newFormData, isPrimaryAddress: locationDetails?.isPrimaryAddress },
        editLocationCallback,
      );
  };

  const handleContactInfo = (e) => {
    const updatedFormData = {
      ...formData,
      contactPersonInformation: {
        ...formData.contactPersonInformation,
        [e.target.name]: e.target.value,
      },
    };
    setFormData(updatedFormData);
  };

  return (
    <div className="relative">
      <div className='modal-right-body'>
        <LocationsForm
          locationDetails={locationDetails}
          formData={formData}
          setFormData={setFormData}
          type={type}
          phone={phone}
          isLoadingCountry={isLoadingCountry}
          isLoadingCities={isLoadingCities}
          isLoadingStates={isLoadingStates}
          states={states}
          cities={cities}
          setPhone={setPhone}
          locations={locations}
          handleContactInfo={handleContactInfo}
        />
        {!pathname.includes('settings') && (
          <button
            className={`text-brand_secondary font-campton_m text-14 w-fit mt-4 mb-4 ${disabledBtn() ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
            data-testid="test-add"
            disabled={disabledBtn()}
            onClick={pushIntoArray}
          >
            Add
          </button>
        )}
        {locations.length > 0 && (
          <LocationList
            options={locations}
            deleteLocation={deleteLocation}
            handleMakePrimaryAddress={handleMakePrimaryAddress}
          />
        )}
      </div>
      <div className="modal-right-button-container">
        <Button
          className="w-28 rounded-full h-10 py-0 px-0 bg-brand_primary font-campton_m text-neutral_white"
          theme="blue"
          name={type === 'Create' ? 'Create' : 'Save'}
          disabled={pathname.includes('settings') ? (checkEmptyProperties(formData) || !phone.phoneNumber || !phone.countryCode) : locations.length <= 0}
          loading={loading}
          onClick={creatLocation}
        />
      </div>
    </div>
  );
};

export default CreateLocation;
CreateLocation.propTypes = {
  callback: PropTypes.func,
  type: PropTypes.string,
  locationDetails: PropTypes.object,
};
