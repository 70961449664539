import React from "react";
import PropTypes from "prop-types"
import Avatar from "components/Avatar/Avatar";
import { userInitals } from "utils/ImagePlaceholder";

export default function Overview({ formData, patient, admittedBy }) {
    return (
        <section
            className={`
            bg-brand_primary rounded-[16px] w-full
            relative bg-[url('/src/assets/svg/frame.svg')] bg-no-repeat bg-cover
            `}
        >
            <div className="w-full h-full p-[24px]">
                <div className="flex mb-[16px]">
                    <Avatar
                        url={patient?.profilePic}
                        width="32px"
                        height="32px"
                        alt="profile"
                        initials={userInitals(patient?.firstName, patient?.lastName)}
                        className="w-[32px] h-[32px] shrink-0 bg-brand_secondary"
                    />
                    <div className="text-neutral_white ml-[12px]">
                        <p className="text-14 font-campton_m capitalize leading-[21px]">{patient?.firstName} {patient?.lastName}</p>
                        <p className="text-12 font-campton_r ellipsis_2_lines leading-[16px]">
                            {patient?.emailAddress} | {patient?.gNumber}
                        </p>
                    </div>
                </div>
                <div className="grid sm:grid-cols-3 mt-[16px]">
                    <div className="pr-[16px] border-r border-r-[#eceeee2f] mb-[16px] sm:mb-0">
                        <p className="text-12 font-campton_r text-[#C7E5EF] mb-[4px]">Location & Room Number</p>
                        <p className="text-14 font-campton_r text-neutral_white">
                            <span className="capitalize">{formData?.location}</span> | {formData?.roomNumber}
                        </p>
                    </div>
                    <div className="sm:px-[16px] border-r border-r-[rgba(236,238,238,0.18)] mb-[16px] sm:mb-0">
                        <p className="text-12 font-campton_r text-[#C7E5EF] mb-[4px]">Admission Diagnosis</p>
                        <p className="text-14 font-campton_r text-neutral_white capitalize">{formData?.admissionDiagnosis}</p>
                    </div>
                    <div className="sm:px-[16px]">
                        <p className="text-12 font-campton_r text-[#C7E5EF] mb-[4px]">Admitted By</p>
                        <p className="text-14 font-campton_r text-neutral_white capitalize">{admittedBy?.firstName} {admittedBy?.lastName}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

Overview.propTypes = {
    formData: PropTypes.object,
    patient: PropTypes.object,
    admittedBy: PropTypes.object
}