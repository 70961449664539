import { Input, TextArea } from 'components/Inputs';
import React, { useContext, useEffect, useState } from 'react';
import FormWrapper from 'components/FormWrapper';
import Dropdown from 'components/Dropdown';
import { Button } from 'components/Buttons';
import PropTypes from 'prop-types';
import usePatient from 'hooks/fetch/usePatient';
import useDebounce from 'hooks/useDebounce';
import useAuth from 'hooks/fetch/useAuth';
import dayjs from 'dayjs';
import { useAuthState } from 'store/modules/auth';
import { useParams } from 'react-router-dom';
import useProviders from 'hooks/fetch/useProviders';
import { useDispatch } from 'react-redux';
import useHandleError from 'hooks/useHandleError';
import { notify } from 'store/modules/global';
import { PatientContext } from 'context';

const AddReferrals = ({ callback, singleVisit }) => {
  const { get_institution, loading: loadingInstitution } = useAuth();
  const { institutionId } = useAuthState();
  const { id } = useParams();
  const [provider, setProvider] = useState([]);
  const { get_institution_providers, loading: loadingProvider } = useProviders();
  const dispatch = useDispatch();
  const { handleError } = useHandleError();
  const { progressNote, getSingleProgressNoteProxy } = useContext(PatientContext)

  const [formData, setFormData] = useState({
    reason: '',
    providerName: '',
    providerId: '',
  });
  const [institution, setInstitution] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState();
  const { refer_patient, loading, get_referrals } = usePatient();
  const { debouncedValue } = useDebounce(formData.institution);

  useEffect(() => {
    get_institution().then((res) => {
      setInstitution(
        res?.data.map((item) => ({
          id: item?._id,
          name: item.businessName,
          value: item.businessName,
        })),
      );
    });
  }, [debouncedValue]);
  useEffect(() => {
    if (selectedInstitution?.name) {
      get_institution_providers({ id: `${selectedInstitution.id}/providers` }).then((res) => {
        if (res?.code === 200)
          return setProvider(
            res?.data?.providers.map((item) => ({
              id: item?._id,
              name: `${item.firstName} ${item.lastName}`,
              value: `${item.firstName} ${item.lastName}`,
            })) || [],
          );
        dispatch(
          notify({
            display: true,
            status: 'error',
            message: handleError({ status: res?.code, message: res?.message }),
          }),
        );
        setProvider([]);
      });
    }
  }, [selectedInstitution]);

  const handleCallback = () => {
    const params = {
      type: 'visit',
      visitId: progressNote?.visit ?? id,
      institutionId,
    };
    callback();
    get_referrals(params);
    if(progressNote) {
    getSingleProgressNoteProxy()
  }
  };
  const referPatient = () => {
    refer_patient(
      {
        visitId: progressNote?.visit ?? id,
        patientId: progressNote?.patient?._id ?? singleVisit?.patient?._id ,
        reason: formData.reason,
        providerId: formData.providerId,
        progressNoteId: progressNote?._id ?? undefined
      },
      handleCallback,
    );
  };

  return (
    <FormWrapper className=" flex justify-between flex-col h-full">
      <div className="grid gap-2">
        <Dropdown
          type="search"
          id="institution"
          label={loadingInstitution ? 'Fetching institution...' : 'Healthcare institution'}
          name="institution"
          variant="mt-0"
          options={institution}
          selected={selectedInstitution?.name}
          onSelect={(data) => {
            setSelectedInstitution(data);
          }}
        />
        <Dropdown
          label={loadingProvider ? `Fetching providers...` : 'Select Provider'}
          type="select"
          id="provider"
          name="provider"
          variant="mt-4"
          disabled={!selectedInstitution || loadingProvider}
          options={provider}
          selected={formData?.providerName}
          onSelect={(data) => {setFormData({ ...formData, providerId: data.id, providerName: data.name })}}
        />
        <Input
          label="Date"
          type="text"
          id="date"
          name="date"
          variant="mt-4"
          value={dayjs(new Date()).format('MMMM DD, YYYY')}
          disabled={true}
        />
        <TextArea
          label="Reason for Referral"
          type="text"
          id="referralReason"
          rows={4}
          variant="mt-2"
          name="referralReason"
          readOnly={false}
          value={formData?.reason}
          onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
        />
      </div>
      <div className="flex mt-1 space-x-4 justify-end">
        <Button
          className="w-20 h-10
              text-14 hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary  px-2 py-1"
          theme="transparent"
          onClick={callback}
          name="Cancel"
        />
        <Button
          name="Save"
          theme="blue"
          loading={loading}
          className="w-[104px] h-10 py-2 px-8"
          onClick={referPatient}
        />
      </div>
    </FormWrapper>
  );
};

export default AddReferrals;
AddReferrals.propTypes = {
  callback: PropTypes.func,
  singleVisit: PropTypes.object,
};
