/* eslint-disable max-len */
import React, { useState } from 'react';
import { ReactComponent as HeartRate } from 'assets/icons/vitals/heartbeat.svg';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Modal from 'components/Modals/Modal';
import ViewSingleVitals from '../ViewSingleVitals/ViewSingleVitals';
import EmptyState from 'components/Table/EmptyState';
import Spinner from 'components/Spinner';
import { extractPatientVitals } from 'utils/extractPatientVitals';

export default function History({ vitals, loading }) {
  const [selectedVitalSign, setSelectedVitalSign] = useState();
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (item) => {
    setSelectedVitalSign(item);
    setShowModal(true);
  };

  return (
    <div className="mt-6">
      <p className="font-campton_sb text-18 text-black_100 mb-2">History</p>
      <div className="mt-4">
        {vitals?.length ? vitals?.map((item) =>
          <List
            key={item?._id}
            item={item}
            handleShowModal={handleShowModal}
          />) : ''
        }
        {!vitals?.length && (loading ? <Spinner /> : <EmptyState title="No records found." />)}
      </div>
      {showModal && (
        <Modal
          closeModal={() => setShowModal(false)}
          styles="w-full md:w-[457px]"
          position="modal-right"
          variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
          title="Vital Signs"
        >
          <ViewSingleVitals vitalSign={selectedVitalSign} />
        </Modal>
      )}
    </div>
  );
}

const List = ({ item, handleShowModal }) => {
  const recordedVitals = extractPatientVitals(item)

  return (
    <div
      data-testid={item?.createdAt}
      className="flex justify-between py-4 first-of-type:pt-0 border-b last-of-type:border-b-0 border-neutral_stroke_2 cursor-pointer"
      onClick={() => handleShowModal(item)}
    >
      <div className="flex font-campton_r lg:text-14 text-12 mr-[8px]">
        <HeartRate className='shrink-0 mr-[10px]' />
        <div>
          <p className="font-campton_m">{dayjs(item?.updatedAt).format('MMM DD, YYYY')}</p>
          <p className="card-description text-neutral_body">{recordedVitals?.join(", ")}.</p>
          <p className="text-accent_blue border border-neutral_stroke_2 px-2 h-7 rounded-full flex justify-center items-center mt-2 w-fit">
            {item?.recordedBy?.businessName || `${item?.recordedBy?.firstName + ' ' + item?.recordedBy?.lastName}`}
          </p>
        </div>
      </div>
      <p className="text-12 text-neutral_body font-campton_r min-w-max">{dayjs(item?.updatedAt).format('hh:mm A')}</p>
    </div>
  )
}

List.propTypes = {
  item: PropTypes.object,
  handleShowModal: PropTypes.func,
};
History.propTypes = {
  vitals: PropTypes.array,
  loading: PropTypes.bool,
};
