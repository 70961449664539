import Accordion from "components/Accordion"
import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { ReactComponent as Uncheck } from 'assets/icons/check-box-unselected.svg';
import { ReactComponent as Check } from 'assets/icons/check-box-selected.svg';
import Modal from "components/Modals/Modal";
import { Button } from "components/Buttons";

export default function WalletFilter({ closeFilterModal, filterParams, setFilterParams, setAddFilter }) {
    const types = ['debit', 'credit']
    const [formData, setFormData] = useState(filterParams)

    const filter = () => {
        setFilterParams(formData)
        setAddFilter()
        closeFilterModal()
    }

    const selectType = (transactionType) => {
        if (transactionType === formData.transactionType)
            setFormData({ ...formData, transactionType: '' })
        else
            setFormData({ ...formData, transactionType })
    }

    const disabled = useMemo(() => {
        return Object.keys(formData).every(key => formData[key] === '')
    }, [formData])

    return (
        <Modal
            closeModal={closeFilterModal}
            styles="w-full md:w-[457px]"
            position="modal-right"
            title="Filter"
            variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
        >
            <div>
                <Accordion display={false} title="By Type" className="border-b pb-4 border-neutral_stroke_2 mb-4" variant="mt-3" titleClassName='text-12'>
                    <div className="bg-[#F7F8F8] p-4 rounded-lg">
                        {types.map((item) => (
                            <div
                                key={item}
                                className={`${item === 'all' ? 'border-b border-dashed border-neutral_stroke_2 py-2' : 'py-3'
                                    } flex`}
                                onClick={() => selectType(item)}
                            >
                                <div data-testid={item} className="cursor-pointer hide_tap">
                                    {formData.transactionType === item ? <Check /> : <Uncheck />}
                                </div>
                                <p className="text-14 text-neutral_black font-campton_r ml-[8px] capitalize">{item}</p>
                            </div>
                        ))}
                    </div>
                </Accordion>
                <div className="flex justify-end absolute bottom-0 left-0 p-[24px] bg-neutral_white w-full">
                    <Button
                        name="Cancel"
                        theme="transparent"
                        type="button"
                        onClick={closeFilterModal}
                        className="h-[40px] text-16 w-[79px] mr-[16px]"
                    />
                    <Button
                        name="Filter"
                        theme="blue"
                        onClick={filter}
                        disabled={disabled}
                        className="h-[40px] text-16 w-[128px]"
                    />
                </div>
            </div>
        </Modal>
    )
}

WalletFilter.propTypes = {
    filterParams: PropTypes.object,
    setFilterParams: PropTypes.func,
    setAddFilter: PropTypes.func,
    closeFilterModal: PropTypes.func
}