const days = {
    'Monday': 'mon',
    'Tuesday': 'tue',
    'Wednesday': 'wed',
    'Thursday': 'thu',
    'Friday': 'fri',
    'Saturday': 'sat',
    'Sunday': 'sun'
}

export const checkOffDays = (workingDays) => {
    if (!workingDays?.length) return ""
    return Object?.keys(days)?.filter(day => !workingDays.includes(day)).map(item => days[item]).join(', ') || "N/A"
}

export const checkWorkingDays = (workingDays) => {
    if (!workingDays?.length) return ""
    return workingDays?.map(item => days[item]).join(', ') || "N/A"
}