import React from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"

export default function AnimateList({ children, index }) {
    return (
        <motion.div
            initial={{ opacity: 0, y: "10px" }}
            animate={{ opacity: 1, y: 0, transition: { duration: .5, delay: index === 0 ? 0 : (.3 * index) } }}
            exit={{ opacity: 0 }}
        >
            {children}
        </motion.div>
    )
}

AnimateList.propTypes = {
    children: PropTypes.element,
    index: PropTypes.number
}