import React, { useEffect, useMemo, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { chart_options } from 'mocks/billAndPayments';
import Status from 'components/Status';
import PaymentStatus from './PaymentStatus';
import { useAuthState } from 'store/modules/auth';
import usePayments from 'hooks/fetch/usePayments';
import { formatCurrency } from 'utils/formatCurrency';
import { usePaymentState } from 'store/modules/payment';
import { month_names } from 'mocks/date';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const chartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  animation: { duration: 1000 },
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

export default function Chart() {
  const [chartFilter, setChartFilter] = useState('monthly');
  const { user, institutionId } = useAuthState();
  const { get_revenue } = usePayments();
  const { revenue } = usePaymentState();

  const chart = useMemo(() => {
    let labels = [''];
    let data = [''];
    revenue?.revenue?.map((item) => {
      labels.push(chartFilter === 'monthly' ? Object.keys(month_names)[item.month - 1] : item?.year);
      data.push(item?.totalRevenue);
      return item;
    });
    return { labels, data };
  }, [revenue?.revenue]);

  const labels = chart.labels;
  const data = {
    labels,
    datasets: [
      {
        data: chart.data,
        backgroundColor: '#fff9b320',
        borderColor: '#23CE6B',
        //borderWidth: 2,
        borderCapStyle: 'round',
        borderDashOffset: [10],
        //fill: true,
        lineTension: '0.4',
        pointBackgroundColor: '#23CE6B',
        pointRadius: 0,
        pointHitRadius: 10,
      },
    ],
  };

  useEffect(() => {
    get_revenue({
      type: 'institution',
      institutionId: institutionId ?? undefined,
      providerId: user?.businessName ? undefined : user?._id,
      filter: chartFilter,
    });
  }, [chartFilter]);

  return (
    <section className="xl:flex p-[24px] border-b border-b-neutral_stroke_2">
      <div className="flex-1 px-[24px] pt-[28px] pb-[10px] border border-neutral_stroke_2 rounded-[16px] mb-[24px] xl:mb-0 xl:mr-[24px]">
        <div className="flex items-center justify-between mb-[21px]">
          <div>
            <h3 className="text-14 text-neutral_body font-campton_m mb-[2px]">Revenue</h3>
            <p className="text-14 text-neutral_black font-campton_m">{formatCurrency(revenue?.totalAmountPaid || 0)}</p>
          </div>
          <div className="flex items-center">
            {chart_options.map((item) => (
              <Status
                key={item.id}
                status={item.name}
                onClick={() => setChartFilter(item.value)}
                className={`
                    ${chartFilter === item.value
                    ? 'bg-brand_primary text-neutral_white cursor-default'
                    : 'border border-neutral_stroke_2 cursor-pointer text-[#143566]'
                  } px-[12px] mr-[12px] text-14
                `}
              />
            ))}
          </div>
        </div>
        <div className="flex justify-center items-center h-[210px] mb-[24px]">
          <Line
            data={data}
            options={chartOptions}
          />
        </div>
      </div>
      <PaymentStatus />
    </section>
  );
}
