import React from "react"
import PropTypes from "prop-types"
import { Input } from "components/Inputs"
import Accordion from "components/Accordion"
import Switch from "components/Switch"
import { Button } from "components/Buttons"
import { ReactComponent as File } from 'assets/icons/file.svg';
import { ReactComponent as Dot } from 'assets/svg/Ellipse 4.svg';
import { AnimatePresence, motion } from "framer-motion"

export default function TestType({ handleChange, loading, isCritical, toggleCritical, testName, tests, file, setFile }) {
    const handleFileSelect = () => {
        const fileInput = document.createElement("input");
        fileInput.type = "file"
        fileInput.accept = "image/png,image/jpg,image/jpeg,application/pdf"
        fileInput.addEventListener("change", (event) => {
            const selectedFile = event.target.files[0];
            const fileExist = file.find(item => item.name === selectedFile.name)
            if (fileExist) return
            setFile([...file, selectedFile]);
        })
        fileInput.click()
    }

    const removeSelectedImage = (name) => {
        const images = file.filter(item => item?.name !== name)
        setFile(images)
    }

    return (
        <>
            <Accordion
                title={testName}
                display={true}
            >
                <>
                    <div className="mt-[20px]">
                        {tests.length ?
                            <div className="flex w-full overflow-x-auto pb-[20px]">
                                {tests?.map((t) =>
                                    <div key={t?.name} className="min-w-max">
                                        <p title={t?.name} className="text-14 text-left font-campton_r bg-[#D3EBF3] p-[12px] mb-[8px]">{t?.name}</p>
                                        <div className="w-full px-[12px]">
                                            <Input
                                                //label={t?.name}
                                                type="text"
                                                id={t?.name}
                                                name={t?.name}
                                                onChange={(e) => handleChange(t?.name, e.target.value)}
                                                value={t?.value}
                                                disabled={loading}
                                                measurement={t?.unit}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div> : ''
                        }
                        <div className="flex items-center mt-[14px]">
                            <p className="text-16 text-neutral_body font-campton_r mr-[12px]">Have an external test result instead?</p>
                            <Button
                                name="Upload file"
                                theme="transparent"
                                type="button"
                                onClick={handleFileSelect}
                                className="h-[40px] text-16 w-1159px] mr-[16px] !text-brand_secondary"
                            />
                        </div>
                        {file.length ?
                            <ul className="flex flex-wrap mt-[24px]">
                                <AnimatePresence>
                                    {file?.map(item =>
                                        <motion.li
                                            initial={{ opacity: 0, y: "10px" }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: "10px" }}
                                            key={item?.name}
                                            className="border border-neutral_stroke_2 py-2 px-4 flex rounded-lg w-[300px] mb-[8px] mr-[8px]"
                                        >
                                            <File className="mr-[10px] shrink-0" />
                                            <div className="flex items-center w-[80%]">
                                                <div className="text-14 font-campton_m flex-1 w-full">
                                                    <p className="mb-0 truncate w-full">{item.name}</p>
                                                    <p className="text-neutral_body text-12 flex items-center space-x-1">
                                                        {item?.size}KB <Dot className="ml-1 mb-0.5" />{' '}
                                                        <span className="text-success font-campton_r">Done</span>
                                                    </p>
                                                </div>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer shrink-0" onClick={() => removeSelectedImage(item?.name)}>
                                                    <path d="M12.854 12.1465C12.9005 12.193 12.9373 12.2481 12.9625 12.3088C12.9876 12.3695 13.0006 12.4346 13.0006 12.5003C13.0006 12.566 12.9876 12.631 12.9625 12.6917C12.9373 12.7524 12.9005 12.8076 12.854 12.854C12.8076 12.9005 12.7524 12.9373 12.6917 12.9625C12.631 12.9876 12.566 13.0006 12.5003 13.0006C12.4346 13.0006 12.3695 12.9876 12.3088 12.9625C12.2481 12.9373 12.193 12.9005 12.1465 12.854L8.00028 8.70715L3.85403 12.854C3.76021 12.9478 3.63296 13.0006 3.50028 13.0006C3.3676 13.0006 3.24035 12.9478 3.14653 12.854C3.05271 12.7602 3 12.633 3 12.5003C3 12.3676 3.05271 12.2403 3.14653 12.1465L7.2934 8.00028L3.14653 3.85403C3.05271 3.76021 3 3.63296 3 3.50028C3 3.3676 3.05271 3.24035 3.14653 3.14653C3.24035 3.05271 3.3676 3 3.50028 3C3.63296 3 3.76021 3.05271 3.85403 3.14653L8.00028 7.2934L12.1465 3.14653C12.2403 3.05271 12.3676 3 12.5003 3C12.633 3 12.7602 3.05271 12.854 3.14653C12.9478 3.24035 13.0006 3.3676 13.0006 3.50028C13.0006 3.63296 12.9478 3.76021 12.854 3.85403L8.70715 8.00028L12.854 12.1465Z" fill="#505959" />
                                                </svg>
                                            </div>
                                        </motion.li>
                                    )}
                                </AnimatePresence>
                            </ul> : ''
                        }
                    </div>
                    <div className="flex items-center mt-[24px]">
                        <Switch
                            status={isCritical ? 'active' : 'inactive'}
                            onChange={() => !loading ? toggleCritical() : {}}
                        />
                        <p className="text-14 text-neutral_black font-campton_r ml-[8px]">
                            This lab test result is critical and requires immediate attention
                        </p>
                    </div>
                </>
            </Accordion>
        </>
    )
}

TestType.propTypes = {
    formData: PropTypes.object,
    handleChange: PropTypes.func,
    toggleCritical: PropTypes.func,
    loading: PropTypes.bool,
    availableTests: PropTypes.object,
    tests: PropTypes.array,
    isCritical: PropTypes.bool,
    testName: PropTypes.string,
    file: PropTypes.array,
    setFile: PropTypes.func
}