import Navbar from 'components/Navbar';
import React, { useLayoutEffect, useState } from 'react';
import { useAuthState, setUserStatus } from 'store/modules/auth';
import Location from 'assets/svg/location.svg';
import License from 'assets/svg/license.svg';
import Contact from 'assets/svg/contact.svg';
import CompleteProfile from 'assets/svg/complete-profile.svg';
import Specialty from 'assets/svg/specialty.svg';
import Modal from 'components/Modals/Modal';
import BasicInformation from 'pages/Onboarding/Components/Forms/BasicInformation';
import UploadLicense from 'pages/Onboarding/Components/Forms/UploadLicense';
import CreateLocation from 'pages/Onboarding/Components/Forms/CreateLocation';
import ContactPersonInfo from 'pages/Onboarding/Components/Forms/ContactPersonInfo';
import { Button } from 'components/Buttons';
import { useNavigate } from 'react-router-dom';
import Onboarding from './Components/Onboarding';
import AddSpecialty from './Components/Forms/AddSpecialty';
import RedirectIfStaff from 'HOC/RedirectIfStaff';
import { useAppDispatch } from 'hooks/useReduxHook';

const Index = () => {
  const { user } = useAuthState();
  const [type, setType] = useState('');
  const navigate = useNavigate();
  const institution = user?.type === 'superAdmin';
  const dispatch = useAppDispatch();

  const onboardingSteps = [
    {
      icon: CompleteProfile,
      title: 'Complete Information',
      description: 'Complete your profile to get started!',
      btnName: 'Update',
      type: `${institution ? '' : 'white'}`,
      variant: `${institution ? 'bg-[#EFF8FB]' : 'bg-brand_primary text-neutral_white '} lg:col-span-4 col-span-12`,
      btnStyles: `${
        institution
          ? 'bg-neutral_white border-neutral_stroke_2 text-neutral_body'
          : 'bg-primary_tint_400 border-none text-neutral_white'
      }`,
      onclick: () => setType('Basic Information'),
      condition: user?.businessDescription || user?.logoUrl || user?.websiteLink || user?.businessAddress,
    },
    {
      icon: Location,
      title: 'Add Location(s)',
      description: `Add your institution's location for your patients to find you`,
      btnName: 'Create location',
      className: 'text-neutral_white',
      type: 'white',
      onclick: () => setType('Create Location(s)'),
      variant: 'lg:col-span-4 col-span-12 bg-[#36A1C5]',
      btnStyles: 'bg-primary_tint_400 border-none text-neutral_white',
      condition: user?.healthCareFacilities?.length > 0,
    },
    {
      icon: Specialty,
      title: 'Add Specialty/Department*',
      description: 'Provide organization logo, website link, healthcare facilities.',
      btnName: 'Update',
      className: 'text-neutral_white',
      onclick: () => setType('Add Specialty/Department'),
      variant: 'lg:col-span-4 col-span-12 bg-[#EFF8FB]',
      btnStyles: 'bg-neutral_white border-neutral_stroke_2 text-neutral_body',
      condition: user?.specialties?.length > 0,
    },
    {
      icon: License,
      title: `${institution ? 'License Verification' : 'Medical License'}`,
      description: `Upload institution's license image here`,
      btnName: 'Upload license',
      onclick: () => setType('Upload License (CAC)'),
      variant: `${
        institution
          ? 'bg-neutral_white border border-neutral_stroke_2 lg:col-span-6'
          : 'bg-[#EFF8FB] h-[330px] lg:col-span-4'
      } col-span-12 `,
      theme: 'transparent',
      condition: user?.CACLicenseUrl || user?.CACLicenseNumber || user?.medicalLicenseNumber || user?.medicalLicenseUrl,
    },
    {
      icon: Contact,
      title: 'Contact Person Information',
      description: 'Provide basic details of contact person for your institution',
      onclick: () => setType('Contact Person Information'),
      btnName: 'Add contact person',
      variant: `${
        institution
          ? 'bg-neutral_white border border-neutral_stroke_2 lg:col-span-6'
          : 'bg-[#EFF8FB] h-[330px] lg:col-span-4'
      } col-span-12 px-2`,
      theme: 'transparent',
      condition: user?.contactInformation?.length > 0,
    },
  ];

  const providerSteps = onboardingSteps.filter((item) => {
    return (
      item.title === 'Complete Information' ||
      item.title === 'Medical License' ||
      item.title === 'Contact Person Information'
    );
  });

  const pendingInstitutionActions = () => onboardingSteps.filter((item) => !item.condition);
  const pendingProviderActions = () => providerSteps.filter((item) => !item.condition);

  const btnDisabled = () => {
    if (institution) {
      return !pendingInstitutionActions().length > 0;
    } else if (!institution) {
      return !pendingProviderActions().length > 0;
    }
  };
  
  useLayoutEffect(() => {
    if (btnDisabled()) {
      dispatch(setUserStatus(true))
      navigate('/clinicals/patients')
    }
  }, []);

  return (
    <RedirectIfStaff>
      <div className="lg:px-8">
        <Navbar title="Onboarding" />
        <div className="px-6 pt-6 overflow-scroll lg:w-5/6 mx-auto">
          <div className="flex text-center mb-6 lg:justify-end justify-center w-full">
            <div className="lg:w-2/3 flex lg:flex-row flex-col items-center justify-between">
              <div>
                <p className="text-neutral_body text-16 font-campton_r capitalize">
                  Hello, {user?.firstName || user?.businessName}
                </p>
                <p className="md:text-24 text-20 font-campton_m text-black_100">Welcome to GabbiHealth</p>
              </div>
              <Button
                name="Proceed to Dashboard"
                theme="blue"
                className="w-fit h-10 lg:mt-0 mt-4"
                disabled={!btnDisabled()}
                onClick={() => navigate('/clinicals/patients')}
              />
            </div>
          </div>
          <Onboarding steps={institution ? onboardingSteps : providerSteps} />
        </div>
        {type && (
          <Modal
            closeModal={() => setType('')}
            styles="min-w-[90%] md:min-w-[730px]"
            title={type}
            containerVariant="bg-neutral_white"
          >
            {type === 'Create Location(s)' && <CreateLocation callback={() => setType('')} type="Create" />}
            {type === 'Basic Information' && <BasicInformation callback={() => setType('')} />}
            {type === 'Upload License (CAC)' && (
              <UploadLicense callback={() => setType('')} type={user?.businessName ? 'cac' : 'medical'} />
            )}
            {type === 'Contact Person Information' && <ContactPersonInfo callback={() => setType('')} />}
            {type === 'Add Specialty/Department' && <AddSpecialty callback={() => setType('')} action="create" />}
          </Modal>
        )}
      </div>
    </RedirectIfStaff>
  );
};

export default Index;
