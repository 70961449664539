import React from "react"
import PropTypes from "prop-types"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function CustomSkeleton({count, className, width}) {
    return(
        <SkeletonTheme width={width}>
            <Skeleton count={count} className={className} containerTestId="skeleton" />
        </SkeletonTheme>
    )
}

CustomSkeleton.propTypes = {
    count: PropTypes.number,
    className: PropTypes.string,
    width: PropTypes.string
}