import React from "react";
import PropTypes from "prop-types"
import { ReactComponent as Copy } from "assets/icons/copy.svg";
import { formatToDateWithDash } from "utils/formatDate";
import Accordion from "components/Accordion";

export default function OtherInformation({ data }) {
    return (
        <Accordion title="Other Information">
            <div className="text-14 font-campton_r rounded-[16px] py-[16px]">
                {/* <div className="flex justify-end mb-[16px]">
                    <p className="text-brand_primary border-b border-b-brand_primary cursor-pointer">See more</p>
                </div> */}
                <div className="h-[240px] overflow-auto">
                    <div className="pb-[16px] border-b border-b-neutral_stroke_2">
                        <p className="text-neutral_body mb-[4px]">Date of Birth</p>
                        <p className="text-neutral_black">{formatToDateWithDash(data?.patientInfo?.dateOfBirth)}</p>
                    </div>
                    <div className="py-[16px] border-b border-b-neutral_stroke_2">
                        <p className="text-neutral_body mb-[4px]">Marital Status</p>
                        <p className="text-neutral_black first-letter:uppercase">{data?.patientInfo?.maritalStatus || 'N/A'}</p>
                    </div>
                    <div className="py-[16px] border-b border-b-neutral_stroke_2">
                        <p className="text-neutral_body mb-[4px]">Home Address</p>
                        <p title="2464 Royal Ln. Mesa, New Jersey..." className="text-neutral_black truncate capitalize">
                            {data?.patientInfo?.homeAddress || 'N/A'}
                        </p>
                    </div>
                    <div className="pt-[16px] truncate">
                        <p className="text-neutral_body mb-[4px]">Emergency Contact</p>
                        <p className="text-neutral_black font-campton_m mb-[4px]">
                            Gabriel Inyamah <span className="text-neutral_body font-campton_r">| Brother</span>
                        </p>
                        <p title="2464 Royal Ln. Mesa, New Jersey..." className="text-neutral_body mb-[4px]">08148171182</p>
                        <div className="flex justify-between">
                            <p title="2464 Royal Ln. Mesa, New Jersey..." className="text-neutral_body">gabe@gmail.com</p>
                            <Copy className="cursor-pointer" />
                        </div>
                    </div>
                </div>
            </div>
        </Accordion>
    )
}

OtherInformation.propTypes = {
    data: PropTypes.object
}