import Dropdown from 'components/Dropdown';
import FormWrapper from 'components/FormWrapper';
import { Input, TextArea } from 'components/Inputs';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Camera } from 'assets/icons/camera-icon.svg';
import useUpload from 'hooks/useUpload';
import { useAuthState } from 'store/modules/auth';
import { institutionTypes } from 'mocks/auth';
import useAuth from 'hooks/fetch/useAuth';
import PropTypes from 'prop-types';
import { Button } from 'components/Buttons';

const BasicInformation = ({ callback }) => {
  const fileRef = useRef();
  const { user, institutionId } = useAuthState();
  const { update_basic_info, loading } = useAuth();
  const { upload, uploading } = useUpload();
  const [providerType, setProviderType] = useState('');
  const [file, setFile] = useState(null)

  const [formData, setFormData] = useState({
    websiteLink: '',
    providedServiceType: '',
    institutionId: institutionId,
    type: `${user?.businessName ? 'institution' : 'provider'}`
  })

  useEffect(() => {
    if (user?.providedServiceType) {
      setProviderType(user?.providedServiceType);
    }
  }, [user]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const updateBasicInfo = () => {
    const timestamp = dayjs()?.format('YYYYMMDD-HHmmss');
    const payload = { logo: `${timestamp}${fileRef.current.files[0]?.name}` }
    if (fileRef.current.files[0]) {
      upload(fileRef.current.files[0], payload, timestamp, null, false)
        .then((res) => {
          if (res?.response?.status === 200)
            update_basic_info({ ...formData, logoUrl: res?.imageUrl }, callback)
        });
      return
    }
    update_basic_info({ ...formData }, callback)
  };

  const disabledBtn = () => {
    if (!user?.businessName) {
      return !providerType || !formData.businessAddress
    }
    return !providerType;
  }

  const selectedImage = fileRef.current?.files[0] ? URL.createObjectURL(fileRef.current?.files[0]) : ''

  return (
    <div className="w-full">
      <FormWrapper onSubmit={updateBasicInfo}>
        <div className="flex justify-between flex-col h-full lg:h-[400px]">
          <div className="grid grid-cols-12 mt-6 gap-6">
            <div className="sm:mr-[24px] mb-[14px] sm:mb-0 hide_tap w-fit col-span-12">
              <label htmlFor="file" className="cursor-pointer flex space-x-4 items-center">
                {file ? (
                  <img
                    src={selectedImage}
                    width="64px"
                    height="64px"
                    alt="profile"
                    className="rounded-full w-[64px] h-[64px] object-cover object-top"
                  />
                ) : (
                  <div className="relative">
                    <div className="h-[64px] w-[64px] rounded-full bg-[#D9D9D9]"></div>
                    <Camera className="absolute top-9 left-10" />
                  </div>
                )}
                <div>
                  <p className="text-14 text-brand_secondary font-campton_m">Upload Logo</p>
                  <p className="text-12 text-neutral_body font-campton_r">Format - jpg & png</p>
                </div>
              </label>
              <input
                data-testid="test-file"
                type="file"
                id="file"
                name="file"
                accept="image/png, image/jpg, image/jpeg"
                className="hidden"
                ref={fileRef}
                readOnly={uploading || loading}
                onChange={(e) => setFile(e)}
              />
            </div>
            {user?.businessName && <TextArea
              label="Description"
              type="text"
              id="description"
              variant="col-span-12"
              name="businessDescription"
              readOnly={loading}
              value={formData?.businessDescription}
              onChange={(e) => setFormData({ ...formData, businessDescription: e.target.value })}
            />}
            <div className="lg:col-span-6 col-span-12">
              <Input
                label="Website link"
                type="text"
                id="websiteLink"
                name="websiteLink"
                readOnly={loading}
                value={formData?.websiteLink}
                onChange={handleChange}
              />
            </div>
            <Dropdown
              type="select"
              id="providedServiceType"
              label="Type of institutions"
              name="providedServiceType"
              variant="lg:col-span-6 col-span-12"
              options={institutionTypes}
              selected={providerType}
              onSelect={(data) => {
                setProviderType(data.name);
                setFormData({ ...formData, providedServiceType: data.value });
              }}
            />
            {!user?.businessName && <TextArea
              label="Business Address"
              type="text"
              id="address"
              variant="col-span-12"
              name="businessAddress"
              readOnly={loading}
              value={formData?.businessAddress}
              onChange={(e) => setFormData({ ...formData, businessAddress: e.target.value })}
            />}
          </div>
          <div className="flex justify-end mt-6">
            <Button
              className="w-28 rounded-full h-10 font-campton_m"
              theme="blue"
              disabled={disabledBtn()}
              name="Save"
              loading={loading || uploading}
            />
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default BasicInformation;
BasicInformation.propTypes = {
  callback: PropTypes.func,
};
