import Status from 'components/Status'
import dayjs from 'dayjs'
import React from 'react'
import PropTypes from 'prop-types';

const MedicationInfo = ({data}) => {
  return (
    <div className="border border-neutral_stroke_2 rounded-lg py-4 px-6 text-14 font-campton_r">
    <div className="pb-4 border-b border-neutral_stroke_2">
      <p className="text-neutral_black">Medication Name</p>
      <p className="text-neutral_body">{data?.medicationName}</p>
    </div>
    <div className="py-4 border-b border-neutral_stroke_2 ">
      <p className="text-neutral_black">Dosage</p>
      <p className="text-neutral_body">{data?.dosage}</p>
    </div>
    <div className="flex justify-between py-4 border-b border-neutral_stroke_2">
      <div>
        <p className="text-neutral_black">Frequency</p>
        <p className="text-neutral_body">{data?.frequency}</p>
      </div>
      <div>
        <p className="text-neutral_black">Route of Administration</p>
        <p className="text-neutral_body">{data?.routeOfAdministration}</p>
      </div>
    </div>
    <div className="flex justify-between py-4 border-b border-neutral_stroke_2 items-start">
      <div>
        <p className="text-neutral_black">Period</p>
        <p className="text-neutral_body">{
          `${dayjs(data?.startDate).format('MMM DD, YYYY')} `  + '-' +  
          ` ${data?.endDate ? dayjs(data?.endDate).format('MMM DD, YYYY') : 'No End date'}`
          }
        </p>
      </div>
      <Status status={data?.status} />
    </div>
    <div className="pt-4 border-neutral_stroke_2">
      <p className="text-neutral_black">Note</p>
      <p className="text-neutral_body">{data?.note}</p>
    </div>
  </div>
  )
}

export default MedicationInfo
MedicationInfo.propTypes = {
    data: PropTypes.object,
  };