import React from "react"
import PropTypes from 'prop-types';
import useInputValidate from "../../hooks/useInputValidate";

export default function Pin({ id, name, onChange, onKeyDown, onPaste, inputRef, readOnly, type, pin, className }) {
    const { error } = useInputValidate()

    return(
        <div className={className}>
            {
                pin?.map((currentInput, index) => 
                    <div key={currentInput.key} className={`w-[48px] h-[48px] ${pin?.length !== (index + 1) ? 'mr-[10px]' : ''}`}>
                        <input
                            data-testid={`${id}-${index}`}
                            id={`${id}-${index}`}
                            name={`${name}-${index}`}
                            ref={e => inputRef.current[currentInput.key] = e}
                            onChange={(e) => onChange(e, currentInput)}
                            onKeyDown={(e) => onKeyDown(e, currentInput)}
                            aria-labelledby={id}
                            onPaste={(e) => onPaste(e)}
                            readOnly={readOnly}
                            autoComplete="off"
                            maxLength={1}
                            inputMode="numeric"
                            type={type || "password"}
                            className={
                                `${error === '' ? 'border-purple_transparent' : 'border-red'}
                                h-[48px] text-neutral_body text-14 text-center w-full outline-0 border font-campton_r font-normal 
                                rounded-lg border-neutral_stroke_1 focus:border-brand_primary`
                            }
                            //readOnly={loading}
                            value={currentInput.value}
                        />
                    </div>
                )
            }
            <p className="font-campton_r text-red text-12 leading-[16px]">{ error }</p>
        </div>
    )
}

Pin.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onPaste: PropTypes.func,
    placeholder: PropTypes.string,
    inputRef: PropTypes.object,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    styles: PropTypes.string,
    style: PropTypes.string,
    maxLength: PropTypes.number,
    pin: PropTypes.array,
    className: PropTypes.string
};