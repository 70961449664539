import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import Phone from 'components/Inputs/PhoneInput';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Button from 'components/Buttons/Button';
import useVisit from 'hooks/fetch/useVisit';
import { PatientContext } from 'context';

const AddFamilyHospital = ({ callback }) => {
  const [phone, setPhone] = useState({
    phoneNumber: '',
    countryCode: ''
  });
  const { patient, setPatient } = useContext(PatientContext)
  const { add_medical_record, loading } = useVisit();
  const [formData, setFormData] = useState({
    name: '',
    emailAddress: '',
    address: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const addMedicalRecord = () => {
    const payload = { ...formData, ...phone };
    add_medical_record('familyHospital', patient?._id, payload)
      .then(res => {
        if ([201, 200]?.includes(res?.code)) {
          setPatient(res?.data)
          callback()
        }
      })
  }

  const disabledBtn = !formData.name || !formData.emailAddress || !formData.address

  return (
    <div className="w-full lg:w-[730px] lg:h-[400px]">
      <FormWrapper className="h-full" onSubmit={addMedicalRecord}>
        <div className="flex justify-between flex-col h-full">
          <div className="grid gap-x-6 gap-y-5 grid-cols-12 mt-6">
            <div className="md:col-span-6 col-span-12">
              <Input
                label="Hospital Name"
                type="text"
                id="name"
                name="name"
                readOnly={loading}
                value={formData?.name}
                onChange={handleChange}
                showError={false}
              />
            </div>
            <div className="lg:col-span-6 col-span-12">
              <Input
                label="Email"
                type="text"
                id="emailAddress"
                name="emailAddress"
                readOnly={loading}
                value={formData?.emailAddress}
                onChange={handleChange}
              />
            </div>
            <Phone value={phone} onChange={setPhone} variant="lg:col-span-6 col-span-12" />
            <div className="md:col-span-6 col-span-12">
              <Input
                label="Address"
                type="text"
                id="address"
                name="address"
                readOnly={loading}
                value={formData?.address}
                onChange={handleChange}
                showError={false}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <Button name="Save" theme="blue" className="w-24 h-10" disabled={disabledBtn} loading={loading} />
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default AddFamilyHospital;
AddFamilyHospital.propTypes = {
  callback: PropTypes.any
};
