import { SlideUp } from "components/Animations";
import React, { useContext, useEffect, useState } from "react";
import Card from "./Card";
import AppointmentDetails from "./AppointmentDetails";
import Modal from "components/Modals/Modal";
import usePatient from "hooks/fetch/usePatient";
import { PatientContext } from "context";
import Spinner from "components/Spinner";
import Table from "components/Table";
import useTable from "hooks/useTable";

export default function Appointments() {
    const [selectedAppointment, setSelectedAppointment] = useState()
    const [appointments, setAppointments] = useState([])
    const { patient } = useContext(PatientContext)
    const { get_patient_appointment, loading } = usePatient()
    const { params, changePage, selectLimit } = useTable()
    
    useEffect(() => {
        get_patient_appointment( `${patient?._id}/appointments` , 
        {page: params.page, limit: params.limit}).then(res => setAppointments(res?.data || undefined))
    }, [params.page, params.limit])

    return (
        <SlideUp>
            <div className="p-[24px]">
                <h2 className="text-16 sm:text-18 text-[#0D0F11] font-campton_m mb-[24px]">Appointments</h2>
              
                    <Table
                        hasTable={false}
                        hasFilter={false}
                        data={appointments?.Appointments}
                        loading={loading}
                        totalCount={appointments?.count}
                        perPage={appointments?.limit}
                        currentPage={appointments?.page}
                        selectLimit={selectLimit}
                        changePage={changePage}
                    >
                        <>
                            {
                                !loading && appointments?.Appointments?.length ? appointments?.Appointments?.map((appointment) =>
                                    <Card
                                        key={appointment?.appointment?._id}
                                        id={appointment?.appointment?._id}
                                        means={appointment?.means}
                                        image={appointment?.providerInfo?.profilePicture}
                                        specialist={appointment?.providerInfo}
                                        patient={patient}
                                        date={appointment?.appointment?.appointmentDate}
                                        time={appointment?.appointment?.startTime}
                                        type={appointment?.appointment?.type}
                                        onClick={() => setSelectedAppointment(appointment)}
                                    />
                                ) : ''
                            }
                            {loading && <Spinner height="30vh" />}
                        </>
                    </Table>
            </div>
            {selectedAppointment ?
                <Modal
                    closeModal={() => setSelectedAppointment()}
                    styles="w-full md:w-[457px]"
                    position="modal-right"
                    title="Appointment Details"
                    variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
                >
                    <AppointmentDetails
                        patient={patient}
                        appointment={selectedAppointment}
                        cancel={setSelectedAppointment}
                    />
                </Modal> : ''
            }
        </SlideUp>
    )
}
