import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types"
import { Button } from "components/Buttons";
import { DefaultDatepicker } from "components/Datepicker/Index";
import Dropdown from "components/Dropdown";
import FormWrapper from "components/FormWrapper";
import { TextArea } from "components/Inputs";
import useShift from "hooks/fetch/useShift";
import { formatDateToTime12Hour } from "utils/formatTime";
import { formatToDateWithDash } from "utils/formatDate";
import { useSelector } from "react-redux";
import useProviders from "hooks/fetch/useProviders";

export default function SwapTimeSlot({ cancel, callback, staffId, date, proposedDate, shiftId }) {
    const { loading, swap_time_slot, swap_time_slot_request } = useShift()
    const { institutionId, userType } = useSelector(state => state.auth)
    const isInstitution = userType === "institution"
    const { providers } = useSelector(state => state.providers)
    const { get_institution_providers, loading: isFetchingProviders } = useProviders()
    const [selectedStaff, setSelectedStaff] = useState({ name: '', id: '' })
    const [formData, setFormData] = useState({
        date: date ? new Date(date) : '',
        reason: '',
        proposedDate: proposedDate ? new Date(proposedDate) : '',
        proposedStartTime: '',
        proposedEndTime: '',
        startTime: '',
        endTime: ''
    })

    useEffect(() => {
        get_institution_providers({ id: `${institutionId}/providers` })
    }, [])

    const selectStaff = useCallback((e) => {
        setSelectedStaff({ name: e.name, id: e.value })
    }, [])

    const staffs = useMemo(() => {
        const filterSelectedStaff = providers?.providers?.filter(item => item?._id !== staffId)
        return filterSelectedStaff?.map(provider => {
            return {
                name: `${provider?.firstName} ${provider?.lastName}`,
                value: provider?._id
            }
        })
    }, [providers.providers])

    const swapTimeSlot = () => {
        const newFormData = {
            ...formData,
            shiftId,
            startTime: formatDateToTime12Hour(formData.startTime),
            endTime: formatDateToTime12Hour(formData.endTime),
            date: formatToDateWithDash(formData.date),
            proposedDate: formatToDateWithDash(formData.proposedDate),
            proposedStartTime: formatDateToTime12Hour(formData.proposedStartTime),
            proposedEndTime: formatDateToTime12Hour(formData.proposedEndTime)
        }
        let url = isInstitution ?
            `/calendar/${staffId}/swap-time/${selectedStaff?.id}` :
            `/calendar/${staffId}/swap-time-request/${selectedStaff?.id}`
        const res = isInstitution ?
            swap_time_slot({ url, payload: newFormData }) :
            swap_time_slot_request({ url, payload: newFormData })
        res.then(res => {
            if ([200, 201].includes(res?.code)) {
                callback()
                cancel()
            }
        })
    }

    const disabled = useMemo(() => {
        return !formData.date || !formData.reason || !formData.proposedDate || !formData.proposedStartTime ||
            !formData.endTime || !selectedStaff?.id || !formData.reason
    }, [formData])

    return (
        <FormWrapper onSubmit={swapTimeSlot} className="pb-[60px]">
            <div className="pb-[16px] pt-[8px] overflow-y-auto">
                <div className="border-b border-dashed border-b-neutral_stroke_1">
                    <p className="text-14 text-neutral_black font-campton_m">Basic Information</p>
                    <p className="text-12 text-neutral_body font-campton_r mb-[24px]">Select date and time  on your shift</p>
                    <div className="mb-[24px]">
                        <DefaultDatepicker
                            label="Date"
                            minDate={new Date()}
                            onSelect={(e) => setFormData({ ...formData, date: e })}
                            name="date"
                            startDate={formData?.date}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-[24px]">
                        <div className="mb-[24px]">
                            <DefaultDatepicker
                                label="Start Time"
                                showTimeSelectOnly={true}
                                dateFormat="h:mm aa"
                                showTimeSelect={true}
                                timeCaption="Select Start time"
                                onSelect={(e) => setFormData({ ...formData, startTime: e })}
                                name="startTime"
                                value={formData?.startTime}
                                showTimeIcon={true}
                            />
                        </div>
                        <div className="mb-[24px]">
                            <DefaultDatepicker
                                label="End Time"
                                showTimeSelectOnly={true}
                                dateFormat="h:mm aa"
                                showTimeSelect={true}
                                timeCaption="Select End time"
                                onSelect={(e) => setFormData({ ...formData, endTime: e })}
                                name="endTime"
                                value={formData?.endTime}
                                showTimeIcon={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="pt-[24px]">
                    <p className="text-14 text-neutral_black font-campton_m">Swap Information</p>
                    <p className="text-12 text-neutral_body font-campton_r mb-[24px]">Select a staff, date and time for the swap.</p>
                    <div className="mb-[24px] w-full">
                        <Dropdown
                            label="Select Staff"
                            type="select"
                            id="staff"
                            name="staff"
                            position="static"
                            options={staffs}
                            loading={isFetchingProviders}
                            selected={selectedStaff?.name}
                            onSelect={selectStaff}
                        />
                    </div>
                    <div className="mb-[24px]">
                        <DefaultDatepicker
                            label="Date"
                            minDate={new Date()}
                            onSelect={(e) => setFormData({ ...formData, proposedDate: e })}
                            name="proposedDate"
                            startDate={formData?.proposedDate}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-[24px]">
                        <div className="mb-[24px]">
                            <DefaultDatepicker
                                label="Start Time"
                                showTimeSelectOnly={true}
                                dateFormat="h:mm aa"
                                showTimeSelect={true}
                                timeCaption="Select Start time"
                                onSelect={(e) => setFormData({ ...formData, proposedStartTime: e })}
                                name="proposedStartTime"
                                value={formData?.proposedStartTime}
                                showTimeIcon={true}
                            />
                        </div>
                        <div className="mb-[24px]">
                            <DefaultDatepicker
                                label="End Time"
                                showTimeSelectOnly={true}
                                dateFormat="h:mm aa"
                                showTimeSelect={true}
                                timeCaption="Select End time"
                                onSelect={(e) => setFormData({ ...formData, proposedEndTime: e })}
                                name="proposedEndTime"
                                value={formData?.proposedEndTime}
                                showTimeIcon={true}
                            />
                        </div>
                    </div>
                    <TextArea
                        placeholder="Enter reason"
                        id="other"
                        name="other"
                        readOnly={loading}
                        value={formData.reason}
                        onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
                        rows={3}
                    />
                </div>
            </div>
            <div className="flex justify-end fixed bottom-0 left-0 py-[16px] px-[24px] bg-neutral_white w-full">
                <Button
                    name="Cancel"
                    theme="transparent"
                    disabled={loading}
                    className="w-[79px] h-[40px] mr-[16px]"
                    onClick={cancel}
                />
                <Button
                    name="Save"
                    theme="blue"
                    disabled={disabled || loading}
                    loading={loading}
                    className="w-[88px] h-[40px]"
                />
            </div>
        </FormWrapper>
    )
}

SwapTimeSlot.propTypes = {
    cancel: PropTypes.func,
    callback: PropTypes.func,
    staffId: PropTypes.string,
    date: PropTypes.string,
    proposedDate: PropTypes.string,
    shiftId: PropTypes.string
}