import React, { useState } from 'react';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import PropTypes from 'prop-types';
import MedicationInfo from './MedicationInfo';
import { Button } from 'components/Buttons';
import Confirmation from './Confirmation';
import Modal from 'components/Modals/Modal';
import { TextArea } from 'components/Inputs';
import { DefaultDatepicker } from 'components/Datepicker/Index';
import useMedications from 'hooks/fetch/useMedications';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

const EditMedication = ({ data, callback, setType, action }) => {
  const pathname = window.location.pathname;
  const page = pathname.includes('patient') ? 'patient' : 'visit';
  const { id } = useParams();
  const params = {
    type: page,
    [pathname.includes('patient') ? 'patientId' : 'visitId']: id
  }
  const [confirm, setConfirm] = useState(false);
  const goBack = () => {
    setType('stop');
    setConfirm(false);
  };
  const [formData, setFormData] = useState({
    reason: '',
    endDate: ''
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { update_medication_status, get_medications, loading } = useMedications();
  const handleCallback = () => {
    callback();
    get_medications(params);
  };
  const handleSubmit = () => {
    const payload = {
      status: "restarted",
      medicationId: data._id,
      endDate: dayjs(formData?.endDate).format('YYYY-MM-DD'),
    };
    update_medication_status(payload, handleCallback);
  };

  return (
    <div className="flex justify-between h-full flex-col">
      <div>
        <p className="font-campton_m text-14 text-neutral_black mt-2 mb-4">Medication Information</p>
        <MedicationInfo data={data} />
        <div className=" border-t border-dashed border-neutral_stroke_2 mt-6 pt-4">
          <p className="font-campton_m text-14 text-neutral_black capitalize">{action} Information</p>
          {data.status === 'paused' && action === 'Restart' ? (
            <DefaultDatepicker
              label="New Intended End Date"
              containerVariant="mt-4 "
              minDate={new Date()}
              onSelect={(e) => setFormData({ ...formData, endDate: e })}
              name="restartDate"
              startDate={formData.endDate}
              showCalender={true}
            />
          ) :  (
            <TextArea
              placeholder={`Reason for ${action}`}
              type="text"
              id="reason"
              name="reason"
              rows={4}
              variant="mt-4"
              value={formData?.reason}
              onChange={handleChange}
            />
          )}
        </div>
      </div>
      <div className="flex mt-1 space-x-4 justify-end">
        <Button
          className="w-fit h-10
              text-14 hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary  px-2 py-1"
          theme="transparent"
          onClick={callback}
          name="Cancel"
        />
        <Button
          name={data.status === 'paused' ? 'Save' : 'Proceed'}
          theme="blue"
          loading={loading}
          className="w-fit h-10 py-2 px-8"
          onClick={() => data.status === 'paused' && action === 'Restart' ? handleSubmit() : setConfirm(true)}
          disabled={data.status === 'paused' && action === 'Restart' ? !formData.endDate : !formData.reason}
        />
      </div>
      {confirm && (
        <Modal
          closeModal={goBack}
          icon={<ArrowBack fill="#748181" className="w-2" />}
          styles="w-full md:w-[457px]"
          position="modal-right"
          variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
          title="Confirmation"
        >
          <Confirmation data={data} callback={callback} action={action} formData={formData} />
        </Modal>
      )}
    </div>
  );
};

export default EditMedication;
EditMedication.propTypes = {
  data: PropTypes.object,
  setType: PropTypes.func,
  callback: PropTypes.func,
  action: PropTypes.string,
};
