import Dropdown from 'components/Dropdown';
import FormWrapper from 'components/FormWrapper';
import { Input, TextArea } from 'components/Inputs';
import Phone from 'components/Inputs/PhoneInput';
import React, { useEffect, useMemo, useState } from 'react';
import { useAuthState } from 'store/modules/auth';
import { DefaultDatepicker } from 'components/Datepicker/Index';
import { Button } from 'components/Buttons';
import PropTypes from 'prop-types';
import usePatient from 'hooks/fetch/usePatient';
import dayjs from 'dayjs';
import useTable from 'hooks/useTable';
import useLocations from 'hooks/fetch/useLocations';
import { useLocationState } from 'store/modules/locations';
import { removeSpecificKeys } from 'utils/removeSpecificKeys';
import useCountry from 'hooks/fetch/useCountry';
import Modal from 'components/Modals/Modal';

const AddPatient = ({ callback, getPatientProxy }) => {
  const { countryList } = useAuthState();
  const { get_countries, loading: isLoadingCountry } = useCountry()
  const { add_patient, loading } = usePatient();
  const { get_locations, loading: loadingLocations } = useLocations();
  const { locations } = useLocationState();
  const { user, institutionId } = useAuthState();
  const { params } = useTable();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    location: '',
    country: '',
    dateOfBirth: '',
    homeAddress: '',
  });

  const [phone, setPhone] = useState({
    phoneNumber: '',
    countryCode: ''
  });

  useEffect(() => {
    get_locations(params)
  }, []);

  const countries = useMemo(() => {
    return countryList?.map((item, index) => {
      return { id: index, value: item?.name, name: item?.name };
    })
  }, [countryList]);

  const disabledButton =
    !formData.firstName || !formData.lastName || !formData.dateOfBirth ||
    !phone.phoneNumber || !formData.country || !formData.location || !formData.homeAddress

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    get_countries()
  }, [])

  const patientParams = {
    institutionId: institutionId ?? undefined,
    providerId: user?.businessName ? undefined : user._id,
    type: user.businessName ? 'institution' : 'provider',
  };

  const handleCallback = () => {
    callback();
    getPatientProxy()
  };

  const addPatient = () => {
    add_patient(removeSpecificKeys({
      ...formData,
      ...patientParams,
      dateOfBirth: dayjs(formData.dateOfBirth).format('YYYY-MM-DD'),
      countryCode: phone?.countryCode,
      phoneNumber: phone?.phoneNumber,
    }), handleCallback);
  };

  return (
    <Modal
      closeModal={callback}
      styles="w-full md:w-[457px]"
      position="modal-right"
      variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
      title='New Patient'
    >
      <FormWrapper className="h-full" onSubmit={addPatient}>
        <div className="relative">
          <div className="modal-right-body grid gap-6 grid-cols-12">
            <div className="col-span-12">
              <Input
                label="First Name"
                type="text"
                id="firstName"
                name="firstName"
                readOnly={loading}
                value={formData?.firstName}
                onChange={handleChange}
                showError={false}
              />
            </div>
            <div className="col-span-12">
              <Input
                label="Last Name"
                type="text"
                id="lastName"
                name="lastName"
                readOnly={loading}
                value={formData?.lastName}
                onChange={handleChange}
                showError={false}
              />
            </div>
            <div className="col-span-12">
              <Input
                label="Email Address"
                type="text"
                id="emailAddress"
                name="emailAddress"
                readOnly={loading}
                value={formData?.emailAddress}
                onChange={handleChange}
                showError={false}
              />
            </div>
            <Phone value={phone} onChange={setPhone} variant="col-span-12" label="Phone Number" />
            <Dropdown
              type="select"
              id="location"
              placeholder={`${loadingLocations ? 'Loading location...' : 'Hospital location'}`}
              name="location"
              label='Hospital location'
              variant="col-span-12"
              selected={formData.location}
            >
              {locations?.locations?.map((location) =>
                <div
                  key={location?._id}
                  data-testid={location?._id}
                  onClick={() => {
                    setFormData({ ...formData, location: `${location?.address}, ${location?.city}, ${location.state}, ${location.country}` })
                  }}
                  className="py-[11px] px-[16px] flex items-center justify-between
                      cursor-pointer hide_tap transition ease-in-out duration-500 hover:bg-[#F2F3F3] first-of-type:mt-2"
                >
                  <div className="w-full">
                    <p className="text-14 font-campton_r capitalize truncate">
                      {`${location?.state}, ${location?.country}.`}
                    </p>
                    <p className="text-[10px] font-campton_r capitalize truncate">{`${location?.address}, ${location?.city}`}</p>
                  </div>
                </div>
              )}
            </Dropdown>
            <Dropdown
              type="search"
              options={countries}
              id="country"
              variant="col-span-12"
              placeholder={`${isLoadingCountry ? 'Loading countries...' : 'Country'}`}
              name="country"
              selected={formData?.country}
              onSelect={(data) => {
                setFormData({ ...formData, country: data.value });
              }}
            />

            <DefaultDatepicker
              label="Date of Birth"
              containerVariant="col-span-12"
              maxDate={new Date()}
              onSelect={(e) => setFormData({ ...formData, dateOfBirth: e })}
              name="date"
              startDate={formData.dateOfBirth}
              showCalender={true}
            />
            <TextArea
              label="Address"
              type="text"
              id="address"
              rows={3}
              variant="col-span-12"
              name="address"
              //   readOnly={loading}
              value={formData?.homeAddress}
              onChange={(e) => setFormData({ ...formData, homeAddress: e.target.value })}
            />
          </div>
          <div className="modal-right-button-container">
            <Button
              name="Cancel"
              theme="transparent"
              className="w-20 h-10 mr-[16px]
              text-14 hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary  px-2 py-1"
              onClick={callback}
              type='button'
            />
            <Button
              name="Add Patient"
              theme="blue"
              loading={loading}
              className="h-10 text-16 px-[12px] w-[114px]"
              disabled={disabledButton}
            />
          </div>
        </div>
      </FormWrapper>
    </Modal>
  );
};

export default AddPatient;
AddPatient.propTypes = {
  callback: PropTypes.func,
  getPatientProxy: PropTypes.func
};
