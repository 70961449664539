import React, { useEffect, useState } from 'react';
import AppointmentInfo from './Components/AppointmentInfo';
import PropTypes from 'prop-types';
import { Button } from 'components/Buttons';
import { useAuthState } from 'store/modules/auth';
import Dropdown from 'components/Dropdown';
import { cancellation_reasons } from 'mocks/appointment';
import { TextArea } from 'components/Inputs';
import { SlideUp } from 'components/Animations';
import { DefaultDatepicker } from 'components/Datepicker/Index';
import useAppointments from 'hooks/fetch/useAppointments';
import dayjs from 'dayjs';

const EditAppointment = ({ data, callback, type, setAppointmentList, params }) => {
  const { institutionId, user } = useAuthState();
  const [formData, setFormData] = useState({
    reason: '',
    other_reason: '',
    appointmentDate: '',
  });
  const [time, setTime] = useState({ start: '', end: '' });
  const { edit_appointment_request, edit_appointment, loading } = useAppointments();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const { get_institution_appointments, get_provider_appointments } = useAppointments();
  
  const handleCallback = () => {
    callback()
    if (institutionId && user?.businessName) {
      get_institution_appointments(params).then((res) =>
      setAppointmentList(res?.data?.appointments || undefined))
    } else {
      get_provider_appointments(params).then((res) =>
      setAppointmentList(res?.data?.appointments || undefined))
    }
  }
  const handleSubmit = () => {
    if (type === 'reschedule') {
      const payload = {
        proposedStartTime: dayjs(time?.start).format('hh:mm A'),
        proposedDate: dayjs(formData?.appointmentDate).format('YYYY-MM-DD'),
        reason: formData.other_reason || formData.reason,
      };
      if (institutionId && user?.businessName) {
        edit_appointment(`${data._id}/reschedule`, payload, handleCallback);
      } else {
        edit_appointment_request(`reschedule/request/${data._id}`, payload, handleCallback);
      }
    } else {
      if (user?.businessName) {
        edit_appointment(
          `${data._id}/cancel`,
          { reason: formData.reason === 'others' ? formData.other_reason : formData.reason },
          handleCallback,
        );
      } else {
        edit_appointment_request(
          `${data._id}/cancel-request`,
          { reason: formData.other_reason || formData.reason },
          handleCallback,
        );
      }
    }
  };

  useEffect(() => {
    if (time?.start)
      setTime({
        ...time,
        end: new Date(time.start).setHours(time.start.getHours() + 1)
      })
  }, [time?.start])
  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <AppointmentInfo appointmentDetails={data} />
        <div className="pt-6 mt-6 border-t border-dashed border-neutral_stroke_2">
          <p className="text-12 text-neutral_black font-campton_r">
            {type === 'reschedule' ? 'Reschedule Information' : 'Cancel Appointment?'}
          </p>
          <p className="text-neutral_body font-campton_r text-12">
            {type === 'reschedule'
              ? 'Select date, time  & give reason for rescheduling'
              : 'Are you sure you want to cancel this appointment'}
          </p>
        </div>
        {type === 'reschedule' && (
          <div className="mt-6">
            <DefaultDatepicker
              label="Proposed Date"
              containerVariant="lg:col-span-4 md:col-span-6 col-span-12"
              minDate={new Date()}
              onSelect={(e) => setFormData({ ...formData, appointmentDate: e })}
              name="date"
              startDate={formData.appointmentDate}
              showCalender={true}
            />
            <div className="grid grid-cols-2 gap-4 mt-6">
              <DefaultDatepicker
                label="Start Time"
                timeCaption="Select Start time"
                showTimeSelectOnly={true}
                startDate={time?.start}
                showTimeIcon={true}
                showTimeSelect={true}
                name="startTime"
                value={time?.start}
                onSelect={(date) => setTime({ ...time, start: date })}
                dateFormat="h:mm aa"
              />
              <DefaultDatepicker
                showTimeSelectOnly={true}
                showTimeIcon={true}
                startDate={time?.end}
                showTimeSelect={false}
                onSelect={(date) => setTime({ ...time, end: date })}
                name="endTime"
                dateFormat="h:mm aa"
                label="End Time"
                value={time?.end}
                timeCaption="Select End time"
              />
            </div>
          </div>
        )}
        <div className={`${type === 'cancel' ? 'mt-4' : ''}`}>
          <Dropdown
            type="select"
            id="cancellation_reason"
            label={`Reason for ${type === 'reschedule' ? 'Rescheduling' : 'Cancellation'}`}
            name="cancellation_reason"
            options={cancellation_reasons}
            selected={formData?.reason}
            onSelect={(data) => {
              setFormData({ ...formData, reason: data.value });
            }}
          />
          {formData.reason === 'others' && (
            <SlideUp>
              {' '}
              <TextArea
                placeholder="Type here..."
                type="text"
                id="reason"
                name="other_reason"
                variant="mt-4"
                onChange={handleChange}
              />
            </SlideUp>
          )}
        </div>
      </div>
      <div className="flex mt-1 space-x-4 justify-end">
        <Button
          className="w-20 h-10
              text-14 hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary  px-2 py-1"
          theme="transparent"
          onClick={callback}
          name="Cancel"
        />
        <Button
          name={institutionId && user?.businessName ? 'Save' : 'Request'}
          theme="blue"
          onClick={handleSubmit}
          loading={loading}
          className="w-[88px] h-10"
        />
      </div>
    </div>
  );
};

export default EditAppointment;
EditAppointment.propTypes = {
  data: PropTypes.object,
  callback: PropTypes.func,
  type: PropTypes.string,
  setAppointmentList: PropTypes.func,
  params: PropTypes.object
};
