import React from 'react';
import { ReactComponent as PasswordCheck } from 'assets/icons/password-check.svg';
import { ReactComponent as Hospital } from 'assets/svg/hospital.svg';
import { ReactComponent as Remove } from 'assets/svg/close-circle.svg';
import { Button } from 'components/Buttons';
import PropTypes from 'prop-types';
import { useAuthState } from 'store/modules/auth';

const LocationCard = ({ data, removeLocationFunc, onClick }) => {
  const { user } = useAuthState();

  return (
    <div className="flex space-x-4 datas-start border border-neutral_stroke_2 p-4 rounded-lg shrink-0 max-w-[280px] h-[120px]">
      <Hospital />
      <div className='flex flex-col justify-between'>
        <div>
          <p className="capitalize font-campton_m text-14 text-primary_shade_300">{user?.businessName}</p>
          <p className="text-neutral_body text-12 font-campton_r">
            {data.address}, {data.city}, {data.state}
          </p>
        </div>
        {data.isPrimaryAddress ? (
          <Button className="bg-[#E6F9EE] h-7 mt-2 w-fit text-14 px-3 border-0">
            <p className="font-campton_m text-[#1BA154] flex items-center text-12">
              <span className="mr-1.5">
                <PasswordCheck />
              </span>
              Primary address
            </p>
          </Button>
        ) : (
          <button
            className="bg-[#F7F8F8] h-7 text-12 mt-2 w-fit px-2 border-0 font-campton_m text-neutral_body rounded-full"
            onClick={onClick}
          >
            Make primary address
          </button>
        )}
      </div>
      <Remove className="cursor-pointer" onClick={removeLocationFunc} data-testid="test-remove" />
    </div>
  );
};

export default LocationCard;
LocationCard.propTypes = {
  removeLocationFunc: PropTypes.func,
  data: PropTypes.object,
  onClick: PropTypes.func,
};
