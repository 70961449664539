import { useState } from "react"
import useMakeRequest from "hooks/useMakeRequest"
import useTryCatch from "hooks/useTryCatch"
import { createRole, deleteRole, editRole, getRoleAssignees, getRoles } from "store/modules/roles/actions"

export default function useRoles() {
    const { makeRequest } = useMakeRequest()
    const [loading, setLoading] = useState(false)
    const { tryCatch } = useTryCatch()

    const get_roles = ({ id, params }) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getRoles({ pathname: id, params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const create_role = (formData) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: createRole({ payload: formData }) })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const edit_role = (formData) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: editRole({ payload: formData }) })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const delete_role = (id) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: deleteRole({ pathname: id }) })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_roleAssignees = ({ params }) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getRoleAssignees({ params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    return {
        get_roles,
        create_role,
        edit_role,
        delete_role,
        get_roleAssignees,
        loading,
    }
}