import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import FormWrapper from 'components/FormWrapper';
import { DefaultDatepicker } from 'components/Datepicker/Index';
import { Button } from 'components/Buttons';
import { visit_status, visit_types } from 'mocks/clinicals/visits';
import { ReactComponent as Uncheck } from 'assets/icons/check-box-unselected.svg';
import { ReactComponent as Check } from 'assets/icons/check-box-selected.svg';
import Accordion from 'components/Accordion';
import Modal from 'components/Modals/Modal';

const Filter = ({ params, setParams, closeModal, loading, func }) => {
  const disabled = useMemo(() => {
    return !params.visitType && (!params.startDate && !params.endDate) && !params?.status
  }, [params])

  return (
    <Modal
      closeModal={closeModal}
      styles="w-full md:w-[457px]"
      position="modal-right"
      title="Filter"
      variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
    >
      <FormWrapper className="relative">
        <section className="modal-right-body">
          <p className="text-12 text-neutral_black font-campton_m mb-[10px]">By Date Range</p>
          <div className="grid grid-cols-2 gap-x-[16px] mb-[24px]">
            <div>
              <DefaultDatepicker
                label="From"
                containerVariant="lg:col-span-6 col-span-12"
                startDate={params.startDate}
                maxDate={new Date()}
                onSelect={(date) => setParams({ ...params, startDate: date })}
                name="startDate"
                showCalender={true}
              />
            </div>
            <div>
              <DefaultDatepicker
                label="To"
                containerVariant="lg:col-span-6 col-span-12"
                startDate={params.endDate}
                maxDate={new Date()}
                onSelect={(date) => setParams({ ...params, endDate: date })}
                name="endDate"
                showCalender={true}
              />
            </div>
          </div>
          <Accordion
            display={!!params?.visitType}
            title="By Visit Type"
            className="border-b pb-4 border-neutral_stroke_2 mb-4"
            variant="mt-3"
            titleClassName="text-12"
          >
            <div className="bg-[#F7F8F8] p-4 rounded-lg">
              {visit_types?.map((item) => (
                <button
                  key={item.name}
                  data-testid={item.value}
                  className={`${item.name === 'all' ? 'border-b border-dashed border-neutral_stroke_2 py-2' : 'py-3'} flex`}
                  onClick={() => setParams({ ...params, visitType: item.value })}
                >
                  <div className="cursor-pointer hide_tap">
                    {params?.visitType === item.value ? <Check /> : <Uncheck />}
                  </div>
                  <p className="text-14 text-neutral_black font-campton_r ml-[8px] capitalize">{item.name}</p>
                </button>
              ))}
            </div>
          </Accordion>
          <Accordion
            display={!!params?.status}
            title="By Status"
            className="border-b pb-4 border-neutral_stroke_2 mb-4"
            variant="mt-3"
            titleClassName="text-12"
          >
            <div className="bg-[#F7F8F8] p-4 rounded-lg">
              {visit_status?.map((item) => (
                <div
                  key={item.name}
                  data-testid={item.value}
                  className={`${item.name === 'all' ? 'border-b border-dashed border-neutral_stroke_2 py-2' : 'py-3'
                    } flex`}
                  onClick={() => setParams({ ...params, status: item.value })}
                >
                  <div className="cursor-pointer hide_tap">
                    {params?.status === item.value ? <Check /> : <Uncheck />}
                  </div>
                  <p className="text-14 text-neutral_black font-campton_r ml-[8px] capitalize">{item.name}</p>
                </div>
              ))}
            </div>
          </Accordion>
        </section>
        <div className="modal-right-button-container">
          <Button
            name="Cancel"
            theme="transparent"
            type="button"
            onClick={closeModal}
            className="h-[40px] text-16 w-[79px] mr-[16px]"
          />
          <Button
            name="Filter"
            theme="blue"
            type='button'
            loading={loading}
            onClick={func}
            className="h-[40px] text-16 w-[128px]"
            disabled={disabled}
          />
        </div>
      </FormWrapper>
    </Modal>

  );
};

export default Filter;
Filter.propTypes = {
  closeModal: PropTypes.func,
  params: PropTypes.object,
  setParams: PropTypes.func,
  loading: PropTypes.bool,
  func: PropTypes.func,
};
