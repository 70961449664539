import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/formatDate';
import LineChart from 'components/Chart/LineChart';

export default function BloodPressure({ analyticsData, recentVitals, className }) {
    const data = useMemo(() => {
        let sysBloodPressureData = ['']
        let diaBloodPressureData = ['']
        let labels = ['']
        analyticsData?.reverse()?.map((item) => {
            labels.push(formatDate(item?.date))
            sysBloodPressureData.push(item?.sysBloodPressure ?? '')
            diaBloodPressureData.push(item?.diaBloodPressure ?? '')
            return item;
        })
        return { labels, sysBloodPressureData, diaBloodPressureData }
    }, [analyticsData]);

    const datasets = [
        {
            data: data?.sysBloodPressureData,
            backgroundColor: "#fff9b320",
            borderColor: "#FF7A21",
            //borderWidth: 2,
            borderCapStyle: 'round',
            borderDashOffset: [10],
            //fill: true,
            lineTension: '0.4',
            pointBackgroundColor: '#FF7A21',
            pointRadius: 0,
            pointHitRadius: 10
        },
        {
            data: data?.diaBloodPressureData,
            backgroundColor: "#fff9b320",
            borderColor: "#23CE6B",
            //borderWidth: 2,
            borderCapStyle: 'round',
            borderDashOffset: [10],
            //fill: true,
            lineTension: '0.4',
            pointBackgroundColor: '#23CE6B',
            pointRadius: 0,
            pointHitRadius: 10
        },
    ]

    return (
        <>
            <p className="font-campton_m text-14 text-neutral_black mb-[16px]">{recentVitals} <span className='text-neutral_body'>mmHg</span></p>
            <div className={`${className} mb-[16px]`}>
                <LineChart chartData={data} datasets={datasets} />
            </div>
            <div className="flex space-x-4 font-campton_r">
                <p className="flex items-center space-x-2">
                    <span className="h-2 w-2 rounded-full bg-success"></span>
                    <span className="text-10 text-neutral_black">Diastolic</span>
                </p>
                <p className="flex items-center space-x-2">
                    <span className="h-2 w-2 rounded-full bg-brand_secondary"></span>
                    <span className="text-10 text-neutral_black">Systolic</span>
                </p>
            </div>
        </>
    );
}

BloodPressure.propTypes = {
    analyticsData: PropTypes.array,
    recentVitals: PropTypes.string,
    className: PropTypes.string
}
