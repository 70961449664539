import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TextArea } from 'components/Inputs';
import { Button } from 'components/Buttons';
import { checkEmptyProperties } from 'utils/checkEmptyProperties';
import useService from 'hooks/fetch/useService';
import Dropdown from 'components/Dropdown';
import { service_category } from 'mocks/services';
import { useServiceState } from 'store/modules/services';
import Modal from 'components/Modals/Modal';

const NewServiceCategory = ({ callback, type, closeModal, data, title }) => {
  const { serviceCategories } = useServiceState()
  const [formData, setFormData] = useState({
    name: '',
    description: '',
  });

  const { create_service_category, loading, update_service_category } = useService()

  const handleCallback = () => {
    closeModal()
    callback()
  }

  const availableServiceCategory = useMemo(() => {
    return service_category.filter(item =>
      serviceCategories?.categories?.every(filterItem => filterItem.name !== item.name)
    );
  }, [serviceCategories?.categories])

  const createServiceCategory = () => {
    const payload = {
      description: formData.description
    }
    if (type === 'New') {
      create_service_category(formData, handleCallback)
    } else {
      update_service_category(data.name, payload, () => handleCallback())
    }
  }

  useEffect(() => {
    if (type === 'Edit' && data) {
      setFormData({
        name: data?.name ?? '',
        description: data?.description ?? ''
      })
    }
  }, [data, type])

  return (
    <Modal
      closeModal={closeModal}
      styles='min-w-[90%] md:min-w-[535px]'
      title={title}
      variant="py-[14.5px] px-[24px] text-center border-b border-b-neutral_stroke_2 text-16 font-campton_m"
    >
      <div className="flex justify-between h-full flex-col">
        <div>
          <p className="font-campton_r text-14 text-neutral_body mb-6">
            {type === 'Edit' ? 'Edit details of your service category' : 'Enter details of your service category below'}
          </p>
          <div className="mb-[24px]">
            <Dropdown
              label={type === 'New' ? 'Select Name' : 'Name'}
              type="select"
              id="name"
              name="name"
              containerVariant='h-[120px]'
              optionContainerVariant='h-[120px]'
              disabled={type === 'Edit'}
              position="static"
              options={availableServiceCategory}
              selected={formData?.name}
              onSelect={(data) => {
                setFormData({ ...formData, name: data.value });
              }}
            />
          </div>
          <TextArea
            label="Description of Category"
            type="text"
            id="description"
            rows={3}
            variant="mt-6"
            name="description"
            readOnly={loading}
            value={formData?.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />
        </div>
        <div className="flex justify-end mt-6">
          <Button
            className={`${type === 'New' ? 'w-28' : 'w-[131px]'
              } w-28 rounded-full h-10 py-0 px-0 bg-brand_primary font-campton_m text-neutral_white`}
            theme="blue"
            name={type === 'New' ? 'Create' : 'Save changes'}
            disabled={checkEmptyProperties(formData)}
            loading={loading}
            onClick={createServiceCategory}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NewServiceCategory;
NewServiceCategory.propTypes = {
  callback: PropTypes.func,
  closeModal: PropTypes.func,
  type: PropTypes.string,
  data: PropTypes.object,
  title: PropTypes.string
};
