import VitalSignsAnalytics from "components/VitalSignsAnalytics"
import { PatientContext } from "context"
import React, { useContext } from "react"
import History from "./History/History"
import useVitalsAnalytics from "hooks/useVitalsAnalytics"

export default function VitalSigns() {
    const { patient } = useContext(PatientContext)
    const {
        loading,
        selectedTab,
        toggleTab,
        vitalsHistory
    } = useVitalsAnalytics({ patientId: patient?._id, className: "h-[200px]" })

    return (
        <div className="p-[24px]">
            <div className="bg-[#ECEEEE] rounded-[16px] p-[16px] mb-[24px] h-[325px]">
                <VitalSignsAnalytics
                    loading={loading}
                    selectedTab={selectedTab}
                    toggleTab={toggleTab}
                />
            </div>
            <History loading={loading} vitals={vitalsHistory} />
        </div>
    )
}