import { useState } from 'react';
import useMakeRequest from 'hooks/useMakeRequest';
import useTryCatch from 'hooks/useTryCatch';
import { addSpecialty, getSpecialty, getSpecialties, editSpecialty, getSpecialtyStaff } from 'store/modules/specialty/actions';

export default function useSpecialty() {
  const { makeRequest } = useMakeRequest();
  const [loading, setLoading] = useState(false);
  const { tryCatch } = useTryCatch();

  const add_specialty = (id, formData, callback) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: addSpecialty({ pathname: id, payload: formData }), callback })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };
  const get_Specialties = (params) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getSpecialties({ params }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };
  const get_Specialty_Staff = (id, params) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getSpecialtyStaff({ pathname: id, params }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };
  const get_Single_Specialty = (id) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getSpecialty({ pathname: id }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };
  const edit_Specialty = (id, callback, payload) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: editSpecialty({ pathname: id, payload }), callback })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };
  return {
    add_specialty,
    get_Specialties,
    get_Specialty_Staff,
    edit_Specialty,
    get_Single_Specialty,
    loading,
  };
}
