import { useState } from 'react';
import useMakeRequest from 'hooks/useMakeRequest';
import useTryCatch from 'hooks/useTryCatch';

export default function useProgressNote() {
  const { makeAnonymousRequest } = useMakeRequest();
  const [loading, setLoading] = useState(false);
  const { tryCatch } = useTryCatch();

  const get_single_progress_note = (progressNoteId) => {
    const url = `/progress-note/${progressNoteId}`
    return tryCatch(async () => {
      setLoading(true);
      return makeAnonymousRequest({ url, method: 'get', alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    })
  }

  const add_progress_note_vitals = (payload) => {
    const url = `/institution/visit/add-vital-sign`
    return tryCatch(async () => {
      setLoading(true);
      return makeAnonymousRequest({ url, payload, method: 'post' })
        .then((res) => res)
        .finally(() => setLoading(false));
    })
  }

  return {
    loading,
    get_single_progress_note,
    add_progress_note_vitals
  };
}
