import React from "react";
import BreadCrumbs from "components/BreadCrumbs";
import Navbar from "components/Navbar";
import { SlideUp } from "components/Animations";
import Avatar from "components/Avatar/Avatar";
import VisitDetails from "components/Visit/VisitDetails";
import { VisitDetailsContext } from "context";
import useProgressNoteDetails from "./useProgressNoteDetails";
import { userInitals } from "utils/ImagePlaceholder";
import { formatTwelveHour } from "utils/formatTime";
import { formatDate } from "utils/formatDate";
import Spinner from "components/Spinner";

export default function ProgressNoteDetails() {
    const { options, isPurposeToAdd, contextValues, progressNote, isFetchingProgressNote, getSingleProgressNoteProxy } = useProgressNoteDetails()

    return (
        <>
            <Navbar title="Patient Management" />
            <BreadCrumbs options={options} />
            {isFetchingProgressNote && !progressNote?.patient ? <Spinner /> :
                <SlideUp>
                    <div className="p-[24px]">
                        <div className="lg:flex w-full h-fit">
                            <div className="flex-1">
                                {!isPurposeToAdd && <p className="mb-[8px] text-14 font-campton_sb text-neutral_black">Patient information</p>}
                                <div className="flex border border-neutral_stroke_2 rounded-[8px] p-[16px]">
                                    <Avatar
                                        url={progressNote?.patient?.profilePic}
                                        width="80px"
                                        height="80px"
                                        //alt={profile?.first_name?.split('')[0]}
                                        initials={userInitals(progressNote?.patient?.firstName, progressNote?.patient?.lastName)}
                                        className="w-[40px] sm:w-[80px] h-[40px] sm:h-[80px] mr-[12px] shrink-0"
                                    />
                                    <div>
                                        <p className="text-16 text-neutral_black font-campton_m capitalize">
                                            {progressNote?.patient?.firstName} {progressNote?.patient?.lastName}{' '}
                                            <span className="text-14 text-neutral_body font-campton_r">| {progressNote?.patient?.gNumber}</span>
                                        </p>
                                        <p className="text-14 text-neutral_body font-campton_r">{progressNote?.patient?.emailAddress}</p>
                                        <p className="text-14 text-neutral_body font-campton_r">
                                            {progressNote?.patient?.countryCode}{progressNote?.patient?.phoneNumber}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {!isPurposeToAdd && <div className="w-full lg:w-[405px] lg:ml-[16px] mt-[24px] lg:mt-0">
                                <p className="mb-[8px] text-14 font-campton_sb text-neutral_black">Added By</p>
                                <div className="flex border border-neutral_stroke_2 rounded-[8px] p-[16px]">
                                    <Avatar
                                        url={progressNote?.provider?.profilePic}
                                        width="80px"
                                        height="80px"
                                        //alt={profile?.first_name?.split('')[0]}
                                        initials={userInitals(progressNote?.provider?.firstName, progressNote?.provider?.lastName)}
                                        className="w-[40px] sm:w-[80px] h-[40px] sm:h-[80px] mr-[12px] shrink-0"
                                    />
                                    <div>
                                        <p className="text-16 text-neutral_black font-campton_m capitalize">
                                            {progressNote?.provider?.firstName} {progressNote?.provider?.lastName} <span className="text-14 text-neutral_body font-campton_r">| {progressNote?.provider?.GPINumber}</span>
                                        </p>
                                        <p className="text-14 text-neutral_body font-campton_r">{progressNote?.provider?.emailAddress}</p>
                                        <p className="text-14 text-neutral_body font-campton_r">{progressNote?.provider?.countryCode}{progressNote?.provider?.phoneNumber}</p>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        {!isPurposeToAdd && <div className="lg:flex w-full h-fit mt-[16px]">
                            <div className="basis-[50%] border border-neutral_stroke_2 rounded-[8px] p-[16px] lg:mr-[16px] mb-[16px] lg:mb-0">
                                <div className="flex justify-between text-14">
                                    <p className="text-neutral_body font-campton_r">Progress note type</p>
                                    <p className="text-neutral_black font-campton_m capitalize">{progressNote?.type}</p>
                                </div>
                            </div>
                            <div className="basis-[50%] border border-neutral_stroke_2 rounded-[8px] p-[16px]">
                                <div className="flex justify-between text-14">
                                    <p className="text-neutral_body font-campton_r">Date & time recorded</p>
                                    <p className="text-neutral_black font-campton_m capitalize">{formatDate(progressNote?.createdAt)}, {formatTwelveHour(progressNote?.createdAt)}</p>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    <div>
                        <VisitDetailsContext.Provider value={contextValues}>
                            <div className="border-t border-neutral_stroke_2">
                                <VisitDetails
                                    callback={getSingleProgressNoteProxy}
                                />
                            </div>
                        </VisitDetailsContext.Provider>
                    </div>
                </SlideUp>
            }
        </>
    )
}