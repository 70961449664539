import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types"
import { Button } from "components/Buttons";
import FormWrapper from "components/FormWrapper";
import { Input, TextArea } from "components/Inputs";
import Dropdown from "components/Dropdown";
import { useSelector } from "react-redux";
import useProviders from "hooks/fetch/useProviders";
import Spinner from "components/Spinner";
import useLocations from "hooks/fetch/useLocations";

export default function BasicInformation({
    gNumber, setGNumber, selectedLocation, patient, loading,
    admittedBy, handleChange, formData, onSelect, cancel, next
}) {
    const { institutionId } = useSelector(state => state.auth)
    const { providers } = useSelector(state => state.providers)
    const { get_institution_providers } = useProviders()
    const { get_locations, loading: isFetchingLocations = true } = useLocations()
    const [availableLocations, setAvailableLocations] = useState([])

    const disabled = useMemo(() => {
        return loading || !patient?.firstName || !patient?.lastName ||
            !selectedLocation || !admittedBy || !formData?.admissionDiagnosis || !formData?.roomNumber
    }, [loading, formData, patient, admittedBy, selectedLocation])

    useEffect(() => {
        get_institution_providers({ id: `${institutionId}/providers` })
        get_locations({ limit: 1000 })
            .then(res => res?.code === 200 && setAvailableLocations(res?.data?.locations))
    }, [])

    return (
        <FormWrapper className="h-full flex flex-col justify-between">
            <div>
                <h2 className="text-16 text-neutral_black font-campton_m mb-[24px]">Basic Information</h2>
                <section className="md:grid grid-cols-2 gap-[24px] w-full mb-[24px]">
                    <div className="mb-[24px] md:mb-0">
                        <Input
                            label="G-Number"
                            type="text"
                            id="gNumber"
                            name="gNumber"
                            measurement={loading ? <Spinner /> : ''}
                            value={gNumber}
                            onChange={(e) => setGNumber(e.target.value)}
                        />
                    </div>
                    <div className="mb-[24px] md:mb-0">
                        <Input
                            label="Patient Name"
                            type="text"
                            id="patientName"
                            name="patientName"
                            disabled={true}
                            value={patient?.firstName ? `${patient?.firstName} ${patient?.lastName}` : ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-[24px] md:mb-0">
                        <Input
                            label="Email Address"
                            type="text"
                            id="emailAddress"
                            name="emailAddress"
                            disabled={true}
                            value={patient?.emailAddress || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <Dropdown
                            label="Location"
                            id="location"
                            name="location"
                            selected={selectedLocation}
                            loading={isFetchingLocations}
                        >
                            {availableLocations?.map((location) =>
                                <div
                                    key={location?._id}
                                    data-testid={location?._id}
                                    onClick={() => {
                                        onSelect({
                                            name: `${location?.city}, ${location?.country}`,
                                            value: `${location?.address} ${location?.city}`,
                                            key: 'location'
                                        })
                                    }}
                                    className="py-[11px] px-[16px] flex items-center justify-between
                                        cursor-pointer hide_tap transition ease-in-out duration-500 hover:bg-[#F2F3F3]"
                                >
                                    <div className="w-full">
                                        <p className="text-14 font-campton_r capitalize truncate">
                                            {`${location?.state}, ${location?.country}.`}
                                        </p>
                                        <p className="text-[10px] font-campton_r capitalize truncate">{`${location?.address}`}</p>
                                    </div>
                                </div>
                            )}
                        </Dropdown>
                    </div>
                </section>
                <section className="grid md:grid-cols-2 gap-[24px] mb-[24px]">
                    <div>
                        <Input
                            label="Room Number"
                            type="text"
                            id="roomNumber"
                            name="roomNumber"
                            value={formData?.roomNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <Dropdown
                            label="Admitted By"
                            type="select"
                            id="admittedBy"
                            name="admittedBy"
                            selected={admittedBy ? `${admittedBy?.firstName} ${admittedBy?.lastName}` : ''}
                            onSelect={onSelect}
                        >
                            {providers?.providers?.map((staff) =>
                                <div
                                    key={staff?._id}
                                    data-testid={staff?._id}
                                    onClick={() => onSelect(staff)}
                                    className={`py-[11px] px-[20px] flex items-center justify-between
                                    cursor-pointer hide_tap transition ease-in-out duration-500 hover:bg-[#F2F3F3]`}
                                >
                                    <div className="flex items-center">
                                        <p className="text-14 font-campton_r capitalize">{staff?.firstName} {staff?.lastName}</p>
                                    </div>
                                </div>
                            )}
                        </Dropdown>
                    </div>
                </section>
                <div>
                    <TextArea
                        id="admissionDiagnosis"
                        name="admissionDiagnosis"
                        label='Admission Diagnosis'
                        //readOnly={loading}
                        value={formData.admissionDiagnosis}
                        onChange={handleChange}
                        rows={3}
                    />
                </div>
            </div>
            <section className="flex justify-end">
                <Button
                    name="Cancel"
                    theme="transparent"
                    type="button"
                    onClick={cancel}
                    className="h-[40px] text-16 w-[79px] mr-[16px]"
                />
                <Button
                    name="Next"
                    theme="blue"
                    type="button"
                    onClick={next}
                    disabled={disabled}
                    className="h-[40px] text-16 w-[88px]"
                />
            </section>
        </FormWrapper >
    )
}

BasicInformation.propTypes = {
    formData: PropTypes.object,
    patient: PropTypes.object,
    gNumber: PropTypes.string,
    onSelect: PropTypes.func,
    loading: PropTypes.bool,
    setGNumber: PropTypes.func,
    handleChange: PropTypes.func,
    selectedLocation: PropTypes.string,
    admittedBy: PropTypes.object,
    cancel: PropTypes.func,
    next: PropTypes.func
}