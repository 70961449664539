import React, { useContext } from 'react';
import { ViewElectronicSignature } from './ViewSignature';
import PropTypes from "prop-types"
import AddElectronicSignature from './AddSignature';
import { VisitDetailsContext } from 'context';

export default function ElectronicSignature() {
    const { singleVisit, progressNote, getSingleProgressNoteProxy, getSingleVisit  } = useContext(VisitDetailsContext)
    const signature = singleVisit?.eSignature || progressNote?.eSignature
    const id = singleVisit?._id || progressNote?._id

    return (
        <>
            <h2 className="text-16 sm:text-18 text-[#0D0F11] font-campton_m">Electronic Signature</h2>
            {signature?.createdAt && Object.keys(signature).length ?
                <ViewElectronicSignature /> :
                <AddElectronicSignature
                    type={singleVisit?._id ? "visit" : "progressNote"}
                    id={id}
                    callback={getSingleVisit || getSingleProgressNoteProxy}
                />
            }
        </>
    )
}

ElectronicSignature.propTypes = {
    callback: PropTypes.func
}