import { useState } from "react";

export default function useMultiStepForm(steps) {
    const [currentStepIndex, setCurrentStepIndex] = useState(0)

    function next(numOfStepToTake = 1) {
        setCurrentStepIndex((i) => {
            if (i >= steps.length - 1) return i
            return i + numOfStepToTake
        })
    }

    function back(numOfStepToTake = 1) {
        setCurrentStepIndex((i) => {
            if (i <= 0) return i
            return i - numOfStepToTake
        })
    }

    function resetSteps() {
        setCurrentStepIndex(0)
    }

    return {
        currentStepIndex,
        setCurrentStepIndex,
        step: steps[currentStepIndex],
        steps,
        next,
        resetSteps,
        back
    }
}