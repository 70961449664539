import Button from 'components/Buttons/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import successGif from 'assets/svg/check.gif';
import { useAuthState } from 'store/modules/auth';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import Copy from 'assets/icons/copy.svg';

const LoginSuccess = () => {
  //Hooks
  const navigate = useNavigate();
  const { copyToClipboard } = useCopyToClipboard();

  //Redux Store
  const { user } = useAuthState();

  return (
    <div className="">
      <div className="text-center mt-2">
        <div className="flex items-center justify-center">
          <img src={successGif} alt="success" className="w-[50%] [h-50%]" />
        </div>
        <p className="font-campton_sb text-20 md:text-28 text-neutral_black">Congratulations, {user?.firstName}!</p>
        <p className="mt-4 font-campton_r text-14 md:text-16 text-neutral_body inline-block">
          Your account was successfully created. Your GPI No. is
          <span className="inline-flex items-center">
            {user?.GPINumber}
            <img
              src={Copy}
              data-testid="test-copy"
              className="ml-2 cursor-pointer"
              onClick={() => copyToClipboard(user?.GPINumber)}
            />
          </span>
        </p>
      </div>
      <Button
        name="My Dashbaord"
        theme="blue"
        className="w-full mt-10 h-12"
        onClick={() => navigate(!user?.businessName && user.institutionId ? '/dashboard' : '/onboarding')}
      />
    </div>
  );
};

export default LoginSuccess;
