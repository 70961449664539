export const patient_chart_data = [
  {
    day: 2021,
    total_outflow: 0,
  },
  {
    day: 2022,
    total_outflow: 150,
  },
  {
    day: 2023,
    total_outflow: 100,
  },
  {
    day: 2024,
    total_outflow: 90,
  },
  {
    day: 2025,
    total_outflow: 250,
  },
];

export const patient_reg_chart_dropdown = [
  {
    id: '1',
    name: 'Monthly',
    value: 'monthly',
  },
  {
    id: '2',
    name: 'Yearly',
    value: 'yearly',
  },
  // {
  //   id: '3',
  //   name: 'Custom',
  //   value: 'Custom',
  // },
];

export const patient_list_header = ['Patient Name', 'Phone number', 'Date created', 'Location', ''];

export const patient_list_data = [
  {
    id: 1,
    firstName: 'Gabriel',
    lastName: 'Inyamah',
    gender: 'Male',
    phoneNumber: '+23490909090',
    created_at: 'May 27, 2020',
    location: 'Reddington Hospital',
  },
  {
    id: 2,
    firstName: 'Success',
    lastName: 'Ologunsua',
    gender: 'Female',
    phoneNumber: '+23490909090',
    created_at: 'May 27, 2020',
    location: 'R-Jolad Hospital',
  },
  {
    id: 3,
    firstName: 'Chinedu',
    lastName: 'Inyamah',
    gender: 'Male',
    phoneNumber: '+23490909090',
    created_at: 'May 27, 2020',
    location: 'Reddington Hospital',
  },
  {
    id: 4,
    firstName: 'Gabriel',
    lastName: 'Inyamah',
    gender: 'Male',
    phoneNumber: '+23490909090',
    created_at: 'May 27, 2020',
    location: 'Reddington Hospital',
  },
  {
    id: 5,
    firstName: 'Success',
    lastName: 'Inyamah',
    gender: 'Female',
    phoneNumber: '+23490909090',
    created_at: 'May 27, 2020',
    location: 'R-Jolad Hospital',
  },
  {
    id: 6,
    firstName: 'Chinedu',
    lastName: 'Inyamah',
    gender: 'Male',
    phoneNumber: '+23490909090',
    created_at: 'May 27, 2020',
    location: 'Reddington Hospital',
  },
];

export const patient = {
  _id: '646422fc2c29ddde8e324361',
  firstName: 'chinedu',
  lastName: 'ikechi',
  emailAddress: 'chinedu@gmail.com',
  phoneNumber: '09058702557',
  gender: 'male',
  homeAddress: '12a,Anthony Street',
  country: 'Ghana',
  dateOfBirth: '1960-01-01T00:00:00.000Z',
  gNumber: 'G-00470992',
  patientStatus: 'active',
  verificationToken: '',
  verificationTokenExpiry: '',
  refreshToken: '2e69af9e110a4a55174daa3c4dbdfd07fc46856c8002a763c05f618eac4826d9734a526143c2ba567ff17ea980a90d4322a3',
  maritalStatus: 'single',
  createdAt: '2023-05-17T00:42:36.029Z',
  updatedAt: '2023-05-17T00:43:50.650Z',
  medicalRecord: {
    "_id": "65310fa99e39f017823df36e",
    "patientId": "65310fa99e39f017823df36c",
    "bloodType": {
      "bloodType": "O+",
      "genotype": "AA"
    },
    "allergies": [
      "Penicillin",
      "Ampicillin",
      "Amoxicillin",
      "Sulfa drugs"
    ],
    "lifestyleHabits": {
      "physicalActivity": {
        "frequency": "",
        "duration": "",
        "typeOfExercise": ""
      },
      "sleepPattern": {
        "avgSleepHours": "",
        "consistentSleepSchedule": "",
        "sleepDifficulty": ""
      },
      "dietAndNutrition": {
        "specificDietaryPlan": "",
        "fruitsAndVegetables": "",
        "processedFoods": ""
      },
      "stressManagement": {
        "method": "",
        "relaxationTechniques": "",
        "specificStressors": ""
      },
      "substanceUse": {
        "smoking": "",
        "alcohol": "",
        "recreationalDrugs": ""
      },
      "mentalHealth": {
        "historyOfMentalConditions": "",
        "emotionalHealthRating": "",
        "mentalActivities": ""
      },
      "environmentalFactors": {
        "pollution": "",
        "occupationalHazards": ""
      },
      "socialConnections": {
        "frequency": "",
        "supportSystem": "",
        "challenges": ""
      },
      "safeSexPractises": {
        "sexuallyActive": "",
        "partner": "",
        "contraceptives": "",
        "stiTests": ""
      },
      "birthControl": {
        "contraception": "",
        "satisfaction": ""
      },
      "sexualDysfunction": {
        "difficulty": "",
        "specificIssues": "",
        "discussWithHealthProvider": ""
      },
      "lifestyleLastModified": "2023-10-19T10:41:41.466Z"
    },
    "emergencyContact": [],
    "insuranceInformation": [
      {
        "insuranceId": "20231022-092934fufu.jpeg",
        "providerName": "Reliance",
        "policyNumber": "434HYU",
        "groupNumber": "21",
        "plan": "Golden",
        "startDate": "2023-10-21T00:00:00.000Z",
        "endDate": "2023-12-30T00:00:00.000Z",
        "_id": "6534dd750a780219438ffd00"
      }
    ],
    "medicalDiagnosis": [],
    "familyHospital": [],
    "surgeries": [],
    "immunizationHistory": [],
    "familyHistory": []
  }
};

export const bills_list_header = ['Billing ID', 'Amount (NGN)', 'Bill Date', 'Due Date', 'Patient Name', 'Status', ''];

export const bills_list_data = [
  {
    id: 1,
    description: 'Description of the service rendered',
    invoiceNumber: 'G/B/12/03/23',
    amount: 15000,
    dateOfService: '1960-01-01T00:00:00.000Z',
    dueDate: '1960-01-01T00:00:00.000Z',
    status: 'Pending',
  },
  {
    id: 2,
    description: 'Description of the service rendered',
    invoiceNumber: 'G/B/12/03/23',
    amount: 45000,
    dateOfService: '1960-01-01T00:00:00.000Z',
    dueDate: '1960-01-01T00:00:00.000Z',
    status: 'Pending',
  },
  {
    id: 3,
    description: 'Description of the service rendered',
    invoiceNumber: 'G/B/12/03/23',
    amount: 35000,
    dateOfService: '1960-01-01T00:00:00.000Z',
    dueDate: '1960-01-01T00:00:00.000Z',
    status: 'Pending',
  },
];

export const appointments = [
  {
    id: '0',
    means: 'onsite',
    profilePicture: '',
    specialist: {
      firstName: 'Gabriel',
      lastName: 'Gabe',
      qualification: 'Dr.',
    },
    subject: 'Cardiologist, MBBS',
    patient: 'Mohammed Oni',
    createdAt: '1960-01-01T00:00:00.000Z',
  },
  {
    id: '1',
    means: 'tele',
    profilePicture: '',
    specialist: {
      firstName: 'Gabriel',
      lastName: 'Gabe',
      qualification: 'Dr.',
    },
    subject: 'Cardiologist, MBBS',
    patient: 'Mohammed Oni',
    createdAt: '1960-01-01T00:00:00.000Z',
  },
];
export const medications_list = [
  {
    createdBy: {
      providerId: '6486613b1cb2d64cf32666d9',
      firstName: 'Lagoon Hospital',
      lastName: 'Lagoon Hospital',
      emailAddress: 'lagoonhospita@gmail.com',
      profilePic: '',
    },
    _id: '64c30e8a3e31caa8efc7a285',
    medicationName: 'Paracetamol',
    dosage: '2 Per day',
    startDate: '2023-12-12T00:00:00.000Z',
    endDate: '2023-12-24T00:00:00.000Z',
    frequency: '4 hours',
    routeOfAdministration: 'oral',
    note: "Don't take with coke",
    patientId: '648737bb82884aed6ede8349',
    institutionId: '6486613b1cb2d64cf32666d9',
    status: 'pending',
    createdAt: '2023-07-28T00:40:42.859Z',
    updatedAt: '2023-07-28T00:40:42.859Z',
    __v: 0,
  },
  {
    createdBy: {
      providerId: '6486613b1cb2d64cf32666d9',
      firstName: 'Lagoon Hospital',
      lastName: 'Lagoon Hospital',
      emailAddress: 'lagoonhospita@gmail.com',
      profilePic: '',
    },
    _id: '64c30e8a3e31caa8efc7a215',
    medicationName: 'Aspirin',
    dosage: '1 Per day',
    startDate: '2023-12-12T00:00:00.000Z',
    endDate: '2023-12-24T00:00:00.000Z',
    frequency: '4 hours',
    routeOfAdministration: 'oral',
    note: "Don't take with coke",
    patientId: '648737bb82884aed6ede8349',
    institutionId: '6486613b1cb2d64cf32666d9',
    status: 'paused',
    createdAt: '2023-07-28T00:40:42.859Z',
    updatedAt: '2023-07-28T00:40:42.859Z',
    __v: 0,
  },
];
export const medication_list_header = ['Medication Name', 'Start Date', 'End Date', 'Status', ''];
export const progress_notes_header = ['Date & Time', 'Type', 'Added Type', ''];
export const progress_notes_types = ['admissionNotes', 'inPatientProgress', 'dischargeSummary', 'addendum'];
export const progress_notes_data = [
  {
    _id: 1,
    created_at: '1960-01-01T00:00:00.000Z',
    type: 'Primary',
    added_by: 'Dr. John Alao',
  },
  {
    _id: 2,
    created_at: '1960-01-01T00:00:00.000Z',
    type: 'Primary',
    added_by: 'Dr. Paul Adeniran',
  },
];
export const routeOfAdministrationList = [
  {
    id: 1,
    name: 'Intramuscular',
    value: 'intramuscular',
  },
  {
    id: 2,
    name: 'Intraperitoneal',
    value: 'intraperitoneal',
  },
  {
    id: 3,
    name: 'Rectum',
    value: 'rectum',
  },
  {
    id: 4,
    name: 'Topical',
    value: 'topical',
  },
  {
    id: 5,
    name: 'Intranasal',
    value: 'intranasal',
  },
  {
    id: 6,
    name: 'Oral',
    value: 'oral',
  },
  {
    id: 7,
    name: 'Intravenous',
    value: 'intravenous',
  },
  {
    id: 8,
    name: 'Intradermal',
    value: 'intradermal',
  },
  {
    id: 9,
    name: 'Sublingual',
    value: 'sublingual',
  },
  {
    id: 10,
    name: 'Buccal',
    value: 'buccal',
  },
];
export const procedure_list_header = ['Procedure Name', 'Procedure Date/Time', 'Provider Info', 'Date Created', ''];
export const procedures = [
  {
    _id: '650ad22d63929dfa27bb1c39',
    procedureName: 'Biopsy',
    procedureDate: '2023-09-22T00:00:00.000Z',
    procedureTime: '12:00 AM',
    preProcedureAssessment: 'dfdfdf',
    preProcedureInstructions: 'sdsdsdsd',
    createdAt: '2023-09-20T11:06:21.830Z',
    updatedAt: '2023-09-20T11:06:21.830Z',
    patientInfo: {
      id: '64662cf2e3d4a6154dddbc9e',
      firstName: 'Johnson',
      lastName: 'Akinpelu',
      emailAddress: 'dunniakinpelu@outlook.com',
      gNumber: 'G-00718995',
    },
    providerInfo: {
      id: '64a2c7405859654c64cac6ca',
      firstName: 'timi',
      lastName: 'akinpelumi',
      emailAddress: 'oluwatimileyin@enyata.com',
      GPINumber: 'GPI-00796990',
      qualification: 'MBBS',
    },
    institutionInfo: {
      id: '64a2c1c45859654c64cac646',
      businessName: 'enyata hospital',
    },
  },
  {
    _id: '650ad1ac63929dfa27bb1c0f',
    procedureName: 'Cataract surgery',
    procedureDate: '2023-10-28T00:00:00.000Z',
    procedureTime: '12:00 AM',
    preProcedureAssessment: 'Looooolll',
    preProcedureInstructions: 'Nothing',
    createdAt: '2023-09-20T11:04:12.812Z',
    updatedAt: '2023-09-20T11:04:12.812Z',
    patientInfo: {
      id: '64662cf2e3d4a6154dddbc9e',
      firstName: 'Johnson',
      lastName: 'Akinpelu',
      emailAddress: 'dunniakinpelu@outlook.com',
      gNumber: 'G-00718995',
    },
    providerInfo: {
      id: '64b91bdaac4412e615dd705f',
      firstName: 'Gee',
      lastName: 'Akinpelu',
      emailAddress: 'godson@mailinator.com',
      GPINumber: 'GPI-00898348',
      qualification: 'MBA',
      title: 'Dr',
    },
    institutionInfo: {
      id: '64a2c1c45859654c64cac646',
      businessName: 'enyata hospital',
    },
  },
  {
    _id: '650ad08c63929dfa27bb1be7',
    procedureName: 'Appendectomy',
    procedureDate: '2023-09-29T00:00:00.000Z',
    procedureTime: '12:00 AM',
    preProcedureAssessment: 'gfggffgf',
    preProcedureInstructions: 'ggghhgghg',
    createdAt: '2023-09-20T10:59:24.722Z',
    updatedAt: '2023-09-20T10:59:24.722Z',
    patientInfo: {
      id: '64662cf2e3d4a6154dddbc9e',
      firstName: 'Johnson',
      lastName: 'Akinpelu',
      emailAddress: 'dunniakinpelu@outlook.com',
      gNumber: 'G-00718995',
    },
    providerInfo: {
      id: '64b91bdaac4412e615dd705f',
      firstName: 'Gee',
      lastName: 'Akinpelu',
      emailAddress: 'godson@mailinator.com',
      GPINumber: 'GPI-00898348',
      qualification: 'MBA',
      title: 'Dr',
    },
    institutionInfo: {
      id: '64a2c1c45859654c64cac646',
      businessName: 'enyata hospital',
    },
  },
];
export const therapies_list_header = ['Therapy Name', 'Therapy Date/Time', 'Added By', 'Date Created', ''];
export const therapies = [
  {
    _id: '650b0aaf6896e4532dc9fc67',
    therapyName: 'Family therapy',
    therapyDate: '2023-09-23T00:00:00.000Z',
    startTime: '02:30 PM',
    endTime: '04:00 PM',
    note: 'Nothing',
    visitId: '64b925d1cbb14e65b4cec5dd',
    institutionId: '64a2c1c45859654c64cac646',
    createdBy: {
      providerId: '64b91bdaac4412e615dd705f',
      firstName: 'Gee',
      lastName: 'Akinpelu',
      emailAddress: 'godson@mailinator.com',
      profilePic: '',
    },
    createdAt: '2023-09-20T15:07:27.408Z',
    updatedAt: '2023-09-20T15:07:27.408Z',
    patientInfo: {
      id: '64662cf2e3d4a6154dddbc9e',
      firstName: 'Johnson',
      lastName: 'Akinpelu',
      emailAddress: 'dunniakinpelu@outlook.com',
      gNumber: 'G-00718995',
    },
  },
  {
    _id: '650b0a406896e4532dc9fc42',
    therapyName: 'Music Therapy',
    therapyDate: '2023-09-23T00:00:00.000Z',
    startTime: '02:30 PM',
    endTime: '04:30 PM',
    note: 'Nothing',
    visitId: '64b925d1cbb14e65b4cec5dd',
    institutionId: '64a2c1c45859654c64cac646',
    createdBy: {
      providerId: '64b91bdaac4412e615dd705f',
      firstName: 'Gee',
      lastName: 'Akinpelu',
      emailAddress: 'godson@mailinator.com',
      profilePic: '',
    },
    createdAt: '2023-09-20T15:05:36.429Z',
    updatedAt: '2023-09-20T15:05:36.429Z',
    patientInfo: {
      id: '64662cf2e3d4a6154dddbc9e',
      firstName: 'Johnson',
      lastName: 'Akinpelu',
      emailAddress: 'dunniakinpelu@outlook.com',
      gNumber: 'G-00718995',
    },
  },
  {
    _id: '650b0a406896e4532dc9fc49',
    therapyName: 'Music Therapy',
    therapyDate: '2023-09-23T00:00:00.000Z',
    startTime: '02:30 PM',
    endTime: '04:30 PM',
    note: 'Nothing',
    visitId: '64b925d1cbb14e65b4cec5dd',
    institutionId: '64a2c1c45859654c64cac646',
    createdBy: {
      // providerId: '64b91bdaac4412e615dd705f',
      businessName: 'enyata hospital',
      emailAddress: 'osita@enyata.com',
      profilePic: '',
    },
    createdAt: '2023-09-20T15:05:36.429Z',
    updatedAt: '2023-09-20T15:05:36.429Z',
    patientInfo: {
      id: '64662cf2e3d4a6154dddbc9e',
      firstName: 'Johnson',
      lastName: 'Akinpelu',
      emailAddress: 'dunniakinpelu@outlook.com',
      gNumber: 'G-00718995',
    },
  },
];
export const referral_list_header = ['Institution Name', 'Referral Date', 'Receiving Provider', ''];
export const referrals = [
  {
    _id: '650b03166896e4532dc9f9b9',
    referringProviderId: '64b91bdaac4412e615dd705f',
    referringInstitutionId: '64a2c1c45859654c64cac646',
    receivingProviderId: '64edcbda840ad213c0bc1e51',
    receivingInstitutionId: '64a43f20c212288206699b95',
    visitId: '64b925d1cbb14e65b4cec5dd',
    reason: 'Nothing',
    createdAt: '2023-09-20T14:35:02.771Z',
    updatedAt: '2023-09-20T14:35:02.771Z',
    patientInfo: {
      id: '64662cf2e3d4a6154dddbc9e',
      firstName: 'Johnson',
      lastName: 'Akinpelu',
      emailAddress: 'dunniakinpelu@outlook.com',
      gNumber: 'G-00718995',
    },
    providerInfo: {
      id: '64edcbda840ad213c0bc1e51',
      firstName: 'Katie',
      lastName: 'Combs',
      emailAddress: 'Combs@gmail.com',
      GPINumber: 'GPI-00152839',
      qualification: 'MBChC',
      specialty: '',
    },
    institutionInfo: {
      id: '64a43f20c212288206699b95',
      businessName: 'Creek Hospital',
    },
  },
  {
    _id: '650b02ea6896e4532dc9f95d',
    referringProviderId: '64b91bdaac4412e615dd705f',
    referringInstitutionId: '64a2c1c45859654c64cac646',
    receivingProviderId: '64b91bdaac4412e615dd705f',
    receivingInstitutionId: '64a2c1c45859654c64cac646',
    visitId: '64b925d1cbb14e65b4cec5dd',
    reason: 'Patient no dey okay',
    createdAt: '2023-09-20T14:34:18.766Z',
    updatedAt: '2023-09-20T14:34:18.766Z',
    patientInfo: {
      id: '64662cf2e3d4a6154dddbc9e',
      firstName: 'Johnson',
      lastName: 'Akinpelu',
      emailAddress: 'dunniakinpelu@outlook.com',
      gNumber: 'G-00718995',
    },
    providerInfo: {
      id: '64b91bdaac4412e615dd705f',
      firstName: 'Gee',
      lastName: 'Akinpelu',
      emailAddress: 'godson@mailinator.com',
      GPINumber: 'GPI-00898348',
      qualification: 'MBA',
      title: 'Dr',
    },
    institutionInfo: {
      id: '64a2c1c45859654c64cac646',
      businessName: 'enyata hospital',
    },
  },
  {
    _id: '650b01596896e4532dc9f58f',
    referringProviderId: '64b91bdaac4412e615dd705f',
    referringInstitutionId: '64a2c1c45859654c64cac646',
    receivingProviderId: '64a2c7405859654c64cac6ca',
    receivingInstitutionId: '64a2c1c45859654c64cac646',
    visitId: '64b925d1cbb14e65b4cec5dd',
    reason: 'Nothing',
    createdAt: '2023-09-20T14:27:37.043Z',
    updatedAt: '2023-09-20T14:27:37.043Z',
    patientInfo: {
      id: '64662cf2e3d4a6154dddbc9e',
      firstName: 'Johnson',
      lastName: 'Akinpelu',
      emailAddress: 'dunniakinpelu@outlook.com',
      gNumber: 'G-00718995',
    },
    providerInfo: {
      id: '64a2c7405859654c64cac6ca',
      firstName: 'timi',
      lastName: 'akinpelumi',
      emailAddress: 'oluwatimileyin@enyata.com',
      GPINumber: 'GPI-00796990',
      qualification: 'MBBS',
    },
    institutionInfo: {
      id: '64a2c1c45859654c64cac646',
      businessName: 'enyata hospital',
    },
  },
];
export const patientFilter = [
  {
    name: 'All Locations',
    value: 'All Locations',
  },
]
export const defaultFilter = [
  {
    name: 'all',
    value: '',
  }
]
export const labTestStatus = [
  {
    name: 'all',
    value: '',
  },
  {
    name: 'requested',
    value: 'requested',
  },
  {
    name: 'completed',
    value: 'completed',
  }
]
export const patientValueContext = {
  "_id": "659f39491f9d9db22e75e699",
  "institutionId": "655f4e3429472c09342ed5e4",
  "roomNumber": "90",
  "admissionDiagnosis": "mandate",
  "admittedBy": "6570788e0e39d234676831d3",
  "careTeam": [
      {
          "_id": "6570788e0e39d234676831d3",
          "firstName": "bimpe",
          "lastName": "thomas",
          "emailAddress": "bimpethomas@yopmail.com",
          "GPINumber": "GPI-00880071",
          "profilePic": "",
          "specialty": "Surgery"
      }
  ],
  "location": "No 12, test street Ikeja",
  "shockIndex": "0",
  "lengthOfStay": 0,
  "severity": "noVitals",
  "admissionStatus": "admitted",
  "visit": "659f39491f9d9db22e75e697",
  "createdAt": "2024-01-11T00:41:45.990Z",
  "updatedAt": "2024-01-11T00:41:45.990Z",
  "patientInfo": {
      "id": "64b925d1cbb14e65b4cec5dd",
      "firstName": "Estella",
      "lastName": "Monroe",
      "emailAddress": "estella@yopmail.com",
      "countryCode": "+234",
      "phoneNumber": "4887346783",
      "gNumber": "G-00295833",
      "gender": "",
      "profilePic": "",
      "dateOfBirth": "2023-11-28T00:00:00.000Z",
      "maritalStatus": "",
      "homeAddress": "No 23, test street",
      "emergencyContact": []
  },
  "admittedByInfo": {
      "id": "6570788e0e39d234676831d3",
      "firstName": "bimpe",
      "lastName": "thomas",
      "emailAddress": "bimpethomas@yopmail.com",
      "GPINumber": "GPI-00880071",
      "profilePic": "",
      "specialty": "Surgery"
  }
}
export const progressNote =         {
  "_id": "659fba728a31c9a4b7189c99",
  "type": "dischargeSummary",
  "patient": {
      "_id": "6578299fa13d0d85a95e2d2d",
      "firstName": "Estella",
      "lastName": "Monroe",
      "emailAddress": "estella@yopmail.com",
      "countryCode": "+234",
      "phoneNumber": "4887346783",
      "gender": "",
      "dateOfBirth": "2023-11-28T00:00:00.000Z",
      "gNumber": "G-00295833",
      "profilePic": ""
  },
  "visit": "659f39491f9d9db22e75e697",
  "createdAt": "2024-01-11T09:52:50.361Z",
  "updatedAt": "2024-01-11T09:52:50.361Z",
  "vitals": [],
  "labrequests": [
      {
          "_id": "659fe287ff3a8c2af239a248",
          "testType": "Microscopic Examination",
          "testProperties": {},
          "isCritical": false,
          "labReadId": "L8545953",
          "patientId": "6578299fa13d0d85a95e2d2d",
          "institutionId": "655f4e3429472c09342ed5e4",
          "requestedBy": "655f4e3429472c09342ed5e6",
          "note": "note",
          "isAbnormal": false,
          "abnormalFindings": "",
          "testResult": [],
          "dateRequested": "2024-01-11T12:43:51.414Z",
          "requestStatus": "requested",
          "progressNoteId": "659fba728a31c9a4b7189c99",
          "createdAt": "2024-01-11T12:43:51.415Z",
          "updatedAt": "2024-01-11T12:43:51.415Z",
          "__v": 0
      }
  ],
  "medications": [
      {
          "_id": "65a0df63a3108f7f8408a981",
          "medicationName": "Ampliclus",
          "dosage": "3 milligram",
          "startDate": "2024-01-25T00:00:00.000Z",
          "endDate": "2024-02-02T00:00:00.000Z",
          "frequency": "twice a day",
          "routeOfAdministration": "intraperitoneal",
          "note": "sdsf",
          "patientId": "6578299fa13d0d85a95e2d2d",
          "createdBy": {
              "providerId": "655f4e3429472c09342ed5e6",
              "firstName": "Lagoon Hospital",
              "lastName": " ",
              "emailAddress": "lagoonhospital@yopmail.com",
              "profilePic": "",
              "GPINumber": "GPI-00174302",
              "gNumber": ""
          },
          "progressNoteId": "659fba728a31c9a4b7189c99",
          "institutionId": "655f4e3429472c09342ed5e4",
          "status": "pending",
          "createdAt": "2024-01-12T06:42:43.303Z",
          "updatedAt": "2024-01-12T06:42:43.303Z",
          "__v": 0
      },
  ],
  "chiefComplaint": [],
  "visitSummary": {
      "_id": "659fba7a8a31c9a4b7189ca4",
      "summary": "pols",
      "instructions": [
          {
              "note": "asjajs",
              "_id": "659fba7e8a31c9a4b7189ca8",
              "createdAt": "2024-01-11T09:53:02.374Z",
              "updatedAt": "2024-01-11T09:53:02.374Z"
          }
      ]
  },
  "provider": {
      "_id": "655f4e3429472c09342ed5e6",
      "firstName": "Lagoon Hospital",
      "lastName": " ",
      "emailAddress": "lagoonhospital@yopmail.com",
      "countryCode": "+234",
      "phoneNumber": "2938289389",
      "GPINumber": "GPI-00174302",
      "profilePic": ""
  },
  "referrals": [
      {
          "_id": "65a0dc81a3108f7f8408a754",
          "patientId": "6578299fa13d0d85a95e2d2d",
          "progressNoteId": "659fba728a31c9a4b7189c99",
          "referringProviderId": "655f4e3429472c09342ed5e6",
          "referringInstitutionId": "655f4e3429472c09342ed5e4",
          "receivingProviderId": "6556480855ea5ed2627c72bd",
          "receivingInstitutionId": "654b9dd086d66f4a9280e03e",
          "visitId": "659fba728a31c9a4b7189c99",
          "reason": "nothing",
          "createdAt": "2024-01-12T06:30:25.775Z",
          "updatedAt": "2024-01-12T06:30:25.775Z",
          "__v": 0
      },
  ],
  "procedures": [
      {
          "_id": "65a0db04a3108f7f8408a607",
          "procedureName": "Test therapy",
          "procedureDate": "2024-01-26T00:00:00.000Z",
          "procedureTime": "08:00 AM",
          "preProcedureAssessment": "Progress note P1",
          "preProcedureInstructions": "no",
          "attendingProviderId": "6570788e0e39d234676831d3",
          "patientId": "6578299fa13d0d85a95e2d2d",
          "institutionId": "655f4e3429472c09342ed5e4",
          "visitId": "659fba728a31c9a4b7189c99",
          "progressNoteId": "659fba728a31c9a4b7189c99",
          "totalFee": 180,
          "serviceDetails": [
              {
                  "category": "Procedure",
                  "subCategory": "Test therapy",
                  "fee": 90,
                  "vat": 90,
                  "_id": "65a0db04a3108f7f8408a608"
              }
          ],
          "createdAt": "2024-01-12T06:24:04.024Z",
          "updatedAt": "2024-01-12T06:24:04.024Z",
          "__v": 0
      }
  ],
  "therapies": [
      {
          "_id": "65a0e01da3108f7f8408aa08",
          "therapyName": "Speech therapy",
          "therapyDate": "2024-01-27T00:00:00.000Z",
          "startTime": "08:00 AM",
          "endTime": "09:00 AM",
          "note": "no",
          "progressNoteId": "659fba728a31c9a4b7189c99",
          "patientId": "6578299fa13d0d85a95e2d2d",
          "attendingProviderId": "65520a435111c09830ca50fc",
          "visitId": "659fba728a31c9a4b7189c99",
          "institutionId": "655f4e3429472c09342ed5e4",
          "createdBy": {
              "providerId": "655f4e3429472c09342ed5e6",
              "firstName": "Lagoon Hospital",
              "lastName": " ",
              "emailAddress": "lagoonhospital@yopmail.com",
              "profilePic": ""
          },
          "totalFee": 31200,
          "serviceDetails": [
              {
                  "category": "Therapy",
                  "subCategory": "Speech therapy",
                  "fee": 30000,
                  "vat": 1200,
                  "_id": "65a0e01da3108f7f8408aa09"
              }
          ],
          "createdAt": "2024-01-12T06:45:49.356Z",
          "updatedAt": "2024-01-12T06:45:49.356Z",
          "__v": 0
      }
  ],
  "eSignature": {}
}