import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Navbar from 'components/Navbar';
import { SlideUp } from 'components/Animations';
import Modal from 'components/Modals/Modal';
import SubNavbar from 'components/SubNavbar';
import { useNavigate } from 'react-router-dom';
import Status from 'components/Status';
import Avatar from 'components/Avatar/Avatar';
import ServiceDetails from './ServiceDetails';
import { Button } from 'components/Buttons';
import PaymentHistory from './PaymentHistory';
import { setBillDetails, usePaymentState } from 'store/modules/payment';
import dayjs from 'dayjs';
import { formatCurrency } from 'utils/formatCurrency';
import { userInitals } from 'utils/ImagePlaceholder';
import { useAuthState } from 'store/modules/auth';
import usePayments from 'hooks/fetch/usePayments';
import { useDispatch } from 'react-redux';
import Confirm from 'components/Forms/Confirm';

export default function BillDetails() {
  const navigate = useNavigate();
  const [paymentHistory, setPaymentHistory] = useState(false);
  const { billDetails } = usePaymentState();
  const { user } = useAuthState();
  const { confirm_payment, loading } = usePayments();
  const [confirmPayment, setConfirmPayment] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Bill Details - Gabbi Health';
  }, []);

  const primaryLocation = user?.healthCareFacilities?.find((item) => item.isPrimaryAddress === true);
  const totalAmount = () => {
    return billDetails?.billDetails?.reduce((total, item) => total + item.fee + item.vat, 0);
  };

  const handleConfirmPayment = () => {
    confirm_payment({
      billId: billDetails?._id,
      amountPaid: billDetails?.amountExpected,
      amountExpected: billDetails?.amountExpected
    })
      .then((res) => {
        if (res?.code === 200) {
          dispatch(setBillDetails({ ...billDetails, ...res?.data }));
          setConfirmPayment(false)
        }
      });
  };

  return (
    <div>
      <Navbar title="Bill & Payments" />
      <SubNavbar
        previous="Billing History"
        onClick={() => navigate('/service/bill-and-payment')}
        current={billDetails?.billId}
      />
      <SlideUp>
        <div className="p-[24px] relative h-[80vh]">
          <section className="pb-[24px]">
            <p className="text-14 text-neutral_black font-campton_m mb-[8px]">Billing Information</p>
            <div className="grid grid-cols-2 sm:grid-cols-4 border-b border-dashed border-b-neutral_stroke_2">
              <List
                name="Billed On"
                value={dayjs(billDetails?.createdAt).format('MMM DD, YYYY')}
                className="mr-[20px]"
              />
              <List
                name="Bill Amount"
                value={`${formatCurrency(billDetails?.amountExpected + billDetails?.amountPaid)}`}
                className="sm:mr-[20px]"
              />
              <List
                name="Payment Due Date"
                value={billDetails?.dueDate ? dayjs(billDetails?.dueDate).format('MMM DD, YYYY') : 'N/A'}
                className="mr-[20px]"
              />
              <List name="Status" status={billDetails?.paymentStatus} />
            </div>
          </section>
          <section className="grid sm:grid-cols-2 pb-[24px] border-b border-b-neutral_stroke_2">
            <div className="mb-[16px] sm:mb-0">
              <p className="text-12 text-[#83889D] font-campton_r mb-[4px]">Bill From:</p>
              <div className="flex mb-[8px]">
                <Avatar
                  url={billDetails?.institutionInfo?.logoUrl}
                  width="40px"
                  height="40px"
                  alt={billDetails?.institutionInfo?.businessName?.split('')[0]}
                  initials={userInitals(
                    billDetails?.institutionInfo?.businessName?.split(' ')[0],
                    billDetails?.institutionInfo?.businessName?.split(' ')[1],
                  )}
                  className="bg-brand_secondary mr-[15px] w-[40px] h-[40px]"
                />
                <div>
                  <p className="text-14 font-campton_m text-neutral_black capitalize">
                    {billDetails?.institutionInfo?.businessName}
                  </p>
                  <p className="text-12 font-campton_r text-neutral_body leading-none">
                    {billDetails?.institutionInfo?.businessEmailAddress ?? 'N/A'}
                  </p>
                </div>
              </div>
              <p className="text-12 font-campton_r text-neutral_body leading-none capitalize-first">
                {primaryLocation?.address
                  ? `${primaryLocation?.address}, ${primaryLocation?.state}, ${primaryLocation?.city}, ${primaryLocation?.country}`
                  : 'N/A'}
              </p>
            </div>
            <div className="">
              <p className="text-12 text-[#83889D] font-campton_r mb-[4px]">Bill To:</p>
              <div className="flex mb-[8px]">
                <Avatar
                  url={billDetails?.patientInfo?.profilePic}
                  width="32px"
                  height="32px"
                  alt={billDetails?.patientInfo?.firstName?.split('')[0]}
                  initials={userInitals(billDetails?.patientInfo?.firstName, billDetails?.patientInfo?.lastName)}
                  className="bg-brand_secondary w-[32px] h-[32px] mr-[8px] shrink-0"
                />
                <div>
                  <p className="text-14 font-campton_m text-neutral_black capitalize">
                    {billDetails?.patientInfo?.firstName} {billDetails?.patientInfo?.lastName}
                  </p>
                  <p className="text-12 font-campton_r text-neutral_body leading-none">
                    {billDetails?.patientInfo?.emailAddress}
                  </p>
                </div>
              </div>
              <p className="text-12 font-campton_r text-neutral_body leading-none">
                {billDetails?.patientInfo?.homeAddress}
              </p>
            </div>
          </section>
          <section className={`${billDetails?.billDetails?.length ? '' : 'h-[70%]'} flex justify-between flex-col`}>
            {billDetails?.billDetails?.length ? (
              <ServiceDetails totalAmount={totalAmount()} />
            ) : (
              <p className="font-campton_m text-neutral_black text-18 text-center my-10">No service details available</p>
            )}
            <div className="flex justify-end py-[20px]">
              <Button
                name="View payment history"
                theme="transparent"
                className="h-[40px] text-16 px-[12px] w-[193px] mr-[17px]"
                onClick={() => setPaymentHistory(true)}
              />
              {billDetails?.paymentStatus === 'unpaid' && (
                <Button
                  name="Confirm payment"
                  theme="blue"
                  className="h-[40px] text-16 px-[12px] w-[193px]"
                  onClick={() => setConfirmPayment(true)}
                />
              )}
            </div>
          </section>
        </div>
      </SlideUp>
      {paymentHistory ? (
        <Modal
          closeModal={() => setPaymentHistory(false)}
          styles="w-full md:w-[457px]"
          position="modal-right"
          title="Payment History"
          variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
        >
          <PaymentHistory close={() => setPaymentHistory(false)} totalAmount={totalAmount()} />
        </Modal>
      ) : (
        ''
      )}
      {confirmPayment &&
        <Confirm
          confirmFnc={handleConfirmPayment}
          closeModal={() => setConfirmPayment(false)}
          title='Confirm?'
          loading={loading}
          caption="This bill will be marked as paid."
        />
      }
    </div>
  );
}

const List = ({ name, value, status, className }) => {
  return (
    <div className={`mb-[16px] border-r border-r-neutral_stroke_2 ${className}`}>
      <p className="text-12 text-[#83889D] font-campton_r mb-[4px]">{name}</p>
      <p className="text-14 text-[#364469] font-campton_r">{value}</p>
      {status ? <Status status={status} /> : ''}
    </div>
  );
};

List.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  status: PropTypes.string,
  className: PropTypes.string,
};
