import React, {useContext} from 'react';
import { Button } from 'components/Buttons';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactComponent as EmptyStateIcon } from 'assets/svg/empty-lifestyle.svg';
import EmptyState from "components/Table/EmptyState"
import PropTypes from 'prop-types';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import useLifestyleLogic from '../useLifestyleLogic';

import ProgressBar from 'components/Progressbar';
import { PatientContext } from 'context';

export default function LifestyleHabits({ view, callback }) {
  const {totalAnswers, answeredCategory} = useLifestyleLogic()
  const { patient } = useContext(PatientContext)

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: '20px' }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        exit={{ opacity: 0 }}
      >
        {view && (
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <Button className="w-fit rounded-full h-8 font-campton_m text-14" theme="transparent" onClick={callback}>
                <p className="flex items-center space-x-4">
                  <ArrowBack fill="#748181" width="7" height="14" className="text-14" />
                  <span>Back</span>
                </p>
              </Button>
              <h2
                className={`text-16 sm:text-18 text-[#0D0F11] font-campton_m ${view && 'border-l border-neutral_stroke_2 pl-4'
                  }`}
              >
                {view}
              </h2>
            </div>
          </div>
        )}

          {(totalAnswers !== 0 ? (
            <div data-testid='lifestyle' className="flex justify-between cursor-pointer mt-6">
              <div className="flex space-x-4">
                <div className="">
                  <p className="font-campton_m text-neutral_black">
                    {`${patient.firstName} ${patient.lastName}`} has responded to a total of {totalAnswers} questions in {answeredCategory} categories
                  </p>
                  <p className="font-campton_r text-neutral_body text-14 w-[80%] mt-1">
                    Get access to all round health tips when you help us get to know more about your lifestyle habits
                  </p>
                  <ProgressBar progress={totalAnswers} total={32}/>
                </div>
              </div>
            </div>
          ) : (
            <EmptyState title="No Lifestyle Habits" icon={<EmptyStateIcon className="mb-[24px]" />} /> 
          ))}
      </motion.div>
    </AnimatePresence>
  );
}
LifestyleHabits.propTypes = {
  callback: PropTypes.func,
  view: PropTypes.string,
};
