import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import PropTypes from 'prop-types';
import React, { useRef, useState, useContext } from 'react';
import useUpload from 'hooks/useUpload';
import dayjs from 'dayjs';
import { ReactComponent as Camera } from 'assets/icons/camera-icon.svg';
import { DefaultDatepicker } from 'components/Datepicker/Index';
import Button from 'components/Buttons/Button';
import { PatientContext } from 'context';
import useVisit from 'hooks/fetch/useVisit';
import { checkEmptyProperties } from 'utils/checkEmptyProperties';

const AddInsuranceInfo = ({ callback }) => {
  //Redux
  const { patient, setPatient } = useContext(PatientContext)

  //Hooks
  const { add_medical_record, loading } = useVisit()
  const { upload, uploading, imageUrl } = useUpload();
 
  const [formData, setFormData] = useState({
    groupNumber: '',
    providerName: '',
    startDate: '',
    endDate: '',
    policyNumber: '',
    plan: '',
  });
  
  const uploadFile = (file) => {
    let timestamp = dayjs()?.format('YYYYMMDD-HHmmss');
    upload(file, { insuranceId: `${timestamp}${file?.name}` }, timestamp);
  };

  //Ref
  const insuranceFileRef = useRef();

  //Handler function
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const addMedicalRecord = () => {
    const payload = {
      ...formData,
      insuranceId: imageUrl,
      startDate: dayjs(formData.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(formData.endDate).format('YYYY-MM-DD'),
    };
    add_medical_record('insuranceInformation', patient?._id, payload)
      .then(res => {
        if ([201, 200]?.includes(res?.code)) {
          setPatient(res?.data)
          callback()
        }
    })
  }

  return (
    <div className="w-full lg:h-[400px] mt-6">
      <FormWrapper className="h-full" onSubmit={addMedicalRecord}>
        <div className="sm:mr-[24px] mb-[14px] sm:mb-0 hide_tap w-fit">
          <label htmlFor="insuranceId" className="cursor-pointer flex space-x-4 items-center">
            {imageUrl ? (
              <img
                src={imageUrl}
                width="80px"
                height="80px"
                alt="profile"
                className="rounded-full w-[80px] h-[80px] object-cover object-top"
              />
            ) : (
              <div className="relative">
                <div className="h-16 w-16 rounded-full bg-[#D9D9D9]"></div>
                <Camera className="absolute top-9 left-10" />
              </div>
            )}
            {uploading ? (
              <span className="text-brand_secondary  text-14">Uploading, Please wait......</span>
            ) : (
              <div className="">
                <p className="text-14 text-brand_secondary font-campton_m">Upload Insurance ID</p>
                <p className="text-12 text-neutral_body font-campton_r">Format - jpg & png</p>
              </div>
            )}
          </label>
          <input
            data-testid="insuranceId"
            type="file"
            id="insuranceId"
            name="insuranceId"
            accept="image/png, image/jpg, image/jpeg"
            className="hidden"
            ref={insuranceFileRef}
            readOnly={uploading || loading}
            onChange={(e) => uploadFile(e.target.files[0])}
          />
        </div>
        <div className="flex justify-between flex-col h-full">
          <div className="grid gap-x-6 gap-y-5 grid-cols-12 mt-6">
            <div className="md:col-span-6 col-span-12">
              <Input
                label="Insurance Provider Name"
                type="text"
                id="providerName"
                name="providerName"
                // readOnly={loading}
                value={formData?.providerName}
                onChange={handleChange}
                showError={false}
              />
            </div>
            <div className="lg:col-span-6 col-span-12">
              <Input
                label="Policy Number"
                type="text"
                id="policyNumber"
                name="policyNumber"
                //   readOnly={loading}
                value={formData?.policyNumber}
                onChange={handleChange}
              />
            </div>
            <div className="lg:col-span-6 col-span-12">
              <Input
                label="Group Number (if applicable)"
                type="text"
                id="groupNumber"
                name="groupNumber"
                //   readOnly={loading}
                value={formData?.groupNumber}
                onChange={handleChange}
              />
            </div>
            <div className="md:col-span-6 col-span-12">
              <Input
                label="Insurance Plan"
                type="text"
                id="plan"
                name="plan"
                // readOnly={loading}
                value={formData?.plan}
                onChange={handleChange}
                showError={false}
              />
            </div>
            <DefaultDatepicker
              label="Start Date"
              containerVariant="lg:col-span-6 col-span-12"
              startDate={formData.startDate}
              maxDate={new Date()}
              onSelect={(e) => setFormData({ ...formData, startDate: e })}
              name="date"
              value={formData?.startDate}
            />
            <DefaultDatepicker
              label="End Date"
              containerVariant="lg:col-span-6 col-span-12"
              startDate={formData.endDate}
              onSelect={(e) => setFormData({ ...formData, endDate: e })}
              name="date"
              value={formData?.endDate}
            />
            <div className="flex justify-end col-span-12 mt-7 ">
              <Button name="Save" theme="blue" className="w-28 h-10" disabled={checkEmptyProperties(formData) || !imageUrl} loading={loading} />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default AddInsuranceInfo;
AddInsuranceInfo.propTypes = {
  callback: PropTypes.any,
};
