import React, { useMemo } from "react";
import PropTypes from "prop-types"
import { Button } from "components/Buttons";
import { DefaultDatepicker } from "components/Datepicker/Index";
import FormWrapper from "components/FormWrapper";
import { Input, TextArea } from "components/Inputs";
import CheckBox from "components/Inputs/CheckBox";

export default function BasicInformation({
    formData, setFormData, handleChange, cancel, next, workingDays, updateWorkingDays
}) {
    const numberOfSelectedWorkingDays = workingDays.reduce((count, day) => count + (day.selected === true ? 1 : 0), 0)

    const disabled = useMemo(() => {
        return !formData.shiftName || !formData.startTime || !formData.endTime ||
            !formData.startDate || !formData.endDate || (numberOfSelectedWorkingDays < 1)
    }, [formData, workingDays, numberOfSelectedWorkingDays])

    return (
        <FormWrapper>
            <h2 className="text-16 text-neutral_black font-campton_m mb-[24px]">Basic Information</h2>
            <section className="md:grid grid-cols-3 gap-[24px] w-full">
                <div className="mb-[24px]">
                    <Input
                        label="Shift Name"
                        type="text"
                        id="shiftName"
                        name="shiftName"
                        //readOnly={loading}
                        value={formData?.shiftName}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-[24px]">
                    <DefaultDatepicker
                        label="Start Time"
                        showTimeSelectOnly={true}
                        dateFormat="h:mm aa"
                        showTimeSelect={true}
                        timeCaption="Select Start time"
                        onSelect={(date) => setFormData({ ...formData, startTime: date })}
                        name="startTime"
                        id="startTime"
                        value={formData?.startTime}
                        showTimeIcon={true}
                    />
                </div>
                <div className="mb-[24px]">
                    <DefaultDatepicker
                        label="End Time"
                        showTimeSelectOnly={true}
                        dateFormat="h:mm aa"
                        showTimeSelect={true}
                        timeCaption="Select End time"
                        name="endTime"
                        id="endTime"
                        onSelect={(date) => setFormData({ ...formData, endTime: date })}
                        value={formData?.endTime}
                        showTimeIcon={true}
                    />
                </div>
            </section>
            <section className="border-b border-dashed border-b-neutral_stroke_2 mb-[24px]">
                <h2 className="text-14 text-neutral_black font-campton_m mb-[12px]">How Long Should this Shift Run For?</h2>
                <div className="grid grid-cols-2 gap-[24px]">
                    <div>
                        <DefaultDatepicker
                            label="Start date"
                            minDate={new Date()}
                            onSelect={(e) => setFormData({ ...formData, startDate: e })}
                            name="startDate"
                            value={formData?.startDate}
                            showCalender={true}
                        />
                    </div>
                    <div>
                        <DefaultDatepicker
                            label="End date"
                            minDate={formData?.startDate}
                            onSelect={(e) => setFormData({ ...formData, endDate: e })}
                            name="endDate"
                            value={formData?.endDate}
                            showCalender={true}
                        />
                    </div>
                </div>
                <h2 className="text-14 text-neutral_black font-campton_m mb-[12px]">Select Working Days</h2>
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-[24px] mb-[24px]">
                    {workingDays.map((day) =>
                        <CheckBox
                            key={day.name}
                            variant="ml-0"
                            label={day.name}
                            value={day.name}
                            id={day.name}
                            checked={day.selected}
                            onChange={(e) => updateWorkingDays(e, day.selected)}
                        />
                    )}
                </div>
            </section>
            <section className="mb-[24px]">
                <h2 className="text-14 text-neutral_black font-campton_m mb-[12px]">Notes</h2>
                <div>
                    <TextArea
                        label="Add note (optional)"
                        id="note"
                        name="note"
                        //readOnly={loading}
                        value={formData.note}
                        onChange={handleChange}
                        rows={3}
                    />
                </div>
            </section>
            <section className="flex justify-end">
                <Button
                    name="Cancel"
                    theme="transparent"
                    type="button"
                    onClick={cancel}
                    className="h-[40px] text-16 w-[79px] mr-[16px]"
                />
                <Button
                    name="Next"
                    theme="blue"
                    type="button"
                    onClick={next}
                    disabled={disabled}
                    className="h-[40px] text-16 w-[88px]"
                />
            </section>
        </FormWrapper>
    )
}

BasicInformation.propTypes = {
    formData: PropTypes.object,
    handleChange: PropTypes.func,
    setFormData: PropTypes.func,
    cancel: PropTypes.func,
    next: PropTypes.func,
    workingDays: PropTypes.array,
    updateWorkingDays: PropTypes.func
}