import React from "react";
import PropTypes from "prop-types"
import dayjs from 'dayjs';
import { ReactComponent as Timer } from 'assets/icons/timer.svg';

export default function Layout({ createdAt, children, border = true }) {
    return (
        <div>
            <div className="flex items-center font-campton_r">
                <Timer />
                <p className="text-12 text-neutral_body ml-[16px] uppercase">{dayjs(createdAt).format('MMM DD, YYYY, hh:mm A')}</p>
            </div>
            <div className="border-dashed border-l border-l-neutral_stroke_2 ml-[15px] pb-[20px]">
                <div className={`${border ? 'border border-neutral_stroke_2' : ''} rounded-[8px] p-[16px] ml-[32px]`}>
                    {children}
                </div>
            </div>                                                              
        </div>
    )
}

Layout.propTypes = {
    createdAt: PropTypes.string,
    children: PropTypes.element,
    border: PropTypes.bool
}