import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';

export default function FormWrapper({
  onSubmit,
  variant,
  children,
  className,
  autoComplete,
  caption,
  title,
  onClick,
  showBackbutton = false,
  description,
}) {
  const submitForm = (e) => {
    e.preventDefault();
    if (onSubmit) onSubmit();
  };

  return (
    <form autoComplete={autoComplete} data-testid="form" className={className} onSubmit={submitForm}>
      {title || description ? (
        <div className={` ${variant || 'text-center'}`}>
          {title && <h5 className="text-18 font-campton_m text-primary_300 md:mb-8 mb-6">{title}</h5>}
          <div>
            {(showBackbutton || description) && (
              <div
                className={` ${showBackbutton ? 'md:flex items-center justify-between md:w-[87%]' : 'w-full '} md:mb-2`}
              >
                {showBackbutton && (
                  <div
                    onClick={onClick}
                    className="h-8 w-8 hidden md:flex justify-center items-center bg-neutral_disabled rounded-full cursor-pointer"
                    data-testid="backBtn"
                  >
                    <ArrowBack className="text-16 w-4 h-4" fill="#153E4C" />
                  </div>
                )}
                {description && (
                  <p className="md:text-24 text-20 font-campton_sb leading-7  text-[#0F2E38]">{description}</p>
                )}
              </div>
            )}
          </div>
          {caption && <p className="text-14 md:text-16 text-neutral_body font-campton_r mt-2">{caption}</p>}
        </div>
      ) : (
        ''
      )}
      {children}
    </form>
  );
}

FormWrapper.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  title: PropTypes.any,
  showBackbutton: PropTypes.bool,
  step: PropTypes.number,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  caption: PropTypes.any,
  description: PropTypes.any,
};
