import { createSlice } from '@reduxjs/toolkit';
import { getProviderAppointments, getInstitutionAppointments, getAppointmentRequest } from './actions';
import { useAppSelector } from 'hooks/useReduxHook';

const initialState = {
  appointments: {},
  appointmentRequest: {},
  liveAppointment: {}
};

export const appointment = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    setLiveAppointment: (state, action) => {
      state.liveAppointment = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProviderAppointments.fulfilled, (state, action) => {
        state.appointments = action?.payload?.data;
      })
      .addCase(getInstitutionAppointments.fulfilled, (state, action) => {
        state.appointments = action?.payload?.data;
      })
      .addCase(getAppointmentRequest.fulfilled, (state, action) => {
        state.appointmentRequest = action?.payload?.data;
      });
  },
});
// Selectors
const selectAppointment = (state) => state.appointment;
export const { setLiveAppointment } = appointment.actions;
//App Redux State
export const useAppointmentState = () => useAppSelector(selectAppointment);

export default appointment.reducer;
