import React, { Suspense, lazy } from 'react';
import { Outlet, createBrowserRouter } from 'react-router-dom';
import AppLayout from 'layouts/AppLayout';
import Login from 'pages/Auth/Login';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import ResetPassword from 'pages/Auth/ResetPassword';
import CreateAccount from 'pages/Auth/CreateAccount';
import RequireAuth from 'HOC/RequireAuth';
import Spinner from 'components/Spinner';
import TermsAndConditions from 'pages/Auth/TermsAndCondition';
import PageNotFound from 'pages/404';
import Patients from "pages/Clinicals/Patients/AllPatients"
import ViewServiceCategory from "pages/ServiceMgt./Services/ViewServiceCategory"
import InPatients from "pages/Clinicals/Patients/InPatients"
import DischargedPatients from "pages/Clinicals/Patients/DischargedPatients"
import AdmitPatient from "pages/Clinicals/Patients/InPatients/AdmitPatient"
import PatientDetails from "pages/Clinicals/Patients/AllPatients/PatientDetails"
import SingleInPatient from "pages/Clinicals/Patients/InPatients/SingleInPatient"
import ProgressNotes from "pages/Clinicals/Patients/InPatients/SingleInPatient/ProgressNote"
import InPatientsHighRisk from "pages/Clinicals/Patients/InPatients/HighRisk"
import VitalSigns from "pages/Clinicals/VitalSigns/index"
import NewVitals from "pages/Clinicals/VitalSigns/NewVitals"
import VitalSignsDetails from "pages/Clinicals/VitalSigns/VitalSignsDetails"
import LabTest from "pages/Clinicals/LabTest"
import LabTestDetails from "pages/Clinicals/LabTest/LabTestDetails"
import Onboarding from "pages/Onboarding/Index.js"
import Visit from "pages/Clinicals/Vists/Visit"
import ViewSingleVist from "pages/Clinicals/Vists/ViewSingleVist";
import Locations from "pages/Settings/Locations"
import Specialty from "pages/Settings/Specialty"
import ViewSpecialty from "pages/Settings/Specialty/ViewSingleSpecialty"
import BillAndPayments from "pages/ServiceMgt./BillAndPayments"
import BillDetails from "pages/ServiceMgt./BillAndPayments/BillDetails"
import Wallet from "pages/ServiceMgt./BillAndPayments/Wallet"
import Policies from "pages/Settings/Policies"
import CreatePolicy from "pages/Settings/Policies/CreatePolicy"
import ViewPolicy from "pages/Settings/Policies/ViewPolicy"
import RolesAndPermission from "pages/Settings/RolesAndPermission"
import StaffManagement from "pages/Settings/StaffManagement"
import Services from "pages/ServiceMgt./Services"
import Profile from "pages/Settings/Profile"
import Appointments from "pages/Appointments"
import NewAppointment from "pages/Appointments/NewAppointment"
import Scheduling from "pages/EShift/Scheduling";
import SingleShift from "pages/EShift/Scheduling/Shift/SingleShift";
import NewShift from "pages/EShift/Scheduling/Shift/NewShift";
import StaffShift from "pages/EShift/Scheduling/Shift/SingleShift/StaffCalendar";
import CalendarForStaff from 'pages/EShift/Scheduling/Shift/CalendarForStaff'
import CreateBill from 'pages/ServiceMgt./BillAndPayments/CreateBill/CreateBill'
import NewVisit from 'pages/Clinicals/Vists/NewVisit'
import ProgressNoteDetails from 'pages/Clinicals/Patients/InPatients/SingleInPatient/ProgressNote/ProgressNoteDetails'
const LiveSession = lazy(() => import('pages/Appointments/LiveSession'))

const router = createBrowserRouter([
    {
        path: "",
        element: <Login />,
    },
    {
        path: "create-account",
        element: <CreateAccount />
    },
    {
        path: "verify-account",
        element: <CreateAccount />
    },
    {
        path: "forgot-password",
        element: <ForgotPassword />
    },
    {
        path: "create-password",
        element: <ResetPassword />
    },
    {
        path: "terms-and-condition",
        element: <TermsAndConditions />,
    },
    {
        path: 'onboarding',
        element: (
            <RequireAuth>
                <Onboarding />
            </RequireAuth>
        ),
    },
    {
        path: "/",
        errorElement: <div>Something went wrong</div>,
        element: (
            <AppLayout>
                <Outlet />
            </AppLayout>
        ),
        children: [
            {
                path: 'dashboard',
                element: <div>dashboard</div>
            },
            {
                path: 'appointments',
                element: <Appointments />
            },
            {
                path: 'appointments/new-appointment',
                element: <NewAppointment />
            },
            {
                path: 'clinicals/visits',
                element: <Visit />
            },
            {
                path: 'clinicals/visits/:id',
                element: <ViewSingleVist />
            },
            {
                path: 'clinicals/visits/new',
                element: <NewVisit />
            },
            {
                path: 'clinicals/vital-signs',
                element: <VitalSigns />
            },
            {
                path: 'clinicals/vital-signs/new',
                element: <NewVitals />
            },
            {
                path: 'clinicals/vital-signs/:id',
                element: <VitalSignsDetails />
            },
            {
                path: 'clinicals/patients',
                element: <Patients />
            },
            {
                path: 'clinicals/in-patients',
                element: <InPatients />
            },
            {
                path: 'clinicals/in-patients/admit-patient',
                element: <AdmitPatient />
            },
            {
                path: 'clinicals/in-patients/:id',
                element: <SingleInPatient />
            },
            {
                path: 'clinicals/in-patients/:id/progress-notes/:id/:purpose',
                element: <ProgressNoteDetails />
            },
            {
                path: 'clinicals/in-patients/:id/progress-notes',
                element: <ProgressNotes />
            },
            {
                path: 'clinicals/patients/:id',
                element: <PatientDetails />
            },
            {
                path: 'clinicals/in-patients/high-risk',
                element: <InPatientsHighRisk />
            },
            {
                path: 'clinicals/discharged-patients',
                element: <DischargedPatients />
            },
            {
                path: 'clinicals/lab-test',
                element: <LabTest />
            },
            {
                path: 'clinicals/lab-test/:id',
                element: <LabTestDetails />
            },
            {
                path: 'e-shift/scheduling',
                element: <Scheduling />
            },
            {
                path: 'e-shift/scheduling/calendar/:shiftId/:providerId',
                element: <CalendarForStaff />
            },
            {
                path: 'e-shift/scheduling/new-shift',
                element: <NewShift />
            },
            {
                path: 'e-shift/scheduling/:id',
                element: <SingleShift />
            },
            {
                path: 'e-shift/scheduling/:id/:id',
                element: <StaffShift />
            },
            {
                path: 'settings/profile',
                element: <Profile />
            },
            {
                path: 'settings/locations',
                element: <Locations />
            },
            {
                path: 'settings/specialty',
                element: <Specialty />
            },
            {
                path: 'settings/specialty/:id',
                element: <ViewSpecialty />
            },
            {
                path: 'settings/policies',
                element: <Policies />
            },
            {
                path: 'settings/policies/:id/:type',
                element: <ViewPolicy />
            },
            {
                path: 'settings/policies/create-policy',
                element: <CreatePolicy />
            },
            {
                path: 'settings/roles-and-permissions',
                element: <RolesAndPermission />
            },
            {
                path: 'settings/staff-management',
                element: <StaffManagement />
            },
            {
                path: 'service/category',
                element: <Services />
            },
            {
                path: 'service/category/:id',
                element: <ViewServiceCategory />
            },
            {
                path: 'service/bill-and-payment',
                element: <BillAndPayments />
            },
            {
                path: 'service/bill-and-payment/create-bill',
                element: <CreateBill />,
            },
            {
                path: 'service/bill-and-payment/:id',
                element: <BillDetails />
            },
            {
                path: 'service/bill-and-payment/wallet/',
                element: <Wallet />
            }
        ]
    },
    {
        path: 'appointments/live/:id',
        element: (
            <Suspense fallback={<Spinner />}>
                <LiveSession />
            </Suspense>
        )
    },
    {
        path: "*",
        element: <PageNotFound />
    }
]);

export { router };
