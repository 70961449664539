import React, { useState } from "react"
import PropTypes from "prop-types"
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg"
import { ReactComponent as ArrowUp } from "assets/icons/arrow-up.svg"
import { ReactComponent as CheckboxUnselected } from "assets/icons/check-box-unselected.svg"
import { ReactComponent as CheckboxSelected } from "assets/icons/check-box-selected.svg"
import { ReactComponent as CheckboxTag } from "assets/icons/check-box-tag.svg"

export default function PermissionList({ item, selectPermission, unselectAllPermissions, selectAllPermissions }) {
    const [showList, setShowList] = useState(false)

    return (
        <div className="text-14 font-campton_r text-neutral_black">
            <div className="border-b border-b-neutral_stroke_2">
                <div className="flex py-[14px]">
                    {(item?.action?.length !== item?.availableActions?.length) &&
                        <button
                            className={`mr-[8px] ${unselectAllPermissions ? 'cursor-pointer' : ''}`}
                            onClick={() => selectAllPermissions ? selectAllPermissions(item?.module) : {}}
                        >
                            {item?.action?.length ? <CheckboxTag className="w-[17.59px]" /> : <CheckboxUnselected className="w-[17.59px]" />}
                        </button>
                    }
                    {item?.action?.length === item?.availableActions?.length ?
                        <CheckboxSelected
                            className={`mr-[8px] ${unselectAllPermissions ? 'cursor-pointer' : ''} hide_tap shrink-0 w-[17.59px]`}
                            onClick={() => unselectAllPermissions ? unselectAllPermissions(item?.module) : {}}
                        /> : ''
                    }
                    <div
                        data-testid={item.module.toLowerCase()}
                        className="flex items-center justify-between cursor-pointer hide_tap w-full"
                        onClick={() => setShowList(!showList)}
                    >
                        <div className="flex items-center">

                            <p>{item?.module}</p>
                        </div>
                        {showList ? <ArrowUp fill="#505959" /> : <ArrowDown fill="#505959" />}
                    </div>
                </div>
                <AnimatePresence>
                    {showList &&
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto', transition: { type: 'spring', duration: .3 } }}
                            exit={{ opacity: 0, height: 0, transition: { duration: .2 } }}
                        >
                            <div className="pt-0 pb-[16px] grid grid-cols-2 gap-[8px]">
                                {item?.availableActions?.map((permission) =>
                                    <div
                                        key={permission}
                                        data-testid={permission.toLowerCase()}
                                        onClick={() => selectPermission ? selectPermission(item.module, permission) : {}}
                                        className="flex items-center pl-[26px] w-full"
                                    >
                                        {item?.action?.includes(permission) ?
                                            <CheckboxSelected className={`mr-[8px] ${selectPermission ? 'cursor-pointer' : ''} hide_tap shrink-0 w-[17.59px]`} /> :
                                            <CheckboxUnselected className={`mr-[8px] ${selectPermission ? 'cursor-pointer' : ''} hide_tap shrink-0 w-[17.59px]`} />
                                        }
                                        <p className="text-12 text-neutral_black font-campton_r whitespace-nowrap">{permission}</p>
                                    </div>
                                )}
                            </div>
                        </motion.div>
                    }
                </AnimatePresence>
            </div>
        </div>
    )
}

PermissionList.propTypes = {
    item: PropTypes.object,
    selectPermission: PropTypes.func,
    selectAllPermissions: PropTypes.func,
    unselectAllPermissions: PropTypes.func
}