import React, { useEffect, useState } from "react"
import Navbar from "components/Navbar"
import { SlideUp } from "components/Animations"
import BreadCrumbs from "components/BreadCrumbs"
import { useNavigate } from "react-router-dom"
import StepProgress from "components/StepProgress"
import useMultiStepForm from "hooks/useMultiStepForm"
import PatientInformation from "./components/PatientInformation"
import useDebounce from "hooks/useDebounce"
import useProviders from "hooks/fetch/useProviders"
import { notify } from "store/modules/global"
import { useDispatch, useSelector } from "react-redux"
import useHandleError from "hooks/useHandleError"
import SelectService from "./components/SelectService/SelectService"
import PreviewInformation from "./components/PreviewInformation/PreviewInformation"
import useBillServices from "./hooks/useBillServices"
import useService from "hooks/fetch/useService"

export default function CreateBill() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { create_bill, loading: isCreatingBill } = useService()
    const { services, categories, handleSelectedService, getServicesByCategory, isFetchingCategory } = useBillServices()
    const { handleError } = useHandleError()
    const [gNumber, setGNumber] = useState('')
    const { institutionId } = useSelector(state => state.auth)
    const { get_patient_by_gNumber, loading: isFetchingPatient } = useProviders()
    const [patient, setPatient] = useState({
        firstName: '', lastName: '',
        emailAddress: '', gender: '',
        phoneNumber: '', dateOfBirth: ''
    })

    const { debouncedValue } = useDebounce(gNumber)

    const handleGNumber = (e) => {
        setGNumber(e.target.value)
    }

    const options = [
        {
            title: 'Bill and Payments',
            method: () => navigate(-1)
        },
        {
            title: 'Create bill'
        }
    ]

    const progress = [
        {
            step: 1,
            title: 'Patient Information',
            caption: 'Patient name, email address and phone number'
        },
        {
            step: 2,
            title: 'Select Services',
            caption: 'Enter bill details'
        },
        {
            step: 3,
            title: 'Preview Information',
            caption: 'Preview & ensure information provided is correct'
        }
    ]

    const totalBillingAmount = services?.reduce((total, obj) => {
        return total + obj.selected.reduce((accumulator, current) => accumulator + current.totalFee, 0)
    }, 0)

    const createBillProxy = () => {
        const billDetails = services.flatMap(item => item.selected.map(service => ({
            category: item.module,
            subCategory: service.name,
            fee: service.fee,
            vat: service.vat
        })));
        const payload = {
            patientId: patient._id,
            institutionId,
            totalAmount: totalBillingAmount,
            billDetails
        }
        create_bill({
            payload, callback: () => navigate(-1)
        })
    }

    const { step, next, back } = useMultiStepForm([
        <PatientInformation
            key={1}
            next={() => next()}
            patient={patient}
            gNumber={gNumber}
            loading={isFetchingPatient}
            handleChange={handleGNumber}
        />,
        <SelectService
            key={1}
            next={() => next()}
            back={() => back()}
            services={services}
            categories={categories}
            getServicesByCategory={getServicesByCategory}
            handleSelectedService={handleSelectedService}
            isFetchingCategory={isFetchingCategory}
        />,
        <PreviewInformation
            key={3}
            back={() => back()}
            patient={patient}
            services={services}
            totalBillingAmount={totalBillingAmount}
            loading={isCreatingBill}
            cancel={() => navigate(-1)}
            createBill={createBillProxy}
        />
    ])

    useEffect(() => {
        if (debouncedValue.length === 10) {
            get_patient_by_gNumber(gNumber)
                .then(res => {
                    if (res?.code === 200) return setPatient(res?.data)
                    dispatch(notify({ display: true, status: 'error', message: handleError({ status: res?.code, message: res?.message }) }))
                    setPatient()
                })
        }
    }, [debouncedValue])

    useEffect(() => {
        document.title = 'Create Bill - Gabbi Health'
    }, [])

    return (
        <div>
            <Navbar title="Bill & Payments" />
            <BreadCrumbs options={options} />
            <SlideUp>
                <div className="md:flex md:p-[24px] h-[80vh]">
                    <StepProgress progress={progress} step={step.key} />
                    <div className="p-[24px] md:border border-neutral_stroke_2 rounded-[16px] w-full md:ml-[316px] h-full">
                        {step}
                    </div>
                </div>
            </SlideUp>
        </div>
    )
}