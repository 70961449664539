import Avatar from "components/Avatar/Avatar";
import React from "react";
import PropTypes from "prop-types"
import { ReactComponent as Calendar } from "assets/icons/calendar.svg";
import { ReactComponent as Hospital } from "assets/icons/hospital.svg";
import { formatDate } from "utils/formatDate";
import { convertTimeTo12HourFormat } from "utils/formatTime";
import { userInitals } from "utils/ImagePlaceholder";

export default function AppointmentDetails({ appointment, cancel, patient }) {
    const businessAddress = appointment?.institutionInfo?.businessAddress

    return (
        <div>
            <div className="overflow-y-auto">
                {/* <Status status="ID: 10002992" className="bg-[#143566] text-neutral_white mb-[24px]" /> */}
                <div className="rounded-[16px] border border-neutral_stroke_2 p-[16px] mb-[24px]">
                    <div className="mb-[25px]">
                        <p className="mb-[4px] text-14 font-campton_r text-neutral_body">Appointment Location</p>
                        <div className="flex">
                            <div className="bg-primary_tint_100 w-[40px] h-[40px] rounded-full flex justify-center items-center">
                                <Hospital fill="#153E4C" />
                            </div>
                            <div className="ml-[8px]">
                                <p className="text-16 text-neutral_black font-campton_m capitalize">{appointment?.institutionInfo?.businessName}</p>
                                <p className="text-14 text-neutral_body font-campton_r">
                                    {businessAddress?.address}, {businessAddress?.city}, {businessAddress?.state} {businessAddress?.country}.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-[25px]">
                        <p className="mb-[4px] text-14 font-campton_r text-neutral_body">Date & Time</p>
                        <div className="flex">
                            <div className="bg-primary_tint_100 w-[40px] h-[40px] rounded-full flex justify-center items-center">
                                <Calendar />
                            </div>
                            <div className="ml-[8px]">
                                <p className="text-16 text-neutral_black font-campton_m">{formatDate(appointment?.appointment?.appointmentDate)}</p>
                                <p className="text-14 text-neutral_body font-campton_r">
                                    {convertTimeTo12HourFormat(appointment?.appointment?.startTime)} - {convertTimeTo12HourFormat(appointment?.appointment?.endTime)}
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr className="border-dashed border-neutral_stroke_1 mb-[24px]" />
                    <div className="mb-[24px]">
                        <p className="mb-[4px] text-14 font-campton_r text-neutral_body">Specialist information</p>
                        <div className="flex">
                            <Avatar
                                url={appointment?.providerInfo?.profilePic}
                                width="40px"
                                height="40px"
                                //alt={profile?.first_name?.split('')[0]}
                                initials={userInitals(appointment?.providerInfo?.firstName, appointment?.providerInfo?.lastName)}
                                className="w-[30px] sm:w-[40px] h-[30px] sm:h-[40px] mr-[12px] shrink-0"
                            />
                            <div>
                                <p className="text-16 text-neutral_black font-campton_m capitalize">
                                    {appointment?.providerInfo?.title} {appointment?.providerInfo?.firstName} {appointment?.providerInfo?.lastName}
                                </p>
                                <p className="text-14 text-neutral_body font-campton_r">{appointment?.providerInfo?.specialty || 'N/A'}, </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="mb-[4px] text-14 font-campton_r text-neutral_body">Patient information</p>
                        <div className="flex">
                            <Avatar
                                url={patient?.profilePic}
                                width="40px"
                                height="40px"
                                //alt={profile?.first_name?.split('')[0]}
                                initials={userInitals(patient?.firstName, patient?.lastName)}
                                className="w-[30px] sm:w-[40px] h-[30px] sm:h-[40px] mr-[12px] shrink-0"
                            />
                            <div>
                                <p className="text-16 text-neutral_black font-campton_m capitalize">{patient?.firstName} {patient?.lastName}</p>
                                <p className="text-14 text-neutral_body font-campton_r">{patient?.emailAddress}</p>
                                <p className="text-14 text-neutral_body font-campton_r">{patient?.countryCode}{patient?.phoneNumber}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-[16px] border border-neutral_stroke_2 p-[16px] bg-primary_tint_50">
                    <p className="mb-[8px] text-14 font-campton_r text-neutral_body">Notes</p>
                    <p className="text-16 font-campton_r text-neutral_black capitalize-first">{appointment?.appointment?.note}</p>
                </div>
            </div>
            {/* <div className="flex justify-end py-[20px]">
                <Button
                    name="Cancel"
                    theme="transparent"
                    onClick={() => cancel()}
                    className="h-[40px] text-16 px-[12px] mr-[16px]"
                />
                <Button
                    name="Reschedule"
                    theme="blue"
                    className="h-[40px] text-16 px-[12px]"
                />
            </div> */}
        </div>
    )
}

AppointmentDetails.propTypes = {
    appointment: PropTypes.object,
    cancel: PropTypes.func,
    patient: PropTypes.object
}