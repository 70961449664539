import { SlideUp } from 'components/Animations';
import { Button } from 'components/Buttons';
import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import { referral_list_header } from 'mocks/patient';
import Avatar from 'components/Avatar/Avatar';
import { userInitals } from 'utils/ImagePlaceholder';
import dayjs from 'dayjs';
import Modal from 'components/Modals/Modal';
import AddReferrals from './AddReferrals';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { useParams } from 'react-router-dom';
import usePatient from 'hooks/fetch/usePatient';
import useDebounce from 'hooks/useDebounce';
import useTable from 'hooks/useTable';
import { useAuthState } from 'store/modules/auth';
import { useClinicalState } from 'store/modules/clinicals';
import { Search } from 'components/Inputs';
import ViewReferral from './ViewReferral';
import { PatientContext } from 'context';

const Referrals = ({ callback, singleVisit, visitStatus, canUpdate = true }) => {
  const [selectedItem, setSelectedItem] = useState();
  const { institutionId } = useAuthState();
  const { referrals } = useClinicalState();
  const [type, setType] = useState('');
  const { get_referrals, loading } = usePatient();
  const { id } = useParams();
  const { handleSearch, params } = useTable();
  const { debouncedValue } = useDebounce(params.search);
  const handleSingleView = (item) => {
    setSelectedItem(item);
    setType('view');
  };
  const pathname = window.location.pathname;
  const page = pathname.includes('clinicals/patient') ? 'patient' : 'visit'
  const { patient, progressNote } = useContext(PatientContext)

  useEffect(() => {
    const params = {
      type: page,
      [page === 'patient' ? 'patientId' : 'visitId']: patient?.visit ?? id,
      institutionId
    };
    if(!progressNote) {
      if (debouncedValue) {
        get_referrals({ ...params, search: debouncedValue })
      } else {
        get_referrals(params);
      }
    }
  }, [debouncedValue]);

  return (
    <>
      <SlideUp>
        <div className="p-0">
          <div className="flex justify-between items-center mb-6">
            <div className="flex items-center space-x-4">
              <Button className="w-fit rounded-full h-8 font-campton_m text-14" theme="transparent" onClick={callback}>
                <p className="flex items-center space-x-4">
                  <ArrowBack fill="#748181" width="7" height="14" className="text-14" />
                  <span>Back</span>
                </p>
              </Button>
              <h2 className="text-16 sm:text-18 text-[#0D0F11] font-campton_m">Referrals</h2>
            </div>
            {!visitStatus && canUpdate && (pathname.includes('visit') || pathname.includes('progress-note')) && <Button name="Add Referral" theme="blue" onClick={() => setType('add')} className="h-8" />}
          </div>
          {referrals?.referrals?.length > 0 && (
            <div className="w-[400px] mb-6">
              <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
            </div>
          )}
          <Table
            headers={referral_list_header}
            data={referrals?.referrals}
            hasHeader={referrals?.referrals?.length > 0}
            totalCount={referrals?.total}
            hasPagination={false}
            hasFilter={false}
            loading={loading}
            perPage={1000}
            buttonName="Add Referral"
            currentPage={referrals?.page}
          >
            <>
              {referrals?.referrals?.map((item) => (
                <tr
                  data-testid={item?._id}
                  onClick={() => handleSingleView(item)}
                  key={item?._id}
                  className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                >
                  <td className="px-[10px] py-[8px] flex items-center">
                    <Avatar
                      url={item?.institutionInfo?.profilePic}
                      width="40px"
                      height="40px"
                      alt={item?.institutionInfo?.businessName?.split('')[0]}
                      initials={userInitals(
                        item?.institutionInfo?.businessName?.split(' ')[0],
                        item?.institutionInfo?.businessName?.split(' ')[1],
                      )}
                      className="bg-brand_secondary mr-[15px] w-[40px] h-[40px]"
                    />
                    <div>
                      <p className="text-neutral_black text-14 font-campton_m capitalize">
                        {item?.institutionInfo?.businessName}
                      </p>
                      <p className=" text-neutral_body text-12 font-campton_r">{item?.institutionInfo?.emailAddress}</p>
                    </div>
                  </td>
                  <td className="px-[10px] py-[8px] capitalize text-14">
                    {dayjs(item.createdAt).format('MMM DD, YYYY')}
                  </td>
                  <td className="px-[10px] py-[8px] flex items-center">
                    <Avatar
                      url={item?.providerInfo?.profilePic}
                      width="40px"
                      height="40px"
                      alt={item?.providerInfo?.firstName?.split('')[0]}
                      initials={userInitals(item?.providerInfo?.firstName, item?.providerInfo?.lastName)}
                      className="bg-brand_secondary mr-[15px] w-[40px] h-[40px]"
                    />
                    <div>
                      <p className="text-neutral_black text-14 font-campton_m capitalize">
                        {item?.providerInfo?.firstName} {item?.providerInfo?.lastName}
                      </p>
                      <p className=" text-neutral_body text-12 font-campton_r">{item?.providerInfo?.emailAddress}</p>
                    </div>
                  </td>
                  <td className="px-[10px] py-[8px] capitalize">
                    <ArrowRight fill="#A9B2B2" />
                  </td>
                </tr>
              ))}
            </>
          </Table>
        </div>
      </SlideUp>
      {type && (
        <Modal
          closeModal={() => setType('')}
          styles="w-full md:w-[457px]"
          position="modal-right"
          variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
          title={`${type} Referral`}
        >
          {type === 'add' && <AddReferrals callback={() => setType('')} singleVisit={singleVisit} />}
          {type === 'view' && <ViewReferral data={selectedItem} />}
        </Modal>
      )}
    </>
  );
};

export default Referrals;
Referrals.propTypes = {
  callback: PropTypes.any,
  singleVisit: PropTypes.object,
  visitStatus: PropTypes.bool,
  canUpdate: PropTypes.bool
};
