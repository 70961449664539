import { useEffect, useState } from "react"

export default function useDeviceWidth() {
    const [deviceWidth, setDeviceWidth] = useState(window?.innerWidth)
    const isMobile = deviceWidth < 601

    const handleResize = () => {
        setDeviceWidth(window?.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return { deviceWidth, isMobile }
}