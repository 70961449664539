import CreateAccountForm from 'components/AuthForms/CreateAccountForm';
import AuthLayout from 'layouts/AuthLayout';
import React from 'react';
import InstitutionIcon from 'assets/icons/Institution';
import IndividualIcon from 'assets/icons/IndividualUser';
import { useSearchParams } from 'react-router-dom';
import VerifyAccountForm from 'components/AuthForms/VerifyAccountForm';
import useResendOtp from 'hooks/useResendOtp';

const CreateAccount = () => {
  //Hooks
  const pathname = window.location.pathname;

  const { timeLeft  } = useResendOtp();
  //Variables
  const userTypes = [
    {
      icon: IndividualIcon,
      title: 'A Doctor/Healthcare Provider',
      description: 'Nurses, Doctors, Lab technicians, Dentist etc',
      type: 'provider',
    },
    {
      icon: InstitutionIcon,
      title: 'A Healthcare Institution',
      description: 'Hospitals, Clinics, Diagnostic centers, Laboratories,  etc',
      type: 'institution',
    },
  ];
  //Hooks
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  return (
    <AuthLayout
      showBackButton={Object.keys(params).length > 0 || pathname === '/verify-account'}
      right={
        pathname.includes('create-account') ? (
          <CreateAccountForm userTypes={userTypes} params={params} setParams={setSearchParams}/>
        ) : (
          <VerifyAccountForm userStatus="new" timeLeft={timeLeft}/>
        )
      }
    />
  );
};

export default CreateAccount;
