import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from "prop-types"
import Table from 'components/Table';
import { lab_request_header } from 'mocks/clinicals/visits';
import { ReactComponent as File } from "assets/icons/file.svg"
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg"
import Status from 'components/Status';
import Modal from 'components/Modals/Modal';
import { Button } from 'components/Buttons';
import { SlideUp } from 'components/Animations';
import NewRequest from './NewRequest';
import Summary from './Summary';
import { formatDate } from 'utils/formatDate';
import ViewTestResult from 'components/Forms/ViewTestResult';
import useLabTest from 'hooks/fetch/useLabTest';
import useTable from 'hooks/useTable';
import usePermission from 'hooks/usePermission';

export default function OrderTests({ purpose, patientId, visitId, progressNote, visitStatus, callback }) {
    const pathname = window.location.pathname
    const { get_visit_tests, get_lab_requests, loading: isFetchingVisitTests } = useLabTest()
    const [selectedResult, setSelectedResult] = useState()
    const [createRequest, setCreateRequest] = useState(false)
    const [summary, setSummary] = useState(false)
    const [selectedTest, setSelectedTest] = useState([])
    const [labTest, setLabTest] = useState([])
    const { params, changePage, selectLimit } = useTable()
    const [note, setNote] = useState('')
    const { hasPermission } = usePermission()

    const clearForm = () => {
        setNote('')
        setSelectedTest([])
    }

    const getLabRequestProxy = useCallback(() => {
        if (purpose === 'visit') {
            get_visit_tests({ id: progressNote?.visit ?? visitId, params })
                .then(res => setLabTest(res?.data))
        } else {
            get_lab_requests({ id: patientId, params })
                .then(res => setLabTest(res?.data))
        }
    }, [])
    const allLabTest = progressNote?.labrequests ?? labTest?.labTest

    const closeNewRequestForm = () => {
        if (!pathname.includes('progress-note')) {
            getLabRequestProxy()
        } else {
            callback()
        }
        clearForm()
        setSummary(false)
        setCreateRequest(false)
    }
    useEffect(() => {
        if (!pathname.includes('progress-note')) {
            getLabRequestProxy()
        }
    }, [params.page, params.limit]);

    return (
        <>
            <div className='flex items-center justify-between mb-[16px]'>
                <h2 className="text-16 sm:text-18 text-[#0D0F11] font-campton_m">Order Tests</h2>
                {!visitStatus && hasPermission('Visits', 'Update visits') &&
                    <Button
                        name="New Request"
                        theme="blue"
                        type="button"
                        className='py-[5.5px] px-[12px]'
                        onClick={() => setCreateRequest(true)}
                    />
                }
            </div>
            <SlideUp>
                <Table
                    headers={lab_request_header}
                    data={allLabTest}
                    hasFilter={false}
                    hasPagination={!pathname.includes('progress-note')}
                    totalCount={labTest?.count || labTest?.total}
                    perPage={labTest?.limit}
                    currentPage={labTest?.page}
                    selectLimit={selectLimit}
                    changePage={changePage}
                    loading={isFetchingVisitTests}
                >
                    <>
                        {allLabTest?.map((item) =>
                            <tr
                                data-testid={item?._id}
                                key={item?._id}
                                className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                                onClick={() => setSelectedResult(item)}
                            >
                                <td className="px-[10px] py-[8px] flex items-center">
                                    <File className="mr-[10px] shrink-0" />
                                    <p
                                        title={item?.testType}
                                        className='w-[200px] truncate max-w-[140px] capitalize-first'
                                    >
                                        {item?.testType}
                                    </p>
                                </td>
                                <td
                                    title={`${item?.requestedBy?.firstName} ${item?.requestedBy?.lastName}`}
                                    className="px-[10px] py-[8px] capitalize truncate max-w-[140px]"
                                >
                                    {item?.requestedBy?.firstName} {item?.requestedBy?.lastName}
                                </td>
                                <td className="px-[10px] py-[8px] capitalize">{formatDate(item?.dateRequested)}</td>
                                <td className="px-[10px] py-[8px]"><Status status={item?.requestStatus} /></td>
                                <td className="px-[10px] py-[8px]"><ArrowRight fill="#A9B2B2" /></td>
                            </tr>
                        )}
                    </>
                </Table>
                {selectedResult &&
                    <ViewTestResult
                        title="Lab result"
                        test={selectedResult}
                        closeModal={() => setSelectedResult()}
                    />
                }
                {createRequest ?
                    <Modal
                        closeModal={closeNewRequestForm}
                        styles="w-full md:w-[457px]"
                        position="modal-right"
                        title="New Order Request"
                        variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
                    >
                        <NewRequest
                            callback={closeNewRequestForm}
                            next={() => setSummary(true)}
                            selectedTest={selectedTest}
                            setSelectedTest={setSelectedTest}
                            note={note}
                            setNote={setNote}
                        />
                    </Modal> : ''
                }
                {summary ?
                    <Modal
                        closeModal={() => setSummary(false)}
                        styles="w-full md:w-[457px]"
                        position="modal-right"
                        title="Summary"
                        variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
                    >
                        <Summary
                            callback={closeNewRequestForm}
                            selectedTest={selectedTest}
                            note={note}
                            visitId={visitId}
                            patientId={patientId}
                            updateTest={getLabRequestProxy}
                            setNote={setNote}
                        />
                    </Modal> : ''
                }
            </SlideUp>
        </>
    )
}

OrderTests.propTypes = {
    test: PropTypes.array,
    visitId: PropTypes.string,
    patientId: PropTypes.string,
    purpose: PropTypes.string,
    visitStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    callback: PropTypes.func,
    progressNote: PropTypes.object,
}