import React from "react";
import PropTypes from "prop-types"
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";

export default function ProgressNoteHeader({ filters, selected, select }) {
    return (
        <div className="lg:flex justify-between border-t border-t-neutral_stroke_2 px-[24px] py-[16px]">
            <p className="text-18 text-[#0D0F11] font-campton_m">Progress note records</p>
            <div className="flex overflow-x-auto mt-[10px] lg:mt-0">
                {filters?.map(f =>
                    <button
                        data-testid={f?._id}
                        key={f?._id}
                        className={`text-14 font-campton_r px-[8px] py-[4px] rounded-[24px] cursor-pointer
                            border ${selected?._id === f?._id ? 'border-brand_primary' : 'border-neutral_stroke_2'} mr-[16px] whitespace-nowrap h-fit`}
                        onClick={() => select(f)}
                    >
                        {f?.createdAt}
                    </button>
                )}
                {filters?.length > 3 &&
                    <button
                        className="text-14 font-campton_r px-[8px] py-[4px] rounded-[24px] border border-neutral_stroke_2 mr-[16px whitespace-nowrap h-fit"
                    >more <ArrowDown fill="#505959" className="inline" />
                    </button>
                }
            </div>
        </div>
    )
}

ProgressNoteHeader.propTypes = {
    filters: PropTypes.array,
    selected: PropTypes.object,
    select: PropTypes.func
}