import React, { useMemo, useState } from 'react';
import Accordion from 'components/Accordion';
import FormWrapper from 'components/FormWrapper';
import Modal from 'components/Modals/Modal';
import { ReactComponent as Uncheck } from 'assets/icons/check-box-unselected.svg';
import { ReactComponent as Check } from 'assets/icons/check-box-selected.svg';
import PropTypes from 'prop-types';
import { Button } from 'components/Buttons';
import { useAuthState } from 'store/modules/auth';
import { defaultFilter } from 'mocks/patient';
import useSpecialty from 'hooks/fetch/useSpecialty';

const Filter = ({ closeModal, loading, defaultParams }) => {
  const [params, setParams] = useState({ location: '' })
  const { user, institutionId } = useAuthState()
  const { get_Specialty_Staff } = useSpecialty();

  const locationList = useMemo(() =>
    user?.healthCareFacilities?.map((item, index) => {
      return {
        id: index,
        value: `${item?.city}, ${item.state}, ${item.country}`, name: `${item?.city}, ${item.state}, ${item.country}`
      };
    }),
    [user?.healthCareFacilities],
  );

  const filterStaff = () => {
    closeModal()
    get_Specialty_Staff(`${institutionId}/providers`, {
      ...defaultParams,
      ...params
    })
  }

  return (
    <Modal
      closeModal={closeModal}
      styles="w-full md:w-[457px]"
      position="modal-right"
      title="Filter"
      variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
    >
      <FormWrapper className="relative">
        <div className='modal-right-body'>
          <Accordion
            display={false}
            title="By Location"
            className="border-b pb-4 border-neutral_stroke_2 mb-4"
            variant="mt-3"
            titleClassName='text-12'
          >
            <div className="bg-[#F7F8F8] p-4 rounded-lg">
              {[...defaultFilter, ...locationList]?.map((item) => (
                <div
                  key={item.name}
                  className={`${item.name === 'all' ? 'border-b border-dashed border-neutral_stroke_2 py-2' : 'py-3'
                    } flex`}
                  onClick={() => setParams({ ...params, location: item.value })}
                >
                  <div data-testid={item.name} className="cursor-pointer hide_tap">
                    {params.location === item.value ? <Check /> : <Uncheck />}
                  </div>
                  <p className="text-14 text-neutral_black font-campton_r ml-[8px] capitalize">{item.name}</p>
                </div>
              ))}
            </div>
          </Accordion>
        </div>
        <div className="modal-right-button-container">
          <Button
            name="Cancel"
            theme="transparent"
            type="button"
            onClick={closeModal}
            className="h-[40px] text-16 w-[79px] mr-[16px]"
          />
          <Button name="Filter" theme="blue" loading={loading} onClick={filterStaff} className="h-[40px] text-16 w-[128px]" />
        </div>
      </FormWrapper>
    </Modal>
  );
};

export default Filter;

Filter.propTypes = {
  closeModal: PropTypes.func,
  loading: PropTypes.bool,
  defaultParams: PropTypes.object
};
