import Accordion from 'components/Accordion';
import { DefaultDatepicker } from 'components/Datepicker/Index';
import FormWrapper from 'components/FormWrapper';
import Modal from 'components/Modals/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as Uncheck } from 'assets/icons/check-box-unselected.svg';
import { ReactComponent as Check } from 'assets/icons/check-box-selected.svg';
import { labTestStatus } from 'mocks/patient';
import { Button } from 'components/Buttons';
import useLabTest from 'hooks/fetch/useLabTest';

const FilterTest = ({ closeModal, setParams, params, filterTest }) => {
  const { loading } = useLabTest()

  return (
    <Modal
      closeModal={closeModal}
      styles="w-full md:w-[457px]"
      position="modal-right"
      title="Filter"
      variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
    >
      <FormWrapper className="relative">
        <div className='modal-right-body'>
          <Accordion
            display={false}
            title="By Status"
            className="border-b pb-4 border-neutral_stroke_2 mb-4"
            variant="mt-3"
            titleClassName="text-12"
          >
            <div className="bg-[#F7F8F8] p-4 rounded-lg max-h-60 overflow-scroll">
              {labTestStatus?.map((item) => (
                <div
                  key={item.name}
                  className={`${item.value === '' ? 'border-b border-dashed border-neutral_stroke_2 py-2' : 'py-3'
                    } flex`}
                  onClick={() => setParams({ ...params, status: item.value })}
                >
                  <div data-testid={item.name} className="cursor-pointer hide_tap">
                    {params.status === item.value ? <Check /> : <Uncheck />}
                  </div>
                  <p className="text-14 text-neutral_black font-campton_r ml-[8px] capitalize">{item.name}</p>
                </div>
              ))}
            </div>
          </Accordion>
          <section className="mb-[24px]">
            <p className="text-12 text-neutral_black font-campton_m mb-[10px]">By Date Range</p>
            <div className="grid grid-cols-2 gap-x-[16px]">
              <div>
                <DefaultDatepicker
                  label="From"
                  containerVariant="lg:col-span-6 col-span-12"
                  startDate={params.startDate}
                  maxDate={new Date()}
                  onSelect={(e) => setParams({ ...params, startDate: e })}
                  name="startDate"
                  showCalender={true}
                />
              </div>
              <div>
                <DefaultDatepicker
                  label="To"
                  containerVariant="lg:col-span-6 col-span-12"
                  startDate={params.endDate}
                  minDate={params?.startDate}
                  onSelect={(e) => setParams({ ...params, endDate: e })}
                  name="endDate"
                  showCalender={true}
                />
              </div>
            </div>
          </section>
        </div>
        <div className="modal-right-button-container">
          <Button
            name="Cancel"
            theme="transparent"
            type="button"
            onClick={closeModal}
            className="h-[40px] text-16 w-[79px] mr-[16px]"
          />
          <Button
            name="Filter"
            theme="blue"
            loading={loading}
            onClick={filterTest}
            className="h-[40px] text-16 w-[128px]"
          />
        </div>
      </FormWrapper>
    </Modal>
  );
};

export default FilterTest;

FilterTest.propTypes = {
  closeModal: PropTypes.func,
  params: PropTypes.object,
  setParams: PropTypes.func,
  filterTest: PropTypes.func,
};
