import { useState } from 'react';
import useMakeRequest from 'hooks/useMakeRequest';
import useTryCatch from 'hooks/useTryCatch';
import { getSingleVisit, getVisits, updateVisit, addMedicalRecord, getVisitAnalytics, getProgressNotes, addProgressNote, createVisitSummary, updateVisitSummary, createChiefComplaint } from 'store/modules/clinicals/actions';

export default function useVisit() {
  const { makeRequest, makeAnonymousRequest } = useMakeRequest();
  const [loading, setLoading] = useState(false);
  const { tryCatch } = useTryCatch();

  const get_visits = (id, params, callback) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getVisits({ pathname: id, params }), callback, alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    })
  }

  const get_progress_notes = (params) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getProgressNotes({ params }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    })
  }

  const get_visit_analytics = (params) => {
    tryCatch(async () => {
      setLoading(true)
      return makeRequest({ action: getVisitAnalytics({ params }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false))
    })
  }

  const get_single_visit = (id) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getSingleVisit({ pathname: id }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    })
  }

  const update_visit = ({ id, payload, callback }) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: updateVisit({ pathname: id, payload }), callback })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const create_visit_summary = (payload, callback) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: createVisitSummary({ payload }), callback })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const create_chief_complaint = (payload, callback) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: createChiefComplaint({ payload }), callback })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const update_visit_summary = (id, payload, callback) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: updateVisitSummary({ pathname: id, payload }), callback })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const add_medical_record = async (type, id, formData, callback) => {
    const payload = {
      type,
      requestType: 'add',
      ...formData,
    };
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: addMedicalRecord({ pathname: id, payload }), callback })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const get_visit_by_appointment_id = ({ appointmentId }) => {
    const url = `/institution/appointment-visit/${appointmentId}`
    return tryCatch(async () => {
      setLoading(true);
      return makeAnonymousRequest({ url, alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    })
  }

  const add_visit = (payload) => {
    const url = `/institution/visit/`
    return tryCatch(async () => {
      setLoading(true);
      return makeAnonymousRequest({ url, payload, method: 'post' })
        .then((res) => res)
        .finally(() => setLoading(false));
    })
  }

  const add_progress_note = (payload) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: addProgressNote(payload) })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const add_physical_examination = ({ payload }) => {
    const url = `/physical-examination/`
    return tryCatch(async () => {
      setLoading(true);
      return makeAnonymousRequest({ url, payload, method: 'post' })
        .then((res) => res)
        .finally(() => setLoading(false));
    })
  }

  const edit_physical_examination = ({ payload, id }) => {
    const url = `/physical-examination/${id}`
    return tryCatch(async () => {
      setLoading(true);
      return makeAnonymousRequest({ url, payload, method: 'patch' })
        .then((res) => res)
        .finally(() => setLoading(false));
    })
  }

  const add_signature = ({ payload }) => {
    const url = `/e-signature/`
    return tryCatch(async () => {
      setLoading(true);
      return makeAnonymousRequest({ url, payload, method: 'post' })
        .then((res) => res)
        .finally(() => setLoading(false));
    })
  }

  const add_diagnosis = ({ payload }) => {
    const url = payload?.primaryDiagnosis ? `/primary-diagnosis/` : `/secondary-diagnosis/`
    return tryCatch(async () => {
      setLoading(true);
      return makeAnonymousRequest({ url, payload, method: 'post' })
        .then((res) => res)
        .finally(() => setLoading(false));
    })
  }

  const edit_diagnosis = ({ payload, id }) => {
    const url = payload?.primaryDiagnosis ? `/primary-diagnosis/${id}` : `/secondary-diagnosis/${id}`
    return tryCatch(async () => {
      setLoading(true);
      return makeAnonymousRequest({ url, payload, method: 'patch' })
        .then((res) => res)
        .finally(() => setLoading(false));
    })
  }

  const add_history_of_patient_illness = ({ payload }) => {
    const url = `/history-of-illness/`
    return tryCatch(async () => {
      setLoading(true);
      return makeAnonymousRequest({ url, payload, method: 'post' })
        .then((res) => res)
        .finally(() => setLoading(false));
    })
  }

  const get_progress_note_visit = (id) => {
    const url = `institution/visit/recent-progress-note/${id}`
    return tryCatch(async () => {
      setLoading(true);
      return makeAnonymousRequest({ url, alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    })
  }

  return {
    get_visits,
    get_single_visit,
    add_medical_record,
    loading,
    get_visit_analytics,
    update_visit,
    create_visit_summary,
    get_visit_by_appointment_id,
    add_visit,
    get_progress_notes,
    add_progress_note,
    update_visit_summary,
    create_chief_complaint,
    add_physical_examination,
    add_signature,
    edit_physical_examination,
    add_diagnosis,
    edit_diagnosis,
    add_history_of_patient_illness,
    get_progress_note_visit
  };
}
