import Dropdown from 'components/Dropdown';
import FormWrapper from 'components/FormWrapper';
import { Input, TextArea } from 'components/Inputs';
import React, { useContext, useState } from 'react';
import { DefaultDatepicker } from 'components/Datepicker/Index';
import { Button } from 'components/Buttons';
import PropTypes from 'prop-types';
import { routeOfAdministrationList } from 'mocks/patient';
import { frequencyTypes } from 'mocks/settings';
import dayjs from 'dayjs';
import useMedications from 'hooks/fetch/useMedications';
import { useAuthState } from 'store/modules/auth';
import { useParams } from 'react-router-dom';
import { removeSpecificKeys } from 'utils/removeSpecificKeys';
import { PatientContext } from 'context';

const NewRequest = ({ callback, singleVisit}) => {
  //Variable
  const pathname = window.location.pathname;
  const page = pathname.includes('patient') ? 'patient' : 'visit'
  const {id} = useParams()
  const { progressNote, getSingleProgressNoteProxy } = useContext(PatientContext)

//Local State
  const [formData, setFormData] = useState({
    frequency: '',
    medicationName: '',
    dosage: '',
    routeOfAdministration: '',
    startDate: '',
    endDate: '',
    note: '',
  });
  const params = {
    type: page,
    [pathname.includes('patient') ? 'patientId' : 'visitId']: progressNote?.visit ?? id
  }
  const patientId = progressNote?.patient?._id ?? id
  const {create_medications, get_medications, loading} = useMedications()
  const {institutionId} = useAuthState()
  
  //Handler Function
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleCallback = () => {
    callback()
    if(progressNote) {
      getSingleProgressNoteProxy()
    } else {
      get_medications(params);
    }
  }
  const disabledButton =
  !formData.frequency ||
  !formData.medicationName ||
  !formData.dosage ||
  !formData.routeOfAdministration ||
  !formData.startDate;
  const createMedication = () => {
    const payload = {
      ...formData,
      startDate: dayjs(formData.startDate).format('YYYY-MM-DD'),
      endDate: formData.endDate ? dayjs(formData.endDate).format('YYYY-MM-DD') : '',
      institutionId,
      patientId: `${ pathname.includes('patient') ? patientId : singleVisit?.patient?._id}`,
      progressNoteId: progressNote?._id ?? '',
      visitId: progressNote?.visit ?? id 
    }
    create_medications(removeSpecificKeys(payload), handleCallback)
  };

  return (
    <FormWrapper className=" flex justify-between flex-col h-full">
      <div className="grid grid-cols-1 gap-2">
        <Input
          label="Medication Name"
          type="text"
          id="name"
          name="medicationName"
          readOnly={loading}
          value={formData?.medicationName}
          onChange={handleChange}
        />
        <Input
          label="Dosage"
          type="text"
          id="dosage"
          name="dosage"
          readOnly={loading}
          value={formData?.dosage}
          onChange={handleChange}
        />
        <Dropdown
          type="select"
          id="frequency"
          placeholder="Frequency"
          name="frequency"
          options={frequencyTypes}
          variant='mb-4'
          selected={formData?.frequency}
          onSelect={(data) => {
            setFormData({ ...formData, frequency: data.value });
          }}
        />
        <Dropdown
          type="select"
          id="routeOfAdministration"
          placeholder="Route of Administration"
          name="routeOfAdministration"
          options={routeOfAdministrationList}
          selected={formData?.routeOfAdministration}
          onSelect={(data) => {
            setFormData({ ...formData, routeOfAdministration: data.value });
          }}
        />
        <DefaultDatepicker
          label="Start Date"
          containerVariant="mt-[14px]"
          startDate={formData.startDate}
          minDate={new Date()}
          onSelect={(e) => setFormData({ ...formData, startDate: e })}
          name="startDate"
        />
        <DefaultDatepicker
          label="End Date"
          containerVariant="mt-[14px]"
          startDate={formData.endDate}
          minDate={formData.startDate}
          onSelect={(e) => setFormData({ ...formData, endDate: e })}
          name="endDate"
        />
        <TextArea
          label="Add note"
          type="text"
          id="note"
          rows={6}
          variant="mt-[14px]"
          name="note"
          readOnly={loading}
          value={formData?.note}
          onChange={(e) => setFormData({ ...formData, note: e.target.value })}
        />
      </div>
      <div className="flex mt-1 space-x-4 justify-end">
        <Button
          className="w-20 h-10
              text-14 hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary  px-2 py-1"
          theme="transparent"
          onClick={callback}
          name="Cancel"
        />
        <Button
          name="Save"
          theme="blue"
          loading={loading}
          onClick={createMedication}
          className="w-[104px] h-10 py-2 px-8"
          disabled={disabledButton}
        />
      </div>
    </FormWrapper>
  );
};

export default NewRequest;
NewRequest.propTypes = {
  callback: PropTypes.func,
  singleVisit: PropTypes.object,
  data: PropTypes.any
};
