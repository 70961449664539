import React from "react";
import PropTypes from "prop-types"
import { ReactComponent as AdminOfficer } from "assets/icons/admin-officer.svg"
import { ReactComponent as Contact } from "assets/icons/contact.svg"
import { ReactComponent as Calendar } from "assets/icons/calendar.svg"
import { formatDate } from "utils/formatDate";
import Avatar from "components/Avatar/Avatar";
import { motion } from "framer-motion"

export default function Card({ id, role, selectedRole, description, staffs, createdAt, onClick, className, displayDate = true, index }) {
    return (
        <motion.div
            initial={{ opacity: 0, y: "10px" }}
            animate={{ opacity: 1, y: 0, transition: { duration: .5, delay: index === 0 ? 0 : (.05 * index) } }}
            exit={{ opacity: 0 }}
            onClick={onClick}
            data-testid={id}
            className={`
            ${selectedRole === id ? 'border-brand_primary bg-primary_tint_50' : 'border-neutral_stroke_2'} border
            px-[18px] py-[14px] rounded-[8px] basis-[339.33px] cursor-pointer hide_tap ${className}
            `}
        >
            <div className="flex">
                {!/admin/.exec(role?.toLowerCase()) ?
                    <Contact fill="#153E4C" className="shrink-0" /> :
                    <AdminOfficer fill="#153E4C" className="shrink-0" />
                }
                <div className="ml-[8.75px] w-full">
                    <div className="mb-[8px] flex items-center justify-between">
                        <p className="text-16 text-primary_shade_300 font-campton_m capitalize">{role}</p>
                        <div className="relative flex items-center">
                            {staffs?.map((staff, i) =>
                                <Avatar
                                    key={staff?.id}
                                    url={staff?.profilePicture}
                                    width="24px"
                                    height="24px"
                                    alt={staff?.firstName?.split('')[0]}
                                    initials={`${staff?.firstName?.split('')[0] || ''}`}
                                    className={`
                                    ${i !== 0 ? 'ml-[-18px]' : ''} w-[24px] h-[24px] border border-neutral_white
                                    `}
                                />
                            )}
                        </div>
                    </div>
                    <p className="text-12 text-neutral_body font-campton_r leading-[18px] ellipsis_2_lines">{description}</p>
                    {displayDate ?
                        <div className="flex items-center justify-between mt-[16px]">
                            <div className="flex items-center">
                                <Calendar className="w-[12px] h-[13px]" />
                                <p className="text-12 text-neutral_black font-campton_r ml-[6px]">{formatDate(createdAt)}</p>
                            </div>
                            <p className="text-12 text-neutral_body font-campton_m ml-[6px]">See all permission(s)</p>
                        </div> : ''
                    }
                </div>
            </div>
        </motion.div>
    )
}

Card.propTypes = {
    displayDate: PropTypes.bool,
    staffs: PropTypes.array,
    role: PropTypes.string,
    description: PropTypes.string,
    createdAt: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,
    className: PropTypes.string,
    selectedRole: PropTypes.string,
    index: PropTypes.number
}