import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Button } from "components/Buttons";
import { Search } from "components/Inputs";
import Card from "pages/Settings/RolesAndPermission/Card";
import { ReactComponent as Warning } from "assets/icons/warning.svg";
import { ReactComponent as AdminOfficer } from "assets/icons/admin-officer.svg"
import { ReactComponent as Contact } from "assets/icons/contact.svg"
import { useSelector } from "react-redux";
import useProviders from "hooks/fetch/useProviders";
import useRoles from "hooks/fetch/useRoles";
import useDebounce from "hooks/useDebounce";
import Modal from "components/Modals/Modal";

export default function ChangeRole({ staff, callback, params, closeModal }) {
    const [state, setState] = useState(true)
    const { loading: isChangingRole, change_staff_role, get_institution_providers } = useProviders()
    const { get_roles } = useRoles()
    const [selectedRole, setSelectedRole] = useState()
    const { institutionId } = useSelector(state => state.auth)
    const { roles } = useSelector(state => state.roles)
    const [search, setSearch] = useState('')

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const iconToDisplay = (role) => {
        return !/admin/i.exec(role?.toLowerCase()) ?
            <Contact fill="#153E4C" className="shrink-0" /> :
            <AdminOfficer fill="#153E4C" className="shrink-0" />
    }

    const { debouncedValue } = useDebounce(search)

    useEffect(() => {
        get_roles({ id: institutionId, params: { search } })
    }, [debouncedValue])

    const currentRole = roles?.Roles?.find((role) => role?.name?.toLowerCase() === staff?.role?.toLowerCase())
    const filteredRoles = roles?.Roles?.filter((role) => role?.name?.toLowerCase() !== staff?.role?.toLowerCase())

    const changeStaffRole = () => {
        change_staff_role({ role: selectedRole?.name, providerId: staff?._id })
            .then((res) => {
                if (res?.code === 200) {
                    get_institution_providers({ id: `${institutionId}/providers`, params })
                    callback()
                }
            })
    }

    return (
        <div className="z-[500] absolute top-[50%] left-[50%]">
            <Modal closeModal={closeModal} styles="w-[310px] md:min-w-[468px]" title="Change Role">
                <div>
                    {state ?
                        <div>
                            <p className="text-14 text-neutral_body font-campton_m mb-[16px]">
                                Assign new role to <span className="text-neutral_black capitalize">{staff?.firstName} {staff?.lastName}</span>
                            </p>
                            <div className="mb-[16px]">
                                <Search
                                    id="search"
                                    name="search"
                                    placeholder="Search by role name..."
                                    onChange={handleSearch}
                                />
                            </div>
                            <div className="flex items-center justify-between border border-neutral_stroke_2 bg-[#F7F8F8] rounded-[8px] px-[16px] py-[15px]">
                                <p className="text-12 text-neutral_black font-campton_r">Current role</p>
                                <div className="flex items-center">
                                    <p className="text-14 text-neutral_black font-campton_sb mr-[8px]">{currentRole?.name}</p>
                                    {iconToDisplay('doctor')}
                                </div>
                            </div>
                            <hr className="border-dashed border-neutral_stroke_1 my-[24px]" />
                            <div className="h-[250px] overflow-y-auto">
                                {
                                    filteredRoles?.map((role) =>
                                        <Card
                                            key={role?._id}
                                            className="mb-[16px]"
                                            id={role?._id}
                                            description={role?.description}
                                            role={role?.name}
                                            //staffs={role.staffs}
                                            displayDate={false}
                                            selectedRole={selectedRole?._id}
                                            onClick={() => setSelectedRole(role)}
                                        />
                                    )
                                }
                            </div>
                            <div className="flex justify-end py-[20px]">
                                <Button
                                    name="Proceed"
                                    theme="blue"
                                    className="h-[40px] text-16 px-[12px] w-[112px]"
                                    onClick={() => setState(false)}
                                    disabled={!selectedRole}
                                />
                            </div>
                        </div> :
                        <div>
                            <p className="text-12 text-neutral_body font-campton_r mb-[5.5px]">Current role</p>
                            <div className="mb-[16px] flex">
                                {iconToDisplay('doctor')}
                                <div className="ml-[4.75px]">
                                    <p className="text-14 text-primary_shade_300 font-campton_m">{currentRole?.name}</p>
                                    <p className="text-12 text-neutral_body font-campton_r">{currentRole?.description}</p>
                                </div>
                            </div>
                            <p className="text-12 text-neutral_body font-campton_r mb-[5.5px]">New role</p>
                            <div className="flex">
                                {iconToDisplay('admin-officer')}
                                <div className="ml-[4.75px]">
                                    <p className="text-14 text-primary_shade_300 font-campton_m">{selectedRole?.name}</p>
                                    <p className="text-12 text-neutral_body font-campton_r">{selectedRole?.description}</p>
                                </div>
                            </div>
                            <div className="rounded-[8px] bg-[#FEF7EB] mt-[16px] p-[16px] flex mb-[92px]">
                                <Warning />
                                <p className="text-14 text-[#F59E0B] font-campton_r ml-[8px]">Changing user role will in return change all permissions tied to their previous role</p>
                            </div>
                            <div className="flex justify-end py-[20px]">
                                <Button
                                    name="Change role"
                                    theme="blue"
                                    className="h-[40px] text-16 px-[12px] w-[112px]"
                                    onClick={changeStaffRole}
                                    disabled={isChangingRole}
                                    loading={isChangingRole}
                                />
                            </div>
                        </div>
                    }
                </div>
            </Modal>
        </div>
    )
}

ChangeRole.propTypes = {
    staff: PropTypes.object,
    callback: PropTypes.func,
    params: PropTypes.object,
    closeModal: PropTypes.func
}