import React, { useContext } from "react";
import { SlideUp } from "components/Animations";
import { Button } from "components/Buttons";
import AbnormalFindings from "./AbnormalFindings";
import { LabTestDetailsContext } from "context";
import TestDetails from "./TestDetails";
import usePermission from "hooks/usePermission";

export default function View() {
    const { hasPermission } = usePermission()
    const { setTab, labTest } = useContext(LabTestDetailsContext)

    return (
        <SlideUp>
            <div className="px-[24px] pb-[20px]">
                <TestDetails test={labTest} />
                <AbnormalFindings />
                <div className="flex justify-end mt-[55px]">
                    {hasPermission("Lab Test", 'Update') &&
                        <Button
                            name="Edit"
                            theme="transparent"
                            onClick={() => setTab('edit')}
                            className="h-[40px] w-[112px] text-16 px-[12px] mr-[16px]"
                        />
                    }
                </div>
            </div>
        </SlideUp>
    )
}