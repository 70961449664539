import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Timer } from 'assets/icons/timer.svg';
import Avatar from 'components/Avatar/Avatar';
import { Button } from 'components/Buttons';
import usePayments from 'hooks/fetch/usePayments';
import { usePaymentState } from 'store/modules/payment';
import { formatCurrency } from 'utils/formatCurrency';
import dayjs from 'dayjs';
import { userInitals } from 'utils/ImagePlaceholder';
import Spinner from 'components/Spinner';
import EmptyState from 'components/Table/EmptyState';

export default function PaymentHistory({ close }) {
  const { get_payment_history, loading } = usePayments();
  const { billDetails } = usePaymentState();
  const [paymentHistory, setPaymentHistory] = useState([]);

  useEffect(() => {
    get_payment_history(`${billDetails?.billId}/payment-history`)
      .then((res) => setPaymentHistory(res?.data));
  }, []);

  return (
    <div className="relative">
      <div className='modal-right-body'>
        <section className="grid grid-cols-2 mb-[31px]">
          <div className="border-r border-r-neutral_stroke_2">
            <p className="text-12 text-neutral_body font-campton_r mb-[4px]">Total Amount Paid</p>
            <p className="text-14 text-neutral_black font-campton_r"> {formatCurrency(billDetails?.amountPaid ?? 0)}</p>
          </div>
          <div className="pl-[24px]">
            <p className="text-12 text-neutral_body font-campton_r mb-[4px]">Total Outstanding Balance</p>
            <p className="text-14 text-neutral_black font-campton_r">
              {formatCurrency(billDetails?.amountExpected ?? 0)}
            </p>
          </div>
        </section>
        <section>
          {!loading && paymentHistory?.length ? paymentHistory?.map((item) => (
            <div key={item?._id}>
              <div className="flex items-center">
                <Timer />
                <p className="text-12 font-campton_r text-neutral_body uppercase ml-[16px]">
                  {dayjs(item?.createdAt).format('MMM DD, YYYY, hh:mm A')}
                </p>
              </div>
              <div className="border-dashed border-l border-l-neutral_stroke_2 ml-[15px]">
                <div className="ml-[32px] pb-4">
                  <p className="text-12 text-neutral_body font-campton_r mb-[4px]">Payer</p>
                  <div className="flex mb-[8px]">
                    <Avatar
                      url={item?.patientInfo?.profilePic}
                      width="32px"
                      height="32px"
                      initials={userInitals(item?.patientInfo?.firstName, item?.patientInfo?.lastName)}
                      alt={item?.patientInfo?.firstName?.split('')[0]}
                      className="w-[32px] h-[32px] shrink-0"
                    />
                    <div className="ml-[8px]">
                      <p className="text-14 font-campton_m text-neutral_black">{`${item?.patientInfo?.firstName} ${item?.patientInfo?.lastName}`}</p>
                      <p className="text-12 font-campton_r text-neutral_body">
                        <span className="border-r border-r-neutral_stroke_2 mr-[4px] pr-[4px]">
                          {item?.patientInfo?.emailAddress}
                        </span>
                        <span>{item?.patientInfo?.gNumber}</span>
                      </p>
                    </div>
                  </div>
                  <div className="font-campton_r">
                    <div className="flex justify-between mb-[4px]">
                      <p className="text-12 text-neutral_body">Transaction ID</p>
                      <p className="text-12 text-neutral_black">{item?.reference ?? 'N/A'}</p>
                    </div>
                    <div className="flex justify-between mb-[4px]">
                      <p className="text-12 text-neutral_body">Amount paid</p>
                      <p className="text-12 text-neutral_black">{formatCurrency(item?.amount)}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="text-12 text-neutral_body">Outstanding amount</p>
                      <p className="text-12 text-neutral_black">
                        {formatCurrency(billDetails?.amountExpected - item?.amount)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )) : ''}
          {loading && <Spinner />}
          {!loading && !paymentHistory?.length && <EmptyState title='No Payment History' />}
        </section>
      </div>
      <div className="modal-right-button-container">
        <Button name="Close" theme="blue" className="h-[40px] text-16 px-[12px] w-[104px]" onClick={close} />
      </div>
    </div>
  );
}

PaymentHistory.propTypes = {
  close: PropTypes.func,
};
