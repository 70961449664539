import React, { useEffect, useMemo, useState } from "react";
import { SlideUp } from "components/Animations";
import useTable from "hooks/useTable";
import Cards from "../Components/Cards"
import Table from "components/Table";
import { shift_list_header } from "mocks/shiftManagement";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg"
import { formatDate, formatToDateWithDash } from "utils/formatDate";
import { useNavigate } from "react-router-dom";
import useShift from "hooks/fetch/useShift";
import { useSelector } from "react-redux";
import useDebounce from "hooks/useDebounce";
import { removeSpecificKeys } from "utils/removeSpecificKeys";
import ShiftFilter from "./ShiftFilter";
import usePermission from "hooks/usePermission";

export default function Shift() {
    const { hasPermission } = usePermission()
    const navigate = useNavigate()
    const { params, changePage, selectLimit, handleSearch, openFilterModal, closeFilterModal, filterModal } = useTable()
    const { loading, get_shifts, get_staff_shifts } = useShift()
    const { institutionId, user } = useSelector(state => state.auth)
    const { shifts } = useSelector(state => state.shift)
    const { debouncedValue } = useDebounce(params.search)
    const [filterParams, setFilterParams] = useState({ startFromDate: '', startToDate: '' })
    const [addFilter, setAddFilter] = useState(false)
    const providerId = user?._id
    const isPermittedToViewInstitutionShift = hasPermission('Scheduling', 'View all shifts')

    const getShiftProxy = (params) => {
        isPermittedToViewInstitutionShift ?
            get_shifts({ pathname: institutionId, params }) :
            get_staff_shifts({ pathname: providerId, params })
    }

    useEffect(() => {
        if (addFilter) {
            const filtered = removeSpecificKeys(filterParams) // remove undefined keys
            const convertedDate = Object.fromEntries(Object.entries(filtered).map(([key, value]) => [key, formatToDateWithDash(value)]))
            const newParams = { ...params, ...convertedDate }
            getShiftProxy(newParams)
            return
        }
        getShiftProxy(params)
    }, [debouncedValue, params.page, params.limit, addFilter, filterParams])

    const dataToExport = useMemo(() => {
        return shifts?.shifts?.map((item) => {
            return {
                shiftName: item?.shiftName,
                startDate: formatDate(item?.startDate),
                endDate: formatDate(item?.endDate),
                assignedStaff: item?.assignedStaff,
            }
        })
    }, [shifts])

    const filters = useMemo(() => {
        const filtered = removeSpecificKeys(filterParams) // remove undefined keys
        const convertedDate = Object.fromEntries(Object.entries(filtered).map(([key, value]) => [key, formatToDateWithDash(value)]))
        return addFilter ? Object.entries(convertedDate) : []
    }, [filterParams, addFilter])

    const clearFilters = () => {
        setFilterParams({ startFromDate: '', startToDate: '' })
    }

    return (
        <SlideUp>
            <Cards purpose="shift" total={shifts?.total ?? 0} />
            <>
                <div className="p-[24px]">
                    <Table
                        title="Shift List"
                        headers={shift_list_header}
                        data={dataToExport}
                        filterFnc={openFilterModal}
                        totalCount={shifts?.total}
                        perPage={shifts?.limit}
                        currentPage={shifts?.page}
                        selectLimit={selectLimit}
                        changePage={changePage}
                        loading={loading}
                        filters={filters}
                        clearFilters={clearFilters}
                        handleSearch={handleSearch}
                        buttonName="New Shift"
                        buttonFnc={() => navigate('new-shift')}
                    >
                        <>
                            {shifts?.shifts?.map((item) =>
                                <tr
                                    data-testid={item?._id}
                                    key={item?._id}
                                    className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                                    onClick={() => isPermittedToViewInstitutionShift ? navigate(item?._id) : navigate(`calendar/${item?._id}/${providerId}`)}
                                >
                                    <td className="px-[10px] py-[11px] flex items-center capitalize">{item?.shiftName}</td>
                                    <td className="px-[10px] py-[11px] capitalize">{formatDate(item?.startDate)}</td>
                                    <td className="px-[10px] py-[11px] capitalize">{formatDate(item?.endDate)}</td>
                                    <td className="px-[10px] py-[11px] capitalize">{item?.assignedStaff}</td>
                                    <td className="px-[10px] py-[11px] w-[20px]"><ArrowRight fill="#A9B2B2" /></td>
                                </tr>
                            )}
                        </>
                    </Table>
                </div>
                {filterModal &&
                    <ShiftFilter
                        closeFilterModal={closeFilterModal}
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        setAddFilter={() => setAddFilter(true)}
                    />
                }
            </>
        </SlideUp>
    )
}