import React, { useEffect, useMemo, useState } from 'react';
import Navbar from 'components/Navbar';
import Table from 'components/Table';
import { billing_history_header } from 'mocks/billAndPayments';
import { ReactComponent as File } from 'assets/icons/file.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import Status from 'components/Status';
import { SlideUp } from 'components/Animations';
import Overview from './Components/Overview';
import Chart from './Components/Chart';
import Filter from './Components/Filter';
import useTable from 'hooks/useTable';
import { useNavigate } from 'react-router-dom';
import usePayments from 'hooks/fetch/usePayments';
import { useAuthState } from 'store/modules/auth';
import { setBillDetails, usePaymentState } from 'store/modules/payment';
import dayjs from 'dayjs';
import useDebounce from 'hooks/useDebounce';
import { formatDate } from 'utils/formatDate';
import { formatCurrency } from 'utils/formatCurrency';
import { useDispatch } from 'react-redux';
import Restricted from 'HOC/Restricted';

export default function BillAndPayments() {
  const navigate = useNavigate();
  const { openFilterModal, closeFilterModal, filterModal } = useTable();
  const { get_billing_history, loading } = usePayments();
  const { user, institutionId, userType } = useAuthState()
  const { billingHistory } = usePaymentState();
  const { params, selectLimit, handleSearch, changePage } = useTable();
  const { debouncedValue } = useDebounce(params.search);
  const dispatch = useDispatch();
  const userAccount = ['institution', 'staff'].includes(userType) ? 'institution' : 'provider'

  const [newParams, setNewParams] = useState({
    fromDate: '',
    toDate: '',
    fromDueDate: '',
    toDueDate: '',
    paymentStatus: '',
    startAmount: '',
    endAmount: '',
  });

  const userDetails = {
    institutionId: institutionId ?? '',
    providerId: userAccount === 'institution' ? '' : user?._id,
    type: userAccount
  }

  useEffect(() => {
    document.title = 'Bill & Payments - Gabbi Health';
  }, []);

  const dataToExport = useMemo(() => {
    return billingHistory?.bills?.map((item) => {
      return {
        billId: item.billId,
        amountPaid: item.amountPaid,
        billDate: formatDate(item?.createdAt),
        dueDate: formatDate(item.dueDate),
        patientName: `${item?.patientInfo?.firstName} ${item?.patientInfo?.lastName}`,
        paymentMethod: item?.paymentMethod ?? 'N/A',
        paymentStatus: item?.paymentStatus,
      }
    })
  }, [billingHistory])

  const handleSingleView = (item) => {
    dispatch(setBillDetails(item))
    navigate(`/service/bill-and-payment/${item?._id}`)
  }
  
  const filter = () => {
    get_billing_history(
      {
        ...params,
        ...newParams,
        ...userDetails,
        fromDate: newParams.fromDate ? dayjs(newParams.fromDate).format('YYYY-MM-DD') : '',
        fromDueDate: newParams.fromDueDate ? dayjs(newParams.fromDueDate).format('YYYY-MM-DD') : '',
        toDueDate: newParams.toDueDate ? dayjs(newParams.toDueDate).format('YYYY-MM-DD') : '',
        toDate: newParams.toDate ? dayjs(newParams.toDate).format('YYYY-MM-DD') : '',
      },
      closeFilterModal,
    );
  };

  useEffect(() => {
    get_billing_history({ ...params, ...userDetails });
  }, [debouncedValue, params.page, params.limit]);

  return (
    <div>
      <Navbar title="Bill & Payments" />
      <Restricted module='Bills and Payment' permission='View billing history'>
        <>
          <SlideUp>
            <Overview />
            <Chart />
            <div className="p-[24px]">
              <Table
                title="Billing History"
                headers={billing_history_header}
                data={dataToExport}
                filterFnc={openFilterModal}
                totalCount={billingHistory?.total}
                perPage={billingHistory?.limit}
                currentPage={billingHistory?.page}
                selectLimit={selectLimit}
                changePage={changePage}
                loading={loading}
                handleSearch={handleSearch}
                buttonName="Create Bill"
                buttonFnc={() => navigate("create-bill")}
                module="Bills and Payment"
                permission="Create bill"
              >
                <>
                  {billingHistory?.bills?.map((item) => (
                    <tr
                      data-testid={item?.billId}
                      key={item?.billId}
                      className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer hide_tap"
                      onClick={() => handleSingleView(item)}
                    >
                      <td className="px-[10px] py-[8px] flex items-center capitalize">
                        <File />
                        <p className="ml-[8px] w-max">{item?.billId}</p>
                      </td>
                      <td className="px-[10px] py-[8px] whitespace-nowrap">{`${formatCurrency(item?.amountExpected + item?.amountPaid)}`}</td>
                      <td className="px-[10px] py-[8px] whitespace-nowrap">{dayjs(item?.createdAt).format('MMM DD, YYYY')}</td>
                      <td className="px-[10px] py-[8px] whitespace-nowrap">{dayjs(item?.dueDate).format('MMM DD, YYYY')}</td>
                      <td className="px-[10px] py-[8px] capitalize">
                        <p className='truncate max-w-[150px]'>{item?.patientInfo?.firstName} {item?.patientInfo?.lastName}</p>
                      </td>
                      <td className="px-[10px] py-[8px] capitalize">{item?.paymentMethod || 'N/A'}</td>
                      <td className="px-[10px] py-[8px] capitalize">
                        <Status status={item?.paymentStatus} />
                      </td>
                      <td className="px-[10px] py-[8px]">
                        <ArrowRight fill="#A9B2B2" />
                      </td>
                    </tr>
                  ))}
                </>
              </Table>
            </div>
          </SlideUp>
          {filterModal && <Filter cancel={closeFilterModal} params={newParams} setParams={setNewParams} func={filter} loading={loading} />}
        </>
      </Restricted>
    </div>
  );
}
