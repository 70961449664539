import { payments } from 'services/endpoints'
import { customAsyncThunk, createCustomAsyncThunk } from 'utils/createThunk'

const getAllBanks = customAsyncThunk('payment/getAllBanks', 'get', payments.get_all_banks)
const ResolveBankAccount = createCustomAsyncThunk('payment/ResolveBankAccount', 'get', payments.resolve_account)
const addBankInfo = customAsyncThunk('payment/AddbankInfo', 'patch', payments.add_bank_info)
const getBillingHistory = createCustomAsyncThunk('payment/getBillingHistory', 'get', payments.get_billing_history)
const withdrawFunds = customAsyncThunk('payment/withdrawFunds', 'post', payments.withdraw_funds)
const getInstitutionRevnue = createCustomAsyncThunk('payment/getInstitutionRevenue', 'get', payments.get_revenue)
const getWalletTransactions = createCustomAsyncThunk('payment/getWalletTransactions', 'get', payments.get_wallet_transactions)
const getPaymentHistory = createCustomAsyncThunk('payment/getPaymentHistory', 'get', payments.payments)
const confirmPayment = customAsyncThunk('payment/confirmPayment', 'patch', payments.confirm_payment)

export {
    getAllBanks,
    ResolveBankAccount,
    addBankInfo,
    getBillingHistory,
    getWalletTransactions,
    getInstitutionRevnue,
    withdrawFunds,
    getPaymentHistory,
    confirmPayment
}
