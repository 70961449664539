import React, { useContext, useEffect, useMemo, useState } from 'react';
import useProviders from 'hooks/fetch/useProviders';
import { PatientContext, VisitDetailsContext } from 'context';
import { default as PatientHistoryView } from "components/PatientHistory/PatientHistory"
import Spinner from 'components/Spinner';

const PatientHistory = () => {
  const [patient, setPatient] = useState()
  const { get_providers_patient, loading } = useProviders()
  const { singleVisit, visitStatus, canUpdate, progressNote} = useContext(VisitDetailsContext)

  useEffect(() => {
    get_providers_patient(singleVisit?.patient?._id ?? progressNote?.patient?._id)
      .then((res) => setPatient(res?.data ?? undefined))
  }, [])

  const contextValues = useMemo(() => {
    return { patient, setPatient, visitStatus, canUpdate }
  }, [patient, visitStatus, canUpdate])

  return (
    <>
      {!patient && loading ? <Spinner /> :
        <PatientContext.Provider value={contextValues}>
          <PatientHistoryView />
        </PatientContext.Provider>
      }
    </>
  );
};

export default PatientHistory;
