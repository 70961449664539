import React from "react"
import PropTypes from "prop-types"
import Status from "components/Status"

export default function VitalSignItem({ name, value, icon, hint, status, className, label }) {
    return (
        <div className={`flex ${className || 'border-r border-r-neutral_stroke_2'} pr-[10px] py-[16px]`}>
            <div className="mr-[6.5px]">{icon}</div>
            <div className="w-full">
                <p className="text-14 text-neutral_body font-campton_r leading-[18px] mb-[4px]">{name}</p>
                <div className="flex justify-between items-center">
                    <p className="text-14 text-neutral_black font-campton_r">{value || 'N/A'}{value && label}</p>
                    <Status status={status} />
                </div>
                <p className="text-10 text-neutral_body font-campton_r mt-[4px]">{hint}</p>
            </div>
        </div>
    )
}

VitalSignItem.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    icon: PropTypes.element,
    hint: PropTypes.string,
    status: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string
}