import React, { useEffect, useState } from 'react';
import HeartRate from 'components/VitalSignsAnalytics/HeartRate';
import RespiratoryRate from 'components/VitalSignsAnalytics/RespiratoryRate';
import Temperature from 'components/VitalSignsAnalytics/Temperature';
import OxygenSaturation from 'components/VitalSignsAnalytics/OxygenSaturation';
import Weight from 'components/VitalSignsAnalytics/Weight';
import Height from 'components/VitalSignsAnalytics/Height';
import BloodPressure from 'components/VitalSignsAnalytics/BloodPressure';
import BMI from 'components/VitalSignsAnalytics/BMI';
import usePatient from './fetch/usePatient';

export default function useVitalsAnalytics({ patientId, visitId, className }) {
    const [currentTab, setCurrentTab] = useState(0)
    const { loading, get_patients_clinical_analytics } = usePatient()
    const [filteredVitalsHistory, setFilteredVitalsHistory] = useState([])
    const [vitalsHistory, setVitalsHistory] = useState([])

    const tabs = [
        {
            id: 0,
            name: 'Blood pressure',
            type: 'bloodPressure',
            component: (
                <BloodPressure
                    className={className}
                    analyticsData={filteredVitalsHistory}
                    recentVitals={vitalsHistory[0]?.sysBloodPressure ? `${vitalsHistory[0]?.sysBloodPressure}/${vitalsHistory[0]?.diaBloodPressure}` : ''}
                />
            )
        },
        {
            id: 1,
            name: 'Heart rate trend',
            type: 'heartRate',
            component: <HeartRate className={className} analyticsData={filteredVitalsHistory} recentVitals={vitalsHistory[0]?.heartRate} />
        },
        {
            id: 2,
            name: 'Respiratory trend',
            type: 'respiratoryRate',
            component: <RespiratoryRate className={className} analyticsData={filteredVitalsHistory} recentVitals={vitalsHistory[0]?.respiratoryRate} />
        },
        {
            id: 3,
            name: 'Temperature trend',
            type: 'temperature',
            component: <Temperature className={className} analyticsData={filteredVitalsHistory} recentVitals={vitalsHistory[0]?.temperature} />
        },
        {
            id: 4,
            name: 'Oxygen saturation trend',
            type: 'oxygenSaturation',
            component: <OxygenSaturation className={className} analyticsData={filteredVitalsHistory} recentVitals={vitalsHistory[0]?.oxygenSaturation} />
        },
        {
            id: 5,
            name: 'Weight trend',
            type: 'weight',
            component: <Weight className={className} analyticsData={filteredVitalsHistory} recentVitals={vitalsHistory[0]?.weight} />
        },
        {
            id: 6,
            name: 'Height trend',
            type: 'height',
            component: <Height className={className} analyticsData={filteredVitalsHistory} recentVitals={vitalsHistory[0]?.height} />
        },
        {
            id: 7,
            name: 'Body mass index trend',
            type: 'bmi',
            component: <BMI className={className} analyticsData={filteredVitalsHistory} recentVitals={Number(vitalsHistory[0]?.bmi)?.toFixed(2)} />
        }
    ]

    const type = tabs[currentTab].type

    const selectedTab = tabs[currentTab]

    const toggleTab = () => {
        if ((currentTab + 1) === tabs.length) return setCurrentTab(0)
        setCurrentTab(prevState => prevState + 1)
    }

    useEffect(() => {
        get_patients_clinical_analytics({ patientId, visitId, type })
            .then(res => {
                setFilteredVitalsHistory(res?.data?.filteredVitalsHistory.slice(0, 10))
                setVitalsHistory(res?.data?.vitalsHistory)
            })
    }, [type])

    return { loading, selectedTab, tabs, toggleTab, filteredVitalsHistory, vitalsHistory }
}