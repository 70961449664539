import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { SlideUp } from "components/Animations"
import Navbar from "components/Navbar"
import Avatar from "components/Avatar/Avatar"
import Accordion from "components/Accordion"
import VitalSigns from "./VitalSigns"
import SubNavbar from "components/SubNavbar"
import { useLocation, useNavigate } from "react-router-dom"
import useVitals from "hooks/fetch/useVitals"
import Spinner from "components/Spinner"
import useDeviceWidth from "hooks/useDeviceWidth"

export default function VitalSignsDetails() {
    const { isMobile } = useDeviceWidth()
    const location = useLocation()
    const navigate = useNavigate()
    const [vitals, setVitals] = useState()
    const { get_vital } = useVitals()
    const patientId = location?.pathname?.split('/')[3]

    useEffect(() => {
        document.title = 'Vital Sign Details - Gabbi Health'
    }, [])

    useEffect(() => {
        if (patientId) {
            get_vital(patientId)
                .then((res) => setVitals(res?.data ?? undefined))
        }
    }, [patientId])

    return (
        <>
            <Navbar title={isMobile ? '' : 'Vital Sign Tracking'} />
            {!vitals ? <Spinner height="h-[80%]" /> :
                <SlideUp>
                    <SubNavbar
                        previous="Vital sign tracking"
                        current={`${vitals?.patient?.firstName || ''} ${vitals?.patient?.lastName || ''}`}
                        onClick={() => navigate('/clinicals/vital-signs')}
                    />
                    <div className="p-[24px]">
                        <div className="mb-[24px]">
                            <div className="bg-brand_primary h-[105px] rounded-t-[8px] relative">
                                <Avatar
                                    url={vitals?.patient?.profilePic}
                                    width="80px"
                                    height="80px"
                                    //alt={staff?.firstName?.split('')[0]}
                                    initials={`${vitals?.patient?.firstName?.split('')[0] || ''}${vitals?.patient?.lastName?.split('')[0] || ''}`}
                                    className="w-[80px] h-[80px] absolute top-[65px] left-[24px] border border-neutral_white"
                                />
                            </div>
                            <div className="border border-neutral_stroke_2 px-[24px] pt-[56px] pb-[24px]">
                                <div className="flex justify-between mb-[4px]">
                                    <div className="flex justify-between items-center w-full">
                                        <p className="text-16 text-neutral_black font-campton_m capitalize">
                                            {vitals?.patient?.firstName} {vitals?.patient?.lastName}
                                        </p>
                                        <p className="text-14 text-neutral_body font-campton_r">{vitals?.patient?.gNumber}</p>
                                    </div>
                                </div>
                                <p className="text-14 text-neutral_body font-campton_r mb-[4px]">{vitals?.patient?.emailAddress}</p>
                                <p className="text-14 text-neutral_body font-campton_r mb-[16px]">
                                    {vitals?.patient?.countryCode}{vitals?.patient?.phoneNumber}
                                </p>
                                <div className="border border-neutral_stroke_2 rounded-[8px] grid grid-cols-2 text-12 font-campton_r">
                                    <div className="px-[16px] py-[8px] border-r border-r-neutral_stroke_2">
                                        <p className="text-neutral_body mb-[3px]">Gender</p>
                                        <p className="text-neutral_black capitalize">{vitals?.patient?.gender || "N/A"}</p>
                                    </div>
                                    <div className="px-[16px] py-[8px]">
                                        <p className="text-neutral_body mb-[3px]">Date of Birth</p>
                                        <p className="text-neutral_black capitalize">{vitals?.patient?.dateOfBirth?.split('T')[0] || "N/A"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <VitalSigns vitals={vitals} />
                        <Accordion
                            title="Additional Notes"
                            display={false}
                        >
                            <p className="text-14 text-neutral_body font-campton_r mt-[16px]">{vitals?.additionalNote}</p>
                        </Accordion>
                    </div>
                </SlideUp>
            }
        </>
    )
}

VitalSignsDetails.propTypes = {
    callback: PropTypes.func
}