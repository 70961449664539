/* eslint-disable no-use-before-define */
import { Button } from 'components/Buttons';
import { DefaultDatepicker } from 'components/Datepicker/Index';
import FormWrapper from 'components/FormWrapper';
import { Input, TextArea } from 'components/Inputs';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { checkEmptyProperties } from 'utils/checkEmptyProperties';
import Spinner from 'components/Spinner';
import { PatientContext } from 'context';
import Dropdown from 'components/Dropdown';
import useAddTherapy from 'hooks/useAddTherapy';
import usePatient from 'hooks/fetch/usePatient';
import { useSelector } from 'react-redux';

const AddTherapy = ({ callback, singleVisit }) => {
  const { id } = useParams();
  const { get_therapies } = usePatient();
  const { institutionId } = useSelector(state => state.auth)
  const patientId = singleVisit?.patient?._id
  const visitId = id
  const { progressNote, getSingleProgressNoteProxy } = useContext(PatientContext)

  const handleCallback = () => {
    callback();
    get_therapies({
      type: 'visit',
      visitId: progressNote?.visit ?? id,
      institutionId,
    });
    if(progressNote) {
      getSingleProgressNoteProxy()
      }
  };
  
  const {
    therapyList,
    createTherapy,
    providerName,
    setGPINumber,
    isFetchingProvider,
    loadingService,
    loading,
    handleSelect,
    formData,
    setFormData,
    gpiNumber
  } = useAddTherapy(handleCallback, patientId, visitId, progressNote)

  return (
    <FormWrapper className=" flex justify-between flex-col h-full">
      <div className="grid">
        <Dropdown
          type="select"
          id="therapyName"
          label="Therapy Name"
          name="therapyName"
          width="mb-6"
          loading={loadingService}
          selected={formData?.therapyName}
          options={therapyList}
          onSelect={handleSelect}
        />
        <DefaultDatepicker
          label="Therapy Date"
          minDate={new Date()}
          startDate={formData?.therapyDate}
          name="therapyDate"
          containerVariant="mt-2"
          showCalender={true}
          onSelect={(e) => setFormData({ ...formData, therapyDate: e })}
        />
        <DefaultDatepicker
          label="Start Time"
          startDate={formData.startTime}
          timeCaption="Select Start time"
          dateFormat="h:mm aa"
          showTimeSelect={true}
          name="startTime"
          containerVariant="mt-5"
          showTimeSelectOnly={true}
          showTimeIcon={true}
          onSelect={(e) => setFormData({ ...formData, startTime: e })}
        />
        <DefaultDatepicker
          label="End Time"
          startDate={formData.endTime}
          timeCaption="Select End time"
          dateFormat="h:mm aa"
          showTimeSelect={true}
          name="endTime"
          containerVariant="mt-5"
          showTimeSelectOnly={true}
          showTimeIcon={true}
          onSelect={(e) => setFormData({ ...formData, endTime: e })}
        />
        <div className="grid gap-4 mt-5">
          <div>
            <Input
              label="Provider GPI-Number"
              type="text"
              id="gpiNumber"
              name="providerGPINumber"
              value={gpiNumber}
              onChange={(e) => setGPINumber(e.target.value)}
              measurement={isFetchingProvider ? <Spinner height="h-[10px]" width="w-[10px] mr-4" /> : ''}
            />
          </div>
          <div>
            <Input
              label={providerName ? '' : 'Provider Name'}
              type="text"
              variant="capitalize"
              id="fullName"
              name="fullName"
              value={providerName}
              disabled={true}
              readOnly={true}
            />
          </div>
        </div>
        <TextArea
          label="Add Note"
          type="text"
          id="note"
          rows={4}
          variant="mt-5"
          name="note"
          readOnly={false}
          value={formData?.note}
          onChange={(e) => setFormData({ ...formData, note: e.target.value })}
        />
      </div>
      <div className="flex mt-1 space-x-4 justify-end">
        <Button
          className="w-20 h-10
            text-14 hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary  px-2 py-1"
          theme="transparent"
          onClick={callback}
          name="Cancel"
        />
        <Button
          name="Create therapy"
          theme="blue"
          loading={loading}
          disabled={checkEmptyProperties(formData)}
          onClick={createTherapy}
          className="w-40 h-10 py-2 px-8"
        />
      </div>
    </FormWrapper>
  );
};

export default AddTherapy;
AddTherapy.propTypes = {
  callback: PropTypes.func,
  singleVisit: PropTypes.object,
};
