import { Button } from "components/Buttons"
import FormWrapper from "components/FormWrapper"
import { TextArea } from "components/Inputs"
import useVisit from "hooks/fetch/useVisit"
import React, { useState } from "react"
import PropTypes from "prop-types"
import { InstructionsThread } from "components/Threads"

export default function Instruction({ visit, canUpdate, callback, progressNote, visitStatus }) {
    const { update_visit_summary, loading } = useVisit()
    const [instruction, setInstruction] = useState('')
    const [addInstruction, setAddInstruction] = useState(false)
    const instructions = progressNote?.visitSummary?.instructions ?? visit?.visitSummary?.instructions
    const visitSummary = progressNote?.visitSummary?.summary ?? visit?.visitSummary?.summary
    const visitSummaryId = progressNote?.visitSummary?._id ?? visit?.visitSummary?._id ?? visit?._id

    const handleChange = (e) => {
        setInstruction(e.target.value)
    }

    const updateInstruction = () => {
        const payload = { note: instruction }
        update_visit_summary(visitSummaryId, payload)
            .then(res => {
                if (res?.code === 200) {
                    setAddInstruction(false)
                    setInstruction('')
                    callback()
                }
            })
    }

    return (
        <>
            <div className="flex items-center mb-[18px]">
                <hr className="border border-neutral_stroke_2 w-full" />
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-[20px]">
                    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#153E4C" />
                    <path d="M16.8538 16.1465C16.9002 16.193 16.9371 16.2481 16.9622 16.3088C16.9874 16.3695 17.0003 16.4346 17.0003 16.5003C17.0003 16.566 16.9874 16.631 16.9622 16.6917C16.9371 16.7524 16.9002 16.8076 16.8538 16.854C16.8073 16.9005 16.7522 16.9373 16.6915 16.9625C16.6308 16.9876 16.5657 17.0006 16.5 17.0006C16.4343 17.0006 16.3693 16.9876 16.3086 16.9625C16.2479 16.9373 16.1927 16.9005 16.1463 16.854L12 12.7072L7.85378 16.854C7.75996 16.9478 7.63272 17.0006 7.50003 17.0006C7.36735 17.0006 7.2401 16.9478 7.14628 16.854C7.05246 16.7602 6.99976 16.633 6.99976 16.5003C6.99976 16.3676 7.05246 16.2403 7.14628 16.1465L11.2932 12.0003L7.14628 7.85403C7.05246 7.76021 6.99976 7.63296 6.99976 7.50028C6.99976 7.3676 7.05246 7.24035 7.14628 7.14653C7.2401 7.05271 7.36735 7 7.50003 7C7.63272 7 7.75996 7.05271 7.85378 7.14653L12 11.2934L16.1463 7.14653C16.2401 7.05271 16.3674 7 16.5 7C16.6327 7 16.76 7.05271 16.8538 7.14653C16.9476 7.24035 17.0003 7.3676 17.0003 7.50028C17.0003 7.63296 16.9476 7.76021 16.8538 7.85403L12.7069 12.0003L16.8538 16.1465Z" fill="white" />
                    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="white" />
                </svg>
            </div>
            <div>
                {!visitStatus && visitSummary &&
                    <p
                        data-testid="add"
                        className="text-14 text-brand_secondary font-campton_m cursor-pointer hide_tap w-fit mb-[16px]"
                        onClick={() => setAddInstruction(true)}
                    >
                        Add follow-up instructions
                    </p>
                }
                {addInstruction && canUpdate ?
                    <FormWrapper onSubmit={updateInstruction}>
                        <div className="mb-[24px]">
                            <TextArea
                                placeholder="Type here..."
                                type="text"
                                id="instruction"
                                name="instruction"
                                readOnly={loading}
                                value={instruction}
                                onChange={handleChange}
                                rows={4}
                            />
                        </div>
                        <div className="flex justify-end mb-[28px]">
                            <Button
                                name="Save"
                                theme="blue"
                                className={`h-[32px] text-14 px-[12px] w-[58px]`}
                                loading={loading}
                                disabled={!instruction}
                            />
                        </div>
                    </FormWrapper> :
                    ''
                }
                <>
                    {instructions?.map((instruction) =>
                        <InstructionsThread
                            key={instruction?._id}
                            createdAt={instruction?.createdAt}
                            firstName={instruction?.provider?.firstName}
                            lastName={instruction?.provider?.lastName}
                            profilePic={instruction?.provider?.profilePic}
                            emailAddress={instruction?.provider?.emailAddress}
                            note={instruction?.note}
                        />
                    )}
                </>
            </div>
        </>
    )
}

Instruction.propTypes = {
    visit: PropTypes.object,
    canUpdate: PropTypes.bool,
    callback: PropTypes.func,
    progressNote: PropTypes.object,
    visitStatus: PropTypes.bool
}