import React from "react"
import PropTypes from "prop-types"
import { Button } from "components/Buttons"
import { ReactComponent as Empty } from "assets/svg/table-empty-state.svg";

export default function EmptyState({ title, caption, icon, buttonName, buttonFunc, className, headerVariant, width }) {
    return (
        <div className={`${className} flex flex-col justify-center items-center py-[24px] px-[16px] h-full mt-[16px]`}>
            <div className="mx-auto">{icon || <Empty className={`${width}`}/>}</div>
            <p className={`text-18 text-center font-campton_m font-neutral_black mb-[4px] mt-[16px] ${headerVariant}`}>
                {title || 'No record found.'}
            </p>
            <p className="text-14 font-campton_r font-neutral_body text-center">{caption}</p>
            {buttonName ?
                <Button
                    name={buttonName}
                    theme="blue"
                    onClick={buttonFunc}
                    className="mt-[20px] py-[4px] px-[4px]"
                /> : ''
            }
        </div>
    )
}

EmptyState.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    caption: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    className: PropTypes.string,
    headerVariant: PropTypes.string,
    icon: PropTypes.element,
    buttonName: PropTypes.string,
    buttonFunc: PropTypes.func,
    iconWidth: PropTypes.string
}