import React from "react"
import PropTypes from "prop-types"
import { ReactComponent as Patient } from "assets/icons/patient.svg"
import { ReactComponent as Vitals } from "assets/icons/vitals.svg"
import { ReactComponent as Visits } from "assets/icons/visits.svg"
// import { ReactComponent as Capsule } from "assets/icons/capsule.svg"
import { ReactComponent as Test } from "assets/icons/test.svg"
import SubMenu from "../SubMenu"
import usePermission from "hooks/usePermission"

export default function ClinicalOption({ showDrawer, toggleClinicals, iconMargin }) {
    const { hasModulePermission } = usePermission()

    const clinical_options = [
        {
            name: "Patients",
            value: "patients",
            icon: <Patient className={iconMargin} />,
            permission: hasModulePermission('Patient Management') || hasModulePermission('Inpatient Management')
        },
        {
            name: "Vital Signs",
            value: "vital-signs",
            icon: <Vitals className={iconMargin} fill='white' />,
            permission: hasModulePermission('Vital Signs')
        },
        {
            name: "Visits",
            value: "visits",
            icon: <Visits className={iconMargin} />,
            permission: hasModulePermission('Visits')
        },
        // {
        //     name: "Medications",
        //     value: "medications",
        //     icon: <Capsule className={iconMargin} />,
        //     permission: hasModulePermission('Medications')
        // },
        {
            name: "Lab Test",
            value: "lab-test",
            icon: <Test className={`${iconMargin} 'min-w-[18px]'`} fill="#FFFFFF" />,
            permission: hasModulePermission('Lab Test')
        }
    ]

    return (
        <>
            <SubMenu
                options={clinical_options}
                condition={toggleClinicals}
                type='clinicals'
                showDrawer={showDrawer}
            />
        </>
    )
}

ClinicalOption.propTypes = {
    showDrawer: PropTypes.bool,
    toggleClinicals: PropTypes.bool,
    iconMargin: PropTypes.string
}