import { Button } from 'components/Buttons';
import React from 'react';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import AppointmentInfo from '../Components/AppointmentInfo';
import PropTypes from 'prop-types';
import useAppointments from 'hooks/fetch/useAppointments';
import dayjs from 'dayjs';

const PreviewInformation = ({ back, patient, provider, formData, time }) => {
  const { create_appointment, loading } = useAppointments();
  const appointmentDetails = {
    patientInfo: {
      profilePic: patient?.profilePic,
      firstName: patient?.firstName,
      lastName: patient?.lastName,
      emailAddress: patient?.emailAddress,
      gNumber: patient?.gNumber
    },

    // dayjs(Tue Aug 29 2023 07:30:00 GMT+0100 (West Africa Standard Time)).format('')
    appointmentDate: formData?.appointmentDate,
    startTime: time?.start,
    endTime: time?.end,
    type: formData?.appointmentType,
    status: formData?.priority,
    note: formData?.note,
    providerInfo: {
      profilePic: provider?.profilePic,
      firstName: provider?.firstName,
      lastName: provider?.lastName,
      emailAddress: provider?.emailAddress,
      role: provider?.role,
      title: provider?.title,
      specialty: provider?.specialty,
      GPINumber: provider?.GPINumber
    }
  }

  const createAppointment = () => {
    const newFormData = {
      ...formData,
      startTime: dayjs(time?.start).format('hh:mm A'),
      appointmentDate: dayjs(formData?.appointmentDate).format('YYYY-MM-DD'),
    };
    create_appointment(newFormData);
  };
  
  return (
    <div className="h-full flex justify-between flex-col">
      <div className='mb-[30px]'>
        <div className="flex items-center mb-[24px]">
          <Button theme="transparent" className="w-[82px] mr-[12px]" type="button" onClick={back}>
            <>
              <ArrowBack fill="#748181" className="w-[7px] h-[12px] mr-[13.5px]" />
              Back
            </>
          </Button>
          <h2 className="text-16 text-neutral_black font-campton_m pl-[12px] border-l border-l-neutral_stroke_2">
            Preview Information
          </h2>
        </div>
        <AppointmentInfo appointmentDetails={appointmentDetails} type='preview' purpose="booking" />
      </div>
      <div className="flex justify-end">
        <Button
          name="Cancel"
          theme="transparent"
          type="button"
          onClick={back}
          className="h-[40px] text-16 w-[79px] mr-[16px]"
        />
        <Button
          name="Create appointment"
          theme="blue"
          type="button"
          className="h-[40px] text-16 w-[182px]"
          loading={loading}
          onClick={createAppointment}
        />
      </div>
    </div>
  );
};

export default PreviewInformation;
PreviewInformation.propTypes = {
  back: PropTypes.func,
  formData: PropTypes.object,
  time: PropTypes.object,
  patient: PropTypes.object,
  provider: PropTypes.object,
};
