import { SlideUp } from 'components/Animations';
import { termsOfUse } from 'mocks/termsAndCondition';
import React from 'react';

const TermsOfUse = () => {
  return (
    <SlideUp>
      <div className="lg:grid grid-cols-12 gap-20">
        <div className="lg:col-span-8" >
          <div>
            <p className="md:text-18 text-16 font-campton_m text-neutral_black">Introduction</p>
            <div className="font-campton_r mt-2 md:text-16 text-14">
              <p className="text-neutral_body">
                Welcome to the Gabbi My Health App (the App) provided by Gabbi Health Inc. (Company, we, us, or our).
              </p>
              <p className="text-neutral_black">Please read these terms of use carefully before using the app</p>
              <p className="text-neutral_body">
                By accessing or using the App, you agree to be bound by these Terms of Use. If you do not agree to these
                Terms of Use, you may not access or use the App.
              </p>
            </div>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id='scopeOfUse'>
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">1. Scope of Use</p>
            <div className="text-neutral_body font-campton_r mt-1 leading-6 md:text-16 text-14">
              <p>
                The App is intended to provide patients with a convenient way to access and manage their healthcare
                information. You may use the App to:
              </p>
              <ul className="list-disc pl-6">
                <li>View your medical records</li>
                <li>Schedule appointments</li>
                <li>Communicate with your healthcare providers</li>
                <li>Pay your bills</li>
                <li>Manage your medications</li>
                <li>Access educational resources</li>
              </ul>
            </div>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id='accountRegistration'>
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">2. Account Registration</p>
            <p className="text-neutral_body font-campton_r mt-1 md:text-16 text-14">
              To use certain features of the App, you will need to create an account. You agree to provide accurate and
              up-to-date information about yourself when you create an account. You are responsible for maintaining the
              confidentiality of your account password and for all activity that occurs under your account. You agree to
              notify us immediately of any unauthorized use of your account.
            </p>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id='userConduct'>
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">3. User Conduct</p>
            <div className="text-neutral_body font-campton_r mt-1 leading-6 md:text-16 text-14">
              <p>You agree to use the App in a responsible and lawful manner. You agree not to use the App to:</p>
              <ul className="list-disc pl-6 mt-4">
                <li>
                  Upload or transmit any content that is unlawful, harmful, threatening, abusive, harassing, tortious,
                  defamatory, vulgar, obscene, libelous, invasive of another`s privacy, hateful, or racially,
                  ethnically, or otherwise objectionable.
                </li>
                <li>Impersonate any person or entity, including any employee or representative of Company.</li>
                <li>Interfere with or disrupt the App or servers or networks connected to the App.</li>
                <li>Attempt to gain unauthorized access to the App or any part of the App.</li>
                <li>
                  Use the App to transmit any unsolicited or unauthorized advertising, promotional materials, `junk
                  mail,` `spam,` or any other form of solicitation
                </li>
              </ul>
            </div>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id='intellectualProperty'>
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">4. Intellectual Property</p>
            <p className="text-neutral_body font-campton_r mt-1 md:text-16 text-14">
              The App and all of its content, including but not limited to text, software, graphics, images, and logos,
              are the property of Company or its licensors and are protected by copyright, trademark, and other
              intellectual property laws. You may not reproduce, distribute, modify, create derivative works of, or
              otherwise exploit the App or any of its content without the prior written consent of Company
            </p>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id='disclaimer'>
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">5. Disclaimer of Warranties</p>
            <p className="text-neutral_body font-campton_r mt-1 md:text-16 text-14">
              THE APP IS PROVIDED `AS IS` AND `AS AVAILABLE.` COMPANY MAKES NO WARRANTIES, EXPRESS OR IMPLIED, INCLUDING
              BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
              NON-INFRINGEMENT. COMPANY DOES NOT WARRANT THAT THE APP WILL BE UNINTERRUPTED OR ERROR-FREE. COMPANY DOES
              NOT WARRANT THAT THE APP WILL BE SECURE OR THAT YOUR USE OF THE APP WILL BE SAFE. YOU AGREE THAT YOU USE
              THE APP AT YOUR OWN RISK.
            </p>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id='limitation'>
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">6. Limitation of Liability</p>
            <p className="text-neutral_body font-campton_r mt-1 md:text-16 text-14">
              COMPANY SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY
              DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE APP, INCLUDING BUT NOT LIMITED TO DAMAGES FOR
              LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES, EVEN IF COMPANY HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES.
            </p>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id='indemnification'>
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">7. Indemnification</p>
            <p className="text-neutral_body font-campton_r mt-1 md:text-16 text-14">
              You agree to indemnify and hold harmless Company, its affiliates, officers, directors, employees, agents,
              and licensors from and against any and all claims, liabilities, damages, losses, costs, expenses, and fees
              (including reasonable attorneys` fees) arising out of or in connection with your use of the App or your
              violation of these Terms of Use.
            </p>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id='termination'>
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">8. Termination</p>
            <p className="text-neutral_body font-campton_r mt-1 md:text-16 text-14">
              Company may terminate your access to the App at any time, without notice or cause. Upon termination, you
              will no longer be able to access the App or any of its content.
            </p>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id='changes'>
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">9. Changes to Terms of Use</p>
            <p className="text-neutral_body font-campton_r mt-1 md:text-16 text-14">
              Company may change these Terms of Use at any time by posting the updated Terms of Use on the App. You
              agree to review these Terms of Use periodically to be informed of any changes. Your continued use of the
              App following the posting of updated Terms of Use will be deemed your acceptance of such changes.
            </p>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id='governingLaw'>
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">10. Governing Law</p>
            <p className="text-neutral_body font-campton_r mt-1 md:text-16 text-14">
              These Terms of Use shall be governed by and construed in accordance with the laws of the State without
              regard to its conflict of laws principles
            </p>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id='entireAgreement'>
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">11. Entire Agreement</p>
            <p className="text-neutral_body font-campton_r mt-1 md:text-16 text-14">
              These Terms of Use constitute the entire agreement between you and Company with respect to the App and
              supersede all prior or contemporaneous communications, representations, or agreements, whether oral or
              written.
            </p>
          </div>
          <div className="pt-4 border-t border-neutral_stroke_2 mt-6" id='waiver'>
            <p className="font-campton_m md:text-18 text-16 text-neutral_black">12. Waiver</p>
            <p className="text-neutral_body font-campton_r mt-1 md:text-16 text-14">
              No waiver of any provision of these Terms of Use shall be effective unless in writing and signed by an
              authorized representative of Company
            </p>
          </div>
        </div>
        <div className="col-span-4 bg-primary_tint_50 px-6 py-2 rounded-2xl h-fit hidden lg:block">
          <ul className="font-campton_r text-14 text-neutral_black list-decimal px-6">
          {
            termsOfUse.map((item) =>
            <li className="py-2.5 cursor-pointer" key={item.name}><a href={`#${item.link}`}>{item.name}</a></li>
            )
          }
          </ul>
        </div>
      </div>
    </SlideUp>
  );
};

export default TermsOfUse;
