import { useDispatch } from "react-redux";
import { notify } from "../store/modules/global";
import useHandleError from "./useHandleError";

export default function useCopyToClipboard() {
    let dispatch = useDispatch()
    const { handleError } = useHandleError()

    const copyToClipboard = async (value) => {
        if (value) {
            await navigator.clipboard?.writeText(value);
            dispatch(notify({display: true, status: 'success', message: 'Copied!'}))
        }
    }

    const share = async (value) => {
        try {
            if (navigator.canShare) {
                // Use navigator.share() to share 'shareData'
                await navigator.share({title: 'POUCHERS', text: value})
            } else {
                await copyToClipboard(value)
            }
        } catch(error) {
            dispatch(notify({display: true, status: 'error', message: handleError({message: error.message})}))
        }
    }

    return { copyToClipboard, share }
}