import { useEffect, useState } from 'react';

export default function useAssignPermissions(prevState) {
  const [permissions, setPermissions] = useState([
    {
      module: 'Dashboard',
      action: [],
      availableActions: ['View analytics', 'Export data'],
    },
    {
      module: 'Appointment',
      action: [],
      availableActions: [
        'View',
        'Book appointment',
        'Send reschedule request',
        'Send cancel request',
        'Manage appointment request',
        'View all appointment',
        'View assigned appointment'
      ],
    },
    {
      module: 'Bills and Payment',
      action: [],
      availableActions: [
        'View wallet transactions',
        'Add wallet bank info',
        'Change wallet bank info',
        'Withdraw funds',
        'View billing history',
        'Confirm payment',
        'Create bill',
        'Export/Print'
      ],
    },
    {
      module: 'Inpatient Management',
      action: [],
      availableActions: ['View patient', 'Admit patient', 'Update manage care team', 'Discharge patient'],
    },
    {
      module: 'Medications',
      action: [],
      availableActions: ['View patient medication', 'Add patient medication', 'Update patient medication'],
    },
    {
      module: 'Messaging',
      action: [],
      availableActions: ['View patient messagings', 'Create new message'],
    },
    {
      module: 'Lab Test',
      action: [],
      availableActions: ['View', 'Add', 'Update', 'Delete', 'Export'],
    },
    {
      module: 'Location',
      action: [],
      availableActions: ['View location', 'Add location', 'Edit location', 'Delete', 'Export'],
    },
    {
      module: 'Patient Management',
      action: [],
      availableActions: [
        'View all patient list',
        'Add patient',
        'View patient records',
        'Update patient records',
        'View patient bills',
      ],
    },
    {
      module: 'Policy',
      action: [],
      availableActions: ['View policies', 'Add policy', 'Edit policy', 'Export'],
    },
    {
      module: 'Roles and Permission',
      action: [],
      availableActions: ['View roles/permissions', 'Create new role', 'Edit role', 'Delete role'],
    },
    {
      module: 'Scheduling',
      action: [],
      availableActions: [
        'View calendar',
        'View all shifts',
        'Create shift',
        'Update shift',
        'Remove staff from shift',
        'View swap request',
        'Manage swap request',
        'Send swap request',
        'Block time slot',
        'Export/Print'
      ],
    },
    {
      module: 'Service Management',
      action: [],
      availableActions: [
        'View service category',
        'Add service category',
        'Create service',
        'View service',
        'Update services',
        'Update category',
        'Delete category',
        'Delete service',
        'Export/Print'
      ],
    },
    {
      module: 'Specialty',
      action: [],
      availableActions: ['View specialty', 'Create specialty', 'Add staff', 'Edit', 'Delete'],
    },
    {
      module: 'Staff Management',
      action: [],
      availableActions: [
        'View staff list',
        'Invite staff',
        'Activate/Deactivate staff',
        'Delete staff',
        'Change role',
        'Export/Print'
      ],
    },
    {
      module: 'Visits',
      action: [],
      availableActions: ['View visits', 'Create visit', 'Update visits', 'Export/Print'],
    },
    {
      module: 'Vital Signs',
      action: [],
      availableActions: ['View vitals', 'Add new vitals', 'Update vitals', 'Export/Print'],
    },
  ]);

  const handleSelectedPermission = (module, action) => {
    const updatedPermissions = permissions.map((item) => {
      if (item.module === module) {
        return {
          ...item,
          module: item.module,
          action: !item.action.includes(action)
            ? [...item.action, action]
            : item.action.filter((item) => item !== action),
        };
      }
      return item;
    });
    setPermissions(updatedPermissions);
  };

  const selectAllPermissions = (module) => {
    const updatedPermissions = permissions.map((item) => {
      if (item.module === module) {
        return {
          ...item,
          module: item.module,
          action: item?.availableActions
        };
      }
      return item;
    });
    setPermissions(updatedPermissions);
  }

  const unselectAllPermissions = (module) => {
    const updatedPermissions = permissions.map((item) => {
      if (item.module === module) {
        return {
          ...item,
          module: item.module,
          action: []
        };
      }
      return item;
    });
    setPermissions(updatedPermissions);
  }

  useEffect(() => {
    if (prevState) {
      let newState = permissions?.map((permission, i) => {
        return {
          module: permission?.module,
          action: prevState[i]?.action || [],
          availableActions: permission.availableActions,
        };
      });
      setPermissions(newState);
    }
  }, []);

  return { permissions, handleSelectedPermission, selectAllPermissions, unselectAllPermissions };
}
