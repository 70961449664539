export const service_list_header = (type) =>
{
    return [`${type} Name`, 'Sub-Category', 'Total Amount (NGN)', 'Date Created', 'Last Modified', '']
}

export const services_list_data = [
    {
        _id: '1',
        name: 'Thyroidectomy',
        subCategory: 'Consultation',
        totalFee: '2500',
        createdAt: '1960-01-01T00:00:00.000Z',
        updatedAt: '1960-02-01T00:00:00.000Z'
    },
    {
        _id: '2',
        name: 'Appendectomy.',
        subCategory: 'Surgical procedure',
        totalFee: '2500',
        createdAt: '1960-01-01T00:00:00.000Z',
        updatedAt: '1960-02-01T00:00:00.000Z'
    },
]
export const serviceCategory = [
    {
        _id: "1",
        name: 'Procedure',
        description: 'Lorem ipsum dolor sit amet consectetur. Et sagittis dui tristique semper mattis. '
    },
    {
        _id: "2",
        name: 'Order Tests',
        description: 'Lorem ipsum dolor sit amet consectetur. Et sagittis dui tristique semper mattis. '
    },
    {
        _id: "3",
        categoryName: 'Therapy',
        description: 'Lorem ipsum dolor sit amet consectetur. Et sagittis dui tristique semper mattis. '
    },
]