export const appointmentTypes = [
    {
        id: 1,
        name: 'Walk-in appointment',
        value: 'Walk-in appointment'
    },
    {
        id: 2,
        name: 'Telehealth appointment',
        value: 'Telehealth appointment'
    }
]