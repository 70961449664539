import React, { useCallback, useEffect, useState } from "react";
import BreadCrumbs from "components/BreadCrumbs";
import Navbar from "components/Navbar";
import { SlideUp } from "components/Animations";
import useMultiStepForm from "hooks/useMultiStepForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import StepProgress from "components/StepProgress";
import BasicInformation from "./BasicInformation";
import CareTeam from "./CareTeam";
import PreviewInformation from "./PreviewInformation";
import useDebounce from "hooks/useDebounce";
import useProviders from "hooks/fetch/useProviders";

export default function AdmitPatient() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const { get_patient_by_gNumber, loading: isFetchingPatient } = useProviders();
    const patientGNumber = searchParams.get('gNumber');
    const [gNumber, setGNumber] = useState(patientGNumber ?? '')
    const [patient, setPatient] = useState()
    const [selectedLocation, setSelectedLocation] = useState('')
    const [admittedBy, setAdmittedBy] = useState()
    const [formData, setFormData] = useState({
        location: '',
        roomNumber: '',
        admissionDiagnosis: '',
        admittedBy: '',
        careTeam: []
    })

    const { debouncedValue } = useDebounce(gNumber)

    const updateStaffs = (staffs) => {
        setFormData({ ...formData, staffs })
    }

    const onSelect = useCallback((e) => {
        if (e.key === 'location') {
            setSelectedLocation(e.name)
            setFormData({ ...formData, location: e.value })
            return
        }
        setAdmittedBy(e)
        setFormData({ ...formData, admittedBy: e?._id })
    }, [formData])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (debouncedValue.length === 10) {
            get_patient_by_gNumber(gNumber)
                .then(res => setPatient(res?.data || {}))
        }
    }, [debouncedValue])

    const { step, next, back } = useMultiStepForm([
        <BasicInformation
            key={1}
            next={() => next()}
            onSelect={onSelect}
            gNumber={gNumber}
            setGNumber={setGNumber}
            selectedLocation={selectedLocation}
            admittedBy={admittedBy}
            loading={isFetchingPatient}
            handleChange={handleChange}
            formData={formData}
            patient={patient}
            cancel={() => navigate(-1)}
        />,
        <CareTeam
            key={2}
            updateStaffs={updateStaffs}
            admittedBy={admittedBy}
            formData={formData}
            setFormData={setFormData}
            cancel={() => navigate(-1)}
            back={() => back()}
            next={() => next()}
        />,
        <PreviewInformation
            key={3}
            back={() => back()}
            formData={formData}
            setFormData={setFormData}
            patient={patient}
            admittedBy={admittedBy}
            cancel={() => navigate(-1)}
        />
    ])

    const progress = [
        {
            step: 1,
            title: 'Basic Information',
            caption: 'Patient information, room number, admission diagnosis.'
        },
        {
            step: 2,
            title: 'Care Team',
            caption: 'Select providers in charge of this patient'
        },
        {
            step: 3,
            title: 'Preview Information',
            caption: 'Preview & ensure information provided is correct'
        }
    ]

    const options = [
        {
            title: 'Patient Management',
            method: () => navigate(-1)
        },
        {
            title: 'Admit Patient'
        }
    ]

    return (
        <>
            <Navbar title="Patient Management" />
            <BreadCrumbs options={options} />
            <SlideUp>
                <div className="md:flex md:p-[24px] min-h-[80vh]">
                    <StepProgress progress={progress} step={step.key} />
                    <div className="p-[24px] md:border border-neutral_stroke_2 rounded-[16px] w-full md:ml-[316px]">
                        {step}
                    </div>
                </div>
            </SlideUp>
        </>
    )
}