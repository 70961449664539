import { Button } from 'components/Buttons';
import React from 'react';
import MedicationInfo from './MedicationInfo';
import PropTypes from 'prop-types';
import useMedications from 'hooks/fetch/useMedications';
import { useParams } from 'react-router-dom';

const Confirmation = ({ callback, data, action, formData }) => {
  const pathname = window.location.pathname;
  const page = pathname.includes('patient') ? 'patient' : 'visit';
  const { id } = useParams();

  const { update_medication_status, get_medications, loading } = useMedications();
  const params = {
    type: page,
    [pathname.includes('patient') ? 'patientId' : 'visitId']: id
  }
  const handleCallback = () => {
    callback();
    get_medications(params);
  };

  const handleSubmit = () => {
    const payload = {
      status: action === 'Pause' ? "paused" : "stopped",
      // patientId: data.patientId,
      medicationId: data._id,
      reason: formData.reason,
    };
    update_medication_status(payload, handleCallback);
  };
  return (
    <div className="flex justify-between h-full flex-col">
      <div>
        <p className="font-campton_m text-14 text-neutral_black mt-2 mb-3">Medication Information</p>
        <MedicationInfo data={data} />
        <div className=" border-t border-dashed border-neutral_stroke_2 mt-6 pt-4">
          <p className="font-campton_m text-14 text-neutral_black capitalize">{action} Information</p>
          <div className="border border-neutral_stroke_2 p-4 rounded-2xl text-14 font-campton_r mt-3 h-[120px]">
            <p className="text-neutral_body capitalize">Reason for {action}</p>
            <p className="text-neutral_black mt-1">{formData?.reason}</p>
          </div>
        </div>
      </div>
      <div className="flex mt-1 space-x-4 justify-end">
        <Button
          className="w-fit h-10
            text-14 hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary  px-2 py-1"
          theme="transparent"
          onClick={callback}
          name="Cancel"
        />
        <Button name={`${action} Medication`} theme="blue" className="w-fit h-10 py-2 px-8" onClick={handleSubmit} loading={loading} />
      </div>
    </div>
  );
};

export default Confirmation;
Confirmation.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  callback: PropTypes.func,
  action: PropTypes.string,
};
