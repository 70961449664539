/* eslint-disable max-len */
import Avatar from "components/Avatar/Avatar";
import React from "react";
import PropTypes from 'prop-types';
import { userInitals } from "utils/ImagePlaceholder";
import Status from "components/Status";
import MoreOptions from "components/Options/MoreOptions";
import { ReactComponent as Dot } from 'assets/icons/veritical-dot.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as View } from "assets/icons/eye-open.svg"
import { ReactComponent as Calender } from 'assets/icons/calendar-icon.svg';
import { ReactComponent as Close } from 'assets/icons/close-circle.svg';
import dayjs from "dayjs";
import usePermission from "hooks/usePermission";
import { insertTimeToDate } from "utils/formatDate";

export default function AppointmentTableRow({ item, selectedTab, handleSingleView, user }) {
    const { hasPermission } = usePermission()
    const module = 'Appointment'
    const appointmentDate = item?.proposedDate || (item?.appointmentDate || item?.appointment?.date || item?.appointmentInfo?.appointmentDate)
    const appointmentStartTime = item?.proposedStartTime || (item?.startTime || item?.appointment?.startTime || item?.appointmentInfo?.startTime)
    const formattedDate = dayjs(insertTimeToDate(appointmentDate, appointmentStartTime)).format('MMM DD, YYYY')
    const formattedTime = dayjs(insertTimeToDate(appointmentDate, appointmentStartTime)).format('hh:mm A')
    const patient = item?.patientInfo || item?.patient
    const provider = item?.providerInfo || item?.provider

    const options = [
        {
            id: 1,
            name: 'Reschedule',
            value: 'reschedule',
            icon: <Calender fill="#748181" className="w-[16px] h-[16px] mr-[10px]" />,
            permission: hasPermission(module, 'Send reschedule request')
        },
        {
            id: 2,
            name: 'Cancel appointment',
            value: 'cancel',
            icon: <Close className="w-[16px] h-[16px] mr-[10px]" />,
            permission: hasPermission(module, 'Send cancel request')
        },
        {
            id: 3,
            name: 'View appointment',
            value: 'view',
            icon: <View className="w-[16px] h-[16px] mr-[10px]" />,
            permission: hasPermission(module, 'View')
        }
    ];

    return (
        <tr
            data-testid={item?._id}
            onClick={selectedTab?.includes('requests') ? () => handleSingleView(item, 'view') : null}
            className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
        >
            <td className="px-[10px] py-[8px] flex items-center">
                <Avatar
                    url={patient?.profilePic}
                    width="40px"
                    height="40px"
                    alt={(patient?.firstName)?.split('')[0]}
                    initials={userInitals(patient?.firstName, patient?.lastName)}
                    className="bg-brand_secondary mr-[15px] w-[40px] h-[40px]"
                />
                <div>
                    <p className="text-neutral_black text-14 font-campton_m capitalize">
                        {patient?.firstName} {patient?.lastName}
                    </p>
                    <p className=" text-neutral_body text-12 font-campton_r">
                        {patient?.emailAddress}
                    </p>
                </div>
            </td>
            <td className="px-[10px] py-[8px] capitalize text-14">{item?._id}</td>
            <td className="px-[10px] py-[8px] capitalize text-14">
                {item?.type || item?.appointment?.type || item?.appointmentInfo?.type}
            </td>
            <td className="px-[10px] py-[8px] capitalize whitespace-nowrap">{formattedDate} {formattedTime}</td>
            <td className={`${user?.businessName ? '' : 'hidden'} px-[10px] py-[8px] capitalize `}>
                <p
                    title={`${(provider?.firstName)} ${(provider?.lastName)}`}
                    className="max-w-[200px] truncate">{(provider?.title) === 'Doctor'
                        ? 'Dr.'
                        : provider?.title || ''}
                    {' ' +
                        (provider?.firstName) +
                        ' ' +
                        (provider?.lastName)}
                </p>
            </td>
            <td className="px-[10px] py-[8px] capitalize">
                <Status status={item.status === 'awaitingApproval' ? 'awaitingapproval' : item.status} />
            </td>
            {selectedTab.includes('requests') ? (
                <td className="px-[10px] py-[8px] capitalize">
                    <ArrowRight fill="#A9B2B2" />
                </td>
            ) : (
                <td className="px-[10px] py-[8px] capitalize">
                    <MoreOptions position="right-[-10px] w-60" icon={Dot}>
                        <>
                            {options?.filter(item => item?.permission)?.map((option) => (
                                <button
                                    key={option?.id}
                                    type="button"
                                    data-testid={option?.value}
                                    onClick={() => handleSingleView(item, option.value)}
                                    className={`w-full
                                    cursor-pointer flex items-center first-of-type:mt-0 mt-2 py-[9.5px] hover:bg-[#dfe2e23b]
                                    text-neutral_body hover:text-neutral_black hover:pl-[8px] rounded-[50px]
                                    ${item.status === 'cancelled' && option.value !== 'view' ? 'hidden' : ''}`}
                                >
                                    <p className="flex items-center">
                                        {option.icon}
                                        <span className="inline-block">{option?.name}</span>
                                    </p>
                                </button>
                            ))}
                        </>
                    </MoreOptions>
                </td>
            )}
        </tr>
    )
}

AppointmentTableRow.propTypes = {
    item: PropTypes.object,
    handleSingleView: PropTypes.func,
    user: PropTypes.object,
    selectedTab: PropTypes.string
};