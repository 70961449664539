import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import Avatar from './Avatar/Avatar';
import { Search } from './Inputs';

export default function AssignedUsers({ items }) {
  const [showList, setShowList] = useState(false);
  const [displayedUsers, setDisplayedUsers] = useState(items);
  const [search, setSearch] = useState('');

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);

    if (searchTerm) {
      const newData = displayedUsers?.filter(
        (val) =>
          val?.firstName?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
          val?.lastName?.toLowerCase()?.includes(searchTerm.toLowerCase()),
      );
      setDisplayedUsers(newData);
    } else {
      setDisplayedUsers(items);
    }
  };

  return (
    <div className="text-14 font-campton_r text-neutral_black">
      <div>
        <div
          data-testid="assigned-users-id"
          className="flex items-center justify-between mb-[8px] cursor-pointer hide_tap"
          onClick={() => setShowList(!showList)}
        >
          <p className="text-14 text-neutral_black font-campton_m">Assigned Users</p>
          {showList ? <ArrowUp fill="#505959" /> : <ArrowDown fill="#505959" />}
        </div>
        <AnimatePresence>
          {showList && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto', transition: { type: 'spring', duration: 0.3 } }}
              exit={{ opacity: 0, height: 0, transition: { duration: 0.2 } }}
            >
              <Search
                id="search"
                name="search"
                placeholder="Search by user name..."
                value={search}
                onChange={handleSearch}
              />
              <div className="pt-0 pb-[16px] grid">
                {displayedUsers?.map((user) => (
                  <div
                    key={user.GPINumber}
                    data-testid={user?.emailAddress}
                    className="flex items-center pt-[12px] pb-[12px] border-b border-b-neutral_stroke_2"
                  >
                    <Avatar
                      url={user?.logoUrl}
                      width="32px"
                      height="32px"
                      alt={user?.firstName?.split('')[0]}
                      initials={`${user?.firstName?.split('')[0] || ''}${user?.lastName?.split('')[0] || ''}`}
                      className="w-[32px] h-[32px]"
                    />
                    <div className="ml-[8px]">
                      <span className="flex text-neutral_black text-14 font-normal">
                        {user?.firstName} {user?.lastName}
                      </span>
                      <span className="text-neutral_body text-12 font-normal">{user?.emailAddress}</span>
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

AssignedUsers.propTypes = {
  items: PropTypes.array,
};
