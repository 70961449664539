import React, { useEffect, useMemo, useState } from 'react';
import Medications from 'pages/Clinicals/Patients/AllPatients/PatientDetails/Components/Medications';
import useMedications from 'hooks/fetch/useMedications';
import { useParams } from 'react-router-dom';
import TreatmentPlanView from 'components/Visit/TreatmentPlanView';
import usePatient from 'hooks/fetch/usePatient';
import { useAuthState } from 'store/modules/auth';
import Procedure from 'components/Visit/Tabs/TreatmentPlan/Procedures';
import Referrals from 'components/Visit/Tabs/TreatmentPlan/Referrals';
import Therapies from 'components/Visit/Tabs/TreatmentPlan/Therapies';
import PropTypes from 'prop-types';
import { PatientContext } from 'context';

const TreatmentPlan = ({patient}) => {
  //Hooks
  const {get_medications, loading} = useMedications()
  const { institutionId } = useAuthState()
  const { get_procedure, get_referrals, get_therapies } = usePatient();
  const {id} = useParams()

  const pathname = window.location.pathname;
  const page = pathname.includes('clinicals/patient') ? 'patient' : 'visit'

  //Local State
  const [view, setView] = useState('');
  const views = {
    'Medications': <Medications callback={() => setView('')} view={view} />,
    'Procedure': <Procedure callback={() => setView('')} />,
    'Therapies': <Therapies callback={() => setView('')} />,
    'Referrals': <Referrals callback={() => setView('')} />
  }
  const contextValues = useMemo(() => {
    return { patient }
  }, [patient])

   useEffect(() => {
    const params = {
      type: page,
      [page === 'patient' ? 'patientId' : 'visitId']: patient?.visit ?? id,
    }
    get_medications(params);
    get_procedure({ ...params, institutionId });
    get_referrals({ ...params, institutionId });
    get_therapies({ ...params, institutionId });
  }, []);

  const View = views[view];

  return (
    <PatientContext.Provider value={contextValues}>
      <TreatmentPlanView loading={loading} view={view} setView={setView} View={View}/>
     </PatientContext.Provider>

  );
};

export default TreatmentPlan;
TreatmentPlan.propTypes = {
  patient: PropTypes.object
}

