
const base = '/payments'
const base2 ='/institution'

export const payments = {
    get_all_banks: 'https://nigerianbanks.xyz',
    resolve_account: `${base}/resolve-account`,
    add_bank_info: `${base2}/add-bank-information`,
    get_billing_history: `${base2}/billing-history`,
    withdraw_funds: `${base}/withdraw-fund`,
    get_revenue: `${base2}/revenue`,
    get_wallet_transactions: `${base}/transactions`,
    payments: `${base}`,
    confirm_payment: `${base}/confirm-payment`
}