import axios from 'axios';
import NProgress from 'nprogress';
import { getUser, updateUser } from "./token.service.js"

const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	timeout: 60000 * 5, // 5 minutes
	crossdomain: true
});

let isRefreshing = false;
let failedRequests = [];

const processFailedRequests = (error, token = null) => {
	failedRequests.forEach((prom) => {
		if (error) {
			prom.reject(error);
		} else {
			if (token) prom.config.headers['Authorization'] = `Bearer ${token}`;
			prom.resolve(instance(prom.config));
		}
	});
	failedRequests = [];
};

instance.interceptors.request.use((config) => {
	config.headers["Authorization"] = `Bearer ${getUser()?.authToken}`;
	config.headers["ngrok-skip-browser-warning"] = 'true'
	NProgress.start();
	return config;
});

instance.interceptors.response.use(
	(response) => {
		NProgress.done();
		return response;
	},
	async (error) => {
		const originalRequest = error.config;
		// Check if the error is due to an expired token
		if (originalRequest?.url !== "/login" && ['Token expired'].includes(error.response?.data?.message) && !originalRequest._retry) {
			if (!isRefreshing) {
				isRefreshing = true;
				try {
					const res = await updateUser();
					const newToken = res?.data?.token;
					instance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
					originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
					processFailedRequests(null, newToken);
					originalRequest._retry = true;
					return instance(originalRequest);
				} catch (err) {
					isRefreshing = false;
					processFailedRequests(err);
					return Promise.reject(err);
				} finally {
					isRefreshing = false;
				}
			} else {
				return new Promise((resolve, reject) => {
					failedRequests.push({ resolve, reject, config: originalRequest });
				});
			}
		}

		NProgress.done();
		return error;
	}
);

export default instance;
