/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types'

export default function ViewProfileImage({ imageUrl, onClick }) {
    return (
        <div
            data-testid="larger-avatar"
            onClick={onClick}
            className={`flex justify-center items-center w-full h-full z-[500] bg-[#17171761] absolute top-0 left-0 transition ease-in-out`}
        >
            <img
                src={imageUrl}
                width={300}
                height={300}
                alt="profile-image"
                className={`rounded-full w-[300px] h-[300px] bg-brand_primary object-cover object-top`}
            />
        </div>
    );
}

ViewProfileImage.propTypes = {
    imageUrl: PropTypes.string,
    onClick: PropTypes.func
}