import Navbar from 'components/Navbar';
import SubNavbar from 'components/SubNavbar';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as SpecialtyIcon } from 'assets/icons/specialty.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit1.svg';
import { ReactComponent as Remove } from 'assets/icons/remove-red.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import Table from 'components/Table';
import { staff_list_header } from 'mocks/settings/specialty';
import useTable from 'hooks/useTable';
import { ReactComponent as Close } from 'assets/icons/close-circle.svg';
import Avatar from 'components/Avatar/Avatar';
import { userInitals } from 'utils/ImagePlaceholder';
import Delete from 'components/Forms/Delete';
import AddSpecialty from 'pages/Onboarding/Components/Forms/AddSpecialty';
import useSpecialty from 'hooks/fetch/useSpecialty';
import { useAuthState } from 'store/modules/auth';
import dayjs from 'dayjs';
import useDebounce from 'hooks/useDebounce';
import { useSpecialtyState } from 'store/modules/specialty';
import Filter from './Components/StaffFilter';
import Restricted from 'HOC/Restricted';

const ViewSingleSpecialty = () => {
  const [staffId, setStaffId] = useState('');
  const [type, setType] = useState('');
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const name = searchParams.get('name');
  const navigate = useNavigate();
  const { institutionId } = useAuthState();
  const { staffs, specialty } = useSpecialtyState();
  const { get_Specialty_Staff, loading, edit_Specialty, get_Single_Specialty } = useSpecialty();
  const { selectLimit, handleSearch, changePage, params, openFilterModal, closeFilterModal, filterModal } = useTable();
  const { debouncedValue } = useDebounce(params.search);

  const showRemoveStaffModal = (id) => {
    setType('remove');
    setStaffId(id);
  };

  const newParams = {
    ...params,
    specialty: name,
  };

  const fetchStaffs = () => {
    get_Specialty_Staff(`${institutionId}/providers`, newParams)
  };

  const getSingleSpecialty = () => {
    get_Single_Specialty(`${institutionId}/specialty/${id}`).then((res) => {
      if (res?.code === 200) {
        setSearchParams({ name: res?.data?.name });
      }
    });
  };

  const deleteSpecialtycallback = () => {
    fetchStaffs();
    navigate('/settings/specialty');
  };

  const callback = () => {
    setType('');
    getSingleSpecialty();
  };

  const removeStaffcallback = () => {
    setType('');
    fetchStaffs();
  };

  const deleteSpecialty = () => {
    edit_Specialty(`${institutionId}/delete-specialty/${id}`, deleteSpecialtycallback);
  };

  const removeStaff = () => {
    edit_Specialty(`${institutionId}/specialty/${id}/staff/${staffId}`, removeStaffcallback);
  };

  useEffect(() => {
    getSingleSpecialty();
  }, []);

  useEffect(() => {
    fetchStaffs();
  }, [debouncedValue, newParams.page, newParams.limit, name]);

  return (
    <div>
      <Navbar title="Specialty/Department" />
      <SubNavbar current={specialty?.name} previous="Specialty" onClick={() => navigate('/settings/specialty')} />
      <div className="p-6">
        <div className="bg-brand_primary p-4 rounded-2xl">
          <div className="flex justify-between">
            <div className="bg-[#EFF7FB1F] rounded-full h-10 w-10 flex justify-center items-center">
              <SpecialtyIcon fill="#ffff" />
            </div>
            <div className="flex space-x-2">
              <Restricted module='Specialty' permission='Edit'>
                <EditIcon className="w-10 h-10 cursor-pointer" data-testid="edit" onClick={() => setType('edit')} />
              </Restricted>
              <Restricted module='Specialty' permission='Delete'>
                <DeleteIcon className="w-10 h-10 cursor-pointer" data-testid="delete" onClick={() => setType('delete')} />
              </Restricted>
            </div>
          </div>
          <div className="mt-2.5">
            <p className="font-campton_m text-neutral_white capitalize">{specialty?.name}</p>
            <p className="font-campton_r text-12 text-[#C7E5EF] mt-1">{specialty?.description}</p>
          </div>
          <div className="border-t border-[#ECEEEE1F] pt-4 mt-4 grid lg:grid-cols-3 grid-cols-1 gap-6">
            <div className="border-r border-[#ECEEEE1F]">
              <p className="text-[#C7E5EF] text-12 font-campton_r">Date Created</p>
              <p className="text-neutral_white font-campton_r text-14">
                {' '}
                {dayjs(specialty?.createdAt).format('DD-MMM-YYYY')}
              </p>
            </div>
            <div className="border-r border-[#ECEEEE1F]">
              <p className="text-[#C7E5EF] text-12 font-campton_r">Time Created</p>
              <p className="text-neutral_white font-campton_r text-14">
                {dayjs(specialty?.createdAt).format('hh:mm A')}
              </p>
            </div>
            <div className="">
              <p className="text-[#C7E5EF] text-12 font-campton_r">No of Staff</p>
              <p className="text-neutral_white font-campton_r text-14">{staffs?.total} Staff(s)</p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-6 mt-4 border-t border-neutral_stroke_2">
        <Table
          title="Staff List"
          headers={staff_list_header}
          data={staffs?.providers}
          totalCount={4}
          handleSearch={handleSearch}
          selectLimit={selectLimit}
          loading={loading}
          perPage={10}
          searchPlaceholder="Search by staff name..."
          filterFnc={openFilterModal}
          //buttonName="Add Staff"
          changePage={changePage}
          currentPage={1}
          emptyStateTitle="No Staff Available."
          module="Specialty"
          permission="Add staff"
        >
          <>
            {staffs?.providers?.map((item) => (
              <tr
                data-testid={item?._id}
                key={item?.firstName}
                className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1"
              >
                <td className="px-[10px] py-[8px] flex space-x-2 items-center">
                  <Avatar
                    initials={userInitals(item?.firstName, item?.lastName)}
                    width="32px"
                    url={item?.profilePic}
                    height="32px"
                    // alt={item?.name?.split('')[0]}
                    className="w-[32px] h-[32px] shrink-0 mx-[8px]"
                  />{' '}
                  <div>
                    <p className="truncate text-14 text-neutral_black font-campton_r">
                      {item?.title} {item?.firstName} {item?.lastName}
                    </p>
                    <p className="text-12 text-neutral_body font-campton_r">{item.emailAddress}</p>
                  </div>
                </td>
                <td className="px-[10px] py-[8px] capitalize text-14">{item?.GPINumber}</td>
                <td className="px-[10px] py-[8px] capitalize md:w-52 w-20">
                  <p className="card-description">{item?.locationName}</p>
                </td>
                <td
                  className="px-[10px] py-[8px] capitalize cursor-pointer"
                  onClick={() => showRemoveStaffModal(item._id)}
                  data-testid="remove"
                >
                  <Close className="w-4 h-4" />
                </td>
              </tr>
            ))}
          </>
        </Table>
      </div>
      {type && type !== "edit" &&
        <Delete
          title={type === 'remove' ? 'Remove Staff?' : 'Delete Specialty/Department?'}
          description={
            type === 'remove'
              ? 'Do you want to remove this staff?'
              // eslint-disable-next-line max-len
              : `The ${specialty?.name} specialty/department ${staffs?.providers?.length ? 'can’t' : 'can'} be deleted as there are currently ${staffs?.providers?.length ? '' : 'no'} staff assigned.`
          }
          cancel={() => setType('')}
          disabled={type === 'delete' && !!staffs?.providers?.length}
          icon={type === 'remove' && <Remove />}
          buttonName={type}
          loading={loading}
          deleteFnc={type === 'delete' ? deleteSpecialty : removeStaff}
        />
      }
      {type === 'edit' &&
        <AddSpecialty
          title='Edit Specialty/Department'
          callback={callback}
          action="edit"
          specialty={specialty}
          closeModal={() => setType('')}
        />
      }
      {filterModal && (
        <Filter
          closeModal={closeFilterModal}
          defaultParams={newParams}
          loading={loading}
        />
      )}
    </div>
  );
};

export default ViewSingleSpecialty;
