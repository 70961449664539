import FormWrapper from 'components/FormWrapper';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import useVisit from 'hooks/fetch/useVisit';
import Dropdown from 'components/Dropdown';
import { bloodTypes, genotypes } from 'mocks/clinicals/visits';
import { PatientContext } from 'context';

const Form = ({ callback }) => {
  const [bloodType, setBloodType] = useState();
  const { patient, setPatient } = useContext(PatientContext)
  const [genotype, setGenotype] = useState();
  const { add_medical_record, loading } = useVisit();
  const [formData, setFormData] = useState({
    bloodType: '',
    genotype: '',
  });

  const addMedicalRecord = () => {
    add_medical_record('bloodType', patient?._id, formData)
      .then(res => {
        if ([201, 200]?.includes(res?.code)) {
          setPatient(res?.data)
          callback()
        }
      })
  };

  return (
    <div className="w-full lg:w-[730px] lg:h-[400px]">
      <FormWrapper className="h-full" onSubmit={addMedicalRecord}>
        <div className="flex justify-between flex-col h-full">
          <div className="grid gap-6 grid-cols-12 mt-6">
            <Dropdown
              type="select"
              id="bloodGroup"
              placeholder="Blood Group"
              name="bloodGroup"
              variant="lg:col-span-6 col-span-12"
              width="mb-6"
              options={bloodTypes}
              selected={bloodType}
              onSelect={(data) => {
                setBloodType(data.name);
                setFormData({ ...formData, bloodType: data.value });
              }}
            />
            <Dropdown
              type="select"
              id="genotype"
              placeholder="Genotype"
              variant="lg:col-span-6 col-span-12"
              name="genotype"
              width="mb-6"
              options={genotypes}
              selected={genotype}
              onSelect={(data) => {
                setGenotype(data.name);
                setFormData({ ...formData, genotype: data.value });
              }}
            />
          </div>
          <div className="flex justify-end">
            <Button name="Save" theme="blue" className="w-28 h-10" disabled={!genotype || !bloodType} loading={loading} />
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default Form;
Form.propTypes = {
  callback: PropTypes.func,
  patientId: PropTypes.string
};
