import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/Buttons';
import { Overview as Card } from 'components/Cards';
import { Input } from 'components/Inputs';
import dayjs from 'dayjs';
import { usePaymentState } from 'store/modules/payment';
import { formatCurrency } from 'utils/formatCurrency';

export default function WithdrawFunds({ withdrawalAmount, setWithdrawalAmount, setConfirmModal }) {
  const { walletTransactions } = usePaymentState()
  const disabled = !withdrawalAmount || withdrawalAmount > walletTransactions?.walletBalance

  const handleChange = (e) => {
    if (isNaN(e.target.value)) return
    setWithdrawalAmount(e.target.value)
  }

  return (
    <div className="flex flex-col justify-between h-full bg-neutral_white">
      <div>
        <Card
          icon={
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" rx="20" fill="#36A1C5" fillOpacity="0.1" />
              <path
                d="M28.25 14.75H13.25C13.0511 14.75 12.8603 14.671 12.7197 14.5303C12.579 14.3897 12.5 14.1989 12.5 14C12.5 13.8011 12.579 13.6103 12.7197 13.4697C12.8603 13.329 13.0511 13.25 13.25 13.25H26C26.1989 13.25 26.3897 13.171 26.5303 13.0303C26.671 12.8897 26.75 12.6989 26.75 12.5C26.75 12.3011 26.671 12.1103 26.5303 11.9697C26.3897 11.829 26.1989 11.75 26 11.75H13.25C12.6533 11.75 12.081 11.9871 11.659 12.409C11.2371 12.831 11 13.4033 11 14V26C11 26.5967 11.2371 27.169 11.659 27.591C12.081 28.0129 12.6533 28.25 13.25 28.25H28.25C28.6478 28.25 29.0294 28.092 29.3107 27.8107C29.592 27.5294 29.75 27.1478 29.75 26.75V16.25C29.75 15.8522 29.592 15.4706 29.3107 15.1893C29.0294 14.908 28.6478 14.75 28.25 14.75ZM28.25 26.75H13.25C13.0511 26.75 12.8603 26.671 12.7197 26.5303C12.579 26.3897 12.5 26.1989 12.5 26V16.1216C12.7408 16.2069 12.9945 16.2504 13.25 16.25H28.25V26.75ZM23.75 21.125C23.75 20.9025 23.816 20.685 23.9396 20.5C24.0632 20.315 24.2389 20.1708 24.4445 20.0856C24.65 20.0005 24.8762 19.9782 25.0945 20.0216C25.3127 20.065 25.5132 20.1722 25.6705 20.3295C25.8278 20.4868 25.935 20.6873 25.9784 20.9055C26.0218 21.1238 25.9995 21.35 25.9144 21.5555C25.8292 21.7611 25.685 21.9368 25.5 22.0604C25.315 22.184 25.0975 22.25 24.875 22.25C24.5766 22.25 24.2905 22.1315 24.0795 21.9205C23.8685 21.7095 23.75 21.4234 23.75 21.125Z"
                fill="#36A1C5"
              />
            </svg>
          }
          title="Wallet Balance"
          total={formatCurrency(walletTransactions?.walletBalance)}
          textColor="text-neutral_black"
          className="bg-primary_tint_50 mb-[24px]"
        />
        <div className="mb-[24px]">
          <Input
            label="Amount"
            type="text"
            id="amount"
            name="amount"
            inputMode="numeric"
            //readOnly={loading}
            value={withdrawalAmount}
            measurement="NGN"
            onChange={handleChange}
          />
        </div>
        <div className="mb-[24px]">
          <Input
            label="Date"
            type="text"
            id="date"
            name="date"
            //readOnly={loading}
            value={dayjs(new Date()).format('DD MMM, YYYY')}
            disabled={true}
          />
        </div>
      </div>
      <div className="flex justify-end py-[20px]">
        <Button
          name="Proceed"
          theme="blue"
          className="h-[40px] text-16 px-[12px] w-[104px]"
          onClick={() => setConfirmModal(true)}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

WithdrawFunds.propTypes = {
  setConfirmModal: PropTypes.func,
  withdrawalAmount: PropTypes.string,
  setWithdrawalAmount: PropTypes.func
};
