import { institution } from 'services/endpoints/institution'
import { createCustomAsyncThunk } from 'utils/createThunk'

const getLocations = createCustomAsyncThunk('locations/getLocation', 'get', institution.locations)
const getLocationDetails = createCustomAsyncThunk('locations/getLocationDetails', 'get', institution.institution)
const updateLocation = createCustomAsyncThunk('locations/updateLocation', 'patch', institution.institution)

export {
    getLocations,
    getLocationDetails,
    updateLocation
}