import { Overview } from 'components/Cards';
import React from 'react';
import { ReactComponent as Wallet } from 'assets/icons/wallet.svg';
import { useAppointmentState } from 'store/modules/appointment';

const SummaryCards = () => {
  const { appointments } = useAppointmentState();

  return (
    <div className="flex p-6 border-b border-neutral_stroke_2 overflow-x-scroll space-x-6 ">
      <Overview
        title="Total Appointment"
        className="bg-brand_primary whitespace-nowrap"
        textColor="text-neutral_white"
        total={appointments?.allCount || 0}
        icon={<Wallet />}
      />
      <div className={`rounded-[16px] flex justify-between p-[24px] text-neutral_black border border-neutral_stroke_2`}>
        <div className="min-w-[180px] border-r border-r-neutral_stroke_2">
          <p className="text-14 font-campton_r mb-[8px]">Upcoming</p>
          <p className="md:text-24 text-base font-campton_sb">{appointments?.upcomingCount || 0}</p>
        </div>
        <div className="min-w-[180px] border-r border-r-neutral_stroke_2 pl-[32px]">
          <p className="text-14 font-campton_r mb-[8px]">Ongoing</p>
          <p className="md:text-24 text-base font-campton_sb">{appointments?.ongoingCount || 0}</p>
        </div>
        <div className="min-w-[180px] border-r border-r-neutral_stroke_2 pl-[32px]">
          <p className="text-14 font-campton_r mb-[8px]">No Show</p>
          <p className="md:text-24 text-base font-campton_sb">{appointments?.noShowCount || 0}</p>
        </div>
        <div className="min-w-[180px] border-r border-r-neutral_stroke_2 pl-[32px]">
          <p className="text-14 font-campton_r mb-[8px]">Cancelled</p>
          <p className="md:text-24 text-base font-campton_sb">{appointments?.cancelledCount || 0}</p>
        </div>
        <div className="min-w-[180px] pl-[32px]">
          <p className="text-14 font-campton_r mb-[8px]">Completed</p>
          <p className="md:text-24 text-base font-campton_sb">{appointments?.completedCount || 0}</p>
        </div>
      </div>
    </div>
  );
};

export default SummaryCards;
