import React from 'react';
import { ReactComponent as Calender } from 'assets/icons/calendar-icon.svg';
import { ReactComponent as Close } from 'assets/icons/close-circle.svg';

export const appointment_list_header = ['Patient Name', 'ID', 'Type', 'Date/Time', 'Provider name', 'Status', ''];
export const checkTimeFormat = /^\d{2}:\d{2} [AP]M$/
export const appointment_list = [
  {
    _id: '0018720',
    firstName: 'Barakat',
    lastName: 'Salam',
    gNumber: 'G-567865',
    emailAddress: 'barakatsalam@reddington.ng',
    date: 'MAY 27, 2023, 09:50 AM',
    type: 'On-site',
    provider: {
      firstName: 'John',
      lastName: 'Alao',
      emailAddress: 'johnalao@reddington.ng',
      specialty: 'Cadiologist',
      gNumber: 'GPI-609298',
      title: 'Doctor',
    },
    status: 'Rescheduled',
  },
  {
    _id: '0018721',
    firstName: 'Barakat',
    lastName: 'Salam',
    gNumber: 'G-567865',
    type: 'On-site',
    emailAddress: 'barakatsalam@reddington.ng',
    date: 'MAY 27, 2023, 09:50 AM',
    provider: {
      firstName: 'John',
      gNumber: 'GPI-609298',
      lastName: 'Alao',
      emailAddress: 'johnalao@reddington.ng',
      specialty: 'Cadiologist',
      title: 'Nurse',
    },
    status: 'Scheduled',
  },
  {
    _id: '0018722',
    firstName: 'Barakat',
    gNumber: 'G-567865',
    type: 'Telehealth',
    lastName: 'Salam',
    emailAddress: 'barakatsalam@reddington.ng',
    date: 'MAY 27, 2023, 09:50 AM',
    provider: {
      gNumber: 'GPI-609298',
      firstName: 'John',
      lastName: 'Alao',
      emailAddress: 'johnalao@reddington.ng',
      specialty: 'Cadiologist',
      title: 'Doctor',
    },
    status: 'Ongoing',
  },
  {
    type: 'Telehealth',
    _id: '0018723',
    firstName: 'Barakat',
    lastName: 'Salam',
    gNumber: 'G-567865',
    emailAddress: 'barakatsalam@reddington.ng',
    date: 'MAY 27, 2023, 09:50 AM',
    provider: {
      gNumber: 'GPI-609298',
      firstName: 'John',
      lastName: 'Alao',
      emailAddress: 'johnalao@reddington.ng',
      specialty: 'Cadiologist',
      title: 'Doctor',
    },
    status: 'Completed',
  },
  {
    _id: '0018724',
    type: 'Telehealth',
    firstName: 'Barakat',
    gNumber: 'G-567865',
    lastName: 'Salam',
    emailAddress: 'barakatsalam@reddington.ng',
    date: 'MAY 27, 2023, 09:50 AM',
    provider: {
      gNumber: 'GPI-609298',
      firstName: 'John',
      lastName: 'Alao',
      emailAddress: 'johnalao@reddington.ng',
      specialty: 'Cadiologist',
      title: 'Doctor',
    },
    status: 'Cancelled',
  },
];
export const appointments = [
  {
    _id: '64dc4baa703fb0d2deee44ed',
    type: 'onSite',
    priority: 'routine',
    appointmentDate: '2023-08-17T00:00:00.000Z',
    startTime: 'Thu Aug 26 2023 04:00:00 GMT+0000 (Coordinated Universal Time)',
    endTime: 'Thu Aug 17 2023 06:00:00 GMT+0000 (Coordinated Universal Time)',
    note: '',
    status: 'upcoming',
    createdAt: '2023-08-16T04:08:10.318Z',
    updatedAt: '2023-08-16T04:58:26.382Z',
    patientInfo: {
      id: '645a78187ce4fc17f2cf5553',
      firstName: 'Pat',
      lastName: 'Doe',
      emailAddress: 'barakat@mailinator.com',
      gNumber: 'G-00581261',
    },
    providerInfo: {
      id: '64b91bdaac4412e615dd705f',
      firstName: 'Gee',
      lastName: 'Akinpelu',
      emailAddress: 'godson@mailinator.com',
      GPINumber: 'GPI-00898348',
      qualification: 'MBA',
      specialty: 'Mid-wife',
      title: 'Dr',
    },
    institutionInfo: {
      id: '64a2c1c45859654c64cac646',
      businessName: 'enyata hospital',
    },
  },
  {
    _id: '64dc4b62703fb0d2deee43ad',
    type: 'inApp',
    priority: 'urgent',
    appointmentDate: '2023-08-22T00:00:00.000Z',
    startTime: 'Tue Aug 22 2023 06:00:00 GMT+0000 (Coordinated Universal Time)',
    endTime: 'Tue Aug 22 2023 06:30:00 GMT+0000 (Coordinated Universal Time)',
    note: '',
    status: 'cancelled',
    createdAt: '2023-08-16T04:06:58.462Z',
    updatedAt: '2023-08-16T05:08:07.710Z',
    patientInfo: {
      id: '645a78187ce4fc17f2cf5553',
      firstName: 'Pat',
      lastName: 'Doe',
      emailAddress: 'barakat@mailinator.com',
      gNumber: 'G-00581261',
    },
    providerInfo: {
      id: '64b91bdaac4412e615dd705f',
      firstName: 'Gee',
      lastName: 'Akinpelu',
      emailAddress: 'godson@mailinator.com',
      GPINumber: 'GPI-00898348',
      qualification: 'MBA',
      specialty: 'Mid-wife',
      title: 'Dr',
    },
    institutionInfo: {
      id: '64a2c1c45859654c64cac646',
      businessName: 'enyata hospital',
    },
  },
  {
    _id: '64dc4b0b703fb0d2deee423e',
    type: 'onSite',
    priority: 'routine',
    appointmentDate: '2023-08-29T00:00:00.000Z',
    startTime: 'Tue Aug 29 2023 08:00:00 GMT+0000 (Coordinated Universal Time)',
    endTime: 'Tue Aug 29 2023 08:30:00 GMT+0000 (Coordinated Universal Time)',
    note: '',
    status: 'cancelled',
    createdAt: '2023-08-16T04:05:31.941Z',
    updatedAt: '2023-08-16T05:08:55.585Z',
    patientInfo: {
      id: '645a760bc793206747cd848a',
      firstName: 'Pat',
      lastName: 'Test',
      emailAddress: 'pat100@yopmail.com',
      gNumber: 'G-00690176',
    },
    providerInfo: {
      id: '64b91bdaac4412e615dd705f',
      firstName: 'Gee',
      lastName: 'Akinpelu',
      emailAddress: 'godson@mailinator.com',
      GPINumber: 'GPI-00898348',
      qualification: 'MBA',
      specialty: 'Mid-wife',
      title: 'Dr',
    },
    institutionInfo: {
      id: '64a2c1c45859654c64cac646',
      businessName: 'enyata hospital',
    },
  },
  {
    _id: '64dc49ab703fb0d2deee4105',
    type: 'inApp',
    priority: 'urgent',
    appointmentDate: '2023-12-03T00:00:00.000Z',
    startTime: '08:00 AM',
    endTime: '09:00 AM',
    note: 'Nothing',
    status: 'upcoming',
    createdAt: '2023-08-16T03:59:39.183Z',
    updatedAt: '2023-08-16T05:44:52.042Z',
    patientInfo: {
      id: '645a6be0c793206747cd8472',
      firstName: 'Pat',
      lastName: 'Doe',
      emailAddress: 'pat@mailinator.com',
      gNumber: 'G-00029659',
    },
    providerInfo: {
      id: '64b91bdaac4412e615dd705f',
      firstName: 'Gee',
      lastName: 'Akinpelu',
      emailAddress: 'godson@mailinator.com',
      GPINumber: 'GPI-00898348',
      qualification: 'MBA',
      specialty: 'Mid-wife',
      title: 'Dr',
    },
    institutionInfo: {
      id: '64a2c1c45859654c64cac646',
      businessName: 'enyata hospital',
    },
  },
  {
    _id: '64db9228aa69dbefce16ff05',
    type: 'onSite',
    priority: 'routine',
    appointmentDate: '2023-10-05T00:00:00.000Z',
    startTime: 'Thu Oct 05 2023 06:30:00 GMT+0000 (Coordinated Universal Time)',
    endTime: 'Thu Oct 05 2023 08:00:00 GMT+0000 (Coordinated Universal Time)',
    note: '',
    status: 'cancelled',
    createdAt: '2023-08-15T14:56:40.035Z',
    updatedAt: '2023-08-16T03:13:17.574Z',
    patientInfo: {
      id: '645a7a3651e876fc795859ed',
      firstName: 'Ossy',
      lastName: 'Doe',
      emailAddress: 'ossy@mailinator.com',
      gNumber: 'G-00982362',
    },
    providerInfo: {
      id: '64b91bdaac4412e615dd705f',
      firstName: 'Gee',
      lastName: 'Akinpelu',
      emailAddress: 'godson@mailinator.com',
      GPINumber: 'GPI-00898348',
      qualification: 'MBA',
      specialty: 'Mid-wife',
      title: 'Dr',
    },
    institutionInfo: {
      id: '64a2c1c45859654c64cac646',
      businessName: 'enyata hospital',
    },
  },
];
export const rescheduleRequests = [
  {
    _id: '64dc6005703fb0d2deee599d',
    proposedDate: '2023-12-03T00:00:00.000Z',
    proposedStartTime: '08:00 AM',
    proposedEndTime: '09:00 AM',
    reason: 'On Leave',
    status: 'approved',
    createdAt: '2023-08-16T05:35:01.015Z',
    updatedAt: '2023-08-16T05:44:52.030Z',
    appointmentInfo: {
      id: '64dc49ab703fb0d2deee4105',
      type: 'inApp',
      appointmentDate: '2023-12-03T00:00:00.000Z',
      endTime: '09:00 AM',
      startTime: '08:00 AM',
      note: 'Nothing',
      status: 'upcoming',
      priority: 'urgent',
    },
    providerInfo: {
      id: '64b91bdaac4412e615dd705f',
      firstName: 'Gee',
      lastName: 'Akinpelu',
      emailAddress: 'godson@mailinator.com',
      GPINumber: 'GPI-00898348',
      qualification: 'MBA',
      specialty: 'Mid-wife',
      title: 'Dr',
    },
    patientInfo: {
      id: '645a6be0c793206747cd8472',
      firstName: 'Pat',
      lastName: 'Doe',
      emailAddress: 'pat@mailinator.com',
      gNumber: 'G-00029659',
    },
  },
  {
    _id: '64dc6005703fb0d2deee599f',
    proposedDate: '2023-12-03T00:00:00.000Z',
    proposedStartTime: '08:00 AM',
    proposedEndTime: '09:00 AM',
    reason: 'On Leave',
    status: 'awaitingApproval',
    createdAt: '2023-08-16T05:35:01.015Z',
    updatedAt: '2023-08-16T05:44:52.030Z',
    appointmentInfo: {
      id: '64dc49ab703fb0d2deee4105',
      type: 'inApp',
      appointmentDate: '2023-12-03T00:00:00.000Z',
      endTime: '09:00 AM',
      startTime: '08:00 AM',
      note: 'Nothing',
      status: 'upcoming',
      priority: 'urgent',
    },
    providerInfo: {
      id: '64b91bdaac4412e615dd705f',
      firstName: 'Gee',
      lastName: 'Akinpelu',
      emailAddress: 'godson@mailinator.com',
      GPINumber: 'GPI-00898348',
      qualification: 'MBA',
      specialty: 'Mid-wife',
      title: 'Doctor',
    },
    patientInfo: {
      id: '645a6be0c793206747cd8472',
      firstName: 'Pat',
      lastName: 'Doe',
      emailAddress: 'pat@mailinator.com',
      gNumber: 'G-00029659',
    },
  },
];
export const cancelledRequest = [
  {
    "_id": "64dc4d1a703fb0d2deee461b",
    "appointment": {
      "appointmentId": "64dc4baa703fb0d2deee44ed",
      "type": "onSite",
      "date": "2023-08-17T00:00:00.000Z",
      "startTime": "Thu Aug 17 2023 04:00:00 GMT+0000 (Coordinated Universal Time)",
      "endTime": "Thu Aug 17 2023 06:00:00 GMT+0000 (Coordinated Universal Time)"
    },
    "provider": {
      "providerId": "64b91bdaac4412e615dd705f",
      "firstName": "Gee",
      "lastName": "Akinpelu"
    },
    "patient": {
      "patientId": "645a78187ce4fc17f2cf5553",
      "firstName": "Pat",
      "lastName": "Doe",
      "emailAddress": "barakat@mailinator.com",
      "profilePic": ""
    },
    "cancelRequestId": "CR-00732848",
    "institutionId": "64a2c1c45859654c64cac646",
    "status": "awaitingApproval",
    "createdAt": "2023-08-16T04:14:18.925Z",
    "updatedAt": "2023-08-16T04:58:26.378Z",
    "__v": 0
  }
]
export const request_options = [
  {
    id: 1,
    name: 'Approve request',
    value: 'Approve request',
    icon: <Calender fill="#748181" className="w-4" />,
  },
  {
    id: 2,
    name: 'Reject request',
    value: 'Reject request',
    icon: <Close />,
  },
];
export const cancellation_reasons = [
  {
    id: 1,
    name: 'On Leave',
    value: 'On Leave',
  },
  {
    id: 2,
    name: 'Others',
    value: 'others',
  },
];
export const providers = [
  {
    _id: 1,
    emailAddress: 'bs@enyata.com',
    firstName: 'Barakat',
    lastName: 'Salam',
    title: '',
    specialty: 'Cardiologist',
    locationName: 'ikeja',
  },
  {
    _id: 2,
    emailAddress: 'elizabethajao@reddington.ng',
    firstName: 'Elizabeth',
    lastName: 'Ajao',
    title: 'Dr',
    specialty: 'Anesthesiologist',
    locationName: 'lagos island',
  },
];
export const appointmentDetails = {
  _id: '0018720',
  firstName: 'Barakat',
  lastName: 'Salam',
  gNumber: 'G-567865',
  emailAddress: 'barakatsalam@reddington.ng',
  date: 'MAY 27, 2023, 09:50 AM',
  type: 'On-site',
  provider: {
    firstName: 'John',
    lastName: 'Alao',
    emailAddress: 'johnalao@reddington.ng',
    specialty: 'Cadiologist',
    gNumber: 'GPI-609298',
    title: 'Doctor',
  },
  status: 'Urgent',
  note: 'Lorem ipsum dolor sit amet consectetur. Id faucibus condimentum fermentum risus amet venenatis nunc. Donec feugiat urna mattis semper viverra tortor.',
};

export const patientVitals = {
  "_id": "64f1cc42fad3b0a5bc4ffde0",
  "sysBloodPressure": "2",
  "diaBloodPressure": "1",
  "heartRate": "4",
  "respiratoryRate": "8",
  "temperature": "12",
  "oxygenSaturation": "122",
  "height": "189",
  "weight": "74",
  "bmi": "0.3915343915343915",
  "patient": {
    "patientId": "6475c1d235b6c98c6fd459e9",
    "firstName": "Omotola",
    "lastName": "Ayeni",
    "emailAddress": "omotolaonifade@enyata.com",
    "gender": "Female",
    "gNumber": "G-00948721",
    "dateOfBirth": "2023-05-30T00:00:00.000Z",
    "countryCode": "+234",
    "phoneNumber": "2348160251663",
    "profilePic": ""
  },
  "visitId": "64f1cc42fad3b0a5bc4ffdde",
  "additionalNote": "test",
  "recordedBy": {
    "providerId": "64a2c1c45859654c64cac646",
    "firstName": "enyata hospital",
    "lastName": "enyata hospital"
  },
  "createdAt": "2023-09-01T11:34:26.938Z",
  "updatedAt": "2023-09-01T11:34:26.938Z",
  "__v": 0
}

export const patientHistory = {
  "_id": "647d9ef018cbec86dee51dc4",
  "firstName": "Sola",
  "lastName": "sola",
  "emailAddress": "tola12@gmail.com",
  "countryCode": "+234",
  "phoneNumber": "9099303920",
  "gender": "",
  "homeAddress": "Sola address",
  "country": "Shqipëria",
  "dateOfBirth": "1994-01-01T00:00:00.000Z",
  "gNumber": "G-00580581",
  "patientStatus": "active",
  "verificationToken": "418777",
  "verificationTokenExpiry": "2023-07-13T07:28:32+00:00",
  "refreshToken": "c5500a2bedc6fa50917f29952b32f8b3a02f2def08c22602e1166d9dc6248a45e9f342fd628a127bdbafd14a9f6e5101d696",
  "maritalStatus": "",
  "race": "",
  "ethnicity": "",
  "officeAddress": "",
  "profilePic": "",
  "createdAt": "2023-06-05T08:38:08.659Z",
  "updatedAt": "2023-07-13T06:59:16.655Z",
  "medicalRecord": {
    "_id": "647d9ef018cbec86dee51dc6",
    "patientId": "647d9ef018cbec86dee51dc4",
    "bloodType": {
      "bloodType": "O-",
      "genotype": "AA"
    },
    "allergies": [
      "Ibuprofen",
      "Penicillin"
    ],
    "lifestyleHabits": {
      "physicalActivity": {
        "frequency": "\"\"",
        "duration": "Not Often",
        "typeOfExercise": "\"\""
      },
      "sleepPattern": {
        "avgSleepHours": "8",
        "consistentSleepSchedule": "No",
        "sleepDifficulty": "No"
      },
      "dietAndNutrition": {
        "specificDietaryPlan": "\"\"",
        "fruitsAndVegetables": "\"\"",
        "processedFoods": "\"\""
      },
      "stressManagement": {
        "method": "\"\"",
        "relaxationTechniques": "\"\"",
        "specificStressors": "\"\""
      },
      "substanceUse": {
        "smoking": "\"\"",
        "alcohol": "\"\"",
        "recreationalDrugs": "\"\""
      },
      "mentalHealth": {
        "historyOfMentalConditions": "Yes",
        "emotionalHealthRating": "\"\"",
        "mentalActivities": "\"\""
      },
      "environmentalFactors": {
        "pollution": "\"\"",
        "occupationalHazards": "\"\""
      },
      "socialConnections": {
        "frequency": "\"\"",
        "supportSystem": "\"\"",
        "challenges": "\"\""
      },
      "safeSexPractises": {
        "sexuallyActive": "\"\"",
        "partner": "\"\"",
        "contraceptives": "\"\"",
        "stiTests": "\"\""
      },
      "birthControl": {
        "contraception": "\"\"",
        "satisfaction": "\"\""
      },
      "sexualDysfunction": {
        "difficulty": "\"\"",
        "specificIssues": "\"\"",
        "discussWithHealthProvider": "\"\""
      }
    },
    "emergencyContact": [
      {
        "firstName": "w",
        "lastName": "w",
        "countryCode": "+234",
        "phoneNumber": "9303930393",
        "emailAddress": "k@kd.com",
        "relationship": "broadd",
        "address": "add",
        "_id": "64a6b2807e819400eeffac59"
      }
    ],
    "insuranceInformation": [
      {
        "insuranceId": "https://photow-profile-images.s3.us-west-2.amazonaws.com/image_picker_77BA18A1-2693-4ABD-8EC8-18F788E227A9-82645-000077F7C070BE26.jpg",
        "providerName": "m",
        "policyNumber": "99",
        "groupNumber": "99",
        "plan": "kk",
        "startDate": "1994-01-01T00:00:00.000Z",
        "endDate": "1994-01-01T00:00:00.000Z",
        "_id": "64a6b1967e819400eeffab89"
      }
    ],
    "medicalDiagnosis": [
      {
        "name": "Congenital heart disease",
        "medications": [
          "m"
        ],
        "startDate": "01 - 01 -1994",
        "endDate": "01 - 01 -1994",
        "_id": "64a6b2257e819400eeffac2d"
      }
    ],
    "familyHospital": [
      {
        "name": "mmllllllll",
        "emailAddress": "mm@ll.com",
        "countryCode": "+234",
        "phoneNumber": "9098989898",
        "address": "address",
        "_id": "64a67d537e819400eeffa883"
      }
    ],
    "surgeries": [
      {
        "procedure": "Appendicitis",
        "date": "1994-01-01T00:00:00.000Z",
        "_id": "64a68f1f7e819400eeffa956"
      }
    ],
    "immunizationHistory": [],
    "familyHistory": [
      {
        "hereditaryDisease": "Cancer",
        "patientRelationship": "bro",
        "_id": "64a6c9177e819400eeffada8"
      }
    ]
  }
}
export const appointment_type = [
{
  name: 'all',
  value: ''
},
{
  name: 'On-site',
  value: 'onSite'
},
{
  name: 'Telehealth',
  value: 'inApp'
},
]
export const all_appointment_status = [
  {
    name: 'all',
    value: ''
  },
  {
    name: 'Upcoming',
    value: 'upcoming'
  },
  {
    name: 'Completed',
    value: 'completed'
  },
  {
    name: 'Ongoing',
    value: 'ongoing'
  },
  {
    name: 'Cancelled',
    value: 'cancelled'
  },
  {
    name: 'No Show',
    value: 'no-show'
  },
]
// "status must be one of [upcoming, completed, cancelled, awaitingApproval, approved, declined]"
export const appointment_request_status = [
  {
    name: 'all',
    value: ''
  },
  {
    name: 'Awaiting Approval',
    value: 'awaitingApproval'
  },
  {
    name: 'Approved',
    value: 'approved'
  },
  {
    name: 'Declined',
    value: 'declined'
  },
]