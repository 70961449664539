import React, { useContext } from 'react';
import { PatientContext } from 'context';
import { ReactComponent as EmptyStateIcon } from 'assets/svg/empty-blood-group.svg';
import EmptyState from "components/Table/EmptyState"
import PropTypes from 'prop-types';
import Layout from '../Layout';

export default function BloodType({ view, callback, setModal, visitStatus, canUpdate = true }) {
  const { patient } = useContext(PatientContext);
  const condition = !patient?.medicalRecord?.bloodType?.bloodType && !patient?.medicalRecord?.bloodType?.genotype;

  return (
    <Layout
      view={view}
      visitStatus={!visitStatus && !condition && canUpdate}
      setModal={setModal}
      callback={callback}
    >
      {condition ? (
        <EmptyState title="No Blood Type" icon={<EmptyStateIcon className="mb-[24px]" />} />
      ) : (
        <>
          <div className="flex py-[24px]">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="32" height="32" rx="16" fill="#EBF6FA" />
              <path
                d="M18.875 10.9842C18.103 10.0926 17.2341 9.28977 16.2844 8.59048C16.2003 8.53159 16.1001 8.5 15.9975 8.5C15.8949 8.5 15.7947 8.53159 15.7106 8.59048C14.7627 9.29006 13.8954 10.0929 13.125 10.9842C11.4069 12.9574 10.5 15.0374 10.5 16.9999C10.5 18.4586 11.0795 19.8575 12.1109 20.8889C13.1424 21.9204 14.5413 22.4999 16 22.4999C17.4587 22.4999 18.8576 21.9204 19.8891 20.8889C20.9205 19.8575 21.5 18.4586 21.5 16.9999C21.5 15.0374 20.5931 12.9574 18.875 10.9842ZM16 21.4999C14.8069 21.4985 13.6631 21.024 12.8195 20.1804C11.9759 19.3368 11.5013 18.1929 11.5 16.9999C11.5 13.423 14.9669 10.4374 16 9.62486C17.0331 10.4374 20.5 13.4217 20.5 16.9999C20.4987 18.1929 20.0241 19.3368 19.1805 20.1804C18.3369 21.024 17.1931 21.4985 16 21.4999ZM19.4931 17.5836C19.3635 18.3078 19.0151 18.9749 18.4948 19.495C17.9745 20.0152 17.3073 20.3635 16.5831 20.493C16.5556 20.4974 16.5278 20.4997 16.5 20.4999C16.3746 20.4998 16.2538 20.4527 16.1615 20.3677C16.0692 20.2828 16.0122 20.1662 16.0018 20.0413C15.9915 19.9163 16.0284 19.7919 16.1054 19.6929C16.1824 19.5939 16.2938 19.5275 16.4175 19.5067C17.4531 19.3324 18.3319 18.4536 18.5075 17.4161C18.5297 17.2853 18.603 17.1687 18.7112 17.0919C18.8193 17.0152 18.9536 16.9845 19.0844 17.0067C19.2152 17.0289 19.3318 17.1022 19.4085 17.2104C19.4853 17.3186 19.516 17.4528 19.4937 17.5836H19.4931Z"
                fill="#36A1C5"
              />
            </svg>
            <div className="ml-[16px]">
              <p className="text-14 text-neutral_body font-campton_r mb-[4px]">
                Blood Group:{' '}
                <span className="text-neutral_black capitalize">{patient?.medicalRecord?.bloodType?.bloodType}</span>
              </p>
              <p className="text-14 text-neutral_body font-campton_r mb-[4px]">
                Genotype:{' '}
                <span className="text-neutral_black capitalize">{patient?.medicalRecord?.bloodType?.genotype}</span>
              </p>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}

BloodType.propTypes = {
  callback: PropTypes.func,
  view: PropTypes.string,
  setModal: PropTypes.func,
  visitStatus: PropTypes.bool,
  canUpdate: PropTypes.bool
};
