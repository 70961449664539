export const formatTwelveHour = (date) => {
  if (!date) return null
  let newDate = new Date(date)
  let hours = newDate.getHours().toString().padStart(2, '0')
  let mins = newDate.getMinutes().toString().padStart(2, '0')
  let suffix = hours >= 12 ? 'PM' : 'AM'
  hours %= 12
  hours = hours ?? 12; // Convert 0 to 12 for 12hour clock
  return `${hours}:${mins} ${suffix}`
}

export const convertTimeTo12HourFormat = (time24) => {
  if (!time24) return null
  let time = time24
  if (['AM', 'PM'].includes(time.split(' ')[1])) {
    time = time.split(' ')[0]
  }
  const [hours, minutes] = time.split(":");
  let period = "AM";
  let hours12 = parseInt(hours);

  if (hours12 >= 12) {
    period = "PM"
    if (hours12 > 12) {
      hours12 -= 12
    }
  }

  if (hours12 === 0) hours12 = 12

  return `${hours12}:${minutes} ${period}`
}

export const convertTimeToDate = (timeString) => {
  if (!timeString) return null
  const [time, meridian] = timeString.split(" "); // Split the time string into time and meridian parts
  const [hours, minutes] = time.split(":"); // Split the time into hours and minutes

  // Create a new Date object and set the hours and minutes
  const date = new Date();
  date.setHours(parseInt(hours, 10) + (meridian === "PM" ? 12 : 0));
  date.setMinutes(parseInt(minutes, 10));

  return date
}

// Function to format the time in 12-hour format
export const formatDateToTime12Hour = (dateString) => {
  if (!dateString) return ""
  const date = new Date(dateString);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Pad minutes and seconds with leading zeros
  const strMinutes = String(minutes).padStart(2, '0');

  // Return formatted time
  return `${hours}:${strMinutes} ${ampm}`;
}