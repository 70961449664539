import React from "react";
import PropTypes from "prop-types"
import { ReactComponent as Check } from "assets/icons/check-box-selected.svg";
import { ReactComponent as Uncheck } from "assets/icons/check-box-unselected.svg";

export default function Checkbox({ status, label, onClick }) {
    return (
        <div className="flex">
            {status ?
                <Check data-testid="checked" onClick={onClick} className="cursor-pointer hide_tap" /> :
                <Uncheck data-testid="unchecked" onClick={onClick} className="cursor-pointer hide_tap" />
            }
            {label ? <p className="font-campton_r ml-[12px] text-neutral_body text-14">{label}</p> : ''}
        </div>
    )
}

Checkbox.propTypes = {
    status: PropTypes.bool,
    onClick: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}