import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PasswordCheck } from 'assets/icons/password-check.svg';
import { Button } from 'components/Buttons';

const Card = ({ title, description, theme, icon, variant, btnName, onClick, btnStyles, condition, type }) => {
  return (
    <div
      className={` ${variant} p-6 rounded-2xl flex flex-col justify-center items-center`}
      data-testid={`test-${title}`}
    >
      {icon}
      <div className="flex flex-col justify-between items-center">
        <div className="text-center mt-6">
          <p
            className={`${type === 'white' ? 'text-neutral_white' : 'text-primary_shade_200'} font-campton_m
              mb-1`}
          >
            {title}
          </p>
          <p
            className={`${type === 'white' ? 'text-neutral_white' : 'text-neutral_body'} font-campton_r  text-14`}
          >
            {description}
          </p>
        </div>
        {condition ? (
          <Button className="bg-[#E6F9EE] h-8 mt-6 w-fit text-14 px-3 border-0">
            <p className="font-campton_r text-[#1BA154] flex items-center">
              <span className="mr-1.5">
                <PasswordCheck />
              </span>
              Completed
            </p>
          </Button>
        ) : (
          <Button
            name={btnName}
            theme={theme}
            onClick={onClick}
            className={`${btnStyles} h-8 mt-6 w-fit text-14 px-3`}
          />
        )}
      </div>
    </div>
  );
};

export default Card;
Card.propTypes = {
  title: PropTypes.string,
  btnName: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  icon: PropTypes.any,
  theme: PropTypes.string,
  condition: PropTypes.any,
  variant: PropTypes.string,
  btnStyles: PropTypes.string,
};
