export const removeSpecificKeys = (obj, keysToRemove) => {
  if (!obj) return {}
  if (Array.isArray(obj)) {
    const array = obj
    for (const object of array) {
      for (const key of keysToRemove) {
        // Delete the key from the object
        delete object[key];
      }
    }
    return array
  }
  Object.keys(obj).forEach(key => {
    if (keysToRemove && keysToRemove.includes(key)) delete obj[key]
    if (obj[key] === "") delete obj[key]
  });
  return obj;
}