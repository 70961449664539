import { Input } from 'components/Inputs';
import Phone from 'components/Inputs/PhoneInput';
import RadioButtonSelect from 'components/RadioButtonSelect';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/Dropdown';
import { institutionTypes } from 'mocks/auth';
import Suggestion from 'components/Suggestion';
import useDebounce from 'hooks/useDebounce';
import useAuth from 'hooks/fetch/useAuth';

const IndividualProfileSetup = ({ formData, setFormData, loading, answer, setAnswer, setPhone, phone}) => {
  const [suggestions, setSuggestions] = useState([]);
  const { get_institution } = useAuth();
  const [providerType, setProviderType] = useState('');
  const { debouncedValue, isTyping } = useDebounce(formData.institution);

  const handleChange = (e) => {
    setSuggestions([])
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (debouncedValue) {
      get_institution({ search: debouncedValue }).then((res) => setSuggestions(res?.data || []));
    } else {
      get_institution();
    }
  }, [debouncedValue, isTyping]);

  return (
    <div className="mt-10">
      <p className="font-campton_r text-14 text-neutral_body mb-2">
        Do you have a healthcare institution you work with?
      </p>
      <div className="flex mt-2">
        <RadioButtonSelect
          label="Yes"
          labelStyles="text-neutral_black"
          variant="mr-6"
          isChecked={answer === 'yes'}
          onChange={() => setAnswer('yes')}
        />
        <RadioButtonSelect
          label="No"
          variant="mr-6"
          labelStyles="text-neutral_black"
          isChecked={answer === 'no'}
          onChange={() => setAnswer('no')}
        />
      </div>
      {answer && (
        <div className="mt-4 pt-2" data-testid="test-form">
          <div className={`${answer === 'yes' && 'pb-6'} border-b border-dashed border-neutral_stroke_1`}>
            {answer === 'yes' && (
              <Input
                label="Healthcare institution"
                type="text"
                id="institution"
                name="institution"
                readOnly={loading}
                value={formData?.institution}
                onChange={(e) => setFormData({ ...formData, institution: e.target.value })}
              />
            )}
            {suggestions?.length ? (
              <Suggestion
                suggestions={suggestions}
                onSelect={(data) => {
                  setSuggestions([])
                  setFormData({ ...formData, institution: data.businessName });
                }}
                loading={loading}
              />
            ) : (
              ''
            )}
          </div>
          <div className="mb-6 pt-6">
            <Input
              label="First Name"
              type="text"
              id="firstName"
              name="firstName"
              readOnly={loading}
              value={formData?.firstName}
              onChange={handleChange}
              showError={false}
            />
          </div>
          <div className="mb-6">
            <Input
              label="Last Name"
              type="text"
              id="lastName"
              name="lastName"
              readOnly={loading}
              value={formData?.lastName}
              onChange={handleChange}
              showError={false}
            />
          </div>
          <div className="mb-6">
            <Input
              label="Email"
              type="text"
              id="emailAddress"
              name="emailAddress"
              readOnly={loading}
              value={formData?.emailAddress}
              onChange={handleChange}
            />
          </div>
          <Phone value={phone} onChange={setPhone} />
          <Dropdown
            type="select"
            id="providedServiceType"
            placeholder="Type of institutions"
            name="providedServiceType"
            options={institutionTypes}
            selected={providerType}
            onSelect={(data) => {
              setProviderType(data.name);
              setFormData({ ...formData, providedServiceType: data.value });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default IndividualProfileSetup;
IndividualProfileSetup.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  setAnswer: PropTypes.func,
  loading: PropTypes.bool,
  answer: PropTypes.string,
  phone: PropTypes.object,
  setPhone: PropTypes.func
};
