import React, { useEffect, useState } from 'react';
import Table from 'components/Table';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import Navbar from 'components/Navbar';
import { SlideUp } from 'components/Animations';
import { policy_list_header } from 'mocks/settings/policies';
import usePolicies from 'hooks/fetch/usePolicies';
import { usePolicyState } from 'store/modules/policies';
import CreatePolicy from './CreatePolicy';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'store/modules/auth';
import Restricted from 'HOC/Restricted';

const Policies = () => {
  const [page, setPage] = useState('list');
  const { get_policies, loading, get_current_policy } = usePolicies();
  const { institutionId } = useAuthState()
  const { currentPolicy } = usePolicyState();
  const navigate = useNavigate();

  useEffect(() => {
    get_policies();
  }, []);

  useEffect(() => {
    get_current_policy(institutionId)
  }, [])

  useEffect(() => {
    document.title = 'Policies - Gabbi Health';
  }, []);

  const policies = currentPolicy ? Object?.values(currentPolicy) : []

  return (
    <>
      <Navbar title="Policies" />
      <Restricted module='Policy' permission='View policies'>
        <SlideUp className={`${page === 'list' ? 'h-[calc(100vh-300px)]' : 'h-[calc(100vh-200px)]'}`}>
          {page === 'list' && (
            <div className="p-6 h-full">
              <Table
                title="Policy List"
                headers={policy_list_header}
                data={policies}
                hasPagination={false}
                totalCount={policies?.total}
                loading={loading}
                perPage={policies?.limit}
                buttonFnc={() => setPage('create')}
                searchPlaceholder="Search by policy type"
                currentPage={policies?.page}
                emptyStateCaption='All policies created will be displayed here.'
                emptyStateTitle='No Policies.'
                module="Policy"
                permission={currentPolicy?.currentAppointmentPolicy && currentPolicy?.currentBillingPolicy ? '' : 'Add policy'}
                buttonName={loading || currentPolicy?.currentAppointmentPolicy && currentPolicy?.currentBillingPolicy ? '' : 'Create Policy'}
              >
                <>
                  {policies?.map((item) => (
                    <tr
                      data-testid={item?._id}
                      onClick={() => navigate(`${item?._id}/${item?.reschedulingTimeframe ? 'appointmentPolicy' : 'billingPolicy'}`)}
                      key={item?._id}
                      className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                    >
                      <td className="px-[10px] py-[8px] flex space-x-2 items-center capitalize">
                        <p>{item?.reschedulingTimeframe ? 'appointment' : 'billing'} Policy</p>
                      </td>
                      <td className="px-[10px] py-[8px] capitalize text-14">
                        {dayjs(item?.createdAt).format('MMM DD, YYYY')}
                      </td>
                      <td className="px-[10px] py-[8px] capitalize md:w-52 w-20">
                        <p className="card-description">
                          {dayjs(item?.updatedAt).format('MMM DD, YYYY')}
                        </p>
                      </td>
                      <td className="px-[10px] py-[8px] capitalize text-14">
                        {item?.createdBy?.firstName || item?.createdBy?.businessName || 'N/A'}
                      </td>
                      <td className="px-[10px] py-[8px] capitalize">
                        <ArrowRight fill="#A9B2B2" />
                      </td>
                    </tr>
                  ))}
                </>
              </Table>
            </div>
          )}
          {page === 'create' && <CreatePolicy callback={() => setPage('list')} action="create" />}
        </SlideUp>
      </Restricted>
    </>
  );
};

export default Policies;
