export const location_list_header = ['Location Name', 'City', 'Address', 'Contact Person', 'No of Staff', ''];
export const location_list = [
  {
    contactPersonInformation: {
      name: 'Sheldon Cooper',
      emailAddress: 'sheldon@cooper.com',
      countryCode: '+234',
      phoneNumber: '09058702551',
    },
    country: 'Egypt',
    state: 'Cairo',
    city: 'Cairo',
    address: '11, Kings Street',
    isPrimaryAddress: false,
    _id: '64b920ae87e8b676bbdd14b1',
    staffCount: 0,
  },
  {
    contactPersonInformation: {
      name: 'Chinedu Ikechi',
      emailAddress: 'chinedu@ikechi.com',
      countryCode: '+234',
      phoneNumber: '09058702551',
    },
    country: 'Ghana',
    state: 'Accra',
    city: 'Accra',
    address: '11, Kings Street',
    isPrimaryAddress: true,
    _id: '64b9393ff9f81df27086ad01',
    staffCount: 0,
  },
]
export const singleLocation = {
    isPrimaryAddress: true,
    city: 'Lekki',
    address: '15C Admiralty Way, Lekki Phase 1 106104, Lagos.',
    state: 'Lagos',
    contactPersonInformation: {
      name: 'Gabriel Inyamah',
      _id: '64b8b4f5860f4e3cd83d5f4f',
      emailAddress: 'Inyamahgabriel@reddington.ng',
      phoneNumber: '816 521 9432',
      countryCode: '+234',
    },
    name: 'Reddington Lekki',
    staff: [
      {
        logoUrl: '',
        businessAddress: '',
        _id: '648ad01a6ee875db36cd9e09',
        institutionId: '6486613b1cb2d64cf32666d9',
        firstName: 'Ifeoluwa',
        lastName: 'Oluwafemi',
        emailAddress: 'ifeoluwa@enyata.com',
        GPINumber: 'GPI-00905320',
        role: 'Comedian',
        profilePic: '',
        specialty: 'neurosurgeries',
        location: 'Yaba left',
        status: 'active',
        __v: 0,
        countryCode: '+234',
        gender: 'female',
        password: '$2b$10$8GfAo4g2ILwHrnY.ThyJPeyjFgj8Fp7vgKS7ELhlOXy..Rf3akC86',
        phoneNumber: '09087654321',
        qualification: 'MBA',
        verificationToken: '',
        verificationTokenExpiry: '',
        refreshToken:
          '9e1b37d4b268f62c4010ca85b9515c416ef1067405f3f8bd3e02c5e02e8084a8170654e3fb1fd5a48af2c09945a20b2e5e43',
        contactInformation: [],
      },
      {
        logoUrl: '',
        businessAddress: '',
        _id: '648ad01a6ee875db36cd9e09',
        institutionId: '6486613b1cb2d64cf32666d9',
        firstName: 'Gabriel',
        lastName: 'Inyamah',
        emailAddress: 'gabrielinyamah@reddington.ng',
        GPINumber: 'GPI-00905320',
        role: 'Comedian',
        profilePic: '',
        specialty: 'Cardiologist',
        location: 'Yaba left',
        status: 'active',
        __v: 0,
        countryCode: '+234',
        gender: 'female',
        password: '$2b$10$8GfAo4g2ILwHrnY.ThyJPeyjFgj8Fp7vgKS7ELhlOXy..Rf3akC86',
        phoneNumber: '09087654321',
        qualification: 'MBA',
        verificationToken: '',
        verificationTokenExpiry: '',
        refreshToken:
          '9e1b37d4b268f62c4010ca85b9515c416ef1067405f3f8bd3e02c5e02e8084a8170654e3fb1fd5a48af2c09945a20b2e5e43',
        contactInformation: [],
      },
      {
        logoUrl: '',
        businessAddress: '',
        _id: '648ad01a6ee875db36cd9e09',
        institutionId: '6486613b1cb2d64cf32666d9',
        firstName: 'Barakat',
        lastName: 'Salam',
        emailAddress: 'barakatsalam@reddington.ng',
        GPINumber: 'GPI-00905320',
        role: 'Comedian',
        profilePic: '',
        specialty: 'Orthopedic Nurse',
        location: 'Yaba left',
        status: 'active',
        __v: 0,
        countryCode: '+234',
        gender: 'female',
        password: '$2b$10$8GfAo4g2ILwHrnY.ThyJPeyjFgj8Fp7vgKS7ELhlOXy..Rf3akC86',
        phoneNumber: '09087654321',
        qualification: 'MBA',
        verificationToken: '',
        verificationTokenExpiry: '',
        refreshToken:
          '9e1b37d4b268f62c4010ca85b9515c416ef1067405f3f8bd3e02c5e02e8084a8170654e3fb1fd5a48af2c09945a20b2e5e43',
        contactInformation: [],
      },
      {
        logoUrl: '',
        businessAddress: '',
        _id: '648ad01a6ee875db36cd9e09',
        institutionId: '6486613b1cb2d64cf32666d9',
        firstName: 'Muhammad',
        lastName: 'Oni',
        emailAddress: 'muhammad@reddington.ng',
        GPINumber: 'GPI-00905320',
        role: 'Comedian',
        profilePic: '',
        specialty: 'Anesthesiologist',
        location: 'Yaba left',
        status: 'active',
        __v: 0,
        countryCode: '+234',
        gender: 'female',
        password: '$2b$10$8GfAo4g2ILwHrnY.ThyJPeyjFgj8Fp7vgKS7ELhlOXy..Rf3akC86',
        phoneNumber: '09087654321',
        qualification: 'MBA',
        verificationToken: '',
        verificationTokenExpiry: '',
        refreshToken:
          '9e1b37d4b268f62c4010ca85b9515c416ef1067405f3f8bd3e02c5e02e8084a8170654e3fb1fd5a48af2c09945a20b2e5e43',
        contactInformation: [],
      },
      {
        logoUrl: '',
        businessAddress: '',
        _id: '648ad01a6ee875db36cd9e09',
        institutionId: '6486613b1cb2d64cf32666d9',
        firstName: 'Dr. Elizabeth',
        lastName: 'Ajao',
        emailAddress: 'elizabethajao@reddington.ng',
        GPINumber: 'GPI-00905320',
        role: 'Comedian',
        profilePic: '',
        specialty: 'Anesthesiologist',
        location: 'Yaba left',
        status: 'active',
        __v: 0,
        countryCode: '+234',
        gender: 'female',
        password: '$2b$10$8GfAo4g2ILwHrnY.ThyJPeyjFgj8Fp7vgKS7ELhlOXy..Rf3akC86',
        phoneNumber: '09087654321',
        qualification: 'MBA',
        verificationToken: '',
        verificationTokenExpiry: '',
        refreshToken:
          '9e1b37d4b268f62c4010ca85b9515c416ef1067405f3f8bd3e02c5e02e8084a8170654e3fb1fd5a48af2c09945a20b2e5e43',
        contactInformation: [],
      },
    ],
    staffCount: 1,
  };