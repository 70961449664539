import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/Buttons';
import { ReactComponent as CheckboxUnselected } from 'assets/icons/check-box-unselected.svg';
import { ReactComponent as CheckboxSelected } from 'assets/icons/check-box-selected.svg';
import useProviders from 'hooks/fetch/useProviders';
import { Search } from 'components/Inputs';
import Avatar from 'components/Avatar/Avatar';
import Spinner from 'components/Spinner';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import AddStaffDropdowns from '../AddStaffDropdowns';
import useShift from 'hooks/fetch/useShift';
import Modal from 'components/Modals/Modal';

const AddStaff = ({ type, closeModal, getShiftProxy }) => {
  const { singleShift } = useSelector((state) => state.shift);
  const { institutionId } = useSelector((state) => state.auth);
  const [search, setSearch] = useState('');
  const { get_staff_without_shift, loading } = useProviders();
  const { add_staff } = useShift();
  const [allStaff, setAllStaff] = useState();
  const [availableStaffs, setAvailableStaffs] = useState();
  const shift = singleShift?.shift;
  const [selectedStaff, setSelectedStaff] = useState([]);

  const handleCheckboxClick = (staff) => {
    const isSelected = selectedStaff.some((item) => item._id === staff._id);

    if (isSelected) {
      setSelectedStaff((prev) => prev.filter((item) => item._id !== staff._id));
    } else {
      setSelectedStaff((prev) => [...prev, staff]);
    }
  };

  const addNewStaff = () => {
    const payload = selectedStaff.map(staff => staff?._id)
    add_staff({ url: `/shift/${shift?._id}/add-staff`, payload: { staffs: payload } }).then((res) => {
      if (res?.code === 200) {
        closeModal();
        getShiftProxy();
      }
    })
  };

  useEffect(() => {
    const startTime = `&startTime=${shift?.startTime}`;
    const endTime = `&endTime=${shift?.endTime}`;
    const startDate = `&startDate=${dayjs(shift?.startDate).format('YYYY-MM-DD')}`;
    const endDate = `&endDate=${dayjs(shift?.endDate).format('YYYY-MM-DD')}`;
    const searchVal = search ? `&search=${search}` : '';

    get_staff_without_shift(
      `providers/${institutionId}/staff-without-shift?page=1&limit=20${searchVal}${startDate}${endDate}${startTime}${endTime}`,
    ).then((res) => {
      setAllStaff(res?.data?.staffs || undefined);
      setAvailableStaffs(res?.data?.staffs || undefined);
    });
  }, [search]);

  const changeToLowercase = (val) => {
    return val?.toLowerCase() ?? val;
  };

  function filterStaffList(value, type) {
    let newStaffList = [];
    if (changeToLowercase(value) === 'all') {
      newStaffList = allStaff;
    } else {
      newStaffList = allStaff?.filter((staff) => changeToLowercase(staff[type]) === changeToLowercase(value));
    }
    return setAvailableStaffs(newStaffList);
  }

  function onChange(type, value) {
    filterStaffList(value.label, type);
  }

  return (
    <Modal
      closeModal={closeModal}
      styles="w-full md:w-[457px]"
      position="modal-right"
      variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
      title={type}
    >
      <div className="relative">
        <div className='modal-right-body'>
          <p className="text-neutral_black text-12 mb-[12px] font-campton_r">
            {selectedStaff?.length ?? 0} Staff Selected
          </p>
          <Search
            id="search"
            name="search"
            placeholder="Search by name, email address..."
            onChange={(e) => setSearch(e.target.value)}
          />
          <AddStaffDropdowns onChange={onChange} />
          <div>
            <div className="pt-[24px] pb-[16px] grid">
              {loading ? (
                <Spinner />
              ) : availableStaffs?.length === 0 ? (
                <div>
                  <p className="text-neutral_body text-14 mb-[20px] mt-[20px] text-center font-campton_r">
                    No Staff found!
                  </p>
                </div>
              ) : (
                availableStaffs?.map((staff) => (
                  <div
                    key={staff?.GPINumber}
                    data-testid={staff?.emailAddress}
                    className="flex items-center pt-[12px] pb-[12px] border-b border-b-neutral_stroke_2"
                  >
                    <div onClick={() => handleCheckboxClick(staff)}>
                      {selectedStaff.some((item) => item._id === staff._id) ? (
                        <CheckboxSelected className="mr-[8px] cursor-pointer hide_tap shrink-0" />
                      ) : (
                        <CheckboxUnselected className="mr-[8px]" />
                      )}
                    </div>
                    <div className="flex items-center w-[100%]">
                      <Avatar
                        url={staff?.profilePic}
                        width="32px"
                        height="32px"
                        backgroundColor="bg-brand_secondary"
                        alt={staff?.firstName?.split('')[0]}
                        initials={`${staff?.firstName?.split('')[0] || ''}${staff?.lastName?.split('')[0] || ''}`}
                        className="w-[32px] h-[32px]"
                      />
                      <div className="ml-[8px]" style={{ width: '-webkit-fill-available' }}>
                        <div className="flex items-center justify-between">
                          <span className="flex text-neutral_black text-14 font-campton_r capitalize">
                            {staff?.firstName} {staff?.lastName}
                          </span>
                          <div
                            className={`flex items-center justify-center text-10 rounded-[100px] capitalize w-[60px] nax-w-[73px] h-[19px] pl-[8px] pr-[8px] ${staff?.status === 'active'
                              ? 'bg-[#E0FAEB] text-[#23CE6B]'
                              : 'bg-[#F7F8F8] text-neutral_body'
                              }`}
                          >
                            {staff?.status === 'inactive' ? 'Unavailable' : 'Available'}
                          </div>
                        </div>
                        <span className="text-neutral_body text-12 font-normal font-campton_r">
                          {staff?.emailAddress} | {staff?.specialty} | {staff?.locationName}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        <div className="modal-right-button-container">
          <Button
            className={`${type === 'New' ? 'w-28' : 'w-[131px]'
              } w-28 rounded-full h-10 py-0 px-0 bg-brand_primary font-campton_m text-neutral_white`}
            theme="blue"
            name={'Save changes'}
            disabled={selectedStaff.length === 0}
            onClick={() => addNewStaff()}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddStaff;

AddStaff.propTypes = {
  getShiftProxy: PropTypes.func,
  closeModal: PropTypes.func,
  type: PropTypes.string,
};
