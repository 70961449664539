import { useEffect, useRef } from "react";

export default function useOnClickOutside(callback) {
    const ref = useRef(null);

    useEffect(() => {
        const listener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (ref && (!ref.current || ref.current.contains(event.target))) return
            callback();
        };

        document.addEventListener('mousedown', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
        };
    }, [ref, callback]);

    return ref
}