import React, { useEffect, useState } from 'react';
import { SlideUp } from 'components/Animations';
import Avatar from 'components/Avatar/Avatar';
import MoreOptions from 'components/Options/MoreOptions';
import Status from 'components/Status';
import Table from 'components/Table';
import { staff_list_header, staff_list_options } from 'mocks/settings';
import InviteUser from './Components/Forms/InviteUser';
import ViewStaff from './Components/ViewStaff';
import Delete from 'components/Forms/Delete';
import ChangeRole from './Components/ChangeRole';
import Cards from './Components/Cards';
import { useSelector } from 'react-redux';
import useDebounce from 'hooks/useDebounce';
import useProviders from 'hooks/fetch/useProviders';
import useTable from 'hooks/useTable';
import Navbar from 'components/Navbar';
import Filter from './Components/Filter';
import Restricted from 'HOC/Restricted';
import ToggleStatus from './Components/Forms/ToggleStatus';

export default function StaffManagement() {
  const { institutionId } = useSelector((state) => state.auth);
  const { providers } = useSelector((state) => state.providers);
  const { get_institution_providers, loading } = useProviders();
  const { loading: isDeleting, delete_staff } = useProviders();
  const { loading: isDisabling, toggle_staff_status } = useProviders();
  const [inviteUser, setInviteUser] = useState(false);
  const [changeRole, setChangeRole] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState();
  const [itemToDelete, setItemToDelete] = useState();
  const [itemToDeactivate, setItemToDeactivate] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const { params, changePage, selectLimit, handleSearch, openFilterModal, closeFilterModal, filterModal } = useTable();
  const { debouncedValue } = useDebounce(params.search);

  useEffect(() => {
    get_institution_providers({ id: `${institutionId}/providers`, params });
  }, [debouncedValue, params.page, params.limit]);

  const reset = () => {
    setSelectedOption();
    setSelectedStaff();
    get_institution_providers({ id: `${institutionId}/providers`, params });
  };

  const deleteStaff = () => {
    delete_staff(selectedStaff?._id).then((res) => {
      if (res?.code === 200) {
        setItemToDelete();
        reset();
      }
    });
  };

  const toggleStaffStatus = () => {
    const payload = {
      providerId: selectedStaff?._id,
      status: selectedOption === 'deactivate' ? 'deactivated' : 'active'
    }
    toggle_staff_status(payload).then((res) => {
      if (res?.code === 200) {
        setItemToDeactivate();
        reset();
      }
    });
  };

  return (
    <div>
      <Navbar title="Staff Management" />
      <Restricted module='Staff Management' permission='View staff list'>
        <SlideUp>
          <Cards />
          <div className="p-[24px]">
            <Table
              title="Staff List"
              headers={staff_list_header}
              data={providers?.providers}
              totalCount={providers?.total}
              filterFnc={openFilterModal}
              perPage={providers?.limit}
              currentPage={providers?.page}
              selectLimit={selectLimit}
              changePage={changePage}
              loading={loading}
              handleSearch={handleSearch}
              emptyStateTitle="No Staffs."
              buttonName="Invite User"
              buttonFnc={() => setInviteUser(true)}
              module="Staff Management"
              permission="Invite staff"
            >
              <>
                {providers?.providers?.map((item) => (
                  <tr
                    data-testid={item?._id}
                    key={item?._id}
                    className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1"
                  >
                    <td className="px-[10px] py-[8px] flex items-center capitalize">
                      <Avatar
                        //url={profile?.profile_picture}
                        width="32px"
                        height="32px"
                        alt={item?.firstName?.split('')[0]}
                        initials={`${item?.firstName?.split('')[0] || ''}${item?.lastName?.split('')[0] || ''}`}
                        className="mr-[15px] w-[32px] h-[32px]"
                      />
                      {item?.firstName} {item?.lastName}
                    </td>
                    <td className="px-[10px] py-[8px] capitalize">{item?.role}</td>
                    <td className="px-[10px] py-[8px] capitalize">{item?.specialty ?? 'N/A'}</td>
                    <td className="px-[10px] py-[8px] capitalize">{item?.locationName ?? 'N/A'}</td>
                    <td className="px-[10px] py-[8px]">
                      <Status status={item?.status} />
                    </td>
                    <td className="px-[10px] py-[8px] w-[70px]">
                      <MoreOptions position="right-[8px]">
                        <>
                          {staff_list_options?.map((option) => (
                            <Restricted key={option?.id} module='Staff Management' permission={option?.permission}>
                              <button
                                data-testid={option?.value}
                                type='button'
                                onClick={() => {
                                  setSelectedOption(option?.value);
                                  setSelectedStaff(item);
                                }}
                                className={`
                                ${item?.status?.toLowerCase() === 'deactivated' &&
                                    option?.value === 'deactivate'
                                    ? 'hidden'
                                    : ''
                                  }
                                ${item?.status?.toLowerCase() !== 'deactivated' &&
                                    option?.value === 'activate'
                                    ? 'hidden'
                                    : ''
                                  }
                                cursor-pointer w-[150px] flex items-center py-[9.5px] hover:bg-[#dfe2e23b]
                                text-neutral_body hover:text-neutral_black hover:pl-[8px] rounded-[50px]
                            `}
                              >
                                <span className="inline-block">{option?.name}</span>
                              </button>
                            </Restricted>
                          ))}
                        </>
                      </MoreOptions>
                    </td>
                  </tr>
                ))}
              </>
            </Table>
            {inviteUser && <InviteUser params={params} callback={() => setInviteUser(false)} />}
            {selectedOption === 'view' &&
              <ViewStaff
                id={selectedStaff?._id}
                staff={selectedStaff}
                deleteStaff={() => setItemToDelete(selectedStaff?._id)}
                changeRole={() => setChangeRole(true)}
                closeModal={() => setSelectedOption()}
                title={<span className="capitalize">{selectedStaff?.firstName} {selectedStaff?.lastName}</span>}
              />
            }
            {(itemToDelete || selectedOption === 'delete') &&
              <Delete
                title="Delete Staff?"
                description='Would you like to delete this staff'
                buttonName="Delete"
                cancel={() => {
                  setItemToDelete();
                  if (selectedOption !== 'view') setSelectedOption();
                }}
                loading={isDeleting}
                deleteFnc={deleteStaff}
              />
            }
            {itemToDeactivate || ['deactivate', 'activate'].includes(selectedOption) &&
              <ToggleStatus
                title={selectedOption === 'deactivate' ? 'Deactivate?' : 'Activate?'}
                description={`Would you like to ${selectedOption} this staff`}
                cancel={() => setItemToDeactivate()}
                loading={isDisabling}
                toggleFnc={toggleStaffStatus}
                closeModal={() => {
                  setItemToDeactivate();
                  setSelectedOption();
                }}
                purpose={selectedOption === 'deactivate' ? 'deactivate' : 'activate'}
              />
            }
            {changeRole &&
              <ChangeRole
                title="Delete Staff?"
                staff={selectedStaff}
                params={params}
                callback={() => {
                  setChangeRole(false);
                  setSelectedOption();
                }}
                closeModal={() => setChangeRole(false)}
              />
            }
            {filterModal && (<Filter closeModal={closeFilterModal} />)}
          </div>
        </SlideUp>
      </Restricted>
    </div>
  );
}
