import React, { useMemo } from "react";
import { ReactComponent as Wallet } from "assets/icons/wallet.svg"
import { ReactComponent as Note } from "assets/icons/note.svg"
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg"
import { useNavigate } from "react-router-dom";
import { Overview as Card } from "components/Cards";
import { usePaymentState } from "store/modules/payment";
import { formatCurrency } from "utils/formatCurrency";
import usePermission from "hooks/usePermission";

export default function Overview() {
    const navigate = useNavigate()
    const { hasPermission } = usePermission()
    const { billingHistory, revenue } = usePaymentState()

    const monthlyPercentageIncrease = useMemo(() => {
        const lastMonth = revenue?.revenue?.[revenue?.revenue?.length - 2]?.totalRevenue;
        const currentMonth = revenue?.revenue?.[revenue?.revenue?.length - 1]?.totalRevenue;

        const increase = ((currentMonth - lastMonth) / lastMonth) * 100;
        if (lastMonth) {
            return increase.toFixed(2)
        }
        return 0;
    }, [revenue])

    return (
        <section className="flex border-b border-b-neutral_stroke_2 p-[24px] overflow-x-scroll">
            <Card
                icon={<Wallet />}
                title="Wallet Balance"
                total={`${formatCurrency(billingHistory?.walletBalance || 0)}`}
                textColor="text-neutral_white"
                className="bg-brand_primary mr-[24px]"
            >
                {hasPermission('Bills and Payment', 'View wallet transactions') &&
                    <div role="button" className="flex items-center cursor-pointer hide_tap" onClick={() => navigate('wallet')}>
                        <p className="text-12 text-neutral_white font-campton_m">View Transactions</p>
                        <ArrowRight fill="#ffffff" />
                    </div>
                }
            </Card>
            <Card
                icon={<Note className="w-[40px] h-[40px]" />}
                title="Total Bill"
                total={`${formatCurrency(billingHistory?.totalBill || 0)}`}
                textColor="text-neutral_black"
                className="border border-neutral_stroke_2 mr-[24px]"
            />
            <Card
                icon={<Note className="w-[40px] h-[40px]" />}
                title="Total Revenue"
                total={`${formatCurrency(billingHistory?.totalRevenue || 0)}`}
                textColor="text-neutral_black"
                className="border border-neutral_stroke_2"
            >
                <div className="flex items-center">
                    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.0003 4.99972V0.999722C14.0003 0.867113 13.9476 0.739936 13.8538 0.646168C13.7601 0.5524 13.6329 0.499722 13.5003 0.499722H9.50028C9.36767 0.499722 9.24049 0.5524 9.14672 0.646168C9.05296 0.739936 9.00028 0.867113 9.00028 0.999722C9.00028 1.13233 9.05296 1.25951 9.14672 1.35328C9.24049 1.44704 9.36767 1.49972 9.50028 1.49972H12.2934L7.50028 6.29285L5.35403 4.14597C5.30759 4.09948 5.25245 4.0626 5.19175 4.03744C5.13105 4.01228 5.06599 3.99933 5.00028 3.99933C4.93457 3.99933 4.86951 4.01228 4.80881 4.03744C4.74811 4.0626 4.69296 4.09948 4.64653 4.14597L0.146528 8.64597C0.0527077 8.73979 -1.39803e-09 8.86704 0 8.99972C1.39803e-09 9.1324 0.0527077 9.25965 0.146528 9.35347C0.240348 9.44729 0.367596 9.5 0.500278 9.5C0.63296 9.5 0.760208 9.44729 0.854028 9.35347L5.00028 5.2066L7.14653 7.35347C7.19296 7.39996 7.24811 7.43684 7.30881 7.462C7.36951 7.48716 7.43457 7.50012 7.50028 7.50012C7.56599 7.50012 7.63105 7.48716 7.69175 7.462C7.75245 7.43684 7.80759 7.39996 7.85403 7.35347L13.0003 2.2066V4.99972C13.0003 5.13233 13.053 5.25951 13.1467 5.35328C13.2405 5.44704 13.3677 5.49972 13.5003 5.49972C13.6329 5.49972 13.7601 5.44704 13.8538 5.35328C13.9476 5.25951 14.0003 5.13233 14.0003 4.99972Z" fill="#106032" />
                    </svg>
                    <p className="text-12 text-success_900 font-campton_r ml-[4px]">{monthlyPercentageIncrease ?? '...'}%</p>
                </div>
            </Card>
        </section>
    )
}