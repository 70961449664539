import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/Buttons';
import PermissionList from 'components/PermissionList';
import useAssignPermissions from 'hooks/useAssignPermissions';
import AssignedUsers from 'components/AssignedUsers';
import useRoles from 'hooks/fetch/useRoles';
import { useSelector } from 'react-redux';
import Restricted from 'HOC/Restricted';
import Modal from 'components/Modals/Modal';

export default function RoleDetails({ deleteRole, id, selectedRole, editRole, closeModal }) {
  const { permissions } = useAssignPermissions();
  const { get_roleAssignees } = useRoles();
  const { assignees } = useSelector((state) => state.roles);

  useEffect(() => {
    get_roleAssignees({ params: { roleName: selectedRole?.name } });
  }, []);

  const newPermissions = selectedRole?.permissions.map((permission, i) => {
    return {
      module: permission?.module,
      action: permission?.action,
      availableActions: permission?.module === permissions[i]?.module ? permissions[i].availableActions : [],
    };
  });

  return (
    <Modal
      closeModal={closeModal}
      styles="w-full md:w-[457px]"
      position="modal-right"
      title="Role Details"
      variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
    >
      <div className='relative'>
        <div className="modal-right-body">
          <div className="py-[16px] overflow-y-auto">
            <p className="text-16 text-neutral_black font-campton_m mb-[8px] capitalize">{selectedRole?.name}</p>
            <p className="text-14 text-neutral_body font-campton_r leading-[21px]">{selectedRole?.description}</p>
            <hr className="border-dashed border-neutral_stroke_1 my-[24px]" />
            <div className="mb-[8px]">
              <p className="text-14 text-neutral_black font-campton_m mb-[16px]">Permissions</p>
              <div className="max-w-[730px] overflow-auto mb-[32px]">
                {newPermissions?.map((item) => <PermissionList key={item.module} item={item} />)}
              </div>
            </div>
            <div className="mb-[8px]">
              <div className="max-w-[730px] overflow-auto mb-[32px]">
                <AssignedUsers items={assignees} />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-right-button-container">
          <Restricted module='Roles and Permission' permission='Delete role'>
            <Button
              name="Delete role"
              theme="transparent"
              onClick={() => deleteRole(id)}
              className="h-[40px] text-16 px-[12px] mr-[16px]"
            />
          </Restricted>
          <Restricted module='Roles and Permission' permission='Edit role'>
            <Button name="Edit role" theme="blue" className="h-[40px] text-16 px-[12px]" onClick={editRole} />
          </Restricted>
        </div>
      </div>
    </Modal>
  );
}

RoleDetails.propTypes = {
  id: PropTypes.string,
  deleteRole: PropTypes.func,
  editRole: PropTypes.func,
  selectedRole: PropTypes.object,
  closeModal: PropTypes.func
};
