/* eslint-disable no-use-before-define */
import React, { useEffect, useMemo, useState } from 'react';
import { patient_list_header } from 'mocks/patient';
import Table from 'components/Table';
import Avatar from 'components/Avatar/Avatar';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'utils/formatDate';
import { SlideUp } from 'components/Animations';
import { useSelector } from 'react-redux';
import usePatient from 'hooks/fetch/usePatient';
import useDebounce from 'hooks/useDebounce';
import useTable from 'hooks/useTable';
import Layout from '../Layout';
import { useAuthState } from 'store/modules/auth';
import AddPatient from './Components/AddPatient';
import Filter from './Components/Filter';
import PatientAnalytics from './Components/PatientAnalytics';
import dayjs from 'dayjs';
import Restricted from 'HOC/Restricted';

export default function AllPatients() {
  const { patients } = useSelector((state) => state.patient);
  const { params, changePage, selectLimit, handleSearch } = useTable();
  const { loading: loadingPatients = true, get_patients } = usePatient();
  const { get_patients_analytics } = usePatient();
  const navigate = useNavigate();
  const [chartFilter, setChartFilter] = useState('monthly');
  const [filterParams, setFilterParams] = useState({
    location: '',
    fromDate: '',
    endDate: '',
  });
  const { debouncedValue } = useDebounce(params.search);
  const { user, institutionId } = useAuthState();
  const patientParams = {
    institutionId: institutionId ?? '',
    providerId: user?.businessName ? '' : user?._id,
    type: user?.businessName ? 'institution' : 'provider',
  };
  const [type, setType] = useState('');

  const getPatientProxy = () => {
    get_patients({ ...params, ...patientParams });
  }

  useEffect(() => {
    getPatientProxy()
  }, [debouncedValue, params.page, params.limit]);

  const chartParams = { filter: chartFilter, ...patientParams }

  useEffect(() => {
    get_patients_analytics(chartParams);
  }, [chartFilter]);

  const dataToExport = useMemo(() => {
    return patients?.patients?.map((item) => {
      return {
        patientName: `${item?.firstName} ${item?.lastName}`,
        phoneNumber: item?.phoneNumber,
        createdAt: formatDate(item?.createdAt),
        location: item?.location,
      };
    });
  }, [patients]);

  const filter = () => {
    get_patients({
      ...patientParams,
      ...chartParams,
      location: filterParams?.location === 'All Locations' ? '' : filterParams?.location,
      fromDate: filterParams.fromDate ? dayjs(filterParams.fromDate).format('YYYY-MM-DD') : '',
      toDate: filterParams.toDate ? dayjs(filterParams.toDate).format('YYYY-MM-DD') : '',
    }, setType(''));
  };

  useEffect(() => {
    document.title = 'Patients - Gabbi Health';
  }, []);

  return (
    <Layout>
      <>
        <SlideUp>
          <Restricted module='Patient Management' permission='View all patient list'>
            <>
              <PatientAnalytics filter={chartFilter} setFilter={setChartFilter} />
              <div className="p-[24px]">
                <Table
                  title="Patient List"
                  headers={patient_list_header}
                  data={dataToExport}
                  filterFnc={() => setType('filter')}
                  totalCount={patients?.count}
                  perPage={patients?.limit}
                  currentPage={patients?.page}
                  selectLimit={selectLimit}
                  emptyStateTitle='No Patients'
                  emptyStateCaption='All added patients will be displayed here'
                  changePage={changePage}
                  loading={loadingPatients}
                  buttonFnc={() => setType('add')}
                  handleSearch={handleSearch}
                  module="Patient Management"
                  permission='Add patient'
                  buttonName="Add Patient"
                >
                  <>
                    {patients?.patients?.map((item) => (
                      <tr
                        data-testid={item?._id}
                        key={item?._id}
                        className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer hide_tap"
                        onClick={() => navigate(`/clinicals/patients/${item?._id}`)}
                      >
                        <td className="px-[10px] py-[8px] flex items-center capitalize">
                          <Avatar
                            url={item?.profilePic}
                            width="32px"
                            height="32px"
                            alt={item?.firstName?.split('')[0]}
                            initials={`${item?.firstName?.split('')[0] || ''}${item?.lastName?.split('')[0] || ''}`}
                            className="mr-[15px] w-[32px] h-[32px]"
                          />
                          <p className='max-w-[280px] truncate'>{item?.firstName} {item?.lastName}</p>
                        </td>
                        <td className="px-[10px] py-[8px]">
                          <p className='w-max'>{item?.countryCode}{item?.phoneNumber}</p>
                        </td>
                        <td className="px-[10px] py-[8px] w-[150px]">{formatDate(item?.createdAt)}</td>
                        <td title={item?.location} className="px-[10px] py-[8px] capitalize">
                          <p className='truncate max-w-[300px]'>{item?.location ?? 'N/A'}</p>
                        </td>
                        <td className="px-[10px] py-[8px] w-[20px]">
                          <ArrowRight fill="#A9B2B2" />
                        </td>
                      </tr>
                    ))}
                  </>
                </Table>
              </div>
            </>
          </Restricted>
        </SlideUp>
        {type === 'add' && <AddPatient callback={() => setType('')} getPatientProxy={getPatientProxy} />}
        {type === 'filter' &&
          <Filter
            callback={() => setType('')}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            onClick={filter}
            loading={loadingPatients}
          />
        }
      </>
    </Layout >
  );
}
