import { useState } from "react"
import useMakeRequest from "hooks/useMakeRequest"
import useTryCatch from "hooks/useTryCatch"
import { getLabTest, getLabTests, updateLabTest } from "store/modules/clinicals/actions"

export default function useLabTest() {
    const { makeRequest, makeAnonymousRequest } = useMakeRequest()
    const [loading, setLoading] = useState(false)
    const { tryCatch } = useTryCatch()

    const get_lab_tests = ({ id, params }) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getLabTests({ pathname: id, params }), alert: false })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    }

    const get_lab_test = (id) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getLabTest({ pathname: id }), alert: false })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    }

    const get_visit_tests = ({ id, params }) => {
        const url = `/institution/visit/${id}/lab-test`
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'get', params, alert: false })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    }

    const get_lab_requests = ({ id, params }) => {
        const url = `/institution/lab-request/${id}`
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'get', params, alert: false })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    }

    const create_lab_request = ({ payload }) => {
        const url = `/institution/lab-request`
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'post', payload })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    }

    const update_lab_test = ({ pathname, payload }) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: updateLabTest({ pathname, payload }), alert: true })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    }

    return {
        get_lab_tests,
        get_lab_test,
        update_lab_test,
        get_visit_tests,
        get_lab_requests,
        create_lab_request,
        loading
    }
}