import Navbar from "components/Navbar";
import React from "react";
import BreadCrumbs from "components/BreadCrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import StaffCalendarDetails from "pages/EShift/Components/StaffCalendarDetails";
import { useSelector } from "react-redux";

export default function StaffCalendar() {
    const navigate = useNavigate()
    const { singleShift, singleStaffShift } = useSelector(state => state.shift)
    const location = useLocation()
    const shiftId = location.pathname.split('/')[3]
    const providerId = location.pathname.split('/')[4]

    const options = [
        {
            title: 'Shift Management',
            method: () => navigate("/e-shift/scheduling")
        },
        {
            title: `${singleShift?.shift?.shiftName}`,
            method: () => navigate(-1)
        },
        {
            title: `${singleStaffShift?.firstName} ${singleStaffShift?.lastName}`
        }
    ]

    return (
        <>
            <Navbar title="Shift Management" />
            <BreadCrumbs options={options} />
            <StaffCalendarDetails staff={singleStaffShift} shiftId={shiftId} providerId={providerId} />
        </>
    )
}