import React, { useContext, useState } from 'react'
import { Button } from 'components/Buttons';
import { SlideUp } from 'components/Animations';
import Modal from 'components/Modals/Modal';
import CreateAndEdit from './Component/CreateAndEdit';
import { ReactComponent as Empty } from 'assets/svg/empty-visit.svg';
import EmptyState from 'components/Table/EmptyState';
import { VisitDetailsContext } from 'context';

export default function PhysicalExamination() {
    const [display, setDisplay] = useState()
    const { singleVisit, progressNote, visitStatus, canUpdate, getSingleVisit, getSingleProgressNoteProxy } = useContext(VisitDetailsContext)
    const id = singleVisit?._id || progressNote?._id
    const type = singleVisit?._id ? "visit" : "progressNote"
    const examination = singleVisit?.physicalExamination?.examination || progressNote?.physicalExamination?.examination
    const examinationId = singleVisit?.physicalExamination?._id || progressNote?.physicalExamination?._id

    return (
        <>
            <div className='flex items-center justify-between'>
                <h2 className="text-16 sm:text-18 text-[#0D0F11] font-campton_m">Physical Examination</h2>
                {!visitStatus && canUpdate && examination?.length ?
                    <Button
                        name="Edit Examination"
                        theme="blue"
                        type="button"
                        className='py-[5.5px] px-[12px]'
                        onClick={() => setDisplay('edit')}
                    /> : ''
                }
            </div>
            <SlideUp>
                <>
                    {examination?.length ? examination?.map((item) =>
                        <div key={item?._id} className='py-[16px] border-b border-neutral_stroke_2'>
                            <p className='text-14 text-neutral_black font-campton_m mb-[8px]'>{item?.title}</p>
                            <p className='text-14 text-neutral_body font-campton_r leading-[21px]'>{item?.observation}</p>
                        </div>
                    ) :
                        <EmptyState
                            title='No Examination.'
                            icon={<Empty />}
                            buttonName={!visitStatus ? 'Add Examination' : ''}
                            buttonFunc={() => canUpdate ? setDisplay('new') : {}}
                            caption='All examinations will be displayed here'
                        />
                    }
                </>
                {display ?
                    <Modal
                        closeModal={() => setDisplay()}
                        //styles="w-[457px] max-w-[457px]"
                        styles="w-[90%] md:w-[730px]"
                        title={`${display === 'new' ? "Add" : "Edit"} Physical Examination`}
                    >
                        <CreateAndEdit
                            id={id}
                            type={type}
                            examinationId={examinationId}
                            purpose={display === 'new' ? "add" : "edit"}
                            examination={examination}
                            setDisplay={() => setDisplay()}
                            callback={getSingleVisit || getSingleProgressNoteProxy}
                        />
                    </Modal> : ''
                }
            </SlideUp>
        </>
    )
}