import React from "react";
import PropTypes from "prop-types"
import { Button } from "components/Buttons"
import { ReactComponent as Test } from "assets/icons/test.svg"
import useVisit from "hooks/fetch/useVisit"
import { useSelector } from "react-redux"
import useLabTest from "hooks/fetch/useLabTest"
import useProgressNoteDetails from "pages/Clinicals/Patients/InPatients/SingleInPatient/ProgressNote/ProgressNoteDetails/useProgressNoteDetails";

export default function Summary({ selectedTest, note, callback, updateTest, visitId, patientId, back }) {
    const { update_visit, loading: isUpdatingVisit } = useVisit()
    const { create_lab_request, loading: isCreatingLabRequest } = useLabTest()
    const { progressNote } = useProgressNoteDetails()
    const pathname = window.location.pathname
    const { institutionId } = useSelector(state => state.auth)

    const submit = () => {
        let payload
        if (visitId && (pathname.includes('visit') || pathname.includes('appointments/live'))) {
            payload = { note, labTest: selectedTest, type: 'labRequest' }
            update_visit({ id: visitId, payload })
                .then(res => {
                    if (res?.code === 200) {
                        if (updateTest) updateTest(res?.data)
                        callback()
                    }
                })
        } else {
            payload = { 
                note, 
                labTest: selectedTest, 
                institutionId, 
                patientId,
                visitId: progressNote?.visit ?? visitId,
                progressNoteId: progressNote?._id
            }
            create_lab_request({ payload })
                .then(res => {
                    if (res?.code === 201) {
                        callback()
                    }
                })
        }
    }

    return (
        <div className="flex flex-col justify-between h-full">
            <div>
                <div className="mb-[24px]">
                    <p className="text-14 text-neutral_body font-campton_r mb-[8px]">Tests</p>
                    <ul className="bg-[#F2F3F3] rounded-[8px] px-[16px]">
                        {selectedTest?.map(suggestion =>
                            <li
                                key={suggestion?.testType}
                                className="text-12 text-neutral_black font-campton_r
                                    border-b border-dashed border-b-neutral_stroke_1 w-full last:border-0
                                    leading-[18px] bg-[#F2F3F3] py-[16px] mr-[8px] flex justify-between items-center"
                            >
                                <Test fill="#DF3939" className="w-[13px] mr-[5px]" />
                                <p title={suggestion?.testType} className="w-[90%] capitalize-first truncate">{suggestion?.testType}</p>
                            </li>
                        )}
                    </ul>
                </div>
                {note &&
                    <div>
                        <p className="text-14 text-neutral_body font-campton_r mb-[8px]">Note</p>
                        <p className="text-16 text-neutral_black font-campton_r leading-[24px] capitalize-first">{note}</p>
                    </div>
                }
            </div>
            <div className="flex justify-end pt-[20px]">
                {back &&
                    <Button
                        name="Back"
                        theme="transparent"
                        className='h-[40px] text-16 px-[12px] w-[100px] mr-[17px]'
                        onClick={back}
                        disabled={isUpdatingVisit || isCreatingLabRequest}
                    />
                }
                <Button
                    name="Send Request"
                    theme="blue"
                    className='h-[40px] text-16 px-[12px] w-[130px]'
                    onClick={() => submit()}
                    loading={isUpdatingVisit || isCreatingLabRequest}
                    disabled={isUpdatingVisit || isCreatingLabRequest}
                />
            </div>
        </div>
    )
}

Summary.propTypes = {
    callback: PropTypes.func,
    selectedTest: PropTypes.array,
    note: PropTypes.string,
    updateTest: PropTypes.func,
    back: PropTypes.func,
    visitId: PropTypes.string,
    patientId: PropTypes.string,
}