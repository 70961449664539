export const roles = [
  {
    id: '0',
    role: 'admin officer',
    description:
      'Responsible for managing administrative tasks and ensuring the smooth operation of an organization, handling a wide range of duties such as record-keeping, correspondence, scheduling, and supporting other staff members.',
    createdAt: '1960-01-01T00:00:00.000Z',
    staffs: [
      {
        id: '0',
        profilePicture: 'https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg',
        firstName: 'Gabriel',
        lastName: 'Gabe',
      },
      {
        id: '1',
        profilePicture:
          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMb2Pc9z9o-JzRZYh5WyiLcVUWG15YPKT0zAI_zs0L&s',
        firstName: 'Gabriel',
        lastName: 'Gabe',
      },
    ],
  },
  {
    id: '1',
    role: 'doctor',
    description:
      'Responsible for managing administrative tasks and ensuring the smooth operation of an organization, handling a wide range of duties such as record-keeping, correspondence, scheduling, and supporting other staff members.',
    createdAt: '1960-01-01T00:00:00.000Z',
    staffs: [
      {
        id: '0',
        profilePicture: 'https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg',
        firstName: 'Ikechi',
        lastName: 'Success',
      },
      {
        id: '1',
        profilePicture:
          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMb2Pc9z9o-JzRZYh5WyiLcVUWG15YPKT0zAI_zs0L&s',
        firstName: 'Ikechi',
        lastName: 'Chinedu',
      },
    ],
  },
];

export const staff_list_header = ['Name', 'Role', 'Specialty', 'Location', 'Status', ''];

export const staff_list_data = [
  {
    id: '0',
    firstName: 'Gabriel',
    lastName: 'Inyamah',
    role: 'admin',
    specialty: 'Cardiologist',
    location: 'Lekki',
    status: 'active',
  },
  {
    id: '1',
    firstName: 'Ikechi',
    lastName: 'Success',
    role: 'Nurse',
    specialty: 'Cardiologist',
    location: 'Lekki',
    status: 'deactivated',
  },
  {
    id: '2',
    firstName: 'Ikechi',
    lastName: 'Chinedu',
    role: 'doctor',
    specialty: 'Cardiologist',
    location: 'Lekki',
    status: 'active',
  },
  {
    id: '3',
    firstName: 'Marcus',
    lastName: 'Rashford',
    role: 'doctor',
    specialty: 'Cardiologist',
    location: 'Lekki',
    status: 'deactivated',
  },
];

export const staff_list_options = [
  {
    id: 1,
    name: 'View details',
    value: 'view',
    permission: 'View staff list'
  },
  {
    id: 2,
    name: 'Deactivate user',
    value: 'deactivate',
    permission: 'Activate/Deactivate staff'
  },
  {
    id: 3,
    name: 'Activate user',
    value: 'activate',
    permission: 'Activate/Deactivate staff'
  },
  {
    id: 4,
    name: 'Delete user',
    value: 'delete',
    permission: 'Delete staff'
  },
];

export const titles = [
  {
    id: 1,
    name: 'Doctor',
    value: 'Dr',
  },
  {
    id: 2,
    name: 'Nurse',
    value: 'Nrs',
  },
];

export const specialities = [
  {
    id: 1,
    name: 'Internal medicine',
    value: 'Internal medicine',
  },
  {
    id: 2,
    name: 'Pediatrics',
    value: 'Pediatrics',
  },
  {
    id: 3,
    name: 'Surgery',
    value: 'Surgery',
  },
  {
    id: 4,
    name: 'Neurology',
    value: 'Neurology',
  },
  {
    id: 5,
    name: 'Family medicine',
    value: 'Family medicine',
  },
  {
    id: 6,
    name: 'Emergency medicine',
    value: 'Emergency medicine',
  },
  {
    id: 7,
    name: 'Anesthesiology',
    value: 'Anesthesiology',
  },
  {
    id: 8,
    name: 'Dermatology',
    value: 'Dermatology',
  },

  {
    id: 9,
    name: 'Psychiatry',
    value: 'Psychiatry',
  },
  {
    id: 10,
    name: 'Obstetrics and gynaecology',
    value: 'Obstetrics and gynaecology',
  },
  {
    id: 11,
    name: 'Pathology',
    value: 'Pathology',
  },
  {
    id: 12,
    name: 'Ophthalmology',
    value: 'Ophthalmology',
  },
  {
    id: 13,
    name: 'General surgery',
    value: 'General surgery',
  },
  {
    id: 14,
    name: 'Physical therapy',
    value: 'Physical therapy',
  },
  {
    id: 15,
    name: 'Radiology',
    value: 'Radiology',
  },
  {
    id: 16,
    name: 'Immunology',
    value: 'Immunology',
  },
  {
    id: 17,
    name: 'Oncology',
    value: 'Oncology',
  },
  {
    id: 18,
    name: 'Nuclear medicine',
    value: 'Nuclear medicine',
  },
  {
    id: 19,
    name: 'Urology',
    value: 'Urology',
  },
  {
    id: 20,
    name: 'Otorhinolaryngology',
    value: 'Otorhinolaryngology',
  },
  {
    id: 21,
    name: 'Preventive healthcare',
    value: 'Preventive healthcare',
  },
  {
    id: 22,
    name: 'Diagnostic Radiology',
    value: 'Diagnostic Radiology',
  },
  {
    id: 23,
    name: 'Medical genetics',
    value: 'Medical genetics',
  },
  {
    id: 24,
    name: 'Cardiology',
    value: 'Cardiology',
  },
  {
    id: 25,
    name: 'Orthopedics',
    value: 'Orthopedics',
  },
  {
    id: 26,
    name: 'Plastic surgery',
    value: 'Plastic surgery',
  },
  {
    id: 27,
    name: 'Gastroenterology',
    value: 'Gastroenterology',
  },
  {
    id: 28,
    name: 'Nephrology',
    value: 'Nephrology',
  },
  {
    id: 29,
    name: 'Rheumatology',
    value: 'Rheumatology',
  },
  {
    id: 30,
    name: 'Neurosurgery',
    value: 'Neurosurgery',
  },
  {
    id: 31,
    name: 'Endocrinology',
    value: 'Endocrinology',
  },
  {
    id: 32,
    name: 'Intensive care medicine',
    value: 'Intensive care medicine',
  },
  {
    id: 33,
    name: 'Cardiothoracic surgery',
    value: 'Cardiothoracic surgery',
  },
  {
    id: 34,
    name: 'Hematology',
    value: 'Hematology',
  },
  {
    id: 35,
    name: 'Pulmonology',
    value: 'Pulmonology',
  },
  {
    id: 36,
    name: 'Geriatrics',
    value: 'Geriatrics',
  },
  {
    id: 37,
    name: 'Clinical chemistry',
    value: 'Clinical chemistry',
  },
  {
    id: 38,
    name: 'Occupational medicine',
    value: 'Occupational medicine',
  },
  {
    id: 39,
    name: 'Infectious diseases',
    value: 'Infectious diseases',
  },
  {
    id: 40,
    name: 'Public health',
    value: 'Public health',
  },
  {
    id: 41,
    name: 'Hospital medicine',
    value: 'Hospital medicine',
  },
  {
    id: 42,
    name: 'Forensic pathology',
    value: 'Forensic pathology',
  },
  {
    id: 43,
    name: 'Vascular surgery',
    value: 'Vascular surgery',
  },
  {
    id: 44,
    name: 'Colorectal surgery',
    value: 'Colorectal surgery',
  },
  {
    id: 45,
    name: 'Child and adolescent psychiatry',
    value: 'Child and adolescent psychiatry',
  },
  {
    id: 46,
    name: 'Gynaecology',
    value: 'Gynaecology',
  },
  {
    id: 47,
    name: 'Oral and maxillofacial surgery',
    value: 'Oral and maxillofacial surgery',
  },
  {
    id: 48,
    name: 'Podiatry',
    value: 'Podiatry',
  },
  {
    id: 49,
    name: 'Reconstructive surgery',
    value: 'Reconstructive surgery',
  },
  {
    id: 50,
    name: 'Adolescent medicine',
    value: 'Adolescent medicine',
  },
  {
    id: 51,
    name: 'Pediatric Hematology Oncology',
    value: 'Pediatric Hematology Oncology',
  },
];
export const dosageTypes = [
  {
    id: 1,
    name: 'Milligrams(mg)',
    value: 'Milligrams(mg)'
  },
  {
    id: 2,
    name: 'Micrograms(mcg)',
    value: 'Micrograms(mcg)'
  },
  {
    id: 3,
    name: 'grams(g)',
    value: 'grams(g)'
  },
  {
    id: 4,
    name: 'Drops',
    value: 'Drops'
  },
  {
    id: 5,
    name: 'Teaspoons(tsp)',
    value: 'Teaspoons(tsp)'
  },
  {
    id: 6,
    name: 'tablespoons(tbsp)',
    value: 'tablespoons(tbsp)'
  },
  {
    id: 7,
    name: 'Patches',
    value: 'Patches'
  },
]
export const frequencyTypes = [
  {
    id: 1,
    name: 'Daily',
    value: 'daily'
  },
  {
    id: 2,
    name: 'Twice a day',
    value: 'twice a day'
  },
  {
    id: 3,
    name: 'Three times a day',
    value: 'three times a day'
  },
  {
    id: 4,
    name: 'Four times a day',
    value: 'four times a day'
  },
  {
    id: 5,
    name: 'Every other day',
    value: 'every other day'
  },
  {
    id: 6,
    name: 'Every bedtime',
    value: 'every bedtime'
  },
  {
    id: 7,
    name: 'Every 4 hours',
    value: 'every 4 hours'
  },
  {
    id: 8,
    name: 'Every 4 to 6 hours',
    value: 'every 4 to 6 hours'
  },
  {
    id: 9,
    name: 'Every week',
    value: 'every week'
  },
  {
    id: 10,
    name: 'PRN/ as needed',
    value: 'PRN/ as needed'
  },
  {
    id: 11,
    name: 'Stat',
    value: 'Stat'
  }
]
export const staff_status = [
  {
    name: 'all',
    value: ''
  },
  {
    name: 'Active',
    value: 'active'
  },
  {
    name: 'Inactive',
    value: 'inactive'
  }
]

