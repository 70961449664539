import { createCustomAsyncThunk } from 'utils/createThunk'
import { roles } from '../../../services/endpoints'

const createRole = createCustomAsyncThunk('roles/createRole', 'post', roles.roles)
const getRoles = createCustomAsyncThunk('roles/getRoles', 'get', roles.roles)
const editRole = createCustomAsyncThunk('roles/editRole', 'patch', roles.roles)
const deleteRole = createCustomAsyncThunk('roles/deleteRole', 'delete', roles.roles)
const getRoleAssignees = createCustomAsyncThunk('roles/assignees', 'get', roles.assignees)

export {
    createRole,
    editRole,
    deleteRole,
    getRoles,
    getRoleAssignees
}