import React from "react"
import { ReactComponent as NotFound } from "assets/svg/404.svg"
import { Button } from "components/Buttons"
import { useNavigate } from "react-router-dom"

export default function PageNotFound() {
    const navigate = useNavigate()

    return (
        <div className="h-screen flex flex-col justify-center items-center">
            <NotFound className="mb-[24px]" />
            <p className="font-campton_sb text-24">Something went wrong</p>
            <p className="font-campton_sb text-16 text-neutral_body text-center">Sorry, the page you’re looking for does not exist</p>
            <Button
                name="Back"
                theme="blue"
                className="w-[120px] h-12 mt-[24px]"
                onClick={()=> navigate(-1)}
            />
        </div>
    )
}