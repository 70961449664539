import { useState } from "react"
import useMakeRequest from "hooks/useMakeRequest"
import useTryCatch from "hooks/useTryCatch"
import { createBillingPolicy, getPolicies, getCurrentPolicy, createAppointmentPolicy } from "store/modules/policies/actions"


export default function usePolicies() {
    const { makeRequest } = useMakeRequest()
    const [loading, setLoading] = useState(false)
    const { tryCatch } = useTryCatch()

    const get_policies = (params) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getPolicies({ params }), alert: false })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    }
    const create_billing_policy = (formData, callback) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: createBillingPolicy(formData), callback })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }
    const create_appointment_policy = (formData, callback) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: createAppointmentPolicy(formData), callback })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }
    const get_current_policy = (id) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getCurrentPolicy({pathname: id}), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    return {
        get_policies,
        loading,
        create_billing_policy,
        get_current_policy,
        create_appointment_policy
    }
}