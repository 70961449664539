import { useState } from 'react';
import useMakeRequest from 'hooks/useMakeRequest';
import useTryCatch from 'hooks/useTryCatch';
import {
  getInstitutionAppointments,
  getProviderAppointments,
  getAppointmentRequest,
  createAppointment,
  editAppointment,
  editAppointmentRequest
} from 'store/modules/appointment/actions';

export default function useAppointments() {
  const { makeRequest, makeAnonymousRequest } = useMakeRequest();
  const [loading, setLoading] = useState(false);
  const { tryCatch } = useTryCatch();

  const get_institution_appointments = (params) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getInstitutionAppointments({ params }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const get_provider_appointments = (params) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getProviderAppointments({ params }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const get_appointment_request = (id, params) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getAppointmentRequest({ pathname: id, params }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const create_appointment = (formData) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: createAppointment(formData), to: '/appointments' })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const edit_appointment = (id, formData, callback) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: editAppointment({ pathname: id, payload: formData }), callback })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const edit_appointment_request = (id, formData, callback) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: editAppointmentRequest({ pathname: id, payload: formData }), callback })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const complete_appointment = (appointmentId) => {
    const url = `/appointment/${appointmentId}/complete`
    return tryCatch(async () => {
      setLoading(true);
      return makeAnonymousRequest({ url, method: 'patch', alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const update_appointment_status = (appointmentId) => {
    const url = `/appointment/update/${appointmentId}/ongoing`
    return tryCatch(async () => {
      setLoading(true);
      return makeAnonymousRequest({ url, method: 'patch', alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  return {
    get_institution_appointments,
    get_provider_appointments,
    get_appointment_request,
    create_appointment,
    edit_appointment,
    edit_appointment_request,
    loading,
    complete_appointment,
    update_appointment_status
  };
}
