import Button from 'components/Buttons/Button';
import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import Phone from 'components/Inputs/PhoneInput';
import TextArea from 'components/Inputs/TextArea';
import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useVisit from 'hooks/fetch/useVisit';
import { PatientContext } from 'context';
import useValidateFormData from 'hooks/useValidateFormData';

const AddEmergencyContact = ({ callback }) => {
  const { validateFormData } = useValidateFormData()
  const [phone, setPhone] = useState({
    phoneNumber: '',
    countryCode: ''
  });
  const { patient, setPatient } = useContext(PatientContext)
  const { add_medical_record, loading } = useVisit();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    relationship: '',
    address: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const updateMedicalRecord = () => {
    const payload = { ...formData, ...phone };
    add_medical_record('emergencyContact', patient?._id, payload)
      .then(res => {
        if (res?.code === 200) {
          setPatient(res?.data)
          callback()
        }
      })
  }

  const disabledBtn = useMemo(() => {
    let result = []
    Object.keys(formData).forEach((key) => {
      result.push(!validateFormData({ [key]: formData[key] }))
    })
    return result.includes(true)
  }, [formData])

  return (
    <div className="w-full lg:w-[730px] lg:h-[400px]">
      <FormWrapper className="h-full" onSubmit={updateMedicalRecord}>
        <div className="flex justify-between flex-col h-full">
          <div className="grid gap-x-6 gap-y-5 grid-cols-12 mt-6">
            <div className="md:col-span-6 col-span-12">
              <Input
                label="First Name"
                type="text"
                id="firstName"
                name="firstName"
                readOnly={loading}
                value={formData?.firstName}
                onChange={handleChange}
                showError={true}
              />
            </div>
            <div className="md:col-span-6 col-span-12">
              <Input
                label="Last Name"
                type="text"
                id="lastName"
                name="lastName"
                readOnly={loading}
                value={formData?.lastName}
                onChange={handleChange}
                showError={true}
              />
            </div>
            <Phone value={phone} onChange={setPhone} variant="lg:col-span-4 md:col-col-6 col-span-12" />
            <div className="lg:col-span-4 md:col-col-6 col-span-12">
              <Input
                label="Email"
                type="text"
                id="emailAddress"
                name="emailAddress"
                readOnly={loading}
                value={formData?.emailAddress}
                onChange={handleChange}
              />
            </div>
            <div className="lg:col-span-4 md:col-col-6 col-span-12">
              <Input
                label="Relationship"
                type="text"
                id="relationship"
                name="relationship"
                readOnly={loading}
                value={formData?.relationship}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-12">
              <TextArea
                label="Address"
                id="address"
                name="address"
                readOnly={loading}
                rows={2}
                value={formData?.address}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <Button name="Save" theme="blue" className="w-24 h-10" disabled={disabledBtn || !phone?.phoneNumber} loading={loading} />
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default AddEmergencyContact;
AddEmergencyContact.propTypes = {
  callback: PropTypes.any
};
