import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'hooks/useReduxHook';
import { addBankInfo } from '../payment/actions';
import { editProviderProfile } from '../providers/actions';
import { addSpecialty } from '../specialty/actions';
import {
  completeProviderProfile,
  createAccountInstitution,
  createAccountProviders,
  forgotPassword,
  getInstitution,
  login,
  updateBasicInfo,
  verifyAccount,
  createLocation,
  addLicense,
  updateContactPerson,
  requestUploadUrl,
  getCountries,
  refreshToken,
  editContactPersonInfo,
} from './actions';

const initialState = {
  user: {},
  isInstitution: false,
  authToken: '',
  refreshToken: '',
  authTokenExpiry: '',
  institutionId: '',
  providerId: '',
  userType: '',
  isLoggedIn: false,
  loading: false,
  userDetails: {},
  uploadId: '',
  hasCompletedOnboarding: false,
  countryList: [],
  recoveredUser: {},
  institution: [],
  permissions: [],
};

const checkUserType = (action) => {
  if (action?.payload?.data?.type === 'superAdmin' || action?.payload?.data?.type === 'independentProvider') return 'institution'
  if (action?.payload?.data?.type === 'staff') return 'staff'
  return 'provider'
}

const checkInstitutionId = (action) => {
  if (action?.payload?.data?.institutionId) return action?.payload?.data?.institutionId
  return ''
}

const setUser = (state, action) => {
  if (action?.payload?.data) {
    state.user = action?.payload?.data;
    state.authToken = action?.payload?.data?.authToken;
    state.refreshToken = action?.payload?.data?.refreshToken;
    state.authTokenExpiry = action?.payload?.data?.authTokenExpiry;
    state.isLoggedIn = action?.payload?.status === 'success';
    state.userType = checkUserType(action)
    state.institutionId = checkInstitutionId(action)
    state.providerId = (!action?.payload?.data?.businessName && !action?.payload?.data?.institutionId) ? action?.payload?.data?._id : ''
    state.permissions = action?.payload?.data?.permission?.permissions;
    state.isInstitution = ['superAdmin', 'independentProvider'].includes(action?.payload?.data?.type) ? true : false
  }
};

const setLicense = (state, action) => {
  if (state.user.businessName) {
    state.user.CACLicenseNumber = action?.payload?.data.CACLicenseNumber;
    state.user.CACLicenseUrl = action?.payload?.data.CACLicenseUrl;
  } else {
    state.user.medicalLicenseNumber = action?.payload?.data.medicalLicenseNumber;
    state.user.medicalLicenseUrl = action?.payload?.data.medicalLicenseUrl;
  }
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setUserStatus: (state, action) => {
      state.hasCompletedOnboarding = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        setUser(state, action);
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.recoveredUser = action.payload?.data?.data;
      })
      .addCase(createAccountProviders.fulfilled, (state, action) => {
        state.user = action.payload?.data;
      })
      .addCase(createAccountInstitution.fulfilled, (state, action) => {
        state.user = action.payload?.data;
      })
      .addCase(verifyAccount.fulfilled, (state, action) => {
        setUser(state, action);
      })
      .addCase(completeProviderProfile.fulfilled, (state, action) => {
        setUser(state, action);
      })
      .addCase(editProviderProfile.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action?.payload?.data }
      })
      .addCase(getInstitution.fulfilled, (state, action) => {
        state.institution = action?.payload?.data || [];
      })
      .addCase(updateBasicInfo.fulfilled, (state, action) => {
        // state.user.businessDescription = action.payload.data.businessDescription;
        // state.user.businessAddress = action.payload.data.businessAddress;
        // state.user.logoUrl = action.payload.data.logoUrl;
        // state.user.websiteLink = action.payload.data.websiteLink;
        // state.user.providedServiceType = action.payload.data.providedServiceType;
        state.user = { ...state.user, ...action.payload?.data }
      })
      .addCase(createLocation.fulfilled, (state, action) => {
        state.user.healthCareFacilities = action.payload?.data?.healthCareFacilities;
      })
      .addCase(addSpecialty.fulfilled, (state, action) => {
        state.user.specialties = action.payload?.data?.specialties;
      })
      .addCase(addLicense.fulfilled, (state, action) => {
        setLicense(state, action);
      })
      .addCase(updateContactPerson.fulfilled, (state, action) => {
        state.user.contactInformation = action.payload?.data?.contactInformation;
      })
      .addCase(editContactPersonInfo.fulfilled, (state, action) => {
        state.user.contactInformation = action.payload?.data?.contactInformation;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.countryList = action?.payload?.data;
      })
      .addCase(addBankInfo.fulfilled, (state, action) => {
        state.user.bankDetails = action?.payload?.data?.bankDetails;
      })
      .addCase(requestUploadUrl.fulfilled, (state, action) => {
        state.uploadId = action.payload?.data;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          state.user = action.payload?.data;
          state.authToken = action.payload?.data?.authToken;
          state.refreshToken = action.payload?.data?.refreshToken;
          state.authTokenExpiry = action.payload?.data?.authTokenExpiry;
        }
      })
  },
});

// Selectors
const selectAuth = (state) => state.auth;
// Action creators are generated for each case reducer function
export const { setUserDetails, setUserStatus } = auth.actions;
//App Redux State
export const useAuthState = () => useAppSelector(selectAuth);

export default auth.reducer;
