export default function useHandleError() {
    const handleError = ({ status, message }) => {
        switch (status) {
            case 400:
            case 401:
            case 403:
            case 404:
            case 422:
            case 500:
                return message
            default:
                return message || 'Something went wrong!'
        }
    }

    return { handleError }
}