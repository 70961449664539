import { Button } from 'components/Buttons';
import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import useAuth from 'hooks/fetch/useAuth';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const navigate = useNavigate()
  const { login_user, loading } = useAuth();
  const [formData, setFormData] = useState({
    emailAddress: '',
    password: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const loginUser = () => {
    login_user(formData)
  }

  const btnDisabled = () => {
    return !formData.password || !formData.emailAddress;
  };

  return (
    <FormWrapper
      description="Access your account"
      caption="Log in with your email address or GPI No."
      className="sm:h-full md:mt-12 mt-8 w-full sm:w-[387px] mx-auto"
      onSubmit={loginUser}
    >
      <div className="mt-10">
        <div className="mb-6">
          <Input
            label="Email"
            type="text"
            id="emailAddress"
            name="emailAddress"
            readOnly={loading}
            value={formData?.emailAddress}
            onChange={handleChange}
          />
        </div>
        <div className="">
          <Input
            label="Password"
            type="password"
            id="password"
            name="password"
            readOnly={loading}
            value={formData?.password}
            onChange={handleChange}
          />
        </div>
        <p className="font-campton_m text-neutral_body md:text-16 text-14 mt-[22px]">
          Forgot password?
          <Link className="text-brand_secondary cursor-pointer ml-1" to="/forgot-password">
            Reset it here
          </Link>
        </p>
        <Button name="Login" theme="blue" className="w-full h-12 mt-12" loading={loading} disabled={btnDisabled()} />
        <div className="py-2 w-full border border-neutral_stroke_2 flex justify-center items-center rounded-full mt-6 h-12 cursor-pointer hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary text-neutral_body" onClick={() => navigate('/create-account')} data-testid='login-btn'>
          <p className=" font-campton_r">
            Don’t have an account? <span className="text-brand_secondary font-campton_m">Create account</span>{' '}
          </p>
        </div>
      </div>
    </FormWrapper>
  );
};

export default LoginForm;
