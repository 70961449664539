import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import 'react-dropdown/style.css';
import { store, persistor } from './store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App';
import { injectStore } from "./services/token.service"
import * as serviceWorker from './serviceWorker';

// Helps in accessing store in token.service which is used to handle jwt and refreshToken in axios interceptor
injectStore(store)

createRoot(document.getElementById("root")).render(
    //<React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    //</React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
