import { medications, patient, progressNote } from 'services/endpoints'
import { institution } from 'services/endpoints/institution'
import { createCustomAsyncThunk, customAsyncThunk } from 'utils/createThunk'

const getVitals = createCustomAsyncThunk('clinicals/getVitals', 'get', institution.institution)
const getVital = createCustomAsyncThunk('clinicals/getVital', 'get', institution.vitals)
const createVitals = createCustomAsyncThunk('clinicals/createVitals', 'post', institution.create_vitals)
const getLabTests = createCustomAsyncThunk('clinicals/getLabTests', 'get', institution.institution)
const getLabTest = createCustomAsyncThunk('clinicals/getLabTest', 'get', institution.lab_test)
const updateLabTest = createCustomAsyncThunk('clinicals/updateLabTest', 'patch', institution.visits)
const getVisits = createCustomAsyncThunk('clinicals/getVisits', 'get', institution.institution)
const getSingleVisit = createCustomAsyncThunk('clinicals/getSingleVisit', 'get', institution.visits)
const updateVisit = createCustomAsyncThunk('clinicals/updateVisit', 'patch', institution.visits)
const createChiefComplaint = createCustomAsyncThunk('clinicals/createChiefComplaint', 'post', institution.chief_complaint)
const createVisitSummary = createCustomAsyncThunk('clinicals/createVisitSummary', 'post', institution.visit_summary)
const updateVisitSummary = createCustomAsyncThunk('clinicals/updateVisitSummary', 'patch', institution.visit_summary)
const addMedicalRecord = createCustomAsyncThunk('clinicals/updateMedicalRecord', 'post', institution.update_medical_record)
const getMedications = createCustomAsyncThunk('clinicals/getMedications', 'get', medications.medications)
const createMedications = customAsyncThunk('clinicals/createMedications', 'post', medications.medications)
const updateMedicationStatus = customAsyncThunk('clinicals/createMedications', 'patch', medications.update_medication_status)
const getProcedure = createCustomAsyncThunk('clinicals/getProcedure', 'get', patient.procedure)
const createProcedure = customAsyncThunk('clinicals/createProcedure', 'post', patient.procedure)
const referPatient = customAsyncThunk('clinicals/referPatient', 'post', patient.referral)
const getReferrals = createCustomAsyncThunk('clinicals/getReferrals', 'get', patient.referral)
const createTheraphy = customAsyncThunk('clinicals/createTheraphy', 'post', patient.therapy)
const getTherapies = createCustomAsyncThunk('clinicals/getTherapies', 'get', patient.therapy)
const getVisitAnalytics = createCustomAsyncThunk('patient/getVisitAnalytics', 'get', institution.get_visit_chart)
const getPatientAppointment = createCustomAsyncThunk('patient/getPatientAppointment', 'get' , patient.patient_profile )
const getProgressNotes = createCustomAsyncThunk('clinicals/getProgressNotes', 'get', progressNote.progress_notes)
const addProgressNote = customAsyncThunk('clinicals/addProgressNote', 'post', progressNote.progress_notes)

export {
    getVitals,
    getVital,
    getPatientAppointment,
    createVitals,
    getLabTests,
    getLabTest,
    createMedications,
    getMedications,
    getProgressNotes,
    updateVisit,
    addMedicalRecord,
    getVisits,
    getSingleVisit,
    updateMedicationStatus,
    updateLabTest,
    getVisitAnalytics,
    getProcedure,
    createProcedure,
    getReferrals,
    referPatient,
    getTherapies,
    createTheraphy,
    addProgressNote,
    createVisitSummary,
    updateVisitSummary,
    createChiefComplaint
}