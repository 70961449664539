export const availableTests = [
    {
        name: 'Comp. Metabolic Panel',
        categories: [
            {
                name: 'Glucose',
                unit: 'mg/dL',
                value: ''
            },
            {
                name: 'BUN',
                unit: 'mg/dL',
                value: ''
            },
            {
                name: 'Creatinine',
                unit: 'mg/dL',
                value: ''
            },
            {
                name: 'eGFR',
                unit: 'mL/min',
                value: ''
            },
            {
                name: 'BUN/Creatinine Ratio',
                unit: '',
                value: ''
            },
            {
                name: 'Sodium',
                unit: 'mmol/L',
                value: ''
            },
            {
                name: 'Potassium',
                unit: 'mmol/L',
                value: ''
            },
            {
                name: 'Chloride',
                unit: 'mmol/L',
                value: ''
            },
            {
                name: 'Carbon Dioxide, Total',
                unit: 'mmol/L',
                value: ''
            },
            {
                name: 'Calcium',
                unit: 'mg/dL',
                value: ''
            },
            {
                name: 'Protein, Total',
                unit: 'g/dL',
                value: ''
            },
            {
                name: 'Albumin',
                unit: 'g/dL',
                value: ''
            },
            {
                name: 'Globulin, Total',
                unit: 'g/dL',
                value: ''
            },
            {
                name: 'A/G Ratio',
                unit: '',
                value: ''
            },
            {
                name: 'Bilirubin, Total',
                unit: 'mg/dL',
                value: ''
            },
            {
                name: 'Alkaline Phosphatase',
                unit: 'IU/L',
                value: ''
            },
            {
                name: 'AST (SGOT)',
                unit: 'IU/L',
                value: ''
            },
            {
                name: 'ALT (SGPT)',
                unit: 'IU/L',
                value: ''
            },
        ]
    },
    {
        name: 'Complete Blood Count (CBC)',
        categories: [
            {
                name: 'WBC',
                unit: 'x10E3/uL',
                value: ''
            },
            {
                name: 'RBC',
                unit: 'x10E3/uL',
                value: ''
            },
            {
                name: 'Hemoglobin',
                unit: 'g/dL',
                value: ''
            },
            {
                name: 'Hematocrit',
                unit: '%',
                value: ''
            },
            {
                name: 'MCV',
                unit: 'fL',
                value: ''
            },
            {
                name: 'MCH',
                unit: 'pg',
                value: ''
            },
            {
                name: 'MCHC',
                unit: 'g/dL',
                value: ''
            },
            {
                name: 'RDW',
                unit: '%',
                value: ''
            },
            {
                name: 'Platelets',
                unit: 'x10E3/uL',
                value: ''
            },
            {
                name: 'Neutrophils',
                unit: '%',
                value: ''
            },
            {
                name: 'Lymphs',
                unit: '%',
                value: ''
            },
            {
                name: 'Monocytes',
                unit: '%',
                value: ''
            },
            {
                name: 'Eos',
                unit: '%',
                value: ''
            },
            {
                name: 'Basos',
                unit: '%',
                value: ''
            },
            {
                name: 'Neutrophils (Absolute)',
                unit: 'x10E3/uL',
                value: ''
            },
            {
                name: 'Lymphs (Absolute)',
                unit: 'x10E3/uL',
                value: ''
            },
            {
                name: 'Monocytes (Absolute)',
                unit: 'x10E3/uL',
                value: ''
            },
            {
                name: 'Eos (Absolute)',
                unit: 'x10E3/uL',
                value: ''
            },
            {
                name: 'Baso (Absolute)',
                unit: 'x10E3/uL',
                value: ''
            },
            {
                name: 'Immature Granulocytes',
                unit: '%',
                value: ''
            },
            {
                name: 'Immature Grans (Abs)',
                unit: 'x10E3/uL',
                value: ''
            },
            {
                name: 'NRBC',
                unit: '%',
                value: ''
            }
        ]
    },
    {
        name: 'Urinalysis Panel',
        categories: [
            {
                name: 'Specific Gravity',
                unit: '',
                value: ''
            },
            {
                name: 'pH',
                unit: '',
                value: ''
            },
            {
                name: 'Urine−Color',
                unit: '',
                value: ''
            },
            {
                name: 'Appearance',
                unit: '',
                value: ''
            },
            {
                name: 'WBC Esterase',
                unit: '',
                value: ''
            },
            {
                name: 'Protein',
                unit: '',
                value: ''
            },
            {
                name: 'Glucose',
                unit: '',
                value: ''
            },
            {
                name: 'Ketones',
                unit: '',
                value: ''
            },
            {
                name: 'Occult Blood',
                unit: '',
                value: ''
            },
            {
                name: 'Bilirubin',
                unit: '',
                value: ''
            },
            {
                name: 'Urobilinogen, Semi−Qn',
                unit: 'mg/dL',
                value: ''
            },
            {
                name: 'Nitrite, Urine',
                unit: '',
                value: ''
            }
        ]
    },
    {
        name: 'Microscopic Examination',
        categories: [
            {
                name: 'WBC',
                unit: '/hpf',
                value: ''
            },
            {
                name: 'RBC',
                unit: '/hpf',
                value: ''
            },
            {
                name: 'Epithelial Cells (non renal)',
                unit: '/hpf',
                value: ''
            },
            {
                name: 'Casts',
                unit: '/hpf',
                value: ''
            },
            {
                name: 'Protein',
                unit: '',
                value: ''
            }
        ]
    }
]