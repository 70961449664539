import Navbar from 'components/Navbar';
import React, { useEffect, useState } from 'react';
import { SlideUp } from 'components/Animations';
import Table from 'components/Table';
import { location_list_header } from 'mocks/settings/locations';
import useTable from 'hooks/useTable';
import { useAuthState } from 'store/modules/auth';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as Location } from 'assets/icons/location.svg';
import { ReactComponent as PrimaryLocation } from 'assets/icons/hospital.svg';
import Modal from 'components/Modals/Modal';
import ViewLocation from './ViewLocation';
import CreateLocation from 'pages/Onboarding/Components/Forms/CreateLocation';
import useLocations from 'hooks/fetch/useLocations';
import useDebounce from 'hooks/useDebounce';
import { useLocationState } from 'store/modules/locations';
import FilterLocations from './Filter';
import Restricted from 'HOC/Restricted';

const Locations = () => {
  const [type, setType] = useState('');
  const { user, institutionId } = useAuthState();
  const { locations } = useLocationState();
  const [locationDetails, setLocationDetails] = useState();
  const { params, openFilterModal, closeFilterModal, filterModal, selectLimit, handleSearch, changePage } = useTable();
  const { get_locations, loading } = useLocations();
  const { get_location_details } = useLocations();
  const { debouncedValue } = useDebounce(params.search);

  useEffect(() => {
    document.title = 'Locations - Gabbi Health';
  }, []);

  useEffect(() => {
    get_locations(params)
  }, [debouncedValue, params.page, params.limit]);

  const getLocationDetails = (id) => {
    get_location_details(`${institutionId}/location/${id}`, () => setType('view')).then((res) =>
      setLocationDetails(res?.data ?? {}),
    );
  };

  return (
    <>
      <Navbar title="Locations" />
      <Restricted module='Location' permission='View location'>
        <>
          <SlideUp>
            <div className="p-6 h-full">
              <Table
                title="Location List"
                headers={location_list_header}
                data={locations?.locations}
                totalCount={locations?.count}
                handleSearch={handleSearch}
                selectLimit={selectLimit}
                filterFnc={openFilterModal}
                loading={loading}
                perPage={locations?.limit}
                buttonFnc={() => setType('Create')}
                searchPlaceholder="Search by city..."
                buttonName="Create Location"
                changePage={changePage}
                currentPage={locations?.page}
                emptyStateTitle="No Locations available."
                module="Location"
                permission="Add location"
              >
                <>
                  {locations?.locations?.map((item) => (
                    <tr
                      data-testid={item?._id}
                      onClick={() => getLocationDetails(item._id)}
                      key={item?._id}
                      className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                    >
                      <td className="px-[10px] py-[11px] flex space-x-2 items-center">
                        <p>{item?.isPrimaryAddress ? <PrimaryLocation fill="#748181" /> : <Location fill="#748181" />}</p>
                        <div title={``}>
                          <p className="text-neutral_black text-14 capitalize truncate max-w-[200px]">
                          {item?.state}
                          </p>
                        </div>
                      </td>
                      <td title={`${item?.city} ${item?.state}`} className="px-[10px] py-[11px] capitalize text-14">
                        <p className="truncate max-w-[200px]">{item?.city}</p>
                      </td>
                      <td title={item?.address} className="px-[10px] py-[11px] capitalize">
                        <p className="truncate max-w-[200px]">{item?.address}</p>
                      </td>
                      <td className="px-[10px] py-[11px] capitalize text-14">{item?.contactPersonInformation?.name}</td>
                      <td className="px-[10px] py-[11px] capitalize text-14">{item?.staffCount}</td>
                      <td className="px-[10px] py-[11px] capitalize">
                        <ArrowRight fill="#A9B2B2" />
                      </td>
                    </tr>
                  ))}
                </>
              </Table>
            </div>
          </SlideUp>
          {type && (
            <Modal
              closeModal={() => {
                setType('')
                setLocationDetails({})
              }}
              styles="w-full md:w-[457px]"
              position="modal-right"
              variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
              title={type === 'view' ? user?.businessName : `${type} Location`}
            >
              {type === 'view' ? (
                <ViewLocation onClick={() => setType('Edit')} />
              ) : (
                <CreateLocation type={type} callback={() => setType('')} locationDetails={locationDetails} />
              )}
            </Modal>
          )}
          {filterModal && <FilterLocations closeModal={closeFilterModal} />}
        </>
      </Restricted>
    </>
  );
};

export default Locations;
