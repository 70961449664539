import React, { useEffect, useMemo, useState } from 'react';
import Navbar from 'components/Navbar';
import { SlideUp } from 'components/Animations';
import Modal from 'components/Modals/Modal';
import SubNavbar from 'components/SubNavbar';
import { useNavigate } from 'react-router-dom';
import Table from 'components/Table';
import EmptyState from 'components/Table/EmptyState';
import { wallet_history_header } from 'mocks/billAndPayments';
import Overview from './Overview';
import WithdrawFunds from './Forms/WithdrawFunds';
import AddBankInformation from './Forms/AddBankInformation';
import usePayments from 'hooks/fetch/usePayments';
import { usePaymentState } from 'store/modules/payment';
import useTable from 'hooks/useTable';
import { useAuthState } from 'store/modules/auth';
import useDebounce from 'hooks/useDebounce';
import dayjs from 'dayjs';
import Status from 'components/Status';
import { formatCurrency } from 'utils/formatCurrency';
import { removeSpecificKeys } from 'utils/removeSpecificKeys';
import WalletFilter from './Components/WalletFilter';
import { formatToDateWithDash } from 'utils/formatDate';
import Restricted from 'HOC/Restricted';
import ConfirmWithdraw from './Forms/ConfirmWithdraw';

export default function Wallet() {
  const navigate = useNavigate();
  const [modal, setModal] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState('');
  const { get_wallet_transactions, loading } = usePayments();
  const { walletTransactions } = usePaymentState();
  const { handleSearch, params, selectLimit, changePage, openFilterModal, closeFilterModal, filterModal } = useTable();
  const { debouncedValue } = useDebounce(params.search);
  const { user, institutionId } = useAuthState();
  const [filterParams, setFilterParams] = useState({ transactionType: '', startDate: '', endDate: '' })
  const [addFilter, setAddFilter] = useState(false)

  useEffect(() => {
    document.title = 'Wallet - Gabbi Health';
  }, []);

  const getWalletTransactions = () => {
    const type = 'institution'
    const providerId = user?.businessName ? undefined : user?._id
    const oldParams = { ...params }

    if (addFilter) {
      const filtered = removeSpecificKeys(filterParams) // remove undefined keys
      // CONVERT START AND END DATE FORMAT
      const enhancedParams = Object.fromEntries(Object.entries(filtered).map(([key, value]) => {
        if (["startDate", "endDate"].includes(key))
          return [key, formatToDateWithDash(value)]
        return [key, value]
      }))
      const params = { ...oldParams, ...enhancedParams }
      get_wallet_transactions({ ...params, type, institutionId, providerId })
      return
    }

    get_wallet_transactions({ ...params, type, institutionId, providerId });
  };

  useEffect(() => {
    getWalletTransactions();
  }, [debouncedValue, params.page, params.limit, addFilter, filterParams]);

  const handleCallback = () => {
    setModal('');
    setWithdrawalAmount('')
    setConfirmModal(false)
    getWalletTransactions();
  };

  const selectedFilters = useMemo(() => {
    const filtered = removeSpecificKeys(filterParams) // remove undefined keys
    const enhancedParams = Object.fromEntries(Object.entries(filtered).map(([key, value]) => {
      if (["startDate", "endDate"].includes(key))
        return [key, formatToDateWithDash(value)]
      return [key, value]
    }))
    return addFilter ? Object.entries(enhancedParams) : []
  }, [filterParams, addFilter])

  const clearFilters = () => {
    setFilterParams({ transactionType: '', startDate: '', endDate: '' })
  }

  const closeConfirmModal = () => {
    setConfirmModal(false);
  };

  return (
    <div>
      <Navbar title="Bill & Payments" />
      <SubNavbar
        previous="Billing History"
        onClick={() => navigate('/service/bill-and-payment')}
        current="Wallet"
      />
      <Restricted module='Bills and Payment' permission='View wallet transactions'>
        <>
          <SlideUp className="relative">
            <Overview callback={() => setModal('Add Bank Information')} />
            <div className="p-[24px]">
              <Table
                title="Wallet Transactions"
                headers={wallet_history_header}
                data={walletTransactions?.transactions}
                totalCount={walletTransactions?.total}
                perPage={walletTransactions?.limit}
                currentPage={walletTransactions?.page}
                selectLimit={selectLimit}
                changePage={changePage}
                loading={loading}
                filters={selectedFilters}
                clearFilters={clearFilters}
                filterFnc={openFilterModal}
                handleSearch={handleSearch}
                emptyState={<EmptyState title="No Bills" />}
                buttonName="Withdraw Funds"
                buttonFnc={() => setModal('Withdraw Funds')}
                module="Bills and Payment"
                permission="Withdraw funds"
              >
                <>
                  {walletTransactions?.transactions?.map((item) => (
                    <tr
                      data-testid={item?._id}
                      key={item?._id}
                      className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1"
                    >
                      <td className="px-[10px] py-[8px] flex items-center capitalize">{item?.paymentId}</td>
                      <td className="px-[10px] py-[8px]">{`${formatCurrency(item?.difference)}`}</td>
                      <td className="px-[10px] py-[8px] capitalize">
                        <Status status={item?.transactionType} />
                      </td>
                      <td className="px-[10px] py-[8px]">{dayjs(item?.createdAt).format('MMM DD, YYYY')}</td>
                    </tr>
                  ))}
                </>
              </Table>
            </div>
          </SlideUp>
          {modal && (
            <Modal
              closeModal={() => {
                setModal('')
                setWithdrawalAmount('')
              }}
              styles="w-full md:w-[457px]"
              position="modal-right"
              title={modal}
              variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
            >
              {modal === 'Withdraw Funds' &&
                <WithdrawFunds
                  withdrawalAmount={withdrawalAmount}
                  setWithdrawalAmount={setWithdrawalAmount}
                  setConfirmModal={setConfirmModal}
                />
              }
              {modal === 'Add Bank Information' && <AddBankInformation callback={() => setModal('')} />}
            </Modal>
          )}
          {confirmModal &&
            <ConfirmWithdraw closeModal={closeConfirmModal} amount={withdrawalAmount} callback={handleCallback} />
          }
          {filterModal &&
            <WalletFilter
              closeFilterModal={closeFilterModal}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              setAddFilter={() => setAddFilter(true)}
            />
          }
        </>
      </Restricted>
    </div>
  );
}
