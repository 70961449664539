import React, { useState } from "react";
import PropTypes from "prop-types"
import { Button } from "components/Buttons";
import { ReactComponent as ArrowBack } from "assets/icons/arrow-back.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import { ReactComponent as Dot } from "assets/icons/dot.svg";
import Avatar from "components/Avatar/Avatar";
import { formatCurrency } from "utils/formatCurrency";
import { userInitals } from "utils/ImagePlaceholder";

export default function PreviewInformation({ back, cancel, patient, services, totalBillingAmount, createBill, loading }) {
    return (
        <div className="flex flex-col justify-between h-full">
            <div>
                <div className="flex items-center mb-[24px]">
                    <Button
                        theme="transparent"
                        className="w-[82px] mr-[12px]"
                        type="button"
                        onClick={back}
                    >
                        <>
                            <ArrowBack fill="#748181" className="w-[7px] h-[12px] mr-[13.5px]" />
                            Back
                        </>
                    </Button>
                    <h2 className="text-16 text-neutral_black font-campton_m pl-[12px] border-l border-l-neutral_stroke_2">Preview Information</h2>
                </div>
                <p className="text-14 text-meutral_black font-campton_r mb-[12px]">Patient</p>
                <div className="bg-[#36A1C5] rounded-[8px] p-[24px] mb-[24px] bg-[url('/src/assets/svg/frame.svg')] bg-no-repeat bg-cover">
                    <div className="flex">
                        <Avatar
                            url={patient?.profilePic}
                            width="40px"
                            height="40px"
                            alt={patient?.firstName}
                            initials={userInitals(patient?.firstName, patient?.lastName)}
                            className="bg-brand_secondary w-[40px] h-[40px] mr-[8px] shrink-0"
                        />
                        <div className="text-neutral_white font-campton_r">
                            <p className="text-14 font-campton_sb">{patient?.firstName} {patient?.lastName}</p>
                            <p className="text-12">{patient?.emailAddress} | {patient?.gNumber} | {patient?.countryCode}{patient?.phoneNumber}</p>
                        </div>
                    </div>
                </div>
                <p className="text-14 text-meutral_black font-campton_r mb-[12px]">Service Details</p>
                <div>
                    {services?.map(item => {
                        return item?.selected?.length ?
                            <ServiceItem
                                key={item?.module}
                                item={item}
                                title={item?.module}
                                amount={"25000.00"}
                            /> : ''
                    })}
                    <div
                        className="text-14 text-neutral_black font-campton_r bg-[#F7F8F8] overflow-y-hidden flex justify-between py-[12px] px-[10px]"
                    >
                        <p className="flex items-center capitalize">Total Billing Amount</p>
                        <p className="text-right">{formatCurrency(totalBillingAmount)}</p>
                    </div>
                </div>
            </div>
            <div className="flex justify-end mt-[24px]">
                <Button
                    name="Cancel"
                    theme="transparent"
                    type="button"
                    onClick={cancel}
                    disabled={loading}
                    className="h-[40px] text-16 w-[79px] mr-[16px]"
                />
                <Button
                    name="Create Bill"
                    theme="blue"
                    type="button"
                    onClick={createBill}
                    loading={loading}
                    disabled={loading}
                    className="h-[40px] text-16 w-[120px]"
                />
            </div>
        </div>
    )
}

const ServiceItem = ({ item }) => {
    const [display, setDisplay] = useState(false)

    const total = item?.selected.reduce((accumulator, service) => {
        return accumulator + service?.totalFee
    }, 0)

    return (
        <>
            <div
                data-testid={item?.module}
                className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer hide_tap flex justify-between py-[12px]"
                onClick={() => setDisplay(!display)}
            >
                <p className="px-[10px] py-[8px] flex items-center capitalize">
                    <span className="w-[13.2px]">{display ? <ArrowDown fill="#748181" /> : <ArrowRight fill="#748181" />}</span>
                    <span className="ml-[8px]">{item?.module}</span>
                </p>
                <p className="px-[10px] py-[8px]">{formatCurrency(total)}</p>
            </div>
            {display && item?.selected?.map(item =>
                <div
                    key={item?.id}
                    className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 bg-[#F7F8F8] overflow-y-hidden flex justify-between"
                >
                    <p className="px-[10px] py-[8px] flex items-center capitalize pl-[30px]">
                        <Dot fill="#131515" className="w-[4px]" />
                        <span className="ml-[8px]">{item?.name}</span>
                    </p>
                    <p className="px-[10px] py-[8px] text-right">{formatCurrency(item?.totalFee)}</p>
                </div>
            )}
        </>
    )
}

PreviewInformation.propTypes = {
    back: PropTypes.func,
    cancel: PropTypes.func,
    patient: PropTypes.object,
    services: PropTypes.array,
    totalBillingAmount: PropTypes.number,
    createBill: PropTypes.func
}
ServiceItem.propTypes = {
    item: PropTypes.object
}