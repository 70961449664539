import React, { useEffect, useState } from 'react';
import CheckBox from 'components/Inputs/CheckBox';
import { Input } from 'components/Inputs';
import PropTypes from 'prop-types';
import RadioButtonSelect from 'components/RadioButtonSelect';
import { SlideUp } from 'components/Animations';
import { usePolicyState } from 'store/modules/policies';

const BillingPolicy = ({ formData, setFormData, action, type, loading, handleChange }) => {
  const [installment, setInstallment] = useState(false);
  const [fullPayment, setFullPayment] = useState(false);
  const [insurance, setInsurance] = useState(false);
  const [card, setCard] = useState(false);
  const [cash, setCash] = useState(false);
  const [choice, setChoice] = useState('');
  const { currentPolicy } = usePolicyState()

  const handleFormData = (e, key, name, type) => {
    const updatedFormData = {
      ...formData,
      [key]: {
        ...formData[key],
        [name]: e.target[type],
      },
    };
    setFormData(updatedFormData);
  };

  useEffect(() => {
    if (action === 'edit' && currentPolicy && type === "billingPolicy") {
      setFormData({
        ...formData,
        paymentPlan: currentPolicy?.currentBillingPolicy?.paymentPlan,
        paymentMethod: currentPolicy?.currentBillingPolicy?.paymentMethod,
        latePayment: currentPolicy?.currentBillingPolicy?.latePayment,
        daysAfterBilledDate: currentPolicy?.currentBillingPolicy?.daysAfterBilledDate,
        description: currentPolicy?.currentBillingPolicy?.description,
      })
      setFullPayment(currentPolicy?.currentBillingPolicy?.paymentPlan?.fullPayment)
      setInstallment(currentPolicy?.currentBillingPolicy?.paymentPlan?.installment)
      setInsurance(currentPolicy?.currentBillingPolicy?.paymentPlan?.insurance)
      setCard(currentPolicy?.currentBillingPolicy?.paymentMethod?.card)
      setCash(currentPolicy?.currentBillingPolicy?.paymentMethod?.cash)
      setChoice(currentPolicy?.currentBillingPolicy?.latePayment?.choice)
    }
  }, [type, action, currentPolicy])

  return (
    <SlideUp className="mt-6">
      <div className="border-y py-6 border-dashed border-neutral_stroke_2 text-12">
        <p className="font-campton_m text-neutral_black">Payment Plan</p>
        <p className="font-campton_r text-neutral_body mt-1 mb-3">
          What is your accepted payment methods for medical services?
        </p>
        <div className={`flex ${installment ? 'mb-3' : ''} space-x-6`}>
          <CheckBox
            variant="ml-0"
            label="Full payment"
            value={fullPayment}
            id="fullPayment"
            checked={formData?.paymentPlan?.fullPayment}
            onChange={(e) => {
              setFullPayment(!fullPayment)
              handleFormData(e, 'paymentPlan', 'fullPayment', 'checked');
            }}
          />
          <CheckBox
            variant="ml-0"
            label="Installments payment"
            value={installment}
            id="installment"
            checked={formData?.paymentPlan?.installment}
            onChange={(e) => {
              setInstallment(!installment)
              handleFormData(e, 'paymentPlan', 'installment', 'checked');
            }}
          />
        </div>
        {installment && (
          <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6">
            <Input
              label="Installment Percentage"
              type="number"
              id="installmentPercentage"
              name="installmentPercentage"
              value={formData?.paymentPlan?.installmentPercentage}
              onChange={(e) => handleFormData(e, 'paymentPlan', 'installmentPercentage', 'value')}
              disabled={loading}
              measurement="% of total bill"
            />
          </div>
        )}
      </div>
      <div className="border-b py-6 border-dashed border-neutral_stroke_2 text-12">
        <p className="font-campton_m text-neutral_black">Select Payment Method</p>
        <p className="font-campton_r text-neutral_body mt-1 mb-3">
          What is your accepted payment methods for all medical services?
        </p>
        <div className="flex space-x-6">
          <CheckBox
            variant="ml-0"
            label="Cash"
            value={cash}
            id="cash"
            checked={formData?.paymentMethod?.cash}
            onChange={(e) => {
              setCash(!cash);
              handleFormData(e, 'paymentMethod', 'cash', 'checked');
            }}
          />
          <CheckBox
            variant="ml-0"
            label="Card"
            value={card}
            id="card"
            checked={formData?.paymentMethod?.card}
            onChange={(e) => {
              setCard(!card);
              handleFormData(e, 'paymentMethod', 'card', 'checked');
            }}
          />
          <CheckBox
            variant="ml-0"
            label="Insurance Coverage"
            value={insurance}
            id="insurance"
            checked={formData?.paymentMethod?.insurance}
            onChange={(e) => {
              setInsurance(!insurance)
              handleFormData(e, 'paymentMethod', 'insurance', 'checked');
            }}
          />
        </div>
      </div>
      <div className="border-b py-6 border-dashed border-neutral_stroke_2 text-12">
        <p className="font-campton_m text-neutral_black">Payment Due Date</p>
        <p className="font-campton_r text-neutral_body mt-1 mb-3">
          What is the deadline for patients to settle outstanding balances or make payments for services rendered?
        </p>
        <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1">
          <Input
            label="Set Due Date"
            type="number"
            id="daysAfterBilledDate"
            name="daysAfterBilledDate"
            value={formData?.daysAfterBilledDate}
            onChange={(e) => handleChange(e, setFormData)}
            disabled={loading}
            measurement="Days after billed date"
          />
        </div>
      </div>
      <div className="py-6 border-neutral_stroke_2 text-12">
        <p className="font-campton_m text-neutral_black">Late Payment</p>
        <p className="font-campton_r text-neutral_body mt-1 mb-3">
          Do you impose extra fees for late payments or overdue balances?
        </p>
        <div className="flex">
          <RadioButtonSelect
            label="Yes"
            labelStyles="text-neutral_black text-14"
            variant="mr-6"
            isChecked={formData?.latePayment?.choice === 'yes'}
            onChange={() => {
              setChoice('yes');
              setFormData((formData) => ({
                ...formData,
                latePayment: { ...formData.latePayment, choice: 'yes' },
              }));
            }}
          />
          <RadioButtonSelect
            label="No"
            variant="mr-6"
            labelStyles="text-neutral_black text-14"
            isChecked={formData?.latePayment?.choice === 'no'}
            onChange={() => {
              setChoice('no');
              setFormData((formData) => ({
                ...formData,
                latePayment: { ...formData.latePayment, choice: 'no' },
              }));
            }}
          />
        </div>
        {choice === 'yes' && (
          <SlideUp>
            <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-5">
              <Input
                label="Late Payment Fee"
                type="number"
                id="feePercentage"
                name="feePercentage"
                value={formData?.latePayment?.feePercentage}
                onChange={(e) => handleFormData(e, 'latePayment', 'feePercentage', 'value')}
                disabled={loading}
                measurement="% of total bill (per) Day"
              />
            </div>
          </SlideUp>
        )}
      </div>
    </SlideUp>
  );
};

export default BillingPolicy;
BillingPolicy.propTypes = {
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
  type: PropTypes.string,
  action: PropTypes.string
};
