export const billing_history_header = ['Billing ID', 'Amount (NGN)', 'Bill Date', 'Due Date', 'Patient Name', 'Payment Method', 'Status', '']

export const billing_history_list_data = [
    {
        _id: 1,
        amount: '4000',
        billDate: 'May 27, 2020',
        dueDate: 'May 27, 2020',
        patientName: 'Kofo bankole',
        status: 'unpaid',
        billingId: 'RED1234567890'
    },
]

export const chart_options = [
    {
        id: '1',
        name: 'Monthly',
        value: 'monthly'
    },
    {
        id: '2',
        name: 'Yearly',
        value: 'yearly'
    }
]

export const chart_data = [
    {
        "month": "Jan",
        "total_outflow": 0
    },
    {
        "month": "Feb",
        "total_outflow": 150
    },
    {
        "month": "March",
        "total_outflow": 100
    },
    {
        "month": "Apr",
        "total_outflow": 90
    },
    {
        "month": "May",
        "total_outflow": 250
    },
    {
        "month": "Jun",
        "total_outflow": 250
    },
    {
        "month": "Jul",
        "total_outflow": 250
    },
    {
        "month": "Aug",
        "total_outflow": 250
    },
    {
        "month": "Sept",
        "total_outflow": 250
    },
    {
        "month": "Oct",
        "total_outflow": 250
    },
    {
        "month": "Nov",
        "total_outflow": 250
    },
    {
        "month": "Dec",
        "total_outflow": 250
    }
]

export const payment_status = [
    {
        id: '1',
        name: 'unpaid',
        value: 'unpaid'
    },
    {
        id: '2',
        name: 'partially paid',
        value: 'partial'
    },
    {
        id: '3',
        name: 'paid',
        value: "full"
    }
]

export const wallet_history_header = ['Transaction ID', 'Amount (NGN)', 'Types', 'Date']
