import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { SlideUp } from "components/Animations"
import SubNavbar from "components/SubNavbar"
import { Button } from "components/Buttons"
import AdditionalNote from "./AdditionalNote"
import useDebounce from "hooks/useDebounce"
import useProviders from "hooks/fetch/useProviders"
import { useDispatch, useSelector } from "react-redux"
import useHandleError from "hooks/useHandleError"
import { notify } from "store/modules/global"
import FormWrapper from "components/FormWrapper"
import useVitals from "hooks/fetch/useVitals"
import { removeSpecificKeys } from "utils/removeSpecificKeys"
import { useNavigate } from "react-router-dom"
import Navbar from "components/Navbar"
import PatientInformation from "pages/Clinicals/Components/PatientInformation"
import Accordion from "components/Accordion"
import AddVitals from "components/VitalSigns/AddVitals"

export default function NewVitals() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { handleError } = useHandleError()
    const { institutionId } = useSelector(state => state.auth)
    const { create_vitals, loading } = useVitals()
    const { get_patient_by_gNumber, loading: isFetchingPatient } = useProviders()
    const [patient, setPatient] = useState({
        firstName: '', lastName: '',
        emailAddress: '', gender: '',
        phoneNumber: '', dateOfBirth: '',
        profilePic: ''
    })
    const [gNumber, setGNumber] = useState('')
    const [formData, setFormData] = useState({
        sysBloodPressure: '', diaBloodPressure: '',
        heartRate: '', respiratoryRate: '',
        temperature: '', oxygenSaturation: '',
        weight: '', height: '',
        additionalNote: '', bmi: ''
    })

    const { debouncedValue, isTyping } = useDebounce(gNumber)

    const handleChange = (e) => {
        if (e.target.name === 'gNumber') return setGNumber(e.target.value)
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleVitalSigns = (e) => {
        if (isNaN(e.target.value)) return
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (debouncedValue.length === 10) {
            get_patient_by_gNumber(gNumber)
                .then(res => {
                    if (res?.code === 200) return setPatient(res?.data)
                    dispatch(notify({ display: true, status: 'error', message: handleError({ status: res?.code, message: res?.message }) }))
                    setPatient()
                })
        }
    }, [debouncedValue])

    useEffect(() => {
        if (formData.height && formData.weight) {
            setFormData({ ...formData, bmi: (formData.weight / (formData.height ** 2)).toString() })
        } else {
            setFormData({ ...formData, bmi: '' })
        }
    }, [formData.height, formData.weight])

    const submit = () => {
        const newFormData = { ...formData }
        const vitalSigns = removeSpecificKeys(newFormData, ['gNumber'])
        const enhancedFormData = { patient: patient?._id || '', ...vitalSigns, institutionId }
        create_vitals(enhancedFormData)
            .then(res => {
                if (res?.code === 200) navigate("/clinicals/vital-signs")
            })
    }

    const disabled = useMemo(() => {
        if (Object.values(formData).every(v => v === '')) return true
        if (!formData.sysBloodPressure && formData?.diaBloodPressure) return true
        if (formData.sysBloodPressure && !formData?.diaBloodPressure) return true
        return !patient?.firstName || isTyping || isFetchingPatient
    }, [patient, isTyping, formData, isFetchingPatient])

    useEffect(() => {
        document.title = 'New Vitals - Gabbi Health'
    }, [])

    return (
        <>
            <Navbar title="Vital Sign Tracking" />
            <SlideUp>
                <SubNavbar
                    previous="Vital sign tracking"
                    current="New vitals"
                    onClick={() => navigate("/clinicals/vital-signs")}
                />
                <FormWrapper className="p-[24px]" onSubmit={submit}>
                    <h2 className="text-18 text-neutral_black font-campton_m mb-[16px]">New Vitals</h2>
                    <PatientInformation
                        handleChange={handleChange}
                        patient={patient}
                        loading={isFetchingPatient}
                        gNumber={gNumber}
                    />
                    <Accordion
                        title="Vital Signs"
                        display={false}
                    >
                        <AddVitals
                            handleChange={handleVitalSigns}
                            formData={formData}
                            loading={false}
                        />
                    </Accordion>
                    <AdditionalNote
                        formData={formData}
                        handleChange={handleChange}
                        loading={false}
                    />
                    <div className="flex justify-end py-[20px]">
                        <Button
                            name="Cancel"
                            theme="transparent"
                            type="button"
                            onClick={() => navigate("/clinicals/vital-signs")}
                            className="h-[40px] w-[112px] text-16 px-[12px] mr-[16px]"
                            disabled={loading}
                        />
                        <Button
                            name="Save"
                            theme="blue"
                            className="h-[40px] w-[112px] text-16 px-[12px]"
                            disabled={disabled}
                            loading={loading}
                        //onClick={changeRole}
                        />
                    </div>
                </FormWrapper>
            </SlideUp>
        </>
    )
}

NewVitals.propTypes = {
    callback: PropTypes.func
}