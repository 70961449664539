import { SlideUp } from 'components/Animations';
import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import Avatar from 'components/Avatar/Avatar';
import { userInitals } from 'utils/ImagePlaceholder';
import Table from 'components/Table';
import { progress_notes_header, progress_notes_types } from 'mocks/patient';
import useTable from 'hooks/useTable';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from 'components/Navbar';
import BreadCrumbs from "components/BreadCrumbs";
import usePatient from "hooks/fetch/usePatient";
import useVisit from 'hooks/fetch/useVisit';
import useDebounce from 'hooks/useDebounce';
import { useClinicalState } from 'store/modules/clinicals';
import AddProgressNote from './AddProgressNote';
import Spinner from 'components/Spinner';

const ProgressNotes = () => {
  const { selectLimit, handleSearch, changePage, params } = useTable();
  const [inPatient, setInPatient] = useState()
  const [showModal, setShowModal] = useState(false)
  const { debouncedValue } = useDebounce(params.search);
  const { progressNotes } = useClinicalState();
  const navigate = useNavigate()
  const { get_in_patient, loading:loadingPatientDetails } = usePatient()
  const { get_progress_notes, loading } = useVisit()
  const { id } = useParams()

  const getInPatientProxy = () => {
    get_in_patient(id).then((res) =>
      setInPatient(res?.data)
    )
  }
  
  const progressNoteType = useMemo(() => {
    return progressNotes?.progressNotes?.map(note => note.type)},[progressNotes])

  const hasAddedAllTypes = progress_notes_types.every(value => progressNoteType?.includes(value))
  const options = [
    {
      title: 'In-Patient Management',
      method: () => navigate(-1)
    },
    {
      title: `${inPatient?.patientInfo?.firstName || ''} ${inPatient?.patientInfo?.lastName || ''}`,
      method: () => navigate(-1)
    },
    {
      title: 'Progress notes',
    },
  ]
  
  useEffect(() => {
    if (!inPatient) {
      getInPatientProxy()
    }
  }, [])

  useEffect(() => {
    if (!inPatient) {
      getInPatientProxy()
    } else {
      get_progress_notes({ ...params, visitId: inPatient?.visit });
    }
  }, [debouncedValue, params.page, params.limit, inPatient]);

  return (
    <main>
      <Navbar title="Patient Management" />
      <BreadCrumbs options={options} />
      <SlideUp>
        <section className="p-6">
        {loadingPatientDetails && !inPatient ? <Spinner height="h-[15vh]"/> :
          <div className="flex justify-between items-center border border-neutral_stroke_2 rounded-lg p-[30px]">
            <div className='flex space-x-2'>
              <Avatar
                url={inPatient?.patientInfo?.profilePic}
                width="80px"
                height="80px"
                //alt={singleVisit?.provider?.firstName?.split('')[0]}
                initials={userInitals(inPatient?.patientInfo?.firstName, inPatient?.patientInfo?.lastName)}
                className="bg-brand_secondary w-[80px] h-[80px] mb-[12px]"
              />
              <div className='mt-2'>
                <p className='font-campton_m text-neutral_black'>{inPatient?.patientInfo?.firstName} {inPatient?.patientInfo?.lastName}<span className='text-14 font-campton_r text-neutral_body border-l pl-3 ml-3 border-neutral_stroke_2'>{inPatient?.patientInfo?.gNumber}</span></p>
                <p className="font-campton_r text-14 mb-[4px]">{inPatient?.patientInfo?.emailAddress} </p>
                <p className="font-campton_r text-14">{inPatient?.patientInfo?.countryCode}{inPatient?.patientInfo?.phoneNumber} </p>
              </div>
            </div>
          </div>
        }
          <div className='mt-6'>
            <Table
              title="Progress Notes"
              headers={progress_notes_header}
              data={progressNotes?.progressNotes}
              totalCount={2}
              handleSearch={handleSearch}
              selectLimit={selectLimit}
              loading={loading || loadingPatientDetails}
              perPage={10}
              buttonFnc={() => setShowModal(true)}
              searchPlaceholder="Search"
              buttonName={hasAddedAllTypes ? '' : 'Add progress note'}
              changePage={changePage}
              currentPage={1}
              emptyStateTitle="You currently have no progress note"
              emptyStateCaption="progress note added will be displayed here"
            >
              <>
                {progressNotes?.progressNotes?.map((item) => (
                  <tr
                    data-testid={item?._id}
                    onClick={() => navigate(`${item?._id}/view`)}
                    key={item?._id}
                    className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                  >
                    <td className="px-[10px] py-[11px] capitalize text-14">
                      {dayjs(item?.createdAt).format('DD-MMM-YYYY')} {dayjs(item.createdAt).format('hh:mmA')}
                    </td>
                    <td className="px-[10px] py-[11px] capitalize text-14">{item?.type}</td>
                    <td className="px-[10px] py-[11px] capitalize text-14">{item?.addedByInfo?.firstName}{item?.addedByInfo?.lastName}</td>
                    <td className="px-[10px] py-[11px] capitalize">
                      <ArrowRight fill="#A9B2B2" />
                    </td>
                  </tr>
                ))}
              </>
            </Table>
          </div>
        </section>
        {
          showModal && <AddProgressNote closeModal={() => setShowModal(false)} visitId={inPatient?.visit} patientId={inPatient?.patientInfo?.id} addedType={progressNoteType}/>
        }
      </SlideUp>
    </main>
  );
};

export default ProgressNotes;

