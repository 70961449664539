import { service } from 'services/endpoints/service'
import { createCustomAsyncThunk, customAsyncThunk } from 'utils/createThunk'

const getServices = createCustomAsyncThunk('services/getServices', 'get', service.service)
const createService = createCustomAsyncThunk('services/createService', 'post', service.service)
const updateService = createCustomAsyncThunk('services/updateService', 'patch', service.service)
const deleteService = createCustomAsyncThunk('services/deleteService', 'delete', service.service)
const createServiceCategory = customAsyncThunk('services/createServiceCategory', 'post', service.category)
const updateServiceCategory = createCustomAsyncThunk('services/updateServiceCategory', 'patch', service.category)
const deleteServiceCategory = createCustomAsyncThunk('services/deleteServiceCategory', 'delete', service.category)
const getServiceCategories = createCustomAsyncThunk('services/getServiceCategories', 'get', service.service_categories)
const getServiceDetails = createCustomAsyncThunk('services/getServiceDetails', 'get', service.service_details)
const getServicesByInstitution = createCustomAsyncThunk('services/getServicesByInstitution', 'get', service.service_clinicals)

export {
    getServices,
    createService,
    createServiceCategory,
    updateServiceCategory,
    updateService,
    getServiceDetails,
    getServiceCategories,
    deleteServiceCategory,
    deleteService,
    getServicesByInstitution
}
