import { useState } from "react"
import useMakeRequest from "hooks/useMakeRequest"
import useTryCatch from "hooks/useTryCatch"
import { getInPatients, getPatients, getPatientsAnalytics, getInPatientsAnalytics, getHighRiskInPatients, addPatient } from "store/modules/patient/actions"
import { getProcedure, createProcedure, getReferrals, referPatient, createTheraphy, getTherapies, getPatientAppointment } from "store/modules/clinicals/actions"

export default function usePatient() {
    const { makeRequest, makeAnonymousRequest } = useMakeRequest()
    const [loading, setLoading] = useState(false)
    const { tryCatch } = useTryCatch()

    const get_procedure = (params) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getProcedure({ params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const create_procedure = (formData, callback) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: createProcedure(formData), callback })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_referrals = (params) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getReferrals({ params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const refer_patient = (formData, callback) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: referPatient(formData), callback })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_therapies = (params) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getTherapies({ params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const create_therapy = (formData, callback) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: createTheraphy(formData), callback })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_patients = (params, callback) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getPatients({ params }), alert: false, callback })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const add_patient = (formData, callback) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: addPatient(formData), callback })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_patients_analytics = (params) => {
        tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getPatientsAnalytics({ params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_in_patients_analytics = ({ params }) => {
        tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getInPatientsAnalytics({ params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const admit_patient = ({ institutionId, payload }) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url: `/in-patient/${institutionId}/admit`, method: 'post', payload })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_in_patients = ({ params }) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getInPatients({ params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_in_patient = (patientId) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url: `/in-patient/${patientId}/details`, method: 'get', alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_patient_visits = ({ patientId }) => {
        const url = `/institution/patient/${patientId}/visits`
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'get', alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const remove_staff_from_care_team = ({ patientId, staffId }) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url: `/in-patient/${patientId}/remove-staff/${staffId}`, method: 'patch' })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_high_risk_in_patients = ({ params }) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getHighRiskInPatients({ params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_patients_clinical_analytics = ({ patientId, visitId, type }) => {
        const url = patientId ? `/patient-profile/vitals-history/${patientId}?type=${type}` :
            `/patient-profile/vitals-history/visit/${visitId}?type=${type}`
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'get', alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const discharge_patient = ({ patientId, payload }) => {
        const url = `/in-patient/${patientId}/discharge`
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'patch', payload })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_patient_appointment = (id, params) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getPatientAppointment({ pathname: id, params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }
    const get_patient_bills = ({ params }) => {
        const url = `/institution/billing-history`
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'get', params, alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    return {
        get_patients,
        get_patients_analytics,
        admit_patient,
        get_in_patients,
        get_in_patients_analytics,
        remove_staff_from_care_team,
        get_in_patient,
        get_high_risk_in_patients,
        get_patients_clinical_analytics,
        get_patient_visits,
        loading,
        get_procedure,
        create_procedure,
        get_referrals,
        refer_patient,
        get_therapies,
        create_therapy,
        add_patient,
        discharge_patient,
        get_patient_appointment,
        get_patient_bills
    }
}