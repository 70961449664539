import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { userInitals } from 'utils/ImagePlaceholder';
import Avatar from 'components/Avatar/Avatar';

const ViewTherapy = ({ data }) => {
  return (
    <>
      <div className="border border-neutral_stroke_2 rounded-lg py-4 px-6 text-14 font-campton_r mb-6">
        <p className="font-campton_r text-12 text-neutral_body mb-4">Details</p>
        <div className="pb-4 border-b border-neutral_stroke_2">
          <p className="text-neutral_black">Therapy Name</p>
          <p className="text-neutral_body">{data?.therapyName}</p>
        </div>
        <div className="py-4 border-b border-neutral_stroke_2 ">
          <p className="text-neutral_black">Therapy Date</p>
          <p className="text-neutral_body">{dayjs(data?.therapyDate).format('MMMM DD, YYYY')}</p>
        </div>
        <div className="flex justify-between py-4 border-b border-neutral_stroke_2">
          <div>
            <p className="text-neutral_black">Start Time</p>
            <p className="text-neutral_body">{data?.startTime}</p>
          </div>
          <div>
            <p className="text-neutral_black">End Time</p>
            <p className="text-neutral_body">{data?.endTime}</p>
          </div>
        </div>
        <div className="pt-4 border-neutral_stroke_2">
          <p className="text-neutral_black">Note</p>
          <p className="text-neutral_body">{data?.note}</p>
        </div>
      </div>
      <div className="border border-neutral_stroke_2 p-4 rounded-2xl">
        <p className="text-neutral_body font-campton_r text-12 mb-4">Provider Information</p>
        <div className="flex justify-between items-start mt-4">
          <div className="flex">
            <Avatar
              url={data?.createdBy?.profilePic}
              width="32px"
              height="32px"
              textVariant="!text-12"
              alt={data?.createdBy?.firstName || data?.createdBy?.businessName}
              initials={userInitals(
                data?.createdBy?.firstName || data?.createdBy?.businessName?.split(' ')[0],
                data?.createdBy?.lastName || data?.createdBy?.businessName?.split(' ')[1],
              )}
              className="bg-brand_secondary mr-1.5 w-8 h-8"
            />
            <div className="font-campton_r">
              <p className="text-neutral_black text-14">
                {data?.createdBy?.firstName
                  ? `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`
                  : `${data?.createdBy?.businessName}`}
              </p>
              <p className="text-12 text-neutral_body">{data?.createdBy?.emailAddress || 'N/A'}</p>
              { data?.createdBy?.firstName && <p className="text-12 text-neutral_body">
                {`${data?.createdBy?.title ?? 'N/A'} | ${data?.createdBy?.specialty ?? 'N/A'} | ${data?.createdBy?.qualification}`}
              </p>}
            </div>
          </div>
          <p className="text-12 text-neutral_body font-campton_r">{data?.createdBy?.GPINumber ?? 'N/A'}</p>
        </div>
      </div>
    </>
  );
};

export default ViewTherapy;
ViewTherapy.propTypes = {
  callback: PropTypes.any,
  data: PropTypes.object,
};
