import { SlideUp } from "components/Animations";
import StaffDetails from "components/Cards/StaffDetails";
import { ReactComponent as Note } from "assets/icons/note2.svg";
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types"
import { Button } from "components/Buttons";
import Modal from "components/Modals/Modal";
import BlockTimeSlot from "pages/EShift/Components/BlockTimeSlot";
import SwapTimeSlot from "pages/EShift/Components/SwapTimeSlot";
import MyCalendar from "components/MyCalendar";
import { useSelector } from "react-redux";
import useProviders from "hooks/fetch/useProviders";
import Spinner from "components/Spinner";
import { userInitals } from "utils/ImagePlaceholder";
import { checkWorkingDays } from "utils/convertDays";
import useShift from "hooks/fetch/useShift";
import { getLocalTimeFromUTC } from "utils/formatDate";

export default function StaffCalendarDetails({ staff, shiftId, providerId }) {
    const { institutionId, isInstitution, user } = useSelector(state => state.auth)
    const { providerCalendar } = useSelector(state => state.providers)
    const { loading: isFetchingShift, get_shift } = useShift();
    const [shift, setShift] = useState();
    const { loading: isFetchingEvents, get_provider_calendar } = useProviders()
    const [blockSlot, setBlockSlot] = useState(false)
    const [swapTime, setSwapTime] = useState(false)
    const loggedUserId = user?._id

    const getProviderCalendarProxy = () => {
        get_provider_calendar(`${providerId}/calendar`)
    }

    useEffect(() => {
        getProviderCalendarProxy()
    }, [])

    useEffect(() => {
        get_shift({ pathname: `${institutionId}/${shiftId}` })
            .then((res) => setShift(res?.data?.shift || {}));
    }, [])

    const events = useMemo(() => {
        return Array.isArray(providerCalendar) ? [] : providerCalendar?.entries?.map(item => {
            return {
                start: getLocalTimeFromUTC(item.startTime),
                end: getLocalTimeFromUTC(item.endTime),
                title: item.type
            }
        })
    }, [providerCalendar])

    const closeModal = () => {
        setBlockSlot(false)
        setSwapTime(false)
    }

    return (
        <>
            <SlideUp>
                <div className="p-[24px]">
                    <section className="flex flex-col lg:flex-row gap-[24px] border-b border-b-neutral_stroke_2 pb-[24px]">
                        <StaffDetails
                            name={`${staff?.firstName} ${staff?.lastName}`}
                            image={staff?.profilePic}
                            gender={staff?.gender}
                            status={staff?.role}
                            initials={userInitals(staff?.firstName, staff?.lastName)}
                            emailAddress={staff?.emailAddress}
                            phoneNumber={staff?.phoneNumber}
                            countryCode={staff?.countryCode}
                            specialty={staff?.specialty || 'N/A'}
                            location={staff?.locationName || 'N/A'}
                            qualification={staff?.qualification || 'N/A'}
                            className="w-full"
                        />
                        <div className="border border-neutral_stroke_2 rounded-[16px] w-full lg:w-[380px] shrink-0 p-[24px] flex flex-col justify-between">
                            {isFetchingShift ? <Spinner /> :
                                <>
                                    <div>
                                        <Note />
                                        <div className="pt-[16px] border-b border-b-[#eceeee2f] text-neutral_white mb-[12px] md:mb-[39px]">
                                            <p className="text-12 text-neutral_body font-campton_r mb-[4px]">Shift Name</p>
                                            <p className="text-16 text-neutral_black font-campton_r mb-[4px] capitalize">{shift?.shiftName}</p>
                                            <p className="text-12 text-neutral_body font-campton_r ellipsis_2_lines">{shift?.note}</p>
                                        </div>
                                    </div>
                                    <div className="border border-neutral_stroke_2 rounded-[8px] grid grid-cols-2 text-12 font-campton_r">
                                        <div className="px-[16px] py-[8px] border-r border-r-neutral_stroke_2">
                                            <p className="text-neutral_body">Working Days</p>
                                            <p className="text-neutral_black capitalize">{checkWorkingDays(shift?.workingDays)}</p>
                                        </div>
                                        <div className="px-[16px] py-[8px]">
                                            <p className="text-neutral_body">Shift Time</p>
                                            <p className="text-neutral_black">{shift?.startTime} - {shift?.endTime}</p>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </section>
                    {(isInstitution || loggedUserId === providerId) &&
                        <section className="flex justify-end pt-[24px] mb-[16px]">
                            <Button
                                name="Swap time slot"
                                theme="transparent"
                                className="w-[130px] h-[40px] mr-[16px]"
                                //disabled={loading}
                                //loading={loading}
                                onClick={() => setSwapTime(true)}
                            />
                            <Button
                                name="Block slot"
                                theme="blue"
                                className="w-[100px] h-[40px]"
                                //disabled={loading}
                                onClick={() => setBlockSlot(true)}
                            />
                        </section>
                    }
                    {isFetchingEvents ? <Spinner /> : <MyCalendar events={events} />}
                </div>
            </SlideUp>
            {(blockSlot || swapTime) && (
                <Modal
                    closeModal={closeModal}
                    styles="w-full md:w-[457px]"
                    position="modal-right"
                    variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
                    title={blockSlot ? "Block Time Slot" : "Swap Time Slot"}
                >
                    {blockSlot ?
                        <BlockTimeSlot cancel={closeModal} callback={getProviderCalendarProxy} /> :
                        <SwapTimeSlot
                            cancel={closeModal}
                            callback={getProviderCalendarProxy}
                            staffId={staff._id}
                            shiftId={shiftId}
                        />
                    }
                </Modal>
            )}
        </>
    )
}

StaffCalendarDetails.propTypes = {
    staff: PropTypes.object,
    shiftId: PropTypes.string,
    providerId: PropTypes.string
}