import Navbar from 'components/Navbar';
import SubNavbar from 'components/SubNavbar';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { userInitals } from 'utils/ImagePlaceholder';
import Avatar from 'components/Avatar/Avatar';
import useVisit from 'hooks/fetch/useVisit';
import Spinner from 'components/Spinner';
import { VisitDetailsContext } from 'context';
import { SlideUp } from 'components/Animations';
import Restricted from 'HOC/Restricted';
import usePermission from 'hooks/usePermission';
import VisitDetails from 'components/Visit/VisitDetails';
import ProgressNoteHeader from './Components/ProgressNoteHeader';
import { formatDate } from 'utils/formatDate';
import { formatTwelveHour } from 'utils/formatTime';
import useProgressNote from 'hooks/fetch/useProgressNote';

const ViewSingleVist = () => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation()
  const { get_single_visit, get_progress_notes, loading } = useVisit();
  const { get_single_progress_note } = useProgressNote()
  const [singleVisit, setSingleVisit] = useState()
  const [progressNote, setProgressNote] = useState()
  const [allProgressNotes, setAllProgressNotes] = useState()
  const visitId = pathname?.split('/')[3]
  const visitStatus = (singleVisit?.status === 'completed' || progressNote?.eSignature?._id) ? true : false
  const { hasPermission } = usePermission()
  const canUpdate = hasPermission('Visits', 'Update visits')
  const isProgressNote = search?.includes('progress-note')
  const patientInfo = singleVisit?.patient || allProgressNotes?.progressNotes[0]?.patientInfo
  const [progressNoteFilter, setProgressNoteFilter] = useState()
  const [selectedProgressNoteFilter, setSelectedProgressNoteFilter] = useState()

  const getSingleVisit = () => {
    get_single_visit(visitId)
      .then((res) => setSingleVisit(res?.data))
  }

  const getProgressNotes = () => {
    get_progress_notes({ visitId: visitId })
      .then((res) => {
        setAllProgressNotes(res?.data)
        const filter = res?.data?.progressNotes?.map(({ _id, createdAt }) => ({
          _id,
          createdAt: `${formatDate(createdAt)} ${formatTwelveHour(createdAt)}`
        }));
        setProgressNoteFilter(filter)
        setSelectedProgressNoteFilter(filter[0])
      })
  }

  useEffect(() => {
    if (!isProgressNote) return getSingleVisit()
    getProgressNotes()
  }, [])

  useEffect(() => {
    if (selectedProgressNoteFilter?._id)
      get_single_progress_note(selectedProgressNoteFilter?._id)
        .then((res) => setProgressNote(res?.data[0]))
  }, [selectedProgressNoteFilter])

  const contextValues = useMemo(() => {
    return { singleVisit, setSingleVisit, progressNote, visitStatus, canUpdate, getSingleVisit }
  }, [singleVisit, progressNote, visitStatus, canUpdate])

  return (
    <div>
      <Navbar
        title={
          <span className="flex">
            <span className="md:block hidden">Clinical Documentation/</span>Visits
          </span>
        }
      />
      <Restricted module='Visits' permission='View visits'>
        <>
          <SubNavbar
            previous="Patient Visit Documentation"
            current={`${patientInfo?.firstName || ''} ${patientInfo?.lastName || ''}`}
            onClick={() => navigate('/clinicals/visits')}
          />
          {!singleVisit && loading ? <Spinner height="h-[80vh]" /> :
            <SlideUp>
              <section className='p-[24px]'>
                <div className="rounded-lg border border-neutral_stroke_2">
                  <div className="h-[96px] bg-[#36A1C5] rounded-t-lg relative">
                    <Avatar
                      url={patientInfo?.profilePic}
                      width="96px"
                      height="96px"
                      alt={patientInfo?.firstName?.split('')[0]}
                      initials={userInitals(patientInfo?.firstName, patientInfo?.lastName)}
                      textVariant='md:text-24 text-18'
                      className="bg-none w-[96px] h-[96px] bg-primary_shade_200 absolute top-10 left-6"
                    />
                  </div>
                  <div className="px-4 bg-neutral_white rounded-b-lg flex justify-between pt-16 pb-6">
                    <div>
                      <p className="font-campton_m text-neutral_black capitalize">
                        {patientInfo?.firstName} {patientInfo?.lastName}
                      </p>
                      <div className="font-campton_r text-14 text-neutral_body mt-1">
                        <p>{patientInfo?.emailAddress}</p>
                        <p className="flex space-x-1 mt-1">
                          <span>{patientInfo?.countryCode ? `${patientInfo?.countryCode}${patientInfo?.phoneNumber}` : ''}</span>
                        </p>
                      </div>
                    </div>
                    <p className="font-campton_r text-14 text-neutral_body">{patientInfo?.gNumber || ''}</p>
                  </div>
                </div>
              </section>
              {isProgressNote &&
                <ProgressNoteHeader
                  filters={progressNoteFilter}
                  selected={selectedProgressNoteFilter}
                  select={setSelectedProgressNoteFilter}
                />
              }
              <VisitDetailsContext.Provider value={contextValues}>
                <div className="border-t border-neutral_stroke_2">
                  <VisitDetails
                    purpose="visit"
                    visitStatus={visitStatus}
                    callback={getSingleVisit}
                  />
                </div>
              </VisitDetailsContext.Provider>
            </SlideUp>
          }
        </>
      </Restricted>
    </div>
  );
}

export default ViewSingleVist;
