import React, { useContext } from "react";
import { SlideUp } from "components/Animations";
import Summary from "./Components/Summary";
import Instruction from "./Components/Instruction";
import { VisitDetailsContext } from "context";
import PropTypes from "prop-types"

export default function VisitSummary({ visitStatus, callback }) {
    const visitId = location?.pathname?.split('/')[3]
    const { singleVisit, setSingleVisit, canUpdate, setProgressNote, progressNote } = useContext(VisitDetailsContext)

    return (
        <>
            <h2 className="text-16 sm:text-18 text-[#0D0F11] font-campton_m mb-[16px]">Visit Summary</h2>
            <SlideUp>
                <Summary 
                    visitId={visitId} 
                    visit={singleVisit} 
                    setVisit={setSingleVisit} 
                    visitStatus={visitStatus} 
                    canUpdate={canUpdate} 
                    callback={callback}
                    setProgressNote={setProgressNote} 
                    progressNote={progressNote}
                />
                <Instruction 
                    visit={singleVisit} 
                    canUpdate={canUpdate} 
                    callback={callback}
                    visitStatus={visitStatus}
                    setProgressNote={setProgressNote} 
                    progressNote={progressNote}
                />
            </SlideUp>
        </>
    )
}

VisitSummary.propTypes = {
    callback: PropTypes.func,
    visitStatus: PropTypes.bool
  }