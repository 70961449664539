import React from "react";
import PropTypes from "prop-types"
import { TextArea } from "components/Inputs";
import Accordion from "components/Accordion";

export default function AdditionalNote({ formData, handleChange, loading }) {
    return (
        <Accordion
            display={false}
            title={
                <>Additional Notes <span className="text-14 text-neutral_body font-campton_r">(Optional)</span></>
            }
        >
            <div className="pt-[24px]">
                <TextArea
                    label="Note"
                    type="text"
                    id="additionalNote"
                    name="additionalNote"
                    readOnly={loading}
                    value={formData?.additionalNote}
                    onChange={handleChange}
                    rows={3}
                />
            </div>
        </Accordion>
    )
}

AdditionalNote.propTypes = {
    formData: PropTypes.object,
    handleChange: PropTypes.func,
    loading: PropTypes.bool
}