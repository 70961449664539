import React, { useContext, useMemo, useState } from 'react';
import PropTypes from "prop-types"
import { ReactComponent as History } from 'assets/icons/history.svg';
import { ReactComponent as Lifestyle } from 'assets/icons/lifestyle.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import {
  ChiefComplaint,
  PatientHistory,
  PhysicalExamination,
  ViewVitals,
  TreatmentPlan,
  VisitSummary,
} from './Tabs';
import { ReactComponent as Vitals } from 'assets/icons/vitals.svg';
import { ReactComponent as Treatment } from 'assets/icons/treatment-plan.svg';
import { ReactComponent as Signature } from 'assets/icons/electronic-signature.svg';
import { ReactComponent as Summary } from 'assets/icons/visit-summary.svg';
import { ReactComponent as Complaint } from 'assets/icons/complaint.svg';
import VerticalMenuTab from 'components/Cards/VerticalMenuTab';
import ElectronicSignature from './Tabs/ElectronicSignature';
import ClinicalAssessment from './Tabs/ClinicalAssessment';
import OrderTests from 'components/OrderTests';
import UpdateVitals from './Tabs/Vitals/UpdateVitals';
import useWindowWidth from 'hooks/useWindowWidth';
import { MEDIUM_WIDTH } from 'constants';
import { VisitDetailsContext } from 'context';

// THIS COMPONENT IS USED FOR BOTH VISIT AND PROGRESS NOTE

export default function VisitDetails({ purpose, visitStatus, callback }) {
  const [tab, setTab] = useState('Vital Signs');
  const visitId = window.location.pathname?.split('/')[3]
  const windowWidth = useWindowWidth()
  const { progressNote } = useContext(VisitDetailsContext)

  const vitals = useMemo(() => {
    if (purpose === 'visit') return <ViewVitals />
    return progressNote?.vitals?.length ? <ViewVitals /> : <UpdateVitals />
  }, [purpose, progressNote?.vitals])

  const options = [
    {
      id: 0,
      name: 'Vital Signs',
      icon: <Vitals fill={tab === 'Vital Signs' ? '#FF7A21' : '#748181'} />,
      component: vitals,
    },
    {
      id: 1,
      name: 'Chief Complaint',
      icon: <Complaint fill={tab === 'Chief Complaint' ? '#FF7A21' : '#748181'} />,
      component: <ChiefComplaint type="chiefComplaint" callback={callback} />,
    },
    {
      id: 2,
      name: 'History of Patient illness',
      icon: <Complaint fill={tab === 'History of Patient illness' ? '#FF7A21' : '#748181'} />,
      component: <ChiefComplaint type="patientIllness" callback={callback} />,
    },
    {
      id: 3,
      name: 'Patient History',
      icon: <History fill={tab === 'Patient History' ? '#FF7A21' : '#748181'} />,
      component: <PatientHistory />,
    },
    {
      id: 4,
      name: 'Physical Examination',
      icon: <Lifestyle fill={tab === 'Physical Examination' ? '#FF7A21' : '#748181'} />,
      component: <PhysicalExamination />,
    },
    {
      id: 5,
      name: 'Order Tests',
      icon: <History fill={tab === 'Order Tests' ? '#FF7A21' : '#748181'} />,
      component: <OrderTests
        purpose='visit'
        patientId={progressNote?.patient?._id}
        visitId={visitId}
        progressNote={progressNote}
        visitStatus={visitStatus}
        callback={callback}
      />,
    },
    {
      id: 6,
      name: 'Clinical Assessment',
      icon: <Plus fill={tab === 'Clinical Assessment' ? '#FF7A21' : '#748181'} />,
      component: <ClinicalAssessment />,
    },
    {
      id: 7,
      name: 'Treatment Plan',
      icon: <Treatment fill={tab === 'Treatment Plan' ? '#FF7A21' : '#748181'} />,
      component: <TreatmentPlan visitStatus={visitStatus} />,
    },
    {
      id: 8,
      name: 'Visit Summary',
      icon: <Summary fill={tab === 'Visit Summary' ? '#FF7A21' : '#748181'} />,
      component: <VisitSummary visitStatus={visitStatus} callback={callback} />,
    },
    {
      id: 9,
      name: 'Electronic Signature',
      icon: <Signature fill={tab === 'Electronic Signature' ? '#FF7A21' : '#748181'} />,
      component: <ElectronicSignature callback={callback} />,
    }
  ]

  const componentToDisplay = options.find((option) => option.name === tab)

  return (
    <div className='sm:flex'>
      <VerticalMenuTab options={options} tab={tab} setTab={setTab} />
      {windowWidth > (MEDIUM_WIDTH || 768) ?
        <div className="p-[24px] w-full sm:border-l border-l-neutral_stroke_2">
          {componentToDisplay?.component}
        </div> : ''
      }
    </div>
  )
}

VisitDetails.propTypes = {
  visitStatus: PropTypes.bool,
  callback: PropTypes.func,
  purpose: PropTypes.string,
}