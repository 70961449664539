import React, { useEffect, useMemo, useState } from 'react';
import useProviders from 'hooks/fetch/useProviders';
import PropTypes from 'prop-types';
import { PatientContext } from 'context';
import { default as PatientHistoryView } from "components/PatientHistory/PatientHistory"
import Spinner from 'components/Spinner';

const PatientHistory = ({ inPatient }) => {
  const [patient, setPatient] = useState();
  const { get_providers_patient, loading } = useProviders();

  useEffect(() => {
    get_providers_patient(inPatient?.patientInfo.id)
      .then((res) => setPatient(res?.data ?? undefined))
  }, [])

  const contextValues = useMemo(() => {
    return { patient, setPatient }
  }, [patient])

  return (
    <>
      {!patient && loading ? <Spinner /> :
        <PatientContext.Provider value={contextValues}>
          <PatientHistoryView />
        </PatientContext.Provider>
      }
    </>
  );
};

export default PatientHistory;
PatientHistory.propTypes = {
  inPatient: PropTypes.object
}
