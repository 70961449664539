import React, { useContext } from "react"
import { PatientContext } from "context"
import { ReactComponent as EmptyStateIcon } from "assets/svg/empty-blood-group.svg"
import EmptyState from "components/Table/EmptyState"
import AnimateList from "../AnimateList"
import { formatDate } from "utils/formatDate"
import Status from "components/Status"
import Layout from "../Layout"
import PropTypes from 'prop-types';

export default function InsuranceInformation({ view, callback, setModal, visitStatus, canUpdate = true }) {
    const { patient } = useContext(PatientContext)

    return (
        <Layout
        view={view}
        visitStatus={visitStatus && canUpdate}
        setModal={setModal}
        callback={callback}
        >
            <>
                {patient?.medicalRecord?.insuranceInformation?.length ?
                    patient?.medicalRecord?.insuranceInformation?.map((item, i) =>
                        <AnimateList key={item?._id} index={i}>
                            <div className="flex mt-[10px] border-b border-b-neutral_stroke_2 py-[24px]">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="32" height="32" rx="16" fill="#EBF6FA" />
                                    <path d="M23.0001 15.914C22.8859 14.6052 22.4072 13.3546 21.6182 12.3041C20.8291 11.2536 19.7615 10.4454 18.5363 9.97106C17.3111 9.49674 15.9775 9.37536 14.6869 9.62068C13.3962 9.86601 12.2001 10.4682 11.2344 11.359C9.94522 12.5434 9.14872 14.1694 9.00318 15.914C8.99132 16.0521 9.00828 16.1911 9.053 16.3223C9.09771 16.4534 9.16921 16.5739 9.26294 16.676C9.35667 16.778 9.4706 16.8595 9.59749 16.9152C9.72439 16.9709 9.86147 16.9997 10.0001 16.9996H15.5001V20.4996C15.5001 21.03 15.7108 21.5387 16.0858 21.9138C16.4609 22.2889 16.9696 22.4996 17.5001 22.4996C18.0305 22.4996 18.5392 22.2889 18.9143 21.9138C19.2893 21.5387 19.5001 21.03 19.5001 20.4996C19.5001 20.367 19.4474 20.2398 19.3536 20.146C19.2598 20.0523 19.1327 19.9996 19.0001 19.9996C18.8675 19.9996 18.7403 20.0523 18.6465 20.146C18.5527 20.2398 18.5001 20.367 18.5001 20.4996C18.5001 20.7648 18.3947 21.0192 18.2072 21.2067C18.0196 21.3942 17.7653 21.4996 17.5001 21.4996C17.2348 21.4996 16.9805 21.3942 16.793 21.2067C16.6054 21.0192 16.5001 20.7648 16.5001 20.4996V16.9996H22.0001C22.1389 17.0001 22.2763 16.9717 22.4036 16.9162C22.5309 16.8607 22.6452 16.7792 22.7392 16.6771C22.8333 16.575 22.9051 16.4544 22.95 16.323C22.9949 16.1916 23.0119 16.0523 23.0001 15.914ZM10.0001 15.9996C10.0986 14.8778 10.5095 13.8061 11.1861 12.9059C11.8626 12.0057 12.7779 11.3131 13.8281 10.9066C14.8782 10.5 16.0213 10.3959 17.1276 10.6058C18.234 10.8158 19.2594 11.3316 20.0876 12.0946C21.1943 13.1088 21.8773 14.5035 22.0001 15.9996H10.0001Z" fill="#36A1C5" />
                                </svg>
                                <div className="ml-[16px]">
                                    <div className="flex items-center mb-[4px]">
                                        <h3 className="text-16 text-neutral_black font-campton_m capitalize">{item?.providerName}</h3>
                                        <Status
                                            status={item?.plan}
                                            className="bg-[#E6F9EE] text-[#1BA154] font-campton_r ml-[8px]"
                                        />
                                    </div>
                                    <p className="text-14 text-neutral_body font-campton_r mb-[4px]">
                                        Duration: <span className="text-neutral_black">{formatDate(item?.startDate, 'number')} to {formatDate(item?.endDate, 'number')}</span>
                                    </p>
                                    <p className="text-14 text-neutral_body font-campton_r">
                                        Policy Number: <span className="text-neutral_black capitalize">{item?.policyNumber}</span>
                                    </p>
                                </div>
                            </div>
                        </AnimateList>
                    ) :
                    <EmptyState
                        title="No Insurance Information"
                        icon={<EmptyStateIcon className="mb-[24px]" />}
                    />
                }
            </>
        </Layout>
    )
}
InsuranceInformation.propTypes = {
    callback: PropTypes.func,
    view: PropTypes.string,
    setModal: PropTypes.func,
    visitStatus: PropTypes.bool,
    canUpdate: PropTypes.bool
  };