import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import Status from 'components/Status';
import { month_names } from 'mocks/date';
import { patient_reg_chart_dropdown } from 'mocks/patient';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import PropTypes from 'prop-types';
import { useClinicalState } from 'store/modules/clinicals';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  animation: { duration: 1000 },
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

const VisitAnalytics = ({ chartFilter, setChartFilter }) => {
  const { analytics } = useClinicalState();

  const chart = useMemo(() => {
    let data = [''];
    let labels = [''];
    analytics?.map((item) => {
      labels.push(chartFilter === 'monthly' ? Object.keys(month_names)[item.month - 1] : item?.year);
      data.push(item?.count);
      return item;
    });
    return { labels, data };
  }, [analytics]);

  const data = {
    labels: chart.labels,
    datasets: [
      {
        data: chart.data,
        backgroundColor: '#fff9b320',
        borderColor: '#23CE6B',
        //borderWidth: 2,
        borderDashOffset: [10],
        borderCapStyle: 'round',
        //fill: true,
        lineTension: '0.4',
        pointBackgroundColor: '#23CE6B',
        pointRadius: 0,
        pointHitRadius: 10,
        fill: true,
      },
    ],
  };
  return (
    <div className="px-[24px] pt-[28px] pb-[10px] border-b border-b-neutral_stroke_2">
      <div className="flex items-center justify-between mb-[21px]">
        <h3 className="text-16 text-neutral_black font-campton_m">Total Visits</h3>
        <div className="flex items-center">
          {patient_reg_chart_dropdown.map((item) => (
            <Status
              key={item.id}
              status={item.name}
              onClick={() => setChartFilter(item.name.toLowerCase())}
              className={`
                ${
                chartFilter === item.name.toLowerCase()
                    ? 'bg-light_blue text-brand_primary cursor-default border border-[#36A1C5]'
                    : 'border border-neutral_stroke_2 cursor-pointer text-[#143566]'
                }
                px-[12px] mr-[12px] text-14
            `}
            />
          ))}
        </div>
      </div>
      <div className="h-[210px] mb-[24px]">
        <Line data={data} options={chartOptions} />
      </div>
    </div>
  );
};

export default VisitAnalytics;
VisitAnalytics.propTypes = {
  setChartFilter: PropTypes.func,
  chartFilter: PropTypes.string,
};
