import React from "react";
import PropTypes from "prop-types";
import Avatar from "components/Avatar/Avatar";
import Status from "components/Status";

export default function StaffDetails({
    image, status, initials, name, emailAddress, phoneNumber, specialty, location, qualification, gender, className, countryCode
}) {
    return (
        <section className={`border border-neutral_stroke_2 rounded-[16px] ${className}`}>
            <div className="bg-brand_primary h-[105px] rounded-t-[16px] relative">
                <Avatar
                    url={image}
                    width="80px"
                    height="80px"
                    alt="image"
                    initials={initials}
                    className="w-[80px] h-[80px] absolute top-[65px] left-[24px] border border-neutral_white"
                />
            </div>
            <div className=" px-[24px] pt-[56px] pb-[24px]">
                <div className="flex justify-between mb-[4px]">
                    <div className="flex items-center">
                        <p className="text-16 text-neutral_black font-campton_m capitalize">{name}</p>
                        <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-[8px]">
                            <circle cx="2" cy="2" r="2" fill="#DFE2E2" />
                        </svg>
                        <p className="text-14 text-neutral_body font-campton_r capitalize">{gender?.charAt(0)}</p>
                    </div>
                    <Status status={status} className="bg-[#E6F9EE] text-[#1BA154]" />
                </div>
                <p className="text-14 text-neutral_body font-campton_r mb-[16px]">{emailAddress} <span className="block md:inline">{countryCode}{phoneNumber}</span></p>
                <div className="border border-neutral_stroke_2 rounded-[8px] grid grid-cols-3 text-12 font-campton_r">
                    <div className="px-[16px] py-[8px] border-r border-r-neutral_stroke_2">
                        <p className="text-neutral_body">Specialty</p>
                        <p className="text-neutral_black capitalize">{specialty}</p>
                    </div>
                    <div className="px-[16px] py-[8px] border-r border-r-neutral_stroke_2">
                        <p className="text-neutral_body">Location</p>
                        <p title={location} className="text-neutral_black capitalize truncate">{location}</p>
                    </div>
                    <div className="px-[16px] py-[8px]">
                        <p className="text-neutral_body">Qualifications</p>
                        <p className="text-neutral_black">{qualification}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

StaffDetails.propTypes = {
    image: PropTypes.string,
    status: PropTypes.string,
    initials: PropTypes.string,
    name: PropTypes.string,
    emailAddress: PropTypes.string,
    phoneNumber: PropTypes.string,
    countryCode: PropTypes.string,
    specialty: PropTypes.string,
    location: PropTypes.string,
    qualification: PropTypes.string,
    gender: PropTypes.string,
    className: PropTypes.string
}