import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'hooks/useReduxHook';
import { getSpecialties, getSpecialtyStaff, getSpecialty } from './actions';

const initialState = {
    specialties: {},
    staffs: {},
    specialty: {}
  };

  export const specialty = createSlice({
    name: 'specialty',
    initialState,
    extraReducers: (builder) => {
      builder
        //GET SPECIALTIES
        .addCase(getSpecialties.fulfilled, (state, action) => {
          state.specialties = action?.payload?.data;
        })
        // getSpecialtyStaff
        .addCase(getSpecialtyStaff.fulfilled, (state, action) => {
          state.staffs = action?.payload?.data;
        })
          // getSpecialtyStaff
          .addCase(getSpecialty.fulfilled, (state, action) => {
            state.specialty = action?.payload?.data;
          })
    },
  });
  // Selectors
  const selectSpecialty = (state) => state.specialty;

  //App Redux State
  export const useSpecialtyState = () => useAppSelector(selectSpecialty);
  
  export default specialty.reducer;
