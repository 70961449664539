import { Button } from 'components/Buttons';
import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import useAuth from 'hooks/fetch/useAuth';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ForgotPasswordForm = ({ callback }) => {
  //Local State
  const [formData, setFormData] = useState({
    emailAddress: '',
  });

  //Hooks
  const { forgot_password, loading2 } = useAuth();

  //Handler Function
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const receiveOTP = () => {
    forgot_password(formData, callback);
  };
  const btnDisabled = () => {
    return !formData.emailAddress;
  };
  return (
    <FormWrapper
      title="Forgot Password"
      description="Ok, let’s access your account!
        First, what’s your email?"
      caption="Email address should be your registered email"
      className="sm:h-full md:mt-12 mt-8 w-full sm:w-[387px] mx-auto"
      onSubmit={receiveOTP}
    >
      <div className="mt-10">
        <div className="mb-6">
          <Input
            label="Email"
            type="text"
            id="email"
            name="emailAddress"
            readOnly={loading2}
            value={formData?.emailAddress}
            onChange={handleChange}
          />
        </div>
        <Button
          name="Receive OTP"
          theme="blue"
          className="w-full h-12 mt-10"
          loading={loading2}
          disabled={btnDisabled()}
        />
        <p className="font-campton_m text-neutral_body md:text-16 text-14 mt-[22px] text-center">
          Remember password?
          <Link className="text-brand_secondary cursor-pointer ml-1" to="/">
            Login
          </Link>
        </p>
      </div>
    </FormWrapper>
  );
};

export default ForgotPasswordForm;
ForgotPasswordForm.propTypes = {
  callback: PropTypes.func,
};
