import { GPI_LENGTH } from "constants";
import dayjs from "dayjs";
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import usePatient from "./fetch/usePatient";
import useService from "./fetch/useService";
import useProviders from "./fetch/useProviders";

export default function useAddTherapy(callback, patientId, visitId, progressNote) {
    const [provider, setProvider] = useState({});
    const { user, institutionId } = useSelector(state => state.auth)
    const [gpiNumber, setGPINumber] = useState('');
    const { create_therapy, loading } = usePatient();
    const [therapies, setTherapies] = useState([])
    const { get_services, loading: loadingService } = useService();
    const { get_provider_by_gpiNumber, loading: isFetchingProvider } = useProviders();
    const [formData, setFormData] = useState({
        therapyName: '',
        therapyDate: '',
        startTime: '',
        endTime: '',
        note: '',
        patientId: progressNote?.patient?._id ?? patientId,
        visitId: progressNote?.visit ?? visitId,
    });

    const providerName = provider?.firstName ? `${provider?.firstName} ${provider?.lastName ?? ''}` : '';

    useEffect(() => {
        if (gpiNumber.length === (GPI_LENGTH ?? 12)) {
            get_provider_by_gpiNumber(gpiNumber).then((res) => {
                if (res?.code === 200) return setProvider(res?.data);
            });
        }
    }, [gpiNumber]);

    const handleSelect = (e) => {
        setFormData({ ...formData, therapyName: e.value });
    };

    const selectedTherapy = therapies?.find((item => item.name === formData.therapyName))

    const createTherapy = () => {
        create_therapy(
            {
                ...formData,
                progressNoteId: progressNote?._id,
                therapyDate: dayjs(formData.therapyDate).format('YYYY-MM-DD'),
                startTime: dayjs(formData.startTime).format('hh:mm A'),
                endTime: dayjs(formData.endTime).format('hh:mm A'),
                attendingProviderId: provider ? provider?._id : '',
                totalFee: Number(selectedTherapy.serviceProperties[0].totalFee),
                serviceDetails: [
                    {
                        category: 'Therapy',
                        subCategory: selectedTherapy?.name,
                        fee: selectedTherapy.serviceProperties[0].fee,
                        vat: selectedTherapy.serviceProperties[0].vat
                    }
                ]
            },
            callback,
        );
    };

    const getTheraphyServices = () => {
        get_services({
            limit: 1000,
            category: 'Therapy',
            type: 'institution',
            providerId: user?.type !== 'staff' ? '' : user?._id,
            institutionId,
        })
            .then(res => setTherapies(res?.data?.services || []))
    };

    useEffect(() => {
        getTheraphyServices();
    }, []);

    const therapyList = therapies.map(p => {
        return { name: p?.name, value: p?.name, id: p?._id }
    })

    return {
        therapyList,
        createTherapy,
        providerName,
        setGPINumber,
        isFetchingProvider,
        loadingService,
        loading,
        handleSelect,
        formData,
        setFormData,
        gpiNumber
    }
}