import React, { useMemo, useEffect } from 'react';
import { Input, TextArea } from 'components/Inputs';
import Dropdown from 'components/Dropdown';
import { useAuthState } from 'store/modules/auth';
import useAuth from 'hooks/fetch/useAuth';
import PropTypes from 'prop-types';
import Phone from 'components/Inputs/PhoneInput';

const LocationsForm = ({
  locationDetails,
  formData,
  setFormData,
  phone,
  setPhone,
  type,
  handleContactInfo,
  states,
  cities,
  isLoadingCountry,
  isLoadingCities,
  isLoadingStates
}) => {
  const { loading } = useAuth();
  const { user, countryList } = useAuthState();
  const pathname = window.location.pathname;

  const countries = useMemo(() => {
    return countryList?.map((item, index) => {
      return { id: index, value: item?.name, name: item?.name };
    })
  }, [countryList])

  const stateList = useMemo(() => {
    return states?.map((item, index) => {
      return { id: index, value: item?.name, name: item?.name };
    })
  }, [states])

  const cityList = useMemo(() => {
    return cities?.map((item, index) => {
      return { id: index, value: item, name: item };
    })
  }, [cities])

  useEffect(() => {
    if (type === 'Edit' && locationDetails) {
      setPhone({
        phoneNumber: locationDetails?.contactPersonInformation?.phoneNumber || null,
        countryCode: locationDetails?.contactPersonInformation?.countryCode || '',
      })
    }
  }, [locationDetails, type]);

  return (
    <div className="grid grid-cols-12 gap-[18px]">
      {pathname.includes('settings') && (
        <div className="col-span-12 mt-1">
          <Input
            label="Name"
            type="text"
            id="name"
            name="name"
            disabled={true}
            readOnly={loading}
            value={user?.businessName ? user?.businessName : `${user?.firstName} ${user?.lastName}`}
            variant="capitalize"
          />
        </div>
      )}
      <Dropdown
        type="search"
        options={countries}
        id="country"
        variant="col-span-12"
        placeholder={`${isLoadingCountry ? 'Loading countries...' : 'Country'}`}
        loading={isLoadingCountry}
        name="country"
        selected={formData?.country}
        onSelect={(data) => {
          setFormData({ ...formData, country: data.value, state: '', city: '' });
        }}
      />
      <Dropdown
        type="search"
        id="state"
        options={stateList}
        variant="md:col-span-6 col-span-12"
        placeholder={`${isLoadingStates ? 'Loading States...' : 'State of Operation'}`}
        loading={isLoadingStates}
        name="state"
        selected={formData?.state}
        onSelect={(data) => {
          setFormData({ ...formData, state: data.value, city: '' });
        }}
        disabled={!formData?.country || isLoadingStates}
      />
      <Dropdown
        type="search"
        id="city"
        variant="md:col-span-6 col-span-12"
        placeholder={`${isLoadingCities ? 'Loading Cities...' : 'City'}`}
        loading={isLoadingCities}
        options={cityList}
        name="city"
        selected={formData?.city}
        onSelect={(data) => {
          setFormData({ ...formData, city: data.value });
        }}
        disabled={!formData?.state || isLoadingCities}
      />
      <TextArea
        label="Address"
        type="text"
        id="address"
        name="address"
        variant="col-span-12 !mb-0"
        readOnly={loading}
        value={formData?.address}
        onChange={(e) => setFormData({ ...formData, address: e.target.value })}
      />
      <p className="font-campton_m text-neutral_black col-span-12 border-t border-dashed border-neutral_stroke_2 pt-6">
        Contact Person Information
      </p>
      <div className="col-span-12">
        <Input
          label="Name"
          type="text"
          id="name"
          name="name"
          readOnly={loading}
          value={formData?.contactPersonInformation?.name}
          onChange={handleContactInfo}
        />
      </div>
      <div className={`${pathname.includes('settings') ? '' : 'lg:col-span-6'} col-span-12`}>
        <Input
          label="Email Address"
          type="email"
          id="emailAddress"
          name="emailAddress"
          readOnly={loading}
          value={formData?.contactPersonInformation?.emailAddress}
          onChange={handleContactInfo}
        // showError={false}
        />
      </div>
      <Phone
        value={phone}
        onChange={setPhone}
        variant={`${pathname.includes('settings') ? '' : 'lg:col-span-6'}  col-span-12`}
      />
    </div>
  );
};

export default LocationsForm;
LocationsForm.propTypes = {
  locationDetails: PropTypes.object,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  setPhone: PropTypes.func,
  phone: PropTypes.object,
  handleContactInfo: PropTypes.func,
  type: PropTypes.string,
  states: PropTypes.array,
  cities: PropTypes.array,
  isLoadingCountry: PropTypes.bool,
  isLoadingCities: PropTypes.bool,
  isLoadingStates: PropTypes.bool
};
