export const restructureBills = (bills) => {
    if (!bills?.length) return []
    const restructuredBills = bills.reduce((acc, bill) => {
        const foundCategory = acc.find((item) => item.category === bill.category);
        if (foundCategory) {
            foundCategory.total += bill.fee + bill.vat;
            foundCategory.services.push({
                name: bill?.subCategory,
                fee: bill?.fee,
                vat: bill?.vat,
                _id: bill?._id,
            });
        } else {
            acc.push({
                total: bill.fee + bill.vat,
                category: bill.category,
                services: [
                    {
                        name: bill?.subCategory,
                        fee: bill?.fee,
                        vat: bill?.vat,
                        _id: bill?._id,
                    },
                ],
            });
        }
        return acc;
    }, []);

    return restructuredBills
}