import useProgressNote from "hooks/fetch/useProgressNote"
import usePermission from "hooks/usePermission"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

export default function useProgressNoteDetails() {
    const navigate = useNavigate()
    const purpose = location?.pathname?.split('/')[6]
    const progressNoteId = location?.pathname?.split('/')[5]
    const patientId = location?.pathname?.split('/')[3]
    const isPurposeToAdd = purpose === "add"
    const { hasPermission } = usePermission()
    const canUpdate = hasPermission('Visits', 'Update visits')
    const { get_single_progress_note, loading: isFetchingProgressNote } = useProgressNote()
    const [progressNote, setProgressNote] = useState([])
    const visitStatus = progressNote?.eSignature?._id

    const options = [
        {
            title: 'In-Patient Management',
            method: () => navigate(`/clinicals/in-patients`)
        },
        {
            title: progressNote?.patient?.firstName ? `${progressNote?.patient?.firstName} ${progressNote?.patient?.lastName}` : '...',
            method: () => navigate(`/clinicals/in-patients/${patientId}`)
        },
        {
            title: 'Progress notes',
            method: () => navigate(`/clinicals/in-patients/${patientId}/progress-notes`)
        },
        {
            title: `${purpose} progress notes`
        }
    ]

    const getSingleProgressNoteProxy = () => {
        get_single_progress_note(progressNoteId)
            .then(res => {
                if (res?.code) setProgressNote(res?.data[0])
            })
    }

    const contextValues = useMemo(() => {
        return { visitStatus, canUpdate, progressNote, setProgressNote, getSingleProgressNoteProxy }
    }, [visitStatus, canUpdate, progressNote])

    useEffect(() => {
        if (progressNoteId) getSingleProgressNoteProxy()
    }, [])

    return {
        contextValues,
        isPurposeToAdd,
        options,
        isFetchingProgressNote,
        progressNote,
        setProgressNote,
        getSingleProgressNoteProxy
    }
}