import React from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"

export default function SlideUp({ children, className }) {
    return (
        <motion.div
            initial={{ opacity: 0, y: "10px" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            className={className}
        >
            {children}
        </motion.div>
    )
}

SlideUp.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string
}