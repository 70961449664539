import { providers } from 'services/endpoints/providers'
import { institution } from 'services/endpoints/institution'
import { createCustomAsyncThunk, customAsyncThunk } from 'utils/createThunk'

const getProviderAppointments = createCustomAsyncThunk('appointment/getProviderAppointments', 'get', providers.get_appointments)
const getInstitutionAppointments = createCustomAsyncThunk('appointment/getInstitutionAppointments', 'get', institution.get_appointments)
const getAppointmentRequest = createCustomAsyncThunk('appointment/getAppointmentRequest', 'get', providers.providers)
const createAppointment = customAsyncThunk('appointment/createAppointment', 'post', institution.create_appointment)
const editAppointment = createCustomAsyncThunk('appointment/editAppointment', 'patch', institution.cancel_appointment)
const editAppointmentRequest = createCustomAsyncThunk('appointment/editAppointmentRequest', 'post', institution.cancel_appointment)

export {
    getProviderAppointments,
    getInstitutionAppointments,
    getAppointmentRequest,
    createAppointment,
    editAppointment,
    editAppointmentRequest
}