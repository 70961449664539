import FormWrapper from 'components/FormWrapper';
import { Input, TextArea } from 'components/Inputs';
import Table from 'components/Table';
import { ReactComponent as Close } from 'assets/icons/close-circle.svg';
import React, { useState, useEffect } from 'react';
import { checkEmptyProperties } from 'utils/checkEmptyProperties';
import dayjs from 'dayjs';
import { Button } from 'components/Buttons';
import useSpecialty from 'hooks/fetch/useSpecialty';
import PropTypes from 'prop-types';
import { useAuthState } from 'store/modules/auth';
import { useParams } from 'react-router-dom';
import { useSpecialtyState } from 'store/modules/specialty';
import Modal from 'components/Modals/Modal';

const AddSpecialty = ({ callback, action, closeModal, title }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
  });
  const { specialty } = useSpecialtyState();

  useEffect(() => {
    if (action === 'edit' && specialty) {
      setFormData({
        name: specialty?.name ?? '',
        description: specialty?.description ?? '',
      });
    }
  }, [action]);

  const pathname = window.location.pathname;

  const [specialtiesArray, setSpecialtiesArray] = useState([]);
  const { add_specialty, loading, edit_Specialty } = useSpecialty();
  const { institutionId } = useAuthState();
  const { id } = useParams();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const pushIntoArray = (formData) => {
    setSpecialtiesArray((prev) => [...prev, formData]);
    setFormData({
      name: '',
      description: '',
    });
  };

  const deleteSpecialty = (specialty) => {
    const newArrayData = [...specialtiesArray];
    const idx = newArrayData.findIndex((item) => item.specialty === specialty);
    newArrayData.splice(idx, 1);
    setSpecialtiesArray(newArrayData);
  };

  const handleSubmit = () => {
    const type = "institution"
    const payload = { specialtiesArray: pathname.includes('settings') ? [formData] : specialtiesArray, type };
    if (action === 'create') {
      add_specialty(`${institutionId}/add-specialty`, payload, callback);
    } else {
      edit_Specialty(`${institutionId}/specialty/${id}`, callback, {
        specialtyName: formData.name,
        specialtyDescription: formData.description,
        type
      });
    }
  };

  return (
    <Modal
      closeModal={closeModal}
      styles='w-full md:w-[457px]'
      position='modal-right'
      variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
      title={title}
    >
      <FormWrapper onSubmit={handleSubmit} className="relative">
        <div className={`${pathname.includes('settings') ? 'h-full' : 'lg:h-[400px]'} flex justify-between flex-col h-full`}>
          <div className='modal-right-body'>
            <div className={`${pathname.includes('settings') ? 'mt-2' : 'mt-6'} grid grid-cols-12 gap-6`}>
              <div className={`${pathname.includes('settings') ? '' : 'md:col-span-6'} col-span-12`}>
                <Input
                  label="Specialty Name"
                  type="text"
                  id="specialty"
                  name="name"
                  readOnly={loading}
                  value={formData?.name}
                  onChange={handleChange}
                />
              </div>
              {pathname.includes('settings') ? (
                <div className="col-span-12">
                  <TextArea
                    label="Description (optional)"
                    id="description"
                    name="description"
                    value={formData?.description}
                    onChange={handleChange}
                    rows={4}
                  />
                </div>
              ) : (
                <div className="md:col-span-6 col-span-12">
                  <Input
                    label="Description"
                    type="text"
                    id="description"
                    name="description"
                    // readOnly={loading}
                    value={formData?.description}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>
            {!pathname.includes('settings') && (
              <p
                className={`text-brand_secondary font-campton_m text-14 mt-6  ${checkEmptyProperties(formData) ? 'cursor-not-allowed' : 'cursor-pointer'
                  }`}
                data-testid="test-add"
                disabled={checkEmptyProperties(formData)}
                onClick={checkEmptyProperties(formData) ? null : () => pushIntoArray(formData)}
              >
                Add
              </p>
            )}
            {specialtiesArray.length > 0 && (
              <div className="pt-6 border-t border-dashed border-neutral_stroke_2 mt-6">
                <Table
                  hasPagination={false}
                  data={specialtiesArray}
                  tableContainer=""
                  headers={['Specialty Name', 'Date/Time Created', '']}
                  hasFilter={false}
                  module="Specialty"
                  permission=""
                >
                  <>
                    {specialtiesArray.map((item) => (
                      <tr
                        key={item.name}
                        className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer py-2.5"
                      >
                        <td className="px-2.5">{item.name}</td>
                        <td className="py-2.5 px-2.5">{dayjs(new Date()).format('DD - MMM- YYYY, hh:mmA')}</td>
                        <td data-testid="delete" onClick={deleteSpecialty}>
                          <Close />
                        </td>
                      </tr>
                    ))}
                  </>
                </Table>
              </div>
            )}
          </div>
          <div className="modal-right-button-container">
            <Button
              className={`${action === 'create' && pathname.includes('settings') ? 'w-fit' : 'w-28'
                } rounded-full h-10 font-campton_m`}
              theme="blue"
              disabled={pathname.includes('settings') ? !formData.name : !specialtiesArray.length}
              name={`${action === 'create' && pathname.includes('settings') ? 'Create Specialty' : 'Save'}`}
              loading={loading}
            />
          </div>
        </div>
      </FormWrapper>
    </Modal>
  );
};

export default AddSpecialty;
AddSpecialty.propTypes = {
  callback: PropTypes.func,
  action: PropTypes.string,
  title: PropTypes.string,
  closeModal: PropTypes.func
};
