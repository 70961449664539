import React, { useContext, useEffect, useState } from 'react'
import PropTypes from "prop-types"
import { Button } from 'components/Buttons';
import { ReactComponent as Note } from "assets/icons/note1.svg"
import { TextArea } from 'components/Inputs';
import { AnimatePresence, motion } from 'framer-motion';
import FormWrapper from 'components/FormWrapper';
import { VisitDetailsContext } from 'context';
import useVisit from 'hooks/fetch/useVisit';
import EmptyState from 'components/Table/EmptyState';

export default function Diagnosis({ title, id, data, initialValue, type, typeId, callback }) {
    const { visitStatus } = useContext(VisitDetailsContext)
    const [diagnosis, setDiagnosis] = useState('')
    const [toggleAccordion, setToggleAccordion] = useState(false)
    const [addDiagnosis, setAddDiagnosis] = useState(false)
    const { add_diagnosis, edit_diagnosis, loading } = useVisit()
    const { canUpdate } = useContext(VisitDetailsContext)

    const handleChange = (e) => {
        setDiagnosis(e.target.value)
    }

    useEffect(() => {
        if (initialValue) {
            setDiagnosis(initialValue)
            setToggleAccordion((initialValue || visitStatus) ? true : false)
            setAddDiagnosis((initialValue || visitStatus) ? false : true)
        }
    }, [initialValue])

    const save = () => {
        if (!diagnosis) return
        const addPayload = {
            type,
            [id]: diagnosis,
            [type]: typeId
        }
        const action = !data?.[id] ?
            add_diagnosis({ payload: addPayload }) :
            edit_diagnosis({ payload: { [id]: diagnosis }, id: data?._id })
        action.then(res => {
            if ([200, 201].includes(res?.code)) {
                callback()
                setAddDiagnosis(false)
            }
        })
    }

    return (
        <div className='rounded-[8px] border border-neutral_stroke_2 mb-[24px] p-[16px]'>
            <div className='flex items-center justify-between'>
                <div className="flex items-center">
                    <Note />
                    <p className="text-16 text-neutral_black font-campton_m ml-[11px]">{title}</p>
                </div>
                {!initialValue && canUpdate && !visitStatus &&
                    <Button
                        className="border-neutral_stroke_1"
                        type="button"
                        onClick={() => {
                            setToggleAccordion(true)
                            setAddDiagnosis(true)
                        }}
                    >
                        <p className="font-campton_m text-12 text-neutral_body py-[4px]">Add diagnosis</p>
                    </Button>
                }
            </div>
            <AnimatePresence>
                {toggleAccordion &&
                    <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: 'auto', transition: { duration: .4 } }}
                        exit={{ height: 0, transition: { duration: .4 } }}
                        className="overflow-y-hidden"
                    >
                        {addDiagnosis ?
                            <FormWrapper onSubmit={save}>
                                <div className="mb-[24px] pt-[24px]">
                                    <TextArea
                                        placeholder='Type here...'
                                        id={id}
                                        name={id}
                                        readOnly={loading}
                                        value={diagnosis}
                                        onChange={handleChange}
                                        rows={3}
                                    />
                                </div>
                                <div className="flex justify-end">
                                    <Button
                                        name="Cancel"
                                        theme="transparent"
                                        type='button'
                                        className="h-[32px] text-14 w-[72px] mr-[17px]"
                                        onClick={() => !data?.[id] ? setToggleAccordion(false) : setAddDiagnosis(false)}
                                        disabled={loading}
                                    />
                                    <Button
                                        name="Save"
                                        theme="blue"
                                        className='h-[32px] text-14 w-[58px]'
                                        disabled={!diagnosis || loading}
                                        loading={loading}
                                    />
                                </div>
                            </FormWrapper> :
                            <div>
                                <p className="text-14 text-neutral_body font-campton_r leading-[21px] my-[8px]">{diagnosis}</p>
                                {!diagnosis &&
                                    <EmptyState
                                        title={`No ${title}.`}
                                        //icon={<Empty />}
                                        caption='All diagnosis will be displayed here'
                                    />
                                }
                                {!visitStatus && canUpdate &&
                                    <div>
                                        <Button
                                            name="Edit"
                                            theme="transparent"
                                            className={`h-[32px] text-14 px-[12px] w-[58px]`}
                                            onClick={() => setAddDiagnosis(true)}
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    )
}

Diagnosis.propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    data: PropTypes.object,
    initialValue: PropTypes.any,
    type: PropTypes.string,
    typeId: PropTypes.string,
    callback: PropTypes.func
}