import { auth, onboarding } from 'services/endpoints';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/axios-instance';

const customAsyncThunk = (thunkName, method, url) =>
  createAsyncThunk(thunkName, async (payload) => {
    try {
      const body = { ...payload }
      const params = payload?.params ? payload?.params : ''
      const response = await api[method || 'get'](`${url}/${params}`, body)
      if (response?.data) return response.data ? response.data : {}
      if (response?.response?.data) return response.response.data ? response.response.data : {}
    } catch (error) {
      return error
    }
  })

const createCustomAsyncThunk = (thunkName, method, url) =>
  createAsyncThunk(thunkName, async (payload) => {
    try {
      const body = { ...payload?.payload }
      const pathname = payload?.pathname ? `/${payload?.pathname}` : ''
      const queryParams = payload?.params ? '?' + new URLSearchParams({ ...payload?.params }).toString() : ''
      const response = await api[method || 'get'](`${url}${pathname}${queryParams}`, body)
      if (response?.data) return response.data ? response.data : {}
      if (response?.response?.data) return response.response.data ? response.response.data : {}
    } catch (error) {
      return error
    }
  })

const login = customAsyncThunk('auth/login', 'post', auth.login);
const forgotPassword = customAsyncThunk('auth/forgotPassword', 'post', auth.forgot_password);
const logout = createAsyncThunk('auth/logout', async (payload, thunkAPI) => {
  thunkAPI.dispatch({ type: 'logout' });
});
const completeProviderProfile = customAsyncThunk(
  'auth/providers/completeProfile',
  'patch',
  auth.complete_provider_profile,
);
const createAccountProviders = customAsyncThunk('auth//providers/createAccount', 'post', auth.signup_provider);
const createAccountInstitution = customAsyncThunk('auth/institution/createAccouny', 'post', auth.institution);
const verifyAccount = customAsyncThunk('auth/verifyAccount', 'patch', auth.verify_account);
const validateToken = customAsyncThunk('auth/validateToken', 'post', auth.validate_token);
const resendOtp = customAsyncThunk('auth/resendOtp', 'post', auth.resend_otp);
const refreshToken = customAsyncThunk('auth/refreshToken', 'post', auth.refresh_token);
const changePassword = customAsyncThunk('auth/resetPassword', 'patch', auth.change_password);
const getInstitution = createAsyncThunk('auth/getInstitution', async (params) => {
  try {
    const response = await api.get(auth.institution, { params });
    return response.data ? response.data : {};
  } catch (error) {
    return Promise.reject(error);
  }
});
const updateBasicInfo = customAsyncThunk('auth/basicInfo', 'patch', onboarding.basic_information);
const updateContactPerson = customAsyncThunk('auth/updateContactPerson', 'patch', onboarding.contact_info);
const editContactPersonInfo = customAsyncThunk('auth/editContactPersonInfo', 'patch', onboarding.edit_contact_info);
const addLicense = customAsyncThunk('auth/addLicense', 'patch', onboarding.add_license);
const createLocation = customAsyncThunk('auth/createLocation', 'patch', onboarding.add_location);
const getCountries = customAsyncThunk('auth/Countries', 'get', 'https://countriesnow.space/api/v0.1/countries/positions')
const getState = createCustomAsyncThunk('auth/getState', 'post', 'https://countriesnow.space/api/v0.1/countries/states');
const getCities = createCustomAsyncThunk('auth/getCities', 'post', 'https://countriesnow.space/api/v0.1/countries/state/cities');
const requestUploadUrl = customAsyncThunk('auth/requestUploadUrl', 'post', auth.request_upload_url)

export {
  logout,
  login,
  forgotPassword,
  verifyAccount,
  validateToken,
  getInstitution,
  getCountries,
  refreshToken,
  completeProviderProfile,
  changePassword,
  getCities,
  resendOtp,
  getState,
  requestUploadUrl,
  createAccountProviders,
  createAccountInstitution,
  updateBasicInfo,
  createLocation,
  addLicense,
  editContactPersonInfo,
  updateContactPerson,
};
