import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types"
import { Button } from "components/Buttons";
import { DefaultDatepicker } from "components/Datepicker/Index";
import FormWrapper from "components/FormWrapper";
import { Input, TextArea } from "components/Inputs";
import { convertTimeTo12HourFormat } from "utils/formatTime";
import { formatToDateWithDash } from "utils/formatDate";
import useShift from "hooks/fetch/useShift";

export default function BlockTimeSlot({ cancel, callback, blockDate }) {
    const { loading, block_time_slot } = useShift()
    const [formData, setFormData] = useState({
        date: blockDate ? new Date(blockDate) : '',
        startTime: '',
        endTime: '',
        reason: ''
    })

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const blockSlot = () => {
        const newFormData = {
            ...formData,
            startTime: convertTimeTo12HourFormat(formData.startTime),
            endTime: convertTimeTo12HourFormat(formData.endTime),
            date: formatToDateWithDash(formData.date)
        }
        block_time_slot(newFormData)
            .then(res => {
                if (res?.code === 200) {
                    callback()
                    cancel()
                }
            })
    }

    const disabled = useMemo(() => {
        return !formData.date || !formData.startTime || !formData.endTime || !formData.reason
    }, [formData])

    return (
        <FormWrapper className="h-[89.5vh]" onSubmit={blockSlot}>
            <div className="py-[16px] h-[80vh] overflow-y-auto">
                <div className="mb-[24px]">
                    <DefaultDatepicker
                        label="Date"
                        minDate={new Date()}
                        onSelect={(e) => setFormData({ ...formData, date: e })}
                        name="date"
                        startDate={formData?.date}
                    />
                </div>
                <div className="grid grid-cols-2 gap-[24px]">
                    <div className="mb-[24px]">
                        <Input
                            label="Start time"
                            type="time"
                            name="startTime"
                            id="startTime"
                            readOnly={loading}
                            value={formData?.startTime}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-[24px]">
                        <Input
                            label="End time"
                            type="time"
                            name="endTime"
                            id="endTime"
                            readOnly={loading}
                            value={formData?.endTime}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <TextArea
                    placeholder="Enter reason"
                    id="other"
                    name="other"
                    readOnly={loading}
                    rows={3}
                    value={formData.reason}
                    onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
                />
            </div>
            <div className="flex justify-end fixed bottom-0 left-0 py-[16px] px-[24px] bg-neutral_white w-full">
                <Button
                    name="Cancel"
                    theme="transparent"
                    disabled={loading}
                    className="w-[79px] h-[40px] mr-[16px]"
                    onClick={cancel}
                />
                <Button
                    name="Block time slot"
                    theme="blue"
                    disabled={disabled || loading}
                    loading={loading}
                    className="w-[139px] h-[40px]"
                />
            </div>
        </FormWrapper >
    )
}

BlockTimeSlot.propTypes = {
    cancel: PropTypes.func,
    callback: PropTypes.func,
    blockDate: PropTypes.string
}