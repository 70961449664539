import { useEffect, useMemo, useState } from "react"

export default function useWorkingDays(initialWorkingDays = []) {
    const [workingDays, setWorkingDays] = useState([
        { name: 'All', selected: false },
        { name: 'Monday', selected: false },
        { name: 'Tuesday', selected: false },
        { name: 'Wednesday', selected: false },
        { name: 'Thursday', selected: false },
        { name: 'Friday', selected: false },
        { name: 'Saturday', selected: false },
        { name: 'Sunday', selected: false }
    ])

    useEffect(() => {
        let days = [...workingDays]
        const selectedDays = [...initialWorkingDays]

        if (selectedDays.length === 7) {
            const updatedWorkingDays = days.map(day => {
                return { name: day.name, selected: true }
            });
            setWorkingDays(updatedWorkingDays)
        } else {
            const updatedWorkingDays = workingDays.map(day => {
                if (selectedDays.includes(day.name)) {
                    return { ...day, selected: true };
                } else {
                    return day
                }
            })
            setWorkingDays(updatedWorkingDays)
        }
    }, [])

    const updateWorkingDays = (e, selected) => {
        if (e.target.value === 'All') {
            setWorkingDays(prevDays => prevDays.map(day => ({ ...day, selected: !selected })))
            return
        }
        // selects all days if all and 1 of the days is unselected and the unselected day(not all) is clicked
        if (workingDays.reduce((count, day) => count + (day.selected === true ? 1 : 0), 0) === 6 && !selected) {
            setWorkingDays(prevDays => prevDays.map(day => ({ ...day, selected: true })))
            return
        }
        setWorkingDays(prevDays => prevDays.map(day => {
            if (day.name === 'All') return { ...day, selected: false }
            if (day.name === e.target.value) return { ...day, selected: !day.selected }
            return day
        }))
    }

    const selectedWorkingDays = useMemo(() => {
        const result = workingDays.filter((day) => day.selected).map((day) => day.name).filter(item => item !== 'All')
        return result
    }, [workingDays])

    return { workingDays, updateWorkingDays, selectedWorkingDays }
}