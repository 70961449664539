/* eslint-disable max-len */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useWindowWidth from 'hooks/useWindowWidth';
import { MEDIUM_WIDTH } from 'constants';

export default function Modal({
  children, title, subTitle, className, variant, styles, closeModal, authModal = false, position, icon
}) {
  const { showDrawer } = useSelector(state => state.global)
  const [showChildren, setShowChildren] = useState(true)
  const { pathname } = useLocation();
  const windowWidth = useWindowWidth()

  const close = () => {
    setShowChildren(false)
    setTimeout(() => closeModal(), 200)
  }

  const animate = () => {
    if (['modal-right'].includes(position) && (windowWidth > MEDIUM_WIDTH)) {
      return {
        initial: { x: '100%' },
        animate: { x: 0, transition: { duration: 0.3 } },
        exit: { x: '100%' }
      }
    }
    return {
      initial: { y: 750 },
      animate: { y: 0, transition: { duration: 0.3 } },
      exit: { y: 750 }
    }
  }

  const centerPosition = useMemo(() => {
    if (showDrawer) {
      if (pathname === '/onboarding' || authModal) {
        return
      }
      return 'lg:ml-[130px]'
    }
    return 'lg:ml-[60px]'
  }, [showDrawer, authModal])


  return (
    <div>
      <div className="z-[500] overlay"></div>
      <div className={`modal ${position || 'modal-center'} ${styles} ${centerPosition}`}>
        {closeModal && !authModal && (
          <div
            className={`${['modal-right'].includes(position) ? 'mt-[11px]' : ''} flex justify-end mr-5 mb-[16px]`}
          >
            <div
              onClick={close}
              data-testid="close-modal"
              className='
              bg-neutral_white w-8 h-8 flex items-center justify-center cursor-pointer hide_tap rounded-full border 
              border-neutral_stroke_2 hover:border-brand_secondary hover:border-[0.5px] hover:bg-secondary_tint '
            >
              <div className='bg-neutral_white w-8 h-8 flex items-center justify-center rounded-full border border-neutral_stroke_2 hover:border-brand_secondary hover:border-[0.5px] hover:bg-secondary_tint'>
                {icon || <Close className='hover:text-brand_secondary' fill='#748181' />}
              </div>
            </div>
          </div>
        )}
        <AnimatePresence>
          {showChildren ?
            <motion.div
              initial={animate()?.initial}
              animate={animate()?.animate}
              exit={animate()?.exit}
              className="flex w-full"
            >
              <div className={`${!authModal && 'bg-neutral_white w-full overflow-scroll'} ${position ? 'rounded-t-2xl' : 'rounded-2xl'} ${className}`}>
                {title && (
                  <p
                    className={`${variant || 'text-center py-[14.5px] border-b border-[#ECEEEE] font-campton_m text-neutral_black '
                      }`}
                  >
                    {title}
                  </p>
                )}
                <div className={`px-6 py-4 ${position === "modal-right" ? 'md:h-[93vh] overflow-y-auto' : ''}`}>
                  {subTitle && <p className="text-neutral_body font-campton_r text-14">{subTitle}</p>}
                  {children}
                </div>
              </div>
            </motion.div> : ''
          }
        </AnimatePresence>
      </div>
    </div>
  );
}

Modal.propTypes = {
  closeModal: PropTypes.func,
  variant: PropTypes.string,
  className: PropTypes.string,
  authModal: PropTypes.bool,
  subTitle: PropTypes.string,
  styles: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  animation: PropTypes.object,
  position: PropTypes.string,
  icon: PropTypes.any
}