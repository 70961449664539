export const service_category = [
  {
    name: 'Appointment',
    value: 'Appointment',
  },
  {
    name: 'Therapy',
    value: 'Therapy',
  },
  {
    name: 'Procedure',
    value: 'Procedure',
  },
  {
    name: 'E-referral',
    value: 'E-referral',
  },
  {
    name: 'Medication',
    value: 'Medication',
  },
  {
    name: 'Lab Test Management',
    value: 'Lab Test Management',
  },
];
export const service_subCategory = {
  Appointment: [
    {
      name: 'Telehealth Appointment',
      value: 'Telehealth Appointment',
    },
    {
      name: 'Walk-in appointment',
      value: 'Walk-in appointment',
    },
  ],
  Procedure: [
    {
      name: 'Diagnostic Procedures',
      value: 'Diagnostic Procedures',
    },
    {
      name: 'Therapeutic Procedures',
      value: 'Therapeutic Procedures',
    },
    {
      name: 'Minimally Invasive  Procedures',
      value: 'Minimally Invasive  Procedures',
    },
    {
      name: 'Interventional Procedures',
      value: 'Interventional Procedures',
    },
    {
      name: 'Reproductive Procedures',
      value: 'Reproductive Procedures',
    },
    {
      name: 'Dental Procedures',
      value: 'Dental Procedures',
    },
    {
      name: 'Gastrointestinal Procedures',
      value: 'Gastrointestinal Procedures',
    },
    {
      name: 'Cardiovascular Procedures',
      value: 'Cardiovascular Procedures',
    },
  ],
  Therapy: [
    {
      name: 'Psychotherapy',
      value: 'Psychotherapy',
    },
    {
      name: 'Behaviour therapy',
      value: 'Behaviour therapy',
    },
    {
      name: 'Cognitive behaviour  therapy',
      value: 'Cognitive behaviour  therapy',
    },
  ],
  'E-referral': [
    {
      name: 'Direct referral',
      value: 'Direct referral',
    },
    {
      name: 'Incentivised referral',
      value: 'Incentivised referral',
    },
  ],
  Medication: [
    {
      name: 'Injection',
      value: 'Injection',
    },
    {
      name: 'Capsule',
      value: 'Capsule',
    },
  ],
  'Lab Test Management': [
    {
      name: 'X-ray',
      value: 'X-ray',
    },
    {
      name: 'Blood Test',
      value: 'Blood Test',
    },
    {
      name: 'Urine Test',
      value: 'Urine Test',
    },
  ],
};
export const service_suggestions = {
  'Diagnostic Procedures': [
    'Imaging Procedures (X-ray, MRI, CT scan, Ultrasound',
    'Endoscopy Procedures (Gastroscopy, Colonoscopy)',
    'Biopsy Procedures (Needle Biopsy, Surgical Biopsy)',
    'Laboratory Tests',
    'Electrocardiogram (ECG/EKG)',
    'Electroencephalogram (EEG)',
    'Pulmonary Function Test (PFT)',
    'Stress Test',
  ],
  'Therapeutic Procedures:': ['Chemotherapy', 'Radiation Therapy', 'Physical Therapy', 'Occupational Therapy'],
  'Minimally Invasive Procedures': [
    'Laparoscopic Procedures',
    'Arthroscopic Procedures',
    'Endovascular Procedures',
    'Robotic-Assisted Procedures',
  ],
  'Interventional Procedures': [
    'Angioplasty',
    'Cardiac Catheterization',
    'Epidural Steroid Injection',
    'Joint Injections',
  ],
  'Reproductive Procedures': [
    'In Vitro Fertilization (IVF)',
    'Intrauterine Insemination (IUI)',
    'Hysteroscopy',
    'Tubal Ligation',
  ],
  'Dental Procedures': ['Teeth Cleaning', 'Dental Fillings', 'Root Canal Treatment', 'Tooth Extraction'],
  'Gastrointestinal Procedures': [
    'Gastrostomy Tube Placement',
    'Colonoscopy',
    'Esophagogastroduodenoscopy (EGD)',
    'Hemorrhoidectomy',
  ],
  'Cardiovascular Procedures': [
    'surgical Angiography',
    'Coronary Artery Bypass Graft (CABG)',
    'Pacemaker Implantation',
    'Aneurysm Repair',
  ],
};
