import React from "react";
import PropTypes from "prop-types"
import Avatar from 'components/Avatar/Avatar';
import { userInitals } from 'utils/ImagePlaceholder';
import dayjs from 'dayjs';
import Layout from "./Layout";

export default function Instructions({ createdAt, profilePic, firstName, lastName, emailAddress, note }) {

    return (
        <Layout createdAt={`${dayjs(createdAt).format('MMM DD, YYYY')} ${dayjs(createdAt).format('hh:mm A')}`}>
            <>
                <div className="flex space-x-2">
                    <Avatar
                        url={profilePic}
                        width="32px"
                        height="32px"
                        alt="profile"
                        initials={userInitals(firstName, lastName)}
                        className="w-[32px] h-[32px] shrink-0"
                    />
                    <div>
                        <p className='text-14 font-campton_m text-neutral_black'>{firstName} {lastName}</p>
                        <p className='text-12 font-campton_r text-neutral_body'>{emailAddress}</p>
                    </div>
                </div>
                <p className="text-12 text-neutral_body leading-[20px]">{note}</p>
            </>
        </Layout>
    )
}

Instructions.propTypes = {
    createdAt: PropTypes.string,
    profilePic: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    emailAddress: PropTypes.string,
    note: PropTypes.string
}