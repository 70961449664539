import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { TextArea } from 'components/Inputs';
import { Button } from 'components/Buttons';
import { ReactComponent as EmptyStateIcon } from 'assets/svg/empty-visit.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import EmptyState from "components/Table/EmptyState"
import { SlideUp } from 'components/Animations';
import useVisit from 'hooks/fetch/useVisit';
import { VisitDetailsContext } from 'context';
import { userInitals } from 'utils/ImagePlaceholder';
import { ChiefComplaintThread } from 'components/Threads';
import PropTypes from "prop-types"

export default function ChiefComplaint({ callback, type }) {
  const isChiefComplaint = type === 'chiefComplaint'
  const pathname = window.location.pathname;
  const { singleVisit, visitStatus, canUpdate, progressNote } = useContext(VisitDetailsContext)
  const { loading, create_chief_complaint, add_history_of_patient_illness } = useVisit();
  const [note, setNote] = useState('')
  const chiefComplaint = progressNote?.chiefComplaint || singleVisit?.chiefComplaint
  const historyOfPatientIllness = progressNote?.historyOfPatientIllness || singleVisit?.historyOfPatientIllness
  const [showList, setShowList] = useState(visitStatus || (chiefComplaint?.length || historyOfPatientIllness?.length) || false)
  const title = isChiefComplaint ? 'Chief Complaint' : 'History of patient illness'
  const buttonName = isChiefComplaint ? 'Add Complaint' : 'Add History'
  const complaint = isChiefComplaint ? chiefComplaint : historyOfPatientIllness

  const addComplaint = () => {
    const id = singleVisit?._id
    const payload = {
      note,
      type: pathname.includes('progress-note') ? 'progressNote' : 'visit',
      [pathname.includes('progress-note') ? 'progressNote' : 'visit']: id ?? progressNote?._id
    }
    const action = isChiefComplaint ? create_chief_complaint(payload) : add_history_of_patient_illness({ payload })
    action.then((res) => {
      if (res?.code === 201) {
        setNote('')
        callback()
      }
    })
  }

  return (
    <>
      <h2 className="text-16 sm:text-18 text-[#0D0F11] font-campton_m">{title}</h2>
      <SlideUp>
        <div className="mt-6">
          {!visitStatus && canUpdate &&
            <div>
              <TextArea
                label="Note"
                type="text"
                id="note"
                rows={3}
                variant="col-span-12"
                name="note"
                readOnly={loading}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              <div className="flex justify-end mt-4">
                <Button
                  className="w-32 rounded-full h-8 font-campton_m text-14"
                  theme="blue"
                  disabled={!note}
                  onClick={addComplaint}
                  name={buttonName}
                  loading={loading}
                />
              </div>
            </div>
          }
          {chiefComplaint?.length ? (
            <>
              <div className="my-5 flex items-center space-x-6">
                <hr className="text-neutral_stroke_2 w-[96%]" />
                <span
                  data-testid="accordion-btn"
                  className={`${showList ? 'bg-primary_shade_300 p-2' : 'bg-neutral_white border border-neutral_stroke_2 h-6 w-6'
                    } rounded-full flex items-center justify-center cursor-pointer`}
                  onClick={() => setShowList(!showList)}
                >
                  {showList ? <Close fill="#FFFFFF" /> : <ArrowDown fill="#748181" />}
                </span>
              </div>
              {showList ? (
                <motion.div
                  initial={{ opacity: 0, y: '10px' }}
                  animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
                  exit={{ opacity: 0 }}
                >
                  {complaint?.map((item) => (
                    <ChiefComplaintThread
                      key={item?._id}
                      id={item?.provider?.providerId}
                      createdAt={item?.createdAt}
                      profilePic={item?.provider?.profilePic}
                      initials={userInitals(item?.provider?.firstName, item?.provider?.lastName)}
                      firstName={item?.provider?.firstName}
                      lastName={item?.provider?.lastName}
                      emailAddress={singleVisit?.provider?.emailAddress}
                      note={item?.note}
                    />
                  ))}
                </motion.div>
              ) : ''}
            </>
          ) : (
            <EmptyState title="No Notes Added" icon={<EmptyStateIcon className="mb-[24px]" />} />
          )}
        </div>
      </SlideUp>
    </>
  );
}

ChiefComplaint.propTypes = {
  callback: PropTypes.func,
  type: PropTypes.string
}
