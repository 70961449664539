import { useState } from 'react';
import useMakeRequest from 'hooks/useMakeRequest';
import useTryCatch from 'hooks/useTryCatch';
import { createMedications, getMedications, updateMedicationStatus } from 'store/modules/clinicals/actions';

export default function useMedications() {
  const { makeRequest } = useMakeRequest();
  const [loading, setLoading] = useState(false);
  const { tryCatch } = useTryCatch();

  const get_medications = (params) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getMedications({ params }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };
  
  const create_medications = (formData, callback) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: createMedications(formData), callback })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const update_medication_status = (formData, callback) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: updateMedicationStatus(formData), callback })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  return {
    get_medications,
    create_medications,
    loading,
    update_medication_status,
  };
}
