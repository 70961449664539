import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"
import { ReactComponent as Note } from "assets/icons/note1.svg"
import EmptyState from "components/Table/EmptyState";
import usePatient from "hooks/fetch/usePatient";
import { ChiefComplaintThread, InstructionsThread } from "components/Threads";
import Spinner from "components/Spinner";
import VitalSignsAnalytics from "components/VitalSignsAnalytics";
import useVitalsAnalytics from "hooks/useVitalsAnalytics";
import useVisit from "hooks/fetch/useVisit";

export default function Clinicals({ patientId, visitId }) {
    const { get_patient_visits, loading: isFetchingPatientVisit } = usePatient()
    const [visits, setVisits] = useState([])
    const { get_progress_note_visit, loading: isFetchingVisit } = useVisit();
    const { selectedTab, toggleTab } = useVitalsAnalytics({ patientId, visitId, className: "h-[180px]" })
    const loading = isFetchingPatientVisit || isFetchingVisit

    const getVisit = () => {
        get_progress_note_visit(visitId)
            .then((res) => setVisits(res?.data ?? undefined))
    }

    const getPatientVisit = () => {
        get_patient_visits({ patientId })
            .then(res => setVisits(res?.data?.visits[0] || []))
    }

    useEffect(() => {
        if (visitId) return getVisit()
        if (patientId) getPatientVisit()
    }, [])

    return (
        <>
            <section className="grid lg:grid-cols-2 gap-[24px] mb-[24px]">
                <div className="p-[16px] border border-neutral_stroke_2 rounded-[16px]">
                    <VitalSignsAnalytics
                        selectedTab={selectedTab}
                        toggleTab={toggleTab}
                    />
                </div>
                <div className="p-[16px] border border-neutral_stroke_2 rounded-[16px] h-[300px] overflow-hidden">
                    <p className="text-14 text-neutral_black font-campton_r mb-[16px]">Chief Complaint</p>
                    <div className="h-full overflow-auto pb-[20px]">
                        {loading ? <Spinner /> :
                            <>
                                {visits?.chiefComplaint?.map((item) =>
                                    <ChiefComplaintThread
                                        key={item?._id}
                                        createdAt={item?.createdAt}
                                        profilePic={item?.provider?.profilePic}
                                        firstName={item?.provider?.firstName}
                                        lastName={item?.provider?.lastName}
                                        emailAddress={item?.provider?.emailAddress}
                                        note={item?.note}
                                    />
                                )}
                                {!visits?.chiefComplaint?.length &&
                                    <EmptyState
                                        title={<span className="text-14">No complaint.</span>}
                                    />
                                }
                            </>
                        }
                    </div>
                </div>
            </section >
            <section className="flex flex-col lg:flex-row gap-[24px] mb-[24px]">
                <div className="basis-[60%] p-[16px] border border-neutral_stroke_2 rounded-[16px] h-[281px] overflow-hidden">
                    <p className="text-14 text-neutral_black font-campton_r mb-[16px]">Physical Examination</p>
                    <div className="h-full overflow-auto pb-[16px]">
                        {loading ? <Spinner /> :
                            (visits?.physicalExamination?.examination?.length ? visits?.physicalExamination?.examination?.map((item) =>
                                <div key={item?._id} className='py-[16px] border-b border-neutral_stroke_2'>
                                    <p className='text-14 text-neutral_black font-campton_m mb-[8px]'>{item?.title}</p>
                                    <p className='text-14 text-neutral_body font-campton_r leading-[21px]'>{item?.observation}</p>
                                </div>) :
                                <EmptyState
                                    title={<span className="text-14">There&apos;s no physical examination yet</span>}
                                    caption={<span className="block text-12 max-w-[200px]">Physical Examination data will be displayed here.</span>}
                                />
                            )
                        }
                    </div>
                </div>
                <div className="basis-[40%] p-[16px] border border-neutral_stroke_2 rounded-[16px] h-[281px] overflow-hidden">
                    <p className="text-14 text-neutral_black font-campton_r mb-[16px]">Clinical Assessment</p>
                    <div className="h-full overflow-auto pb-[30px]">
                        {visits?.primaryDiagnosis?.primaryDiagnosis ?
                            <div className="mb-[10px]">
                                <p className="text-14 text-neutral_black font-campton_m mb-[10px]"><Note className="inline" />Primary Diagnosis</p>
                                <p className="text-14 text-neutral_body font-campton_r">{visits?.primaryDiagnosis?.primaryDiagnosis}</p>
                            </div> : ''
                        }
                        {visits?.secondaryDiagnosis?.secondaryDiagnosis ?
                            <div>
                                <p className="text-14 text-neutral_black font-campton_m mb-[10px]"><Note className="inline" />Secondary Diagnosis</p>
                                <p className="text-14 text-neutral_body font-campton_r">{visits?.secondaryDiagnosis?.secondaryDiagnosis}</p>
                            </div> : ''
                        }
                    </div>
                    {!visits?.secondaryDiagnosis?.secondaryDiagnosis && !visits?.primaryDiagnosis?.primaryDiagnosis &&
                        <EmptyState
                            title={<span className="text-14">No clinical assessment</span>}
                        />
                    }
                </div>
            </section>
            <section className="p-[16px] border border-neutral_stroke_2 rounded-[16px]">
                <p className="text-14 text-neutral_black font-campton_r mb-[16px]">Follow-up Instructions</p>
                <div>
                    {visits?.visitSummary?.instructions?.map((instruction) =>
                        <InstructionsThread
                            key={instruction?._id}
                            firstName={instruction?.provider?.firstName}
                            lastName={instruction?.provider?.lastName}
                            profilePic={instruction?.provider?.profilePic}
                            emailAddress={instruction?.provider?.emailAddress}
                            note={instruction?.note}
                        />
                    )}
                    {!visits?.visitSummary?.instructions?.length ?
                        <EmptyState
                            title={<span className="text-14">No follow-up instructions.</span>}
                        /> : ''
                    }
                </div>
            </section>
        </>
    )
}

Clinicals.propTypes = {
    selectedTab: PropTypes.number,
    patientId: PropTypes.string,
    visitId: PropTypes.string
}