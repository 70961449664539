import { SlideUp } from "components/Animations";
import Navbar from "components/Navbar";
import Status from "components/Status";
import Table from "components/Table";
import { lab_test_list_header } from "mocks/clinicals/lab-test";
import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg"
import { Overview } from "components/Cards";
import { ReactComponent as File } from "assets/icons/file.svg"
import useTable from "hooks/useTable";
import { useNavigate } from "react-router-dom";
import useLabTest from "hooks/fetch/useLabTest";
import useDebounce from "hooks/useDebounce";
import { useSelector } from "react-redux";
import { formatDate } from "utils/formatDate";
import Restricted from "HOC/Restricted";
import FilterTest from "./Component/FilterTest";
import dayjs from "dayjs";

export default function LabTest() {
    const [filterParams, setFilterParams] = useState({
        startDate: '',
        endDate: '',
        testType: '',
        status: '',
    });
    const navigate = useNavigate()
    const { institutionId } = useSelector(state => state.auth)
    const { labTest } = useSelector(state => state.clinicals)
    const { get_lab_tests, loading } = useLabTest()
    const { params, changePage, selectLimit, handleSearch, openFilterModal, closeFilterModal, filterModal } = useTable()
    const { debouncedValue } = useDebounce(params.search)

    useEffect(() => {
        get_lab_tests({ id: `${institutionId}/lab-test`, params })
    }, [debouncedValue, params.page, params.limit])

    useEffect(() => {
        document.title = 'Lab Test - Gabbi Health'
    }, [])

    const filterTest = () => {
        closeFilterModal()
        get_lab_tests({
            id: `${institutionId}/lab-test`,
            params: {
                ...params,
                ...filterParams,
                endDate: filterParams.endDate ? dayjs(filterParams.endDate).format('YYYY-MM-DD') : '',
                startDate: filterParams.startDate ? dayjs(filterParams.startDate).format('YYYY-MM-DD') : '',
            }
        })
    }

    return (
        <>
            <Navbar title="Lab Test Management" />
            <Restricted module="Lab Test" permission="View">
                <>
                    <SlideUp>
                        <div className="flex border-b border-b-neutral_stroke_2 p-[24px] overflow-x-scroll">
                            <Overview
                                title="Total Number of Test Results"
                                className="bg-brand_primary mr-[24px]"
                                textColor="text-neutral_white"
                                total={labTest?.allLabTest}
                                icon={
                                    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" width="40" height="40" rx="20" fill="white" fillOpacity="0.1" />
                                        <path d="M24.7806 17.2194C24.8504 17.289 24.9057 17.3717 24.9434 17.4628C24.9812 17.5538 25.0006 17.6514 25.0006 17.75C25.0006 17.8486 24.9812 17.9462 24.9434 18.0372C24.9057 18.1283 24.8504 18.211 24.7806 18.2806L19.5306 23.5306C19.461 23.6004 19.3783 23.6557 19.2872 23.6934C19.1962 23.7312 19.0986 23.7506 19 23.7506C18.9014 23.7506 18.8038 23.7312 18.7128 23.6934C18.6218 23.6557 18.539 23.6004 18.4694 23.5306L16.2194 21.2806C16.0786 21.1399 15.9996 20.949 15.9996 20.75C15.9996 20.551 16.0786 20.3601 16.2194 20.2194C16.3601 20.0786 16.551 19.9996 16.75 19.9996C16.949 19.9996 17.1399 20.0786 17.2806 20.2194L19 21.9397L23.7194 17.2194C23.789 17.1496 23.8718 17.0943 23.9628 17.0566C24.0538 17.0188 24.1514 16.9994 24.25 16.9994C24.3486 16.9994 24.4462 17.0188 24.5372 17.0566C24.6283 17.0943 24.711 17.1496 24.7806 17.2194ZM30.25 20C30.25 21.9284 29.6782 23.8134 28.6068 25.4168C27.5355 27.0202 26.0127 28.2699 24.2312 29.0078C22.4496 29.7458 20.4892 29.9389 18.5979 29.5627C16.7066 29.1865 14.9693 28.2579 13.6057 26.8943C12.2422 25.5307 11.3136 23.7934 10.9373 21.9021C10.5611 20.0108 10.7542 18.0504 11.4922 16.2688C12.2301 14.4873 13.4798 12.9645 15.0832 11.8932C16.6866 10.8218 18.5716 10.25 20.5 10.25C23.085 10.2527 25.5634 11.2808 27.3913 13.1087C29.2192 14.9366 30.2473 17.415 30.25 20ZM28.75 20C28.75 18.3683 28.2661 16.7733 27.3596 15.4165C26.4531 14.0598 25.1646 13.0024 23.6571 12.378C22.1497 11.7536 20.4909 11.5902 18.8905 11.9085C17.2902 12.2268 15.8202 13.0126 14.6664 14.1664C13.5126 15.3202 12.7269 16.7902 12.4085 18.3905C12.0902 19.9908 12.2536 21.6496 12.878 23.1571C13.5024 24.6646 14.5598 25.9531 15.9165 26.8596C17.2733 27.7661 18.8683 28.25 20.5 28.25C22.6873 28.2475 24.7843 27.3775 26.3309 25.8309C27.8775 24.2843 28.7475 22.1873 28.75 20Z" fill="white" />
                                    </svg>
                                }
                            />
                            <Overview
                                title="Total Number of Critical Tests"
                                className="bg-[#EFF8FB]"
                                textColor="text-neutral_black"
                                total={labTest?.criticalLabTest}
                                icon={
                                    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" width="40" height="40" rx="20" fill="#36A1C5" fillOpacity="0.1" />
                                        <path d="M24.7806 17.2194C24.8504 17.289 24.9057 17.3717 24.9434 17.4628C24.9812 17.5538 25.0006 17.6514 25.0006 17.75C25.0006 17.8486 24.9812 17.9462 24.9434 18.0372C24.9057 18.1283 24.8504 18.211 24.7806 18.2806L19.5306 23.5306C19.461 23.6004 19.3783 23.6557 19.2872 23.6934C19.1962 23.7312 19.0986 23.7506 19 23.7506C18.9014 23.7506 18.8038 23.7312 18.7128 23.6934C18.6218 23.6557 18.539 23.6004 18.4694 23.5306L16.2194 21.2806C16.0786 21.1399 15.9996 20.949 15.9996 20.75C15.9996 20.551 16.0786 20.3601 16.2194 20.2194C16.3601 20.0786 16.551 19.9996 16.75 19.9996C16.949 19.9996 17.1399 20.0786 17.2806 20.2194L19 21.9397L23.7194 17.2194C23.789 17.1496 23.8718 17.0943 23.9628 17.0566C24.0538 17.0188 24.1514 16.9994 24.25 16.9994C24.3486 16.9994 24.4462 17.0188 24.5372 17.0566C24.6283 17.0943 24.711 17.1496 24.7806 17.2194ZM30.25 20C30.25 21.9284 29.6782 23.8134 28.6068 25.4168C27.5355 27.0202 26.0127 28.2699 24.2312 29.0078C22.4496 29.7458 20.4892 29.9389 18.5979 29.5627C16.7066 29.1865 14.9693 28.2579 13.6057 26.8943C12.2422 25.5307 11.3136 23.7934 10.9373 21.9021C10.5611 20.0108 10.7542 18.0504 11.4922 16.2688C12.2301 14.4873 13.4798 12.9645 15.0832 11.8932C16.6866 10.8218 18.5716 10.25 20.5 10.25C23.085 10.2527 25.5634 11.2808 27.3913 13.1087C29.2192 14.9366 30.2473 17.415 30.25 20ZM28.75 20C28.75 18.3683 28.2661 16.7733 27.3596 15.4165C26.4531 14.0598 25.1646 13.0024 23.6571 12.378C22.1497 11.7536 20.4909 11.5902 18.8905 11.9085C17.2902 12.2268 15.8202 13.0126 14.6664 14.1664C13.5126 15.3202 12.7269 16.7902 12.4085 18.3905C12.0902 19.9908 12.2536 21.6496 12.878 23.1571C13.5024 24.6646 14.5598 25.9531 15.9165 26.8596C17.2733 27.7661 18.8683 28.25 20.5 28.25C22.6873 28.2475 24.7843 27.3775 26.3309 25.8309C27.8775 24.2843 28.7475 22.1873 28.75 20Z" fill="#36A1C5" />
                                    </svg>
                                }
                            />
                        </div>
                        <div className="p-[24px]">
                            <Table
                                title="Test Results"
                                searchPlaceholder="Search by patient name"
                                headers={lab_test_list_header}
                                data={labTest?.labTest}
                                totalCount={labTest?.total}
                                perPage={labTest?.limit}
                                currentPage={labTest?.page}
                                selectLimit={selectLimit}
                                changePage={changePage}
                                filterFnc={openFilterModal}
                                loading={loading}
                                handleSearch={handleSearch}
                                module="Lab Test"
                                permission="Add"
                            // buttonName="New Request"
                            // buttonFnc={() => setInviteUser(true)}
                            >
                                <>
                                    {labTest?.labTest?.map((item) =>
                                        <tr
                                            data-testid={item?._id}
                                            key={item?._id}
                                            className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                                            onClick={() => navigate(`/clinicals/lab-test/${item?._id}`)}
                                        >
                                            <td className="px-[10px] py-[8px] flex items-center">
                                                <File className="mr-[10px] shrink-0" />
                                                <p title={item?.testType} className="truncate max-w-[200px] capitalize">{item?.testType}</p>
                                            </td>
                                            <td className="px-[10px] py-[8px] capitalize max-w-[150px] truncate">
                                                {item?.requestedBy?.firstName} {item?.requestedBy?.firstName}
                                            </td>
                                            <td className="px-[10px] py-[8px] capitalize max-w-[170px] truncate">
                                                {item?.patientInfo?.firstName} {item?.patientInfo?.lastName}
                                            </td>
                                            <td className="px-[10px] py-[8px] capitalize">{formatDate(item?.dateRequested)}</td>
                                            <td className="px-[10px] py-[8px]"><Status status={item?.requestStatus} /></td>
                                            <td className="px-[10px] py-[8px]"><ArrowRight fill="#A9B2B2" /></td>
                                        </tr>
                                    )}
                                </>
                            </Table>
                        </div>
                    </SlideUp>
                    {filterModal && <FilterTest closeModal={closeFilterModal} params={filterParams} setParams={setFilterParams} filterTest={filterTest} />}
                </>
            </Restricted>
        </>
    )
}