/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/Dropdown';
import { request_options } from 'mocks/appointment';
import { Button } from 'components/Buttons';
import { useAuthState } from 'store/modules/auth';
import AppointmentInfo from './Components/AppointmentInfo';
import useAppointments from 'hooks/fetch/useAppointments';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { setLiveAppointment } from 'store/modules/appointment';
import { isTimeInRange } from 'utils/isTimeInRange';
import { insertTimeToDate } from 'utils/formatDate';
import { SlideUp } from 'components/Animations';
import { TextArea } from 'components/Inputs';
import { useNavigate } from 'react-router-dom';

const ViewAppointment = ({ data, callback, type, setAppointmentList, params }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { institutionId, user } = useAuthState();
  const { edit_appointment, get_appointment_request, update_appointment_status, loading } = useAppointments();
  const [formData, setFormData] = useState({
    status: '',
    reason: '',
  });

  const isTimeInRangeProxy = isTimeInRange(
    (data?.appointmentDate ?? data?.appointmentInfo?.appointmentDate ?? data?.appointment?.date),
    (data?.startTime ?? data?.appointmentInfo?.startTime ?? data?.appointment?.startTime),
    (data?.endTime ?? data?.appointmentInfo?.endTime ?? data?.appointment?.endTime)
  )

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCallback = () => {
    callback();
    if (type.includes('cancel')) {
      get_appointment_request(`${institutionId}/appointment/cancel-requests`).then((res) =>
        setAppointmentList(res?.data?.cancelRequests || undefined),
      );
    } else {
      get_appointment_request(`${institutionId}/reschedule/requests`, params).then((res) =>
        setAppointmentList(res?.data?.rescheduleRequests || undefined),
      );
    }
  };

  const approveDeclineRequest = () => {
    const status = formData.status.includes('Approve') ? 'approved' : 'declined'
    const newFormData = status.includes('approve') ? { status } : { status, reason: formData.reason };
    if (type.includes('cancel')) {
      edit_appointment(
        `${data._id}/cancel-request`,
        { status },
        handleCallback);
    } else {
      edit_appointment(`reschedule/request/${data._id}`, newFormData, handleCallback);
    }
  };

  const joinCall = () => {
    if (!data?.liveCallId) return
    dispatch(setLiveAppointment(data))
    const anchor = document.createElement('a')
    anchor.href = `/appointments/live/${data?.liveCallId}`
    anchor.target = '_blank';
    anchor.click()
  }

  const startVisit = () => {
    const url = `/clinicals/visits/${data?.visitInfo?.id}?visitType=appointment&appointmentId=${data?._id}`
    if (data?.status === 'upcoming') {
      update_appointment_status(data?._id)
        .then(res => {
          if (res?.code === 200) navigate(url)
        })
      return
    }
    navigate(url)
  }

  return (
    <div className="relative">
      <div className='modal-right-body'>
        <AppointmentInfo appointmentDetails={data} type={type} />
        {type.includes('requests') &&
          <div className="mt-6">
            <p className="font-campton_m text-14 text-neutral_black">
              {`${type.includes('cancel') ? 'Cancel' : 'Reschedule'} Details`}{' '}
            </p>
            <div className="border border-neutral_stroke_2 p-4 rounded mt-3">
              {type.includes('reschedule') && (
                <div className="mb-4 pb-4 border-b border-neutral_stroke_2 grid grid-cols-2">
                  <div>
                    <p className="text-12 text-neutral_body font-campton_r">Proposed Date</p>
                    <p className="text-14 text-neutral_black font-campton_r mt-1">
                      {dayjs(insertTimeToDate(data.proposedDate, data?.proposedStartTime)).format('MMM DD, YYYY')}
                    </p>
                  </div>
                  <div>
                    <p className="text-12 text-neutral_body font-campton_r">Proposed Time</p>
                    <p className="text-14 text-neutral_black font-campton_r mt-1">
                      {dayjs(insertTimeToDate(data.proposedDate, data.proposedStartTime)).format('hh:mm A')} - {dayjs(insertTimeToDate(data.proposedDate, data.proposedEndTime)).format('hh:mm A')}
                    </p>
                  </div>
                </div>
              )}
              <p className="text-12 text-neutral_body font-campton_r">
                Reason for {type.includes('cancel') ? 'Cancelling' : 'Rescheduling'}
              </p>
              <p className="text-14 text-neutral_black font-campton_r mt-1">{data?.reason || 'N/A'}</p>
            </div>
            {user?.type !== 'staff' && data.status === 'awaitingApproval' && (
              <div className="pt-6 mt-6 border-t border-dashed border-neutral_stroke_2">
                <Dropdown
                  type="select"
                  id="action"
                  label="Select Action"
                  name="action"
                  options={request_options}
                  selected={formData?.status}
                  onSelect={(data) => {
                    setFormData({ ...formData, status: data.value });
                  }}
                />
                {formData.status === 'Reject request' && (
                  <SlideUp>
                    <TextArea
                      placeholder="Type rejection reason here..."
                      type="text"
                      id="reason"
                      name="reason"
                      variant="mt-4"
                      onChange={handleChange}
                    />
                  </SlideUp>
                )}
              </div>
            )}
          </div>
        }
      </div>
      {user?.type !== 'staff' && data.status === 'awaitingApproval' && (
        <div className="modal-right-button-container">
          <Button
            className="w-20 h-10 mr-[16px]
              text-14 hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary  px-2 py-1"
            theme="transparent"
            onClick={callback}
            name="Cancel"
          />
          <Button
            name="Save"
            theme="blue"
            loading={loading}
            className="w-[88px] h-10"
            disabled={!formData.status}
            onClick={approveDeclineRequest}
          />
        </div>
      )}
      {data?.type === 'onSite' && data?.status !== 'completed' && isTimeInRangeProxy &&
        <div className="modal-right-button-container">
          <Button
            name={`${data?.status === 'upcoming' ? "Start" : "Continue"} Visit`}
            theme="blue"
            className="min-w-[120px] h-10 px-[8px]"
            loading={loading}
            onClick={startVisit}
          />
        </div>
      }
      {['ongoing', 'upcoming', 'rescheduled'].includes(data?.status?.toLowerCase()) && data?.type === 'inApp' && isTimeInRangeProxy ?
        <div className="modal-right-button-container">
          <Button
            name="Join Call"
            theme="blue"
            className="w-[100px] h-10"
            onClick={joinCall}
          />
        </div> : ''
      }
    </div>
  );
};

export default ViewAppointment;
ViewAppointment.propTypes = {
  data: PropTypes.object,
  callback: PropTypes.func,
  type: PropTypes.string,
  setAppointmentList: PropTypes.func,
  params: PropTypes.object,
};
