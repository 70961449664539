import React, { useEffect, useState } from "react";
import { SlideUp } from "components/Animations";
import useTable from "hooks/useTable";
import Cards from "../Components/Cards"
import Table from "components/Table";
import EmptyState from "components/Table/EmptyState";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg"
import { swap_requests_list_header } from "mocks/shiftManagement";
import { formatDate } from "utils/formatDate";
import Status from "components/Status";
import ViewSwapRequest from "pages/EShift/Components/ViewSwapRequest";
import useShift from "hooks/fetch/useShift";
import useDebounce from "hooks/useDebounce";
import usePermission from "hooks/usePermission";
import { useSelector } from "react-redux";

export default function SwapRequests() {
    const { hasPermission } = usePermission()
    const { changePage, selectLimit, handleSearch, params } = useTable()
    const { institutionId, user } = useSelector(state => state.auth)
    const { swapRequests } = useSelector(state => state.shift)
    const { debouncedValue } = useDebounce(params.search)
    const [selectedSwapRequest, setSelectedSwapRequest] = useState()
    const { loading, get_swap_requests } = useShift()
    const { loading: isUpdating, update_swap_request_status } = useShift()
    const providerId = user?._id
    const isPermittedToViewInstitutionShift = hasPermission('Scheduling', 'View all shifts')

    const getSwapRequestsProxy = () => {
        isPermittedToViewInstitutionShift ?
            get_swap_requests({ params: { requestType: "institution", institutionId, ...params } }) :
            get_swap_requests({ params: { requestType: "provider", providerId, ...params } })
    }

    const closeModal = () => {
        setSelectedSwapRequest()
    }

    const updateSwapRequestProxy = (swapRequestId, status) => {
        update_swap_request_status({ swapRequestId, status })
            .then(res => {
                if ([200, 201].includes(res?.code)) {
                    getSwapRequestsProxy()
                    closeModal()
                }
            })
    }

    useEffect(() => {
        getSwapRequestsProxy()
    }, [debouncedValue, params.page, params.limit])

    return (
        <SlideUp>
            <Cards total={swapRequests?.total ?? 0} purpose="swap" />
            <div className="p-[24px]">
                <Table
                    title="Time Slot Swap Requests"
                    headers={swap_requests_list_header}
                    data={swapRequests?.swapRequests}
                    totalCount={swapRequests?.total}
                    perPage={swapRequests?.limit}
                    currentPage={swapRequests?.page}
                    selectLimit={selectLimit}
                    changePage={changePage}
                    loading={loading}
                    handleSearch={handleSearch}
                    emptyState={
                        <EmptyState
                            title="You’ve not created any shift yet"
                            caption="Shift created will be displayed and managed here"
                        />
                    }
                    module="Scheduling"
                    permission="View swap request"
                >
                    <>
                        {swapRequests?.swapRequests?.map((item) =>
                            <tr
                                key={item?._id}
                                data-testid={item?._id}
                                onClick={() => setSelectedSwapRequest(item)}
                                className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                            >
                                <td className="px-[10px] py-[11px] flex items-center capitalize">{item?.shiftName}</td>
                                <td className="px-[10px] py-[11px] capitalize">{formatDate(item?.createdAt)}</td>
                                <td className="px-[10px] py-[11px] capitalize"><Status status={item.status} /></td>
                                <td className="px-[10px] py-[11px] w-[20px]"><ArrowRight fill="#A9B2B2" /></td>
                            </tr>
                        )}
                    </>
                </Table>
            </div>
            {selectedSwapRequest &&
                <ViewSwapRequest
                    closeModal={closeModal}
                    request={selectedSwapRequest}
                    updateSwapRequest={updateSwapRequestProxy}
                    loading={isUpdating}
                />
            }
        </SlideUp>
    )
}