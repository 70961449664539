import Button from "components/Buttons/Button"
import Checkbox from "components/Checkbox"
import TextArea from "components/Inputs/TextArea"
import React, { useState } from "react"
import PropTypes from "prop-types"
import FormWrapper from "components/FormWrapper"

export default function VisitSummary({ onSubmit, btnName, loading, formData, setFormData }) {
    const [agreedToTerms, setAgreedToTerms] = useState(false)
    const [addInstructions, setAddInstructions] = useState(false)

    const disabled = !agreedToTerms || !formData.summary || loading

    return (
        <FormWrapper onSubmit={onSubmit}>
            <div className="pb-[24px] border-dashed border-b border-b-neutral_stroke_2">
                <p className="text-14 text-neutral_black font-campton_sb mb-[12px]">Visit Summary</p>
                <TextArea
                    placeholder="Type the visit summary here..."
                    id="summary"
                    name="summary"
                    //readOnly={loading}
                    value={formData.summary}
                    onChange={(e) => setFormData({ ...formData, summary: e.target.value })}
                    rows={3}
                />
            </div>
            <div className="py-[24px] border-b border-b-neutral_stroke_2">
                <p className="text-14 text-neutral_black font-campton_sb mb-[12px]">Follow-up Instructions</p>
                <p
                    className="text-14 text-brand_secondary font-campton_sb underline cursor-pointer hide_tap mb-[12px]"
                    onClick={() => setAddInstructions(!addInstructions)}
                >
                    Add follow-up instructions
                </p>
                {addInstructions &&
                    <TextArea
                        placeholder="Type the additional instructions..."
                        id="instructions"
                        name="instructions"
                        //readOnly={loading}
                        value={formData.instructions}
                        onChange={(e) => setFormData({ ...formData, instructions: e.target.value })}
                        rows={3}
                    />
                }
            </div>
            <div className="py-[10px]">
                <Checkbox
                    status={agreedToTerms}
                    onClick={() => setAgreedToTerms(!agreedToTerms)}
                    label="By checking the box, your signature will be attached to the visit record created for this patient."
                />
                <div className="flex justify-end mt-[40px]">
                    <Button
                        name={btnName}
                        theme="blue"
                        className="h-[40px] text-16 w-[189px]"
                        disabled={disabled}
                        loading={loading}
                    />
                </div>
            </div>
        </FormWrapper>
    )
}

VisitSummary.propTypes = {
    onSubmit: PropTypes.func,
    btnName: PropTypes.string,
    loading: PropTypes.bool,
    formData: PropTypes.object,
    setFormData: PropTypes.func
};