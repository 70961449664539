import React from "react";
import PropTypes from "prop-types"
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import { Button } from "components/Buttons";

export default function Layout({ children, view, setModal, callback, visitStatus }) {
    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0, y: "20px" }}
                animate={{ opacity: 1, y: 0, transition: { duration: .5 } }}
                exit={{ opacity: 0 }}
            >
                <>
                    {view && (
                        <div className="flex justify-between items-center">
                            <div className="flex items-center space-x-4">
                                <Button
                                    className="w-fit rounded-full h-8 font-campton_m text-14"
                                    theme="transparent"
                                    onClick={callback}
                                >
                                    <p className="flex items-center space-x-4">
                                        <ArrowBack fill="#748181" width="7" height="14" className="text-14" />
                                        <span>Back</span>
                                    </p>
                                </Button>
                                <h2
                                    className={`text-16 sm:text-18 text-[#0D0F11] font-campton_m ${view && 'border-l border-neutral_stroke_2 pl-4'
                                        }`}
                                >
                                    {view}
                                </h2>
                            </div>
                            {!visitStatus && <Button
                                className="w-fit rounded-full h-8 font-campton_m text-14"
                                theme="blue"
                                name={`Add ${view}`}
                                onClick={setModal}
                            />}
                        </div>
                    )}
                    {children}
                </>
            </motion.div>
        </AnimatePresence>
    )
}

Layout.propTypes = {
    children: PropTypes.element,
    view: PropTypes.any,
    setModal: PropTypes.func,
    callback: PropTypes.func,
    visitStatus: PropTypes.bool
}