import React, { useContext } from 'react'
import { ReactComponent as Medication } from 'assets/svg/medications.svg';
import { ReactComponent as Theraphy } from 'assets/svg/therapies.svg';
import { ReactComponent as Procedure } from 'assets/svg/procedure.svg';
import { ReactComponent as BloodDrop } from 'assets/svg/blood-type.svg';
import { SlideUp } from 'components/Animations';
import { useClinicalState } from 'store/modules/clinicals';
import StatusCard from '../StatusCard';
import PropTypes from 'prop-types';
import { PatientContext } from 'context';

const TreatmentPlanView = ({ view, setView, View}) => {
  const {medications, procedures, loading, referrals, therapies} = useClinicalState()
  const pathname = window.location.pathname
  const { progressNote } = useContext(PatientContext)

  const medicationCount = pathname.includes('progress-note') ? progressNote?.medications?.length : medications?.count
  const procedureCount = pathname.includes('progress-note') ? progressNote?.procedures?.length : procedures?.total
  const referralsCount = pathname.includes('progress-note') ? progressNote?.referrals?.length : referrals?.total
  const therapyListCount = pathname.includes('progress-note') ? progressNote?.therapies?.length : therapies?.total

  return (
    <SlideUp>
      {view === '' && (
        <div className="grid grid-cols-12">
          <StatusCard
            containerVariant="col-span-6 lg:col-span-4 border-r border-neutral_stroke_2 pb-4 pr-4 border-b"
            variant="px-4"
            loading={loading}
            icon={<Medication/>}
            title="Medications"
            description="Prescription details, including medication name, dosage, frequency, and route of administration."
            status={`${medicationCount} type`}
            onClick={() => setView('Medications')}
          />
          <StatusCard
            containerVariant="col-span-6 lg:col-span-4 lg:border-r border-neutral_stroke_2 px-4 pb-4 border-b"
            icon={<Theraphy />}
            variant="px-4"
            loading={loading}
            title="Therapies"
            description="Recommendations for physical therapy, occupational therapy, or other non-pharmacological treatments."
            status={`${therapyListCount} type`}
            onClick={() => setView('Therapies')}
          />
          <StatusCard
            containerVariant="col-span-6 lg:col-span-4 pl-4 pb-4 border-r lg:border-r-0 border-neutral_stroke_2 border-b"
            variant="px-4"
            loading={loading}
            icon={<Procedure />}
            title="Procedures"
            description="Instructions for specific medical procedures or interventions."
            status={`${procedureCount} type`}
            onClick={() => setView('Procedure')}
          />
          {/* <StatusCard
            containerVariant="col-span-6 lg:border-r border-neutral_stroke_2 pt-4 pr-4 border-b lg:border-b-0"
            variant="px-4"
            icon={<Lifestyle />}
            title="Lifestyle Recommendations"
            description="Guidance regarding dietary changes, exercise, or other lifestyle modifications."
            status={0}
          /> */}
          <StatusCard
            containerVariant="col-span-6 pt-4 pl-4 border-r lg:border-r-0 border-neutral_stroke_2"
            variant="px-4"
            icon={<BloodDrop />}
            loading={loading}
            title="Referrals"
            description="Recommendations for specialized consultations or referrals to other healthcare professionals."
            status={`${referralsCount} type`}
            onClick={() => setView('Referrals')}
          />
        </div>)
      }
      {view && View}
    </SlideUp>
  )
}

export default TreatmentPlanView;
TreatmentPlanView.propTypes = {
  View: PropTypes.element,
  view: PropTypes.string,
  setView: PropTypes.func,
};