import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as MedicationIllustration } from 'assets/svg/medications-empty.svg';
import { Button } from 'components/Buttons';
import { SlideUp } from 'components/Animations';
import Table from 'components/Table';
import { medication_list_header } from 'mocks/patient';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import Status from 'components/Status';
import NewRequest from './Components/NewRequest';
import Modal from 'components/Modals/Modal';
import ViewMedication from './Components/ViewMedication';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import PropTypes from 'prop-types';
import { ReactComponent as File } from 'assets/icons/file.svg';
import EmptyState from 'components/Table/EmptyState';
import useMedications from 'hooks/fetch/useMedications';
import { useParams } from 'react-router-dom';
import { useClinicalState } from 'store/modules/clinicals';
import dayjs from 'dayjs';
import { PatientContext } from 'context';

const Medications = ({ view, callback, singleVisit, visitStatus, canUpdate = true }) => {
  const [type, setType] = useState('');
  const { id } = useParams()
  const pathname = window.location.pathname;
  const page = pathname.includes('clinicals/patient') ? 'patient' : 'visit'

  const { medications } = useClinicalState()
  const [selectedItem, setSelectedItem] = useState();
  const handleSingleMedications = (item) => {
    setSelectedItem(item);
    setType('view');
  };
  const { get_medications, loading } = useMedications()
  const { patient, progressNote } = useContext(PatientContext)
  const medicationList = pathname.includes('progress-note') ? progressNote?.medications : medications?.medications

  useEffect(() => {
    const params = {
      type: page,
      [page === 'patient' ? 'patientId' : 'visitId']: patient?.visit ?? id,
    }
    if(!progressNote) {
      get_medications(params);
    }
  }, []);

  return (
    <>
      <SlideUp>
        <div className={`${view ? 'p-0' : 'p-6'}`}>
          <div className="flex justify-between items-center mb-6">
            <div className="flex items-center space-x-4">
              {view && (
                <Button
                  className="w-fit rounded-full h-8 font-campton_m text-14"
                  theme="transparent"
                  onClick={callback}
                >
                  <p className="flex items-center space-x-4">
                    <ArrowBack fill="#748181" width="7" height="14" className="text-14" />
                    <span>Back</span>
                  </p>
                </Button>
              )}
              <h2
                className={`text-16 sm:text-18 text-[#0D0F11] font-campton_m ${view && 'border-l border-neutral_stroke_2 pl-4'
                  }`}
              >
                Medications
              </h2>
            </div>
            {!visitStatus && canUpdate &&
              <Button
                name={`New ${page === 'patient' ? 'Medication' : ''} Request`}
                theme="blue"
                onClick={() => setType('create')}
                className="h-8"
              />
            }
          </div>
          <Table
            headers={medication_list_header}
            data={medicationList}
            hasHeader={medicationList?.length > 0}
            totalCount={2}
            hasPagination={false}
            hasFilter={false}
            loading={loading}
            perPage={10}
            buttonName="Create Location"
            currentPage={1}
            emptyState={
              <EmptyState
                title="No Medications"
                className="h-[calc(100vh-530px)] bg-neutral_white"
                buttonName={`New ${page === 'patient' ? 'Medication' : ''} Request`}
                buttonFunc={() => setType('create')}
                icon={<MedicationIllustration />}
              />
            }
          >
            <>
              {medicationList?.map((item) => (
                <tr
                  data-testid={item?._id}
                  onClick={() => handleSingleMedications(item)}
                  key={item?._id}
                  className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                >
                  <td className="px-[10px] py-[8px] flex space-x-2 items-center">
                    <File /> <p>{item.medicationName} ({item.dosage})</p>
                  </td>
                  <td className="px-[10px] py-[8px] capitalize text-14">{item.startDate ? dayjs(item.startDate).format('MMM DD, YYYY') : 'N/A'}</td>
                  <td className="px-[10px] py-[8px] capitalize md:w-52 w-20">{item.endDate ? dayjs(item.endDate).format('MMM DD, YYYY') : 'N/A'}</td>
                  <td className="px-[10px] py-[8px] capitalize text-14">
                    <Status status={item.status} />
                  </td>
                  <td className="px-[10px] py-[8px] capitalize">
                    <ArrowRight fill="#A9B2B2" />
                  </td>
                </tr>
              ))}
            </>
          </Table>
        </div>
      </SlideUp>
      {type && (
        <Modal
          closeModal={() => setType('')}
          styles="w-full md:w-[457px]"
          position="modal-right"
          variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
          title={type === 'view' ? selectedItem?.medicationName : 'New Medication Request'}
        >
          {type === 'create' ? (
            <NewRequest callback={() => setType('')} singleVisit={singleVisit} />
          ) : (
            <ViewMedication data={selectedItem} callback={() => setType('')} />
          )}
        </Modal>
      )}
    </>
  );
};

export default Medications;
Medications.propTypes = {
  callback: PropTypes.any,
  view: PropTypes.string,
  singleVisit: PropTypes.object,
  canUpdate: PropTypes.bool,
  visitStatus: PropTypes.any
};
