import React, { useEffect, useState } from 'react';
import { Button } from 'components/Buttons';
import { Search } from 'components/Inputs';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import Table from 'components/Table';
import Avatar from 'components/Avatar/Avatar';
import { userInitals } from 'utils/ImagePlaceholder';
import PropTypes from 'prop-types';
import RadioButtonSelect from 'components/RadioButtonSelect';
import { useAuthState } from 'store/modules/auth';
import useProviders from 'hooks/fetch/useProviders';
import useDebounce from 'hooks/useDebounce';
import useTable from 'hooks/useTable';
import EmptyState from 'components/Table/EmptyState';
import useRoles from 'hooks/fetch/useRoles';
import dayjs from 'dayjs';
import Status from 'components/Status';
import { useSelector } from 'react-redux';

const SelectProvider = ({ back, next, formData, setProvider, time, setFormData }) => {
  const { institutionId } = useAuthState();
  const { params, handleSearch } = useTable();
  const { get_roles } = useRoles();
  const { roles } = useSelector((state) => state.roles);
  const { debouncedValue } = useDebounce(params.search);
  const { get_staff_with_shift, loading } = useProviders();
  const [availableStaffs, setAvailableStaffs] = useState();
  const [filter, setFilter] = useState('');
  const handleType = (item) => {
    setFormData({ ...formData, providerId: item._id });
    setProvider(item);
  };

  useEffect(() => {
    get_roles({ id: institutionId });
  }, []);

  useEffect(() => {
    const startTime = `${dayjs(time?.start).format('hh:mm A')}`;
    const endTime = `${dayjs(time?.end).format('hh:mm A')}`;
    const startDate = `${dayjs(formData?.appointmentDate).format('YYYY-MM-DD')}`;
    const endDate = `${dayjs(formData?.appointmentDate).format('YYYY-MM-DD')}`;
    const role = `${filter}`;
    const search = `${debouncedValue}`;
    const limit = `100`
    const page = '1'
    const params = { page, startDate, startTime, endTime, endDate, role, search, limit }
    get_staff_with_shift({ institutionId, params })
      .then((res) => setAvailableStaffs(res?.data || undefined));
  }, [debouncedValue, filter]);

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="mb-[24px]">
        <div className="flex items-center mb-[24px]">
          <Button theme="transparent" type="button" className="w-[82px] mr-[12px]" onClick={back}>
            <>
              <ArrowBack fill="#748181" className="w-[7px] h-[12px] mr-[13.5px]" />
              Back
            </>
          </Button>
          <h2 className="text-16 text-neutral_black font-campton_m pl-[12px] border-l border-l-neutral_stroke_2">
            Select Provider
          </h2>
        </div>
        <Search id="search" name="search" placeholder="Search by staff name..." onChange={handleSearch} />
        <div className="flex overflow-x-auto no-scrollbar mt-[14px] mb-4 space-x-2">
          {roles?.Roles?.map((role) => (
            <Status
              key={role.name}
              status={role.name}
              onClick={() => setFilter(role.name)}
              className={`${filter === role.name
                ? 'border-[#143566] text-[#143566] bg-[#F2F6FC]'
                : 'border-neutral_stroke_2 text-neutral_body bg-neutral_white'
                } border  flex items-center justify-center h-[34px] px-4 w-fit rounded-full cursor-pointer`}
            />
          ))}
        </div>
        <div>
          <Table
            hasFilter={false}
            hasPagination={false}
            tableContainer="min-w-[400px]"
            headers={['Staff Name', 'Specialty', 'Location']}
            data={availableStaffs?.staffs}
            loading={loading}
            emptyState={
              <EmptyState
                title="No Staff"
              //icon={<div></div>}
              />
            }
          >
            <>
              {availableStaffs?.staffs?.map((item) => (
                <tr
                  data-testid={item?._id}
                  key={item?._id}
                  onClick={() => handleType(item)}
                  className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                >
                  <td className="px-[10px] py-[8px] flex items-center w-[260px]">
                    <RadioButtonSelect
                      showLabel={false}
                      label="provider"
                      variant="border-0 mb-6"
                      isChecked={formData?.providerId === item?._id}
                    />
                    <Avatar
                      initials={userInitals(item?.firstName, item?.lastName)}
                      width="32px"
                      url={item?.profilePic}
                      height="32px"
                      // alt={item?.name?.split('')[0]}
                      className="w-[32px] h-[32px] shrink-0 mx-[8px]"
                    />
                    <div className='max-w-[200px]'>
                      <p
                        title={`${item?.firstName} ${item?.lastName}`}
                        className="truncate text-14 text-neutral_black font-campton_r "
                      >
                        {item?.title} {item?.firstName} {item?.lastName}
                      </p>
                      <p className="text-12 text-neutral_body font-campton_r truncate">{item.emailAddress ?? 'N/A'}</p>
                    </div>
                  </td>
                  <td title={item?.specialty} className="px-[10px] py-[8px] capitalize whitespace-nowrap truncate max-w-[200px]">{item?.specialty ?? 'N/A'}</td>
                  <td title={item?.locationName} className="px-[10px] py-[8px] capitalize whitespace-nowrap truncate max-w-[200px]">{item?.locationName || 'N/A'}</td>
                </tr>
              ))}
            </>
          </Table>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          name="Cancel"
          theme="transparent"
          type="button"
          onClick={back}
          className="h-[40px] text-16 w-[79px] mr-[16px]"
        />
        <Button
          name="Next"
          theme="blue"
          type="button"
          onClick={next}
          className="h-[40px] text-16 w-[88px]"
          disabled={!formData?.providerId}
        />
      </div>
    </div>
  );
};

export default SelectProvider;
SelectProvider.propTypes = {
  back: PropTypes.func,
  next: PropTypes.func,
  time: PropTypes.object,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  setProvider: PropTypes.func,
};
