import React from "react"
import PropTypes from "prop-types"
import { Input } from "components/Inputs"

export default function AddVitals({ formData, handleChange, loading }) {
    return (
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-[24px] pt-[24px]">
            <div className="w-full">
                <Input
                    label="Blood Pressure (SYS)"
                    type="text"
                    id="sysBloodPressure"
                    name="sysBloodPressure"
                    value={formData?.sysBloodPressure}
                    onChange={handleChange}
                    disabled={loading}
                    measurement="mmHg"
                />
            </div>
            <div className="w-full">
                <Input
                    label="Blood Pressure (DIA)"
                    type="text"
                    id="diaBloodPressure"
                    name="diaBloodPressure"
                    value={formData?.diaBloodPressure}
                    disabled={loading}
                    onChange={handleChange}
                    measurement="mmHg"
                />
            </div>
            <div className="w-full">
                <Input
                    label="Heart Rate"
                    type="text"
                    id="heartRate"
                    name="heartRate"
                    value={formData?.heartRate}
                    disabled={loading}
                    onChange={handleChange}
                    measurement="bpm"
                />
            </div>
            <div className="w-full">
                <Input
                    label="Respiratory Rate"
                    type="text"
                    id="respiratoryRate"
                    name="respiratoryRate"
                    value={formData?.respiratoryRate}
                    disabled={loading}
                    onChange={handleChange}
                    measurement="bpm"
                />
            </div>
            <div className="w-full">
                <Input
                    label="Temperature"
                    type="text"
                    id="temperature"
                    name="temperature"
                    value={formData?.temperature}
                    disabled={loading}
                    onChange={handleChange}
                    measurement={<><sup>o</sup>C</>}
                />
            </div>
            <div className="w-full">
                <Input
                    label="Oxygen Saturation"
                    type="text"
                    id="oxygenSaturation"
                    name="oxygenSaturation"
                    value={formData?.oxygenSaturation}
                    disabled={loading}
                    onChange={handleChange}
                    measurement="%"
                />
            </div>
            <div className="w-full">
                <Input
                    label="Height"
                    type="text"
                    id="height"
                    name="height"
                    value={formData?.height}
                    disabled={loading}
                    onChange={handleChange}
                    measurement="meters"
                />
            </div>
            <div className="w-full">
                <Input
                    label="Weight"
                    type="text"
                    id="weight"
                    name="weight"
                    value={formData?.weight}
                    disabled={loading}
                    onChange={handleChange}
                    measurement="kg"
                />
            </div>
            <div className="w-full">
                <Input
                    label="BMI"
                    type="text"
                    id="bmi"
                    name="bmi"
                    value={Number(formData?.bmi)?.toFixed(2)}
                    disabled={true}
                    onChange={handleChange}
                />
            </div>
        </div>
    )
}

AddVitals.propTypes = {
    formData: PropTypes.object,
    handleChange: PropTypes.func,
    loading: PropTypes.bool
}