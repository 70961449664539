import { Button } from "components/Buttons";
import Dropdown from "components/Dropdown";
import FormWrapper from "components/FormWrapper";
import { Input } from "components/Inputs";
import Modal from "components/Modals/Modal";
import useLocations from "hooks/fetch/useLocations";
import useProviders from "hooks/fetch/useProviders";
import useRoles from "hooks/fetch/useRoles";
import useSpecialty from "hooks/fetch/useSpecialty";
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

export default function InviteUser({ params, callback }) {
    const { invite_staff, loading: isInviting } = useProviders()
    const { get_institution_providers } = useProviders()
    const { roles } = useSelector(state => state.roles)
    const { institutionId } = useSelector(state => state.auth)
    const { get_roles, loading: isLoadingRoles } = useRoles()
    const { get_Specialties, loading: isFetchingSpecialties = true } = useSpecialty()
    const { get_locations, loading: isFetchingLocations = true } = useLocations()
    const [availableSpecialties, setAvailableSpecialties] = useState([])
    const [availableLocations, setAvailableLocations] = useState([])
    const [selectedRole, setSelectedRole] = useState('')
    const [selectedSpecialty, setSelectedSpecialty] = useState('')
    const [selectedLocation, setSelectedLocation] = useState('')
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        specialty: '',
        role: '',
        location: '',
        locationName: ''
    })

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const selectRole = useCallback((e) => {
        setSelectedRole(e.name)
        setFormData({ ...formData, role: e.value })
    }, [formData])

    const selectSpecialty = useCallback((e) => {
        setSelectedSpecialty(e.value)
        setFormData({ ...formData, specialty: e.value })
    }, [formData])

    const selectLocation = useCallback((e) => {
        setSelectedLocation(e.address)
        setFormData({ ...formData, location: e.id, locationName: e.address })
    }, [formData])

    const invite = () => {
        invite_staff({ institutionId, staffs: [formData] })
            .then(res => {
                if (res?.code === 201) {
                    get_institution_providers({ id: `${institutionId}/providers`, params })
                    callback()
                }
            })
    }

    useEffect(() => {
        get_roles({ id: institutionId })
        get_Specialties({ limit: 1000 })
            .then(res => res?.code === 200 && setAvailableSpecialties(res?.data?.specialties))
        get_locations({ limit: 1000 })
            .then(res => res?.code === 200 && setAvailableLocations(res?.data?.locations))
    }, [])

    const rolesOptions = roles?.Roles?.map(role => {
        return { name: role.name, value: role.name }
    })

    const disabled = useMemo(() => {
        return !formData?.firstName || !formData.lastName || !formData.emailAddress ||
            !formData.specialty || !formData.role || !formData.location || isInviting
    }, [formData])

    const specialties = availableSpecialties?.map((item) => {
        return { name: item?.name, value: item?.name }
    })

    return (
        <Modal
            closeModal={callback}
            styles="w-[90%] md:w-[730px] md:pt-0 pt-20"
            title="Invite User"
            subTitle="Enter the details of your staff here"
        >
            <FormWrapper className="relative" onSubmit={invite}>
                <div className="py-[16px] overflow-y-auto h-[38vh] pb-[50px]">
                    <div className="sm:flex">
                        <div className="mb-[24px] w-full sm:mr-[24px]">
                            <Input
                                label="First Name"
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formData?.firstName}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-[24px] w-full">
                            <Input
                                label="Last Name"
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={formData?.lastName}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="sm:flex">
                        <div className="mb-[24px] w-full sm:mr-[24px]">
                            <Input
                                label="Email Address"
                                type="text"
                                id="emailAddress"
                                name="emailAddress"
                                value={formData?.emailAddress}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-[24px] w-full">
                            <Dropdown
                                label="Specialty"
                                type="select"
                                id="specialty"
                                name="specialty"
                                position="static"
                                loading={isFetchingSpecialties}
                                options={specialties}
                                selected={selectedSpecialty}
                                onSelect={selectSpecialty}
                            />
                        </div>
                    </div>
                    <div className="sm:flex">
                        <div className="mb-[24px] w-full sm:mr-[24px]">
                            <Dropdown
                                label="Role"
                                type="select"
                                id="role"
                                name="role"
                                position="static"
                                selected={selectedRole}
                                options={rolesOptions}
                                onSelect={selectRole}
                                loading={isLoadingRoles}
                            />
                        </div>
                        <div className="mb-[24px] w-full">
                            <Dropdown
                                label="Location"
                                id="location"
                                name="location"
                                //position="static"
                                selected={selectedLocation}
                                loading={isFetchingLocations}
                            //onSelect={selectLocation}
                            >
                                {availableLocations?.map((location) =>
                                    <div
                                        key={location?._id}
                                        data-testid={location?._id}
                                        onClick={() => {
                                            selectLocation({ address: `${location?.address}, ${location?.city}, ${location?.state}, ${location.country}`, id: location?._id })
                                        }}
                                        className="py-[11px] px-[16px] flex items-center justify-between
                                        cursor-pointer hide_tap transition ease-in-out duration-500 hover:bg-[#F2F3F3]"
                                    >
                                        <div className="w-full">
                                            <p className="text-14 font-campton_r capitalize truncate">
                                                {`${location?.state}, ${location?.country}.`}
                                            </p>
                                            <p className="text-[10px] font-campton_r capitalize truncate">{`${location?.address}, ${location?.city}`}</p>
                                        </div>
                                    </div>
                                )}
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className="modal-right-button-container rounded-[40px]">
                    <Button
                        name="Invite"
                        theme="blue"
                        className="h-[40px] text-16 px-[12px] w-[112px]"
                        disabled={disabled}
                        loading={isInviting}
                    />
                </div>
            </FormWrapper>
        </Modal>
    )
}

InviteUser.propTypes = {
    params: PropTypes.object,
    callback: PropTypes.func
}