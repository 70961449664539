export const visit_list_header = ['Patient Name', 'Visit ID', 'Visit type', 'Date created', 'Provider', 'Status', ''];
export const surgicalProcedures = [
  {
    id: 1,
    name: 'Appendectomy',
    value: 'Appendectomy'
  },
  {
    id: 2,
    name: 'Breast biopsy',
    value: 'Breast biopsy'
  },
  {
    id: 3,
    name: 'Carotid endarterectomy',
    value: 'Carotid endarterectomy'
  },
  {
    id: 4,
    name: 'Cataract surgery',
    value: 'Cataract surgery'
  },
  {
    id: 5,
    name: 'Cesarean section',
    value: 'Cesarean section'
  },
  {
    id: 6,
    name: 'Cholecystectomy',
    value: 'Cholecystectomy'
  },
  {
    id: 7,
    name: 'Coronary artery bypass',
    value: 'AppendCoronary artery bypassectomy'
  },
  {
    id: 8,
    name: 'Debridement of wound, burn, or infection',
    value: 'Debridement of wound, burn, or infection'
  },
  {
    id: 9,
    name: 'Dilation and curettage',
    value: 'Dilation and curettage'
  },
  {
    id: 10,
    name: 'Free skin graft',
    value: 'Free skin graft'
  },
  {
    id: 11,
    name: 'Hemorrhoidectomy',
    value: 'Hemorrhoidectomy'
  },
  {
    id: 12,
    name: 'Hysterectomy',
    value: 'Hysterectomy'
  },
  {
    id: 13,
    name: 'Hysteroscopy',
    value: 'Hysteroscopy'
  },
  {
    id: 14,
    name: 'Inguinal hernia repair.',
    value: 'Inguinal hernia repair.'
  },
  {
    id: 15,
    name: 'Low back pain surgery',
    value: 'Low back pain surgery'
  },
  {
    id: 16,
    name: 'Mastectomy.',
    value: 'Mastectomy.'
  },
  {
    id: 17,
    name: 'Partial colectomy',
    value: 'Partial colectomy'
  },
  {
    id: 18,
    name: 'Prostatectomy',
    value: 'Prostatectomy'
  }
]
export const medicalDiagnosisOptions = [
  {
    id: 1,
    name: 'Coronary heart disease',
    value: 'Coronary heart disease'
  },
  {
    id: 1,
    name: 'Atherosclerosis',
    value: 'Atherosclerosis'
  },
  {
    id: 1,
    name: 'Cardiomyopathy',
    value: 'Cardiomyopathy'
  },
  {
    id: 1,
    name: 'Heart attack',
    value: 'Heart attack'
  },
  {
    id: 1,
    name: 'Pericarditis',
    value: 'Pericarditis'
  },
  {
    id: 1,
    name: 'Peripheral vascular disease',
    value: 'Peripheral vascular disease'
  },
  {
    id: 1,
    name: 'Rheumatic heart disease',
    value: 'Rheumatic heart disease'
  },
  {
    id: 1,
    name: 'Aortic valve stenosis',
    value: 'Aortic valve stenosis'
  },
  {
    id: 1,
    name: 'Congestive heart failure',
    value: 'Congestive heart failure'
  },
  {
    id: 1,
    name: 'Atrial fibrillation',
    value: 'Atrial fibrillation'
  },
]
export const allergiesTypes = [
  {
    id: 1,
    name: 'Penicillin',
    value: 'Penicillin'
  },
  {
    id: 2,
    name: 'Ampicillin',
    value: 'Ampicillin'
  },
  {
    id: 3,
    name: 'Amoxicillin',
    value: 'Amoxicillin'
  },
  {
    id: 4,
    name: 'Tetracycline',
    value: 'Tetracycline'
  },
  {
    id: 5,
    name: 'Sulfa drugs',
    value: 'Sulfa drugs'
  },
  {
    id: 6,
    name: 'Carbamezepine',
    value: 'Carbamezepine'
  },
  {
    id: 7,
    name: 'Lamotrigine',
    value: 'Lamotrigine'
  },
  {
    id: 8,
    name: 'Phenytoin',
    value: 'Phenytoin'
  },
  {
    id: 9,
    name: 'Aspirin',
    value: 'Aspirin'
  },
  {
    id: 10,
    name: 'Ibuprofen',
    value: 'Ibuprofen'
  },
  {
    id: 11,
    name: 'Chemotherapy drugs',
    value: 'Chemotherapy drugs'
  },
  {
    id: 12,
    name: 'Animal fur or hair',
    value: 'Animal fur or hair'
  },
  {
    id: 13,
    name: 'Dust Mites',
    value: 'Dust Mites'
  },
  {
    id: 14,
    name: 'Eggs',
    value: 'Eggs'
  },
  {
    id: 15,
    name: 'Seafood',
    value: 'Seafood',
  },
  {
    id: 16,
    name: 'Soy',
    value: 'Soy'
  },
  {
    id: 17,
    name: 'Nuts',
    value: 'Nuts'
  },
  {
    id: 18,
    name: 'Gluten',
    value: 'Gluten'
  },
]
export const hereditaryDiseases = [
  {
    id: 1,
    value: 'Brugada Syndrome',
    name: 'Brugada Syndrome',
  },
  {
    id: 2,
    value: 'Charcot-Marie-Tooth Disease',
    name: 'Charcot-Marie-Tooth Disease',
  },
  {
    id: 3,
    value: 'Cleft Lip and Palate',
    name: 'Cleft Lip and Palate',
  },
  {
    id: 4,
    value: 'Arrhythmogenic Right Ventricular Dysplasia/Cardiomyopathy (ARVD/C)',
    name: 'Arrhythmogenic Right Ventricular Dysplasia/Cardiomyopathy (ARVD/C)',
  },
  {
    id: 5,
    value: 'Cleidocranial Dysplasia (CCD)',
    name: 'Cleidocranial Dysplasia (CCD)',
  },
  {
    id: 6,
    value: 'Cystic Fibrosis',
    name: 'Cystic Fibrosis',
  },
  {
    id: 7,
    value: 'Familial Adenomatous Polyposis',
    name: 'Familial Adenomatous Polyposis',
  },
  {
    id: 8,
    value: 'Hirschsprungs Disease',
    name: 'Hirschsprungs Disease',
  },
  {
    id: 9,
    value: 'Huntington`s Disease',
    name: 'Huntington`s Disease',
  },
  {
    id: 10,
    value: 'Klinefelter Syndrome',
    name: 'Klinefelter Syndrome',
  },
  {
    id: 11,
    value: 'Kneist Syndrome',
    name: 'Kneist Syndrome',
  },
  {
    id: 12,
    value: 'Marfan syndrome',
    name: 'Marfan syndrome',
  },
  {
    id: 13,
    value: 'Mucopolysaccharidoses (MPS)',
    name: 'Mucopolysaccharidoses (MPS)',
  },
  {
    id: 14,
    value: 'Muscular Dystrophy',
    name: 'Muscular Dystrophy',
  },
  {
    id: 15,
    value: 'Sickle Cell Disease',
    name: 'Sickle Cell Disease',
  },
  {
    id: 16,
    value: 'Von Hippel-Lindau (VHL)',
    name: 'Von Hippel-Lindau (VHL)',
  },
];
export const immunizationTypes = [
  {
    id: 1,
    name: 'Hepatitis A',
    value: 'Hepatitis A',
  },
  {
    id: 2,
    name: 'Hepatitis B',
    value: 'Hepatitis B',
  },
  {
    id: 3,
    name: 'Rotavirus',
    value: 'Rotavirus',
  },
  {
    id: 4,
    name: 'Diphtheria, Tetanus, and Pertussis (DTaP)',
    value: 'Diphtheria, Tetanus, and Pertussis (DTaP)',
  },
  {
    id: 5,
    name: 'Tetanus, Diphtheria, and Pertussis (TdaP)',
    value: 'Tetanus, Diphtheria, and Pertussis (TdaP)',
  },
  {
    id: 6,
    name: 'Haemophilus influenzae type b',
    value: 'Haemophilus influenzae type b',
  },
  {
    id: 7,
    name: 'Pneumococcal conjugate (PCV13, PCV15, PCV20)',
    value: 'Pneumococcal conjugate (PCV13, PCV15, PCV20)',
  },
  {
    id: 8,
    name: 'Pneumococcal Polysaccharide (PPSV 23)',
    value: 'Pneumococcal Polysaccharide (PPSV 23)',
  },
  {
    id: 9,
    name: 'Polio virus',
    value: 'Polio virus',
  },
  {
    id: 10,
    name: 'COVID-19',
    value: 'COVID-19',
  },
  {
    id: 11,
    name: 'Influenza',
    value: 'Influenza',
  },
  {
    id: 12,
    name: 'Measles, Mumps, Rubella (MMR)',
    value: 'Measles, Mumps, Rubella (MMR)',
  },
  {
    id: 13,
    name: 'Varicella (VAR)',
    value: 'Varicella (VAR)',
  },
  {
    id: 14,
    name: 'Zoster Recombinant (RZV)',
    value: 'Zoster Recombinant (RZV)',
  },
  {
    id: 15,
    name: 'Human papillomavirus (HPV)',
    value: 'Human papillomavirus (HPV)',
  },
  {
    id: 16,
    name: 'Meningococcal',
    value: 'Meningococcal',
  },
  {
    id: 17,
    name: 'Dengue',
    value: 'Dengue',
  },
  {
    id: 18,
    name: 'Rabies',
    value: 'Rabies',
  },
  {
    id: 19,
    name: 'Cholera',
    value: 'Cholera',
  },
  {
    id: 21,
    name: 'Adenovirus',
    value: 'Adenovirus',
  },
  {
    id: 22,
    name: 'Influenza',
    value: 'Influenza',
  },
  {
    id: 23,
    name: 'Shingles',
    value: 'Shingles',
  },
  {
    id: 24,
    name: 'Tuberculosis (BCG)',
    value: 'Tuberculosis (BCG)',
  },
  {
    id: 25,
    name: 'Typhoid Fever',
    value: 'Typhoid Fever',
  },
  {
    id: 26,
    name: 'Yellow Fever',
    value: 'Yellow Fever',
  },
];
export const lab_request_header = ['Test Type', 'Requested By', 'Date Requested', 'Status', ''];
export const lab_request_data = [
  {
    _id: 1,
    testType: 'Complete blood test, Blood enzyme test',
    requestedBy: {
      firstName: 'John',
      lastName: 'Alao'
    },
    dateRequested: 'May 27, 2023',
    requestStatus: 'requested'
  },
  {
    _id: 2,
    testType: 'Complete blood test, Blood enzyme test',
    requestedBy: {
      firstName: 'Dr. John',
      lastName: 'Alao'
    },
    dateRequested: 'May 27, 2023',
    requestStatus: 'in-progress'
  },
  {
    _id: 3,
    testType: 'Complete blood test, Blood enzyme test',
    requestedBy: {
      firstName: 'Dr. John',
      lastName: 'Alao'
    },
    dateRequested: 'May 27, 2023',
    requestStatus: 'completed'
  },
]
export const clinical_assessment_header = ['Test Type', 'Requested By', 'Date Requested', ''];
export const clinical_assessment_data = [
  {
    _id: 1,
    type: 'Complete blood test, Blood enzyme test',
    requestedBy: {
      firstName: 'Dave',
      lastName: 'East'
    },
    dateRequested: 'May 27, 2023',
  },
  {
    _id: 2,
    type: 'Complete blood test, Blood enzyme test',
    requestedBy: {
      firstName: 'Dave',
      lastName: 'East'
    },
    dateRequested: 'May 27, 2023',
  }
]
export const physical_examination_types = [
  {
    id: '1',
    show: false,
    title: 'Appearance',
    name: "appearance",
    observation: '',
    suggestions: [
      'Patient is in no acute distress.',
      'Patient is in distress.',
      'Patient appears well developed and well nourished.',
      'Patient appears malnourished.'
    ]
  },
  {
    id: '2',
    show: false,
    title: 'Head',
    name: "head",
    observation: '',
    suggestions: [
      'Normocephalic and atraumatic.'
    ]
  },
  {
    id: '3',
    show: false,
    title: 'Right Ear/ Left Ear',
    name: "ears",
    observation: '',
    suggestions: [
      'External ear normal.'
    ]
  },
  {
    id: '4',
    show: false,
    title: 'Eyes',
    name: "eyes",
    observation: '',
    suggestions: [
      'Pupils are equal, round, and reactive to light and accommodation (PERRLA).',
      'Conjuctivae and EOM are normal.',
      'Pale conjuctivae.',
      'Right eye discharge.',
      'Left eye discharge.',
      'Exhibits no discharge.',
      'Scleral icterus present.',
      'No scleral icterus.'
    ]
  },
  {
    id: '5',
    show: false,
    title: 'Nose',
    name: "nose",
    observation: '',
    suggestions: [
      'Nose normal.'
    ]
  },
  {
    id: '6',
    show: false,
    title: 'Throat',
    name: "throat",
    observation: '',
    suggestions: [
      'Normal Mucous membranes.',
      'Pale Mucous membranes.',
      'Cyanotic Mucous membranes.'
    ]
  },
  {
    id: '7',
    show: false,
    title: 'Neck',
    name: "neck",
    observation: '',
    suggestions: [
      'Normal range of motion',
      'Neck supple.',
      'Tracheal deviation present',
      'lymphadenopathy',
      'No lymphadenopathy',
      'Thyroid normal',
      'Thyromegaly'
    ]
  },
  {
    id: '8',
    show: false,
    title: 'Cardiovascular (Heart)',
    name: "cardiovascular",
    observation: '',
    suggestions: [
      'Normal rate and regular rhythm.',
      'Irregularly irregular rhythm',
      'Normal S1/S2',
      'No murmurs, rubs, or gallops',
      'S3 present',
      'S4 present'
    ]
  },
  {
    id: '9',
    show: false,
    title: 'Pulmonary (Chest)',
    name: 'pulmonary',
    observation: '',
    suggestions: [
      'Clear breath sounds bilaterally.',
      'No respiratory distress.',
      'No accessory muscle use/stridor.'
    ]
  },
  {
    id: '10',
    show: false,
    title: 'Abdominal',
    name: 'abdominal',
    observation: '',
    suggestions: [
      'Soft.',
      'Nondistended.',
      'nontender.',
      'Positive bowel sounds.',
      'No hepatosplenomegaly.'
    ]
  },
  {
    id: '11',
    show: false,
    title: 'Musculoskeletal',
    name: 'musculoskeletal',
    observation: '',
    suggestions: [
      'Muscle strength 5/5 throughout',
      'Normal range of motion in all extremities',
      'Normal deep tendon reflexes',
      'No edema',
      'No cyanosis'
    ]
  },
  {
    id: '12',
    show: false,
    title: 'Neurological',
    name: 'neurological',
    observation: '',
    suggestions: [
      'No cranial nerve deficit',
      'Normal gait and coordination',
      'Patient is alert and oriented to person, place and time.',
      'Patient is disoriented.',
      'No motor deficits',
      'No sensory deficits'
    ]
  },
  {
    id: '13',
    show: false,
    title: 'Skin',
    name: 'skin',
    observation: '',
    suggestions: [
      'Skin is warm and dry.',
      'No rash noted.',
      'Patient is not diaphoretic.',
      'No erythema observed.',
      'No pallor.',
      'Skin is jaundiced.',
      'Cyanosis present.',
      'Unspecified skin lesions.'
    ]
  },
  {
    id: '14',
    show: false,
    title: 'Psychiatric',
    name: 'psychiatric',
    observation: '',
    suggestions: [
      'Normal mood and affect.',
      'Flat affect.',
      'Normal behavior.',
      'Judgement and thought content normal.'
    ]
  }
]
export const bloodTypes = [
  {
    id: 1,
    value: 'A+',
    name: 'A+',
  },
  {
    id: 2,
    value: 'A-',
    name: 'A-',
  },
  {
    id: 3,
    value: 'B+',
    name: 'B+',
  },
  {
    id: 4,
    value: 'B-',
    name: 'B-',
  },
  {
    id: 5,
    value: 'AB+',
    name: 'AB+',
  },
  {
    id: 6,
    value: 'AB-',
    name: 'AB-',
  },
  {
    id: 7,
    value: 'O+',
    name: 'O+',
  },
  {
    id: 8,
    value: 'O-',
    name: 'O-',
  },
];
export const genotypes = [
  {
    id: 1,
    value: 'AA',
    name: 'AA',
  },
  {
    id: 2,
    value: 'AS',
    name: 'AS',
  },
  {
    id: 3,
    value: 'AC',
    name: 'AC',
  },
  {
    id: 4,
    value: 'SS',
    name: 'SS',
  },
  {
    id: 5,
    value: 'SC',
    name: 'SC',
  },
];
export const visit_types = [
  {
    id: 1,
    name: 'In-patient',
    value: 'inPatient'
  },
  {
    id: 2,
    name: 'Out-patient',
    value: 'outPatient'
  },
  {
    id: 3,
    name: 'Tele-health',
    value: 'teleHealth'
  }
]
export const visit_status = [
  {
    id: 1,
    name: 'Completed',
    value: 'completed'
  },
  {
    id: 2,
    name: 'Ongoing',
    value: 'onGoing'
  }
]