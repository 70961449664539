import React from 'react';
import PropTypes from 'prop-types';
import usePermission from 'hooks/usePermission';

// Check if user has permission via a HOC
const Restricted = ({ module, permission, children }) => {
  const { hasPermission } = usePermission()

  if (hasPermission(module, permission)) return <>{children}</>;

  return null
};

export default Restricted;

Restricted.propTypes = {
  permission: PropTypes.string,
  module: PropTypes.string,
  children: PropTypes.element,
};
