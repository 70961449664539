import React, { useEffect, useMemo, useState } from "react";
import { SlideUp } from "components/Animations";
import Navbar from "components/Navbar";
import { useNavigate } from "react-router-dom";
import CareTeam from "./CareTeam";
import AdmissionInformation from "./AdmissionInformation";
import OtherInformation from "./OtherInformation";
import HorizontalMenu from "components/HorizontalMenu";
import Avatar from "components/Avatar/Avatar";
import Status from "components/Status";
import OrderTests from "components/OrderTests";
import Clinicals from "./Clinicals";
import usePatient from "hooks/fetch/usePatient";
import Spinner from "components/Spinner";
import { userInitals } from "utils/ImagePlaceholder";
import TreatmentPlan from './TreatmentPlan';
import PatientHistory from "./PatientHistory";
import Restricted from "HOC/Restricted";
import ProgressNoteBanner from "./ProgressNoteBanner/ProgressNoteBanner";
import BreadCrumbs from "components/BreadCrumbs";
import AddProgressNote from "./ProgressNote/AddProgressNote";
import useTable from "hooks/useTable";
import useVisit from "hooks/fetch/useVisit";
import { useClinicalState } from "store/modules/clinicals";

export default function SingleInPatient() {
    const navigate = useNavigate()
    const [currentTab, setCurrentTab] = useState('Clinicals')
    const [showModal, setShowModal] = useState(false)
    const { get_in_patient } = usePatient()
    const [inPatient, setInPatient] = useState()
    const id = window.location.pathname.split("/")[3]
    const { params } = useTable()
    const { get_progress_notes } = useVisit()
    const { progressNotes } = useClinicalState();
    const progressNoteType = useMemo(() => {
        return progressNotes?.progressNotes?.map(note => note.type)
    }, [progressNotes])

    const getInPatientProxy = () => {
        get_in_patient(id)
            .then((res) => {
                setInPatient(res?.data)
                get_progress_notes({ ...params, visitId: res?.data?.visit });
            })
    }

    const options = [
        {
            title: 'In-Patient Management',
            method: () => navigate(-1)
        },
        {
            title: `${inPatient?.patientInfo?.firstName || ''} ${inPatient?.patientInfo?.lastName || ''}`,
        },
    ]

    useEffect(() => {
        getInPatientProxy()
    }, [])

    const tabs = [
        {
            id: 1,
            name: 'Clinicals',
            component: <Clinicals patientId={inPatient?.patientInfo?.id} visitId={inPatient?.visit} />
        },
        {
            id: 2,
            name: 'Order Tests',
            component: <OrderTests patientId={inPatient?.patientInfo?.id} visitId={inPatient?.visit} purpose='visit' visitStatus='admitted' />
        },
        {
            id: 3,
            name: 'Treatment Plans',
            component: <TreatmentPlan patient={inPatient} visitId={inPatient?.visit} />
        },
        {
            id: 4,
            name: 'Patient History',
            component: <PatientHistory inPatient={inPatient} />
        }
    ]

    const componentToDisplay = tabs?.find((tab) => tab.name === currentTab)?.component

    return (
        <main>
            <Navbar title="Patient Management" />
            <BreadCrumbs options={options} />
            <Restricted module="Inpatient Management" permission="Admit patient">
                <>
                    {!inPatient ? <Spinner height="h-[80vh]" /> :
                        <SlideUp>
                            <div className="md:flex overflow-hidden md:h-[80vh]">
                                <section className="md:flex md:flex-col md:w-[328px] shrink-0">
                                    <div className="p-[24px]">
                                        <div className="flex flex-col items-center bg-brand_primary rounded-[16px] py-[24px]">
                                            <Avatar
                                                url={inPatient?.patientInfo?.profilePic}
                                                width="80px"
                                                height="80px"
                                                canViewProfile={true}
                                                //alt={singleVisit?.provider?.firstName?.split('')[0]}
                                                initials={userInitals(inPatient?.patientInfo?.firstName, inPatient?.patientInfo?.lastName)}
                                                className="bg-brand_secondary w-[80px] h-[80px] mb-[12px] cursor-pointer"
                                            />
                                            <div className="text-neutral_white text-center font-campton_r mb-[16px]">
                                                <p className="text-16 mb-[4px] font-campton_m capitalize">
                                                    {inPatient?.patientInfo?.firstName} {inPatient?.patientInfo?.lastName} <span className="text-14 font-campton_r">{inPatient?.patientInfo?.gender ? inPatient?.patientInfo?.gender[0] : ''} </span></p>
                                                <p className="text-14 mb-[4px]">{inPatient?.patientInfo?.emailAddress} </p>
                                                <p className="text-14">{inPatient.patientInfo.countryCode}{inPatient?.patientInfo?.phoneNumber} </p>
                                            </div>
                                            <Status
                                                status={inPatient?.patientInfo?.gNumber}
                                                className="text-neutral_white font-campton_r bg-[#143566]"
                                            />
                                        </div>
                                    </div>
                                    <div className="overflow-y-auto md:h-[60vh] px-[24px]">
                                        <OtherInformation data={inPatient} />
                                        <AdmissionInformation data={inPatient} />
                                        <CareTeam
                                            careTeam={inPatient?.careTeam}
                                            admittedBy={inPatient?.admittedBy}
                                            patientId={id}
                                            callback={getInPatientProxy}
                                        />
                                    </div>
                                </section>
                                <section className="border-l border-l-neutral_stroke_2 w-full">
                                    <div className="md:flex gap-[24px] pt-0 md:pt-[24px] p-[24px] border-b border-b-neutral_stroke_2">
                                        <ProgressNoteBanner addProgressNote={() => setShowModal(true)} patientId={inPatient?.patientInfo?.id} addedType={progressNoteType} />
                                    </div>
                                    <HorizontalMenu
                                        tabs={tabs}
                                        currentTab={currentTab}
                                        setCurrentTab={setCurrentTab}
                                    />
                                    <div className="h-full md:pb-[260px] p-[24px] overflow-auto">
                                        {componentToDisplay}
                                    </div>
                                </section>
                            </div>
                        </SlideUp>
                    }
                </>
            </Restricted>
            {
                showModal && <AddProgressNote closeModal={() => setShowModal(false)} visitId={inPatient?.visit} patientId={inPatient?.patientInfo?.id} addedType={progressNoteType} />
            }
        </main>
    )
}
