import React from 'react'
import PropTypes from 'prop-types';

export default function VitalsCard({variant, value, icon, title, label, className}) {
  return (
    <div key={title} className={`${className} border-neutral_stroke_2`}>
    <div className="text-12 flex font-campton_r space-x-2.5 min-w-max">
      {icon}
      <div>
        <p className="text-neutral_body ">{title}</p>
        <p className={`${variant || 'text-primary_shade_400'}  text-14 mt-1.5`}>{value || 'N/A'}{value && label }</p>
      </div>
    </div>
  </div>
  )
}

VitalsCard.propTypes = {
    value: PropTypes.string,
    variant: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.any,
};