import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Button } from "components/Buttons"
import FormWrapper from "components/FormWrapper"
import { Input, TextArea } from "components/Inputs"
import { DefaultDatepicker } from "components/Datepicker/Index"
import CheckBox from "components/Inputs/CheckBox";
import useWorkingDays from "hooks/useWorkingDays"
import { convertTimeToDate } from "utils/formatTime"
import { formatToDateWithDash } from "utils/formatDate"
import dayjs from "dayjs"
import useShift from "hooks/fetch/useShift"
import Modal from "components/Modals/Modal"

export default function EditShift({ shift, callback }) {
    const { edit_shift, loading } = useShift()
    const { workingDays, updateWorkingDays, selectedWorkingDays } = useWorkingDays(shift.workingDays)
    const [formData, setFormData] = useState({
        shiftName: shift?.shiftName || '',
        note: shift?.note,
        startTime: convertTimeToDate(shift?.startTime),
        startDate: shift?.startDate ? new Date(shift?.startDate) : '',
        endDate: shift?.endDate ? new Date(shift?.endDate) : '',
        endTime: convertTimeToDate(shift?.endTime),
        staffs: shift?.staffs
    })

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const edit = () => {
        const newFormData = {
            ...formData,
            startTime: dayjs(formData?.startTime).format('hh:mm A'),
            endTime: dayjs(formData?.endTime).format('hh:mm A'),
            endDate: formatToDateWithDash(formData.endDate),
            startDate: formatToDateWithDash(formData.startDate),
            workingDays: selectedWorkingDays
        }

        edit_shift({ shiftId: shift?._id, formData: newFormData })
            .then(res => {
                if (res?.code === 200) callback()
            })
    }

    const disabled = useMemo(() => {
        return selectedWorkingDays < 1
    }, [selectedWorkingDays])

    return (
        <Modal
            closeModal={callback}
            styles="w-full md:w-[457px]"
            position="modal-right"
            variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
            title="Edit Shift"
        >
            <FormWrapper onSubmit={edit}>
                <h2 className="text-16 text-neutral_black font-campton_m mb-[16px]">Basic Information</h2>
                <div className="mb-[24px]">
                    <Input
                        label="Shift Name"
                        type="text"
                        id="shiftName"
                        name="shiftName"
                        disabled={true}
                        value={formData?.shiftName}
                    />
                </div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <div>
                        <DefaultDatepicker
                            label="Start Time"
                            startDate={formData?.startTime}
                            //timeCaption="Select Start time"
                            dateFormat="h:mm aa"
                            showTimeSelectOnly={true}
                            name="startTime"
                            showTimeSelect={true}
                            onSelect={(date) => setFormData({ ...formData, startTime: date })}
                            // value={formData?.startTime}
                            showTimeIcon={true}
                        />
                    </div>
                    <DefaultDatepicker
                        label="End Time"
                        startDate={formData?.endTime}
                        showTimeSelectOnly={true}
                        dateFormat="h:mm aa"
                        showTimeSelect={true}
                        //timeCaption="Select End time"
                        onSelect={(date) => setFormData({ ...formData, endTime: date })}
                        name="endTime"
                        value={formData?.endTime}
                        showTimeIcon={true}
                    />
                </div>
                <section className="border-b border-dashed border-b-neutral_stroke_2 mb-[24px]">
                    <h2 className="text-14 text-neutral_black font-campton_m mb-[12px]">How Long Should this Shift Run For?</h2>
                    <div className="grid grid-cols-2 gap-[16px]">
                        <div>
                            <DefaultDatepicker
                                label="Start date"
                                minDate={new Date()}
                                onSelect={(e) => setFormData({ ...formData, startDate: e })}
                                name="startDate"
                                startDate={formData?.startDate}
                            />
                        </div>
                        <div>
                            <DefaultDatepicker
                                label="End date"
                                minDate={new Date()}
                                onSelect={(e) => setFormData({ ...formData, endDate: e })}
                                name="endDate"
                                startDate={formData?.endDate}
                            />
                        </div>
                    </div>
                    <h2 className="text-14 text-neutral_black font-campton_m mb-[12px]">Select Working Days</h2>
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-[24px] mb-[24px]">
                        {workingDays.map((day) =>
                            <CheckBox
                                key={day.name}
                                variant="ml-0"
                                label={day.name}
                                value={day.name}
                                id={day.name}
                                checked={day.selected}
                                onChange={(e) => updateWorkingDays(e, day.selected)}
                            />
                        )}
                    </div>
                </section>
                <section className="mb-[24px]">
                    <h2 className="text-14 text-neutral_black font-campton_m mb-[12px]">Notes</h2>
                    <div>
                        <TextArea
                            label="Add note (optional)"
                            id="note"
                            name="note"
                            //readOnly={loading}
                            value={formData?.note}
                            onChange={handleChange}
                            rows={3}
                        />
                    </div>
                </section>
                <section className="flex justify-end fixed bottom-0 left-0 py-[16px] px-[24px] bg-neutral_white w-full">
                    <Button
                        name="Save changes"
                        theme="blue"
                        loading={loading}
                        disabled={disabled || loading}
                        className="h-[40px] text-16 w-[131px]"
                    />
                </section>
            </FormWrapper>
        </Modal>
    )
}

EditShift.propTypes = {
    callback: PropTypes.func,
    shift: PropTypes.object
}