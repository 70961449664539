export const limit_options = [
    {
        id: 0,
        name: '10 Entries',
        value: 10
    },
    {
        id: 1,
        name: '20 Entries',
        value: 20
    },
    {
        id: 2,
        name: '30 Entries',
        value: 30
    }
]