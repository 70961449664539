import FormWrapper from 'components/FormWrapper';
import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { ReactComponent as SurgeriesSolid } from 'assets/icons/surgeries-solid.svg';
import useVisit from 'hooks/fetch/useVisit';
import Dropdown from 'components/Dropdown';
import { DefaultDatepicker } from 'components/Datepicker/Index';
import { surgicalProcedures } from 'mocks/clinicals/visits';
import { PatientContext } from 'context';

export default function AddSurgeries({ callback }) {
  const { patient, setPatient } = useContext(PatientContext)
  const [selectedSurgeries, setSelectedSurgeries] = useState([])
  const [surgicalProcedure, setSurgicalProcedure] = useState()
  const { add_medical_record } = useVisit()
  const [formData, setFormData] = useState({
    procedure: '',
    date: ''
  });

  const addMedicalRecord = () => {
    const payload = {
      ...formData,
      date: dayjs(formData.date).format('YYYY-MM-DD'),
    };
    add_medical_record('surgeries', patient?._id, payload)
      .then(res => {
        if ([201, 200]?.includes(res?.code)) {
          setPatient(res?.data)
          setSelectedSurgeries([...selectedSurgeries, payload])
          setSurgicalProcedure('');
          setFormData({ procedure: '', date: '' })
        }
      })
  }

  const disabledBtn = !surgicalProcedure || !formData.date

  return (
    <div className="w-full lg:w-[730px] lg:h-[400px]">
      <FormWrapper className="h-full">
        <div className="flex justify-between flex-col h-full">
          <div className="grid gap-6 grid-cols-12 mt-6">
            <Dropdown
              type="select"
              id="surgicalProcedure"
              placeholder="Surgical Procedure"
              name="surgicalProcedure"
              variant="lg:col-span-6 col-span-12"
              width="mb-1"
              options={surgicalProcedures}
              selected={surgicalProcedure}
              onSelect={(data) => {
                setSurgicalProcedure(data.name);
                setFormData({ ...formData, procedure: data.value });
              }}
            />
            <DefaultDatepicker
              label="Date"
              containerVariant="lg:col-span-6 col-span-12"
              //minDate={new Date()}
              onSelect={(date) => setFormData({ ...formData, date })}
              name="date"
              value={formData?.date}
            />
            <p
              data-testid="test-add"
              className={`text-brand_secondary font-campton_m text-14  ${disabledBtn ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
              onClick={disabledBtn ? null : addMedicalRecord}
              disabled={disabledBtn}
            >
              Add
            </p>
            {selectedSurgeries.length > 0 && (
              <div className="col-span-12 border-t border-dashed border-neutral_stroke pt-6">
                {selectedSurgeries?.map((item) => (
                  <div key={item.procedure} className="flex space-x-4 mb-6 last-of-type:mb-0">
                    <SurgeriesSolid />
                    <div className="">
                      <p className="capitalize font-campton_m text-neutral_black text-14">{item?.procedure}</p>
                      <p className="capitalize text-12 text-neutral_body">
                        Date:
                        <span className="font-campton_r text-neutral_black "> {item.date}</span>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <button
              className="w-28 rounded-full h-10 py-0 px-0 bg-brand_primary font-campton_m text-neutral_white"
              onClick={callback}
            >
              Close
            </button>
          </div>
        </div>
      </FormWrapper>
    </div>
  );
}

AddSurgeries.propTypes = {
  callback: PropTypes.any
};
