import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SlideUp } from 'components/Animations';
import { Button } from 'components/Buttons';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import Table from 'components/Table';
import Modal from 'components/Modals/Modal';
import AddProcedure from './AddProcedure';
import { procedure_list_header } from 'mocks/patient';
import dayjs from 'dayjs';
import Avatar from 'components/Avatar/Avatar';
import { userInitals } from 'utils/ImagePlaceholder';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import usePatient from 'hooks/fetch/usePatient';
import { Search } from 'components/Inputs';
import useTable from 'hooks/useTable';
import useDebounce from 'hooks/useDebounce';
import { useClinicalState } from 'store/modules/clinicals';
import ViewProcedure from './ViewProcedure';
import { useParams } from 'react-router-dom';
import { PatientContext } from 'context';
import { useAuthState } from 'store/modules/auth';

const Procedure = ({ callback, singleVisit, visitStatus, canUpdate = true }) => {
  const { institutionId } = useAuthState();
  const [type, setType] = useState('');
  const { get_procedure, loading } = usePatient();
  const [selectedItem, setSelectedItem] = useState();
  const { handleSearch, params } = useTable();
  const { debouncedValue } = useDebounce(params.search);
  const { id } = useParams();
  const { procedures } = useClinicalState();
  const pathname = window.location.pathname;
  const page = pathname.includes('clinicals/patient') ? 'patient' : 'visit'
  const { patient, progressNote } = useContext(PatientContext)

  const newParams = {
    type: page,
    [page === 'patient' ? 'patientId' : 'visitId']: patient?.visit ?? id,
    institutionId
  };

  useEffect(() => {
    if(!progressNote) {
      get_procedure(
        debouncedValue ? { search: debouncedValue, ...newParams } : newParams
      );
    }
  }, [debouncedValue]);

  const handleViewProcedure = (item) => {
    setSelectedItem(item);
    setType('view');
  };

  const handleClose = () => {
    setType('')
  }

  return (
    <>
      <SlideUp>
        <div className="p-0">
          <div className="flex justify-between items-center mb-6">
            <div className="flex items-center space-x-4">
              <Button className="w-fit rounded-full h-8 font-campton_m text-14" theme="transparent" onClick={callback}>
                <p className="flex items-center space-x-4">
                  <ArrowBack fill="#748181" width="7" height="14" className="text-14" />
                  <span>Back</span>
                </p>
              </Button>
              <h2 className="text-16 sm:text-18 text-[#0D0F11] font-campton_m">Procedures</h2>
            </div>
            {!visitStatus && canUpdate && (pathname.includes('visit') || pathname.includes('progress-note'))  && <Button name="Add Procedure" theme="blue" onClick={() => setType('create')} className="h-8" />}
          </div>
          {procedures?.procedures?.length > 0 && (
            <div className="w-[400px] mb-6">
              <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
            </div>
          )}
          <Table
            headers={procedure_list_header}
            data={procedures?.procedures}
            hasHeader={procedures?.procedures?.length > 0}
            totalCount={procedures?.total}
            hasPagination={false}
            hasFilter={false}
            loading={loading}
            perPage={1000}
            buttonName="New Procedure"
            currentPage={procedures?.page}
          >
            <>
              {procedures?.procedures?.map((item) => (
                <tr
                  data-testid={item?._id}
                  onClick={() => handleViewProcedure(item)}
                  key={item?._id}
                  className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                >
                  <td className="px-[10px] py-[8px] flex space-x-2 items-center">
                    <p>{item.procedureName}</p>
                  </td>
                  <td className="capitalize px-[10px] py-[8px] text-14">
                    {dayjs(item.procedureDate).format('MMM DD, YYYY')}/{item.procedureTime}
                  </td>
                  <td className="px-[10px] py-[8px] flex items-center">
                    <Avatar
                      url={item?.providerInfo.profilePic}
                      width="40px"
                      height="40px"
                      alt={item?.providerInfo.firstName?.split('')[0]}
                      initials={userInitals(item.providerInfo.firstName, item.providerInfo.lastName)}
                      className="bg-brand_secondary mr-[15px] w-[40px] h-[40px]"
                    />
                    <div>
                      <p className="text-neutral_black text-14 font-campton_m capitalize">
                        {`${item?.providerInfo?.firstName}  ${item?.providerInfo?.lastName}`}
                      </p>
                      <p className=" text-neutral_body text-12 font-campton_r">{item.providerInfo?.emailAddress}</p>
                    </div>
                  </td>
                  <td className="px-[10px] py-[8px] capitalize text-14">
                    {dayjs(item.createdAt).format('MMM DD, YYYY')}
                  </td>
                  <td className="px-[10px] py-[8px] capitalize">
                    <ArrowRight fill="#A9B2B2" />
                  </td>
                </tr>
              ))}
            </>
          </Table>
        </div>
      </SlideUp>
      {type && (
        <Modal
          closeModal={() => setType('')}
          styles="w-full md:w-[457px]"
          position="modal-right"
          variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
          title={type === 'view' ? selectedItem?.procedureName : 'New Procedure'}
        >
          {type === 'create' && <AddProcedure callback={handleClose} singleVisit={singleVisit} />}
          {type === 'view' && <ViewProcedure data={selectedItem} />}
        </Modal>
      )}
    </>
  );
};

export default Procedure;
Procedure.propTypes = {
  callback: PropTypes.any,
  singleVisit: PropTypes.object,
  canUpdate: PropTypes.bool,
  visitStatus: PropTypes.any
};
