import React from 'react';
import PropTypes from 'prop-types';
import FormWrapper from 'components/FormWrapper';
import { DefaultDatepicker } from 'components/Datepicker/Index';
import { Button } from 'components/Buttons';
import { checkEmptyProperties } from 'utils/checkEmptyProperties';
import Modal from 'components/Modals/Modal';

const SpecialtyFilter = ({ params, setParams, closeModal, loading, func }) => {
  return (
    <Modal
      closeModal={closeModal}
      styles="w-full md:w-[457px]"
      position="modal-right"
      title="Filter"
      variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
    >
      <FormWrapper className="relative">
        <section className="modal-right-body">
          <p className="text-12 text-neutral_black font-campton_m mb-[10px]">By Date Range</p>
          <div className="grid grid-cols-2 gap-x-[16px]">
            <div>
              <DefaultDatepicker
                label="From"
                containerVariant="lg:col-span-6 col-span-12"
                value={params.fromDate}
                maxDate={new Date()}
                onSelect={(date) => setParams({ ...params, fromDate: date })}
                name="startDate"
                showCalender={true}
              />
            </div>
            <div>
              <DefaultDatepicker
                label="To"
                containerVariant="lg:col-span-6 col-span-12"
                value={params.toDate}
                minDate={params.fromDate}
                onSelect={(date) => setParams({ ...params, toDate: date })}
                name="endDate"
                showCalender={true}
              />
            </div>
          </div>
        </section>
        <div className="modal-right-button-container">
          <Button
            name="Cancel"
            theme="transparent"
            type="button"
            onClick={closeModal}
            className="h-[40px] text-16 w-[79px] mr-[16px]"
          />
          <Button
            name="Filter"
            theme="blue"
            loading={loading}
            onClick={func}
            className="h-[40px] text-16 w-[128px]"
            disabled={checkEmptyProperties(params)}
          />
        </div>
      </FormWrapper>
    </Modal>
  )
}

export default SpecialtyFilter
SpecialtyFilter.propTypes = {
  closeModal: PropTypes.func,
  params: PropTypes.object,
  setParams: PropTypes.func,
  loading: PropTypes.bool,
  func: PropTypes.func,
};