import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import React, { useContext, useState } from 'react';
import Dropdown from 'components/Dropdown';
import { medicalDiagnosisOptions } from 'mocks/clinicals/visits';
import { DefaultDatepicker } from 'components/Datepicker/Index';
import useVisit from 'hooks/fetch/useVisit';
import PropTypes from 'prop-types';
import { Button } from 'components/Buttons';
import dayjs from 'dayjs';
import { PatientContext } from 'context';

const AddMedicalHistory = ({ callback }) => {
  const [startDate, setStartDate] = useState();
  const { patient, setPatient } = useContext(PatientContext)
  const [endDate, setEndDate] = useState();
  const [medicalDiagnoses, setMedicalDiagnoses] = useState();
  const { add_medical_record, loading } = useVisit();
  const [formData, setFormData] = useState({
    name: '',
    medications: ''
  })

  const addMedicalRecord = () => {
    const payload = {
      ...formData,
      medications: [formData.medications],
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
    };
    add_medical_record('medicalDiagnosis', patient?._id, payload)
      .then(res => {
        if ([201, 200]?.includes(res?.code)) {
          setPatient(res?.data)
          callback()
        }
      })
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const disableBtn = !startDate || !endDate || !medicalDiagnoses || !formData.medications

  return (
    <div className="w-full lg:w-[730px] lg:h-[400px]">
      <FormWrapper className="h-full" onSubmit={addMedicalRecord}>
        <div className="flex justify-between flex-col h-full">
          <div className="grid gap-x-6 gap-y-5 grid-cols-12 mt-6">
            <Dropdown
              type="select"
              id="medicalDiagnoses"
              placeholder="Medical Diagnoses"
              name="medicalDiagnoses"
              variant="lg:col-span-6 col-span-12"
              width="mb-6"
              options={medicalDiagnosisOptions}
              selected={medicalDiagnoses}
              onSelect={(data) => {
                setMedicalDiagnoses(data.name);
                setFormData({ ...formData, name: data.value });
              }}
            />
            <div className="md:col-span-6 col-span-12">
              <Input
                label="Medications"
                type="text"
                id="medications"
                name="medications"
                // readOnly={loading}
                value={formData?.medications}
                onChange={handleChange}
                showError={false}
              />
            </div>
            <DefaultDatepicker
              label="Start Date"
              containerVariant="lg:col-span-6 col-span-12"
              startDate={startDate}
              maxDate={new Date()}
              onSelect={(date) => setStartDate(date)}
              name="startDate"
              value={formData?.startDate}
            />
            <DefaultDatepicker
              label="End Date"
              containerVariant="lg:col-span-6 col-span-12"
              maxDate={new Date()}
              startDate={endDate}
              onSelect={(date) => setEndDate(date)}
              name="endDate"
              value={formData?.endDate}
            />
          </div>
          <div className="flex justify-end">
            {/* <button className="w-28 rounded-full h-10 py-0 px-0 bg-brand_primary font-campton_m text-neutral_white">
              Save
            </button> */}
            <Button name="Save" theme="blue" className="w-28 h-10" disabled={disableBtn} loading={loading} />
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default AddMedicalHistory;
AddMedicalHistory.propTypes = {
  callback: PropTypes.any
};
