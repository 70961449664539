/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types"

export default function Status({ status, onClick, className }) {
    return (
        <p
            onClick={() => onClick ? onClick(status) : {}}
            data-testid={`status-${status}`}
            className={`
                rounded-[100px] w-fit hide_tap ${className || ''} ${onClick ? 'cursor-pointer' : ''} text-14 px-[8px] py-[1.5px] font-campton_r
                ${status?.toLowerCase() === 'inactive' ? 'bg-[#FFF5DB] text-[#996600]' : ''}
                ${status?.toLowerCase() === 'no-show' ? 'bg-[#dbf6ff] text-[#222460]' : ''}
                ${['ongoing', 'upcoming', 'routine', 'partial', 'awaitingapproval', 'unpaid'].includes(status?.toLowerCase()) ? 'bg-[#FEF1DD] text-[#F59E0B]' : ''}
                ${['active', 'completed', 'credit', 'approved', 'full', 'paid'].includes(status?.toLowerCase()) ? 'bg-[#E6F9EE] text-[#1BA154]' : ''}
                ${['deactivated', 'abnormal', 'cancelled', 'urgent', 'declined', 'debit', 'stopped'].includes(status?.toLowerCase()) ? 'bg-[#FBE5E5] text-error' : ''}
                ${['pending', 'in-progress'].includes(status?.toLowerCase()) ? 'bg-[#FEF3E2] text-[#F59E0B]' : ''}
                ${['requested', 'scheduled', 'rescheduled', 'paused'].includes(status?.toLowerCase()) ? 'bg-[#F2F3F3] text-[#748181]' : ''}
            `}
        >
            <span className="truncate capitalize">{status?.toLowerCase()}</span>
        </p>
    )
}

Status.propTypes = {
    status: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string
}