import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import useService from "./fetch/useService";
import useProviders from "./fetch/useProviders";
import usePatient from "./fetch/usePatient";
import { GPI_LENGTH } from "constants";
import dayjs from "dayjs";

export default function useAddProcedure(callback, patientId, visitId, progressNote) {
    const { institutionId, user } = useSelector(state => state.auth);
    const [procedures, setProcedures] = useState([])
    const { get_services, loading: isFetchingProcedures } = useService();
    const [providerDetails, setProviderDetails] = useState({});
    const [providerGPINumber, setProviderGPINumber] = useState('');
    const { get_provider_by_gpiNumber, loading: isFetchingProvider } = useProviders();
    const { create_procedure, loading } = usePatient();

    const [formData, setFormData] = useState({
        procedureName: '',
        procedureDate: '',
        procedureTime: '',
        preProcedureAssessment: '',
        preProcedureInstructions: '',
        patientId: progressNote?.patient?._id ?? patientId,
        visitId: progressNote?.visit ?? visitId,
        institutionId,
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSelect = (e) => {
        setFormData({ ...formData, procedureName: e.value })
    };
    
    useEffect(() => {
        if (providerGPINumber.length === (GPI_LENGTH ?? 12)) {
            get_provider_by_gpiNumber(providerGPINumber).then((res) => {
                if (res?.code === 200) setProviderDetails(res?.data);
            });
        }
    }, [providerGPINumber]);

    const providerName = providerDetails?.firstName
        ? `${providerDetails?.firstName} ${providerDetails?.lastName ?? ''}`
        : ''

    const selectedProcedure = procedures?.find((item => item?.name?.toLowerCase() === formData?.procedureName?.toLowerCase()))

    const createProcedure = () => {
        create_procedure(
            {
                ...formData,
                progressNoteId: progressNote?._id,
                procedureDate: dayjs(formData.procedureDate).format('YYYY-MM-DD'),
                procedureTime: dayjs(formData.procedureTime).format('hh:mm A'),
                attendingProviderId: providerDetails ? providerDetails?._id : '',
                totalFee: Number(selectedProcedure.serviceProperties[0].totalFee),
                serviceDetails: [
                    {
                        category: 'Procedure',
                        subCategory: selectedProcedure?.name,
                        fee: selectedProcedure.serviceProperties[0].fee,
                        vat: selectedProcedure.serviceProperties[0].vat
                    }
                ]
            },
            callback,
        );
    };

    const getProcedureServices = () => {
        get_services({
            limit: 1000,
            category: 'Procedure',
            type: 'institution',
            providerId: user?.type !== 'staff' ? '' : user?._id,
            institutionId
        })
            .then(res => setProcedures(res?.data?.services || []))
    };

    useEffect(() => {
        getProcedureServices()
    }, []);

    const procedureList = procedures.map(p => {
        return { name: p?.name, value: p?.name, id: p?._id }
    })

    return {
        handleChange,
        handleSelect,
        providerName,
        createProcedure,
        procedureList,
        isFetchingProcedures,
        setProviderGPINumber,
        providerGPINumber,
        isFetchingProvider,
        loading,
        formData,
        setFormData
    }
}