import FormWrapper from 'components/FormWrapper'; import { Input } from 'components/Inputs';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Button from 'components/Buttons/Button';
import useVisit from 'hooks/fetch/useVisit';
import { immunizationTypes } from 'mocks/clinicals/visits';
import Dropdown from 'components/Dropdown';
import { DefaultDatepicker } from 'components/Datepicker/Index';
import { PatientContext } from 'context';

const AddImmunization = ({ callback }) => {
  const [name, setName] = useState();
  const { patient, setPatient } = useContext(PatientContext)
  const { add_medical_record, loading } = useVisit()
  const [formData, setFormData] = useState({
    name: '',
    vaccineNumber: '',
    routeOfAdministration: '',
    site: '',
    date: '',
    location: '',
    manufacturer: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const addMedicalRecord = () => {
    const payload = {
      ...formData,
      date: dayjs(formData?.date).format('YYYY-MM-DD'),
    };
    add_medical_record('immunizationHistory', patient?._id, payload)
      .then(res => {
        if ([201, 200]?.includes(res?.code)) {
          setPatient(res?.data)
          callback()
        }
      })
  }

  const disabledBtn =
    !formData.location ||
    !formData?.date ||
    !formData.manufacturer ||
    !formData.routeOfAdministration ||
    !formData.site ||
    !formData.vaccineNumber ||
    !name

  return (
    <div className="w-full lg:w-[730px] lg:h-[400px]">
      <FormWrapper className="h-full" onSubmit={addMedicalRecord}>
        <div className="flex justify-between flex-col h-full">
          <div className="grid gap-x-6 gap-y-5 grid-cols-12 mt-6">
            <Dropdown
              type="select"
              id="name"
              placeholder="Immunization Name"
              name="name"
              variant="lg:col-span-6 col-span-12"
              width="mb-1"
              options={immunizationTypes}
              selected={name}
              onSelect={(data) => {
                setName(data.name);
                setFormData({ ...formData, name: data.value });
              }}
            />
            <div className="lg:col-span-6 col-span-12">
              <Input
                label="Vaccine Number"
                type="text"
                id="vaccineNumber"
                name="vaccineNumber"
                readOnly={loading}
                value={formData?.vaccineNumber}
                onChange={handleChange}
                showError={false}
              />
            </div>
            <div className="lg:col-span-6 col-span-12">
              <Input
                label="Route of Administration"
                type="text"
                id="routeOfAdministration"
                name="routeOfAdministration"
                readOnly={loading}
                value={formData?.routeOfAdministration}
                onChange={handleChange}
              />
              {/* <p className="text-12 font-campton_r text-neutral_body">Helper text</p> */}
            </div>
            <div className="lg:col-span-6 col-span-12">
              <Input
                label="Site"
                type="text"
                id="site"
                name="site"
                readOnly={loading}
                value={formData?.site}
                onChange={handleChange}
              />
              {/* <p className="text-12 text-neutral_body font-campton_r">Helper text</p> */}
            </div>
            <DefaultDatepicker
              label="Date"
              containerVariant="lg:col-span-4 md:col-span-6 col-span-12"
              //maxDate={new Date()}
              onSelect={(date) => setFormData({ ...formData, date })}
              name="date"
              value={formData?.date}
            />
            <div className="lg:col-span-4 md:col-span-6 ol-span-12">
              <Input
                label="Location"
                type="text"
                id="location"
                name="location"
                readOnly={loading}
                value={formData?.location}
                onChange={handleChange}
                showError={false}
              />
            </div>
            <div className="lg:col-span-4 md:col-span-6 ol-span-12">
              <Input
                label="Manufacturer"
                type="text"
                id="manufacturer"
                name="manufacturer"
                readOnly={loading}
                value={formData?.manufacturer}
                onChange={handleChange}
                showError={false}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <Button name="Add" theme="blue" className="w-24 h-10" disabled={disabledBtn} loading={loading} />
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default AddImmunization;
AddImmunization.propTypes = {
  callback: PropTypes.any
};
