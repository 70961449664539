import React from 'react';
import { Button } from 'components/Buttons';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

export default function ProgressNoteBanner({ addProgressNote, addedType }) {
    const navigate = useNavigate()

    return (
        <div className='bg-accent_blue rounded-[16px] p-[24px] w-full'>
            <div className='flex justify-between'>
                <div className='mb-[14px] mr-[20px]'>
                    <p className='text-16 font-campton_sb text-neutral_white mb-[4px]'>Progress Notes</p>
                    <p className='text-12 font-campton_r text-[#FFFFFFB8] max-w-[462px]'>Add crucial details to the Progress Note for an update of your patient’s health</p>
                </div>
                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="64" height="64" rx="32" fill="#EFF8FB" />
                    <path d="M27.668 28C27.668 27.7348 27.7733 27.4804 27.9609 27.2929C28.1484 27.1054 28.4028 27 28.668 27H36.668C36.9332 27 37.1875 27.1054 37.3751 27.2929C37.5626 27.4804 37.668 27.7348 37.668 28C37.668 28.2652 37.5626 28.5196 37.3751 28.7071C37.1875 28.8946 36.9332 29 36.668 29H28.668C28.4028 29 28.1484 28.8946 27.9609 28.7071C27.7733 28.5196 27.668 28.2652 27.668 28ZM28.668 33H36.668C36.9332 33 37.1875 32.8946 37.3751 32.7071C37.5626 32.5196 37.668 32.2652 37.668 32C37.668 31.7348 37.5626 31.4804 37.3751 31.2929C37.1875 31.1054 36.9332 31 36.668 31H28.668C28.4028 31 28.1484 31.1054 27.9609 31.2929C27.7733 31.4804 27.668 31.7348 27.668 32C27.668 32.2652 27.7733 32.5196 27.9609 32.7071C28.1484 32.8946 28.4028 33 28.668 33ZM32.668 35H28.668C28.4028 35 28.1484 35.1054 27.9609 35.2929C27.7733 35.4804 27.668 35.7348 27.668 36C27.668 36.2652 27.7733 36.5196 27.9609 36.7071C28.1484 36.8946 28.4028 37 28.668 37H32.668C32.9332 37 33.1875 36.8946 33.3751 36.7071C33.5626 36.5196 33.668 36.2652 33.668 36C33.668 35.7348 33.5626 35.4804 33.3751 35.2929C33.1875 35.1054 32.9332 35 32.668 35ZM44.668 22V35.5863C44.6688 35.849 44.6174 36.1093 44.5168 36.352C44.4161 36.5947 44.2682 36.815 44.0817 37L37.668 43.4137C37.4829 43.6003 37.2627 43.7482 37.0199 43.8488C36.7772 43.9494 36.517 44.0008 36.2542 44H22.668C22.1375 44 21.6288 43.7893 21.2538 43.4142C20.8787 43.0391 20.668 42.5304 20.668 42V22C20.668 21.4696 20.8787 20.9609 21.2538 20.5858C21.6288 20.2107 22.1375 20 22.668 20H42.668C43.1984 20 43.7071 20.2107 44.0822 20.5858C44.4573 20.9609 44.668 21.4696 44.668 22ZM22.668 42H35.668V36C35.668 35.7348 35.7733 35.4804 35.9609 35.2929C36.1484 35.1054 36.4028 35 36.668 35H42.668V22H22.668V42ZM37.668 37V40.5875L41.2542 37H37.668Z" fill="#36A1C5" />
                </svg>
            </div>
            <div className='md:flex'>
                <Button
                    name="Add progress note"
                    theme="blue"
                    className='min-w-[160px] h-[32px] text-14 px-[12px] mr-[16px] border-0 mb-[10px] md:mb-0'
                    onClick={addProgressNote}
                />
                {addedType?.length ? <Button
                    name="View previous notes"
                    theme="transparent"
                    className='min-w-[160px] h-[32px] text-14 px-[12px]'
                    onClick={() => navigate('progress-notes')}
                /> : ''
                }
            </div>
        </div>
    );
}

ProgressNoteBanner.propTypes = {
    addProgressNote: PropTypes.func,
    addedType: PropTypes.array
};

