import { Button } from "components/Buttons"
import FormWrapper from "components/FormWrapper"
import { TextArea } from "components/Inputs"
import useVisit from "hooks/fetch/useVisit"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

export default function Summary({ visitId, visit, progressNote, callback, visitStatus, canUpdate }) {
    const pathname = window.location.pathname;
    const { update_visit_summary, loading, create_visit_summary } = useVisit()
    const visitSummary = progressNote?.visitSummary?.summary ?? visit?.visitSummary?.summary
    const visitSummaryId = progressNote?.visitSummary?._id ?? visit?.visitSummary?._id
    const [summary, setSummary] = useState('')
    const [addSummary, setAddSummary] = useState(false)
    const type = pathname.includes('progress-note') ? 'progressNote' : 'visit'
    
    const handleChange = (e) => {
        setSummary(e.target.value)
    }

    useEffect(() => {
        setSummary(visitSummary ?? '')
        setAddSummary(visitSummary ? false : true)
    }, [visitSummary])

    const updateSummary = () => {
        const payload = {
            summary,
            type,
            [type]: progressNote?._id ?? visitId
        }
        const action = visitSummary ?
            update_visit_summary(visitSummaryId, { summary }) :
            create_visit_summary(payload)

        action.then(res => {
            if ([200, 201].includes(res?.code)) {
                callback()
                setAddSummary(false)
            }
        })
    }

    return (
        <div className="mb-[28px]">
            {addSummary && canUpdate ?
                <FormWrapper onSubmit={updateSummary}>
                    <div className="mb-[24px]">
                        <TextArea
                            placeholder="Type here..."
                            id="summary"
                            name="summary"
                            readOnly={loading}
                            value={summary}
                            onChange={handleChange}
                            rows={4}
                        />
                    </div>
                    <div className="flex justify-end">
                        <Button
                            name="Save"
                            theme="blue"
                            className={`h-[32px] text-14 px-[12px] w-[58px]`}
                            loading={loading}
                            disabled={!summary || loading}
                        />
                    </div>
                </FormWrapper> :
                <div>
                    <p className="text-14 text-neutral_body font-campton_r leading-[21px] mb-[8px]">{summary}</p>
                    {!visitStatus && canUpdate &&
                        <div>
                            <Button
                                name="Edit"
                                theme="transparent"
                                className={`h-[32px] text-14 px-[12px] w-[58px]`}
                                onClick={() => setAddSummary(true)}
                            />
                        </div>
                    }
                </div>
            }
        </div>
    )
}

Summary.propTypes = {
    visitId: PropTypes.string,
    progressNote: PropTypes.object,
    visit: PropTypes.object,
    callback: PropTypes.func,
    visitStatus: PropTypes.bool,
    canUpdate: PropTypes.bool
}