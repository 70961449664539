import { physical_examination_types } from "mocks/clinicals/visits"
import { useEffect, useState } from "react"

export default function usePhysicalExamination(initialState) {
    const [examinationTypes, setExaminationTypes] = useState(physical_examination_types)

    useEffect(() => {
        if (initialState && Array.isArray(initialState)) {
            const result = examinationTypes.map((item) => {
                return {
                    ...item,
                    observation: initialState?.find(state => item.name === state.name)?.observation || item.observation
                }
            })
            setExaminationTypes(result)
        }
    },[])

    const handleChange = (observation, examinationTypeName) => {
        let result = examinationTypes.map(item => {
            return {
                ...item,
                observation: item.name === examinationTypeName ? observation : item.observation
            }
        })
        setExaminationTypes(result)
    }

    return { examinationTypes, handleChange }
}