import { SlideUp } from 'components/Animations';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/Buttons';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import Table from 'components/Table';
import { therapies_list_header } from 'mocks/patient';
import dayjs from 'dayjs';
import Avatar from 'components/Avatar/Avatar';
import { userInitals } from 'utils/ImagePlaceholder';
import Modal from 'components/Modals/Modal';
import AddTherapy from './AddTheraphy';
import usePatient from 'hooks/fetch/usePatient';
import { Search } from 'components/Inputs';
import { useParams } from 'react-router-dom';
import useTable from 'hooks/useTable';
import useDebounce from 'hooks/useDebounce';
import { useClinicalState } from 'store/modules/clinicals';
import { useAuthState } from 'store/modules/auth';
import ViewTherapy from './ViewTherapy';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { PatientContext } from 'context';

const Therapies = ({ callback, singleVisit, visitStatus, canUpdate = true }) => {
  const { institutionId } = useAuthState();
  const { get_therapies, loading } = usePatient();
  const [type, setType] = useState('');
  const { id } = useParams();
  const pathname = window.location.pathname;
  const page = pathname.includes('clinicals/patient') ? 'patient' : 'visit'
  const { handleSearch, params } = useTable();
  const { debouncedValue } = useDebounce(params.search);
  const { therapies } = useClinicalState();
  const [selectedItem, setSelectedItem] = useState();
  const { patient, progressNote } = useContext(PatientContext)

  const handleSingleView = (item) => {
    setSelectedItem(item);
    setType('view');
  };

  const newParams = {
    type: page,
    [page === 'patient' ? 'patientId' : 'visitId']: patient?.visit ?? id,
    institutionId
  };

  useEffect(() => {
    if(!progressNote) {
      if (debouncedValue) {
        get_therapies({ ...newParams, search: debouncedValue });
      }
      get_therapies(newParams);
    }
  }, [debouncedValue]);

  const handleClose = () => {
    setType('')
  }

  return (
    <>
      <SlideUp>
        <div className='p-0'>
          <div className="flex justify-between items-center mb-6">
            <div className="flex items-center space-x-4">
              <Button
                className="w-fit rounded-full h-8 font-campton_m text-14"
                theme="transparent"
                onClick={callback}
              >
                <p className="flex items-center space-x-4">
                  <ArrowBack fill="#748181" width="7" height="14" className="text-14" />
                  <span>Back</span>
                </p>
              </Button>
              <h2
                className={`text-16 sm:text-18 text-[#0D0F11] font-campton_m`}
              >
                Therapies
              </h2>
            </div>
            {!visitStatus && canUpdate && (pathname.includes('visit') || pathname.includes('progress-note')) && <Button name="New Therapy" theme="blue" onClick={() => setType('create')} className="h-8" />}
          </div>
          {therapies?.therapies?.length > 0 && (
            <div className="w-[400px] mb-6">
              <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
            </div>
          )}
          <Table
            headers={therapies_list_header}
            data={therapies?.therapies}
            hasHeader={therapies?.therapies?.length > 0}
            totalCount={therapies?.total}
            hasPagination={false}
            hasFilter={false}
            loading={loading}
            perPage={1000}
            buttonName="New Procedure"
            currentPage={therapies?.page}
          >
            <>
              {therapies?.therapies?.map((item) => (
                <tr
                  key={item?._id}
                  onClick={() => handleSingleView(item)}
                  data-testid={item?._id}
                  className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                >
                  <td className="px-[10px] py-[8px] capitalize">
                    <p>{item.therapyName}</p>
                  </td>
                  <td className="capitalize px-[10px] py-[8px] text-14">{dayjs(item.therapyDate).format('MMM DD, YYYY')}/{item.startTime}</td>
                  <td className="px-[10px] py-[8px] flex items-center">
                    <Avatar
                      url={item?.createdBy.profilePic}
                      alt={item?.createdBy?.firstName ? item?.createdBy?.firstName?.split('')[0] : item.createdBy?.businessName}
                      width="40px"
                      height="40px"
                      className="bg-brand_secondary mr-[15px] w-[40px] h-[40px]"
                      initials={
                        item.createdBy.firstName
                          ? userInitals(item.createdBy.firstName, item.createdBy.lastName)
                          : userInitals(
                            item?.createdBy?.businessName?.split(' ')[0],
                            item?.createdBy?.businessName?.split(' ')[1],
                          )
                      }
                    />
                    <div>
                      <p className="text-neutral_black text-14 font-campton_m capitalize">
                        {item?.createdBy.firstName
                          ? `${item?.createdBy.firstName + ' ' + item?.createdBy?.lastName}`
                          : `${item?.createdBy?.businessName}`}
                      </p>
                      <p className=" text-12 text-neutral_body font-campton_r">{item?.createdBy?.emailAddress}</p>
                    </div>
                  </td>
                  <td className="capitalize px-[10px] py-[8px] text-14">{dayjs(item.createdAt).format('MMM DD, YYYY')}</td>
                  <td className="px-[10px] py-[8px] capitalize">
                    <ArrowRight fill="#A9B2B2" />
                  </td>
                </tr>
              ))}
            </>
          </Table>
        </div>
      </SlideUp>
      {type &&
        <Modal
          closeModal={() => setType('')}
          styles="w-full md:w-[457px]"
          position="modal-right"
          variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
          title={type === 'view' ? selectedItem?.therapyName : 'New Therapy'}
        >
          {type === 'create' && <AddTherapy callback={handleClose} singleVisit={singleVisit} />}
          {type === 'view' && <ViewTherapy data={selectedItem} callback={() => setType('')} />}
        </Modal>}
    </>
  );
};

export default Therapies;
Therapies.propTypes = {
  callback: PropTypes.any,
  singleVisit: PropTypes.object,
  canUpdate: PropTypes.bool,
  visitStatus: PropTypes.any
};
