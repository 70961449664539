import React, { useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from "prop-types"
import { SlideUp } from 'components/Animations';
import AddVitals from 'components/VitalSigns/AddVitals';
import FormWrapper from 'components/FormWrapper';
import { Button } from 'components/Buttons';
import useProgressNote from 'hooks/fetch/useProgressNote';
import { useSelector } from 'react-redux';
import { VisitDetailsContext } from 'context';
import { removeSpecificKeys } from 'utils/removeSpecificKeys';

export default function UpdateVitals() {
    const { institutionId } = useSelector(state => state.auth)
    const { progressNote, getSingleProgressNoteProxy } = useContext(VisitDetailsContext)
    const { add_progress_note_vitals, loading } = useProgressNote()
    const [formData, setFormData] = useState({
        sysBloodPressure: '', diaBloodPressure: '',
        heartRate: '', respiratoryRate: '',
        temperature: '', oxygenSaturation: '',
        weight: '', height: '',
        bmi: ''
    })

    const handleVitalSigns = (e) => {
        if (isNaN(e.target.value)) return
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (formData.height && formData.weight) {
            setFormData({ ...formData, bmi: (formData.weight / (formData.height ** 2)).toString() })
        } else {
            setFormData({ ...formData, bmi: '' })
        }
    }, [formData.height, formData.weight])

    const disabled = useMemo(() => {
        if (Object.values(formData).every(v => v === '')) return true
        if (!formData.sysBloodPressure && formData?.diaBloodPressure) return true
        if (formData.sysBloodPressure && !formData?.diaBloodPressure) return true
    }, [formData])

    const submit = () => {
        const enhancedFormData = {
            patient: progressNote?.patient?._id || '',
            ...formData,
            institutionId,
            visitId: progressNote?.visit,
            progressNoteId: progressNote?._id,
        }
        removeSpecificKeys(enhancedFormData)
        add_progress_note_vitals(enhancedFormData)
            .then(() => getSingleProgressNoteProxy())
    }

    return (
        <>
            <div className='flex items-center justify-between'>
                <h2 className="text-16 sm:text-18 text-[#0D0F11] font-campton_m">Vital Signs</h2>
            </div>
            <SlideUp>
                <FormWrapper onSubmit={submit}>
                    <AddVitals
                        handleChange={handleVitalSigns}
                        formData={formData}
                        loading={false}
                    />
                    <div className="flex justify-end py-[20px]">
                        <Button
                            name="Save"
                            theme="blue"
                            className="h-[40px] w-[112px] text-16 px-[12px]"
                            disabled={disabled || loading}
                            loading={loading}
                            onClick={submit}
                        />
                    </div>
                </FormWrapper>
            </SlideUp>
        </>
    )
}

UpdateVitals.propTypes = {
    visitStatus: PropTypes.bool
}