export const termsOfUse = [
    {
        name: 'Scope of Use',
        link: 'scopeOfUse'
    },
    {
        name: 'Account Registration',
        link: 'accountRegistration'
    },
    {
        name: 'User Conduct',
        link: 'userConduct'
    },
    {
        name: 'Intellectual Property',
        link: 'intellectualProperty'
    },
    {
        name: 'Disclaimer of Warranties',
        link: 'disclaimer'
    },
    {
        name: 'Limitation of Liability',
        link: 'limitation'
    },
    {
        name: 'Indemnification',
        link: 'indemnification'
    },
    {
        name: 'Termination',
        link: 'termination'
    },
    {
        name: 'Changes to Terms of Use',
        link: 'changes'
    },
    {
        name: 'Governing Law',
        link: 'governingLaw'
    },
    {
        name: 'Entire Agreement',
        link: 'entireAgreement'
    },
    {
        name: 'Waiver',
        link: 'waiver'
    }
]
export const privacyPolicy = [
    {
        name: 'Personal Information We Collect',
        link: 'personalInfo'
    },
    {
        name: 'How We Use Your Personal Information',
        link: 'useOfInfo'
    },
    {
        name: 'Sharing Your Personal Information',
        link: 'sharing'
    },
    {
        name: 'Your Choices',
        link: 'choices'
    },
    {
        name: 'Data Security',
        link: 'dataSecurity'
    },
    {
        name: 'Changes to this Privacy Policy',
        link: 'changes'
    },
    {
        name: 'Contact Us',
        link: 'contactUs'
    },
    {
        name: 'Additional Information about Telehealth Privacy',
        link: 'telehealth'
    },
]