import React, { useEffect, useState } from 'react';
import RadioButtonSelect from 'components/RadioButtonSelect';
import PropTypes from 'prop-types';
import { Input, TextArea } from 'components/Inputs';
import Dropdown from 'components/Dropdown';
import { SlideUp } from 'components/Animations';
import { usePolicyState } from 'store/modules/policies';

const AppointmentPolicy = ({ formData, setFormData, loading, action, type }) => {
  const [hasCharge, setHasCharge] = useState('');
  const [frequency, setFrequency] = useState();
  const [hasTimeframe, setHasTimeframe] = useState('');
  const [noShowPolicy, setNoShowPolicy] = useState('');
  const { currentPolicy } = usePolicyState()

  useEffect(() => {
    if (action === 'edit' && currentPolicy && type === "appointmentPolicy") {
      setFormData({
        ...formData,
        description: currentPolicy.currentAppointmentPolicy.description,
        cancellationFee: currentPolicy.currentAppointmentPolicy.cancellationFee,
        cancellationFeePercentage: currentPolicy.currentAppointmentPolicy.cancellationFeePercentage,
        reschedulingTimeframe: currentPolicy.currentAppointmentPolicy.reschedulingTimeframe,
        reschedulingTimeframeDays: currentPolicy.currentAppointmentPolicy.reschedulingTimeframeDays,
        lateArrivalPolicy: currentPolicy.currentAppointmentPolicy.lateArrivalPolicy,
        noShowPolicy: currentPolicy.currentAppointmentPolicy.noShowPolicy,
      })
      setNoShowPolicy(currentPolicy?.currentAppointmentPolicy?.noShowPolicy)
      setHasTimeframe(currentPolicy?.currentAppointmentPolicy.reschedulingTimeframe)
      setHasCharge(currentPolicy?.currentAppointmentPolicy?.cancellationFee)
    }
  }, [type, action, currentPolicy])

  return (
    <SlideUp className="mt-6">
      <div className="border-y py-6 border-dashed border-neutral_stroke_2 text-12">
        <p className="font-campton_m text-neutral_black">Appointment Cancellation Policy</p>
        <p className="font-campton_r text-neutral_body mt-1 mb-3">
          Do your institution charge a cancellation fee from patients?
        </p>
        <div className="flex">
          <RadioButtonSelect
            label="Yes"
            labelStyles="text-neutral_black text-14"
            variant="mr-6"
            isChecked={formData?.cancellationFee === 'yes'}
            onChange={() => {
              setHasCharge('yes');
              setFormData((formData) => ({
                ...formData,
                cancellationFee: 'yes'
              }));
            }}
          />
          <RadioButtonSelect
            label="No"
            variant="mr-6"
            labelStyles="text-neutral_black text-14"
            isChecked={formData?.cancellationFee === 'no'}
            onChange={() => {
              setHasCharge('no');
              setFormData((formData) => ({
                ...formData,
                cancellationFee: 'no'
              }));
            }}
          />
        </div>
        {hasCharge === 'yes' && (
          <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-5">
            <Input
              label="Cancellation Fee"
              type="number"
              id="cancellationFeePercentage"
              name="cancellationFeePercentage"
              value={formData?.cancellationFeePercentage}
              onChange={(e) => setFormData({ ...formData, cancellationFeePercentage: e.target.value })}
              disabled={loading}
              measurement="% of fee"
            />
          </div>
        )}
      </div>
      <div className="border-b py-6 border-dashed border-neutral_stroke_2 text-12">
        <p className="font-campton_m text-neutral_black">Appointment Rescheduling</p>
        <p className="font-campton_r text-neutral_body mt-1 mb-3">
          Do your institution have a timeframe for rescheduling an appointment?
        </p>
        <div className="flex">
          <RadioButtonSelect
            label="Yes"
            labelStyles="text-neutral_black text-14"
            variant="mr-6"
            isChecked={hasTimeframe === 'yes'}
            onChange={() => {
              setHasTimeframe('yes');
              setFormData((formData) => ({
                ...formData,
                reschedulingTimeframe: 'yes'
              }));
            }}
          />
          <RadioButtonSelect
            label="No"
            variant="mr-6"
            labelStyles="text-neutral_black text-14"
            isChecked={hasTimeframe === 'no'}
            onChange={() => {
              setHasTimeframe('no');
              setFormData((formData) => ({
                ...formData,
                reschedulingTimeframe: 'no'
              }));
            }}
          />
        </div>
        {hasTimeframe === 'yes' && (
          <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-5 relative">
            <div className="">
              <p className="text-neutral_body mb-2 font-campton_r px-1 pt-2 cursor-text bg-neutral_white absolute z-50 left-2 bottom-10 ">
                Reschedule Timeframe
              </p>
              <div className="grid grid-cols-12 border rounded-lg border-neutral_stroke_2 ">
                <Dropdown
                  type="select"
                  id="frequency"
                  name="frequency"
                  options={[
                    {
                      name: 'At least',
                      value: 'At least',
                    },
                  ]}
                  variant="border-0 rounded-none col-span-5 bg-none"
                  selected={frequency}
                  onSelect={(data) => {
                    setFrequency(data.value);
                  }}
                />
                <div className="col-span-7">
                  <Input
                    type="number"
                    variant="!rounded-none !border-0 bg-none"
                    id="time"
                    name="time"
                    value={formData?.reschedulingTimeframeDays}
                    // onChange={(e) => setTimeFrameDays({ ...timeFrameDays, time: e.target.value })}
                    onChange={(e) => setFormData({ ...formData, reschedulingTimeframeDays: e.target.value })}
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="border-b py-6 border-dashed border-neutral_stroke_2 text-12">
        <p className="font-campton_m text-neutral_black">Late Arrival Policy</p>
        <p className="font-campton_r text-neutral_body mt-1 mb-3">
          Guidelines for patients who arrive late for their scheduled appointments.
        </p>
        <TextArea
          label="Late Arrival Policy"
          type="text"
          id="lateArrivalPolicy"
          name="lateArrivalPolicy"
          readOnly={loading}
          value={formData?.lateArrivalPolicy}
          onChange={(e) => setFormData({ ...formData, lateArrivalPolicy: e.target.value })}
        />
      </div>
      <div className="text-12 py-6">
        <p className="font-campton_m text-neutral_black">No Show Policy</p>
        <p className="font-campton_r text-neutral_body mt-1 mb-3">Is the appointment fee refundable?</p>
        <div className="flex">
          <RadioButtonSelect
            label="Yes"
            labelStyles="text-neutral_black text-14"
            variant="mr-6"
            isChecked={noShowPolicy === 'yes'}
            onChange={() => {
              setNoShowPolicy('yes');
              setFormData({ ...formData, noShowPolicy: 'yes' });
            }}
          />
          <RadioButtonSelect
            label="No"
            variant="mr-6"
            labelStyles="text-neutral_black text-14"
            isChecked={noShowPolicy === 'no'}
            onChange={() => {
              setNoShowPolicy('no');
              setFormData({ ...formData, noShowPolicy: 'no' });
            }}
            value={formData?.noShowPolicy}
          />
        </div>
      </div>
    </SlideUp>
  );
};

export default AppointmentPolicy;
AppointmentPolicy.propTypes = {
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  loading: PropTypes.bool,
  type: PropTypes.string,
  action: PropTypes.string
};
