import { useState } from "react"

export default function useTable(limit) {
    const [filterModal, setFilterModal] = useState(false)
    const [params, setParams] = useState({
        page: 1,
        limit: limit ?? 20
    })

    const openFilterModal = () => setFilterModal(true)
    const closeFilterModal = () => setFilterModal(false)
    const changePage = (e) => setParams({ ...params, page: e })
    const selectLimit = (e) => setParams({ ...params, limit: e })
    const handleSearch = (e) => setParams({ ...params, search: e.target.value.trim(), page: 1 })

    return {
        params,
        setParams,
        changePage,
        selectLimit,
        handleSearch,
        filterModal,
        openFilterModal,
        closeFilterModal
    }
}