import React, { useEffect, useMemo, useState } from 'react';
import Navbar from 'components/Navbar';
import Table from 'components/Table';
import { visit_list_header } from 'mocks/clinicals/visits';
import Avatar from 'components/Avatar/Avatar';
import { userInitals } from 'utils/ImagePlaceholder';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import useVisit from 'hooks/fetch/useVisit';
import useTable from 'hooks/useTable';
import useDebounce from 'hooks/useDebounce';
import { useAuthState } from 'store/modules/auth';
import { useClinicalState } from 'store/modules/clinicals';
import dayjs from 'dayjs';
import { SlideUp } from 'components/Animations';
import { useNavigate } from 'react-router-dom';
import VisitAnalytics from './Components/VisitAnalytics';
import Filter from './Components/Filter';
import Restricted from 'HOC/Restricted';
import { formatDate, formatToDateWithDash } from 'utils/formatDate';
import Status from 'components/Status';
import { removeSpecificKeys } from 'utils/removeSpecificKeys';

const Visit = () => {
  const navigate = useNavigate();
  const { get_visits, loading = true } = useVisit();
  const { get_visit_analytics } = useVisit();
  const { params, selectLimit, handleSearch, changePage, openFilterModal, closeFilterModal, filterModal } = useTable();
  const { institutionId, user } = useAuthState();
  const { visits } = useClinicalState();
  const { debouncedValue } = useDebounce(params.search);
  const [chartFilter, setChartFilter] = useState('monthly');
  const [addFilter, setAddFilter] = useState(false)
  const [newParams, setNewParams] = useState({
    startDate: '',
    endDate: '',
    visitType: '',
    status: ''
  });

  useEffect(() => {
    get_visit_analytics({
      filter: chartFilter,
      type: user?.businessName ? 'institution' : 'provider',
      providerId: user?.businessName ? '' : user._id,
      institutionId: institutionId ?? ''
    });
  }, [chartFilter]);

  const filterVisit = () => {
    closeFilterModal()
    get_visits(
      `${institutionId}/visits`,
      {
        ...params,
        ...newParams,
        startDate: newParams.startDate ? dayjs(newParams.startDate).format('YYYY-MM-DD') : '',
        endDate: newParams.endDate ? dayjs(newParams.endDate).format('YYYY-MM-DD') : ''
      });
  }

  useEffect(() => {
    if (!addFilter)
      get_visits(`${institutionId}/visits`, params)
    else
      filterVisit()
  }, [debouncedValue, params.page, params.limit, addFilter]);

  const dataToExport = useMemo(() => {
    return visits?.visits?.map((item) => {
      return {
        patientName: `${item?.patient?.firstName} ${item?.patient?.lastName}`,
        visitReadId: item?.visitReadId,
        visitType: item?.visitType,
        createdAt: formatDate(item?.createdAt),
        provider: item?.provider?.firstName ? `${item?.provider?.firstName} ${item?.provider.lastName}` : `${item?.institution?.businessName}`
      };
    });
  }, [visits]);

  const filters = useMemo(() => {
    const filtered = removeSpecificKeys(newParams) // remove undefined keys
    const enhancedParams = Object.fromEntries(Object.entries(filtered).map(([key, value]) => {
      if (["startDate", "endDate"].includes(key))
        return [key, formatToDateWithDash(value)]
      return [key, value]
    }))
    return addFilter ? Object.entries(enhancedParams) : []
  }, [newParams, addFilter])

  const clearFilters = () => {
    setNewParams({ startDate: '', endDate: '', visitType: '', status: '' })
    setAddFilter(false)
  }

  return (
    <div>
      <Navbar
        title={
          <span className="flex">
            <span className="hidden md:block">Clinical Documentation/</span>Visits
          </span>
        }
      />
      <Restricted module='Visits' permission='View visits'>
        <>
          <SlideUp>
            <VisitAnalytics chartFilter={chartFilter} setChartFilter={setChartFilter} />
            <div className="p-6">
              <Table
                title="Patient Visit List"
                headers={visit_list_header}
                data={dataToExport}
                filterFnc={openFilterModal}
                totalCount={visits?.total}
                handleSearch={handleSearch}
                selectLimit={selectLimit}
                loading={loading}
                filters={filters}
                clearFilters={clearFilters}
                perPage={visits?.limit}
                changePage={changePage}
                currentPage={visits?.page}
                module='Visits'
                permission='Create visit'
                emptyStateTitle="No Visit Available."
                buttonName="Add Visit"
                buttonFnc={() => navigate("/clinicals/visits/new")}
              >
                <>
                  {visits?.visits?.map((item) => (
                    <tr
                      data-testid={item?.visitReadId}
                      onClick={() => navigate(item?.visitType === 'inPatient' ? `${item?._id}?type=progress-note` : item?._id)}
                      key={item?.visitReadId}
                      className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                    >
                      <td className="px-[10px] py-[8px] flex items-center">
                        <Avatar
                          url={item?.patient?.profilePic}
                          width="40px"
                          height="40px"
                          alt={item?.patient?.firstName?.split('')[0]}
                          initials={userInitals(item.patient?.firstName, item.patient?.lastName)}
                          className="bg-brand_secondary mr-[15px] w-[40px] h-[40px]"
                        />
                        <div>
                          <p className="text-neutral_black text-14 font-campton_m capitalize">
                            {item?.patient?.firstName} {item?.patient?.lastName}
                          </p>
                          <p className=" text-neutral_body text-12 font-campton_r">{item?.patient?.emailAddress}</p>
                        </div>
                      </td>
                      <td className="px-[10px] py-[8px] capitalize text-14">{item?.visitReadId}</td>
                      <td className="px-[10px] py-[8px] capitalize text-14">{item?.visitType}</td>
                      <td className="px-[10px] py-[8px] capitalize">{dayjs(item?.createdAt).format('MMM DD, YYYY')}</td>
                      <td className="px-[10px] py-[8px] capitalize">
                        {item?.provider?.firstName ? `${item?.provider?.firstName} ${item?.provider.lastName}` : `${item.institution.businessName}`}
                      </td>
                      <td className="px-[10px] py-[8px] capitalize"><Status status={item?.status} className="text-12 capitalize" /></td>
                      <td className="px-[10px] py-[8px] capitalize w-[20px]">
                        <ArrowRight fill="#A9B2B2" />
                      </td>
                    </tr>
                  ))}
                </>
              </Table>
            </div>
          </SlideUp>
          {filterModal &&
            <Filter
              closeModal={closeFilterModal}
              params={newParams}
              setParams={setNewParams}
              loading={loading}
              func={() => setAddFilter(true)}
            />
          }
        </>
      </Restricted>
    </div>
  );
};

export default Visit;
