import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'components/Buttons';
import PropTypes from 'prop-types';
import FormWrapper from 'components/FormWrapper';
import { Input, TextArea } from 'components/Inputs';
import useService from 'hooks/fetch/useService';
import { useSelector } from 'react-redux';
import { useServiceState } from 'store/modules/services';
import { checkEmptyProperties } from 'utils/checkEmptyProperties';
import { useSearchParams } from 'react-router-dom';
import { service_suggestions } from 'mocks/services';
import Modal from 'components/Modals/Modal';
import { availableTests } from 'constants/testTypes';
import Dropdown from 'components/Dropdown';
import { appointmentTypes } from 'constants/appointment';

export default function NewService({ callback, type, closeModal, data, title, category, services = [] }) {
  const testTypes = availableTests?.map(t => t.name)
  const { create_service, loading, update_service, get_service_details } = useService();
  const { institutionId } = useSelector((state) => state.auth);
  const { selectedCategory } = useServiceState();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    fee: '',
    vat: '',
    subCategory: '',
    categoryId: selectedCategory?._id,
    institutionId,
  });

  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');
  const handleChange = (e) => {
    if (['fee', 'vat'].includes(e.target.name) && isNaN(e.target.value)) return;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCallback = () => {
    if (type === 'Edit' && service) {
      get_service_details(service);
    }
    closeModal();
    callback();
  };

  const createService = () => {
    const enhancedFormData = { ...formData, fee: Number(formData?.fee), vat: Number(formData?.vat) };
    if (type === 'New') {
      create_service(enhancedFormData, handleCallback);
    } else {
      update_service(
        data._id,
        { fee: Number(formData?.fee), vat: Number(formData?.vat), description: formData?.description },
        handleCallback,
      );
    }
  };

  useEffect(() => {
    if (type === 'Edit' && data) {
      setFormData({
        name: data?.name,
        description: data?.serviceProperties?.[0]?.description,
        subCategory: data?.subCategory,
        fee: data?.serviceProperties?.[0]?.fee,
        vat: data?.serviceProperties?.[0]?.vat,
      });
    }
  }, [data, type]);

  const suggestions = useMemo(() => {
    if (formData?.subCategory?.length < 2) return
    const regex = new RegExp(formData?.subCategory, 'i')
    return testTypes?.filter((t) => regex.test(t))
  }, [formData?.subCategory, testTypes])

  return (
    <Modal
      closeModal={closeModal}
      styles='w-[90%] max-w-[535px]'
      title={title}
      variant="py-[14.5px] px-[24px] text-center border-b border-b-neutral_stroke_2 text-16 font-campton_m"
    >
      <FormWrapper className="flex flex-col justify-between h-full" onSubmit={createService}>
        <div>
          <div className="mb-[24px]">
            {category === "appointment" ?
              <>
                {type !== 'Edit' &&
                  <Dropdown
                    type="select"
                    id="sub-category"
                    label='Sub-category'
                    name="sub-category"
                    options={appointmentTypes.filter(item => item.name !== services[0]?.name)}
                    selected={formData?.subCategory}
                    onSelect={(data) => {
                      setFormData({ ...formData, subCategory: data.value, name: data.value });
                    }}
                  />
                }
              </> :
              <Input
                label="Sub-Category"
                type="text"
                id="category"
                name="category"
                value={formData?.subCategory}
                onChange={(e) => {
                  const value = e.target.value
                  setFormData({ ...formData, subCategory: value, name: value })
                }}
              />
            }
            <div className="flex flex-wrap">
              {suggestions?.map(suggestion =>
                <button
                  key={suggestion}
                  type='button'
                  data-testid={suggestion}
                  className="text-12 text-neutral_black font-campton_r
                                    leading-[18px] bg-[#F2F3F3] py-[4px] px-[8px] mr-[8px] w-fit mb-[8px] cursor-pointer"
                  onClick={() => setFormData({ ...formData, subCategory: suggestion, name: suggestion })}
                >
                  {suggestion}
                </button>
              )}
            </div>
          </div>
          <div>
            <Input
              label={type === 'New' ? 'Name' : ''}
              type="text"
              id="name"
              name="name"
              disabled={true}
              readOnly={loading}
              value={formData?.name}
            />
          </div>
          {type !== 'Edit' && !formData?.name && <ul className="flex flex-wrap">
            {service_suggestions[formData?.subCategory]?.map(suggestion =>
              <button
                key={suggestion}
                type='button'
                onClick={() => setFormData({ ...formData, name: suggestion })}
                className="text-12 text-neutral_black font-campton_r
                  leading-[18px] bg-[#F2F3F3] py-[4px] px-[8px] mr-[8px] w-fit mb-[8px] cursor-pointer"
              >
                {suggestion}
              </button>
            )}
          </ul>}
          <TextArea
            label="Description of service"
            id="description"
            name="description"
            variant='mt-6'
            readOnly={loading}
            value={formData.description}
            onChange={handleChange}
            rows={3}
          />
          <section className="grid grid-cols-2 gap-x-3 mb-[18px] mt-4">
            <div className="">
              <Input
                label="Fee"
                type="number"
                id="fee"
                name="fee"
                readOnly={loading}
                measurement="NGN"
                value={formData?.fee}
                onChange={handleChange}
              />
            </div>
            <div className="">
              <Input
                label="VAT"
                type="number"
                id="vat"
                name="vat"
                readOnly={loading}
                value={formData?.vat}
                measurement="NGN"
                onChange={handleChange}
              />
            </div>
          </section>
          <div className="mb-[24px]">
            <Input
              label="Total Amount"
              type="text"
              id="total"
              name="total"
              disabled={true}
              value={Number(formData?.fee) + Number(formData.vat) || ''}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex justify-end py-[20px]">
          <Button
            name={type === 'New' ? 'Create service' : 'Save changes'}
            theme="blue"
            className="h-[40px] text-16 w-[128px]"
            disabled={checkEmptyProperties(formData)}
            loading={loading}
          />
        </div>
      </FormWrapper>
    </Modal>
  );
}

NewService.propTypes = {
  closeModal: PropTypes.func,
  callback: PropTypes.func,
  type: PropTypes.string,
  data: PropTypes.object,
  title: PropTypes.string,
  category: PropTypes.string,
  services: PropTypes.array
};
