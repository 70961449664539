import { useState } from 'react';
import {
  createAccountInstitution,
  createAccountProviders,
  forgotPassword,
  login,
  verifyAccount,
  validateToken,
  changePassword,
  getInstitution,
  completeProviderProfile,
  updateContactPerson,
  updateBasicInfo,
  addLicense,
  createLocation,
  resendOtp,
  editContactPersonInfo,
} from 'store/modules/auth/actions';
import useInputValidate from '../useInputValidate';
import useMakeRequest from 'hooks/useMakeRequest';
import useTryCatch from 'hooks/useTryCatch';
import { extractPIN } from 'utils/extractPin';
import { useAuthState } from 'store/modules/auth';
import { removeSpecificKeys } from 'utils/removeSpecificKeys';
import { editProviderProfile } from 'store/modules/providers/actions';
import { getRoles } from 'store/modules/roles/actions';

export default function useAuth() {
  const { validate } = useInputValidate();
  const { makeRequest } = useMakeRequest();
  const { tryCatch } = useTryCatch();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const { recoveredUser } = useAuthState();

  const login_user = (formData, callback) => {
    return tryCatch(async () => {
      if (!validate(formData)) return; // check if formData is valid
      setLoading(true);
      makeRequest({ action: login(formData), to: `/onboarding`, callback })
        .then((res) => {
          if (res.code === 400 && res.message === 'Account is not verified, please verify your email to proceed') {
            const enhancedFormData = formData;
            removeSpecificKeys(enhancedFormData, ['password']);
            makeRequest({ action: resendOtp(formData), to: `/verify-account` });
          }
          // fetch roles after login
          if (res.code === 200) {
            const id = res?.data?.businessName ? res?.data?._id : res?.data?.institutionId;
            makeRequest({ action: getRoles({ pathname: id }), alert: false });
          }
        })
        .finally(() => setLoading(false));
    });
  };

  const forgot_password = async (formData) => {
    const { emailAddress } = formData;
    tryCatch(async () => {
      // check if email is valid
      if (!validate({ name: 'emailAddress', value: formData.emailAddress })) return;
      setLoading2(true);
      makeRequest({ action: forgotPassword(formData), to: `/verify-account?email=${emailAddress}` }).finally(() =>
        setLoading2(false),
      );
    });
  };

  const resend_otp = async (formData) => {
    tryCatch(async () => {
      // check if email is valid
      if (!validate({ name: 'emailAddress', value: formData.emailAddress })) return;
      makeRequest({ action: resendOtp(formData) });
    });
  };

  const complete_provider_profile = async (formData, callback) => {
    tryCatch(async () => {
      // check if email is valid
      if (!validate(formData)) return;
      setLoading(true);
      makeRequest({ action: completeProviderProfile(formData), callback }).finally(() => setLoading(false));
    });
  };

  const edit_provider_profile = async (formData) => {
    tryCatch(async () => {
      // check if email is valid
      if (!validate(formData)) return;
      removeSpecificKeys(formData, ['fullName', 'emailAddress']);
      setLoading(true);
      makeRequest({ action: editProviderProfile(formData) }).finally(() => setLoading(false));
    });
  };

  const create_account_providers = async (formData, callback) => {
    tryCatch(async () => {
      // check if email is valid
      if (!validate(formData)) return;
      removeSpecificKeys(formData, ['institution']);
      setLoading(true);
      makeRequest({ action: createAccountProviders(formData), to: `/verify-account`, callback }).finally(() =>
        setLoading(false),
      );
    });
  };

  const create_account_institution = async (formData, callback) => {
    tryCatch(async () => {
      // check if email is valid
      if (!validate(formData)) return;
      setLoading(true);
      makeRequest({ action: createAccountInstitution(formData), to: `/verify-account`, callback }).finally(() =>
        setLoading(false),
      );
    });
  };

  const verify_account = async (pin) => {
    tryCatch(async () => {
      const verificationToken = extractPIN(pin);
      setLoading(true);
      return makeRequest({
        action: verifyAccount({ verificationToken }),
      })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const validate_token = async (payload) => {
    tryCatch(async () => {
      const token = extractPIN(payload.token);
      setLoading(true);
      return makeRequest({
        action: validateToken({ emailAddress: payload.email, token }),
        to: '/create-password',
      })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const change_password = async (formData) => {
    tryCatch(() => {
      const enhancedFormData = formData;
      removeSpecificKeys(enhancedFormData, ['confirm_password']);
      if (!validate(formData)) return; // check if formData is valid
      setLoading(true);
      makeRequest({
        action: changePassword({
          ...enhancedFormData,
          emailAddress: recoveredUser?.emailAddress || recoveredUser?.businessEmailAddress,
        }),
        to: '/',
      })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const get_institution = async (params) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getInstitution(params), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const add_contact_info = async (formData, callback) => {
    tryCatch(() => {
      if (!validate(formData)) return; // check if formData is valid
      setLoading(true);
      makeRequest({
        action: updateContactPerson(formData),
        callback,
      })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const edit_contact_info = async (formData, callback) => {
    tryCatch(() => {
      if (!validate(formData)) return; // check if formData is valid
      setLoading(true);
      makeRequest({
        action: editContactPersonInfo(formData),
        callback,
      })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const create_location = async (formData, callback) => {
    tryCatch(() => {
      setLoading(true);
      makeRequest({
        action: createLocation(formData),
        callback,
      })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const update_basic_info = async (formData, callback) => {
    tryCatch(() => {
      setLoading(true);
      makeRequest({
        action: updateBasicInfo(formData),
        callback,
      })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const add_license = async (formData, callback) => {
    tryCatch(() => {
      setLoading(true);
      makeRequest({
        action: addLicense(formData),
        callback,
      })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  return {
    login_user,
    forgot_password,
    add_contact_info,
    verify_account,
    change_password,
    validate_token,
    get_institution,
    add_license,
    create_location,
    edit_provider_profile,
    loading,
    resend_otp,
    loading2,
    update_basic_info,
    create_account_institution,
    create_account_providers,
    edit_contact_info,
    complete_provider_profile,
  };
}
