import React, { useState } from "react";
import PropTypes from 'prop-types';
import Avatar from "components/Avatar/Avatar";
import Modal from "components/Modals/Modal";
import Status from "components/Status";
import { formatDate } from "utils/formatDate";
import { formatTwelveHour } from "utils/formatTime";
import { Button } from "components/Buttons";
import { useSelector } from "react-redux";

export default function ViewSwapRequest({ request, closeModal, updateSwapRequest, loading }) {
    const [selectedAction, setSelectedAction] = useState("")
    const { isInstitution } = useSelector(state => state.auth)
    const { provider, swapWithProvider, status } = request

    return (
        <Modal
            closeModal={closeModal}
            styles="w-full md:w-[457px]"
            position="modal-right"
            variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
            title="Swap Time Slot"
        >
            <div className="relative">
                <div className="modal-right-body">
                    <div className="bg-[#F7FBFD] p-[16px] rounded-[16px] border border-neutral_stroke_2">
                        <p className="text-12 text-neutral_body font-campton_r mb-[16px]">Requester Details</p>
                        <div className="flex mb-[16px]">
                            <Avatar
                                url={provider?.profilePic}
                                width="32px"
                                height="32px"
                                alt="image"
                                initials={`${provider?.firstName?.split('')[0] || ''}${provider?.lastName?.split('')[0] || ''}`}
                                className="w-[32px] h-[32px] shrink-0 mr-[4px]"
                            />
                            <div>
                                <p className="text-14 text-neutral_black font-campton_r">{swapWithProvider?.title} {provider?.firstName} {provider?.lastName}</p>
                                <p className="text-12 text-neutral_body font-campton_r">{provider?.emailAddress}</p>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 mb-[16px]">
                            <div>
                                <p className="text-12 text-neutral_body font-campton_r">Date</p>
                                <p className="text-14 text-neutral_black font-campton_r">{formatDate(request?.startTime)}</p>
                            </div>
                            <div>
                                <p className="text-12 text-neutral_body font-campton_r">Time Duration</p>
                                <p className="text-14 text-neutral_black font-campton_r">
                                    {formatTwelveHour(request?.startTime)} - {formatTwelveHour(request?.endTime)}
                                </p>
                            </div>
                        </div>
                        <div>
                            <p className="text-12 text-neutral_body font-campton_r">Reason for swapping</p>
                            <p className="text-14 text-neutral_black font-campton_r">{request?.reason || "N/A"}</p>
                        </div>
                    </div>
                    <div className="p-[16px] rounded-[16px] border border-neutral_stroke_2 mt-[24px]">
                        <p className="text-12 text-neutral_body font-campton_r mb-[16px]">Swap Details</p>
                        <div className="flex mb-[16px]">
                            <Avatar
                                url={swapWithProvider?.profilePic}
                                width="32px"
                                height="32px"
                                alt={swapWithProvider?.firstName?.split('')[0]}
                                initials={`${swapWithProvider?.firstName?.split('')[0] || ''}${swapWithProvider?.lastName?.split('')[0] || ''}`}
                                className="w-[32px] h-[32px] shrink-0 mr-[4px]"
                            />
                            <div>
                                <p className="text-14 text-neutral_black font-campton_r">{swapWithProvider?.title} {swapWithProvider?.firstName} {swapWithProvider?.lastName}</p>
                                <p className="text-12 text-neutral_body font-campton_r">{swapWithProvider?.emailAddress}</p>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 mb-[16px]">
                            <div>
                                <p className="text-12 text-neutral_body font-campton_r">Date</p>
                                <p className="text-14 text-neutral_black font-campton_r">{formatDate(request?.proposedDate)}</p>
                            </div>
                            <div>
                                <p className="text-12 text-neutral_body font-campton_r">Time Duration</p>
                                <p className="text-14 text-neutral_black font-campton_r">
                                    {formatTwelveHour(request?.proposedStartTime)} - {formatTwelveHour(request?.proposedEndTime)}
                                </p>
                            </div>
                        </div>
                        <div>
                            <p className="text-12 text-neutral_body font-campton_r">Status</p>
                            <Status status={status} />
                        </div>
                    </div>
                </div>
                {status === "pending" && isInstitution &&
                    <div className="modal-right-button-container">
                        <Button
                            name="Decline"
                            theme="transparent"
                            className="w-20 h-10 mr-[16px] text-14 hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary  px-2 py-1"
                            disabled={loading}
                            loading={selectedAction === "decline" && loading}
                            type='button'
                            onClick={() => {
                                setSelectedAction("decline")
                                updateSwapRequest(request?._id, "declined")
                            }}
                        />
                        <Button
                            name="Approve"
                            theme="blue"
                            type="button"
                            disabled={loading}
                            loading={selectedAction === "approve" && loading}
                            className="h-10 text-16 px-[12px] w-[114px]"
                            onClick={() => {
                                setSelectedAction("approve")
                                updateSwapRequest(request?._id, "approved")
                            }}
                        />
                    </div>
                }
            </div>
        </Modal>
    )
}

ViewSwapRequest.propTypes = {
    request: PropTypes.object,
    closeModal: PropTypes.func,
    updateSwapRequest: PropTypes.func,
    loading: PropTypes.bool
};
