import React, { useEffect } from "react";
import { SlideUp } from "components/Animations";
import Table from "components/Table";
import EmptyState from "components/Table/EmptyState";
import Avatar from "components/Avatar/Avatar";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg"
import BreadCrumbs from "components/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import Navbar from "components/Navbar";
import { useSelector } from "react-redux";
import useTable from "hooks/useTable";
import usePatient from "hooks/fetch/usePatient";
import { userInitals } from "utils/ImagePlaceholder";
import { formatDate } from "utils/formatDate";
import { formatTwelveHour } from "utils/formatTime";
import useDebounce from "hooks/useDebounce";
import Restricted from "HOC/Restricted";

export default function HighRisk() {
    const navigate = useNavigate()
    const { get_high_risk_in_patients, loading } = usePatient()
    const { institutionId } = useSelector(state => state.auth)
    const { highRiskInPatient } = useSelector(state => state.patient)
    const { params, changePage, selectLimit, handleSearch } = useTable()
    const { debouncedValue } = useDebounce(params.search)

    const options = [
        {
            title: 'Patient Management',
            method: () => navigate(-1)
        },
        {
            title: 'High Risk Patient'
        }
    ]

    useEffect(() => {
        const newParams = {
            ...params,
            institutionId,
        }
        get_high_risk_in_patients({ params: newParams })
    }, [debouncedValue, params.page, params.limit])

    useEffect(() => {
        document.title = 'High Risk - Gabbi Health'
    }, [])

    return (
        <>
            <Navbar title="Patient Management" />
            <BreadCrumbs options={options} />
            <Restricted module="Inpatient Management" permission="View patient">
                <SlideUp>
                    <div className="p-[24px]">
                        <Table
                            title="High Risk Patient List"
                            headers={['Patient Name', 'Admission Diagnosis', 'Admission Date', 'Location | Room No.', 'Length of Stay']}
                            data={highRiskInPatient?.highRisk}
                            totalCount={highRiskInPatient?.total}
                            perPage={highRiskInPatient?.limit}
                            currentPage={highRiskInPatient?.page}
                            selectLimit={selectLimit}
                            changePage={changePage}
                            loading={loading}
                            handleSearch={handleSearch}
                            emptyState={
                                <EmptyState
                                    title="No Patients"
                                    caption="All high risks patient will be displayed here"
                                />
                            }
                        >
                            <>
                                {highRiskInPatient?.highRisk?.map((item) =>
                                    <tr
                                        data-testid={item?._id}
                                        key={item?._id}
                                        className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer hide_tap"
                                        onClick={() => navigate(`/clinicals/in-patients/${item?._id}`)}
                                    >
                                        <td className="px-[10px] py-[8px] flex items-center capitalize">
                                            <Avatar
                                                url={item?.patientInfo?.profilePic}
                                                width="32px"
                                                height="32px"
                                                alt={item?.patientInfo?.firstName?.split('')[0]}
                                                initials={userInitals(item?.patientInfo?.firstName, item?.patientInfo?.lastName)}
                                                className="mr-[15px] w-[32px] h-[32px]"
                                            />
                                            <div>
                                                <p className="text-14 text-neutral_black font-campton_r">
                                                    {item?.patientInfo?.firstName} {item?.patientInfo?.lastName}
                                                </p>
                                                <p className="text-12 text-neutral_body font-campton_r">
                                                    {item?.patientInfo?.emailAddress} | {item?.patientInfo?.gNumber}
                                                </p>
                                            </div>
                                        </td>
                                        <td title={item?.admissionDiagnosis} className="py-[8px] px-[10px] max-w-[150px] truncate">
                                            {item?.admissionDiagnosis}
                                        </td>
                                        <td className="px-[10px] py-[8px]">{formatDate(item?.createdAt)}, {formatTwelveHour(item?.createdAt)}</td>
                                        <td className="px-[10px] py-[8px] capitalize">{item?.location} | {item?.roomNumber}</td>
                                        <td className="py-[8px] px-[10px]">{item?.lengthOfStay} day(s)</td>
                                        <td className="px-[10px] py-[8px]"><ArrowRight fill="#A9B2B2" /></td>
                                    </tr>
                                )}
                            </>
                        </Table>
                    </div>
                </SlideUp>
            </Restricted>
        </>
    )
}