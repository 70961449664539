import FormWrapper from 'components/FormWrapper';
import React, { useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuthState } from 'store/modules/auth';
import PropTypes from 'prop-types';
import Pin from 'components/Inputs/Pin';
import usePIN from 'hooks/usePIN';
import useResendOtp from 'hooks/useResendOtp';
import Button from 'components/Buttons/Button';
import useExtractPIN from 'hooks/useExtractPIN';
import useAuth from 'hooks/fetch/useAuth';
import Modal from 'components/Modals/Modal';

const VerifyAccountForm = ({timeLeft}) => {

  //Ref
  const inputRef = useRef([]);
  //Hooks
  const { extractPIN } = useExtractPIN();
  const { otp, onChange, onPress, onPaste } = usePIN(6, inputRef);
  const { resetTimer  } = useResendOtp();
  const { verify_account, validate_token, loading, resend_otp, forgot_password } = useAuth();
  const [searchParams] = useSearchParams();
  //Redux Store
  const { user } = useAuthState();
  //Variables
  const email = searchParams.get('email');
  
  //Handler Function
  const disabled = useMemo(() => {
    return extractPIN(otp)?.length < 6 ? true : false;
  }, [otp]);

  const verifyAccount = () => {
    !email ? verify_account(otp) : validate_token({ token: otp, email: email });
  };
  const resentOtp = () => {
    email ?
     forgot_password({ emailAddress: email }, resetTimer)
    :
       resend_otp({ emailAddress: user?.emailAddress ?? user?.businessEmailAddress }, resetTimer)
  };
  return (
    <>
      <FormWrapper
        description={
          <>
            {email && (
              <>
                <span className="text-neutral_body font-campton_r">Almost done! </span>
                <br />
              </>
            )}
            <span className="text-[#0F2E38] font-campton_sb">Just one last step</span>
          </>
        }
        caption={
          <>
            Enter the 6-digit otp sent to{' '}
            <span className="text-brand_secondary font-campton_m">
              {email || user?.emailAddress || user?.businessEmailAddress }
            </span>
          </>
        }
        className="sm:h-full md:mt-12 mt-8 w-full sm:w-[387px] mx-auto"
        onSubmit={verifyAccount}
      >
        <div>
          <Pin
            onChange={onChange}
            onPaste={onPaste}
            onKeyDown={onPress}
            id="otp_input"
            pin={otp}
            inputRef={inputRef}
            className="flex justify-center items-center w-full max-w-md mx-auto mt-10"
          />
          <p className="font-campton_m text-neutral_body mt-6 text-center">
            Didn’t receive code?
            <span
            onClick={resentOtp}
            data-testid='resendOtp'
              className={`${
                timeLeft === 0 ? 'text-brand_secondary cursor-pointer underline' : 'text-neutral_body'
              } ml-0.5`}
            >
              Resend
            </span>
            {timeLeft === 0 ? '' : <span className="text-neutral_body"> in </span>}
            {timeLeft === 0 ? '' : <span className="text-neutral_black">{timeLeft}s</span>}
          </p>
          <Button name="Submit" theme="blue" className="w-full h-12 mt-10" disabled={disabled} />
        </div>
      </FormWrapper>
      {loading && (
        <Modal
          className="flex flex-col justify-center"
          variant="text-neutral_white font-campton_m text-14"
          styles="flex flex-col items-center justify-center bg-[#153E4C] rounded-xl h-[165px] md:p-16 px-8 py-10"
          authModal={true}
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.0"
              width="24"
              height="24"
              viewBox="0 0 128 128"
              className="mx-auto mb-8"
            >
              <g>
                <path
                  d="M75.4 126.63a11.43 11.43 0 01-2.1-22.65 40.9 40.9 0 0030.5-30.6 11.4 11.4 0 1122.27 4.87h.02a63.77 63.77 0 01-47.8 48.05v-.02a11.38 11.38 0 01-2.93.37z"
                  fill="#FFFFFF"
                />
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 64 64"
                  to="360 64 64"
                  dur="1000ms"
                  repeatCount="indefinite"
                />
              </g>
            </svg>
            <p className="text-center py-[14.5px] text-neutral_white font-campton_m">Verifying OTP...</p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default VerifyAccountForm;
VerifyAccountForm.propTypes = {
  userStatus: PropTypes.string,
  timeLeft: PropTypes.number
};
