import React from "react";
import PropTypes from "prop-types"
import Avatar from 'components/Avatar/Avatar';
import { userInitals } from 'utils/ImagePlaceholder';
import dayjs from 'dayjs';
import Layout from "./Layout";
import { useSelector } from "react-redux";

export default function ChiefComplaint({ createdAt, profilePic, id, firstName, lastName, emailAddress, note }) {
    const { institutionId, user } = useSelector(state => state.auth)
    const isInstitution = institutionId === id
    const profile = isInstitution ? user?.logoUrl : profilePic
    const name = !isInstitution ? `${firstName} ${lastName}` : user?.businessName
    const initials = !isInstitution ? userInitals(firstName, lastName) : userInitals(user?.businessName)

    return (
        <Layout createdAt={dayjs(createdAt).format('MMM DD, YYYY, hh:mm A')}>
            <>
                <div className="flex space-x-2">
                    <Avatar
                        url={profile}
                        width="40px"
                        height="40px"
                        alt="profile"
                        initials={initials}
                        className="bg-brand_secondary w-[40px] h-[40px] shrink-0"
                    />
                    <div>
                        <p className="font-campton_m text-neutral_black leading-1 capitalize">{name}</p>
                        <p className="text-14 font-campton_r text-neutral_body leading-none">{emailAddress}</p>
                    </div>
                </div>
                <p className="text-14 mt-2 font-campton_r">{note}</p>
            </>
        </Layout>
    )
}

ChiefComplaint.propTypes = {
    createdAt: PropTypes.string,
    profilePic: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    emailAddress: PropTypes.string,
    note: PropTypes.string,
    id: PropTypes.string
}