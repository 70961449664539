import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Wallet } from 'assets/icons/wallet.svg';
import { ReactComponent as Bank } from 'assets/icons/bank.svg';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { Overview as Card } from 'components/Cards';
import { useAuthState } from 'store/modules/auth';
import { SlideUp } from 'components/Animations';
import { usePaymentState } from 'store/modules/payment';
import { formatCurrency } from 'utils/formatCurrency';
import Restricted from 'HOC/Restricted';

export default function Overview({ callback }) {
  const { user } = useAuthState();
  const [showReason, setShowReason] = useState(false);
  const { walletTransactions } = usePaymentState()

  return (
    <section className="flex border-b border-b-neutral_stroke_2 p-[24px] overflow-x-scroll flex-grow h-full">
      <div className="min-w-[100%] sm:min-w-[395px] sm:mr-[24px] shrink-0 mb-[16px] sm:mb-0">
        <Card
          icon={<Wallet />}
          title="Wallet Balance"
          total={formatCurrency(walletTransactions?.walletBalance || 0)}
          textColor="text-neutral_white"
          className="bg-brand_primary h-full"
        />
      </div>
      <div
        className={`rounded-[16px] flex p-[24px] min-w-[90%] sm:min-w-[338px] lg:unset-min-w md:w-full border border-neutral_stroke_2 bg-primary_tint_50`}
      >
        <Bank />
        <div className={`ml-[12px] text-neutral_black w-full`}>
          <div className="flex items-center mb-[8px] ">
            <p className="text-14 font-campton_r mr-[10px] ">Bank Information</p>
            {!user.bankDetails && <Info onClick={() => setShowReason(!showReason)} className="cursor-pointer" data-testid='reason' />}
            {showReason && (
              <SlideUp>
                <div className="bg-blue py-6 px-4 absolute rounded-xl text-14 text-neutral_white max-w-[342px] top-[7.5rem] right-[18rem]">
                  <p className="font-campton_sb">Why do we need you bank information?</p>
                  <p className="font-campton_r">
                    Your bank information is needed for disbursement of funds from your wallet.
                  </p>
                </div>
              </SlideUp>
            )}
          </div>
          {user.bankDetails ? (
            <div className="sm:flex justify-between items-center">
              <div className="sm:flex items-center">
                <p className="text-18 text-neutral_black font-campton_m mr-[4px] md:first-line:w-44">{user?.bankDetails?.accountName}</p>
                <p className="text-14 text-neutral_black font-campton_r mr-[4px]">
                  <span className="border-x border-x-neutral_stroke_2 px-2.5 mr-2.5">
                    {user?.bankDetails?.bankName}
                  </span>{' '}
                  <span>**** {user?.bankDetails?.accountNumber.slice(-4)}</span>
                </p>
              </div>
              <Restricted module='Bills and Payment' permission='Change wallet bank info'>
                <p onClick={callback} className="text-16 text-brand_secondary font-campton_m cursor-pointer hide_tap">
                  Change
                </p>
              </Restricted>
            </div>
          ) : (
            <Restricted module='Bills and Payment' permission='Add wallet bank info'>
              <p role='button' onClick={callback} className="text-16 text-brand_secondary font-campton_m cursor-pointer w-fit">
                Add Bank Information
              </p>
            </Restricted>
          )}
        </div>
      </div>
    </section>
  );
}

Overview.propTypes = {
  callback: PropTypes.func,
};
