import { Button } from "components/Buttons";
import PropTypes from "prop-types"
import FormWrapper from "components/FormWrapper";
import React, { useState } from "react";
import { DefaultDatepicker } from 'components/Datepicker/Index';
import Modal from "components/Modals/Modal";

export default function Filter({ closeFilterModal, filterParams, setFilterParams, setAddFilter }) {
    const [formData, setFormData] = useState(filterParams)

    const filter = () => {
        setFilterParams(formData)
        setAddFilter()
        closeFilterModal()
    }

    return (
        <Modal
            closeModal={closeFilterModal}
            styles="w-full md:w-[457px]"
            position="modal-right"
            title="Filter"
            variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
        >
            <FormWrapper className="relative">
                <section className="modal-right-body">
                    <p className="text-12 text-neutral_black font-campton_m mb-[10px]">Date Range</p>
                    <div className="grid grid-cols-2 gap-x-[16px]">
                        <div>
                            <DefaultDatepicker
                                label="From"
                                containerVariant="lg:col-span-6 col-span-12"
                                startDate={formData.startDate}
                                maxDate={new Date()}
                                onSelect={(date) => setFormData({ ...formData, startDate: date })}
                                name="startDate"
                            />
                        </div>
                        <div>
                            <DefaultDatepicker
                                label="To"
                                containerVariant="lg:col-span-6 col-span-12"
                                startDate={formData.endDate}
                                maxDate={new Date()}
                                onSelect={(date) => setFormData({ ...formData, endDate: date })}
                                name="endDate"
                            //value={formData?.startDate}
                            />
                        </div>
                    </div>
                </section>
                <div className="modal-right-button-container">
                    <Button
                        name="Cancel"
                        theme="transparent"
                        type="button"
                        onClick={closeFilterModal}
                        className="h-[40px] text-16 w-[79px] mr-[16px]"
                    />
                    <Button
                        name="Filter"
                        theme="blue"
                        onClick={filter}
                        disabled={Object.keys(formData).every(key => filterParams[key] === '')}
                        className="h-[40px] text-16 w-[128px]"
                    />
                </div>
            </FormWrapper>
        </Modal>
    )
}

Filter.propTypes = {
    filterParams: PropTypes.object,
    setFilterParams: PropTypes.func,
    setAddFilter: PropTypes.func,
    closeFilterModal: PropTypes.func
}