import PropTypes from "prop-types"
import React, { useMemo, useState } from "react";
import { Button } from "components/Buttons";
import FormWrapper from "components/FormWrapper";
import { Input, TextArea } from "components/Inputs";
import PermissionList from "components/PermissionList";
import useRoles from "hooks/fetch/useRoles";
import useAssignPermissions from "hooks/useAssignPermissions";
import { useSelector } from "react-redux";
import useTryCatch from "hooks/useTryCatch";
import Modal from "components/Modals/Modal";

export default function CreateAndEditRole({ callback, purpose, role, closeModal }) {
    const { institutionId } = useSelector(state => state.auth)
    const { permissions, handleSelectedPermission, selectAllPermissions, unselectAllPermissions } = useAssignPermissions(role?.permissions)
    const { loading, create_role, edit_role, get_roles } = useRoles()
    const { tryCatch } = useTryCatch()
    const [formData, setFormData] = useState({
        name: role?.name || '',
        description: role?.description || ''
    })

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const submit = () => {
        tryCatch(() => {
            let newPermissions = [...permissions]
            let enhancedPermission = newPermissions?.map((item) => {
                return {
                    module: item.module,
                    action: item.action
                }
            })
            if (purpose === 'edit') {
                edit_role({ ...formData, roleId: role?._id, permissions: enhancedPermission })
                    .then((res) => {
                        if (res?.code === 200) {
                            get_roles({ id: institutionId })
                            callback()
                        }
                    })
                return
            }
            create_role({ ...formData, institutionId, permissions: enhancedPermission })
                .then((res) => {
                    if (res?.code === 201) {
                        get_roles({ id: institutionId })
                        callback()
                    }
                })
        })
    }

    const disabled = useMemo(() => {
        const checkPermissionIsSelected = permissions.map(permission => !permission.action.length)
        return loading || !formData.name || !formData.description || !checkPermissionIsSelected.includes(false)
    }, [loading, formData, permissions])

    return (
        <Modal
            closeModal={closeModal}
            styles="w-full md:w-[457px]"
            position="modal-right"
            title={purpose === 'create' ? 'Create New Role' : 'Edit Role'}
            variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
        >
            <FormWrapper className="relative" onSubmit={submit}>
                <div className="modal-right-body">
                    <div className="mb-[24px] pt-[10px]">
                        <Input
                            label="Role Name"
                            type="text"
                            id="name"
                            name="name"
                            readOnly={loading}
                            value={formData?.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-[26.5px]">
                        <TextArea
                            label="Description"
                            type="text"
                            id="description"
                            name="description"
                            readOnly={loading}
                            value={formData?.description}
                            onChange={handleChange}
                            rows={3}
                            maxLength="250"
                        />
                    </div>
                    <div className="mb-[8px]">
                        <p className="text-14 text-neutral_black font-campton_m mb-[16px]">Assign Permissions</p>
                        <div className="max-w-[730px] overflow-auto mb-[32px]">
                            {permissions.map((item) =>
                                <PermissionList
                                    key={item.module}
                                    item={item}
                                    selectPermission={handleSelectedPermission}
                                    unselectAllPermissions={unselectAllPermissions}
                                    selectAllPermissions={selectAllPermissions}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="modal-right-button-container">
                    <Button
                        name={`${purpose !== 'edit' ? 'Create role' : 'Save'}`}
                        theme="blue"
                        className="h-[40px] text-16 px-[12px] w-[110px]"
                        loading={loading}
                        disabled={disabled}
                    />
                </div>
            </FormWrapper>
        </Modal>
    )
}

CreateAndEditRole.propTypes = {
    callback: PropTypes.func,
    purpose: PropTypes.string,
    role: PropTypes.object,
    closeModal: PropTypes.func
}