import FormWrapper from 'components/FormWrapper';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as AllergiesSolid } from 'assets/icons/allergies-solid.svg';
import useVisit from 'hooks/fetch/useVisit';
import Dropdown from 'components/Dropdown';
import { allergiesTypes } from 'mocks/clinicals/visits';
import { PatientContext } from 'context';

const Allergies = ({ callback }) => {
  const [selectedAllergies, setSelectedAllergies] = useState();
  const { patient, setPatient } = useContext(PatientContext)
  const [allergies, setAllergies] = useState([]);
  const { add_medical_record } = useVisit();
  const [formData, setFormData] = useState({ allergy: '' })

  const addMedicalRecord = () => {
    add_medical_record('allergies', patient?._id, formData)
      .then(res => {
        if ([201, 200]?.includes(res?.code)) {
          setPatient(res?.data)
          setAllergies([...allergies, formData])
          setSelectedAllergies('')
        }
      })
  }

  const disabledBtn = !selectedAllergies

  return (
    <div className="w-full lg:w-[730px] lg:h-[400px]">
      <FormWrapper className="h-full">
        <div className="flex justify-between flex-col h-full">
          <div className="grid gap-6 grid-cols-12 mt-6">
            <Dropdown
              type="select"
              id="allergies"
              placeholder="Select Allergies"
              name="hereditaryDisease"
              variant="lg:col-span-6 col-span-12"
              width="mb-6"
              options={allergiesTypes}
              selected={selectedAllergies}
              onSelect={(data) => {
                setSelectedAllergies(data.name);
                setFormData({ ...formData, allergy: data.value });
              }}
            />
            <p
              className="text-brand_secondary font-campton_m text-14 col cursor-pointer  col-span-12"
              data-testid='test-add'
              onClick={disabledBtn ? null : addMedicalRecord}
              disabled={disabledBtn}
            >
              Add
            </p>
            {allergies.length > 0 && (
              <div className="col-span-12 border-t border-dashed border-neutral_stroke pt-6">
                {allergies?.map((item) => (
                  <div key={item} className="flex space-x-4 mb-6 last-of-type:mb-0">
                    <AllergiesSolid />
                    <p className="capitalize font-campton_m text-neutral_black text-14">{item?.allergy}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <button
              className="w-28 rounded-full h-10 py-0 px-0 bg-brand_primary font-campton_m text-neutral_white"
              onClick={callback}
            >
              Close
            </button>
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default Allergies;
Allergies.propTypes = {
  callback: PropTypes.any
};
