import React from 'react'
import PropTypes from 'prop-types';

const Card = ({ variant, title, children }) => {
  return (
    <div className={`${variant} border-neutral_stroke_2`}>
      <p className='font-campton_r text-neutral_body text-12'>{title}</p>
      <div className='font-campton_r text-14 text-neutral_black mt-1'>
        {children}
      </div>
    </div>
  )
}

export default Card
Card.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
};
