import LoginForm from 'components/AuthForms/LoginForm'
import AuthLayout from 'layouts/AuthLayout'
import React from 'react'

const Login = () => {
  return (
    <AuthLayout
      right={<LoginForm />}
    />
  )
}

export default Login