import React from 'react';
import PropTypes from 'prop-types';
import Avatar from './Avatar/Avatar';
// import { ReactComponent as Humburger } from 'assets/icons/hamburger.svg';
import { Button } from './Buttons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userInitals } from 'utils/ImagePlaceholder';

export default function Navbar({ title, className }) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  return (
    <div
      className={`sticky top-0 z-30 bg-neutral_white flex items-center justify-between px-[16px] sm:px-[24px] py-[18px] border-b border-b-neutral_stroke_2 ${className}`}
    >
      {title ? (
        <div className="flex items-center">
          {/* <Humburger className="sm:hidden mr-[10px]" /> */}
          <h1 className="text-18 font-campton_b text-neutral_black">{title}</h1>
        </div>
      ) : (
        <div>
          <Button theme="transparent" width="w-[82px]" type="button" onClick={() => navigate(-1)}>
            <>
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-[13.5px]"
              >
                <path
                  d="M6.35366 10.6465C6.40011 10.693 6.43696 10.7481 6.4621 10.8088C6.48724 10.8695 6.50018 10.9346 6.50018 11.0003C6.50018 11.066 6.48724 11.131 6.4621 11.1917C6.43696 11.2524 6.40011 11.3076 6.35366 11.354C6.3072 11.4005 6.25205 11.4373 6.19135 11.4625C6.13066 11.4876 6.0656 11.5006 5.99991 11.5006C5.93421 11.5006 5.86915 11.4876 5.80846 11.4625C5.74776 11.4373 5.69261 11.4005 5.64615 11.354L0.646155 6.35403C0.599667 6.30759 0.562787 6.25245 0.537625 6.19175C0.512463 6.13105 0.499512 6.06599 0.499512 6.00028C0.499512 5.93457 0.512463 5.86951 0.537625 5.80881C0.562787 5.74811 0.599667 5.69296 0.646155 5.64653L5.64615 0.646528C5.73997 0.552708 5.86722 0.5 5.99991 0.5C6.13259 0.5 6.25984 0.552708 6.35366 0.646528C6.44748 0.740348 6.50018 0.867596 6.50018 1.00028C6.50018 1.13296 6.44748 1.26021 6.35366 1.35403L1.70678 6.00028L6.35366 10.6465Z"
                  fill="#748181"
                />
              </svg>
              Back
            </>
          </Button>
        </div>
      )}
      <div className="flex">
        {/* <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mr-[20px]"
        >
          <rect width="40" height="40" rx="20" fill="#F7FBFD" />
          <path
            d="M27.3282 23.7453C26.8946 22.9984 26.2501 20.8852 26.2501 18.125C26.2501 16.4674 25.5916 14.8777 24.4195 13.7056C23.2474 12.5335 21.6577 11.875 20.0001 11.875C18.3425 11.875 16.7528 12.5335 15.5807 13.7056C14.4086 14.8777 13.7501 16.4674 13.7501 18.125C13.7501 20.8859 13.1048 22.9984 12.6712 23.7453C12.5604 23.9352 12.5017 24.1509 12.501 24.3707C12.5002 24.5905 12.5574 24.8066 12.6669 24.9973C12.7763 25.1879 12.934 25.3463 13.1242 25.4565C13.3144 25.5667 13.5303 25.6248 13.7501 25.625H16.9384C17.0825 26.3306 17.466 26.9647 18.0239 27.4201C18.5818 27.8756 19.2799 28.1243 20.0001 28.1243C20.7202 28.1243 21.4183 27.8756 21.9762 27.4201C22.5341 26.9647 22.9176 26.3306 23.0618 25.625H26.2501C26.4698 25.6247 26.6856 25.5665 26.8757 25.4562C27.0657 25.346 27.2234 25.1875 27.3327 24.9969C27.442 24.8063 27.4992 24.5903 27.4984 24.3705C27.4976 24.1508 27.4389 23.9351 27.3282 23.7453ZM20.0001 26.875C19.6124 26.8749 19.2344 26.7546 18.9179 26.5308C18.6014 26.3069 18.3621 25.9905 18.2329 25.625H21.7673C21.638 25.9905 21.3987 26.3069 21.0823 26.5308C20.7658 26.7546 20.3877 26.8749 20.0001 26.875ZM13.7501 24.375C14.3516 23.3406 15.0001 20.9438 15.0001 18.125C15.0001 16.7989 15.5269 15.5271 16.4645 14.5895C17.4022 13.6518 18.674 13.125 20.0001 13.125C21.3262 13.125 22.5979 13.6518 23.5356 14.5895C24.4733 15.5271 25.0001 16.7989 25.0001 18.125C25.0001 20.9414 25.6469 23.3383 26.2501 24.375H13.7501Z"
            fill="#131515"
          />
          <circle cx="27" cy="15" r="4" fill="#D66F0F" stroke="white" strokeWidth="2" />
        </svg> */}
        <div className="flex items-center capitalize">
          <div className="mr-[12px] hidden sm:block text-right">
            <p className="text-14 text-neutral_black font-campton_m">
              {user?.firstName || user?.businessName} {user?.lastName || ''}
            </p>
            <p className="text-14 text-neutral_body font-campton_m">{user?.GPINumber}</p>
          </div>
          <Avatar
            url={user?.logoUrl}
            width="40px"
            height="40px"
            alt={user?.firstName?.split('')[0]}
            initials={
              user?.businessName ? user?.businessName?.split('')[0] : userInitals(user?.firstName, user?.lastName)
            }
            className="w-[40px] h-[40px]"
          />
        </div>
      </div>
    </div>
  );
}

Navbar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
};
