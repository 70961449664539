import React, { useEffect, useMemo } from "react";
import { SlideUp } from "components/Animations";
import Table from "components/Table";
import { useSelector } from "react-redux";
import useTable from "hooks/useTable";
import usePatient from "hooks/fetch/usePatient";
import Layout from "../Layout";
import useDebounce from "hooks/useDebounce";
import { formatDate } from "utils/formatDate";
import { formatTwelveHour } from "utils/formatTime";
import Restricted from "HOC/Restricted";
import InPatientTableRow from "../Componets/InpatientTableRow";

export default function DischargedPatients() {
    const { institutionId } = useSelector(state => state.auth)
    const { inPatients } = useSelector(state => state.patient)
    const { params, changePage, selectLimit, handleSearch } = useTable()
    const { loading: loadingPatients, get_in_patients } = usePatient()
    const { debouncedValue } = useDebounce(params.search)

    const getInPatientsProxy = () => {
        const newParams = {
            ...params,
            institutionId,
            admissionStatus: 'discharged'
        }
        get_in_patients({ params: newParams })
    }

    useEffect(() => {
        getInPatientsProxy()
    }, [debouncedValue, params.page, params.limit])

    const dataToExport = useMemo(() => {
        return inPatients?.inPatients?.map((item) => {
            return {
                patientName: `${item?.patientInfo?.firstName} ${item?.patientInfo?.lastName}`,
                admissionDiagnosis: item?.admissionDiagnosis,
                dischargedDate: `${formatDate(item?.updatedAt)} ${formatTwelveHour(item?.updatedAt)}`,
                location: `${item?.location} | ${item?.roomNumber}`,
                lengthOfStay: `${item?.lengthOfStay} day(s)`
            }
        })
    }, [inPatients])

    useEffect(() => {
        document.title = 'Discharged Patient - Gabbi Health'
    }, [])

    return (
        <Layout>
            <Restricted module="Inpatient Management" permission="View patient">
                <SlideUp>
                    <div className="p-[24px]">
                        <Table
                            title="Discharged Patient List"
                            headers={['Patient Name', 'Admission Diagnosis', 'Discharged Date', 'Location | Room No.', 'Length of Stay', 'Status', '']}
                            data={dataToExport}
                            totalCount={inPatients?.total}
                            perPage={inPatients?.limit}
                            currentPage={inPatients?.page}
                            searchPlaceholder="Search by patient name"
                            selectLimit={selectLimit}
                            changePage={changePage}
                            loading={loadingPatients}
                            handleSearch={handleSearch}
                            module="Inpatient management"
                        >
                            <>
                                {inPatients?.inPatients?.map((item) => <InPatientTableRow key={item?._id} item={item} status="discharged"  />)}
                            </>
                        </Table>
                    </div>
                </SlideUp>
            </Restricted>
        </Layout>
    )
}