const base = '/providers';

export const auth = {
  forgot_password: `${base}/forgot-password`,
  login: `${base}/login`,
  signup_provider: `${base}`,
  request_upload_url: `/patient-profile/pre-signed-url`,
  institution: `/institution`,
  verify_account: `${base}/verify-account`,
  resend_otp: `${base}/resend-otp`,
  change_password: `${base}/change-password`,
  validate_token: `${base}/validate-token`,
  refresh_token: `${base}/refresh-token`,
  complete_provider_profile: `${base}/profile`,
};