import React from "react"
import PropTypes from "prop-types"
import { ReactComponent as Calendar } from "assets/icons/calendar-icon.svg"
import SubMenu from "../SubMenu"
import usePermission from "hooks/usePermission"

export default function EShift({ showDrawer, toggleEShift, iconMargin }) {
    const { hasModulePermission } = usePermission()

    const eshift_options = [
        {
            name: "Scheduling",
            value: "scheduling",
            icon: <Calendar fill="white" className={`${iconMargin} w-[15px] shrink-0`} />,
            permission: hasModulePermission('Scheduling')
        },
        // {
        //     name: "Calendar",
        //     value: "calendar",
        //     icon: <Calendar fill="white" className={`${iconMargin} w-[15px]`} />
        // }
    ]

    return (
        <SubMenu
            options={eshift_options}
            condition={toggleEShift}
            type='e-shift'
            showDrawer={showDrawer}
        />
    )
}

EShift.propTypes = {
    showDrawer: PropTypes.bool,
    toggleEShift: PropTypes.bool,
    iconMargin: PropTypes.string
}