import React, { useMemo } from "react";
import PropTypes from "prop-types"
import Navbar from "components/Navbar";
import HorizontalMenu from "components/HorizontalMenu";
import { useLocation, useNavigate } from "react-router-dom";
import usePermission from "hooks/usePermission";

export default function Layout({ children }) {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { hasModulePermission } = usePermission()

    const tabs = [
        {
            id: 1,
            name: 'All Patients',
            method: () => navigate("/clinicals/patients"),
            permission: hasModulePermission('Patient Management')
        },
        {
            id: 2,
            name: 'In-patients',
            method: () => navigate("/clinicals/in-patients"),
            permission: hasModulePermission('Inpatient Management')
        },
        {
            id: 3,
            name: 'Discharged patients',
            method: () => navigate("/clinicals/discharged-patients"),
            permission: hasModulePermission('Inpatient Management')
        }
    ]

    const currentTab = useMemo(() => {
        const tab = pathname.split("/")[2]
        if (tab?.match(/in-patients/i)) return "In-patients"
        if (tab?.match(/discharged-patients/i)) return "Discharged patients"
        return "All Patients"
    }, [pathname])

    const tabsWithPermission = () => {
        return tabs.filter(tab => tab.permission)
    }

    return (
        <div>
            <Navbar title="Patient Management" />
            <HorizontalMenu
                tabs={tabsWithPermission()}
                containerClass="sticky top-[76.5px] sm:top-[79px]"
                currentTab={currentTab}
            />
            {children}
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.element
}