import { removeSpecificKeys } from "./removeSpecificKeys"

const vitalsList = {
    "sysBloodPressure": "Blood pressure",
    "diaBloodPressure": "Blood pressure",
    "heartRate": "Heart rate",
    "respiratoryRate": "Respiratory rate",
    "temperature": "Temperature",
    "oxygenSaturation": "Oxygen saturation",
    "height": "Height",
    "weight": "Weight",
    "bmi": "bmi"
}

export const extractPatientVitals = (item) => {
    const vitals = { ...item }
    const r = removeSpecificKeys(vitals, ['patient', 'recordedBy', '_id', 'createdAt', 'updatedAt', '__v', 'visitId'])
    const removeDuplicate = [...new Set(Object.keys(r)?.map(item => vitalsList[item]))]
    return removeDuplicate
}