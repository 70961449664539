import { createSlice } from '@reduxjs/toolkit';
import { getLocationDetails, getLocations } from './actions';
import { useAppSelector } from 'hooks/useReduxHook';

const initialState = {
  locations: {},
  locationDetails: {},
};

export const locations = createSlice({
  name: 'locations',
  initialState,
  extraReducers: (builder) => {
    builder
      //GET LOCATIONS
      .addCase(getLocations.fulfilled, (state, action) => {
        state.locations = action?.payload?.data;
      })
      //GET A LOCATION DETAILS
      .addCase(getLocationDetails.fulfilled, (state, action) => {
        state.locationDetails = action?.payload?.data;
      })
  },
});
// Selectors
const selectLocations = (state) => state.locations;
//App Redux State
export const useLocationState = () => useAppSelector(selectLocations);

export default locations.reducer;
