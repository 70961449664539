import { createSlice } from '@reduxjs/toolkit';
import {
  getLabTests,
  getMedications,
  getVisits,
  getVitals,
  getProcedure,
  getReferrals,
  getTherapies,
  getVisitAnalytics,
  getProgressNotes,
} from './actions';
import { useAppSelector } from 'hooks/useReduxHook';

const initialState = {
  vitals: {},
  labTest: {},
  visits: {},
  services: [],
  medications: {},
  procedures: {},
  referrals: {},
  therapies: {},
  analytics: [],
  progressNotes: {}
};

export const clinicals = createSlice({
  name: 'clinicals',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getVitals.fulfilled, (state, action) => {
        state.vitals = action?.payload?.data;
      })
      .addCase(getLabTests.fulfilled, (state, action) => {
        state.labTest = action?.payload?.data;
      })
      .addCase(getVisits.fulfilled, (state, action) => {
        state.visits = action?.payload?.data;
      })
      .addCase(getVisitAnalytics.fulfilled, (state, action) => {
        state.analytics = action?.payload?.data;
      })
      .addCase(getMedications.fulfilled, (state, action) => {
        state.medications = action?.payload?.data;
      })
      .addCase(getProcedure.fulfilled, (state, action) => {
        state.procedures = action?.payload?.data;
      })
      .addCase(getReferrals.fulfilled, (state, action) => {
        state.referrals = action?.payload?.data;
      })
      .addCase(getTherapies.fulfilled, (state, action) => {
        state.therapies = action?.payload?.data;
      })
      .addCase(getProgressNotes.fulfilled, (state, action) => {
        state.progressNotes = action?.payload?.data;
      });
  },
});
// Selectors
const selectClinicals = (state) => state.clinicals;
//App Redux State
export const useClinicalState = () => useAppSelector(selectClinicals);
export default clinicals.reducer;
