import React, { useContext } from 'react';
import { ReactComponent as File } from 'assets/svg/file.svg';
import { ReactComponent as Dot } from 'assets/svg/Ellipse 4.svg';
import dayjs from 'dayjs';
import Avatar from 'components/Avatar/Avatar';
import { userInitals } from 'utils/ImagePlaceholder';
import { VisitDetailsContext } from 'context';

export const ViewElectronicSignature = () => {
    const { singleVisit, progressNote } = useContext(VisitDetailsContext)
    const signature = singleVisit?.eSignature || progressNote?.eSignature

    return (
        <div>
            <div className="border border-neutral_stroke_2 mt-6 px-4 py-6 rounded-lg">
                <div className='flex justify-between'>
                    <div className="flex space-x-2 mb-3">
                        <Avatar
                            url={signature?.provider?.profilePic}
                            width="40px"
                            height="40px"
                            alt={signature?.provider?.firstName?.split('')[0]}
                            initials={userInitals(
                                signature?.provider?.firstName,
                                signature?.provider?.lastName,
                            )}
                            className="bg-brand_secondary w-8 h-8"
                        />
                        <div>
                            <p className="font-campton_m text-14 text-neutral_black leading-1 capitalize">
                                Dr {signature?.provider?.firstName} {signature?.provider?.lastName}
                            </p>
                            <p className="text-12 font-campton_r text-neutral_body mt-05 flex items-center space-x-1">
                                {signature?.provider?.emailAddress} <Dot className="ml-1 mb-0.5" />
                                <span className="text-success font-campton_r">{signature?.provider?.GPINumber}</span>
                            </p>
                        </div>
                    </div>
                    <p className='text-12 text-neutral_body font-campton_r'>
                        {dayjs(signature?.createdAt).format('MMM DD, YYYY, hh:mm A')}
                    </p>
                </div>
                <div className="bg-primary_tint_50 py-2 px-4 flex space-x-2.5 rounded-lg">
                    <File />
                    <div className="text-14 font-campton_m overflow-x-hidden">
                        <p className="mb-1">{signature?.signature.split('com/')[1] ?? signature?.signature}</p>
                        <p className="text-neutral_body text-12 flex items-center space-x-1">
                            {/* {file?.size}KB <Dot className="ml-1 mb-0.5" />{' '} */}
                            <span className="text-success font-campton_r">Done</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}