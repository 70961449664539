import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useInputValidate from '../../hooks/useInputValidate';
import { ReactComponent as EyeClosed } from 'assets/icons/eye-closed.svg';
import { ReactComponent as EyeOpen } from 'assets/icons/eye-open.svg';

export default function Input({
  id,
  name,
  placeholder,
  value,
  label,
  onChange,
  type,
  readOnly,
  helperText,
  measurement,
  onBlur,
  maxLength,
  defaultValue,
  max,
  pattern,
  inputMode,
  showError,
  disabled,
  variant,
  onKeyDown
}) {
  const [passwordView, setPasswordView] = useState(false);
  const { error, validate, setError } = useInputValidate(showError);

  const inputError = useMemo(() => {
    return !(showError === false || !error)
  }, [error]);

  const onBlurAction = () => {
    validate({ name, value })
    if(value && onBlur) {
      onBlur()
    }
  }
  
  return (
    <>
      <div className="relative mb-[3.5px] input-container">
        <input
          id={id}
          name={name}
          type={(type === 'password' && (passwordView ? 'text' : 'password')) || type}
          placeholder={placeholder || ''}
          value={value}
          disabled={disabled}
          defaultValue={defaultValue}
          maxLength={maxLength}
          inputMode={inputMode}
          max={max}
          pattern={pattern}
          data-testid={`test-${id}`}
          aria-labelledby={id}
          onChange={onChange}
          readOnly={readOnly}
          autoComplete="off"
          onBlur={onBlurAction}
          //onKeyDown={() => setError('')}
          onKeyDown={onKeyDown}
          className={`${variant} 
            ${inputError ? 'border-error' : 'border-neutral_stroke'} 
            h-[56px] px-4 text-neutral_black text-14 w-full outline-0 border font-campton_r hide_tap
            rounded-lg focus:border-brand_primary focus:border
            ${disabled ? 'bg-neutral_disabled border-neutral_stroke_2' : 'bg-neutral_white'} 
          `}
        />
        {label &&
          <label
            htmlFor={id}
            className={`
            ${disabled ? 'bg-none' : 'bg-neutral_white'}
            text-neutral_body mb-2 font-campton_r px-1 pt-2 cursor-text ${variant}`}
          >
            {label}
          </label>
        }
        {type === 'password' && (
          <div
            onClick={() => setPasswordView(!passwordView)}
            data-testid={!passwordView ? 'show' : 'hide'}
            className="flex items-center absolute top-0 right-[13.48px] cursor-pointer hide_tap h-full"
          >
            {!passwordView ? <EyeClosed /> : <EyeOpen />}
          </div>
        )}
        {measurement && (
          <div className="flex items-center absolute top-0 right-[12px] h-full">
            <div className="text-14 text-neutral_grey font-campton_r">{measurement}</div>
          </div>
        )}
      </div>
      <p className={`font-campton_r ${inputError ? 'text-error' : 'text-neutral_body'} text-12`}>
        {(inputError && error) || helperText}
      </p>
    </>
  );
}

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number,
  max: PropTypes.string,
  pattern: PropTypes.string,
  inputMode: PropTypes.string,
  showError: PropTypes.bool,
  helperText: PropTypes.string,
  measurement: PropTypes.any,
  onKeyDown: PropTypes.func
};
