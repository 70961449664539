import Modal from "components/Modals/Modal"
import React, { useState } from "react"
import PropTypes from "prop-types"
import VisitSummary from "components/Forms/VisitSummary"
import usePatient from "hooks/fetch/usePatient"

export default function DischargePatient({ closeModal, callback, patientId, visitId }) {
    const { discharge_patient, loading } = usePatient()
    const [formData, setFormData] = useState({
        summary: '',
        instructions: ''
    })

    const dischargePatientProxy = () => {
        let payload = formData.instructions ?
            { summary: formData.summary, followUpInstructions: formData.instructions, visit: visitId } :
            { summary: formData.summary, visit: visitId }
        discharge_patient({ patientId, payload })
            .then(res => {
                if (res?.code === 200) {
                    callback()
                    closeModal()
                }
            })
    }

    return (
        <Modal
            closeModal={closeModal}
            styles="w-[90%] md:w-[526px]"
            title="Discharge Patient"
            containerVariant="bg-neutral_white"
        >
            <VisitSummary
                onSubmit={dischargePatientProxy}
                btnName="Discharge patient"
                loading={loading}
                formData={formData}
                setFormData={setFormData}
            />
        </Modal>
    )
}

DischargePatient.propTypes = {
    closeModal: PropTypes.func,
    patientId: PropTypes.string,
    callback: PropTypes.func,
    visitId: PropTypes.string
};