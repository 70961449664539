/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types"

export default function StepProgress({ progress, step }) {
    //const currentStep = progress.find(item => item.step === Number(step))

    return (
        <div className="hidden md:block p-[24px] md:border border-neutral_stroke_2 rounded-[16px] shrink-0 w-full md:w-[290px] mr-[24px] md:h-fit md:absolute">
            {/* <div className="md:hidden">
                <div className="flex mb-[24px]">
                    {progress.map(item =>
                        <div
                            key={item.step}
                            className={`border w-full
                        ${item.step !== progress?.length ? 'mr-[8px]' : ''}
                        ${item?.step <= step ? 'border-success' : 'border-[#748181]'}
                        `}
                        ></div>
                    )}
                </div>
                <div>
                    <p className="text-14 text-neutral_black font-campton_m mb-[2px]">{currentStep?.title}</p>
                    <p className="text-12 text-neutral_body font-campton_r">{currentStep?.caption}</p>
                </div>
            </div> */}
            {progress?.map(item =>
                <div key={item?.step} className={`hidden md:flex ${item?.step !== progress.length ? 'mb-[8px]' : ''} ${item.step > step ? 'opacity-60' : ''}`}>
                    <div className={`flex justify-center items-center w-[20px] h-[20px] ${item?.step <= step ? 'bg-success' : 'bg-[#748181]'} rounded-full shrink-0`}>
                        <p className='text-12 text-neutral_white text-center'>{item?.step}</p>
                    </div>
                    <div className="ml-[16px]">
                        <p className="text-14 text-neutral_black font-campton_m mb-[2px]">{item?.title}</p>
                        <p className={`
                                    text-12 text-neutral_body font-campton_r pb-[16px]
                                    ${item.step !== progress?.length ? 'border-l' : ''}
                                    ${item?.step <= step ? 'border-l-success' : 'border-l-[#748181]'} ml-[-26px] pl-[26px]`}
                        >
                            {item?.caption}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

StepProgress.propTypes = {
    progress: PropTypes.array,
    step: PropTypes.string
}