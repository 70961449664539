import React from 'react';
import { usePaymentState } from 'store/modules/payment';
import { formatCurrency } from 'utils/formatCurrency';

export default function PaymentStatus() {
  const { revenue } = usePaymentState();
  const percentage = revenue?.amountPaidPercentage ?? 0;
  const radius = 20;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  return (
    <div className="w-full xl:basis-[365px] border border-neutral_stroke_2 rounded-[16px] p-[16px] flex flex-col justify-between">
      <p className="text-14 text-neutral_black font-campton_m mb-[16px]">Payment Status</p>
      <div className="flex justify-center mb-[16px] relative">
        <svg width="218" height="203" viewBox={`0 0 ${radius * 2} ${radius * 2}`}>
          <circle cx={radius} cy={radius} r={radius - 2} fill="none" strokeWidth="4" stroke="#F7F8F8" />
          <circle
            cx={radius}
            cy={radius}
            r={radius - 2}
            fill="none"
            strokeWidth="4"
            stroke="#23CE6B"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            transform={`rotate(-90 ${radius} ${radius})`}
            strokeLinecap="round"
          />
        </svg>
        <div className="absolute w-full h-full flex flex-col items-center justify-center">
          <p className="text-16 text-neutral_black font-campton_m leading-[18.62px]">
            {revenue?.amountPaidPercentage?.toFixed(2) || 0}%
          </p>
          <p className="text-12 text-neutral_body font-campton_r">paid</p>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex">
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="#23CE6B" />
          </svg>
          <div className="ml-[8px] mt-[-3px]">
            <p className="text-12 text-neutral_body font-campton_r">Total Amount Paid</p>
            <p className="text-14 text-neutral_black font-campton_r">{formatCurrency(revenue?.totalAmountPaid || 0)}</p>
          </div>
        </div>
        <div className="flex">
          <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4.5" cy="4" r="4" fill="#F2F3F3" />
          </svg>
          <div className="ml-[8px] mt-[-3px]">
            <p className="text-12 text-neutral_body font-campton_r">Outstanding Bills</p>
            <p className="text-14 text-neutral_black font-campton_r">{formatCurrency(revenue?.outstandingBill || 0)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
