export const policy_list_header = ['Policy Type', 'Date Created', 'Last Modified', 'Modified By', ''];
export const policies_list = [
  {
    policy_type: 'Billing Policy',
    date_created: 'Feb 27, 2023',
    last_modified: 'Jun 05, 2023',
    modified_By: 'Gabriel Inyamah',
  },
];
export const policy = {
  policies: {
    _id: '64c1eddd8516c9d717a3f6f1',
    billingPolicy: [
      {
        paymentPlan: {
          fullPayment: false,
          installment: true,
          installmentPercentage: 87,
        },
        paymentMethod: {
          card: false,
          cash: true,
          insurance: false,
        },
        createdBy: {
          providerId: '64a2c1c45859654c64cac646',
          firstName: 'enyata hospital',
          lastName: 'enyata hospital',
          emailAddress: 'osita@enyata.com',
          profilePic: '',
        },
        latePayment: {
          choice: 'yes',
          feePercentage: 1,
        },
        description: 'ppoll',
        daysAfterBilledDate: 6,
        _id: '651fc8abd7f1588a5eab6ce3',
        createdAt: '2023-10-06T08:43:23.213Z',
        updatedAt: '2023-10-06T08:43:23.213Z',
      },
      {
        paymentPlan: {
          fullPayment: false,
          installment: true,
          installmentPercentage: 87,
        },
        paymentMethod: {
          card: false,
          cash: true,
          insurance: false,
        },
        createdBy: {
          providerId: '64a2c1c45859654c64cac646',
          firstName: 'enyata hospital',
          lastName: 'enyata hospital',
          emailAddress: 'osita@enyata.com',
          profilePic: '',
        },
        latePayment: {
          choice: 'yes',
          feePercentage: 1,
        },
        description: 'yajjj',
        daysAfterBilledDate: 6,
        _id: '651fc89dd7f1588a5eab6c87',
        createdAt: '2023-10-06T08:43:09.907Z',
        updatedAt: '2023-10-06T08:43:09.907Z',
      },
    ],
    institutionId: '64a2c1c45859654c64cac646',
    createdAt: '2023-07-27T04:09:02.014Z',
    updatedAt: '2023-10-06T08:43:23.213Z',
    __v: 0,
    appointmentPolicy: [
      {
        createdBy: {
          providerId: '64a2c1c45859654c64cac646',
          firstName: 'enyata hospital',
          lastName: 'enyata hospital',
          emailAddress: 'osita@enyata.com',
          profilePic: '',
        },
        description: 'Nope',
        cancellationFee: 'no',
        cancellationFeePercentage: 0,
        reschedulingTimeframe: 'yes',
        reschedulingTimeframeDays: 4,
        lateArrivalPolicy: 'hjhjhhj',
        noShowPolicy: 'yes',
        _id: '651fc7ddd7f1588a5eab6be4',
        createdAt: '2023-10-06T08:39:57.567Z',
        updatedAt: '2023-10-06T08:39:57.567Z',
      },
      {
        createdBy: {
          providerId: '64a2c1c45859654c64cac646',
          firstName: 'enyata hospital',
          lastName: 'enyata hospital',
          emailAddress: 'osita@enyata.com',
          profilePic: '',
        },
        description: 'jghghhggh',
        cancellationFee: 'no',
        cancellationFeePercentage: 0,
        reschedulingTimeframe: 'yes',
        reschedulingTimeframeDays: 4,
        lateArrivalPolicy: 'hjhjhhj',
        noShowPolicy: 'yes',
        _id: '651fc7ccd7f1588a5eab6b8e',
        createdAt: '2023-10-06T08:39:40.931Z',
        updatedAt: '2023-10-06T08:39:40.931Z',
      },
    ],
  },
  currentPolicy: {
    currentAppointmentPolicy: {
      createdBy: {
        providerId: '64a2c1c45859654c64cac646',
        firstName: 'enyata hospital',
        lastName: 'enyata hospital',
        emailAddress: 'osita@enyata.com',
        profilePic: '',
      },
      description: 'Nope',
      cancellationFee: 'no',
      cancellationFeePercentage: 0,
      reschedulingTimeframe: 'yes',
      reschedulingTimeframeDays: 4,
      lateArrivalPolicy: 'hjhjhhj',
      noShowPolicy: 'yes',
      _id: '651fc7ddd7f1588a5eab6be4',
      createdAt: '2023-10-06T08:39:57.567Z',
      updatedAt: '2023-10-06T08:39:57.567Z',
    },
    currentBillingPolicy: {
      paymentPlan: {
        fullPayment: false,
        installment: true,
        installmentPercentage: 87,
      },
      paymentMethod: {
        card: false,
        cash: true,
        insurance: false,
      },
      createdBy: {
        providerId: '64a2c1c45859654c64cac646',
        firstName: 'enyata hospital',
        lastName: 'enyata hospital',
        emailAddress: 'osita@enyata.com',
        profilePic: '',
      },
      latePayment: {
        choice: 'yes',
        feePercentage: 1,
      },
      description: 'ppoll',
      daysAfterBilledDate: 6,
      _id: '651fc8abd7f1588a5eab6ce3',
      createdAt: '2023-10-06T08:43:23.213Z',
      updatedAt: '2023-10-06T08:43:23.213Z',
    },
  },
};
