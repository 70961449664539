import React from 'react';
import PropTypes from 'prop-types';

export default function Switch({ status, onChange }) {
  return (
    <div className="flex items-center justify-between">
      <div
        data-testid={`${status === 'active' ? 'active-switch' : 'inactive-switch'}`}
        className={`switch cursor-pointer ${status === 'active' ? 'switch_active' : 'switch_inactive'}`}
        onClick={onChange}
      >
        <span className={`slider switchball rounded-[100%] ${status === 'active' ? 'active_slider active_switchball' : 'inactive_slider inactive_switchball'}`}></span>
      </div>
    </div>
  )
}

Switch.propTypes = {
  label: PropTypes.string,
  status: PropTypes.string,
  onChange: PropTypes.func
};