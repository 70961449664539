import { Input, TextArea } from 'components/Inputs';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RadioButtonSelect from 'components/RadioButtonSelect';
import { DefaultDatepicker } from 'components/Datepicker/Index';
import { Button } from 'components/Buttons';
import Spinner from 'components/Spinner';
import { isTimeInThePast } from 'utils/isTimeInRange';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { notify } from 'store/modules/global';

const BasicInformation = ({ formData, patientDetails, setFormData, handleChange, time, setTime, cancel, next, loading }) => {
  const dispatch = useDispatch()
  const handleType = (type, value) => {
    setFormData({ ...formData, [type]: value });
  };
  const patientName = patientDetails?.firstName ? `${patientDetails?.firstName} ${patientDetails?.lastName ?? ''}` : ''

  const checkIfTimeIsInThePast = (date, startT) => {
    const appointmentDate = date?.toISOString()
    const startTime = dayjs(startT).format('hh:mm A')
    const endTime = new Date(startT).setHours(startT.getHours() + 1)
    if (isTimeInThePast(appointmentDate, startTime, dayjs(endTime).format('hh:mm A'))) {
      setTime({ start: '', end: '' })
      dispatch(notify({ display: true, status: 'error', message: 'Please select a future start time.' }))
      return true
    }
    return false
  }

  useEffect(() => {
    if (time?.start) {
      setTime({
        ...time,
        end: new Date(time.start).setHours(time.start.getHours() + 1)
      })
    }
  }, [time?.start])

  useEffect(() => {
    if (formData?.appointmentDate && time?.start) {
      checkIfTimeIsInThePast(formData?.appointmentDate, time?.start)
    }
  }, [formData?.appointmentDate])

  const selectTime = (date) => {
    if (formData?.appointmentDate) {
      if (checkIfTimeIsInThePast(formData?.appointmentDate, date)) return
    }
    setTime({ ...time, start: date })
  }

  return (
    <div className="h-full">
      <h2 className="text-16 text-neutral_black font-campton_m mb-[24px]">Basic Information</h2>
      <div className="flex justify-between flex-col h-[90%]">
        <div>
          <div className="grid grid-cols-2 gap-6">
            <div>
              <Input
                label="G-Number"
                type="text"
                id="gNumber"
                name="patientGNumber"
                measurement={loading ? <Spinner height="h-[10px]" width="w-[10px] mr-4" /> : ''}
                value={formData?.patientGNumber}
                onChange={handleChange}
              />
            </div>
            <div>
              <Input
                label="Patient Name"
                type="text"
                id="fullName"
                name="fullName"
                disabled={true}
                value={patientName}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6 mt-[18px]">
            <div>
              <p className="font-campton_r text-14 text-neutral_black mb-2">Appointment Type</p>
              <div className="grid grid-cols-2 md:gap-4 gap-6">
                <RadioButtonSelect
                  label="In-app"
                  isChecked={formData?.appointmentType === 'inApp'}
                  onChange={() => handleType('appointmentType', 'inApp')}
                />
                <RadioButtonSelect
                  label="On-site"
                  isChecked={formData?.appointmentType === 'onSite'}
                  onChange={() => handleType('appointmentType', 'onSite')}
                />
              </div>
            </div>
            <div>
              <p className="font-campton_r text-14 text-neutral_black mb-2">Priority</p>
              <div className="grid grid-cols-2 md:gap-4 gap-6">
                <RadioButtonSelect
                  label="Urgent"
                  isChecked={formData?.priority === 'urgent'}
                  onChange={() => handleType('priority', 'urgent')}
                />
                <RadioButtonSelect
                  label="Routine"
                  isChecked={formData?.priority === 'routine'}
                  onChange={() => handleType('priority', 'routine')}
                />
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 lg:gap-6 mt-6">
            <DefaultDatepicker
              label="Date"
              minDate={new Date()}
              onSelect={(e) => setFormData({ ...formData, appointmentDate: e })}
              name="date"
              value={formData?.appointmentDate}
              showCalender={true}
            />
            <div className="grid grid-cols-2 md:gap-4 gap-6">
              <DefaultDatepicker
                label="Start Time"
                showTimeSelectOnly={true}
                dateFormat="h:mm aa"
                showTimeSelect={true}
                timeCaption="Select Start time"
                onSelect={selectTime}
                name="startTime"
                value={time?.start}
                showTimeIcon={true}
              />
              <DefaultDatepicker
                label="End Time"
                showTimeSelectOnly={true}
                dateFormat="h:mm aa"
                showTimeSelect={false}
                timeCaption="Select End time"
                onSelect={(date) => setTime({ ...time, end: date })}
                name="endTime"
                value={time?.end}
                showTimeIcon={true}
              />
            </div>
          </div>
          <TextArea
            placeholder="Note (optional)"
            type="text"
            id="reason"
            name="note"
            variant=""
            value={formData?.note}
            onChange={handleChange}
          />
        </div>
        <div className="flex justify-end">
          <Button
            name="Cancel"
            theme="transparent"
            type="button"
            onClick={cancel}
            className="h-[40px] text-16 w-[79px] mr-[16px]"
          />
          <Button
            name="Next"
            theme="blue"
            type="button"
            onClick={next}
            className="h-[40px] text-16 w-[88px]"
            disabled={
              !patientName ||
              !formData?.appointmentType ||
              !formData?.priority ||
              !formData?.patientGNumber ||
              !formData?.appointmentDate ||
              !time?.start ||
              !time?.end
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BasicInformation;
BasicInformation.propTypes = {
  formData: PropTypes.object,
  handleChange: PropTypes.func,
  setFormData: PropTypes.func,
  cancel: PropTypes.func,
  gNumber: PropTypes.string,
  patientDetails: PropTypes.object,
  time: PropTypes.any,
  setTime: PropTypes.any,
  next: PropTypes.func,
  loading: PropTypes.bool,
};
