import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'
import { Button } from "components/Buttons";
import { Input } from "components/Inputs";
import Dropdown from "components/Dropdown";
import usePayments from "hooks/fetch/usePayments";
import { usePaymentState } from "store/modules/payment";
import { useAppDispatch } from "hooks/useReduxHook";
import { getAllBanks } from "store/modules/payment/actions";
import { useSelector } from "react-redux";

export default function AddBankInformation({ callback }) {
    const { loading, add_bank_info } = usePayments()
    const { loading: isfetchingAccountName, resolve_bank_account } = usePayments()
    const { institutionId } = useSelector(state => state.auth)
    const { banks } = usePaymentState()
    const dispatch = useAppDispatch()
    const [formData, setFormData] = useState({
        bankName: '',
        accountName: '',
        accountNumber: '',
        bankCode: '',
    })

    const handleChange = (e) => {
        if (isNaN(e.target.value)) return
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const selectBank = (e) => {
        setFormData({ ...formData, bankName: e.name, bankCode: e.value })
    }

    const addBankInformation = () => {
        add_bank_info({
            ...formData,
            institutionId,
            type: 'institution',
        }, callback)
    }

    useEffect(() => {
        dispatch(getAllBanks())
    }, [])

    useEffect(() => {
        const params = {
            accountNumber: formData.accountNumber,
            bankCode: formData.bankCode
        }
        if (formData?.accountNumber.length === 10 && formData.bankCode) {
            resolve_bank_account(params).then(res => {
                if (res?.code === 200) {
                    setFormData({ ...formData, accountName: res?.data?.account_name })
                    return
                }
                setFormData({ ...formData, accountName: '' })
            })
        }
    }, [formData.bankCode, formData.accountNumber])

    return (
        <div className="flex flex-col justify-between h-full">
            <div>
                <div className="mb-[24px] w-full">
                    <Dropdown
                        label="Bank Name"
                        type="select"
                        id="bankName"
                        name="bankName"
                        position="static"
                        options={banks}
                        selected={formData.bankName}
                        onSelect={selectBank}
                        disabled={!banks.length}
                    />
                </div>
                <div className="mb-[24px]">
                    <Input
                        label="Account Number"
                        type="text"
                        id="accountNumber"
                        name="accountNumber"
                        maxLength={10}
                        inputMode="numeric"
                        // readOnly={loading}
                        value={formData?.accountNumber}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-[24px]">
                    <Input
                        label={formData?.accountName || isfetchingAccountName ? '' : 'Account Name'}
                        type="text"
                        id="accountName"
                        name="accountName"
                        disabled={true}
                        // readOnly={loading}
                        variant='capitalize'
                        value={isfetchingAccountName ? 'Fetching Account Name......' : formData?.accountName}
                    />
                </div>
            </div>
            <div className="flex justify-end py-[20px]">
                <Button
                    name='Save'
                    theme="blue"
                    className='h-[40px] text-16 px-[12px] w-[128px]'
                    onClick={addBankInformation}
                    disabled={!formData.accountName || !formData.accountNumber || !formData.bankName || isfetchingAccountName}
                    loading={loading}
                />
            </div>
        </div>
    )
}

AddBankInformation.propTypes = {
    callback: PropTypes.func
}