import ForgotPasswordForm from 'components/AuthForms/ForgotPasswordForm'
import VerifyAccountForm from 'components/AuthForms/VerifyAccountForm';
import useResendOtp from 'hooks/useResendOtp';
import AuthLayout from 'layouts/AuthLayout'
import React from 'react'
import { useSearchParams } from 'react-router-dom';
import { useAuthState } from 'store/modules/auth';

const ForgotPassword = () => {
  const { recoverUser } = useAuthState()
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get('email')
  const {timeLeft} = useResendOtp()

  return (
        <AuthLayout
        showBackButton={email}
        right={email === recoverUser?.emailAddress ? 
        <VerifyAccountForm timeLeft={timeLeft}/> :
      <ForgotPasswordForm callback={() => setSearchParams({ email: recoverUser.emailAddress })}/> }
      />
  )
}

export default ForgotPassword