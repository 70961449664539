import React, { useEffect } from "react";
import Overview from "./Overview";
import { ReactComponent as Contact } from "assets/icons/contact-bg.svg"
import { ReactComponent as Calendar } from "assets/icons/calender-bg.svg"
import { Link } from "react-router-dom";
import usePatient from "hooks/fetch/usePatient";
import { useSelector } from "react-redux";

export default function HighRiskOverview() {
    const { get_high_risk_in_patients } = usePatient()
    const { institutionId } = useSelector(state => state.auth)
    const { highRiskInPatient } = useSelector(state => state.patient)
    const percentage = highRiskInPatient?.highRiskPercentage || 0
    const radius = 20;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - ((percentage - 4) / 100) * circumference;
    
    useEffect(() => {
        const newParams = {
            //type: userType,
            institutionId,
            //providerId: user?.businessName ? '' : user?._id,
        }
        get_high_risk_in_patients({ params: newParams })
    }, [])

    return (
        <section className="w-full lg:w-[481px] shrink-0 border-r border-r-neutral_stroke_2 p-[24px]">
            <div className="grid sm:grid-cols-2 gap-[16px] mb-[16px]">
                <Overview
                    icon={<Contact className="shrink-0" />}
                    title="Total High Risk"
                    textColor="text-neutral_white"
                    total={highRiskInPatient?.total ?? '...'}
                    className="bg-brand_primary"
                />
                <Overview
                    icon={<Calendar className="shrink-0" />}
                    title="Avg. Length Stay"
                    total={`${highRiskInPatient?.averageLengthOfStay?.toFixed() ?? 0} day(s)`}
                    className="border border-neutral_stroke_2"
                />
            </div>
            <div className="flex justify-between p-[24px] bg-[#FFEDE0] rounded-[8px]">
                <div>
                    <p className="text-16 text-[#522100] font-campton_m mb-[8px]">High Risk Patients</p>
                    <p className="text-12 text-neutral_body font-campton_r mb-[32px]">Keep track of patients with extremely abnormal vitals</p>
                    <Link
                        to="/clinicals/in-patients/high-risk"
                        className="text-14 font-campton_r text-brand_secondary border-b"
                    >
                        View high risk patient
                    </Link>
                </div>
                <div className="flex justify-center w-[116px] h-[116px] shrink-0 relative">
                    <svg
                        width="116"
                        height="116"
                        viewBox={`0 0 ${radius * 2} ${radius * 2}`}
                    >
                        <circle
                            cx={radius}
                            cy={radius}
                            r={radius - 2}
                            fill="none"
                            strokeWidth="4"
                            stroke="#F7F8F8"
                        />
                        <circle
                            cx={radius}
                            cy={radius}
                            r={radius - 2}
                            fill="none"
                            strokeWidth="4"
                            stroke="#FF7A21"
                            strokeDasharray={circumference}
                            strokeDashoffset={offset}
                            transform={`rotate(-90 ${radius} ${radius})`}
                            strokeLinecap="round"
                        />
                    </svg>
                    <div className="absolute z-10 w-full h-full flex flex-col items-center justify-center">
                        <p className="text-16 text-neutral_black font-campton_m leading-[18.62px]">{percentage?.toFixed(1)}%</p>
                        <p className="text-12 text-[#522100] font-campton_r">In-patients</p>
                    </div>
                </div>
            </div>
        </section>
    )
}