import { Button } from 'components/Buttons';
import FormWrapper from 'components/FormWrapper';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/Dropdown';
import { DefaultDatepicker } from 'components/Datepicker/Index';
import { patientFilter } from 'mocks/patient';
import { useAuthState } from 'store/modules/auth';
import Modal from 'components/Modals/Modal';

const Filter = ({ callback, onClick, filterParams, setFilterParams, loading }) => {
  const { user } = useAuthState()

  const locationList = useMemo(() => {
    if (!user?.healthCareFacilities?.length) return []
    return user?.healthCareFacilities?.map((item, index) => {
      let location = `${item?.city}, ${item.state}, ${item.country}`
      return { id: index, value: location, name: location };
    })
  }, [user?.healthCareFacilities])

  return (
    <Modal
      closeModal={callback}
      styles="w-full md:w-[457px]"
      position="modal-right"
      variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
      title='Patient List Filter'
    >
      <FormWrapper className="h-full">
        <div className="relative">
          <div className='modal-right-body'>
            <Dropdown
              type="select"
              id="location"
              label='Location'
              name="location"
              variant="col-span-12"
              options={[...patientFilter, ...locationList]}
              selected={filterParams.location}
              onSelect={(data) => setFilterParams({ ...filterParams, location: data.value })}
            />
            <section className="mt-6">
              <p className="text-12 text-neutral_black font-campton_m mb-3">By Date Range</p>
              <div className="grid grid-cols-2 gap-x-[16px]">
                <div>
                  <DefaultDatepicker
                    label="From"
                    containerVariant="lg:col-span-6 col-span-12"
                    startDate={filterParams.fromDate}
                    maxDate={new Date()}
                    onSelect={(date) => setFilterParams({ ...filterParams, fromDate: date })}
                    name="startDate"
                    showCalender={true}
                  />
                </div>
                <div>
                  <DefaultDatepicker
                    label="To"
                    containerVariant="lg:col-span-6 col-span-12"
                    startDate={filterParams.toDate}
                    minDate={filterParams.fromDate}
                    onSelect={(date) => setFilterParams({ ...filterParams, toDate: date })}
                    name="endDate"
                    showCalender={true}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="modal-right-button-container">
            <Button
              className="w-20 h-10 mr-[16px]
              text-14 hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary  px-2 py-1"
              theme="transparent"
              onClick={callback}
              type='button'
              name="Cancel"
            />
            <Button name="Filter" theme="blue" loading={loading} onClick={onClick} className="w-[88px] h-10" />
          </div>
        </div>
      </FormWrapper>
    </Modal>
  );
};

export default Filter;
Filter.propTypes = {
  callback: PropTypes.func,
  setFilterParams: PropTypes.func,
  filterParams: PropTypes.object,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};
