import React, { useContext } from "react"
import Accordion from "components/Accordion"
import { LabTestDetailsContext } from "context"

export default function AbnormalFindings() {
    const { labTest } = useContext(LabTestDetailsContext)

    return (
        <Accordion
            title="Abnormal findings"
            display={false}
        >
            <div className="pt-[24px]">
                <p className="text-14 text-neutral_body font-campton_r">{labTest?.abnormalFindings}</p>
            </div>
        </Accordion>
    )
}