import React, { useMemo, useState } from "react";
import BillsAndPayments from "../BillsAndPayments";
import Appointments from "../Appointments";
import HorizontalMenu from "components/HorizontalMenu";
import PatientHistory from "components/PatientHistory/PatientHistory";
import VitalSigns from "../VitalSigns";
import usePermission from "hooks/usePermission";
import TreatmentPlan from "pages/Clinicals/Patients/InPatients/SingleInPatient/TreatmentPlan";


export default function Tabs() {
    const [currentTab, setCurrentTab] = useState('Patient History')
    const patientId = 0
    const { hasPermission } = usePermission()

    const tabs = [
        {
            id: 1,
            name: 'Patient History',
            component: <PatientHistory className="p-[24px]" />,
            permission: hasPermission('Patient Management', 'View patient records')
        },
        {
            id: 2,
            name: 'Vital signs',
            component: <VitalSigns patientId={patientId} />,
            permission: hasPermission('Patient Management', 'View patient records')
        },
        {
            id: 3,
            name: 'Appointments',
            component: <Appointments />,
            permission: hasPermission('Appointment', 'View')
        },
        {
            id: 4,
            name: 'Treatment Plan',
            permission: hasPermission("Medications", 'View patient medication'),
            component: <section className="p-6"><TreatmentPlan /></section>,
        },
        {
            id: 5,
            name: 'Bills and Payments',
            component: <BillsAndPayments />,
            permission: hasPermission("Bills and Payment", 'View billing history')
        }
    ]

    const componentToDisplay = useMemo(() => {
        return tabs.find((tab) => tab.name === currentTab)
    }, [currentTab])

    const tabsWithPermission = tabs.filter(tab => tab.permission)

    return (
        <div>
            <HorizontalMenu
                tabs={tabsWithPermission}
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
            />
            {componentToDisplay?.permission && componentToDisplay?.component}
        </div>
    )
}