import React from 'react';
import { ReactComponent as DoubleArrowRight } from 'assets/icons/double-arrow-right.svg';
import PropTypes from 'prop-types';

export default function SubNavbar({ onClick, previous, current, prePrev, prevOnClick }) {
  return (
    <div className="flex sticky z-20 top-[76.5px] sm:top-[79px] items-center px-[24px] py-[8px] border-b border-b-neutral_stroke_2 bg-neutral_white">
      <p
        data-testid="previous"
        className="text-12 text-neutral_body font-campton_m cursor-pointer hide_tap"
        onClick={onClick}
      >
        {previous}
      </p>
      <DoubleArrowRight />
      {prePrev && (
        <>
          <p
            data-testid="prePrev"
            className="text-12 text-neutral_body font-campton_m cursor-pointer hide_tap capitalize"
            onClick={prevOnClick}
          >
            {prePrev}
          </p>
          <DoubleArrowRight />
        </>
      )}
      <p className="text-12 text-orange_400 font-campton_m capitalize">{current}</p>
    </div>
  );
}

SubNavbar.propTypes = {
  onClick: PropTypes.func,
  prevOnClick: PropTypes.func,
  previous: PropTypes.string,
  current: PropTypes.string,
  prePrev: PropTypes.string,
};
