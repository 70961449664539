import { useState } from "react"
import useMakeRequest from "hooks/useMakeRequest"
import useTryCatch from "hooks/useTryCatch"
import {
    changeStaffRole, deleteStaff, getProviderByGpiNUmber, getProviderCalendar,
    getProviders, getProvidersPatient, inviteStaff
} from "store/modules/providers/actions"

export default function useProviders() {
    const { makeRequest, makeAnonymousRequest } = useMakeRequest()
    const [loading, setLoading] = useState(false)
    const { tryCatch } = useTryCatch()

    const get_institution_providers = ({ id, params }) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getProviders({ pathname: id, params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_provider_by_gpiNumber = (id) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getProviderByGpiNUmber({ pathname: `${id}` }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_providers_patient = (id) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getProvidersPatient({ pathname: id }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_staff_without_shift = (url) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'get', alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_staff_with_shift = ({ institutionId, params }) => {
        const url = `providers/${institutionId}/staff-with-shift`
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'get', params, alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_patient_by_gNumber = (id) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getProvidersPatient({ pathname: `gNumber/${id}` }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const invite_staff = (formData) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: inviteStaff({ payload: formData }) })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const delete_staff = (id) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: deleteStaff({ pathname: id }) })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const change_staff_role = (formData) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: changeStaffRole({ payload: formData }) })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const toggle_staff_status = (payload) => {
        const url = `/providers/change-status`
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'patch', payload })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_provider_calendar = (pathname) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getProviderCalendar({ pathname }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    return {
        get_institution_providers,
        get_providers_patient,
        change_staff_role,
        toggle_staff_status,
        delete_staff,
        invite_staff,
        get_patient_by_gNumber,
        get_staff_without_shift,
        get_staff_with_shift,
        get_provider_calendar,
        get_provider_by_gpiNumber,
        loading
    }
}