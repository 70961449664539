import React from "react";
import Navbar from "components/Navbar";
import HorizontalMenu from "components/HorizontalMenu";
import Shift from "./Shift";
import SwapRequests from "./SwapRequests";
import { useSearchParams } from "react-router-dom";

export default function Scheduling() {
    const [searchParams, setSearchParams] = useSearchParams()
    const category = searchParams.get("category") ?? "shift"

    const tabs = [
        {
            id: 0,
            name: 'Shift',
            value: 'shift',
            component: <Shift />
        },
        {
            id: 1,
            name: 'Swap Requests',
            value: 'swap-request',
            component: <SwapRequests />
        }
    ]

    return (
        <div>
            <Navbar title="Shift Management" />
            <HorizontalMenu
                tabs={tabs}
                setCurrentTab={(tab) => setSearchParams({ category: tab })}
                containerClass="sticky z-20 top-[76.5px] sm:top-[79px]"
                currentTab={category}
            />
            {tabs.find((tab) => tab.value === category).component}
        </div>
    )
}