import React from "react";
import PropTypes from "prop-types"
import { Button } from "components/Buttons";
import { ReactComponent as ArrowBack } from "assets/icons/arrow-back.svg";
import { ReactComponent as Remove } from "assets/icons/remove.svg";
import Table from "components/Table";
import Overview from "./Overview";
import Avatar from "components/Avatar/Avatar";
import usePatient from "hooks/fetch/usePatient";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function PreviewInformation({ back, cancel, formData, setFormData, admittedBy, patient }) {
    const navigate = useNavigate()
    const { admit_patient, loading } = usePatient()
    const { institutionId } = useSelector(state => state.auth)

    const admitPatient = () => {
        const payload = {
            ...formData,
            careTeam: [...formData.careTeam.map(item => item?.providerId), formData.admittedBy],
            patient: patient?._id
        }
        admit_patient({ institutionId, payload })
            .then(res => {
                if (res.code === 201) navigate("/clinicals/in-patients")
            })
    }

    return (
        <div className="flex flex-col justify-between h-full">
            <div>
                <div className="flex items-center mb-[24px]">
                    <Button
                        theme="transparent"
                        className="w-[82px] mr-[12px]"
                        type="button"
                        onClick={back}
                    >
                        <>
                            <ArrowBack fill="#748181" className="w-[7px] h-[12px] mr-[13.5px]" />
                            Back
                        </>
                    </Button>
                    <h2 className="text-16 text-neutral_black font-campton_m pl-[12px] border-l border-l-neutral_stroke_2">Preview Information</h2>
                </div>
                <Overview formData={formData} patient={patient} admittedBy={admittedBy} />
                <hr className="my-[24px] border-0 border-b border-dashed border-b-neutral_stroke_2" />
                <div>
                    <Table
                        hasFilter={false}
                        hasPagination={false}
                        tableContainer="min-w-[400px]"
                        headers={['Staff Name', 'Specialty', 'Location', '']}
                        data={formData.careTeam.length ? formData?.careTeam : [admittedBy]}
                    >
                        <>
                            <tr
                                key={admittedBy?._id}
                                className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1"
                            >
                                <td className="px-[10px] py-[8px] flex items-center w-[260px] capitalize">
                                    <Avatar
                                        url={admittedBy?.profilePic}
                                        height="32px"
                                        width="32px"
                                        alt={admittedBy?.firstName?.split('')[0]}
                                        initials={`${admittedBy?.firstName?.split('')[0] || ''}${admittedBy?.lastName?.split('')[0] || ''}`}
                                        className="w-[32px] h-[32px] shrink-0 mx-[8px]"
                                    />
                                    <div>
                                        <p
                                            title={`${admittedBy?.firstName} ${admittedBy?.lastName}`}
                                            className="truncate text-14 text-neutral_black font-campton_r"
                                        >
                                            {admittedBy?.firstName} {admittedBy?.lastName}
                                        </p>
                                        <p className="text-12 text-neutral_body font-campton_r">{admittedBy?.emailAddress}</p>
                                    </div>
                                </td>
                                <td className="py-[8px] px-[10px] capitalize">{admittedBy?.specialty}</td>
                                <td className="px-[10px] py-[8px] capitalize">{admittedBy?.locationName}</td>
                            </tr>
                            {formData?.careTeam?.map((item) =>
                                <tr
                                    data-testid={item?.providerId}
                                    key={item?.providerId}
                                    className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1"
                                >
                                    <td className="px-[10px] py-[8px] flex items-center w-[260px]">
                                        <Avatar
                                            width="32px"
                                            height="32px"
                                            url={item?.profilePic}
                                            alt={item?.firstName?.split('')[0]}
                                            initials={`${item?.firstName?.split('')[0] || ''}${item?.lastName?.split('')[0] || ''}`}
                                            className="w-[32px] h-[32px] shrink-0 mx-[8px]"
                                        />
                                        <div>
                                            <p
                                                title={`${item?.firstName} ${item?.lastName}`}
                                                className="truncate text-14 text-neutral_black font-campton_r capitalize"
                                            >
                                                {item?.firstName} {item?.lastName}
                                            </p>
                                            <p className="text-12 text-neutral_body font-campton_r">{item?.emailAddress}</p>
                                        </div>
                                    </td>
                                    <td className="px-[10px] py-[8px] capitalize">{item?.specialty}</td>
                                    <td className="px-[10px] py-[8px] capitalize">{item?.location}</td>
                                    <td className="px-[10px] py-[8px]">
                                        <Remove
                                            data-testid={`remove-${item?.providerId}`}
                                            onClick={() => {
                                                setFormData({
                                                    ...formData,
                                                    careTeam: formData.careTeam.filter(staff => staff?.providerId !== item?.providerId)
                                                })
                                            }}
                                            className="cursor-pointer"
                                        />
                                    </td>
                                </tr>
                            )}
                        </>
                    </Table>
                </div>
            </div>
            <div className="flex justify-end mt-[24px]">
                <Button
                    name="Cancel"
                    theme="transparent"
                    type="button"
                    onClick={cancel}
                    disabled={loading}
                    className="h-[40px] text-16 w-[79px] mr-[16px]"
                />
                <Button
                    name="Admit Patient"
                    theme="blue"
                    type="button"
                    onClick={admitPatient}
                    loading={loading}
                    // disabled={loading}
                    className="h-[40px] text-16 w-[132px]"
                />
            </div>
        </div>
    )
}

PreviewInformation.propTypes = {
    back: PropTypes.func,
    cancel: PropTypes.func,
    admittedBy: PropTypes.object,
    formData: PropTypes.object,
    setFormData: PropTypes.func,
    patient: PropTypes.object
}