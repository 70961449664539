/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';

export default function Loader() {
    const { display, title, caption } = useSelector(state => state.global.loader)

    return (
        <div className={`flex justify-center items-center w-full h-full z-[500] bg-[#17171761] absolute transition ease-in-out ${!display ? 'scale-0' : 'scale-1'}`}>
            <div className='flex flex-col items-center justify-end bg-[#153E4C] w-[181px] h-[165px] rounded-[16px] p-[40px] text-center'>
                <div className="h-full w-full flex justify-center items-center">
                    <div className="spinner-icon"></div>
                </div>
                <p className='text-14 font-campton_sb text-neutral_white'>{title || 'Loading...'}</p>
                <p>{caption}</p>
            </div>
        </div>
    );
}