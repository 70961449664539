import React from "react";
import PropTypes from "prop-types"
import { Button } from "components/Buttons";
import { ReactComponent as ArrowBack } from "assets/icons/arrow-back.svg";
import { ReactComponent as Remove } from "assets/icons/remove.svg";
import Table from "components/Table";
import Overview from "./Overview";
import Avatar from "components/Avatar/Avatar";
import useShift from "hooks/fetch/useShift";
import { formatToDateWithDash } from "utils/formatDate";
import { useNavigate } from "react-router-dom";
import EmptyState from "components/Table/EmptyState";
import dayjs from "dayjs";

export default function PreviewInformation({ back, cancel, formData, setFormData, workingDays }) {
    const navigate = useNavigate()
    const { create_shift, loading } = useShift()

    const createShift = () => {
        const newFormData = {
            ...formData,
            staffs: formData.staffs.map(item => item?.providerId),
            startTime: dayjs(formData.startTime).format('hh:mm A'),
            endTime: dayjs(formData.endTime).format('hh:mm A'),
            endDate: formatToDateWithDash(formData.endDate),
            startDate: formatToDateWithDash(formData.startDate),
            workingDays
        }

        create_shift(newFormData)
            .then(res => {
                if (res?.code === 201) navigate('/e-shift/scheduling')
            })
    }

    return (
        <div className="flex flex-col justify-between h-full">
            <div>
                <div className="flex items-center mb-[24px]">
                    <Button
                        theme="transparent"
                        className="w-[82px] mr-[12px]"
                        type="button"
                        onClick={back}
                    >
                        <>
                            <ArrowBack fill="#748181" className="w-[7px] h-[12px] mr-[13.5px]" />
                            Back
                        </>
                    </Button>
                    <h2 className="text-16 text-neutral_black font-campton_m pl-[12px] border-l border-l-neutral_stroke_2">Preview Information</h2>
                </div>
                <Overview data={formData} workingDays={workingDays} />
                <hr className="my-[24px] border-0 border-b border-dashed border-b-neutral_stroke_2" />
                <div>
                    <Table
                        hasFilter={false}
                        hasPagination={false}
                        data={formData.staffs}
                        tableContainer="min-w-[400px]"
                        headers={['Staff Name', 'Specialty', 'Location', '']}
                        emptyState={
                            <EmptyState
                                title="No Staff Selected"
                            />
                        }
                        module="Scheduling"
                        permission=""
                    >
                        <>
                            {formData?.staffs?.map((item) =>
                                <tr
                                    data-testid={item?.providerId}
                                    key={item?.providerId}
                                    className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1"
                                >
                                    <td className="px-[10px] py-[8px] flex items-center w-[260px]">
                                        <Avatar
                                            url={item?.profilePic}
                                            width="32px"
                                            height="32px"
                                            alt={item?.firstName?.split('')[0]}
                                            initials={`${item?.firstName?.split('')[0] || ''}${item?.lastName?.split('')[0] || ''}`}
                                            className="w-[32px] h-[32px] shrink-0 mx-[8px]"
                                        />
                                        <div>
                                            <p
                                                title={`${item?.firstName} ${item?.lastName}`}
                                                className="truncate text-14 text-neutral_black font-campton_r capitalize"
                                            >
                                                {item?.firstName} {item?.lastName}
                                            </p>
                                            <p className="text-12 text-neutral_body font-campton_r">{item?.emailAddress}</p>
                                        </div>
                                    </td>
                                    <td title={item?.specialty} className="px-[10px] py-[8px] capitalize whitespace-nowrap truncate max-w-[200px]">{item?.specialty || 'N/A'}</td>
                                    <td title={item?.location} className="px-[10px] py-[8px] capitalize whitespace-nowrap truncate max-w-[200px]">{item?.location || 'N/A'}</td>
                                    <td className="px-[10px] py-[8px]">
                                        <Remove
                                            data-testid={`remove-${item?.providerId}`}
                                            className="cursor-pointer"
                                            onClick={() => setFormData({ ...formData, staffs: formData.staffs.filter(staff => staff?.providerId !== item?.providerId) })}
                                        />
                                    </td>
                                </tr>
                            )}
                        </>
                    </Table>
                </div>
            </div>
            <div className="flex justify-end mt-[24px]">
                <Button
                    name="Cancel"
                    theme="transparent"
                    type="button"
                    onClick={cancel}
                    disabled={loading}
                    className="h-[40px] text-16 w-[79px] mr-[16px]"
                />
                <Button
                    name="Create Shift"
                    theme="blue"
                    type="button"
                    onClick={createShift}
                    loading={loading}
                    className="h-[40px] text-16 w-[120px]"
                />
            </div>
        </div>
    )
}

PreviewInformation.propTypes = {
    back: PropTypes.func,
    cancel: PropTypes.func,
    formData: PropTypes.object,
    setFormData: PropTypes.func,
    workingDays: PropTypes.array,
}