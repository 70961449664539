import { SlideUp } from "components/Animations";
import Navbar from "components/Navbar";
import SubNavbar from "components/SubNavbar";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import BasicInformation from "./BasicInformation";
import useMultiStepForm from "hooks/useMultiStepForm";
import AssignStaff from "./AssignStaff";
import PreviewInformation from "./PreviewInformation";
import { useSelector } from "react-redux";
import StepProgress from "components/StepProgress";
import useWorkingDays from "hooks/useWorkingDays";

export default function NewShift({ startDate, endDate, startTime, endTime }) {
    const navigate = useNavigate()
    const { institutionId } = useSelector(state => state.auth)
    const { workingDays, updateWorkingDays, selectedWorkingDays } = useWorkingDays()
    const [formData, setFormData] = useState({
        institutionId: institutionId,
        shiftName: '',
        note: '',
        startTime: startTime ? new Date(startTime) : '',
        startDate: startDate ? new Date(startDate) : '',
        endDate: endDate ? new Date(endDate) : '',
        endTime: endTime ? new Date(endTime) : '',
        staffs: []
    })

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const updateStaffs = (staffs) => {
        setFormData({ ...formData, staffs })
    }

    const progress = [
        {
            step: 1,
            title: 'Basic Information',
            caption: 'Shift name, time, date, duration and working days'
        },
        {
            step: 2,
            title: 'Assign Staff',
            caption: 'Select staff using roles and assign to the shift.'
        },
        {
            step: 3,
            title: 'Preview Information',
            caption: 'Preview & ensure information provided is correct'
        }
    ]

    const { step, next, back } = useMultiStepForm([
        <BasicInformation
            key={1}
            formData={formData}
            setFormData={setFormData}
            workingDays={workingDays}
            updateWorkingDays={updateWorkingDays}
            handleChange={handleChange}
            cancel={() => navigate(-1)}
            next={() => next()}
        />,
        <AssignStaff
            key={2}
            formData={formData}
            staffs={formData.staffs}
            updateStaffs={updateStaffs}
            cancel={() => navigate(-1)}
            back={() => back()}
            next={() => next()}
        />,
        <PreviewInformation
            key={3}
            back={() => back()}
            formData={formData}
            cancel={() => navigate(-1)}
            workingDays={selectedWorkingDays}
            setFormData={setFormData}
        />
    ])

    return (
        <>
            <Navbar title="Shift Management" />
            <SubNavbar
                previous="Shift Management"
                onClick={() => navigate('/e-shift/scheduling')}
                current="New Shift"
            />
            <SlideUp>
                <div className="md:flex md:p-[24px]">
                    <StepProgress progress={progress} step={step.key} />
                    <div className="p-[24px] md:border border-neutral_stroke_2 rounded-[16px] w-full md:ml-[316px]">
                        {step}
                    </div>
                </div>
            </SlideUp>
        </>
    )
}

NewShift.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string
}