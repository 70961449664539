import React from "react";
import PropTypes from 'prop-types';

export default function Overview({ icon, title, total, className, textColor }) {
    return (
        <div className={`rounded-[16px] flex p-[16px] w-full ${className}`}>
            {icon}
            <div className={`ml-[12px] w-full ${textColor}`}>
                <p className="text-14 font-campton_r">{title}</p>
                <p className="text-16 font-campton_sb leading-[24px]">{total ?? '...'}</p>
            </div>
        </div>
    )
}

Overview.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.string,
    total: PropTypes.any,
    className: PropTypes.string,
    textColor: PropTypes.string
}