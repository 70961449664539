import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as Dot } from 'assets/icons/dot.svg';
import { usePaymentState } from 'store/modules/payment';
import dayjs from 'dayjs';
import { formatCurrency } from 'utils/formatCurrency';

export default function ServiceDetails({totalAmount}) {
  const { billDetails } = usePaymentState();

  return (
    <section className="py-[24px]">
      <p className="text-14 text-neutral_black font-campton_m mb-[8px]">Service Details</p>
      <div className="w-full overflow-x-auto">
        <table className="w-full min-w-[700px]">
          <thead>
            <tr className="text-14 text-left text-neutral_body font-campton_r capitalize border-b border-b-neutral_stroke_1">
              <th className="px-[10px] pb-[18px] whitespace-nowrap w-[60%]">Description</th>
              <th className="px-[10px] pb-[18px] whitespace-nowrap">Date</th>
              <th className="px-[10px] pb-[18px] whitespace-nowrap text-right">Amount (NGN)</th>
            </tr>
          </thead>
          <tbody>
            {billDetails?.billDetails?.map((item) => (
              <ServiceItem
                key={item?._id}
                title={item?.category}
                description={item?.subCategory}
                date={dayjs(billDetails?.createdAt).format('MMM DD, YYYY')}
                amount={formatCurrency(item?.fee + item?.vat)}
              />
            ))}
            <tr className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 bg-neutral_stroke_2">
              <td className="px-[10px] py-[8px] flex items-center capitalize">
                <p className="ml-[8px]">Total Billing Amount</p>
              </td>
              <td className="px-[10px] py-[8px]"></td>
              <td className="px-[10px] py-[8px] text-right">{formatCurrency(totalAmount)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}
ServiceDetails.propTypes = {
  totalAmount: PropTypes?.number
}
const ServiceItem = ({ title, date, amount, description }) => {
  const [display, setDisplay] = useState(false);

  return (
    <>
      <tr
        data-testid={title}
        className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer hide_tap"
        onClick={() => setDisplay(!display)}
      >
        <td className="px-[10px] py-[8px] flex items-center capitalize">
          <div className="w-[13.2px]">{display ? <ArrowDown fill="#748181" /> : <ArrowRight fill="#748181" />}</div>
          <p className="ml-[8px]">{title}</p>
        </td>
        <td className="px-[10px] py-[8px]">{date}</td>
        <td className="px-[10px] py-[8px] text-right">{amount}</td>
      </tr>
      {display && (
        <tr className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 bg-[#F7F8F8] overflow-y-hidden">
          <td className="px-[10px] py-[8px] flex items-center capitalize pl-[40px]">
            <Dot fill="#131515" className="w-[4px]" />
            <p className="ml-[8px]">{description ?? 'N/A'}</p>
          </td>
          <td className="px-[10px] py-[8px]">{date}</td>
          <td className="px-[10px] py-[8px] text-right">{amount}</td>
        </tr>
      )}
    </>
  );
};

ServiceItem.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  amount: PropTypes.string,
  description: PropTypes.string
};
