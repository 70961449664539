import { useContext, useMemo } from 'react';
import { PatientContext } from 'context';


const useLifestyleLogic = () => {
    const { patient } = useContext(PatientContext)
    const habit = patient?.medicalRecord?.lifestyleHabits;

    const NewLifestyleData = habit ? Object.fromEntries(
        Object?.entries(habit).filter(([key]) => key !== 'lifestyleLastModified') 
      ) : {}

    const totalAnsweredCategory = (obj) => {
        let keysWithValuesCount = 0;
    
        // Iterate through each key in the object
        for (const key in obj) {
            // Check if the value is an object (nested object)
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                // If it's an object, recursively check its keys
                keysWithValuesCount += totalAnsweredCategory(obj[key]);
            } else if (obj[key] !== "" && obj[key] !== undefined) {
                    keysWithValuesCount++;
                    break; // Exit the loop if at least one non-empty value is found
            }
        }
    
        return keysWithValuesCount;
    } 

    const totalAnswers = useMemo(() => {
        // Convert the values of the object into an array
        const valuesArray = Object.values(NewLifestyleData);
    
        // Flatten the array (handle nested objects)
        const flattenedArray = valuesArray.flatMap(value => {
            if (typeof value === 'object' && value !== null) {
              return Object.values(value);
            } else {
              return value;
            }
        });
        // Filter out empty and undefined values
        const nonEmptyValues = flattenedArray.filter(value => value !== "" && value !== undefined);
    
        // Return the total count of non-empty values
        return nonEmptyValues.length;
      }, [habit])

      return {totalAnswers, answeredCategory: totalAnsweredCategory(NewLifestyleData)};
}

export default useLifestyleLogic;
