import { useState } from 'react';
import useTryCatch from 'hooks/useTryCatch';
import useMakeRequest from 'hooks/useMakeRequest';
import { ResolveBankAccount, addBankInfo, getBillingHistory, withdrawFunds, getInstitutionRevnue, getWalletTransactions, getPaymentHistory, confirmPayment } from 'store/modules/payment/actions';

export default function usePayments() {
  const [loading, setLoading] = useState(false);
  const { makeRequest } = useMakeRequest();
  const { tryCatch } = useTryCatch();

  const resolve_bank_account = (params) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: ResolveBankAccount({ params }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const add_bank_info = (formData, callback) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: addBankInfo(formData), callback })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const get_billing_history = (params, callback) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getBillingHistory({ params }), alert: false, callback })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const withdraw_funds = (formData, callback) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: withdrawFunds(formData), callback })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const get_revenue = (params) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getInstitutionRevnue({ params }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const get_wallet_transactions = (params) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getWalletTransactions({ params }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  const get_payment_history = (id) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: getPaymentHistory({ pathname: id }), alert: false })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };

  // getPaymentHistory
  const confirm_payment = (formData, callback) => {
    return tryCatch(async () => {
      setLoading(true);
      return makeRequest({ action: confirmPayment(formData), callback })
        .then((res) => res)
        .finally(() => setLoading(false));
    });
  };
  
  return {
    resolve_bank_account,
    loading,
    withdraw_funds,
    get_billing_history,
    get_wallet_transactions,
    get_payment_history,
    get_revenue,
    confirm_payment,
    add_bank_info
  };
}
