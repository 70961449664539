import React, { useMemo, useState } from 'react';
import Modal from 'components/Modals/Modal';
import FormWrapper from 'components/FormWrapper';
import { Button } from 'components/Buttons';
import { checkEmptyProperties } from 'utils/checkEmptyProperties';
import PropTypes from 'prop-types';
import useLocations from 'hooks/fetch/useLocations';
import useTable from 'hooks/useTable';
import Accordion from 'components/Accordion';
import { defaultFilter } from 'mocks/patient';
import { ReactComponent as Uncheck } from 'assets/icons/check-box-unselected.svg';
import { ReactComponent as Check } from 'assets/icons/check-box-selected.svg';
import { useLocationState } from 'store/modules/locations';

const FilterLocations = ({ closeModal }) => {
  const [filterParams, setFilterParams] = useState({
    state: '',
  });

  const { params } = useTable();
  const { get_locations, loading } = useLocations();
  const { locations } = useLocationState();

  const filterLocations = () => {
    closeModal();
    get_locations({
      ...params,
      ...filterParams
    });
  };

  const locationList = useMemo(
    () =>
      locations?.locations?.map((item, index) => {
        return { id: index, value: item.state, name: item.state };
      }),
    [locations],
  );

  return (
    <Modal
      closeModal={closeModal}
      styles="w-full md:w-[457px]"
      position="modal-right"
      title="Filter"
      variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
    >
      <FormWrapper className="relative">
        <div className='modal-right-body'>
          <Accordion
            display={false}
            title="By State"
            className="border-b pb-4 border-neutral_stroke_2 mb-4"
            variant="mt-3"
            titleClassName="text-12"
          >
            <div className="bg-[#F7F8F8] p-4 rounded-lg overflow-scroll">
              {[...defaultFilter, ...locationList]?.map((item) => (
                <div
                  key={item.name}
                  className={`${item.name === 'all' ? 'border-b border-dashed border-neutral_stroke_2 py-2' : 'py-3'
                    } flex`}
                  onClick={() => setFilterParams({ ...params, state: item.value })}
                >
                  <div data-testid={item.name} className="cursor-pointer hide_tap">
                    {filterParams.state === item.value ? <Check /> : <Uncheck />}
                  </div>
                  <p className="text-14 text-neutral_black font-campton_r ml-[8px] capitalize">{item.name}</p>
                </div>
              ))}
            </div>
          </Accordion>
        </div>
        <div className="modal-right-button-container">
          <Button
            name="Cancel"
            theme="transparent"
            type="button"
            onClick={closeModal}
            className="h-[40px] text-16 w-[79px] mr-[16px]"
          />
          <Button
            name="Filter"
            theme="blue"
            loading={loading}
            onClick={filterLocations}
            className="h-[40px] text-16 w-[128px]"
            disabled={checkEmptyProperties(params)}
          />
        </div>
      </FormWrapper>
    </Modal>
  );
};

export default FilterLocations;
FilterLocations.propTypes = {
  closeModal: PropTypes.func,
};
