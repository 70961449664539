/* eslint-disable no-use-before-define */
import Navbar from "components/Navbar"
import React, { useEffect, useMemo, useState } from "react"
import Avatar from "components/Avatar/Avatar"
import { motion, AnimatePresence } from "framer-motion"
import Status from "components/Status"
import { formatDate } from "utils/formatDate"
import { PatientContext } from "context"
import Spinner from "components/Spinner"
import PropTypes from "prop-types"
import { SlideUp } from "components/Animations"
import Tabs from "./Components/Tabs"
import SubNavbar from "components/SubNavbar"
import { useNavigate, useLocation } from "react-router-dom"
import useProviders from "hooks/fetch/useProviders"
import useDeviceWidth from "hooks/useDeviceWidth"
import { userInitals } from "utils/ImagePlaceholder"
import { Button } from "components/Buttons"

export default function PatientDetails() {
    const { isMobile } = useDeviceWidth()
    const location = useLocation()
    const navigate = useNavigate()
    const [viewMore, setViewMore] = useState(false)
    const [patient, setPatient] = useState()
    const { get_providers_patient } = useProviders()
    const patientId = location?.pathname?.split('/')[3]

    useEffect(() => {
        document.title = 'Patient Details - Gabbi Health'
    }, [])

    const getProvidersPatientProxy = () => {
        get_providers_patient(patientId)
            .then((res) => setPatient(res?.data ?? undefined))
    }

    useEffect(() => {
        getProvidersPatientProxy()
    }, [])

    const contextValues = useMemo(() => {
        return { patient, setPatient }
    }, [patient])

    return (
        <div>
            <Navbar title={isMobile ? '' : 'Patient Management'} />
            {!patient ? <Spinner height="h-[80vh]" /> :
                <SlideUp>
                    <SubNavbar
                        previous="Patient Management"
                        onClick={() => navigate('/clinicals/patients')}
                        current={`${patient?.firstName || ''} ${patient?.lastName || ''}`}
                    />
                    <section className="sm:flex px-[24px] py-[16px] border-b border-b-neutral_stroke_2">
                        <div
                            className="flex flex-col items-center min-w-[100%] bg-[url('/src/assets/svg/frame.svg')] bg-no-repeat bg-[center_top_-2rem]
                        sm:min-w-[251px] mr-[16px] p-[24px] bg-brand_primary rounded-[16px]"
                        >
                            <Avatar
                                url={patient?.profilePic}
                                width="80px"
                                height="80px"
                                canViewProfile={true}
                                alt={patient?.firstName?.split('')[0]}
                                initials={userInitals(patient?.firstName, patient?.lastName)}
                                className="bg-brand_secondary w-[80px] h-[80px] mb-[12px] !text-[24px] cursor-pointer"
                            />
                            <div className="text-neutral_white text-center font-campton_r mb-[16px]">
                                <p className="text-16 mb-[4px] font-campton_m capitalize">
                                    {patient?.firstName} {patient?.lastName} <span className="text-14 font-campton_r">{patient?.gender.charAt(0) || ''}</span>
                                </p>
                                <p className="text-14 mb-[4px]">{patient?.gNumber}</p>
                                <p className="text-14 mb-[4px]">{patient?.emailAddress}</p>
                                <p className="text-14">{patient?.countryCode}{patient?.phoneNumber}</p>
                            </div>
                            <Button
                                name='Admit Patient'
                                className="h-8 text-14 px-3 bg-neutral_white text-neutral_body border-none"
                                onClick={() => navigate(`/clinicals/in-patients/admit-patient?gNumber=${patient.gNumber}`)}
                            />
                            <AnimatePresence>
                                {viewMore ?
                                    <motion.div
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: 'auto', transition: { type: 'spring', duration: .5 } }}
                                        exit={{ opacity: 0, height: 0 }}
                                        className="w-full sm:h-[214px] mt-[20px] sm:hidden"
                                    >
                                        <MList
                                            name="Date of Birth"
                                            value={formatDate(patient?.dateOfBirth, 'number')}
                                        />
                                        <MList
                                            name="Marital Status"
                                            value={patient?.maritalStatus}
                                        />
                                        <MList
                                            name="Race/Ethnicity"
                                            value={patient?.race}
                                        />
                                        <MList
                                            name="Home Address"
                                            value={patient?.homeAddress}
                                        />
                                        <MList
                                            name="Office Address"
                                            value={patient?.officeAddress}
                                        />
                                        <MList
                                            name="Status"
                                            status={patient?.patientStatus}
                                        />
                                    </motion.div> : ''
                                }
                            </AnimatePresence>
                            <p
                                className="text-14 text-neutral_white sm:hidden mt-[20px]"
                                onClick={() => setViewMore(!viewMore)}
                            >
                                View {viewMore ? 'less' : 'more'}
                            </p>
                        </div>
                        <div className="w-full bg-primary_tint_50 border border-[#EDF2F7] p-[24px] hidden sm:grid grid-cols-2 gap-[16px] rounded-[16px]">
                            <List
                                name="Date of Birth"
                                value={formatDate(patient?.dateOfBirth, 'number')}
                            />
                            <List
                                name="Marital Status"
                                value={patient?.maritalStatus}
                            />
                            <List
                                name="Race/Ethnicity"
                                value={patient?.race}
                            />
                            <List
                                name="Status"
                                status={patient?.patientStatus}
                            />
                            <List
                                name="Home Address"
                                value={patient?.homeAddress}
                            />
                            <List
                                name="Office Address"
                                value={patient?.officeAddress}
                            />
                        </div>
                    </section>
                    <PatientContext.Provider value={contextValues}>
                        <Tabs />
                    </PatientContext.Provider>
                </SlideUp>
            }
        </div>
    )
}

const List = ({ name, value, status }) => {
    return (
        <div>
            <p className="text-14 text-[#83889D] font-campton_r mb-[4px]">{name}</p>
            {!status && <p className="text-16 text-[#364469] font-campton_r">{value || 'N/A'}</p>}
            {status && <Status status={status} />}
        </div>
    )
}
const MList = ({ name, value, status }) => {
    return (
        <div className="text-neutral_white mb-[16px]">
            <p className="text-14 font-campton_m mb-[4px]">{name}</p>
            {!status && <p className="text-16 font-campton_r">{value || 'N/A'}</p>}
            {status && <Status status={status} />}
        </div>
    )
}

List.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    status: PropTypes.string
}
MList.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    status: PropTypes.string
}