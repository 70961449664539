export const lab_test_list_header = ['Test Type', 'Requested By', 'Patient Name', 'Date Requested', 'status', '']

export const lab_test_list_data = [
    {
        _id: '1',
        type: 'Complete blood test, Blood enzyme test',
        requestedBy: 'Dr. John Alao',
        patientName: 'Gabriel Inyamah',
        dateRequested: 'May 27, 2020',
        status: 'requested'
    },
    {
        _id: '2',
        type: 'Complete blood test, Blood enzyme test',
        requestedBy: 'Dr. Success Chinedu',
        patientName: 'Gabriel Inyamah',
        dateRequested: 'May 27, 2020',
        status: 'in-progress'
    },
    {
        _id: '3',
        type: 'Complete blood test, Blood enzyme test',
        requestedBy: 'Dr. Barakat Salam',
        patientName: 'Gabriel Inyamah',
        dateRequested: 'May 27, 2020',
        status: 'completed'
    }
]