import { useState } from "react"
import useMakeRequest from "hooks/useMakeRequest"
import useTryCatch from "hooks/useTryCatch"
import { getLocationDetails, getLocations, updateLocation } from "store/modules/locations/actions"


export default function useLocations() {
    const { makeRequest } = useMakeRequest()
    const [loading, setLoading] = useState(false)
    const { tryCatch } = useTryCatch()

    const get_locations = (params) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getLocations({ params }), alert: false })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    }
    const get_location_details = (id, callback, params) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getLocationDetails({ pathname: id, params }), alert: false, callback })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    }

    const edit_location = (id, formData, callback) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: updateLocation({pathname: id, payload: formData }), callback })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    return {
        get_locations,
        get_location_details,
        edit_location,
        loading
    }
}