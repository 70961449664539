import React, { useEffect } from 'react';
import { router } from 'router';
import { RouterProvider } from 'react-router-dom';
import Notification from 'components/Notifications';
import { useDispatch } from 'react-redux';
import { showLoader, toggleDrawer } from 'store/modules/global';
import Loader from 'components/Modals/Loader';
import useWindowWidth from 'hooks/useWindowWidth';
import { MEDIUM_WIDTH } from 'constants';

function App() {
  const dispatch = useDispatch()
  const windowWidth = useWindowWidth()

  useEffect(() => {
    dispatch(showLoader({ display: false, title: '' }))
    if (windowWidth < MEDIUM_WIDTH) dispatch(toggleDrawer(false))
  }, [])

  return (
    <div className="relative h-screen">
      <Loader />
      <Notification />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
