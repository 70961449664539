import { SlideUp } from 'components/Animations';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import Navbar from 'components/Navbar';
import Table from 'components/Table';
import useTable from 'hooks/useTable';
import { specialty_list_header } from 'mocks/settings/specialty';
import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as SpecialtyIcon } from 'assets/icons/specialty.svg';
import AddSpecialty from 'pages/Onboarding/Components/Forms/AddSpecialty';
import { useNavigate } from 'react-router-dom';
import useSpecialty from 'hooks/fetch/useSpecialty';
import useDebounce from 'hooks/useDebounce';
import { useSpecialtyState } from 'store/modules/specialty';
import dayjs from 'dayjs';
import { formatDate } from 'utils/formatDate';
import SpecialtyFilter from './Components/SpecialtyFilter';
import Restricted from 'HOC/Restricted';

const Specialty = () => {
  const { selectLimit, openFilterModal, closeFilterModal, filterModal, handleSearch, changePage, params } = useTable();
  const { debouncedValue } = useDebounce(params.search);
  const [createSpecialty, setCreateSpecialty] = useState(false);
  const navigate = useNavigate();
  const { get_Specialties, loading } = useSpecialty();
  const { specialties } = useSpecialtyState();
  const [newParams, setNewParams] = useState({
    fromDate: '',
    toDate: '',
  });

  const callback = () => {
    setCreateSpecialty(false);
    get_Specialties(params);
  };

  useEffect(() => {
    get_Specialties(params);
  }, [debouncedValue, params.page, params.limit]);

  const dataToExport = useMemo(() => {
    return specialties?.specialties?.map((item) => {
      return {
        patientName: item.name,
        createdAt: formatDate(item?.createdAt),
        time: dayjs(item?.createdAt).format('hh:mm A'),
        staffNumber: item?.staffNumber,
      };
    });
  }, [specialties]);

  const filterSpecialty = () => {
    closeFilterModal()
    get_Specialties({
      ...params,
      fromDate: newParams.fromDate ? dayjs(newParams.fromDate).format('YYYY-MM-DD') : '',
      toDate: newParams.toDate ? dayjs(newParams.toDate).format('YYYY-MM-DD') : '',
    });
  };

  return (
    <>
      <Navbar title="Specialty/Department" />
      <Restricted module='Specialty' permission='View specialty'>
        <>
          <SlideUp>
            <div className="p-6">
              <Table
                title="Specialty List"
                headers={specialty_list_header}
                data={dataToExport}
                totalCount={specialties?.total}
                handleSearch={handleSearch}
                selectLimit={selectLimit}
                filterFnc={openFilterModal}
                loading={loading}
                perPage={specialties?.limit}
                buttonFnc={() => setCreateSpecialty(true)}
                searchPlaceholder="Search by specialty name..."
                buttonName="Create Specialty"
                changePage={changePage}
                currentPage={specialties?.page}
                emptyStateCaption="You currently have no specialty created on the system"
                emptyStateTitle="Specialty/Department created will be displayed here"
                module="Specialty"
                permission="Create specialty"
              >
                <>
                  {specialties?.specialties?.map((item) => (
                    <tr
                      data-testid={item?._id}
                      onClick={() => navigate(`${item._id}?name=${item.name}`)}
                      key={item?._id}
                      className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                    >
                      <td className="px-[10px] py-[11px] flex space-x-2 items-center">
                        <SpecialtyIcon className="mr-1" fill="#748181" />{' '}
                        <p className="font-campton_r text-neutral_black capitalize">{item.name}</p>
                      </td>
                      <td className="px-[10px] py-[11px] capitalize text-14">
                        {dayjs(item?.createdAt).format('DD-MMM-YYYY')}
                      </td>
                      <td className="px-[10px] py-[11px] capitalize md:w-52 w-20">
                        <p className="card-description">{dayjs(item?.createdAt).format('hh:mm A')}</p>
                      </td>
                      <td className="px-[10px] py-[11px] capitalize text-14">{item?.staffNumber}</td>
                      <td className="px-[10px] py-[11px] capitalize">
                        <ArrowRight fill="#A9B2B2" />
                      </td>
                    </tr>
                  ))}
                </>
              </Table>
            </div>
          </SlideUp>
          {createSpecialty &&
            <AddSpecialty
              closeModal={() => setCreateSpecialty(false)}
              callback={callback}
              action="create"
              title="Create Specialty/Department"
            />
          }
          {filterModal && (
            <SpecialtyFilter closeModal={closeFilterModal} params={newParams} setParams={setNewParams} loading={loading} func={filterSpecialty} />
          )}
        </>
      </Restricted>
    </>
  );
};

export default Specialty;
