import Navbar from "components/Navbar";
import React from "react";
import BreadCrumbs from "components/BreadCrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import StaffCalendarDetails from "pages/EShift/Components/StaffCalendarDetails";
import { useSelector } from "react-redux";

export default function CalendarForStaff() {
    const navigate = useNavigate()
    const location = useLocation()
    const { user } = useSelector(state => state.auth)
    const shiftId = location.pathname.split('/')[4]
    const providerId = location.pathname.split('/')[5]

    const singleStaffShift = {
        firstName: user?.firstName,
        lastName: user?.lastName,
        gender: user?.gender,
        role: user?.role,
        emailAddress: user?.emailAddress,
        phoneNumber: user?.phoneNumber,
        countryCode: user?.countryCode,
        specialty: user?.specialty,
        location: user?.location,
        qualification: user?.qualification,
        providerId: user?._id,
        profilePic: user?.profilePic
    }

    const options = [
        {
            title: 'Shift Management',
            method: () => navigate("/e-shift/scheduling")
        },
        {
            title: `${singleStaffShift?.firstName} ${singleStaffShift?.lastName}`
        }
    ]

    return (
        <>
            <Navbar title="Shift Management" />
            <BreadCrumbs options={options} />
            <StaffCalendarDetails staff={singleStaffShift} shiftId={shiftId} providerId={providerId} />
        </>
    )
}