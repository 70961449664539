import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ progress, total }) => {
  const percentage = (progress / total) * 100;

  return (
    <div className="h-[12px] relative mt-3" title={`${percentage.toFixed(2)}%`}>
      <div
        className="bg-success h-full rounded-full relative z-[2]"
        style={{
          width: `${percentage}%`,
        }}
      />
      <div className="bg-neutral_disabled w-full h-full rounded-full absolute top-0 left-0 z-[1]" />
    </div>
  );
};

export default ProgressBar;
ProgressBar.propTypes = {
  progress: PropTypes.number,
  total: PropTypes.number,
};
