import React, { useEffect, useState } from 'react';
import { SlideUp } from 'components/Animations';
import Navbar from 'components/Navbar';
import SubNavbar from 'components/SubNavbar';
import useMultiStepForm from 'hooks/useMultiStepForm';
import { notify } from 'store/modules/global';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'store/modules/auth';
import BasicInformation from './BasicInformation';
import PreviewInformation from './PreviewInformation';
import SelectProvider from './SelectProvider';
import useDebounce from 'hooks/useDebounce';
import useProviders from 'hooks/fetch/useProviders';
import { useDispatch } from 'react-redux';
import useHandleError from 'hooks/useHandleError';
import StepProgress from 'components/StepProgress';

const NewAppointment = () => {
  const navigate = useNavigate();
  const [gNumber, setGNumber] = useState('');
  const { handleError } = useHandleError();
  const [time, setTime] = useState({ start: '', end: '' });
  const { debouncedValue } = useDebounce(gNumber);
  const [patient, setPatient] = useState({});
  const [provider, setProvider] = useState({});
  const dispatch = useDispatch();
  const { institutionId } = useAuthState();
  const [formData, setFormData] = useState({
    patientGNumber: '',
    priority: '',
    appointmentType: '',
    appointmentDate: '',
    note: '',
    providerId: '',
    institutionId: institutionId,
  });
  const { get_patient_by_gNumber, loading: isFetchingPatient } = useProviders();

  const handleChange = (e) => {
    if (e.target.name === 'patientGNumber') {
      setFormData({ ...formData, patientGNumber: e.target.value });
      setGNumber(e.target.value);
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (debouncedValue.length === 10) {
      get_patient_by_gNumber(gNumber).then((res) => {
        if (res?.code === 200) return setPatient(res?.data);
        dispatch(
          notify({
            display: true,
            status: 'error',
            message: handleError({ status: res?.code, message: res?.message }),
          }),
        );
        setPatient();
      });
    }
  }, [debouncedValue]);

  const { step, next, back } = useMultiStepForm([
    <BasicInformation
      key={1}
      formData={formData}
      handleChange={handleChange}
      setFormData={setFormData}
      patientDetails={patient}
      loading={isFetchingPatient}
      time={time}
      gNumber={gNumber}
      setTime={setTime}
      cancel={() => navigate(-1)}
      next={() => next()}
    />,
    <SelectProvider
      formData={formData}
      setFormData={setFormData}
      key={2}
      time={time}
      back={() => back()}
      next={() => next()}
      setProvider={setProvider}
    />,
    <PreviewInformation
      formData={formData}
      patient={patient}
      key={3}
      back={() => back()}
      time={time}
      provider={provider}
    />,
  ]);

  const progress = [
    {
      step: 1,
      title: 'Basic Information',
      caption: 'Appointment type, priority, date and note'
    },
    {
      step: 2,
      title: 'Select Provider',
      caption: 'Select provider for this appointment based off specialty'
    },
    {
      step: 3,
      title: 'Preview Information',
      caption: 'Preview & ensure information provided is correct'
    }
  ]

  return (
    <>
      <Navbar title="Appointments" />
      <SubNavbar previous="Appointments" onClick={() => navigate('/appointments')} current="New Appointment" />
      <SlideUp>
        <div className="md:flex md:p-[24px] min-h-[80vh]">
          <StepProgress progress={progress} step={step.key} />
          <div className="p-[24px] md:border border-neutral_stroke_2 rounded-[16px] w-full md:ml-[316px]">
            {step}
          </div>
        </div>
      </SlideUp>
    </>
  );
};

export default NewAppointment;
