import React from 'react'
import { SlideUp } from 'components/Animations';
import PropTypes from 'prop-types';
import useWindowWidth from 'hooks/useWindowWidth';
import { MEDIUM_WIDTH } from 'constants';

const VerticalMenuTab = ({ options, tab, setTab }) => {
  const windowWidth = useWindowWidth()

  return (
    <SlideUp>
      <section className="flex">
        <div className="min-w-full sm:min-w-[258px] h-full p-[24px] sm:border-r border-r-neutral_stroke_2">
          {options.map((item) => (
            <div key={item?.id}>
              <div
                data-testid={item?.name}
                className="flex items-center first-of-type:mt-0 py-3 my-2.5 cursor-pointer hide_tap "
                onClick={() => setTab(item?.name)}
              >
                <div className="w-[24px] mr-[16px]">{item?.icon}</div>
                <div className="flex justify-between items-center w-full">
                  <p
                    className={`text-16 sm:text-14 ${tab !== item?.name ? 'text-neutral_body' : 'text-brand_secondary'
                      } font-campton_m`}
                  >
                    {item?.name}
                  </p>
                  {tab === item?.name ? (
                    <svg data-testid={`active-${item.name}`} width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="2" cy="2" r="2" fill="#FF7A21" />
                    </svg>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {windowWidth < ((MEDIUM_WIDTH || 768) + 1) ?
                <div className="mb-[20px] border-b border-b-neutral_stroke_2 pb-[16px]">
                  {tab === item.name ? item.component : ''}
                </div> : ''
              }
            </div>
          ))}
        </div>
      </section>
    </SlideUp>
  )
}

export default VerticalMenuTab
VerticalMenuTab.propTypes = {
  setTab: PropTypes.func,
  tab: PropTypes.string,
  options: PropTypes.array
}