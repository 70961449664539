import React, { useContext } from "react"
import { ReactComponent as EmptyStateIcon } from "assets/svg/empty-medical-history.svg"
import { PatientContext } from "context"
import AnimateList from "../AnimateList"
import PropTypes from 'prop-types';
import EmptyState from "components/Table/EmptyState"
import Layout from "../Layout"

export default function MedicalHistory({ view, callback, setModal, visitStatus, canUpdate  = true }) {
  const { patient } = useContext(PatientContext)

  return (
    <Layout
      view={view}
      visitStatus={visitStatus && canUpdate}
      setModal={setModal}
      callback={callback}
    >
      <>
        {patient?.medicalRecord?.medicalDiagnosis?.length ?
          patient?.medicalRecord?.medicalDiagnosis?.map((item, i) =>
            <AnimateList key={item?._id} index={i}>
              <div className="flex border-b border-b-neutral_stroke_2 py-[24px]">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="32" height="32" rx="16" fill="#EBF6FA" />
                  <path d="M21.5 13.5H18.5V10.5C18.5 10.2348 18.3946 9.98043 18.2071 9.79289C18.0196 9.60536 17.7652 9.5 17.5 9.5H14.5C14.2348 9.5 13.9804 9.60536 13.7929 9.79289C13.6054 9.98043 13.5 10.2348 13.5 10.5V13.5H10.5C10.2348 13.5 9.98043 13.6054 9.79289 13.7929C9.60536 13.9804 9.5 14.2348 9.5 14.5V17.5C9.5 17.7652 9.60536 18.0196 9.79289 18.2071C9.98043 18.3946 10.2348 18.5 10.5 18.5H13.5V21.5C13.5 21.7652 13.6054 22.0196 13.7929 22.2071C13.9804 22.3946 14.2348 22.5 14.5 22.5H17.5C17.7652 22.5 18.0196 22.3946 18.2071 22.2071C18.3946 22.0196 18.5 21.7652 18.5 21.5V18.5H21.5C21.7652 18.5 22.0196 18.3946 22.2071 18.2071C22.3946 18.0196 22.5 17.7652 22.5 17.5V14.5C22.5 14.2348 22.3946 13.9804 22.2071 13.7929C22.0196 13.6054 21.7652 13.5 21.5 13.5ZM21.5 17.5H18C17.8674 17.5 17.7402 17.5527 17.6464 17.6464C17.5527 17.7402 17.5 17.8674 17.5 18V21.5H14.5V18C14.5 17.8674 14.4473 17.7402 14.3536 17.6464C14.2598 17.5527 14.1326 17.5 14 17.5H10.5V14.5H14C14.1326 14.5 14.2598 14.4473 14.3536 14.3536C14.4473 14.2598 14.5 14.1326 14.5 14V10.5H17.5V14C17.5 14.1326 17.5527 14.2598 17.6464 14.3536C17.7402 14.4473 17.8674 14.5 18 14.5H21.5V17.5Z" fill="#36A1C5" />
                </svg>
                <div className="ml-[16px]">
                  <h3 className="text-16 text-neutral_black font-campton_m mb-[4px]">{item?.name}</h3>
                  <p className="text-14 text-neutral_body font-campton_r mb-[4px]">
                    <span className="hidden sm:inline-block">Medications:</span> <span className="text-neutral_black capitalize">{item?.medications.join(", ")}</span>
                  </p>
                  <p className="text-14 text-neutral_body font-campton_r mb-[4px]">
                    <span className="hidden sm:inline-block">Duration:</span> <span className="text-neutral_black">
                      {item?.startDate ? item?.startDate.split(' ').join('') : ''} to {item?.endDate ? item?.endDate.split(' ').join('') : ''}
                    </span>
                  </p>
                </div>
              </div>
            </AnimateList>
          ) : (
            <EmptyState
              title="No Medical Diagnoses"
              icon={<EmptyStateIcon className="mb-[24px]" />}
            />
          )}
      </>
    </Layout>
  )
}
MedicalHistory.propTypes = {
  callback: PropTypes.func,
  view: PropTypes.string,
  setModal: PropTypes.func,
  visitStatus: PropTypes.func,
  canUpdate: PropTypes.bool
};