import useService from "hooks/fetch/useService"
import { useEffect, useState } from "react"
import { useAuthState } from "store/modules/auth";

export default function useBillServices() {
    const { get_services, get_service_categories, loading: isFetchingCategory } = useService()
    const { institutionId } = useAuthState();
    const [services, setServices] = useState([])

    const handleSelectedService = (module, obj) => {
        const updatedServices = services?.map((item) => {
            if (item?.module?.toLowerCase() === module?.toLowerCase()) {
                return {
                    ...item,
                    selected: !item?.selected?.find(service => service?.id === obj?.id) ?
                        [...item.selected, obj] : item.selected.filter(item => item?.id !== obj?.id)
                }
            }
            return item
        })
        setServices(updatedServices)
    }

    useEffect(() => {
        if (services?.length) return
        get_service_categories({
            type: 'institution',
            institutionId,
            //providerId: user?.businessName ? '' : user?._id,
        })
            .then(res => {
                if (res?.code !== 200) return
                const categories = res?.data?.categories?.map(category => {
                    return {
                        module: category?.name,
                        selected: [],
                        service: []
                    }
                })
                setServices([...services, ...categories])
            })
    }, [])

    const getServicesByCategory = (category) => {
        get_services({
            category,
            type: 'institution',
            institutionId,
            //providerId
        })
            .then(res => {
                if (!res?.data?.services) return
                const categoryServices = res?.data?.services
                const updatedService = services?.map(item => {
                    if (category === item?.module) {
                        return {
                            ...item,
                            service: categoryServices?.map(obj => {
                                return {
                                    ...item.service,
                                    id: obj?._id,
                                    name: obj?.name,
                                    category: obj?.subCategory,
                                    totalFee: obj?.serviceProperties[0]?.totalFee,
                                    fee: obj?.serviceProperties[0]?.fee,
                                    vat: obj?.serviceProperties[0]?.vat
                                }
                            })
                        }
                    }
                    return item
                })
                setServices(updatedService)
            })
    }

    return { services, getServicesByCategory, handleSelectedService, isFetchingCategory }
}