/* eslint-disable max-len */
import React from 'react';
import Status from 'components/Status';
import { userInitals } from 'utils/ImagePlaceholder';
import Avatar from 'components/Avatar/Avatar';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { insertTimeToDate } from 'utils/formatDate';

const AppointmentInfo = ({ appointmentDetails, purpose }) => {
  const { pathname } = useLocation();
  const appointmentDate = appointmentDetails?.proposedDate || (appointmentDetails?.appointmentDate || appointmentDetails?.appointment?.date || appointmentDetails?.appointmentInfo?.appointmentDate)
  const appointmentStartTime = appointmentDetails?.proposedStartTime || (appointmentDetails?.startTime || appointmentDetails?.appointment?.startTime || appointmentDetails?.appointmentInfo?.startTime)
  const appointmentEndTime = appointmentDetails?.proposedEndTime || (appointmentDetails?.endTime || appointmentDetails?.appointment?.endTime || appointmentDetails?.appointmentInfo?.endTime)
  const formattedDate = purpose !== 'booking' ? dayjs(insertTimeToDate(appointmentDate, appointmentStartTime)).format('MMM DD, YYYY') : dayjs(appointmentDate).format('MMM DD, YYYY')
  const formattedStartTime = purpose !== 'booking' ? dayjs(insertTimeToDate(appointmentDate, appointmentStartTime)).format('hh:mm A') : dayjs(appointmentStartTime).format('hh:mm A')
  const formattedEndTime = purpose !== 'booking' ? dayjs(insertTimeToDate(appointmentDate, appointmentEndTime)).format('hh:mm A') : dayjs(appointmentEndTime).format('hh:mm A')

  return (
    <>
      <div className="border border-neutral_stroke_2 bg-primary_tint_50 p-4 rounded-2xl mb-3">
        <p className="font-campton_r text-12 text-neutral_body">Details</p>
        <div className="flex justify-between items-start my-4">
          <div className="flex">
            <Avatar
              url={appointmentDetails?.patientInfo?.profilePic ?? appointmentDetails?.patient?.profilePic}
              width="32px"
              height="32px"
              textVariant="!text-12"
              alt={(appointmentDetails?.patientInfo?.firstName || appointmentDetails?.patient?.firstName)?.split('')[0]}
              initials={userInitals(
                appointmentDetails?.patientInfo?.firstName ?? appointmentDetails?.patient?.firstName,
                appointmentDetails?.patientInfo?.lastName ?? appointmentDetails?.patient?.lastName,
              )}
              className="bg-brand_secondary mr-1.5 w-8 h-8"
            />
            <div className="font-campton_r">
              <p className="text-neutral_black text-14 capitalize">
                {appointmentDetails?.patientInfo?.firstName ?? appointmentDetails?.patient?.firstName}{' '}
                {appointmentDetails?.patientInfo?.lastName ?? appointmentDetails?.patient?.lastName}
              </p>
              <p className="text-12 text-neutral_body">
                {appointmentDetails?.patientInfo?.emailAddress ?? appointmentDetails?.patient?.emailAddress ?? 'N/A'} |{' '}
                {appointmentDetails?.patientInfo?.gNumber ?? appointmentDetails?.patient?.gNumber ?? 'N/A'}
              </p>
            </div>
          </div>
          <Status status={appointmentDetails?.status} className="!text-12" />
        </div>
        <div>
          <div className="font-campton_r mb-[10px]">
            <p className="text-neutral_body text-12">Date</p>
            <p className="text-14 uppercase">{formattedDate}, {formattedStartTime} - {formattedEndTime}</p>
          </div>
          <div className="font-campton_r">
            <p className="text-neutral_body text-12">Appointment Type</p>
            <p className="capitalize text-14">
              {(appointmentDetails?.type ??
                appointmentDetails?.appointment?.type ??
                appointmentDetails?.appointmentInfo?.type) === 'inApp'
                ? 'In-App'
                : 'On-site'}
            </p>
          </div>
        </div>
        {pathname.includes('new-appointment') && (
          <div className="mt-4 font-campton_r">
            <p className="text-neutral_body text-12">Note</p>
            <p className="text-14">{appointmentDetails.note || 'N/A'}</p>
          </div>
        )}
      </div>
      <div className="border border-neutral_stroke_2 p-4 rounded-2xl">
        <p className="text-neutral_body font-campton_r text-12 mb-4">Provider Information</p>
        <div className="flex justify-between items-start mt-4">
          <div className="flex">
            <Avatar
              url={appointmentDetails?.providerInfo?.profilePic || appointmentDetails?.provider?.profilePic}
              width="32px"
              height="32px"
              textVariant="!text-12"
              alt={
                (appointmentDetails?.providerInfo?.firstName || appointmentDetails?.provider?.firstName)?.split('')[0]
              }
              initials={userInitals(
                appointmentDetails?.providerInfo?.firstName || appointmentDetails?.provider?.firstName,
                appointmentDetails?.providerInfo?.lastName || appointmentDetails?.provider?.lastName,
              )}
              className="bg-brand_secondary mr-1.5 w-8 h-8"
            />
            <div className="font-campton_r">
              <p className="text-neutral_black text-14 capitalize">
                {appointmentDetails?.providerInfo?.title ? `${appointmentDetails?.providerInfo?.title}. ` : ''}
                {appointmentDetails?.providerInfo?.firstName || appointmentDetails?.provider?.firstName}{' '}
                {appointmentDetails?.providerInfo?.lastName || appointmentDetails?.provider?.lastName}
              </p>
              <p className="text-12 text-neutral_body">
                {appointmentDetails?.providerInfo?.emailAddress ?? appointmentDetails?.provider?.emailAddress}
              </p>
              <p className="text-12 text-neutral_body">
                {appointmentDetails?.providerInfo?.specialty ?? appointmentDetails?.provider?.specialty} |{' '}
                {appointmentDetails?.providerInfo?.qualification ?? appointmentDetails?.provider?.qualification}
              </p>
            </div>
          </div>
          <p className="text-12 text-neutral_body font-campton_r">
            {appointmentDetails?.providerInfo?.GPINumber ?? appointmentDetails?.provider?.GPINumber ?? 'N/A'}
          </p>
        </div>
      </div>
    </>
  );
};

export default AppointmentInfo;
AppointmentInfo.propTypes = {
  appointmentDetails: PropTypes.object,
  type: PropTypes.string,
  purpose: PropTypes.string
};