import { useState } from "react"
import { useAppDispatch } from 'hooks/useReduxHook';
import { notify } from "../store/modules/global";
import useHandleError from "./useHandleError";
import { unwrapResult } from "@reduxjs/toolkit"
import instance from 'axios';
import NProgress from 'nprogress';
import { requestUploadUrl } from "store/modules/auth/actions";

export default function useUpload(check = true) {
    let dispatch = useAppDispatch()
    const [uploading, setUploading] = useState(false)
    const { handleError } = useHandleError()
    const [imageUrl, setImageUrl] = useState('')

    const upload = async (file, formData, timestamp, callback, alert = true) => {
        try {
            if (file?.size > 3000000 && check) return dispatch(notify({ display: true, message: "File size cannot be more than 3mb" }))

            setUploading(true)
            return dispatch(requestUploadUrl({ ...formData }))
                .then(unwrapResult)
                .then(async (res) => {
                    if (res?.code === 200) {
                        let reqInstance = instance.create({
                            headers: {
                                'Content-Type': '/image/*',
                            }
                        })

                        NProgress.start()
                        const response = await reqInstance.put(res?.data, file)
                        if (response?.status === 200) {
                            alert && dispatch(notify({ display: true, status: 'success', message: "Upload successful" }))
                            setImageUrl(`${process.env.REACT_APP_S3_BUCKET}${timestamp}${file.name}`)
                            if (callback) callback()
                            return { response, imageUrl: `${process.env.REACT_APP_S3_BUCKET}${timestamp}${file.name}` }
                        }
                    }
                    //dispatch(notify({ display: true, status: 'error', message: handleError({ message: res?.message }) }))
                    dispatch(notify({ display: true, status: 'error', message: handleError({ message: "Unable to upload file." }) }))
                })
                .catch(error => {
                    dispatch(notify({ display: true, status: 'error', message: handleError({ message: "Something went wrong." }) }))
                    //dispatch(notify({ display: true, status: 'error', message: handleError({ message: error.message }) }))
                }).finally(() => {
                    NProgress.done()
                    setUploading(false)
                })
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    };

    return {
        upload,
        uploading,
        setUploading,
        imageUrl,
        setImageUrl
    }
}