import React from 'react';
import PropTypes from 'prop-types';

export default function TextArea({ id, name, placeholder, variant, label, value, onChange, readOnly, rows, maxLength, disabled, className }) {
    return (
        <div className={`relative mb-[3.5px] textarea-container ${variant || ''}`}>
            <textarea
                id={id}
                name={name}
                rows={rows}
                maxLength={maxLength}
                disabled={disabled}
                onChange={onChange}
                placeholder={placeholder || ''}
                data-testid={`test-${id}`}
                aria-labelledby={id}
                readOnly={readOnly}
                value={value}
                className={`
                border-neutral_stroke p-4 text-neutral_black text-14 w-full outline-0 border font-campton_r hide_tap
                rounded-[8px] focus:border-brand_primary focus:border bg-neutral_white placeholder:text-14 ${className}`
                }
           />
            {label && <label
                htmlFor={id}
                className={`${disabled ? 'bg-none' : 'bg-neutral_white'}
                    text-neutral_body mb-2 font-campton_r bg-neutral_white px-1 pt-2 cursor-text`}
            >
                {label}
            </label>}
        </div>
    );
}

TextArea.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    rows: PropTypes.number,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    variant: PropTypes.string,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    maxLength: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string
};
