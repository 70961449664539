import { institution } from 'services/endpoints/institution'
import { createCustomAsyncThunk } from 'utils/createThunk'

const addSpecialty = createCustomAsyncThunk('specialty/addSpecialty', 'patch', institution.institution)
const getSpecialties = createCustomAsyncThunk('specialty/getAllSpecialty', 'get', institution.get_specialty)
const getSpecialty = createCustomAsyncThunk('specialty/getSpecialty', 'get', institution.institution)
const getSpecialtyStaff = createCustomAsyncThunk('specialty/getSpecialtyStaff', 'get', institution.institution)
const editSpecialty = createCustomAsyncThunk('specialty/editSpecialty', 'patch', institution.institution)

export {
    addSpecialty,
    getSpecialties,
    getSpecialty,
    getSpecialtyStaff,
    editSpecialty
}
