import React, { useRef, useState } from 'react';
import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import useUpload from 'hooks/useUpload';
import { useAuthState } from 'store/modules/auth';
import dayjs from 'dayjs';
import useAuth from 'hooks/fetch/useAuth';
import { ReactComponent as File } from 'assets/svg/file.svg';
import { ReactComponent as Dot } from 'assets/svg/Ellipse 4.svg';
import PropTypes from 'prop-types';
import { Button } from 'components/Buttons';

const UploadLicense = ({ type, callback }) => {
  const fileRef = useRef();
  const { user, institutionId } = useAuthState();
  const { upload, uploading } = useUpload();
  const { add_license, loading } = useAuth();
  const [file, setFile] = useState();
  const [formData, setFormData] = useState({
    licenseNumber: '',
    licenseType: type,
    institutionId: institutionId,
    type: user?.businessName ? 'institution' : 'provider'
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const updateLicense = () => {
    const timestamp = dayjs()?.format('YYYYMMDD-HHmmss');
    const payload = { file: `${timestamp}${fileRef.current.files[0]?.name}` }
    upload(fileRef.current.files[0]?.name, payload, timestamp, null, false)
      .then((res) => {
        if (res?.response?.status === 200)
          add_license({ ...formData, licenseImageUrl: res?.imageUrl }, callback)
      })
  };

  const disabledBtn = !type || !formData.licenseNumber || !fileRef.current?.files[0];

  return (
    <FormWrapper onSubmit={updateLicense}>
      <div className="flex justify-between flex-col h-full lg:h-[400px]">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12">
            <Input
              label="License Number"
              type="text"
              id="licenseNumber"
              name="licenseNumber"
              readOnly={loading}
              value={formData?.licenseNumber}
              onChange={handleChange}
            />
          </div>
          <div className="mb-[14px] sm:mb-0 hide_tap col-span-12 w-full">
            <label htmlFor="file" className="cursor-pointer ">
              {file ? (
                <div className="border border-neutral_stroke_2 py-2 px-4 rounded-lg flex space-x-2.5 ">
                  <File />
                  <div className="text-14 font-campton_m">
                    <p className="">{file?.name}</p>
                    <p className="text-neutral_body text-12 flex items-center space-x-1">
                      {file?.size}KB <Dot className="ml-1 mb-0.5" />{' '}
                      <span className="text-success font-campton_r">Done</span>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="p-7 border border-dashed border-neutral_stroke_1 rounded-lg w-full font-campton_r text-center">
                  <Download className="mx-auto mb-5" />
                  <p>
                    <span className="underline text-[#36A1C5] text-14">Click to upload</span> or drag and drop
                  </p>
                  <p className="font-campton_m text-neutral_body text-12">Supported format: PDF</p>
                </div>
              )}
            </label>
            <input
              data-testid="test-file"
              type="file"
              id="file"
              name="file"
              accept="image/png, image/jpg, image/jpeg, pdf"
              className="hidden"
              ref={fileRef}
              readOnly={uploading || loading}
              onChange={(e) => {
                setFile({
                  name: e.target.files[0]?.name,
                  size: (e.target.files[0]?.size / 1000000).toFixed(2),
                })
              }}
            />
          </div>
        </div>
        <div className="flex justify-end mt-6">
          <Button
            className="w-28 rounded-full h-10 font-campton_m"
            theme="blue"
            disabled={disabledBtn}
            name="Save"
            loading={loading || uploading}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

export default UploadLicense;
UploadLicense.propTypes = {
  type: PropTypes.string,
  callback: PropTypes.func,
};
