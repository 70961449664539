import React, { useCallback, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Accordion from "components/Accordion"
import { Search } from "components/Inputs"
import { formatCurrency } from "utils/formatCurrency"
import { ReactComponent as CheckboxTag } from "assets/icons/check-box-tag.svg"
import { ReactComponent as UnChecked } from "assets/icons/check-box-unselected.svg";
import { ReactComponent as Checked } from "assets/icons/check-box-selected.svg";
import Spinner from "components/Spinner"

export default function Service({ item, handleSelectedService, getServicesByCategory, isFetchingCategory }) {
    const [search, setSearch] = useState('')

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const checkIfItemIsSelected = useCallback((id) => {
        return item?.selected?.some(obj => obj?.id === id)
    }, [item?.selected])

    const searchOptions = useMemo(() => {
        return !search ? item?.service : item?.service?.filter((option) => option?.name?.toLowerCase().includes(search?.toLowerCase()));
    }, [item, search]);

    return (
        <Accordion
            key={item?.module}
            title={item?.module}
            titleIcon={item?.selected?.length ? <CheckboxTag className="mr-[8px]" /> : <UnChecked className="mr-[8px]" />}
            iconPosition="right"
            className="border-b border-neutral_stroke_2 py-[16px] text-14 -mx-[16px]"
            titleClassName="text-14"
            onClick={() => !item?.service?.length && getServicesByCategory(item?.module)}
        >
            <div className="px-[32px] pt-[24px] bg-[#F7F8F8] mt-[12px]">
                <Search
                    id="search"
                    name="search"
                    placeholder="Search by service name"
                    onChange={handleSearch}
                />
                <div className="py-[16px]">
                    {!searchOptions?.length && isFetchingCategory && <Spinner/>}
                    {!searchOptions?.length && !isFetchingCategory ? <p className="text-14 text-neutral_black text-center font-campton_r">No available services.</p> :
                        searchOptions?.map((option) =>
                            <div
                                key={option.id}
                                className="font-campton_r flex items-center justify-between py-[12px]"
                            >
                                <div className="flex items-center">
                                    <div
                                        data-testid={option?.id}
                                        className="mr-[8px] cursor-pointer hide_tap shrink-0"
                                        onClick={() => handleSelectedService(item?.module, option)}
                                    >
                                        {checkIfItemIsSelected(option?.id) ? <Checked data-testid="checked" /> : <UnChecked data-testid="unchecked" />}
                                    </div>
                                    <p className="text-14 text-neutral_black">
                                        {option?.name} | <span className="text-12 text-neutral_body">{option?.category}</span>
                                    </p>
                                </div>
                                <p className="text-14 text-neutral_black">{formatCurrency(option?.totalFee)}</p>
                            </div>
                        )}
                </div>
            </div>
        </Accordion>
    )
}

Service.propTypes = {
    item: PropTypes.object,
    handleSelectedService: PropTypes.func,
    getServicesByCategory: PropTypes.func,
    isFetchingCategory: PropTypes.bool
}