import { Button } from 'components/Buttons';
import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'components/Inputs';
import Phone from 'components/Inputs/PhoneInput';
import { useNavigate } from 'react-router-dom';
import Spinner from 'components/Spinner';

export default function PatientInformation({ patient, gNumber, handleChange, next, loading }) {
  const navigate = useNavigate()
  const patientName = patient?.firstName ? `${patient?.firstName} ${patient?.lastName ?? ''}` : ''
  const phone = {
    phoneNumber: patient?.phoneNumber,
    countryCode: patient?.countryCode
}
  return (
    <div className="h-full flex justify-between flex-col">
      <div>
        <h2 className="text-16 text-neutral_black font-campton_m mb-[24px]">Patient Information</h2>
        <div className='grid md:grid-cols-2 grid-cols-1 gap-[24px]'>
          <div className="w-full">
            <Input
              label="G-Number"
              type="text"
              id="gNumber"
              name="gNumber"
              value={gNumber}
              maxLength={10}
              measurement={loading ? <Spinner height="h-[10px]" width="w-[10px] mr-4" /> : ''}
              onChange={handleChange}
            />
          </div>
          <div className="w-full">
            <Input
              label="Patient Name"
              type="text"
              id="patientName"
              name="patientName"
              value={patientName}
              readOnly={true}
              disabled={true}
            />
          </div>
          <div className="w-full">
            <Input
              label="Email Address"
              type="text"
              id="emailAddress"
              name="emailAddress"
              value={patient?.emailAddress || ''}
              readOnly={true}
              disabled={true}
            />
          </div>
          <div className="w-full">
            <Phone
              value={phone}
              disabled={true}
              variant="mb-0"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          name="Cancel"
          theme="transparent"
          type="button"
          onClick={() => navigate(-1)}
          className="h-[40px] text-16 w-[88px] mr-[16px]"
        />
        <Button
          name="Next"
          theme="blue"
          type="button"
          className="h-[40px] text-16 w-[88px]"
          disabled={!gNumber || !patient?.emailAddress || !patientName}
          onClick={next}
        />
      </div>
    </div>
  );
}

PatientInformation.propTypes = {
  patient: PropTypes.object,
  handleChange: PropTypes.func,
  next: PropTypes.func,
  gNumber: PropTypes.string,
  loading: PropTypes.bool,
}