import React, { useState } from "react";
import PropTypes from "prop-types"
import Avatar from "components/Avatar/Avatar";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { formatCurrency } from "utils/formatCurrency";
import Status from "components/Status";
import { userInitals } from "utils/ImagePlaceholder";
import { formatDate } from "utils/formatDate";
import { restructureBills } from "utils/restructureBills";

export default function BillingInformation({ data }) {
    const businessAddress = data?.institutionInfo?.businessAddress
    const bills = restructureBills(data?.billDetails)

    return (
        <div className="relative">
            <div className="pb-[20px]">
                <div>
                    <section className="border border-neutral_stroke_2 bg-[#F7FBFD] rounded-[16px] font-campton_r mb-[24px]">
                        <div className="p-[16px] border-b border-b-neutral_stroke_2">
                            <div className="flex justify-between">
                                <p className="text-12 text-neutral_body">Bill Amount</p>
                                <Status
                                    status={data?.paymentStatus}
                                    className="text-12 capitalize"
                                />
                            </div>
                            <p className="text-14 text-neutral_black">{formatCurrency(data?.amountExpected)}</p>
                        </div>
                        <div className="grid grid-cols-2">
                            <div className="p-[16px] border-r border-r-neutral_stroke_2">
                                <p className="text-12 text-neutral_body">Amount paid</p>
                                <p className="text-14 text-neutral_black">{formatCurrency(data?.amountPaid)}</p>
                            </div>
                            <div className="p-[16px]">
                                <p className="text-12 text-neutral_body">Outstanding amount</p>
                                <p className="text-14 text-neutral_black">{formatCurrency(data?.amountExpected - data?.amountPaid)}</p>
                            </div>
                        </div>
                    </section>
                    <section className="flex justify-between p-[16px] border border-neutral_stroke_2 rounded-[16px] font-campton_r mb-[24px]">
                        <div>
                            <p className="text-12 text-neutral_body">Billed On</p>
                            <p className="text-14 text-neutral_black">{formatDate(data?.createdAt)}</p>
                        </div>
                        <div>
                            <p className="text-12 text-neutral_body"> Due Date</p>
                            <p className="text-14 text-neutral_black">{formatDate(data?.dueDate) || "N/A"}</p>
                        </div>
                    </section>
                </div>
                <div className="mb-[24px]">
                    <p className="text-12 text-[#83889D] font-campton_r mb-[8px]">Bill From</p>
                    <div className="border border-neutral_stroke_2 rounded-[16px] p-[16px]">
                        <Avatar
                            url={data?.institutionInfo.logoUrl}
                            width="32px"
                            height="32px"
                            //alt={singleVisit?.provider?.firstName?.split('')[0]}
                            initials={userInitals(data?.institutionInfo?.businessName, data?.institutionInfo?.logoUrl)}
                            className="bg-brand_secondary w-[32px] h-[32px] mb-[8px] shrink-0"
                        />
                        <p className="text-14 font-campton_m text-neutral_black capitalize mb-[4px]">{data?.institutionInfo?.businessName}</p>
                        <p className="text-14 font-campton_r text-neutral_body leading-[21px] mb-[4px]">{data?.institutionInfo?.businessEmailAddress}</p>
                        <p className="text-14 font-campton_r text-neutral_body leading-[21px] capitalize">
                            {businessAddress?.address}, {businessAddress?.city}, {businessAddress?.state} {businessAddress?.country}.
                        </p>
                    </div>
                </div>
                <section className="mb-[24px]">
                    <p className="text-12 text-[#83889D] font-campton_r mb-[8px]">Service Details & Summary</p>
                    <div className="border border-neutral_stroke_2 rounded-[16px] p-[16px]">
                        {bills?.map((item, i) =>
                            <ServiceItem
                                key={item?.category}
                                title={item?.category}
                                services={item?.services}
                                //date="Jul 04, 2023"
                                total={Number(item?.total)}
                                lastItem={(i + 1) === bills?.length}
                            />
                        )}
                        {/* <ServiceItem
                            title="Discount"
                            date="Jul 04, 2023"
                            amount="25000.00"
                        />
                        <ServiceItem
                            title="Tax"
                            date="Jul 04, 2023"
                            amount="25000.00"
                            lastItem={true}
                        /> */}
                    </div>
                </section>
            </div>
        </div>
    )
}

const ServiceItem = ({ title, services, total, lastItem }) => {
    const [display, setDisplay] = useState(false)

    return (
        <>
            <div
                data-testid={title}
                className={`
                text-14 text-neutral_black font-campton_r ${!lastItem ? 'border-b border-b-neutral_stroke_1' : ''}
                cursor-pointer hide_tap flex items-center justify-between py-[16px]`}
                onClick={() => setDisplay(!display)}
            >
                <div className="px-[10px] flex items-center capitalize">
                    <div className="w-[13.2px]">{display ? <ArrowDown fill="#748181" /> : <ArrowRight fill="#748181" />}</div>
                    <p className="ml-[8px]">{title}</p>
                </div>
                <p className="px-[10px] text-right">{formatCurrency(total)}</p>
            </div>
            {display &&
                <div>
                    {services?.map(item =>
                        <div
                            key={item?.name}
                            className="flex justify-between text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 w-full bg-[#F7F8F8] overflow-y-hidden"
                        >
                            <p className="px-[10px] py-[8px] flex items-center capitalize pl-[24px]">
                                {/* <Dot fill="#131515" className="w-[4px]" /> */}
                                {item?.name}
                            </p>
                            <p className="px-[10px] py-[8px] text-right ml-[8px]">{formatCurrency(item?.fee + item?.vat)}</p>
                        </div>
                    )}
                </div>
            }
        </>
    )
}

BillingInformation.propTypes = {
    data: PropTypes.object,
}
ServiceItem.propTypes = {
    title: PropTypes.string,
    total: PropTypes.number,
    lastItem: PropTypes.bool,
    services: PropTypes.array
}