import React from "react";
import PropTypes from 'prop-types';
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function RedirectIfStaff({ children }) {
  const { userType, authToken, institutionId } = useSelector(state => state.auth)
  let location = useLocation();
  // Redirect user to dashboard page if user is not an institution 
  if (userType === 'staff' && institutionId && authToken) return <Navigate to="/clinicals/patients" state={{ from: location }} replace />

  return children
}

RedirectIfStaff.propTypes = {
  children: PropTypes.element
};
