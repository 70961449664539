import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/Buttons';
import MedicationInfo from './MedicationInfo';
import Modal from 'components/Modals/Modal';
import EditMedication from './EditMedication';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';

const ViewMedication = ({ data, callback }) => {
  const [type, setType] = useState('');

  return (
    <div className="flex justify-between h-full flex-col">
      <MedicationInfo data={data} />
      {data.status !== 'completed' && data.status !== 'stopped' && (
        <div className="flex mt-1 space-x-4 justify-end">
          <Button
            className="w-fit h-10
              text-14 hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary  px-2 py-1"
            theme="transparent"
            onClick={() => setType('Stop')}
            name="Stop Medication"
          />
          <Button
            name={`${data.status === 'paused' ? 'Restart' : 'Pause'} Medication`}
            theme="blue"
            className="w-fit h-10 py-2 px-8"
            onClick={ () => data.status === 'paused' ? setType('Restart') : setType('Pause')}
          />
        </div>
      )}
      {type && (
        <Modal
          closeModal={() => setType('')}
          icon={type && <ArrowBack fill="#748181" className="w-2" />}
          styles="w-full md:w-[457px]"
          position="modal-right"
          variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
          title={`${type} Medication`}
        >
          <EditMedication data={data} setType={setType} callback={callback} action={type} />
        </Modal>
      )}
    </div>
  );
};

export default ViewMedication;
ViewMedication.propTypes = {
  data: PropTypes.object,
  callback: PropTypes.func,
};
