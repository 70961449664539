import Accordion from 'components/Accordion';
import FormWrapper from 'components/FormWrapper';
import Modal from 'components/Modals/Modal';
import { ReactComponent as Uncheck } from 'assets/icons/check-box-unselected.svg';
import { ReactComponent as Check } from 'assets/icons/check-box-selected.svg';
import { defaultFilter } from 'mocks/patient';
import React, { useEffect, useMemo, useState } from 'react';
import { useAuthState } from 'store/modules/auth';
import PropTypes from 'prop-types';
import useSpecialty from 'hooks/fetch/useSpecialty';
import useTable from 'hooks/useTable';
import { Button } from 'components/Buttons';
import useRoles from 'hooks/fetch/useRoles';
import { useSelector } from 'react-redux';
import { staff_status } from 'mocks/settings';
import useProviders from 'hooks/fetch/useProviders';

const Filter = ({ closeModal }) => {
  const [filterParams, setFilterParams] = useState({
    location: '',
    status: '',
    specialty: 'all',
    role: '',
  });

  const { user, institutionId } = useAuthState();

  const specialtyList = user?.specialties;
  const { get_Specialties } = useSpecialty();
  const { get_roles } = useRoles();
  const { get_institution_providers, loading } = useProviders();
  const { params } = useTable();
  const { roles } = useSelector((state) => state.roles);

  useEffect(() => {
    const newParams = {
      ...params,
      limit: 1000,
    };
    get_Specialties({ ...newParams });
    get_roles({ id: institutionId, params: newParams });
  }, []);

  const filterStaff = () => {
    closeModal();
    get_institution_providers({
      id: `${institutionId}/providers`,
      params: {
        ...params,
        ...filterParams,
        specialty: filterParams.specialty === 'all' ? '' : filterParams.specialty
      },
    });
  };
  const locationList = useMemo(
    () =>
      user?.healthCareFacilities?.map((item, index) => {
        return {
          id: index,
          name: `${item?.address}, ${item.city}, ${item.state}, ${item.country}`,
        };
      }),
    [user?.healthCareFacilities],
  );

  return (
    <Modal
      closeModal={closeModal}
      styles="w-full md:w-[457px]"
      position="modal-right"
      title="Filter"
      variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
    >
      <FormWrapper className="relative">
        <div className="modal-right-body">
          <Accordion
            display={false}
            title="By Location"
            className="border-b pb-4 border-neutral_stroke_2 mb-4"
            variant="mt-3"
            titleClassName="text-12"
          >
            <div className="bg-[#F7F8F8] p-4 rounded-lg overflow-scroll">
              {[...defaultFilter, ...locationList]?.map((item) => (
                <div
                  key={item.name}
                  className={`${item.name === 'all' ? 'border-b border-dashed border-neutral_stroke_2 py-2' : 'py-3'
                    } flex`}
                  onClick={() => setFilterParams({ ...filterParams, location: item.name })}
                >
                  <div data-testid={item.name} className="cursor-pointer hide_tap">
                    {filterParams.location === item.name ? <Check /> : <Uncheck />}
                  </div>
                  <p className="text-14 text-neutral_black font-campton_r ml-[8px] capitalize">{item.name}</p>
                </div>
              ))}
            </div>
          </Accordion>
          <Accordion
            display={false}
            title="By Specialty"
            className="border-b pb-4 border-neutral_stroke_2 mb-4"
            variant="mt-3"
            titleClassName="text-12"
          >
            <div className="bg-[#F7F8F8] p-4 rounded-lg overflow-scroll">
              {[...defaultFilter, ...specialtyList]?.map((item) => (
                <div
                  key={item.name}
                  className={`${item.name === 'all' ? 'border-b border-dashed border-neutral_stroke_2 py-2' : 'py-3'
                    } flex`}
                  onClick={() => setFilterParams({ ...filterParams, specialty: item.name })}
                >
                  <div data-testid={item.name} className="cursor-pointer hide_tap">
                    {filterParams.specialty === item.name ? <Check /> : <Uncheck />}
                  </div>
                  <p className="text-14 text-neutral_black font-campton_r ml-[8px] capitalize">{item.name}</p>
                </div>
              ))}
            </div>
          </Accordion>
          <Accordion
            display={false}
            title="By Role"
            className="border-b pb-4 border-neutral_stroke_2 mb-4"
            variant="mt-3"
            titleClassName="text-12"
          >
            <div className="bg-[#F7F8F8] p-4 rounded-lg overflow-scroll">
              {roles?.Roles?.map((item) => (
                <div
                  key={item.name}
                  className={`${item.name === 'all' ? 'border-b border-dashed border-neutral_stroke_2 py-2' : 'py-3'
                    } flex`}
                  onClick={() => setFilterParams({ ...filterParams, role: item.name })}
                >
                  <div data-testid={item.name} className="cursor-pointer hide_tap">
                    {filterParams.role === item.name ? <Check /> : <Uncheck />}
                  </div>
                  <p className="text-14 text-neutral_black font-campton_r ml-[8px] capitalize">{item.name}</p>
                </div>
              ))}
            </div>
          </Accordion>
          <Accordion
            display={false}
            title="By Status"
            className="border-b pb-4 border-neutral_stroke_2 mb-4"
            variant="mt-3"
            titleClassName="text-12"
          >
            <div className="bg-[#F7F8F8] p-4 rounded-lg overflow-scroll">
              {staff_status?.map((item) => (
                <div
                  key={item.name}
                  className={`${item.name === 'all' ? 'border-b border-dashed border-neutral_stroke_2 py-2' : 'py-3'
                    } flex`}
                  onClick={() => setFilterParams({ ...filterParams, status: item.value })}
                >
                  <div data-testid={item.value} className="cursor-pointer hide_tap">
                    {filterParams.status === item.value ? <Check /> : <Uncheck />}
                  </div>
                  <p className="text-14 text-neutral_black font-campton_r ml-[8px] capitalize">{item.name}</p>
                </div>
              ))}
            </div>
          </Accordion>
        </div>
        <div className="modal-right-button-container">
          <Button
            className="w-20 h-10 mr-[16px]
              text-14 hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary  px-2 py-1"
            theme="transparent"
            type='button'
            onClick={closeModal}
            name="Cancel"
          />
          <Button name="Filter" theme="blue" loading={loading} onClick={filterStaff} className="w-[88px] h-10" />
        </div>
      </FormWrapper>
    </Modal>
  );
};

export default Filter;
Filter.propTypes = {
  closeModal: PropTypes.func,
};
