import React, { useContext } from 'react';
import { VitalSignItem } from 'components/Cards';
import { ReactComponent as Blood } from 'assets/icons/drop.svg';
import { ReactComponent as Temperature } from 'assets/icons/temperature.svg';
import { ReactComponent as StickFigure } from 'assets/icons/stick-figure.svg';
import { ReactComponent as HeartRate } from 'assets/icons/heart-rate.svg';
import { ReactComponent as Oxygen } from 'assets/icons/oxygen.svg';
import { ReactComponent as Ruler } from 'assets/icons/ruler.svg';
import { ReactComponent as Stethoscope } from 'assets/icons/stethoscope.svg';
import { SlideUp } from 'components/Animations';
import { VisitDetailsContext } from 'context';

export default function ViewVitals() {
  const { singleVisit, progressNote } = useContext(VisitDetailsContext)
  const vitals = singleVisit?.vitals || progressNote?.vitals[0]

  return (
    <>
      <h2 className="text-16 sm:text-18 text-[#0D0F11] font-campton_m">Vital Signs</h2>
      <SlideUp>
        <div className="grid lg:grid-cols-3 grid-cols-2 gap-6">
          <VitalSignItem
            name="Blood Pressure"
            value={vitals?.sysBloodPressure && `${vitals?.sysBloodPressure}/${vitals?.diaBloodPressure}mmHg`}
            icon={<Blood />}
          />
          <VitalSignItem
            className="md:border-r border-neutral_stroke_2"
            name="Heart Rate"
            value={vitals?.heartRate && `${vitals?.heartRate}bpm`}
            icon={<HeartRate />}
          />
          <VitalSignItem
            className="border-r-0"
            name="Respiratory Rate"
            value={vitals?.respiratoryRate && `${vitals?.respiratoryRate}bpm`}
            icon={<Stethoscope />}
          />
          <VitalSignItem
            className="lg:border-r border-neutral_stroke_2"
            name="Temperature"
            value={vitals?.temperature && `${vitals?.temperature}°C`}
            icon={<Temperature />}
          />
          <VitalSignItem
            name="Oxygen Saturation"
            value={vitals?.oxygenSaturation && `${vitals?.oxygenSaturation}%`}
            icon={<Oxygen />}
          />
          <VitalSignItem
            className="border-r-0"
            name="Height"
            value={vitals?.height && `${vitals?.height}meters`}
            icon={<Ruler />}
          />
          <VitalSignItem
            name="Weight"
            value={vitals?.weight && `${vitals?.weight}kg`}
            icon={<StickFigure fill="#DF3939" />}
          />
          <VitalSignItem
            className="lg:border-r border-neutral_stroke_2"
            name="BMI"
            value={vitals?.bmi && Number(vitals?.bmi)?.toFixed(2)}
            icon={<StickFigure fill="#F59E0B" />}
          />
        </div>
      </SlideUp>
    </>
  );
}
