import React, { useState } from "react";
import PropTypes from "prop-types"
import { ReactComponent as Close } from "assets/icons/close-circle.svg";
import Avatar from "components/Avatar/Avatar";
import Modal from "components/Modals/Modal";
import { userInitals } from "utils/ImagePlaceholder";
import usePatient from "hooks/fetch/usePatient";
import { useDispatch } from "react-redux";
import { notify } from "store/modules/global";
import Accordion from "components/Accordion";

export default function CareTeam({ careTeam, admittedBy, patientId, callback }) {
    const dispatch = useDispatch()
    const [display, setDisplay] = useState(false)
    const { remove_staff_from_care_team, loading } = usePatient()

    const removeStaff = (staffId) => {
        if (staffId === admittedBy) return dispatch(notify({ status: 'error', message: 'Cannot remove primary care staff!!!' }))
        remove_staff_from_care_team({ patientId, staffId })
            .then(() => callback())
    }

    return (
        <Accordion title="Care Team">
            <div className="text-14 font-campton_r rounded-[16px] py-[16px] overflow-hidden shrink-0">
                <div className="flex justify-end mb-[16px]">
                    {/* <p className="text-neutral_black">Care Team</p> */}
                    <p className="text-brand_primary border-b border-b-brand_primary cursor-pointer" onClick={() => setDisplay(true)}>Manage team</p>
                </div>
                <div className="h-full overflow-auto">
                    {careTeam?.map((item, i) =>
                        <div
                            key={item?._id}
                            className={`
                        flex items-center border-b border-b-neutral_stroke_2
                        ${i === 0 ? 'pb-[16px]' : 'py-[16px]'}
                        `}
                        >
                            <Avatar
                                url={item?.profilePic}
                                width="32px"
                                height="32px"
                                //alt={singleVisit?.provider?.firstName?.split('')[0]}
                                initials={userInitals(item?.firstName, item?.lastName)}
                                className="bg-brand_secondary w-[32px] h-[32px] mr-[8px] shrink-0"
                            />
                            <div>
                                <p className="text-neutral_black capitalize">{item?.firstName} {item?.lastName}</p>
                                <p className="text-12 text-neutral_body">{item?.specialty || 'N/A'} | {item?.GPINumber}</p>
                            </div>
                        </div>
                    )}
                </div>
                {display && (
                    <Modal
                        closeModal={() => setDisplay(false)}
                        styles="w-full md:w-[457px]"
                        position="modal-right"
                        variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
                        title="Manage Care Team"
                    >
                        <div className="modal-right-body">
                            {careTeam?.map((item, i) =>
                                <div
                                    key={item?._id}
                                    className={`flex justify-between items-center py-[16px] ${i !== 2 ? 'border-b border-b-neutral_stroke_2' : ''}`}
                                >
                                    <div className="flex items-center">
                                        <Avatar
                                            url={item?.profilePic}
                                            width="32px"
                                            height="32px"
                                            //alt={singleVisit?.provider?.firstName?.split('')[0]}
                                            initials={userInitals(item?.firstName, item?.lastName)}
                                            className="bg-brand_secondary w-[32px] h-[32px] mr-[8px] shrink-0"
                                        />
                                        <div>
                                            <p className="text-neutral_black capitalize">{item?.firstName} {item?.lastName}</p>
                                            <p className="text-12 text-neutral_body">{item?.specialty || 'N/A'} | {item?.GPINumber}</p>
                                        </div>
                                    </div>
                                    <Close
                                        fill="red"
                                        className="cursor-pointer"
                                        data-testid={item?._id}
                                        onClick={() => !loading ? removeStaff(item?._id) : {}}
                                    />
                                </div>
                            )}
                        </div>
                    </Modal>
                )}
            </div>
        </Accordion>
    )
}

CareTeam.propTypes = {
    careTeam: PropTypes.array,
    patientId: PropTypes.string,
    callback: PropTypes.func,
    admittedBy: PropTypes.string
}