import React, { useContext, useEffect, useState } from 'react'
import { SlideUp } from 'components/Animations';
import Accordion from 'components/Accordion';
import { ReactComponent as Test } from "assets/icons/test.svg"
import { ReactComponent as File } from "assets/icons/file.svg"
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg"
import Table from 'components/Table';
import { formatDate } from 'utils/formatDate';
import { clinical_assessment_header } from 'mocks/clinicals/visits';
import ViewTestResult from 'components/Forms/ViewTestResult';
import Diagnosis from './Forms/Diagnosis';
import { VisitDetailsContext } from 'context';
import useLabTest from 'hooks/fetch/useLabTest';
import useTable from 'hooks/useTable';

export default function ClinicalAssessment() {
    const [selectedResult, setSelectedResult] = useState()
    const { get_visit_tests, loading: isFetchingVisitTests } = useLabTest()
    const { singleVisit, progressNote, getSingleProgressNoteProxy, getSingleVisit } = useContext(VisitDetailsContext)
    const [labTest, setLabTest] = useState([])
    const { params } = useTable()
    const primaryDiagnosis = singleVisit?.primaryDiagnosis || progressNote?.primaryDiagnosis
    const secondaryDiagnosis = singleVisit?.secondaryDiagnosis || progressNote?.secondaryDiagnosis
    const completedTest = labTest?.labTest?.filter((test) => test.requestStatus === 'completed')
    const type = singleVisit?._id ? "visit" : "progressNote"
    const typeId = singleVisit?._id || progressNote?._id

    useEffect(() => {
        get_visit_tests({ id: singleVisit?._id || progressNote?.visit, params })
            .then(res => setLabTest(res?.data))
    }, [params.page, params.limit])

    return (
        <>
            <div className='flex items-center justify-between mb-[16px]'>
                <h2 className="text-16 sm:text-18 text-[#0D0F11] font-campton_m">Clinical Assessment</h2>
            </div>
            <SlideUp>
                <Accordion
                    title="Order Result"
                    titleIcon={<Test fill='#143566' className='mr-[11px]' />}
                >
                    <div className="pt-[24px]">
                        <Table
                            headers={clinical_assessment_header}
                            data={completedTest}
                            hasFilter={false}
                            totalCount={completedTest?.count || completedTest?.total}
                            perPage={completedTest?.limit}
                            currentPage={completedTest?.page}
                            loading={isFetchingVisitTests}
                            hasPagination={false}
                        >
                            <>
                                {completedTest?.map((item) =>
                                    <tr
                                        data-testid={item?._id}
                                        key={item?._id}
                                        className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
                                        onClick={() => setSelectedResult(item)}
                                    >
                                        <td className="px-[10px] py-[8px] flex items-center w-[260px]">
                                            <File className="mr-[10px] shrink-0" />
                                            <p title={item?.testType} className='truncate'>{item?.testType}</p>
                                        </td>
                                        <td
                                            title={`${item?.requestedBy?.firstName} ${item?.requestedBy?.lastName}`}
                                            className="px-[10px] py-[8px] capitalize truncate max-w-[140px]"
                                        >
                                            {item?.requestedBy?.firstName} {item?.requestedBy?.lastName}
                                        </td>
                                        <td className="px-[10px] py-[8px] capitalize">{formatDate(item?.dateRequested)}</td>
                                        <td className="px-[10px] py-[8px] w-[20px]"><ArrowRight fill="#A9B2B2" /></td>
                                    </tr>
                                )}
                            </>
                        </Table>
                    </div>
                </Accordion>
                <Diagnosis
                    title="Primary Diagnosis"
                    id='primaryDiagnosis'
                    initialValue={primaryDiagnosis?.primaryDiagnosis}
                    data={primaryDiagnosis}
                    type={type}
                    typeId={typeId}
                    callback={getSingleProgressNoteProxy || getSingleVisit}
                />
                <Diagnosis
                    title="Secondary Diagnosis"
                    id='secondaryDiagnosis'
                    initialValue={secondaryDiagnosis?.secondaryDiagnosis}
                    data={secondaryDiagnosis}
                    type={type}
                    typeId={typeId}
                    callback={getSingleProgressNoteProxy || getSingleVisit}
                />
            </SlideUp>
            {selectedResult &&
                <ViewTestResult
                    title="Order Result"
                    test={selectedResult}
                    closeModal={() => setSelectedResult()}
                />
            }
        </>
    )
}
