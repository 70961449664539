import Avatar from "components/Avatar/Avatar"
import React from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { formatDate } from "utils/formatDate"
import { formatTwelveHour } from "utils/formatTime"
import MoreOptions from "components/Options/MoreOptions"
import { ReactComponent as View } from "assets/icons/eye-open.svg"
import { ReactComponent as Discharge } from "assets/icons/logout.svg"
import Status from "components/Status"
import usePermission from "hooks/usePermission"

export default function InPatientTableRow({ item, discharge, status }) {
    const navigate = useNavigate()
    const { hasPermission } = usePermission()
    const discharged = status === 'discharged'

    const options = [
        {
            id: 1,
            icon: <View className="mr-[8px]" />,
            value: 'view',
            name: 'View information',
            method: () => navigate(`/clinicals/in-patients/${item?._id}`),
            permission: true
        },
        {
            id: 2,
            name: 'Discharge patient',
            value: 'discharge',
            icon: <Discharge fill="#000000" className="mr-[8px] w-[16px] h-[16px]" />,
            method: discharge,
            permission: hasPermission('Inpatient management', 'Discharge patient') && status !== "discharged"
        }
    ]

    const severity = () => {
        switch (item?.severity?.toLowerCase()) {
            case 'highrisk':
                return <Status status="High risk" className="text-error bg-[#FBE5E5]" />
            case 'lowrisk':
                return <Status status="Normal" className="text-green bg-[#E6F9EE]" />
            case 'novitals':
                return <Status status="No vitals" className="text-neutral_black bg-light_blue" />
            default:
                return
        }
    }

    return (
        <tr
            data-testid={item?._id}
            key={item?._id}
            className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer"
            onClick={() => navigate(`/clinicals/in-patients/${item?._id}`)}
        >
            <td className="px-[10px] py-[8px] flex items-center capitalize">
                <Avatar
                    url={item?.patientInfo?.profilePic}
                    width="32px"
                    height="32px"
                    alt={item?.patientInfo?.firstName?.split('')[0]}
                    initials={`
                    ${item?.patientInfo?.firstName?.split('')[0] || ''}${item?.patientInfo?.lastName?.split('')[0] || ''}
                    `}
                    className="mr-[15px] w-[32px] h-[32px] shrink-0"
                />
                <div className="w-[170px]">
                    <p className="text-14 text-neutral_black font-campton_r truncate">
                        {item?.patientInfo?.firstName} {item?.patientInfo?.lastName}
                    </p>
                    <p className="text-12 text-neutral_body font-campton_r truncate">
                        <span className="lowercase">{item?.patientInfo?.emailAddress}</span> | {item?.patientInfo?.gNumber}
                    </p>
                </div>
            </td>
            <td className="px-[10px] py-[8px] truncate max-w-[170px]">{item?.admissionDiagnosis}</td>
            <td className="px-[10px] py-[8px]">
                <p className="w-max">
                    {formatDate(discharged ? item?.updatedAt : item?.createdAt)} {formatTwelveHour(discharged ? item?.updatedAt : item?.createdAt)}
                </p>
            </td>
            <td title={`${item?.location} | ${item?.roomNumber}`} className="px-[10px] py-[8px] capitalize">
                <span className="truncate block w-[150px]">{item?.location} | {item?.roomNumber}</span>
            </td>
            <td className="px-[10px] py-[8px]">{item?.lengthOfStay} day(s)</td>
            <td className="px-[10px] py-[8px]">{severity()}</td>
            <td className="px-[10px] py-[11px] w-[70px]">
                <MoreOptions position="right-[8px] w-[200px]">
                    <>
                        {options?.filter(o => o.permission)?.map((option) =>
                            <button
                                key={option?.id}
                                type="button"
                                data-testid={option?.value}
                                onClick={() => option.method(item)}
                                className={`
                                    cursor-pointer w-full flex items-center py-[9.5px] hover:bg-[#dfe2e23b]
                                    text-neutral_body hover:text-neutral_black hover:pl-[8px] rounded-[50px]
                                `}
                            >
                                {option.icon}
                                {option?.name}
                            </button>
                        )}
                    </>
                </MoreOptions>
            </td>
        </tr>
    )
}

InPatientTableRow.propTypes = {
    item: PropTypes.object,
    discharge: PropTypes.func,
    status: PropTypes.string
};