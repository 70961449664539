import { useState } from "react"
import useMakeRequest from "hooks/useMakeRequest"
import useTryCatch from "hooks/useTryCatch"
import {
    createService,
    createServiceCategory,
    deleteService,
    deleteServiceCategory,
    getServiceCategories,
    getServiceDetails,
    getServices,
    getServicesByInstitution,
    updateService,
    updateServiceCategory
} from "store/modules/services/actions"

export default function useService() {
    const { makeRequest, makeAnonymousRequest } = useMakeRequest()
    const [loading, setLoading] = useState(false)
    const { tryCatch } = useTryCatch()

    const get_services = (params) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getServices({ params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const create_service = (formData, callback) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: createService({ payload: formData }), callback })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const update_service = (id, formData, callback) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: updateService({ pathname: id, payload: formData }), callback })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const delete_service = (id, callback) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: deleteService({ pathname: id }), callback })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_service_details = (id) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getServiceDetails({ pathname: id }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const create_service_category = (formData, callback) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: createServiceCategory(formData), callback })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const update_service_category = (id, formData, callback) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: updateServiceCategory({ pathname: id, payload: formData }), callback })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_service_categories = (params) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getServiceCategories({ params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const delete_service_category = (id, callback) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: deleteServiceCategory({ pathname: id }), callback })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const create_bill = ({ payload, callback }) => {
        const url = `/payments/bill`
        return tryCatch(async () => {
            setLoading(true)
            return makeAnonymousRequest({ url, method: 'post', payload, callback })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_institution_services = (params) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getServicesByInstitution({ params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    return {
        create_service,
        create_bill,
        get_services,
        loading,
        create_service_category,
        get_service_categories,
        update_service_category,
        update_service,
        delete_service,
        delete_service_category,
        get_service_details,
        get_institution_services
    }
}