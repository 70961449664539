import React from "react"
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

export default function Search({
    id, name, placeholder, value, label, onChange, readOnly,
    defaultValue, pattern, inputMode
}) {
    return (
        <div className="relative mb-[3.5px]">
            <input
                id={id}
                name={name}
                type="search"
                label={label}
                placeholder={placeholder}
                value={value}
                defaultValue={defaultValue}
                inputMode={inputMode}
                pattern={pattern}
                data-testid="search"
                aria-labelledby={id}
                onChange={onChange}
                readOnly={readOnly}
                className={`
                border-neutral_stroke h-[40px] pl-[34px] pr-[16px] text-neutral_black
                text-14 md:min-w-[288px] w-full outline-0 border font-campton_r font-normal hide_tap
                rounded-[100px] focus:border-brand_primary focus:border bg-neutral_white
                `}
            />
            <div
                className="flex items-center absolute top-0 left-[13.48px] cursor-pointer hide_tap h-full"
            >
                <SearchIcon />
            </div>
        </div>
    )
}

Search.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string,
    readOnly: PropTypes.bool,
    pattern: PropTypes.string,
    inputMode: PropTypes.string,
    showError: PropTypes.bool,
    helperText: PropTypes.string
};