import React from "react";
import PropTypes from "prop-types"
import { ReactComponent as Clock } from "assets/icons/clock.svg";
// import { ReactComponent as Edit } from "assets/icons/edit1.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { ReactComponent as Note } from "assets/icons/note2.svg";
import { formatDate } from "utils/formatDate";
import { formatTwelveHour } from "utils/formatTime";
import { checkOffDays, checkWorkingDays } from "utils/convertDays";

export default function Overview({ data, deleteFunc, setEditShift }) {
    return (
        <section className="md:flex border-b border-b-neutral_stroke_2 p-[24px] overflow-x-scroll no-scrollbar">
            <div
                className="
                bg-brand_primary rounded-[16px] w-full md:w-[60%] mr-[24px] mb-[16px] md:mb-0 relative
                bg-[url('/src/assets/svg/frame.svg')] bg-no-repeat bg-cover"
            >
                <div className="w-full h-full p-[24px] flex flex-col justify-between">
                    <div>
                        <div className="flex justify-between items-center">
                            <div className="bg-[#EFF7FB1F] w-[40px] h-[40px] rounded-full flex justify-center items-center">
                                <Clock className="w-[20px]" fill="white" />
                            </div>
                            <div className="flex">
                                {/* <Edit className="w-[40px] h-[40px] mr-[16px] shrink-0 cursor-pointer" onClick={() => setEditShift(true)} /> */}
                                <Delete
                                    data-testid="delete-shift"
                                    className="w-[40px] h-[40px] shrink-0 cursor-pointer"
                                    onClick={deleteFunc}
                                />
                            </div>
                        </div>
                        <div className="py-[16px] text-neutral_white">
                            <p className="text-16 font-campton_m mb-[4px] capitalize">{data?.shiftName}</p>
                            <p className="text-12 font-campton_r leading-[18px]">{data?.note}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-3 border-t border-t-[#eceeee2f] pt-[16px]">
                        <div className="pr-[16px] border-r border-r-[#eceeee2f]">
                            <p className="text-12 font-campton_r text-[#C7E5EF] mb-[4px]">Shift Time</p>
                            <p className="text-14 font-campton_r text-neutral_white">{data?.startTime} - {data?.endTime}</p>
                        </div>
                        <div className="px-[16px] border-r-0 sm:border-r border-r-[#eceeee2f]">
                            <p className="text-12 font-campton_r text-[#C7E5EF] mb-[4px]">Date Created</p>
                            <p className="text-14 font-campton_r text-neutral_white">{formatDate(data?.startDate)} - {formatDate(data?.endDate)}</p>
                        </div>
                        <div className="mt-[24px] md:mt-0 md:px-[16px]">
                            <p className="text-12 font-campton_r text-[#C7E5EF] mb-[4px]">Last Modified</p>
                            <p className="text-14 font-campton_r text-neutral_white">
                                {formatDate(data?.updatedAt)}, {formatTwelveHour(data?.updatedAt)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border border-neutral_stroke_2 rounded-[16px] w-full md:w-[40%] p-[24px]">
                <Note />
                <div className="pt-[16px] border-b border-b-[#eceeee2f] text-neutral_white mb-[12px] sm:mb-[39px]">
                    <p className="text-12 text-neutral_body font-campton_r mb-[4px]">Shift Duration</p>
                    <p className="text-14 text-neutral_black font-campton_r">{formatDate(data?.startDate)} - {formatDate(data?.endDate)}</p>
                </div>
                <div className="border border-neutral_stroke_2 rounded-[8px] grid grid-cols-2 text-12 font-campton_r">
                    <div className="px-[16px] py-[8px] border-r border-r-neutral_stroke_2">
                        <p className="text-neutral_body">Working Days</p>
                        <p className="text-neutral_black capitalize">{checkWorkingDays(data?.workingDays)}</p>
                    </div>
                    <div className="px-[16px] py-[8px]">
                        <p className="text-neutral_body">Off Days</p>
                        <p className="text-neutral_black capitalize">{checkOffDays(data?.workingDays)}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

Overview.propTypes = {
    data: PropTypes.object,
    deleteFunc: PropTypes.func,
    setEditShift: PropTypes.func
}