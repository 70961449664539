import { useState } from "react"
import useMakeRequest from "hooks/useMakeRequest"
import useTryCatch from "hooks/useTryCatch"
import { createVitals, getVital, getVitals } from "store/modules/clinicals/actions"

export default function useVitals() {
    const { makeRequest, makeAnonymousRequest } = useMakeRequest()
    const [loading, setLoading] = useState(false)
    const { tryCatch } = useTryCatch()

    const get_vitals = ({ id, params }) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getVitals({ pathname: id, params }), alert: false })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    }

    const get_vital = (id) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: getVital({ pathname: id }), alert: false })
                .then(res => res)
                .finally(() => setLoading(false))
        })
    }

    const create_vitals = (formData) => {
        return tryCatch(async () => {
            setLoading(true)
            return makeRequest({ action: createVitals({ payload: formData }) })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const get_patient_vitals_history = ({ patientId, type }) => {
        const url = `/patient-profile/vitals-history/${patientId}?type=${type}`
        return tryCatch(async () => {
          setLoading(true);
          return makeAnonymousRequest({ url, alert: false })
            .then((res) => res)
            .finally(() => setLoading(false));
        })
      }

    return {
        get_vitals,
        get_vital,
        create_vitals,
        get_patient_vitals_history,
        loading
    }
}