import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SlideUp } from "components/Animations";
import Navbar from "components/Navbar";
import SubNavbar from "components/SubNavbar";
import Avatar from "components/Avatar/Avatar";
import Edit from "./Components/Edit";
import View from "./Components/View";
import useLabTest from "hooks/fetch/useLabTest";
import Spinner from "components/Spinner";
import { LabTestDetailsContext } from "context";
import Restricted from "HOC/Restricted";

export default function LabTestDetails() {
    const navigate = useNavigate()
    const location = useLocation()
    const [tab, setTab] = useState('view')
    const [labTest, setLabTest] = useState()
    const { get_lab_test } = useLabTest()
    const labTestId = location?.pathname?.split('/')[3]

    const getTestDetails = () => {
        get_lab_test(labTestId)
            .then(res => setLabTest(res.data || undefined))
    }

    useEffect(() => {
        getTestDetails()
    }, [])

    return (
        <>
            <Navbar title="Lab Test Management" />
            <SubNavbar
                previous="Lab Test Management"
                current={`${labTest?.patient?.firstName || ''} ${labTest?.patient?.lastName || ''}`}
                onClick={() => navigate('/clinicals/lab-test')}
            />
            <Restricted module="Lab Test" permission="View">
                <>
                    <SlideUp>
                        {!labTest ? <Spinner height="h-[80vh]" /> :
                            <>
                                <div className="p-[24px] lg:flex gap-[24px]">
                                    <div className="w-full">
                                        <p className="text-14 text-neutral_black font-campton_m mb-[8px]">Patient Information</p>
                                        <div className="border border-neutral_stroke_2 p-[16px] rounded-[8px] flex items-center">
                                            <Avatar
                                                url={labTest?.patient?.profilePic}
                                                width="80px"
                                                height="80px"
                                                alt={labTest?.patient?.firstName}
                                                initials={`${labTest?.patient?.firstName?.split('')[0] || ''}${labTest?.patient?.lastName?.split('')[0] || ''}`}
                                                className="w-[80px] h-[80px] border border-neutral_white mr-[8px]"
                                            />
                                            <div className="w-full">
                                                <div className="flex flex-wrap justify-between items-center w-full">
                                                    <p className="text-16 text-neutral_black font-campton_m capitalize">
                                                        {labTest?.patient?.firstName} {labTest?.patient?.lastName}
                                                    </p>
                                                    <p className="text-14 text-neutral_body font-campton_r">{labTest?.patient?.gNumber}</p>
                                                </div>
                                                <p className="text-14 text-neutral_body font-campton_r mb-[4px]">{labTest?.patient?.emailAddress}</p>
                                                <p className="text-14 text-neutral_body font-campton_r">
                                                    {labTest?.patient?.countryCode}{labTest?.patient?.phoneNumber}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-[20px] lg:mt-0 w-full">
                                        <p className="text-14 text-neutral_black font-campton_m mb-[8px]">Requested By</p>
                                        <div className="border border-neutral_stroke_2 p-[16px] rounded-[8px] flex items-center">
                                            <Avatar
                                                url={labTest?.requestedBy?.profilePic}
                                                width="80px"
                                                height="80px"
                                                alt={labTest?.requestedBy?.firstName}
                                                initials={`${labTest?.requestedBy?.firstName?.split('')[0] || ''}${labTest?.requestedBy?.lastName?.split('')[0] || ''}`}
                                                className="w-[80px] h-[80px] border border-neutral_white mr-[8px]"
                                            />
                                            <div className="w-full">
                                                <div className="flex flex-wrap justify-between items-center w-full">
                                                    <p className="text-16 text-neutral_black font-campton_m capitalize">
                                                        {labTest?.requestedBy?.firstName} {labTest?.requestedBy?.lastName}
                                                    </p>
                                                    <p className="text-14 text-neutral_body font-campton_r">{labTest?.requestedBy?.GPINumber}</p>
                                                </div>
                                                <p className="text-14 text-neutral_body font-campton_r mb-[4px]">{labTest?.requestedBy?.emailAddress}</p>
                                                <p className="text-14 text-neutral_body font-campton_r">
                                                    {labTest?.requestedBy?.countryCode}{labTest?.requestedBy?.phoneNumber}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <LabTestDetailsContext.Provider value={{ setTab, labTest, getTestDetails }}>
                                    {tab === 'view' ? <View /> : <Edit />}
                                </LabTestDetailsContext.Provider>
                            </>
                        }
                    </SlideUp >
                </>
            </Restricted>
        </>
    )
}