import { createSlice } from '@reduxjs/toolkit'
import { getProviderCalendar, getProviders } from './actions'

const initialState = {
  providers: {},
  providerCalendar: {},
  loading: false
}

export const providers = createSlice({
  name: 'providers',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getProviders.fulfilled, (state, action) => {
        state.providers = action?.payload?.data ?? {}
      })
      .addCase(getProviderCalendar.fulfilled, (state, action) => {
        state.providerCalendar = action?.payload?.data ?? {}
      })
  }
})

export default providers.reducer