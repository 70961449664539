import React from 'react';
import PropTypes from "prop-types"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function LineChart({ chartData, chartOptions, datasets }) {
    const defaultDatasets = [{
        data: chartData?.data,
        backgroundColor: "#fff9b320",
        borderColor: "#23CE6B",
        //borderWidth: 2,
        borderCapStyle: 'round',
        borderDashOffset: [10],
        //fill: true,
        lineTension: '0.4',
        pointBackgroundColor: '#23CE6B',
        pointRadius: 0,
        pointHitRadius: 10
    }]

    const defaultChartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        animation: { duration: 1000 },
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    }

    const data = {
        labels: chartData?.labels,
        datasets: datasets || defaultDatasets
    }

    return <Line data={data} options={defaultChartOptions || chartOptions} />
}

LineChart.propTypes = {
    chartData: PropTypes.object,
    chartOptions: PropTypes.object,
    datasets: PropTypes.array
}