import React from "react";
import PropTypes from "prop-types"
import { ReactComponent as Clock } from "assets/icons/clock.svg";
import { formatDate } from "utils/formatDate";
import { checkOffDays, checkWorkingDays } from "utils/convertDays";
import dayjs from "dayjs";

export default function Overview({ data, workingDays }) {
    return (
        <section
            className={`
            bg-brand_primary rounded-[16px] w-full
            relative bg-[url('/src/assets/svg/frame.svg')] bg-no-repeat bg-cover
            `}
        >
            <div className="w-full h-full p-[24px]">
                <div className="flex">
                    <div className="bg-[#EFF7FB1F] shrink-0 w-[40px] h-[40px] rounded-full flex justify-center items-center">
                        <Clock className="w-[20px]" fill="white" />
                    </div>
                    <div className="pb-[16px] text-neutral_white ml-[12px]">
                        <p className="text-16 font-campton_m mb-[4px] capitalize">{data?.shiftName}</p>
                        <p className="text-12 font-campton_r leading-[18px] ellipsis_2_lines">{data?.note}</p>
                    </div>
                </div>
                <div className="grid grid-cols-2 mt-[16px]">
                    <div className="pr-[16px] border-r border-r-[#eceeee2f]">
                        <p className="text-12 font-campton_r text-[#C7E5EF] mb-[4px]">Shift Duration</p>
                        <p className="text-14 font-campton_r text-neutral_white">
                            {dayjs(data?.startTime).format('hh:mm A')} - {dayjs(data?.endTime).format('hh:mm A')}
                        </p>
                    </div>
                    <div className="px-[16px]">
                        <p className="text-12 font-campton_r text-[#C7E5EF] mb-[4px]">Start Date & End Date</p>
                        <p className="text-14 font-campton_r text-neutral_white">{formatDate(data?.startDate)} - {formatDate(data?.endDate)}</p>
                    </div>
                </div>
                <hr className="my-[24px] border-0 border-b border-b-[#eceeee2f]" />
                <div className="grid grid-cols-2 mt-[16px]">
                    <div className="pr-[16px] border-r border-r-[#eceeee2f]">
                        <p className="text-12 font-campton_r text-[#C7E5EF] mb-[4px]">Working Days</p>
                        <p className="text-14 font-campton_r text-neutral_white capitalize">{checkWorkingDays(workingDays)}</p>
                    </div>
                    <div className="px-[16px]">
                        <p className="text-12 font-campton_r text-[#C7E5EF] mb-[4px]">Off Days</p>
                        <p className="text-14 font-campton_r text-neutral_white capitalize">{checkOffDays(workingDays)}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

Overview.propTypes = {
    data: PropTypes.object,
    workingDays: PropTypes.array
}