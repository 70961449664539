export const shift_list_header = ['Shift Name', 'Start Date', 'End Date', 'Assigned Staff', '']

export const shift_list_data = {
    "page": "1",
    "perPage": "20",
    "count": 2,
    'shifts': [
        {
            id: '0',
            name: 'Doctor_Morning Shift',
            start: '2023-05-16T00:42:36.029Z',
            end: '2023-05-18T00:42:36.029Z',
            assignedStaff: 'Gabriel'
        },
        {
            id: '1',
            name: 'Nurse_Morning Shift',
            start: '2023-05-19T00:42:36.029Z',
            end: '2023-05-20T00:42:36.029Z',
            assignedStaff: 'Inyamah'
        }
    ]
}

export const swap_requests_list_header = ['Shift Name', 'Date Requested', 'Status', '']

export const swap_requests_list_data = {
    "page": "1",
    "perPage": "20",
    "count": 2,
    'swapRequests': [
        {
            id: '0',
            name: 'Doctor_Morning Shift',
            role: 'Doctor',
            dateRequested: '2023-05-17T00:42:36.029Z',
            status: 'pending'
        },
        {
            id: '1',
            name: 'Nurse_Morning Shift',
            role: 'Inyamah',
            dateRequested: '2023-05-18T00:42:36.029Z',
            status: 'pending'
        }
    ]
}