import Button from 'components/Buttons/Button';
import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import React, { useState } from 'react';
import { ReactComponent as PasswordUncheck } from 'assets/icons/password-uncheck.svg';
import { ReactComponent as PasswordCheck } from 'assets/icons/password-check.svg';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/fetch/useAuth';
import { setUserDetails, useAuthState } from 'store/modules/auth';
import { useAppDispatch } from 'hooks/useReduxHook';
import PropTypes from 'prop-types';
import Modal from 'components/Modals/Modal';
import LoginSuccess from 'components/Modals/LoginSuccess';

const ResetPasswordForm = ({ params = {} }) => {
  const [formData, setFormData] = useState({
    password: '',
    confirm_password: '',
  });
  const [showModal, setShowModal] = useState(false);
  //Variables
  const { userType, invited } = params;
  //Hooks
  const navigate = useNavigate();
  const { create_account_providers, create_account_institution, loading, change_password, complete_provider_profile } =
    useAuth();
  const dispatch = useAppDispatch();

  //Redux Store
  const { userDetails } = useAuthState();

  const passwordCondition = [
    {
      name: 'Minimum of 8 characters',
      condition: /[a-zA-Z0-9\W]{8,}/giu.test(formData.password),
    },
    {
      name: 'At least 1 uppercase',
      condition: /[A-Z]+/gu.test(formData.password),
    },
    {
      name: 'At least 1 lowercase',
      condition: /[a-z]+/gu.test(formData.password),
    },
    {
      name: 'At least 1 special character',
      condition: /\W|_/g.test(formData.password),
    },
  ];
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    if (userType === 'provider') {
      create_account_providers(
        {
          firstName: userDetails?.firstName,
          lastName: userDetails?.lastName,
          emailAddress: userDetails?.emailAddress,
          phoneNumber: userDetails?.phoneNumber,
          countryCode: userDetails?.countryCode,
          providedServiceType: userDetails?.providedServiceType,
          password: formData.password,
        },
        () => dispatch(setUserDetails({})),
      );
    } else if (userType === 'institution') {
      create_account_institution({ 
        businessEmailAddress: userDetails?.businessEmailAddress,
        businessName: userDetails?.businessName,
        phoneNumber: userDetails?.phoneNumber,
        countryCode: userDetails?.countryCode,
        providedServiceType: userDetails?.providedServiceType,
        password: formData.password }, 
        () => dispatch(setUserDetails({})));
    } else {
      complete_provider_profile({ ...userDetails, password: formData.password, 
        countryCode: userDetails?.countryCode,
       }, () => setShowModal(true));
    }
  };
  const disableBtn = () => {
    return formData?.password !== formData.confirm_password || !formData.confirm_password
  }
  return (
    <>
      <FormWrapper
        showBackbutton={userType ? true : false}
        onClick={() => navigate(-1)}
        description={
          !userType ? (
            <span className="text-neutral_body font-campton_r">
              Just one last step
              <br /> <span className="text-[#0F2E38] font-campton_sb">Create a new password!</span>
            </span>
          ) : (
            'Ok, let’s set up your profile'
          )
        }
        caption={!userType ? 'Password should be a minimum of 8 characters' : 'This is how you will appear on GabbiHealth'}
        className="sm:h-full md:mt-12 mt-8 w-full sm:w-[430px] mx-auto"
        onSubmit={userType || invited ? handleSubmit : () => change_password(formData)}
      >
        <div
          className={`${
            userType ? 'md:mt-10' : 'md:mt-12'
          } flex justify-center items-center flex-col w-full max-w-md mx-auto mt-8`}
        >
          <div className="w-full">
            <Input
              label="Create Password"
              type="password"
              id="password"
              name="password"
              readOnly={loading}
              value={formData?.password}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-wrap">
            {passwordCondition.map((data) => (
              <div
                key={data.name}
                className={`py-[1.5px] px-2.5 rounded-full mr-3 third-of-type:mr-0 mt-3 flex items-center space-x-1.5 ${
                  data.condition ? 'bg-light_green' : 'bg-primary_tint_50'
                }`}
              >
                {data.condition ? <PasswordCheck /> : <PasswordUncheck />}
                <p className={`${data.condition ? 'text-[#1BA154]' : 'text-neutral_body'} text-14 font-campton_r `}>
                  {data.name}
                </p>
              </div>
            ))}
          </div>

          <div className="mt-7 w-full">
            <Input
              label={userType ? 'Confirm Password' : 'Confirm New Password'}
              type="password"
              id="confirm_password"
              name="confirm_password"
              readOnly={loading}
              value={formData?.confirm_password}
              onChange={handleChange}
            />
          </div>
          {userType ? (
            <div className="w-full mt-6">
              <p className="text-14 text-center mb-6 w-[90%] mx-auto text-neutral_black font-campton_r">
                <span>By creating your account you agree to GabbiHealth </span>
                <a className="text-brand_secondary font-campton_m cursor-pointer" href='/terms-and-condition?type=terms of use' target='_blank'>
                  Terms of Use
                </a> and
                <a 
                  className="text-brand_secondary font-campton_m cursor-pointer ml-1" href='/terms-and-condition?type=privacy policy' target='_blank'>
                  Privacy Policy
                </a>
              </p>
              <Button name="Create account" theme="blue" className="w-full h-12" loading={loading} 
              disabled={disableBtn()}
              />
              <Button
                className="py-2 w-full border border-neutral_stroke_2 flex justify-center items-center rounded-full mt-6 h-12 cursor-pointer hover:bg-orange_100 hover:border-[0.5px] hover:border-brand_secondary hover:text-brand_secondary text-neutral_body"
                onClick={() => navigate('/')}
                data-testid="test-login"
              >
                <p className=" font-campton_r">
                  Have an account? <span className="text-brand_secondary font-campton_m">Login</span>
                </p>
              </Button>
            </div>
          ) : (
            <Button
              name={`${invited ? 'Create account' : 'Save'}`}
              theme="blue"
              className="w-full h-12 mt-10"
              disabled={disableBtn()}
              loading={loading}
            />
          )}
        </div>
      </FormWrapper>
      {showModal && (
        <Modal
          variant="rounded-[100px] bg-[#274572] py-2 px-4 text-neutral_white font-campton_sb text-center"
          styles="md:w-[520px] w-[320px] flex flex-col items-center justify-center bg-neutral_white md:p-16 px-4 py-10 rounded-3xl"
          authModal={true}
        >
          <LoginSuccess />
        </Modal>
      )}
    </>
  );
};

export default ResetPasswordForm;
ResetPasswordForm.propTypes = {
  params: PropTypes.object,
};
