import React, { useMemo, useState } from 'react';
import { Input } from 'components/Inputs';
import FormWrapper from 'components/FormWrapper';
import useAuth from 'hooks/fetch/useAuth';
import PropTypes from 'prop-types';
import { useAuthState } from 'store/modules/auth';
import { userInitals } from 'utils/ImagePlaceholder';
import { ReactComponent as Dot } from 'assets/svg/Ellipse 4.svg';
import { Button } from 'components/Buttons';
import useValidateFormData from 'hooks/useValidateFormData';
import Phone from 'components/Inputs/PhoneInput';

const ContactPersonInfo = ({ callback }) => {
  const { validateFormData } = useValidateFormData()
  const { user, institutionId } = useAuthState();
  const [contactInfoArrayData, setContactInfoArrayData] = useState([]);
  const [phone, setPhone] = useState({
    phoneNumber: '',
    countryCode: ''
  });
  const { add_contact_info, loading } = useAuth();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const pushIntoArray = () => {
    setContactInfoArrayData([...contactInfoArrayData, { ...formData, ...phone }]);
    setFormData({ firstName: '', lastName: '', emailAddress: '' });
    setPhone({ phoneNumber: '', countryCode: ''})
  };

  const disabledBtn = useMemo(() => {
    let result = []
    Object.keys(formData).forEach((key) => {
      result.push(!validateFormData({ [key]: formData[key] }))
    })
    return result.includes(true)
  }, [formData])

  return (
    <FormWrapper>
      <div className="flex justify-between flex-col h-full lg:h-[400px]">
        <div className="grid grid-cols-12 mt-6 gap-6">
          <div className="md:col-span-6 col-span-12">
            <Input
              label="First Name"
              type="text"
              id="firstName"
              name="firstName"
              readOnly={loading}
              value={formData?.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="md:col-span-6 col-span-12">
            <Input
              label="Last Name"
              type="text"
              id="lastName"
              name="lastName"
              readOnly={loading}
              value={formData?.lastName}
              onChange={handleChange}
            />
          </div>
          <div className="md:col-span-6 col-span-12">
            <Input
              label="Email Address"
              type="text"
              id="emailAddress"
              name="emailAddress"
              readOnly={loading}
              value={formData?.emailAddress}
              onChange={handleChange}
            />
          </div>
          <Phone
            value={phone}
            onChange={setPhone}
            variant="md:col-span-6 col-span-12"
          />
          <button
            className={`text-brand_secondary font-campton_m text-14 cursor-pointer w-fit mt-4 mb-4`}
            data-testid="test-add"
            disabled={disabledBtn || !phone?.phoneNumber}
            onClick={pushIntoArray}
          >
            Add
          </button>
          {contactInfoArrayData.length > 0 && (
            <div className="col-span-12 border-t border-dashed border-neutral_stroke pt-6">
              {contactInfoArrayData?.map((item) => (
                <div key={item.firstName} className="flex space-x-4 mb-6 last-of-type:mb-0">
                  <div className="h-10 w-10 bg-brand_secondary flex justify-center items-center rounded-full text-neutral_white uppercase font-campton_sb">
                    {userInitals(item.firstName, item.lastName)}
                  </div>
                  <div>
                    <p className="capitalize font-campton_m text-neutral_black text-14">
                      {item?.firstName} {item?.lastName}
                    </p>
                    <div className="text-14 font-campton_r text-neutral_body flex items-center space-x-2 mt-1">
                      <p className=" ">{item.emailAddress} </p> <Dot />{' '}
                      <p>
                        {item.countryCode}
                        {item.phoneNumber}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex justify-end py-6">
          <Button
            name="Save"
            className="w-28 rounded-full h-10 font-campton_m"
            theme="blue"
            onClick={() =>
              add_contact_info(
                {
                  contactInfoArrayData,
                  type: user?.businessName ? 'institution' : 'provider',
                  institutionId,
                },
                callback,
              )
            }
            disabled={!contactInfoArrayData?.length}
            loading={loading}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

export default ContactPersonInfo;
ContactPersonInfo.propTypes = {
  callback: PropTypes.func,
};
