/* eslint-disable max-len */
import React from 'react';
import PropTypes from "prop-types"
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import Spinner from 'components/Spinner';

export default function VitalSignsAnalytics({ loading, toggleTab, selectedTab }) {
    return (
        <div>
            <p className="flex font-campton_r text-neutral_body items-center text-12 cursor-pointer hide_tap w-fit" onClick={toggleTab}>
                {selectedTab?.name}
                <ArrowDown fill='#AAB6C8' className='ml-[4px]' />
            </p>
            {loading ? <Spinner  height="h-[250px]" /> : selectedTab?.component}
        </div>
    );
}

VitalSignsAnalytics.propTypes = {
    patientId: PropTypes.string,
    loading: PropTypes.bool,
    toggleTab: PropTypes.func,
    selectedTab: PropTypes.any
}