import React from 'react'
import { ReactComponent as DoubleArrowRight } from 'assets/icons/double-arrow-right.svg';
import PropTypes from 'prop-types';

export default function BreadCrumbs({ options }) {
    return (
        <div className="flex sticky z-10 top-[77px] items-center px-[24px] py-[8px] border-b border-b-neutral_stroke_2 bg-neutral_white overflow-x-auto">
            {options?.map((option, i) =>
                <React.Fragment key={option.title}>
                    <p
                        data-testid="previous"
                        onClick={() => option?.method ? option?.method() : {}}
                        className={`
                            ${options?.length !== (i + 1) ? 'text-neutral_body cursor-pointer' : 'text-orange_400 cursor-default'}
                            text-12 font-campton_m hide_tap capitalize whitespace-nowrap
                            `}
                    >
                        {option?.title}
                    </p>
                    {options?.length !== (i + 1) ? <DoubleArrowRight className='shrink-0 mx-[4px]'/> : ''}
                </React.Fragment>
            )}
        </div>
    )
}

BreadCrumbs.propTypes = {
    options: PropTypes.array,
};