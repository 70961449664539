import React from "react"
import PropTypes from "prop-types"
import { TextArea } from "components/Inputs"
import Accordion from "components/Accordion"

export default function AbnormalFindings({ abnormalFindings, setAbnormalFindings, loading }) {
    return (
        <Accordion
            title="Abnormal findings"
            display={true}
        >
            <div className="pt-[24px]">
                <TextArea
                    type="text"
                    id="abnormalFindings"
                    name="abnormalFindings"
                    placeholder="Type your findings here..."
                    readOnly={loading}
                    value={abnormalFindings}
                    onChange={(e) => setAbnormalFindings(e.target.value)}
                    rows={3}
                />
            </div>
        </Accordion>
    )
}

AbnormalFindings.propTypes = {
    abnormalFindings: PropTypes.string,
    setAbnormalFindings: PropTypes.func,
    loading: PropTypes.bool
}