import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { SlideUp } from "components/Animations"
import SubNavbar from "components/SubNavbar"
import { Button } from "components/Buttons"
import useDebounce from "hooks/useDebounce"
import useProviders from "hooks/fetch/useProviders"
import { useDispatch, useSelector } from "react-redux"
import useHandleError from "hooks/useHandleError"
import { notify } from "store/modules/global"
import FormWrapper from "components/FormWrapper"
import { useNavigate } from "react-router-dom"
import Navbar from "components/Navbar"
import PatientInformation from "pages/Clinicals/Components/PatientInformation"
import useVisit from "hooks/fetch/useVisit"

export default function NewVisit() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { handleError } = useHandleError()
    const { institutionId } = useSelector(state => state.auth)
    const { add_visit, loading } = useVisit()
    const { get_patient_by_gNumber, loading: isFetchingPatient } = useProviders()
    const [patient, setPatient] = useState({
        firstName: '', lastName: '',
        emailAddress: '', gender: '',
        phoneNumber: '', dateOfBirth: '',
        profilePic: ''
    })
    const [gNumber, setGNumber] = useState('')

    const { debouncedValue, isTyping } = useDebounce(gNumber)

    const handleChange = (e) => {
        if (e.target.name === 'gNumber') return setGNumber(e.target.value)
    }

    useEffect(() => {
        if (debouncedValue.length === 10) {
            get_patient_by_gNumber(gNumber)
                .then(res => {
                    if (res?.code === 200) return setPatient(res?.data)
                    dispatch(notify({ display: true, status: 'error', message: handleError({ status: res?.code, message: res?.message }) }))
                    setPatient()
                })
        }
    }, [debouncedValue])

    const submit = () => {
        const payload = { patient: patient?._id, institutionId }
        add_visit(payload)
            .then(res => {
                if ([200, 201].includes(res?.code))
                    navigate("/clinicals/visits")
            })
    }

    const disabled = useMemo(() => {
        return !patient?.firstName || isTyping || isFetchingPatient
    }, [patient, isTyping, isFetchingPatient])

    useEffect(() => {
        document.title = 'New Visit - Gabbi Health'
    }, [])

    return (
        <>
            <Navbar title="Visit" />
            <SlideUp>
                <SubNavbar
                    previous="Visit"
                    current="New visit"
                    onClick={() => navigate("/clinicals/visits")}
                />
                <FormWrapper className="p-[24px]" onSubmit={submit}>
                    <h2 className="text-18 text-neutral_black font-campton_m mb-[16px]">New Visit</h2>
                    <PatientInformation
                        handleChange={handleChange}
                        patient={patient}
                        loading={isFetchingPatient}
                        gNumber={gNumber}
                    />
                    <div className="flex justify-end py-[20px]">
                        <Button
                            name="Cancel"
                            theme="transparent"
                            type="button"
                            onClick={() => navigate("/clinicals/visits")}
                            className="h-[40px] w-[112px] text-16 px-[12px] mr-[16px]"
                            disabled={loading}
                        />
                        <Button
                            name="Save"
                            theme="blue"
                            className="h-[40px] w-[112px] text-16 px-[12px]"
                            disabled={disabled}
                            loading={loading}
                        //onClick={changeRole}
                        />
                    </div>
                </FormWrapper>
            </SlideUp>
        </>
    )
}

NewVisit.propTypes = {
    callback: PropTypes.func
}