import React, { useState } from "react";
import PropTypes from "prop-types"
import Avatar from "components/Avatar/Avatar";
import Modal from "components/Modals/Modal";
import { formatTwelveHour } from "utils/formatTime";
import { formatDate } from "utils/formatDate";
import { userInitals } from "utils/ImagePlaceholder";
import Accordion from "components/Accordion";

export default function AdmissionInformation({ data }) {
    const [display, setDisplay] = useState(false)

    return (
        <Accordion title="Admission Information">
            <div className="text-14 font-campton_r rounded-[16px] py-[16px] w-full mb-[24px] md:mb-0">
                <div className="flex justify-end mb-[16px]">
                    {/* <p className="text-neutral_black">Admission Information</p> */}
                    <p className="text-brand_primary border-b border-b-brand_primary cursor-pointer" onClick={() => setDisplay(true)}>View more</p>
                </div>
                <div className="pb-[16px] border-b border-b-neutral_stroke_2">
                    <p className="text-neutral_body">Admission Diagnosis</p>
                    <p className="text-neutral_black first-letter:uppercase">{data?.admissionDiagnosis}</p>
                </div>
                <div className="py-[16px] border-b border-b-neutral_stroke_2">
                    <p className="text-neutral_body">Admitted By</p>
                    <p className="text-neutral_black capitalize">
                        {data?.admittedByInfo?.firstName} {data?.admittedByInfo?.lastName} <span className="text-neutral_body">|</span> {data?.admittedByInfo?.GPINumber}
                    </p>
                    <p className="text-neutral_black">{formatDate(data?.createdAt)}, {formatTwelveHour(data?.createdAt)}</p>
                </div>
                <div className="pt-[16px] truncate">
                    <p className="text-neutral_body">Location & Room Number</p>
                    <p className="text-neutral_black">
                        <span className="capitalize">{data?.location}</span> <span className="text-neutral_body">|</span> {data?.roomNumber}
                    </p>
                </div>
                {display && (
                    <Modal
                        closeModal={() => setDisplay(false)}
                        styles="w-full md:w-[457px]"
                        position="modal-right"
                        variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
                        title="Admission Information"
                    >
                        <div className="modal-right-body">
                            <div className="border border-neutral_stroke_2 rounded-[16px] px-[16px] bg-[#F7FBFD] mb-[24px]">
                                <div className="py-[16px] border-b border-b-neutral_stroke_2">
                                    <p className="text-neutral_body">Admission Diagnosis</p>
                                    <p className="text-neutral_black capitalize">{data?.admissionDiagnosis}</p>
                                </div>
                                <div className="py-[16px] border-b border-b-neutral_stroke_2">
                                    <p className="text-neutral_body">Admitted By</p>
                                    <div className="flex items-center">
                                        <Avatar
                                            url={data?.admittedByInfo?.profilePic}
                                            width="32px"
                                            height="32px"
                                            //alt={singleVisit?.provider?.firstName?.split('')[0]}
                                            initials={userInitals(data?.admittedByInfo?.firstName, data?.admittedByInfo?.lastName)}
                                            className="bg-brand_secondary w-[32px] h-[32px] mr-[8px]"
                                        />
                                        <div>
                                            <p className="text-neutral_black capitalize">
                                                {data?.admittedByInfo?.firstName} {data?.admittedByInfo?.lastName}
                                            </p>
                                            <p className="text-12 text-neutral_body">
                                                {data?.admittedByInfo?.specialty} | {data?.admittedByInfo?.GPINumber}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-[16px]">
                                    <p className="text-neutral_body">Admitted On</p>
                                    <p className="text-neutral_black">{formatDate(data?.createdAt)}, {formatTwelveHour(data?.createdAt)}</p>
                                </div>
                            </div>
                            <div className="border border-neutral_stroke_2 rounded-[16px] px-[16px]">
                                <div className="py-[16px] border-b border-b-neutral_stroke_2">
                                    <div className="flex justify-between">
                                        <div>
                                            <p className="text-neutral_body">Hospital Location</p>
                                            <p className="text-neutral_black capitalize">{data?.location}</p>
                                        </div>
                                        <div className="text-end">
                                            <p className="text-neutral_body">Room Number</p>
                                            <p className="text-neutral_black">{data?.roomNumber}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-[16px]">
                                    <p className="text-neutral_body">Length of Stay</p>
                                    <p className="text-neutral_black">{data?.lengthOfStay} Day(s)</p>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        </Accordion>
    )
}

AdmissionInformation.propTypes = {
    data: PropTypes.object
}