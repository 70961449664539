import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"
import { Button } from "components/Buttons";
import { Search } from "components/Inputs";
import Table from "components/Table";
import Avatar from "components/Avatar/Avatar";
import { ReactComponent as UnChecked } from "assets/icons/check-box-unselected.svg";
import { ReactComponent as Checked } from "assets/icons/check-box-selected.svg";
import { ReactComponent as DarkChecked } from "assets/icons/check-box-selected-dark.svg";
import { ReactComponent as ArrowBack } from "assets/icons/arrow-back.svg";
import useProviders from "hooks/fetch/useProviders";
import { useSelector } from "react-redux";
import useTable from "hooks/useTable";
import useDebounce from "hooks/useDebounce";
import EmptyState from "components/Table/EmptyState";
import Status from "components/Status";
import useRoles from "hooks/fetch/useRoles";

export default function CareTeam({ back, formData, setFormData, cancel, next, admittedBy }) {
    const { get_roles } = useRoles()
    const { roles } = useSelector(state => state.roles)
    const { providers } = useSelector(state => state.providers)
    const { get_institution_providers, loading } = useProviders()
    const [filter, setFilter] = useState('')
    const { institutionId } = useSelector(state => state.auth)
    const { params, handleSearch, selectLimit, changePage } = useTable()
    const {debouncedValue} = useDebounce(params.search)

    useEffect(() => {
        const queryParams = {
            role: filter,
            search: params.search || ''
        }
        get_institution_providers({ id: `${institutionId}/providers`, params: { ...queryParams } })
    }, [filter, debouncedValue])

    useEffect(() => {
        get_roles({ id: institutionId })
    }, [])

    return (
        <div className="flex flex-col justify-between h-full">
            <div className="mb-[24px]">
                <div className="flex items-center mb-[24px]">
                    <Button
                        theme="transparent"
                        type="button"
                        className="w-[82px] mr-[12px]"
                        onClick={back}
                    >
                        <>
                            <ArrowBack fill="#748181" className="w-[7px] h-[12px] mr-[13.5px]" />
                            Back
                        </>
                    </Button>
                    <h2 className="text-16 text-neutral_black font-campton_m pl-[12px] border-l border-l-neutral_stroke_2">Care Team</h2>
                </div>
                <div className="mb-[24px]">
                    <Search
                        name="search"
                        id="search"
                        placeholder="Search by staff name..."
                        onChange={handleSearch}
                    />
                    <div className="flex overflow-x-auto no-scrollbar">
                        {roles?.Roles?.map(role => role?.name)?.map(item =>
                            <Status
                                key={item}
                                status={item}
                                className={`
                                border text-14 mt-[16px] mr-[8px] cursor-pointer
                                ${filter === item ? 'border-blue bg-#F2F6FC text-blue' : 'border-neutral_stroke_2 text-neutral_body'}
                                `}
                                onClick={() => setFilter(item)}
                            />
                        )}
                    </div>
                </div>
                <div>
                    <p className="text-12 text-brand_secondary font-campton_r mb-[11px]">{formData?.careTeam?.length + 1} Staff Selected</p>
                    <div>
                        <Table
                            hasFilter={false}
                            hasPagination={false}
                            tableContainer="min-w-[400px]"
                            headers={['Staff Name', 'Specialty', 'Location']}
                            data={providers?.providers}
                            loading={loading}
                            selectLimit={selectLimit}
                            changePage={changePage}
                            emptyState={
                                <EmptyState
                                    title="No Available Staff"
                                />
                            }
                        >
                            <>
                                <tr
                                    key={admittedBy?._id}
                                    className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1"
                                >
                                    <td className="px-[10px] py-[8px] flex items-center w-[260px] capitalize">
                                        <DarkChecked />
                                        <Avatar
                                            url={admittedBy?.profilePic}
                                            height="32px"
                                            width="32px"
                                            alt={admittedBy?.firstName?.split('')[0]}
                                            initials={`${admittedBy?.firstName?.split('')[0] || ''}${admittedBy?.lastName?.split('')[0] || ''}`}
                                            className="shrink-0 w-[32px] h-[32px] mx-[8px]"
                                        />
                                        <div>
                                            <p
                                                title={`${admittedBy?.firstName} ${admittedBy?.lastName}`}
                                                className="text-14 text-neutral_black font-campton_r truncate"
                                            >
                                                {admittedBy?.firstName} {admittedBy?.lastName}
                                            </p>
                                            <p className="text-12 text-neutral_body font-campton_r">{admittedBy?.emailAddress}</p>
                                        </div>
                                    </td>
                                    <td className="px-[10px] py-[8px] capitalize">{admittedBy?.specialty || 'N/A'}</td>
                                    <td className="px-[10px] py-[8px] capitalize">{admittedBy?.locationName || 'N/A'}</td>
                                </tr>
                                {providers?.providers?.filter(item => item?._id !== formData?.admittedBy)?.map((item) =>
                                    <tr
                                        key={item?._id}
                                        className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1"
                                    >
                                        <td className="px-[10px] py-[8px] flex items-center w-[260px] capitalize">
                                            {formData?.careTeam?.find(staff => staff?.providerId === item?._id) ?
                                                <Checked
                                                    data-testid={item?._id}
                                                    className="cursor-pointer shrink-0"
                                                    onClick={() => {
                                                        setFormData({
                                                            ...formData,
                                                            careTeam: formData.careTeam.filter(staff => staff?.providerId !== item?._id)
                                                        })
                                                    }}
                                                /> :
                                                <UnChecked
                                                    className="cursor-pointer shrink-0"
                                                    data-testid={item?._id}
                                                    onClick={() => {
                                                        setFormData({
                                                            ...formData,
                                                            careTeam: [
                                                                ...formData.careTeam, {
                                                                    providerId: item?._id,
                                                                    firstName: item?.firstName,
                                                                    lastName: item?.lastName,
                                                                    emailAddress: item?.emailAddress,
                                                                    gender: item?.gender,
                                                                    qualification: item?.qualification,
                                                                    role: item?.role,
                                                                    specialty: item?.specialty,
                                                                    location: item?.locationName
                                                                }]
                                                        })
                                                    }}
                                                />
                                            }
                                            <Avatar
                                                url={item?.profilePic}
                                                height="32px"
                                                width="32px"
                                                alt={item?.firstName?.split('')[0]}
                                                initials={`${item?.firstName?.split('')[0] || ''}${item?.lastName?.split('')[0] || ''}`}
                                                className="w-[32px] h-[32px] shrink-0 mx-[8px]"
                                            />
                                            <div>
                                                <p title={`${item?.firstName} ${item?.lastName}`} className="truncate text-14 text-neutral_black font-campton_r">{item?.firstName} {item?.lastName}</p>
                                                <p className="text-12 text-neutral_body font-campton_r">{item?.emailAddress?.toLowerCase()}</p>
                                            </div>
                                        </td>
                                        <td className="px-[10px] py-[8px] capitalize">{item?.specialty}</td>
                                        <td className="px-[10px] py-[8px] capitalize">{item?.locationName}</td>
                                    </tr>
                                )}
                            </>
                        </Table>
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <Button
                    name="Cancel"
                    theme="transparent"
                    type="button"
                    className="h-[40px] text-16 w-[79px] mr-[16px]"
                    onClick={cancel}
                />
                <Button
                    name="Next"
                    theme="blue"
                    type="button"
                    onClick={next}
                    className="h-[40px] text-16 w-[88px]"
                />
            </div>
        </div>
    )
}

CareTeam.propTypes = {
    back: PropTypes.func,
    cancel: PropTypes.func,
    next: PropTypes.func,
    admittedBy: PropTypes.object,
    setFormData: PropTypes.func,
    formData: PropTypes.object,
    updateStaffs: PropTypes.func
}