import Button from 'components/Buttons/Button';
import FormWrapper from 'components/FormWrapper';
import { Input } from 'components/Inputs';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as FamilyHistorySolid } from 'assets/icons/family-history-solid.svg';
import useVisit from 'hooks/fetch/useVisit';
import Dropdown from 'components/Dropdown';
import { hereditaryDiseases } from 'mocks/clinicals/visits';
import { PatientContext } from 'context';

const AddFamilyHistory = ({ callback }) => {
  const { add_medical_record, loading } = useVisit();
  const { patient, setPatient } = useContext(PatientContext)
  const [familyHistories, setFamilyHistories] = useState([]);
  const [hereditaryDisease, setHereditaryDisease] = useState();
  const [formData, setFormData] = useState({
    hereditaryDisease: '',
    relationship: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const addMedicalRecord = () => {
    add_medical_record('familyHistory', patient?._id, formData)
      .then(res => {
        if ([201, 200]?.includes(res?.code)) {
          setPatient(res?.data)
          setFamilyHistories([...familyHistories, formData])
          setHereditaryDisease('');
          setFormData({ hereditaryDisease: '', relationship: '' })
        }
      })
  }

  const disabledBtn = !hereditaryDisease || !formData.relationship

  return (
    <div className="w-full lg:w-[730px] lg:h-[400px]">
      <FormWrapper className="h-full"
        onSubmit={callback}
      >
        <div className="flex justify-between flex-col h-full">
          <div className="grid gap-x-6 gap-y-5 grid-cols-12 mt-6">
            <Dropdown
              type="select"
              id="hereditaryDisease"
              placeholder="Known Hereditary Disease"
              name="hereditaryDisease"
              variant="lg:col-span-6 col-span-12"
              width="mb-6"
              options={hereditaryDiseases}
              selected={hereditaryDisease}
              onSelect={(data) => {
                setHereditaryDisease(data.name);
                setFormData({ ...formData, hereditaryDisease: data.value });
              }}
            />
            <div className="md:col-span-6 col-span-12">
              <Input
                label="Relationship with the patient"
                type="text"
                id="relationship"
                name="relationship"
                readOnly={loading}
                value={formData?.relationship}
                onChange={handleChange}
                showError={false}
              />
            </div>
            <p
              data-testid="test-add"
              className={`text-brand_secondary font-campton_m text-14  ${disabledBtn ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
              onClick={disabledBtn ? null : addMedicalRecord}
              disabled={disabledBtn}
            >
              Add
            </p>
            {familyHistories.length > 0 && (
              <div className="col-span-12 border-t border-dashed border-neutral_stroke pt-6">
                {familyHistories?.map((item) => (
                  <div key={item} className="flex space-x-4 mb-6 last-of-type:mb-0">
                    <FamilyHistorySolid />
                    <div>
                      <p className="capitalize font-campton_m text-neutral_black text-14">{item?.hereditaryDisease}</p>
                      <p
                        className='text-12 text-neutral_body font-campton_r mt-0.5'
                      >
                        Relationship: <span className='text-neutral_black'>{item?.relationship}</span></p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <Button name='Close' theme="blue" className="w-28 h-10"
              onClick={callback}

            />
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default AddFamilyHistory;
AddFamilyHistory.propTypes = {
  callback: PropTypes.any
};
